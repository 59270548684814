import React, { useState } from 'react';
import Styles from './styles.module.css';

type ManageMemoProps = {
  setExamToggle: React.Dispatch<React.SetStateAction<boolean>>;
  setExamPanelToggle: React.Dispatch<React.SetStateAction<boolean>>;
};

const ManageExamPanel: React.FC<ManageMemoProps> = ({ setExamToggle, setExamPanelToggle }) => {

  return (
    <>
    <div className={Styles.modal}>
      <div>
        <div className={Styles.modal_black} onClick={() => setExamToggle(false)}></div>
        <div className={Styles.modal_contents}>
          <h4>審査しますか？</h4>
          <p>
            応募者1名に対して審査できる人数は8名までとなりますので、予め社内で審査担当者を検討してください。
          </p>
          <div>
            <button style={{backgroundColor : 'white', color : '#AD0200'}} 
              onClick={() => setExamToggle(false)}>キャンセル</button>
            <button style={{backgroundColor : '#AD0200', color : 'white'}} 
              onClick={() => {setExamToggle(false); setExamPanelToggle(true);}}>審査する</button>
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

export default ManageExamPanel;
