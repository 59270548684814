import './App.css';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

// 求人
import Top from './pages/top/index';
import WorksRecruit from './pages/recruit/recruit/index';


// その他のページ
import Contact from './pages/contact/index';
import Terms from './pages/document/terms/index';
import Policy from './pages/document/policy/index';
import Company from './pages/landing/index';
import NotFoundPage from './pages/404/index';

// 認証
import CompanySignIn from './auth/signin/index';
import CompanyLogin from './auth/login/1-login';
import CompanyConfirm from './auth/login/2-confirm';
import CompanyReset from './auth/login/3-reset';

// サービス
import WorksService from './desktop/index';
import WorksEdit from './desktop/2.recruit/2.edit/index';
import WorksEditStore from './desktop/2.recruit/2.edit/1.summary/index';
import WorksServiceStore from './desktop/2.recruit/2.edit/2.place/edit/index';
import WorksCallback from './desktop/8.callback/index';

// 店舗編集ページ
import WorksEditArea from './desktop/2.recruit/2.edit/2.place/edit/1.edit/1.place/index';
import WorksEditPentagon from './desktop/2.recruit/2.edit/2.place/edit/1.edit/2.pentagon/index';
import WorksEditType from './desktop/2.recruit/2.edit/2.place/edit/1.edit/3.type/index';

// 求人編集ページ
import WorksEditPoint from './desktop/2.recruit/2.edit/3.point/index';
import WorksEditStoreSelect from './desktop/2.recruit/2.edit/2.place/index';
import WorksEditNumber from './desktop/2.recruit/2.edit/4.number/index';
import WorksEditPhoto from './desktop/2.recruit/2.edit/5.photo/index';
import WorksEditInterview from './desktop/2.recruit/2.edit/6.interview/index';
import WorksEditPerson from './desktop/2.recruit/2.edit/7.person/index';
import WorksEditRecruit from './desktop/2.recruit/2.edit/9.recruit/index';
import WorksEditHashTag from './desktop/2.recruit/2.edit/8.hashTag/index';

import WorksEditDocument from './desktop/2.recruit/2.edit/10.document/index';
import WorksEditEvaluation from './desktop/2.recruit/2.edit/11.examination/index';

import Media0001 from './media/article/0001';
import Media0002 from './media/article/0002';
import Media0003 from './media/article/0003';
import Media0004 from './media/article/0004';
import Media0005 from './media/article/0005';
import Media0006 from './media/article/0006';
import Media0007 from './media/article/0007';

import Add from './z_box/index';
import RecruitData from './z_box/recruits/index';
import CompanyData from './z_box/companies/index';
import StoresData from './z_box/stores/index';

function App() {
  return (
    <>
    <Router>
      <Routes>
        <Route exact path="/" element={<Top />} />
        <Route exact path="/:search" element={<Top />} />
        <Route exact path="/recruit/:id" element={<WorksRecruit />} />

        <Route exact path="/Company" element={<Company />} />
        <Route exact path="/contact" element={<Contact />} />
        <Route exact path="/terms" element={<Terms />} />
        <Route exact path="/policy" element={<Policy />} />

        <Route exact path="/company/login" element={<CompanyLogin />} />
        <Route exact path="/company/signin" element={<CompanySignIn />} />
        <Route exact path="/company/reset" element={<CompanyReset />} />
        <Route exact path="/company/confirm" element={<CompanyConfirm />} />

        <Route exact path="/service" element={<WorksService />} />

        <Route exact path="/edit/:id" element={<WorksEdit />} />
        <Route exact path="/edit/store/:id" element={<WorksEditStore />} />
        <Route exact path="/edit/storeSetting/:id" element={<WorksServiceStore />} />
        <Route exact path="/edit/storeSelect/:id" element={<WorksEditStoreSelect />} />
        <Route exact path="/edit/area/:id" element={<WorksEditArea />} />
        <Route exact path="/edit/type/:id" element={<WorksEditType />} />
        <Route exact path="/edit/pentagon/:id" element={<WorksEditPentagon />} />
        <Route exact path="/edit/point/:id" element={<WorksEditPoint />} />
        <Route exact path="/edit/number/:id" element={<WorksEditNumber />} />
        <Route exact path="/edit/photo/:id" element={<WorksEditPhoto />} />
        <Route exact path="/edit/interview/:id" element={<WorksEditInterview />} />
        <Route exact path="/edit/person/:id" element={<WorksEditPerson />} />
        <Route exact path="/edit/recruit/:id" element={<WorksEditRecruit />} />
        <Route exact path="/edit/hashtag/:id" element={<WorksEditHashTag />} />
        <Route exact path="/service/callback" element={<WorksCallback />} />

        <Route exact path="/edit/template/:id" element={<WorksEditDocument />} />
        <Route exact path="/edit/evaluation/:id" element={<WorksEditEvaluation />} />

        <Route exact path="/media/0001" element={<Media0001 />} />
        <Route exact path="/media/0002" element={<Media0002 />} />
        <Route exact path="/media/0003" element={<Media0003 />} />
        <Route exact path="/media/0004" element={<Media0004 />} />
        <Route exact path="/media/0005" element={<Media0005 />} />
        <Route exact path="/media/0006" element={<Media0006 />} />
        <Route exact path="/media/0007" element={<Media0007 />} />

        <Route exact path="/add" element={<Add />} />
        <Route exact path="/recruitData" element={<RecruitData />} />
        
        <Route exact path="/CompanyData" element={<CompanyData />} />
       
        <Route exact path="/storesData" element={<StoresData />} />

        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </Router>
    </>
  );
}

export default App;
