import { Splide, SplideSlide } from "@splidejs/react-splide";
import Styles from "./styles.module.css";
import PentagonTreatment from "../pentagonTreatment/index";
import PentagonStyle from "../pentagonStyle/index";
import '@splidejs/splide/css';
import React, { useEffect, useState } from 'react';
import firebase from 'firebase/compat/app';

type RecruitStoresProps = {
  recruitId: string;
};

const RecruitStores: React.FC<RecruitStoresProps> = ({ recruitId }) => {

  const [ storeList, setStoreList ] = useState<any[]>([]);
  
  useEffect(() => {
    store(recruitId);
  }, []);

  function store(recruit: string) {

    firebase.firestore().collection('stores').where('recruitId', '==', recruit).orderBy("number", "asc").get()
    .then(snapShot => {
      var tmp: any = [];
      snapShot.forEach((doc) => {
        const data = doc.data();
        if (data) {
          tmp.push({
            'index' : tmp.length,
  
            'name' : data.name,
            'address' : data.address,
            'image' : data.image,
  
            'stationCount' : data.stationCount,
            'station1Name' : data.station1Name,
            'station1Time' : data.station1Time,
            'station2Name' : data.station2Name,
            'station2Time' : data.station2Time,
            'station3Name' : data.station3Name,
            'station3Time' : data.station3Time,
  
            'type' : data.type,
  
            'mood1' : data.pentagonTreatment1,
            'mood2' : data.pentagonTreatment2,
            'mood3' : data.pentagonTreatment3,
            'mood4' : data.pentagonTreatment4,
            'mood5' : data.pentagonTreatment5,
            'mood6' : data.pentagonTreatment6,
  
            'style1' : data.pentagonStyle6,
            'style2' : data.pentagonStyle1,
            'style3' : data.pentagonStyle2,
            'style4' : data.pentagonStyle3,
            'style5' : data.pentagonStyle4,
            'style6' : data.pentagonStyle5,
  
            'pentagonStyleInput' : data.pentagonStyleInput,

          });
        }
      });
      setStoreList(tmp);
    });
  }

  const [ pentagonSwitch, setPentagonSwitch ] = useState(true);

  return (
    <>
    <Splide
      aria-label=""
      options={{
        perPage: 1,
        autoplay: true,
        interval: 3000,
      }}
    >
      {storeList.map((data, index) =>
        <SplideSlide key={index}>
          <div className={Styles.store} key={index}>
            <img src={`https://firebasestorage.googleapis.com/v0/b/trankllc-com.appspot.com/o/stores%2Fresize_images%2F${data['image']}_1080x1080?alt=media&token=`} />
            <h4>{data.name}</h4>
            <h5>{data.address}</h5>
            <h6>{`${data['stationCount'] >= 1 ? data['station1Name'] : data['station1Name']} ${data['stationCount'] >= 1 ? data['station1Time'] : data['station1Time']} ${data['stationCount'] >= 2 ? '、' : ''} ${data['stationCount'] >= 2 ? data['station2Name'] : data['station2Name']} ${data['stationCount'] >= 2 ? data['station2Time'] : data['station2Time']} ${data['stationCount'] >= 3 ? '、' : ''} ${data['stationCount'] >= 3 ? data['station3Name'] : data['station3Name']} ${data['stationCount'] >= 3 ? data['station3Time'] : data['station3Time']}`}</h6>
            
            <div className={Styles.title}>
              <h4 className={Styles.title_bar} onClick={() => setPentagonSwitch(true)} style={pentagonSwitch ? {} : {backgroundColor : '#ffffff', color: '#AD0200', borderBottom : '1px solid #AD0200',}}>
                施術頻度
              </h4>
              <h4 className={Styles.title_bar} onClick={() => setPentagonSwitch(false)} style={pentagonSwitch ? {backgroundColor : '#ffffff', color: '#AD0200', borderBottom : '1px solid #AD0200',} : {}}>
                スタイル頻度
              </h4>
            </div>

            {pentagonSwitch ?
              <PentagonTreatment
                pentagonTreatment={[
                  data.mood1 === undefined ? 1 : data.mood1,
                  data.mood2 === undefined ? 1 : data.mood2,
                  data.mood3 === undefined ? 1 : data.mood3,
                  data.mood4 === undefined ? 1 : data.mood4,
                  data.mood5 === undefined ? 1 : data.mood5,
                  data.mood6 === undefined ? 1 : data.mood6,
                ]}
              />
              :
              <PentagonStyle
                pentagonStyle={[
                  data.style1 === undefined ? 1 : data.style1,
                  data.style2 === undefined ? 1 : data.style2,
                  data.style3 === undefined ? 1 : data.style3,
                  data.style4 === undefined ? 1 : data.style4,
                  data.style5 === undefined ? 1 : data.style5,
                  data.style6 === undefined ? 1 : data.style6,
                ]}

                triangleTreatTitle={data.pentagonStyleInput === undefined ? '' : data.pentagonStyleInput}
              />
            }
          </div>
        </SplideSlide>
      )}
    </Splide>
    </>
  );
}

export default RecruitStores;
