import Styles from './styles.module.css';
import React, { useState } from 'react';

type DetailsResumeProps = {
  recruitList: RecruitList;
  applicantList: ApplicantList;
};

interface RecruitList {
  id: string;
  selection: number;
  selectionCount: number;
  selectionText1: string;
  selectionText2: string;
  selectionText3: string;
  selectionText4: string;
  selectionText5: string;
  selectionText6: string;
  selectionText7: string;
  selectionText8: string;

  resume: boolean;
  coordinate: boolean;
  question: boolean;
  questionCount: number;
  question01: string;
  question02: string;
  question03: string;
  otherDocument: boolean;
  otherDocumentCount: number;
  otherDocument01: string;
  otherDocument02: string;
  otherDocument03: string;
}

interface ApplicantList {
  uid: string;
  examination: number;
  recruitId: string;
  time: Date;
  name: string;
  nameHurigana: string;
  birthdayYears: string;
  birthdayMonth: string;
  birthdayDays: string;
  age: string;
  sex: string;
  bloodType: string;
  addressHurigana: string;
  postCode: string;
  address: string;
  phoneNumber: string;
  mailAddress: string;
  history1: string;
  historyYears1: string;
  historyMonth1: string;
  history2: string;
  historyYears2: string;
  historyMonth2: string;
  history3: string;
  historyYears3: string;
  historyMonth3: string;
  history4: string;
  historyYears4: string;
  historyMonth4: string;
  history5: string;
  historyYears5: string;
  historyMonth5: string;
  history6: string;
  historyYears6: string;
  historyMonth6: string;
  award1: string;
  awardYears1: string;
  awardMonth1: string;
  award2: string;
  awardYears2: string;
  awardMonth2: string;
  award3: string;
  awardYears3: string;
  awardMonth3: string;
  instagram: string;
  instagramFollower: string;
  tiktok: string;
  tiktokFollower: string;
  twitter: string;
  twitterFollower: string;
  motivation: string;
  prText: string;
  coordinate1Title: string;
  coordinate1Point: string;
  coordinate2Title: string;
  coordinate2Point: string;
  coordinate3Title: string;
  coordinate3Point: string;
  question01Text: string;
  question02Text: string;
  question03Text: string;
}

const DetailsResumeMobile: React.FC<DetailsResumeProps> = ({ recruitList, applicantList }) => {

  return (
    <> 
    {recruitList.resume ?
    <>
      <div className={Styles.box}>
        <div className={Styles.grid_1}>
          <div className={Styles.grid_1_1}>履歴書</div>
          <div className={Styles.grid_1_2}>
            {new Date(applicantList.time).getFullYear()}年{(new Date(applicantList.time).getMonth() + 1)}月{new Date(applicantList.time).getDate()}日
          </div>
          <div className={Styles.grid_1_3}>
            <img src={`https://firebasestorage.googleapis.com/v0/b/trankllc-com.appspot.com/o/resume%2Fresize_images%2F${applicantList.uid}_${applicantList.recruitId}_500x500?alt=media&token=2bdf5a1a-1956-433f-bda0-e2b8a7afa2a8`} />
          </div>
          <div className={Styles.grid_1_4}>フリガナ　{applicantList.nameHurigana}</div>
          <div className={Styles.grid_1_5}>氏名　{applicantList.name}</div>
          <div className={Styles.grid_1_6}>
            {applicantList.birthdayYears}年{applicantList.birthdayMonth}月{applicantList.birthdayDays}日
            （満 {applicantList.age}歳）</div>
          <div className={Styles.grid_1_7}>性別</div>
          <div className={Styles.grid_1_8}>{applicantList.sex}</div>
          <div className={Styles.grid_1_9}>血液型：{applicantList.bloodType}型</div>

          <div className={Styles.grid_1_10}>フリガナ　{applicantList.addressHurigana}</div>
          <div className={Styles.grid_1_11}>〒　{applicantList.postCode.substr(0,3)}-{applicantList.postCode.substr(3,4)}</div>
          <div className={Styles.grid_1_12}>現住所　{applicantList.address}</div>
          <div className={Styles.grid_1_13}>電話番号　{applicantList.phoneNumber}　メールアドレス　{applicantList.mailAddress}</div>

          <div className={Styles.grid_1_14}>年</div>
          <div className={Styles.grid_1_15}>月</div>
          <div className={Styles.grid_1_16}>学歴・職歴</div>
          <div className={Styles.grid_1_17} style={applicantList.history1 === '' ? {backgroundColor : '#cccccc'} : {}}>
            {applicantList.historyYears1}</div>
          <div className={Styles.grid_1_18} style={applicantList.history1 === '' ? {backgroundColor : '#cccccc'} : {}}>
            {applicantList.historyMonth1}</div>
          <div className={Styles.grid_1_19} style={applicantList.history1 === '' ? {backgroundColor : '#cccccc'} : {}}>
            {applicantList.history1}</div>
          <div className={Styles.grid_1_20} style={applicantList.history2 === '' ? {backgroundColor : '#cccccc'} : {}}>
            {applicantList.historyYears2}</div>
          <div className={Styles.grid_1_21} style={applicantList.history2 === '' ? {backgroundColor : '#cccccc'} : {}}>
            {applicantList.historyMonth2}</div>
          <div className={Styles.grid_1_22} style={applicantList.history2 === '' ? {backgroundColor : '#cccccc'} : {}}>
            {applicantList.history2}</div>
          <div className={Styles.grid_1_23} style={applicantList.history3 === '' ? {backgroundColor : '#cccccc'} : {}}>
            {applicantList.historyYears3}</div>
          <div className={Styles.grid_1_24} style={applicantList.history3 === '' ? {backgroundColor : '#cccccc'} : {}}>
            {applicantList.historyMonth3}</div>
          <div className={Styles.grid_1_25} style={applicantList.history3 === '' ? {backgroundColor : '#cccccc'} : {}}>
            {applicantList.history3}</div>
          <div className={Styles.grid_1_26} style={applicantList.history4 === '' ? {backgroundColor : '#cccccc'} : {}}>
            {applicantList.historyYears4}</div>
          <div className={Styles.grid_1_27} style={applicantList.history4 === '' ? {backgroundColor : '#cccccc'} : {}}>
            {applicantList.historyMonth4}</div>
          <div className={Styles.grid_1_28} style={applicantList.history4 === '' ? {backgroundColor : '#cccccc'} : {}}>
            {applicantList.history4}</div>
          <div className={Styles.grid_1_29} style={applicantList.history5 === '' ? {backgroundColor : '#cccccc'} : {}}>
            {applicantList.historyYears5}</div>
          <div className={Styles.grid_1_30} style={applicantList.history5 === '' ? {backgroundColor : '#cccccc'} : {}}>
            {applicantList.historyMonth5}</div>
          <div className={Styles.grid_1_31} style={applicantList.history5 === '' ? {backgroundColor : '#cccccc'} : {}}>
            {applicantList.history5}</div>
          <div className={Styles.grid_1_32} style={applicantList.history6 === '' ? {backgroundColor : '#cccccc'} : {}}>
            {applicantList.historyYears6}</div>
          <div className={Styles.grid_1_33} style={applicantList.history6 === '' ? {backgroundColor : '#cccccc'} : {}}>
            {applicantList.historyMonth6}</div>
          <div className={Styles.grid_1_34} style={applicantList.history6 === '' ? {backgroundColor : '#cccccc'} : {}}>
            {applicantList.history6}</div>

          <div className={Styles.grid_1_35}></div>

          <div className={Styles.grid_1_36}>年</div>
          <div className={Styles.grid_1_37}>月</div>
          <div className={Styles.grid_1_38}>資格取得・受賞歴</div>
          <div className={Styles.grid_1_39} style={applicantList.award1 === '' ? {backgroundColor : '#cccccc'} : {}}>
            {applicantList.awardYears1}</div>
          <div className={Styles.grid_1_40} style={applicantList.award1 === '' ? {backgroundColor : '#cccccc'} : {}}>
            {applicantList.awardMonth1}</div>
          <div className={Styles.grid_1_41} style={applicantList.award1 === '' ? {backgroundColor : '#cccccc'} : {}}>
            {applicantList.award1}</div>
          <div className={Styles.grid_1_42} style={applicantList.award2 === '' ? {backgroundColor : '#cccccc'} : {}}>
            {applicantList.awardYears2}</div>
          <div className={Styles.grid_1_43} style={applicantList.award2 === '' ? {backgroundColor : '#cccccc'} : {}}>
            {applicantList.awardMonth2}</div>
          <div className={Styles.grid_1_44} style={applicantList.award2 === '' ? {backgroundColor : '#cccccc'} : {}}>
            {applicantList.award2}</div>
          <div className={Styles.grid_1_45} style={applicantList.award3 === '' ? {backgroundColor : '#cccccc'} : {}}>
            {applicantList.awardYears3}</div>
          <div className={Styles.grid_1_46} style={applicantList.award3 === '' ? {backgroundColor : '#cccccc'} : {}}>
            {applicantList.awardMonth3}</div>
          <div className={Styles.grid_1_47} style={applicantList.award3 === '' ? {backgroundColor : '#cccccc'} : {}}>
            {applicantList.award3}</div>
        </div>
      </div>

      <div className={Styles.box}>
        <div className={Styles.grid_2}>
          {/* <div className={Styles.grid_2_1}>希望店舗</div>
          <div className={Styles.grid_2_2}>{applicantList.hopeStore}</div> */}
          <div className={Styles.grid_2_3}></div>
          <div className={Styles.grid_2_4}>媒体名</div>
          <div className={Styles.grid_2_5}>アカウント名</div>
          <div className={Styles.grid_2_6}>フォロワー数</div>
          <div className={Styles.grid_2_7}>Instagram</div>
          <div className={Styles.grid_2_8}>{applicantList.instagram}</div>
          <div className={Styles.grid_2_9}>{applicantList.instagramFollower}人</div>
          <div className={Styles.grid_2_10}>Tiktok</div>
          <div className={Styles.grid_2_11}>{applicantList.tiktok}</div>
          <div className={Styles.grid_2_12}>{applicantList.tiktokFollower}人</div>
          <div className={Styles.grid_2_13}>X（Twitter）</div>
          <div className={Styles.grid_2_14}>{applicantList.twitter}</div>
          <div className={Styles.grid_2_15}>{applicantList.twitterFollower}人</div>
          <div className={Styles.grid_2_16}></div>
          <div className={Styles.grid_2_17}>志望動機</div>
          <div className={Styles.grid_2_18}>{applicantList.motivation}</div>
          <div className={Styles.grid_2_19}>自己PR</div>
          <div className={Styles.grid_2_20}>{applicantList.prText}</div>
        </div>
      </div>
    </> : <></> }

    {recruitList.coordinate ?
      <div className={Styles.box}>
        <div className={Styles.grid_3}>
          <div className={Styles.grid_3_1}>
            <img src={`https://firebasestorage.googleapis.com/v0/b/trankllc-com.appspot.com/o/coordination%2Fresize_images%2F${applicantList.recruitId}_${applicantList.uid}_1_750x750?alt=media&token=`} />
          </div>
          <div className={Styles.grid_3_2}>{applicantList.coordinate1Title}</div>
          <div className={Styles.grid_3_3}>{applicantList.coordinate1Point}</div>
          <div className={Styles.grid_3_4}>
            <img src={`https://firebasestorage.googleapis.com/v0/b/trankllc-com.appspot.com/o/coordination%2Fresize_images%2F${applicantList.recruitId}_${applicantList.uid}_2_750x750?alt=media&token=`} />
          </div>
          <div className={Styles.grid_3_5}>{applicantList.coordinate2Title}</div>
          <div className={Styles.grid_3_6}>{applicantList.coordinate2Point}</div>
          <div className={Styles.grid_3_7}>
            <img src={`https://firebasestorage.googleapis.com/v0/b/trankllc-com.appspot.com/o/coordination%2Fresize_images%2F${applicantList.recruitId}_${applicantList.uid}_3_750x750?alt=media&token=`} />
          </div>
          <div className={Styles.grid_3_8}>{applicantList.coordinate3Title}</div>
          <div className={Styles.grid_3_9}>{applicantList.coordinate3Point}</div>
        </div>
      </div>
    : <></> }

    {recruitList.question ?
      <div className={Styles.box}>
        <div className={Styles.grid_4}>
          <div className={Styles.grid_4_1}
            style={applicantList.question01Text === '' ? {backgroundColor : '#cccccc'} : {}}
          >{recruitList.question01}</div>
          <div className={Styles.grid_4_2}
            style={applicantList.question01Text === '' ? {backgroundColor : '#cccccc'} : {}}
          >{applicantList.question01Text}</div>
          <div className={Styles.grid_4_3}
            style={applicantList.question02Text === '' ? {backgroundColor : '#cccccc'} : {}}
          >{recruitList.question02}</div>
          <div className={Styles.grid_4_4}
            style={applicantList.question02Text === '' ? {backgroundColor : '#cccccc'} : {}}
          >{applicantList.question02Text}</div>
          <div className={Styles.grid_4_5}
            style={applicantList.question03Text === '' ? {backgroundColor : '#cccccc'} : {}}
          >{recruitList.question03}</div>
          <div className={Styles.grid_4_6}
            style={applicantList.question03Text === '' ? {backgroundColor : '#cccccc'} : {}}
          >{applicantList.question03Text}</div>
        </div>
      </div>
    : <></> } 
    </>
  );
}

export default DetailsResumeMobile;


