import Styles from './styles.module.css';
import React, { SetStateAction, useState } from 'react';
import firebase from 'firebase/compat/app';
import { v4 as uuid } from 'uuid';
import DefaultInput1 from '../../../package/parts/1-input/index';
import { useDispatch, useSelector } from 'react-redux';
import { fetchRecruits } from '../../../features/0.main/recruit';

type RecruitMakeProps = {
  setMakeDoc: React.Dispatch<React.SetStateAction<number>>;
};

const RecruitMake: React.FC<RecruitMakeProps> = ({ setMakeDoc }) => {

  const dispatch = useDispatch();

  const company = useSelector((state: any) => state.company.company);

  const [ recruitTitle, setRecruitTitle ] = useState<string | number>('');
  const [ errorRecruitTitle, setErrorRecruitTitle ] = useState(false);

  function make() {
    var docId = uuid();

    firebase.firestore().collection('recruits').doc(docId)
    .set({
      // 情報
      id: docId,
      companyId: company.id,
      time: new Date(),
      last_time: new Date(),

      // 店舗情報 > 基本
      store_name: '',
      store_title: recruitTitle,
      store_goalPeople : 0,
      recruit_hashTag: [],
      place_stores: [],

      // データ
      data_applicants: [],
      data_apply: [],
      data_recruitment: [],
      data_pv: [],
      data_save: [],
      data_visit: [],
      
      recruit_point1Title: '',
      recruit_point2Title: '',
      recruit_point3Title: '',

      // 数字で見る
      recruit_storeNumberTags: '',
      recruit_storeNumber1: '',
      recruit_storeNumber2: '',
      recruit_storeNumber3: '',
      recruit_storeNumber4: '',
      recruit_storeNumber5: '',
      recruit_storeNumber6: '',
      recruit_storeNumber7: '',
      recruit_storeNumber8: '',
      recruit_storeNumber9: '',
      recruit_storeNumber10: '',
      recruit_storeNumber11: '',
      recruit_storeNumber12: '',
      recruit_storeNumber13: '',

      // ステータス
      status: 3,

      status_store: false,
      status_place: false,
      status_point: false,
      status_number: false,
      status_image: false,
      status_interview: false,
      status_person: false,
      status_recruit: false,
      status_hashTags: false,
      status_document: false,

      recruit_imageCount: 3,
      recruit_image1Title: '',
      recruit_image1Desc: '',
      recruit_image1FilesName: [],
      recruit_image2Title: '',
      recruit_image2Desc: '',
      recruit_image2FilesName: [],
      recruit_image3Title: '',
      recruit_image3Desc: '',
      recruit_image3FilesName: [],

      recruit_interviewCount: 0,
      recruit_interview1Image: false,
      recruit_interview1Name: '',
      recruit_interview1Title: '',
      recruit_interview1Desc: '',
      recruit_interview2Image: false,
      recruit_interview2Name: '',
      recruit_interview2Title: '',
      recruit_interview2Desc: '',
      recruit_interview3Image: false,
      recruit_interview3Name: '',
      recruit_interview3Title: '',
      recruit_interview3Desc: '',
      recruit_interview4Image: false,
      recruit_interview4Name: '',
      recruit_interview4Title: '',
      recruit_interview4Desc: '',
      recruit_interview5Image: false,
      recruit_interview5Name: '',
      recruit_interview5Title: '',
      recruit_interview5Desc: '',
      recruit_interview6Image: false,
      recruit_interview6Name: '',
      recruit_interview6Title: '',
      recruit_interview6Desc: '',

      recruit_person1: 1,
      recruit_person2: 1,
      recruit_person3: 1,
      recruit_person4: 1,
      recruit_person5: 1,

      recruit_hashTag1: [],
      recruit_hashTag2: [],
      recruit_hashTag3: [],
      
      document_visit: false,
      document_document: false,
      
      document_resume: false,
      document_question: false,
      document_otherDocument: false,
      document_coordinate: false,

      document_questionCount: 0,
      document_question01: '',
      document_question02: '',
      document_question03: '',

      document_otherDocumentCount: 0,
      document_otherDocument01: '',
      document_otherDocument02: '',
      document_otherDocument03: '',

      // 店舗情報 > 情報
      store_imageName: [],

      // 求人
      recruit_employmentStatus: '新卒採用（正社員）',
      recruit_post: '',
      recruit_salary: '',
      recruit_annualSalaryStart: 0,
      recruit_annualSalaryEnd: 0,
      recruit_annualIncomeStart: 0,
      recruit_annualIncomeEnd: 0,
      recruit_monthlySalaryStart: 0,
      recruit_monthlySalaryEnd: 0,
      recruit_dailyWageStart: 0,
      recruit_dailyWageEnd: 0,
      recruit_hourWageStart: 0,
      recruit_hourWageEnd: 0,
      recruit_successFeeStart: 0,
      recruit_successFeeEnd: 0,
      recruit_workingHoursStart: '',
      recruit_workingHoursEnd: '',
      recruit_successFee: '',
      recruit_overtime: false,
      recruit_overtimeOther: '',
      recruit_holiday: [],
      recruit_holidayCheck: false,
      recruit_holidayOther: '',
      recruit_welfare: [],
      recruit_welfareCheck: false,
      recruit_welfareOther: '',
      recruit_salaryWelfare: '',
      
      recruit_personStatue: '',
      recruit_workContents: '',

      // 評価
      exam_selection: 0,

      exam_selection1Count: 0,
      exam_selection1Contents: '',
      exam_selection1Text1: '',
      exam_selection1Text2: '',
      exam_selection1Text3: '',
      exam_selection1Text4: '',
      exam_selection1Text5: '',
      exam_selection1Text6: '',
      exam_selection1Text7: '',
      exam_selection1Text8: '',
      exam_selection2Count: '',
      exam_selection2Contents: '',
      exam_selection2Text1: '',
      exam_selection2Text2: '',
      exam_selection2Text3: '',
      exam_selection2Text4: '',
      exam_selection2Text5: '',
      exam_selection2Text6: '',
      exam_selection2Text7: '',
      exam_selection2Text8: '',
      exam_selection3Count: '',
      exam_selection3Contents: '',
      exam_selection3Text1: '',
      exam_selection3Text2: '',
      exam_selection3Text3: '',
      exam_selection3Text4: '',
      exam_selection3Text5: '',
      exam_selection3Text6: '',
      exam_selection3Text7: '',
      exam_selection3Text8: '',
      exam_selection4Count: '',
      exam_selection4Contents: '',
      exam_selection4Text1: '',
      exam_selection4Text2: '',
      exam_selection4Text3: '',
      exam_selection4Text4: '',
      exam_selection4Text5: '',
      exam_selection4Text6: '',
      exam_selection4Text7: '',
      exam_selection4Text8: '',
      exam_selection5Count: '',
      exam_selection5Contents: '',
      exam_selection5Text1: '',
      exam_selection5Text2: '',
      exam_selection5Text3: '',
      exam_selection5Text4: '',
      exam_selection5Text5: '',
      exam_selection5Text6: '',
      exam_selection5Text7: '',
      exam_selection5Text8: '',
    })
    
    firebase.firestore().collection('companies').doc(company.id).update({ recruits: firebase.firestore.FieldValue.arrayUnion(docId) })
    .then(() => {
      dispatch(fetchRecruits(company.id));
      setMakeDoc(0);
    });

  }

  var text1 = [
    '★高給与と手厚い保証★初任給22万円～【全国253店舗】',
    '【24卒選考スタート！】プライベートも充実できるサロン★',
    'しっかり学んで休んで早期デビュー◎【月給２2万～＋週休２日】',
    '新店舗オープン！アカデミー/メイク着付け/給与22万社保完備',
    '【限定3名募集】池袋★カラー/エクステ/ブリーチに特化！',
    '【原宿・吉祥寺】デザインカラーに特化したスペシャリスト集団！',
    'デビュー1年以内★営業内練習！社保完備！ウィッグ2体支給',
    '★24年卒★「都心に近い地域密着サロン」で美容師しませんか？',
  ];

  return (
    <>
    <div className={Styles.modal}>
      <div>
        <div className={Styles.modal_black} onClick={() => setMakeDoc(0)}></div>
        <div className={Styles.modal_contents}>
          <div className={Styles.modal_contents_title}>
            <h4>求人タイトルを入力する</h4>
            <button onClick={() => recruitTitle === '' ? setErrorRecruitTitle(true) : make()}>作成</button>
          </div>
          <p>{errorRecruitTitle ? 
            <span style={recruitTitle === '' ? { color : '#AD0200', fontWeight : 'bold' } : {}}
          >{recruitTitle === '' ? '※求人タイトルを入力してください。' : '求人タイトルは30文字以内で入力してください。'}</span> : 'ステップ 1/1'} </p>
          <DefaultInput1
            title={'求人タイトル'} required={true} length={30} place={'例）美容業界を変えたい人を募集！'} type={'text'}
            value={recruitTitle} setValue={setRecruitTitle}
            desc={'　'}
          />
          
          <div className={Styles.example}>
            <div className={Styles.example_title}>求人タイトルの参考例</div>
            <div className={Styles.example_contents}>
              {text1.map((data, index) => (
                <div>
                  <p>{data}</p>
                  <button onClick={() => setRecruitTitle(data)}>使用する</button>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

export default RecruitMake;


