import Styles from './styles.module.css';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAnalytics } from '../../../features/1.home/analytics';
import { getRecruitDate } from '../../../features/1.home/analytics';

const HomeAnalytics: React.FC = () => {

  const dispatch = useDispatch();

  const recruitIds = useSelector((state: any) => state.recruit.recruitIds);

  const [ standardDate, setStandardDate ] = useState<string[]>([]);
  const [ period, setPeriod ] = useState(false);
  const [ choiceRecruit, setChoiceRecruit ] = useState(1);

  const analyticsPvCount = useSelector((state: any) => state.analytics.analyticsPvCount);
  const analyticsSaveCount = useSelector((state: any) => state.analytics.analyticsSaveCount);
  const analyticsApplyCount = useSelector((state: any) => state.analytics.analyticsApplyCount);
  const analyticsMemory1 = useSelector((state: any) => state.analytics.analyticsMemory1);
  const analyticsMemory2 = useSelector((state: any) => state.analytics.analyticsMemory2);
  const analyticsMemory3 = useSelector((state: any) => state.analytics.analyticsMemory3);

  useEffect(() => {
    const fetchData = async () => {
      
      const fetchAnalyticsResult = await dispatch(fetchAnalytics(recruitIds));

      if (fetchAnalytics.fulfilled.match(fetchAnalyticsResult)) {
        const standardDate = fetchAnalyticsResult.payload;

        const actionResult = await dispatch(getRecruitDate({ 
          recruitList: recruitIds, 
          date: standardDate 
        }));
      
        if (getRecruitDate.fulfilled.match(actionResult)) {
          const { pvTmp, saveTmp, applyTmp, date } = actionResult.payload;
          setStandardDate(date);
        }
      }
    }

    fetchData();
  }, [ dispatch, recruitIds ]);

  return (
    <>
    <div className={Styles.home_progress}>
      <div>
        <h4 style={period ? {borderBottom : '3px solid #AD0200', color: '#222222'} : {}} onClick={() => setPeriod(true)}>1ヶ月</h4>
        <h4 style={!period ? {borderBottom : '3px solid #AD0200', color: '#222222'} : {}} onClick={() => setPeriod(false)}>1週間</h4>
      </div>
    </div>
    
    <div className={Styles.analytics}>
      <div className={Styles.analytics_title}>
        <h4>求人のアナリティクス</h4>
        <div>
          <div className={Styles.analytics_tabs}
            onClick={() => {setChoiceRecruit(1);}} style={{opacity: choiceRecruit !== 1 ? '.3' : '1'}}>
            <div style={{backgroundColor : '#D14F60'}}></div>
            <p>PV数</p>
          </div>
          <div className={Styles.analytics_tabs}
            onClick={() => {setChoiceRecruit(2);}} style={{opacity: choiceRecruit !== 2 ? '.3' : '1'}}>
            <div style={{backgroundColor : '#59C47C'}}></div>
            <p>保存数</p>
          </div>
          <div className={Styles.analytics_tabs}
            onClick={() => {setChoiceRecruit(3);}} style={{opacity: choiceRecruit !== 3 ? '.3' : '1'}}>
            <div style={{backgroundColor : '#FCDC4A'}}></div>
            <p>応募数</p>
          </div>
        </div>
      </div>
      <div className={Styles.analytics_date}>
        <div className={Styles.analytics_date_unit}>
          <div>
            {choiceRecruit === 1 ?
            <>
              <p>{analyticsMemory1}</p>
              <p>{analyticsMemory1 / 6 * 5}</p>
              <p>{analyticsMemory1 / 6 * 4}</p>
              <p>{analyticsMemory1 / 6 * 3}</p>
              <p>{analyticsMemory1 / 6 * 2}</p>
              <p>{analyticsMemory1 / 6}</p>
            </>
            : choiceRecruit === 2 ?
            <>
              <p>{analyticsMemory2}</p>
              <p>{analyticsMemory2 / 6 * 5}</p>
              <p>{analyticsMemory2 / 6 * 4}</p>
              <p>{analyticsMemory2 / 6 * 3}</p>
              <p>{analyticsMemory2 / 6 * 2}</p>
              <p>{analyticsMemory2 / 6}</p>
            </>
            :
            <>
              <p>{analyticsMemory3}</p>
              <p>{analyticsMemory3 / 6 * 5}</p>
              <p>{analyticsMemory3 / 6 * 4}</p>
              <p>{analyticsMemory3 / 6 * 3}</p>
              <p>{analyticsMemory3 / 6 * 2}</p>
              <p>{analyticsMemory3 / 6}</p>
            </>
            }
            <p>0</p>
          </div>
        </div>
        <div className={Styles.analytics_date_graph}>
          {standardDate.length !== 0 ?
          <>
            {period ?
            <>
              {/* データ：1年 */}
              <div className={Styles.analytics_date_graph_year}>
                <div style={{height : `calc(300px / 
                    ${choiceRecruit === 1 ? analyticsMemory1 : choiceRecruit === 2 ? analyticsMemory2 : analyticsMemory3} * 
                    ${choiceRecruit === 1 ? analyticsPvCount[28] : choiceRecruit === 2 ? analyticsSaveCount[28] : analyticsApplyCount[28]})`,
                  marginTop : `calc(300px - 300px / 
                    ${choiceRecruit === 1 ? analyticsMemory1 : choiceRecruit === 2 ? analyticsMemory2 : analyticsMemory3} * 
                    ${choiceRecruit === 1 ? analyticsPvCount[28] : choiceRecruit === 2 ? analyticsSaveCount[28] : analyticsApplyCount[28]})`,
                  backgroundColor : choiceRecruit === 1 ? '#D14F60' : choiceRecruit === 2 ? '#59C47C' : '#FCDC4A',}}></div>

                <div style={{height : `calc(300px / 
                    ${choiceRecruit === 1 ? analyticsMemory1 : choiceRecruit === 2 ? analyticsMemory2 : analyticsMemory3} * 
                    ${choiceRecruit === 1 ? analyticsPvCount[27] : choiceRecruit === 2 ? analyticsSaveCount[27] : analyticsApplyCount[27]})`,
                  marginTop : `calc(300px - 300px / 
                    ${choiceRecruit === 1 ? analyticsMemory1 : choiceRecruit === 2 ? analyticsMemory2 : analyticsMemory3} * 
                    ${choiceRecruit === 1 ? analyticsPvCount[27] : choiceRecruit === 2 ? analyticsSaveCount[27] : analyticsApplyCount[27]})`,
                  backgroundColor : choiceRecruit === 1 ? '#D14F60' : choiceRecruit === 2 ? '#59C47C' : '#FCDC4A',}}></div>

                <div style={{height : `calc(300px / 
                    ${choiceRecruit === 1 ? analyticsMemory1 : choiceRecruit === 2 ? analyticsMemory2 : analyticsMemory3} * 
                    ${choiceRecruit === 1 ? analyticsPvCount[26] : choiceRecruit === 2 ? analyticsSaveCount[26] : analyticsApplyCount[26]})`,
                  marginTop : `calc(300px - 300px / 
                    ${choiceRecruit === 1 ? analyticsMemory1 : choiceRecruit === 2 ? analyticsMemory2 : analyticsMemory3} * 
                    ${choiceRecruit === 1 ? analyticsPvCount[26] : choiceRecruit === 2 ? analyticsSaveCount[26] : analyticsApplyCount[26]})`,
                  backgroundColor : choiceRecruit === 1 ? '#D14F60' : choiceRecruit === 2 ? '#59C47C' : '#FCDC4A',}}></div>

                <div style={{height : `calc(300px / 
                    ${choiceRecruit === 1 ? analyticsMemory1 : choiceRecruit === 2 ? analyticsMemory2 : analyticsMemory3} * 
                    ${choiceRecruit === 1 ? analyticsPvCount[25] : choiceRecruit === 2 ? analyticsSaveCount[25] : analyticsApplyCount[25]})`,
                  marginTop : `calc(300px - 300px / 
                    ${choiceRecruit === 1 ? analyticsMemory1 : choiceRecruit === 2 ? analyticsMemory2 : analyticsMemory3} * 
                    ${choiceRecruit === 1 ? analyticsPvCount[25] : choiceRecruit === 2 ? analyticsSaveCount[25] : analyticsApplyCount[25]})`,
                  backgroundColor : choiceRecruit === 1 ? '#D14F60' : choiceRecruit === 2 ? '#59C47C' : '#FCDC4A',}}></div>

                <div style={{height : `calc(300px / 
                    ${choiceRecruit === 1 ? analyticsMemory1 : choiceRecruit === 2 ? analyticsMemory2 : analyticsMemory3} * 
                    ${choiceRecruit === 1 ? analyticsPvCount[24] : choiceRecruit === 2 ? analyticsSaveCount[24] : analyticsApplyCount[24]})`,
                  marginTop : `calc(300px - 300px / 
                    ${choiceRecruit === 1 ? analyticsMemory1 : choiceRecruit === 2 ? analyticsMemory2 : analyticsMemory3} * 
                    ${choiceRecruit === 1 ? analyticsPvCount[24] : choiceRecruit === 2 ? analyticsSaveCount[24] : analyticsApplyCount[24]})`,
                  backgroundColor : choiceRecruit === 1 ? '#D14F60' : choiceRecruit === 2 ? '#59C47C' : '#FCDC4A',}}></div>

                <div style={{height : `calc(300px / 
                    ${choiceRecruit === 1 ? analyticsMemory1 : choiceRecruit === 2 ? analyticsMemory2 : analyticsMemory3} * 
                    ${choiceRecruit === 1 ? analyticsPvCount[23] : choiceRecruit === 2 ? analyticsSaveCount[23] : analyticsApplyCount[23]})`,
                  marginTop : `calc(300px - 300px / 
                    ${choiceRecruit === 1 ? analyticsMemory1 : choiceRecruit === 2 ? analyticsMemory2 : analyticsMemory3} * 
                    ${choiceRecruit === 1 ? analyticsPvCount[23] : choiceRecruit === 2 ? analyticsSaveCount[23] : analyticsApplyCount[23]})`,
                  backgroundColor : choiceRecruit === 1 ? '#D14F60' : choiceRecruit === 2 ? '#59C47C' : '#FCDC4A',}}></div>

                <div style={{height : `calc(300px / 
                    ${choiceRecruit === 1 ? analyticsMemory1 : choiceRecruit === 2 ? analyticsMemory2 : analyticsMemory3} * 
                    ${choiceRecruit === 1 ? analyticsPvCount[22] : choiceRecruit === 2 ? analyticsSaveCount[22] : analyticsApplyCount[22]})`,
                  marginTop : `calc(300px - 300px / 
                    ${choiceRecruit === 1 ? analyticsMemory1 : choiceRecruit === 2 ? analyticsMemory2 : analyticsMemory3} * 
                    ${choiceRecruit === 1 ? analyticsPvCount[22] : choiceRecruit === 2 ? analyticsSaveCount[22] : analyticsApplyCount[22]})`,
                  backgroundColor : choiceRecruit === 1 ? '#D14F60' : choiceRecruit === 2 ? '#59C47C' : '#FCDC4A',}}></div>

                <div style={{height : `calc(300px / 
                    ${choiceRecruit === 1 ? analyticsMemory1 : choiceRecruit === 2 ? analyticsMemory2 : analyticsMemory3} * 
                    ${choiceRecruit === 1 ? analyticsPvCount[21] : choiceRecruit === 2 ? analyticsSaveCount[21] : analyticsApplyCount[21]})`,
                  marginTop : `calc(300px - 300px / 
                    ${choiceRecruit === 1 ? analyticsMemory1 : choiceRecruit === 2 ? analyticsMemory2 : analyticsMemory3} * 
                    ${choiceRecruit === 1 ? analyticsPvCount[21] : choiceRecruit === 2 ? analyticsSaveCount[21] : analyticsApplyCount[21]})`,
                  backgroundColor : choiceRecruit === 1 ? '#D14F60' : choiceRecruit === 2 ? '#59C47C' : '#FCDC4A',}}></div>

                <div style={{height : `calc(300px / 
                    ${choiceRecruit === 1 ? analyticsMemory1 : choiceRecruit === 2 ? analyticsMemory2 : analyticsMemory3} * 
                    ${choiceRecruit === 1 ? analyticsPvCount[20] : choiceRecruit === 2 ? analyticsSaveCount[20] : analyticsApplyCount[20]})`,
                  marginTop : `calc(300px - 300px / 
                    ${choiceRecruit === 1 ? analyticsMemory1 : choiceRecruit === 2 ? analyticsMemory2 : analyticsMemory3} * 
                    ${choiceRecruit === 1 ? analyticsPvCount[20] : choiceRecruit === 2 ? analyticsSaveCount[20] : analyticsApplyCount[20]})`,
                  backgroundColor : choiceRecruit === 1 ? '#D14F60' : choiceRecruit === 2 ? '#59C47C' : '#FCDC4A',}}></div>

                <div style={{height : `calc(300px / 
                    ${choiceRecruit === 1 ? analyticsMemory1 : choiceRecruit === 2 ? analyticsMemory2 : analyticsMemory3} * 
                    ${choiceRecruit === 1 ? analyticsPvCount[19] : choiceRecruit === 2 ? analyticsSaveCount[19] : analyticsApplyCount[19]})`,
                  marginTop : `calc(300px - 300px / 
                    ${choiceRecruit === 1 ? analyticsMemory1 : choiceRecruit === 2 ? analyticsMemory2 : analyticsMemory3} * 
                    ${choiceRecruit === 1 ? analyticsPvCount[19] : choiceRecruit === 2 ? analyticsSaveCount[19] : analyticsApplyCount[19]})`,
                  backgroundColor : choiceRecruit === 1 ? '#D14F60' : choiceRecruit === 2 ? '#59C47C' : '#FCDC4A',}}></div>

                <div style={{height : `calc(300px / 
                    ${choiceRecruit === 1 ? analyticsMemory1 : choiceRecruit === 2 ? analyticsMemory2 : analyticsMemory3} * 
                    ${choiceRecruit === 1 ? analyticsPvCount[18] : choiceRecruit === 2 ? analyticsSaveCount[18] : analyticsApplyCount[18]})`,
                  marginTop : `calc(300px - 300px / 
                    ${choiceRecruit === 1 ? analyticsMemory1 : choiceRecruit === 2 ? analyticsMemory2 : analyticsMemory3} * 
                    ${choiceRecruit === 1 ? analyticsPvCount[18] : choiceRecruit === 2 ? analyticsSaveCount[18] : analyticsApplyCount[18]})`,
                  backgroundColor : choiceRecruit === 1 ? '#D14F60' : choiceRecruit === 2 ? '#59C47C' : '#FCDC4A',}}></div>

                <div style={{height : `calc(300px / 
                    ${choiceRecruit === 1 ? analyticsMemory1 : choiceRecruit === 2 ? analyticsMemory2 : analyticsMemory3} * 
                    ${choiceRecruit === 1 ? analyticsPvCount[17] : choiceRecruit === 2 ? analyticsSaveCount[17] : analyticsApplyCount[17]})`,
                  marginTop : `calc(300px - 300px / 
                    ${choiceRecruit === 1 ? analyticsMemory1 : choiceRecruit === 2 ? analyticsMemory2 : analyticsMemory3} * 
                    ${choiceRecruit === 1 ? analyticsPvCount[17] : choiceRecruit === 2 ? analyticsSaveCount[17] : analyticsApplyCount[17]})`,
                  backgroundColor : choiceRecruit === 1 ? '#D14F60' : choiceRecruit === 2 ? '#59C47C' : '#FCDC4A',}}></div>

                <div style={{height : `calc(300px / 
                    ${choiceRecruit === 1 ? analyticsMemory1 : choiceRecruit === 2 ? analyticsMemory2 : analyticsMemory3} * 
                    ${choiceRecruit === 1 ? analyticsPvCount[16] : choiceRecruit === 2 ? analyticsSaveCount[16] : analyticsApplyCount[16]})`,
                  marginTop : `calc(300px - 300px / 
                    ${choiceRecruit === 1 ? analyticsMemory1 : choiceRecruit === 2 ? analyticsMemory2 : analyticsMemory3} * 
                    ${choiceRecruit === 1 ? analyticsPvCount[16] : choiceRecruit === 2 ? analyticsSaveCount[16] : analyticsApplyCount[16]})`,
                  backgroundColor : choiceRecruit === 1 ? '#D14F60' : choiceRecruit === 2 ? '#59C47C' : '#FCDC4A',}}></div>

                <div style={{height : `calc(300px / 
                    ${choiceRecruit === 1 ? analyticsMemory1 : choiceRecruit === 2 ? analyticsMemory2 : analyticsMemory3} * 
                    ${choiceRecruit === 1 ? analyticsPvCount[15] : choiceRecruit === 2 ? analyticsSaveCount[15] : analyticsApplyCount[15]})`,
                  marginTop : `calc(300px - 300px / 
                    ${choiceRecruit === 1 ? analyticsMemory1 : choiceRecruit === 2 ? analyticsMemory2 : analyticsMemory3} * 
                    ${choiceRecruit === 1 ? analyticsPvCount[15] : choiceRecruit === 2 ? analyticsSaveCount[15] : analyticsApplyCount[15]})`,
                  backgroundColor : choiceRecruit === 1 ? '#D14F60' : choiceRecruit === 2 ? '#59C47C' : '#FCDC4A',}}></div>

                <div style={{height : `calc(300px / 
                    ${choiceRecruit === 1 ? analyticsMemory1 : choiceRecruit === 2 ? analyticsMemory2 : analyticsMemory3} * 
                    ${choiceRecruit === 1 ? analyticsPvCount[14] : choiceRecruit === 2 ? analyticsSaveCount[14] : analyticsApplyCount[14]})`,
                  marginTop : `calc(300px - 300px / 
                    ${choiceRecruit === 1 ? analyticsMemory1 : choiceRecruit === 2 ? analyticsMemory2 : analyticsMemory3} * 
                    ${choiceRecruit === 1 ? analyticsPvCount[14] : choiceRecruit === 2 ? analyticsSaveCount[14] : analyticsApplyCount[14]})`,
                  backgroundColor : choiceRecruit === 1 ? '#D14F60' : choiceRecruit === 2 ? '#59C47C' : '#FCDC4A',}}></div>

                <div style={{height : `calc(300px / 
                    ${choiceRecruit === 1 ? analyticsMemory1 : choiceRecruit === 2 ? analyticsMemory2 : analyticsMemory3} * 
                    ${choiceRecruit === 1 ? analyticsPvCount[13] : choiceRecruit === 2 ? analyticsSaveCount[13] : analyticsApplyCount[13]})`,
                  marginTop : `calc(300px - 300px / 
                    ${choiceRecruit === 1 ? analyticsMemory1 : choiceRecruit === 2 ? analyticsMemory2 : analyticsMemory3} * 
                    ${choiceRecruit === 1 ? analyticsPvCount[13] : choiceRecruit === 2 ? analyticsSaveCount[13] : analyticsApplyCount[13]})`,
                  backgroundColor : choiceRecruit === 1 ? '#D14F60' : choiceRecruit === 2 ? '#59C47C' : '#FCDC4A',}}></div>

                <div style={{height : `calc(300px / 
                    ${choiceRecruit === 1 ? analyticsMemory1 : choiceRecruit === 2 ? analyticsMemory2 : analyticsMemory3} * 
                    ${choiceRecruit === 1 ? analyticsPvCount[12] : choiceRecruit === 2 ? analyticsSaveCount[12] : analyticsApplyCount[12]})`,
                  marginTop : `calc(300px - 300px / 
                    ${choiceRecruit === 1 ? analyticsMemory1 : choiceRecruit === 2 ? analyticsMemory2 : analyticsMemory3} * 
                    ${choiceRecruit === 1 ? analyticsPvCount[12] : choiceRecruit === 2 ? analyticsSaveCount[12] : analyticsApplyCount[12]})`,
                  backgroundColor : choiceRecruit === 1 ? '#D14F60' : choiceRecruit === 2 ? '#59C47C' : '#FCDC4A',}}></div>

                <div style={{height : `calc(300px / 
                    ${choiceRecruit === 1 ? analyticsMemory1 : choiceRecruit === 2 ? analyticsMemory2 : analyticsMemory3} * 
                    ${choiceRecruit === 1 ? analyticsPvCount[11] : choiceRecruit === 2 ? analyticsSaveCount[11] : analyticsApplyCount[11]})`,
                  marginTop : `calc(300px - 300px / 
                    ${choiceRecruit === 1 ? analyticsMemory1 : choiceRecruit === 2 ? analyticsMemory2 : analyticsMemory3} * 
                    ${choiceRecruit === 1 ? analyticsPvCount[11] : choiceRecruit === 2 ? analyticsSaveCount[11] : analyticsApplyCount[11]})`,
                  backgroundColor : choiceRecruit === 1 ? '#D14F60' : choiceRecruit === 2 ? '#59C47C' : '#FCDC4A',}}></div>

                <div style={{height : `calc(300px / 
                    ${choiceRecruit === 1 ? analyticsMemory1 : choiceRecruit === 2 ? analyticsMemory2 : analyticsMemory3} * 
                    ${choiceRecruit === 1 ? analyticsPvCount[10] : choiceRecruit === 2 ? analyticsSaveCount[10] : analyticsApplyCount[10]})`,
                  marginTop : `calc(300px - 300px / 
                    ${choiceRecruit === 1 ? analyticsMemory1 : choiceRecruit === 2 ? analyticsMemory2 : analyticsMemory3} * 
                    ${choiceRecruit === 1 ? analyticsPvCount[10] : choiceRecruit === 2 ? analyticsSaveCount[10] : analyticsApplyCount[10]})`,
                  backgroundColor : choiceRecruit === 1 ? '#D14F60' : choiceRecruit === 2 ? '#59C47C' : '#FCDC4A',}}></div>

                <div style={{height : `calc(300px / 
                    ${choiceRecruit === 1 ? analyticsMemory1 : choiceRecruit === 2 ? analyticsMemory2 : analyticsMemory3} * 
                    ${choiceRecruit === 1 ? analyticsPvCount[9] : choiceRecruit === 2 ? analyticsSaveCount[9] : analyticsApplyCount[9]})`,
                  marginTop : `calc(300px - 300px / 
                    ${choiceRecruit === 1 ? analyticsMemory1 : choiceRecruit === 2 ? analyticsMemory2 : analyticsMemory3} * 
                    ${choiceRecruit === 1 ? analyticsPvCount[9] : choiceRecruit === 2 ? analyticsSaveCount[9] : analyticsApplyCount[9]})`,
                  backgroundColor : choiceRecruit === 1 ? '#D14F60' : choiceRecruit === 2 ? '#59C47C' : '#FCDC4A',}}></div>

                <div style={{height : `calc(300px / 
                    ${choiceRecruit === 1 ? analyticsMemory1 : choiceRecruit === 2 ? analyticsMemory2 : analyticsMemory3} * 
                    ${choiceRecruit === 1 ? analyticsPvCount[8] : choiceRecruit === 2 ? analyticsSaveCount[8] : analyticsApplyCount[8]})`,
                  marginTop : `calc(300px - 300px / 
                    ${choiceRecruit === 1 ? analyticsMemory1 : choiceRecruit === 2 ? analyticsMemory2 : analyticsMemory3} * 
                    ${choiceRecruit === 1 ? analyticsPvCount[8] : choiceRecruit === 2 ? analyticsSaveCount[8] : analyticsApplyCount[8]})`,
                  backgroundColor : choiceRecruit === 1 ? '#D14F60' : choiceRecruit === 2 ? '#59C47C' : '#FCDC4A',}}></div>

                <div style={{height : `calc(300px / 
                    ${choiceRecruit === 1 ? analyticsMemory1 : choiceRecruit === 2 ? analyticsMemory2 : analyticsMemory3} * 
                    ${choiceRecruit === 1 ? analyticsPvCount[8] : choiceRecruit === 2 ? analyticsSaveCount[8] : analyticsApplyCount[8]})`,
                  marginTop : `calc(300px - 300px / 
                    ${choiceRecruit === 1 ? analyticsMemory1 : choiceRecruit === 2 ? analyticsMemory2 : analyticsMemory3} * 
                    ${choiceRecruit === 1 ? analyticsPvCount[8] : choiceRecruit === 2 ? analyticsSaveCount[8] : analyticsApplyCount[8]})`,
                  backgroundColor : choiceRecruit === 1 ? '#D14F60' : choiceRecruit === 2 ? '#59C47C' : '#FCDC4A',}}></div>

                <div style={{height : `calc(300px / 
                    ${choiceRecruit === 1 ? analyticsMemory1 : choiceRecruit === 2 ? analyticsMemory2 : analyticsMemory3} * 
                    ${choiceRecruit === 1 ? analyticsPvCount[7] : choiceRecruit === 2 ? analyticsSaveCount[7] : analyticsApplyCount[7]})`,
                  marginTop : `calc(300px - 300px / 
                    ${choiceRecruit === 1 ? analyticsMemory1 : choiceRecruit === 2 ? analyticsMemory2 : analyticsMemory3} * 
                    ${choiceRecruit === 1 ? analyticsPvCount[7] : choiceRecruit === 2 ? analyticsSaveCount[7] : analyticsApplyCount[7]})`,
                  backgroundColor : choiceRecruit === 1 ? '#D14F60' : choiceRecruit === 2 ? '#59C47C' : '#FCDC4A',}}></div>

                <div style={{height : `calc(300px / 
                    ${choiceRecruit === 1 ? analyticsMemory1 : choiceRecruit === 2 ? analyticsMemory2 : analyticsMemory3} * 
                    ${choiceRecruit === 1 ? analyticsPvCount[6] : choiceRecruit === 2 ? analyticsSaveCount[6] : analyticsApplyCount[6]})`,
                  marginTop : `calc(300px - 300px / 
                    ${choiceRecruit === 1 ? analyticsMemory1 : choiceRecruit === 2 ? analyticsMemory2 : analyticsMemory3} * 
                    ${choiceRecruit === 1 ? analyticsPvCount[6] : choiceRecruit === 2 ? analyticsSaveCount[6] : analyticsApplyCount[6]})`,
                  backgroundColor : choiceRecruit === 1 ? '#D14F60' : choiceRecruit === 2 ? '#59C47C' : '#FCDC4A',}}></div>

                <div style={{height : `calc(300px / 
                    ${choiceRecruit === 1 ? analyticsMemory1 : choiceRecruit === 2 ? analyticsMemory2 : analyticsMemory3} * 
                    ${choiceRecruit === 1 ? analyticsPvCount[5] : choiceRecruit === 2 ? analyticsSaveCount[5] : analyticsApplyCount[5]})`,
                  marginTop : `calc(300px - 300px / 
                    ${choiceRecruit === 1 ? analyticsMemory1 : choiceRecruit === 2 ? analyticsMemory2 : analyticsMemory3} * 
                    ${choiceRecruit === 1 ? analyticsPvCount[5] : choiceRecruit === 2 ? analyticsSaveCount[5] : analyticsApplyCount[5]})`,
                  backgroundColor : choiceRecruit === 1 ? '#D14F60' : choiceRecruit === 2 ? '#59C47C' : '#FCDC4A',}}></div>

                <div style={{height : `calc(300px / 
                    ${choiceRecruit === 1 ? analyticsMemory1 : choiceRecruit === 2 ? analyticsMemory2 : analyticsMemory3} * 
                    ${choiceRecruit === 1 ? analyticsPvCount[4] : choiceRecruit === 2 ? analyticsSaveCount[4] : analyticsApplyCount[4]})`,
                  marginTop : `calc(300px - 300px / 
                    ${choiceRecruit === 1 ? analyticsMemory1 : choiceRecruit === 2 ? analyticsMemory2 : analyticsMemory3} * 
                    ${choiceRecruit === 1 ? analyticsPvCount[4] : choiceRecruit === 2 ? analyticsSaveCount[4] : analyticsApplyCount[4]})`,
                  backgroundColor : choiceRecruit === 1 ? '#D14F60' : choiceRecruit === 2 ? '#59C47C' : '#FCDC4A',}}></div>

                <div style={{height : `calc(300px / 
                    ${choiceRecruit === 1 ? analyticsMemory1 : choiceRecruit === 2 ? analyticsMemory2 : analyticsMemory3} * 
                    ${choiceRecruit === 1 ? analyticsPvCount[3] : choiceRecruit === 2 ? analyticsSaveCount[3] : analyticsApplyCount[3]})`,
                  marginTop : `calc(300px - 300px / 
                    ${choiceRecruit === 1 ? analyticsMemory1 : choiceRecruit === 2 ? analyticsMemory2 : analyticsMemory3} * 
                    ${choiceRecruit === 1 ? analyticsPvCount[3] : choiceRecruit === 2 ? analyticsSaveCount[3] : analyticsApplyCount[3]})`,
                  backgroundColor : choiceRecruit === 1 ? '#D14F60' : choiceRecruit === 2 ? '#59C47C' : '#FCDC4A',}}></div>

                <div style={{height : `calc(300px / 
                    ${choiceRecruit === 1 ? analyticsMemory1 : choiceRecruit === 2 ? analyticsMemory2 : analyticsMemory3} * 
                    ${choiceRecruit === 1 ? analyticsPvCount[2] : choiceRecruit === 2 ? analyticsSaveCount[2] : analyticsApplyCount[2]})`,
                  marginTop : `calc(300px - 300px / 
                    ${choiceRecruit === 1 ? analyticsMemory1 : choiceRecruit === 2 ? analyticsMemory2 : analyticsMemory3} * 
                    ${choiceRecruit === 1 ? analyticsPvCount[2] : choiceRecruit === 2 ? analyticsSaveCount[2] : analyticsApplyCount[2]})`,
                  backgroundColor : choiceRecruit === 1 ? '#D14F60' : choiceRecruit === 2 ? '#59C47C' : '#FCDC4A',}}></div>

                <div style={{height : `calc(300px / 
                    ${choiceRecruit === 1 ? analyticsMemory1 : choiceRecruit === 2 ? analyticsMemory2 : analyticsMemory3} * 
                    ${choiceRecruit === 1 ? analyticsPvCount[1] : choiceRecruit === 2 ? analyticsSaveCount[1] : analyticsApplyCount[1]})`,
                  marginTop : `calc(300px - 300px / 
                    ${choiceRecruit === 1 ? analyticsMemory1 : choiceRecruit === 2 ? analyticsMemory2 : analyticsMemory3} * 
                    ${choiceRecruit === 1 ? analyticsPvCount[1] : choiceRecruit === 2 ? analyticsSaveCount[1] : analyticsApplyCount[1]})`,
                  backgroundColor : choiceRecruit === 1 ? '#D14F60' : choiceRecruit === 2 ? '#59C47C' : '#FCDC4A',}}></div>

                <div style={{height : `calc(300px / 
                    ${choiceRecruit === 1 ? analyticsMemory1 : choiceRecruit === 2 ? analyticsMemory2 : analyticsMemory3} * 
                    ${choiceRecruit === 1 ? analyticsPvCount[0] : choiceRecruit === 2 ? analyticsSaveCount[0] : analyticsApplyCount[0]})`,
                  marginTop : `calc(300px - 300px / 
                    ${choiceRecruit === 1 ? analyticsMemory1 : choiceRecruit === 2 ? analyticsMemory2 : analyticsMemory3} * 
                    ${choiceRecruit === 1 ? analyticsPvCount[0] : choiceRecruit === 2 ? analyticsSaveCount[0] : analyticsApplyCount[0]})`,
                  backgroundColor : choiceRecruit === 1 ? '#D14F60' : choiceRecruit === 2 ? '#59C47C' : '#FCDC4A',}}></div>
              </div>
            </> :
            <>
              {/* データ：1週間 */}
              <div className={Styles.analytics_date_graph_week}>
                <div style={{height : `calc(300px / 
                    ${choiceRecruit === 1 ? analyticsMemory1 : choiceRecruit === 2 ? analyticsMemory2 : analyticsMemory3} * 
                    ${choiceRecruit === 1 ? analyticsPvCount[6] : choiceRecruit === 2 ? analyticsSaveCount[6] : analyticsApplyCount[6]})`,
                  marginTop : `calc(300px - 300px / 
                    ${choiceRecruit === 1 ? analyticsMemory1 : choiceRecruit === 2 ? analyticsMemory2 : analyticsMemory3} * 
                    ${choiceRecruit === 1 ? analyticsPvCount[6] : choiceRecruit === 2 ? analyticsSaveCount[6] : analyticsApplyCount[6]})`,
                  backgroundColor : choiceRecruit === 1 ? '#D14F60' : choiceRecruit === 2 ? '#59C47C' : '#FCDC4A',}}></div>

                <div style={{height : `calc(300px / 
                    ${choiceRecruit === 1 ? analyticsMemory1 : choiceRecruit === 2 ? analyticsMemory2 : analyticsMemory3} * 
                    ${choiceRecruit === 1 ? analyticsPvCount[5] : choiceRecruit === 2 ? analyticsSaveCount[5] : analyticsApplyCount[5]})`,
                  marginTop : `calc(300px - 300px / 
                    ${choiceRecruit === 1 ? analyticsMemory1 : choiceRecruit === 2 ? analyticsMemory2 : analyticsMemory3} * 
                    ${choiceRecruit === 1 ? analyticsPvCount[5] : choiceRecruit === 2 ? analyticsSaveCount[5] : analyticsApplyCount[5]})`,
                  backgroundColor : choiceRecruit === 1 ? '#D14F60' : choiceRecruit === 2 ? '#59C47C' : '#FCDC4A',}}></div>

                <div style={{height : `calc(300px / 
                    ${choiceRecruit === 1 ? analyticsMemory1 : choiceRecruit === 2 ? analyticsMemory2 : analyticsMemory3} * 
                    ${choiceRecruit === 1 ? analyticsPvCount[4] : choiceRecruit === 2 ? analyticsSaveCount[4] : analyticsApplyCount[4]})`,
                  marginTop : `calc(300px - 300px / 
                    ${choiceRecruit === 1 ? analyticsMemory1 : choiceRecruit === 2 ? analyticsMemory2 : analyticsMemory3} * 
                    ${choiceRecruit === 1 ? analyticsPvCount[4] : choiceRecruit === 2 ? analyticsSaveCount[4] : analyticsApplyCount[4]})`,
                  backgroundColor : choiceRecruit === 1 ? '#D14F60' : choiceRecruit === 2 ? '#59C47C' : '#FCDC4A',}}></div>

                <div style={{height : `calc(300px / 
                    ${choiceRecruit === 1 ? analyticsMemory1 : choiceRecruit === 2 ? analyticsMemory2 : analyticsMemory3} * 
                    ${choiceRecruit === 1 ? analyticsPvCount[3] : choiceRecruit === 2 ? analyticsSaveCount[3] : analyticsApplyCount[3]})`,
                  marginTop : `calc(300px - 300px / 
                    ${choiceRecruit === 1 ? analyticsMemory1 : choiceRecruit === 2 ? analyticsMemory2 : analyticsMemory3} * 
                    ${choiceRecruit === 1 ? analyticsPvCount[3] : choiceRecruit === 2 ? analyticsSaveCount[3] : analyticsApplyCount[3]})`,
                  backgroundColor : choiceRecruit === 1 ? '#D14F60' : choiceRecruit === 2 ? '#59C47C' : '#FCDC4A',}}></div>

                <div style={{height : `calc(300px / 
                    ${choiceRecruit === 1 ? analyticsMemory1 : choiceRecruit === 2 ? analyticsMemory2 : analyticsMemory3} * 
                    ${choiceRecruit === 1 ? analyticsPvCount[2] : choiceRecruit === 2 ? analyticsSaveCount[2] : analyticsApplyCount[2]})`,
                  marginTop : `calc(300px - 300px / 
                    ${choiceRecruit === 1 ? analyticsMemory1 : choiceRecruit === 2 ? analyticsMemory2 : analyticsMemory3} * 
                    ${choiceRecruit === 1 ? analyticsPvCount[2] : choiceRecruit === 2 ? analyticsSaveCount[2] : analyticsApplyCount[2]})`,
                  backgroundColor : choiceRecruit === 1 ? '#D14F60' : choiceRecruit === 2 ? '#59C47C' : '#FCDC4A',}}></div>

                <div style={{height : `calc(300px / 
                    ${choiceRecruit === 1 ? analyticsMemory1 : choiceRecruit === 2 ? analyticsMemory2 : analyticsMemory3} * 
                    ${choiceRecruit === 1 ? analyticsPvCount[1] : choiceRecruit === 2 ? analyticsSaveCount[1] : analyticsApplyCount[1]})`,
                  marginTop : `calc(300px - 300px / 
                    ${choiceRecruit === 1 ? analyticsMemory1 : choiceRecruit === 2 ? analyticsMemory2 : analyticsMemory3} * 
                    ${choiceRecruit === 1 ? analyticsPvCount[1] : choiceRecruit === 2 ? analyticsSaveCount[1] : analyticsApplyCount[1]})`,
                  backgroundColor : choiceRecruit === 1 ? '#D14F60' : choiceRecruit === 2 ? '#59C47C' : '#FCDC4A',}}></div>

                <div style={{height : `calc(300px / 
                    ${choiceRecruit === 1 ? analyticsMemory1 : choiceRecruit === 2 ? analyticsMemory2 : analyticsMemory3} * 
                    ${choiceRecruit === 1 ? analyticsPvCount[0] : choiceRecruit === 2 ? analyticsSaveCount[0] : analyticsApplyCount[0]})`,
                  marginTop : `calc(300px - 300px / 
                    ${choiceRecruit === 1 ? analyticsMemory1 : choiceRecruit === 2 ? analyticsMemory2 : analyticsMemory3} * 
                    ${choiceRecruit === 1 ? analyticsPvCount[0] : choiceRecruit === 2 ? analyticsSaveCount[0] : analyticsApplyCount[0]})`,
                  backgroundColor : choiceRecruit === 1 ? '#D14F60' : choiceRecruit === 2 ? '#59C47C' : '#FCDC4A',}}></div>
              </div>
            </> }
          </> : <></> }

          {standardDate.length !== 0 ?
          <>
            {period ?
            // 期間：1年
            <div className={Styles.analytics_date_graph_year_bottom}>
              <p>{new Date(standardDate[29]).getDate() - 1}日</p>
              <p>{new Date(standardDate[28]).getDate() - 1}日</p>
              <p>{new Date(standardDate[27]).getDate() - 1}日</p>
              <p>{new Date(standardDate[26]).getDate() - 1}日</p>
              <p>{new Date(standardDate[25]).getDate() - 1}日</p>
              <p>{new Date(standardDate[24]).getDate() - 1}日</p>
              <p>{new Date(standardDate[23]).getDate() - 1}日</p>
              <p>{new Date(standardDate[22]).getDate() - 1}日</p>
              <p>{new Date(standardDate[21]).getDate() - 1}日</p>
              <p>{new Date(standardDate[20]).getDate() - 1}日</p>
              <p>{new Date(standardDate[19]).getDate() - 1}日</p>
              <p>{new Date(standardDate[18]).getDate() - 1}日</p>
              <p>{new Date(standardDate[17]).getDate() - 1}日</p>
              <p>{new Date(standardDate[16]).getDate() - 1}日</p>
              <p>{new Date(standardDate[15]).getDate() - 1}日</p>
              <p>{new Date(standardDate[14]).getDate() - 1}日</p>
              <p>{new Date(standardDate[13]).getDate() - 1}日</p>
              <p>{new Date(standardDate[12]).getDate() - 1}日</p>
              <p>{new Date(standardDate[11]).getDate() - 1}日</p>
              <p>{new Date(standardDate[10]).getDate() - 1}日</p>
              <p>{new Date(standardDate[9]).getDate() - 1}日</p>
              <p>{new Date(standardDate[8]).getDate() - 1}日</p>
              <p>{new Date(standardDate[7]).getDate() - 1}日</p>
              <p>{new Date(standardDate[6]).getDate() - 1}日</p>
              <p>{new Date(standardDate[5]).getDate() - 1}日</p>
              <p>{new Date(standardDate[4]).getDate() - 1}日</p>
              <p>{new Date(standardDate[3]).getDate() - 1}日</p>
              <p>{new Date(standardDate[2]).getDate() - 1}日</p>
              <p>{new Date(standardDate[1]).getDate() - 1}日</p>
              <p>{new Date(standardDate[0]).getDate() - 1}日</p>
            </div>
            :
            // 期間：1週間
            <div className={Styles.analytics_date_graph_week_bottom}>
              <p>{new Date(standardDate[6]).getDate() - 1}日</p>
              <p>{new Date(standardDate[5]).getDate() - 1}日</p>
              <p>{new Date(standardDate[4]).getDate() - 1}日</p>
              <p>{new Date(standardDate[3]).getDate() - 1}日</p>
              <p>{new Date(standardDate[2]).getDate() - 1}日</p>
              <p>{new Date(standardDate[1]).getDate() - 1}日</p>
              <p>{new Date(standardDate[0]).getDate() - 1}日</p>
            </div> }
          </> : <></> }
        </div>
      </div>
    </div>
    </>
  );
}

export default HomeAnalytics;
