import React, { useEffect, useState } from 'react';
import Styles from './styles.module.css';

type DefaultInput1Props = {
  title: string,
  required: Boolean,
  value: string| number,
  place: string,
  type: string,
  desc: string,
  length: number,
  setValue: React.Dispatch<React.SetStateAction<string | number>>;
};

const DefaultInput1: React.FC<DefaultInput1Props> = ({title, required, value, place, type, desc, length, setValue }) => {

  const [ stringLength, setStringLength ] = useState(0);

  useEffect(() => {
    if (value === 'text') {
      setStringLength(value.length);
    }
  }, [ ]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;

    // 数字入力の場合
    if (type === 'number' && !isNaN(Number(newValue))) {
      setValue(Number(newValue));
    }
    // テキスト入力の場合
    else if (type === 'text') {
      setValue(newValue);
      setStringLength(newValue.length);
    }
  };

  return (
    <>
    <div className={Styles.title}>
      <div>
        <p>{title}</p>
        <div className={required ? Styles.title_yes : Styles.title_no}>{required ? '※必須' : '任意'}</div>
      </div>
      {value === "number" ? <></> : <p>{stringLength} / {length}</p> }
      {/* {typeof value === "number" ? <></> : <p>{value.length === 0 ? '0' : value.length} / {length}</p> } */}
    </div>
    <input
      className={Styles.input}
      placeholder={place}
      maxLength={length}
      type={type === 'number' ? 'number' : 'text'}
      value={typeof value === 'number' ? value.toString() : value}
      onChange={handleChange} />
    <p className={Styles.desc}>{desc}</p>
    </>
  );
}

export default DefaultInput1;
