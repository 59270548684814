import Styles from './styles.module.css';
import React from 'react';
import { IoMailOutline } from "react-icons/io5";
import { useNavigate } from 'react-router-dom';
import Icon from "./../../image/logo.png";
import { isMobile } from "react-device-detect";

const AuthHeader: React.FC = () => {
  
  const navigate = useNavigate();

  return (
    <>
    <div className={Styles.box}>

      <div className={Styles.box_logo} onClick={() => navigate('/')}>
        <img src={Icon} alt='' />
        <h4>memorii works</h4>
      </div>

      <h4>美容室専用ページ</h4>

      {!isMobile ?
        <div className={Styles.box_link}>
          <div onClick={() => navigate('/contact')}>
            <IoMailOutline className={Styles.box_link_img} />
            <p>お問い合わせ</p>
          </div>
        </div>
        :
        <div className={Styles.box_link}>
          <div onClick={() => navigate('/contact')}>
            <p>お問い合わせ</p>
          </div>
        </div>
      }
      
    </div>
    </>
  );
}

export default AuthHeader;
