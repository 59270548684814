import Styles from './styles.module.css';
import React, { useState, useEffect } from 'react'
import { v4 as uuid } from 'uuid';
import firebase from 'firebase/compat/app'
import { useNavigate } from 'react-router-dom';
import liff from "@line/liff";

const Footer = () => {

  const [ company, setCompany ] = useState([]);

  useEffect(() => {

    firebase.firestore().collection('stores').orderBy("time", 'asc').get()
    .then(snapShot => {
      var tmp = [];
      snapShot.forEach((doc) => {
        tmp.push({...doc.data(), id: doc.id });
      });
      setCompany(tmp);
    });

  }, []);

  return (
    <>
    {company.length !== 0 ?
    <>
      {company.map((data, index) =>
      <>
        <p>{index + 1} ID：{data.id}、会社名：{data.name}：年月日：{new Date(data.time.seconds * 1000).getFullYear()}年{new Date(data.time.seconds * 1000).getMonth()+1}月{new Date(data.time.seconds * 1000).getDate()}日</p>
      </>
      )}
    </> : <></> }
    </>
  );
}

export default Footer;
