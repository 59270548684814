import authStyles from './styles.module.css';
import inputStyles from './input.module.css';
import firebase from 'firebase/compat/app';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../header/index';
import { FaEye } from "react-icons/fa";
import Modal from '../../package/parts/pop-modal/index';
import ErrorMessage from '../../package/parts/error/index';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCompanyState } from '../../features/0.main/company';

const AuthLogin: React.FC = () => {
   
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [ errorMessage, setErrorMessage ] = useState('');

  const [ errorAuth, setErrorAuth ] = useState(false);
  const [ errorId, setErrorId ] = useState(false);
  const [ errorNoneId, setErrorNoneId ] = useState(false);

  const [ worksId, setWorksId ] = useState('');
  const [ email, setEmail ] = useState('');
  const [ password, setPassword ] = useState('');

  const [ passwordEye, setPasswordEye ] = useState(false);

  function check() {
    setErrorAuth(false);
    setErrorId(false);
    setErrorNoneId(false);

    if (worksId === '') {
      setErrorMessage('会員IDを入力してください。');
    } else if (email.length <= 7) {
      setErrorMessage('メールアドレスを入力してください。');
    } else if (password.length <= 7) {
      setErrorMessage('パスワードを7文字以上入力してください。');
    } else {
      Register();
    }
  }

  const Register = async () => {
    await firebase.auth().signInWithEmailAndPassword(email, password)
    .then(() => {
      success(email);
    })
    .catch((error) => {
      setErrorAuth(true);
    });
  };

  async function success(email: string) {
    await firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        if (user.emailVerified) {
          companyAuth(user.uid, email);
        } else {
          navigate('/company/confirm');
        }
      }
    });
  }
  
  async function companyAuth(uid: string, email: string) {
    await firebase.firestore().collection('companies').where('member_id', '==', worksId).get()
    .then(snapShot => {
      if (snapShot.empty) {
        setErrorNoneId(true);
      } else {
        snapShot.forEach((docs) => {
          if (docs.exists) {
            // メールアドレス削除
            if (docs.data().invite.includes(email)) {
              firebase.firestore().collection('companies').doc(docs.id)
              .update({ 
                invite: firebase.firestore.FieldValue.arrayRemove(email),
                member: firebase.firestore.FieldValue.arrayUnion(uid),
              });
            }
  
            // アカウント有無
            if (docs.data().member.includes(uid) || docs.data().invite.includes(email)) {
              firebase.firestore().collection('users').doc(uid).get()
              .then((doc) => {
                if (doc.exists) {
                  dispatch(fetchCompanyState(docs.id));
                  navigate(`/service`);
                } else {
                  firebase.firestore().collection('users').doc(uid)
                  .set({
                    user_name: '',
                    user_like_count : 0,
                    user_award : 0,
                    user_likes : [],
                    user_text : '',
                    user_uid : uid,
                    user_createdAt : new Date(),
                    user_scout : false,
                    user_address : [],
                    user_address_postalCode: [],
                    user_badge: 0,
                    user_business: "美容師",
                    user_recruit: false,
                    user_token: "",
                    user_connection : [],
                    user_connection_count : 0,
                    user_connection_permission : [],
                    user_connection_request : [],
                    user_likes_list : [],
                    user_login : '',
                    user_login_count : [],
                    user_notification : [],
                    user_notification_confiram : false,
                    user_post_like_count : 0,
                    user_post_view_count : 0,
                    user_views : '',
                    user_post_count : 0,
                    user_recruit_save: [],
                    user_instagram : '',
                    user_tiktok : '',
                    memorii_score : 0,
                  }).then(() => {
                    dispatch(fetchCompanyState(docs.id));
                  });
                }
              });
            } else if (!docs.data().member.includes(uid) && !docs.data().invite.includes(email)) {
              setErrorId(true);
            }
          } else {
            setErrorId(true);
          }
        });
      }
    })
    .catch((error) => {
      setErrorAuth(true);
    });
  }

  return (
    <>
    <Header />

    <div className={authStyles.background}>

      {errorMessage !== '' ?
        <ErrorMessage message={errorMessage} />
      : <></> }

      <div className={authStyles.contents}>

        <h2 className={authStyles.contents_title}>ログイン</h2>

        <p className={inputStyles.input_title}>会員ID</p>
        <div className={inputStyles.input_input}>
          <input placeholder='memorii' value={worksId} onChange={(e) => setWorksId(e.target.value)} />
        </div>
        
        <p className={inputStyles.input_title}>メールアドレス</p>
        <div className={inputStyles.input_input}>
          <input placeholder='memorii@trankllc.com' value={email} onChange={(e) => setEmail(e.target.value)} />
        </div>
        
        <p className={inputStyles.input_title}>パスワード</p>
        <div className={inputStyles.input_input}>
          <input placeholder='nk12q3ja' type={passwordEye ? '' : 'password'} value={password} onChange={(e) => setPassword(e.target.value)} />
          <FaEye className={inputStyles.input_icon} onClick={() => setPasswordEye(!passwordEye)} />
        </div>

        <button className={authStyles.contents_btn} onClick={() => check()}>ログインする</button>

        <p className={authStyles.contents_link} onClick={() => navigate('/company/signin')}>
          アカウントをお持ちではない方はこちら
        </p>

        <p className={authStyles.contents_link} onClick={() => navigate('/company/reset')}>
          パスワードをお忘れの方はこちら
        </p>

      </div>

    </div>

    {errorAuth ?
      <Modal 
        title={'ログイン エラー'}
        desc={'メールアドレス、もしくはパスワードが間違っています。\nまた、アカウント登録がお済みではない方は「アカウントをお持ちではない方はこちら」より、アカウントを作成してください。'}
        setError={setErrorAuth}
      />
    : <></> }

    {errorNoneId ?
      <Modal 
        title={'美容室ID エラー'}
        desc={'美容室IDが間違っています。\n美容室IDを確認したい方は「memorii 事務局」に美容室IDの発行を依頼してください。'}
        setError={setErrorNoneId}
      />
    : <></> }
    
    {errorId ?
      <Modal 
        title={'認証 エラー'}
        desc={'メールアドレス、パスワードの内容と会員IDが一致していません。ログインを再度試す、もしくは弊社までお問い合わせください。'}
        setError={setErrorId}
      />
    : <></> }
    </>
  );
}

export default AuthLogin;