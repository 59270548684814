import Styles from './styles.module.css';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import DefaultImage from './../../../image/default/recruit.png';
import firebase from 'firebase/compat/app';

import { getFunctions, httpsCallable } from "firebase/functions";
import { initializeApp } from "firebase/app";


type TopRecruitsProps = {
  characterScore: number[];
};

interface FetchRecruitsResponse {
  recruits: any;
}

const TopRecruits: React.FC<TopRecruitsProps> = ({ characterScore }) => {

  const navigate = useNavigate();

  const link: string = new URL(window.location.href).href;
  const recruitLink = decodeURIComponent(link.slice(link.indexOf('search=') + 7, link.length));

  const [ recruitList, setRecruitList ] = useState<any[]>([]);

  const firebaseConfig = {
    apiKey: process.env.REACT_APP_APIKEY,
    authDomain: process.env.REACT_APP_AUTHDOMAIN,
    databaseURL: process.env.REACT_APP_DATABASEURL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID,
  };
  
  const app = firebase.initializeApp(firebaseConfig);

  useEffect(() => {
    const fetchRecruits = async () => {

      const functionsForRegion = getFunctions(app, "asia-northeast1");
      const getSearchRecruits = httpsCallable<unknown, FetchRecruitsResponse>(functionsForRegion, "getSearchRecruits");

      try {
        const result = await getSearchRecruits({ searchUrl: recruitLink });
        setRecruitList(result.data.recruits as any);
      } catch (error) {
        console.error("Error calling Cloud Function:", error);
      }
    };

    fetchRecruits();
  }, [recruitLink]);

  return (
    <div className={Styles.list}>
      {recruitList && recruitList.length === 0 && (
        <h4 className={Styles.list_none}>指定した条件で美容室を検索しましたが、該当する美容室がございませんでした。</h4>
      )}
      {recruitList && recruitList.map((data) =>
        <div className={Styles.list_box} onClick={() => navigate(`/recruit/id=${data.id}`)}>
          <img src={`https://firebasestorage.googleapis.com/v0/b/trankllc-com.appspot.com/o/styles%2Fresize_images%2F${data.image}_1080x1080?alt=media&token=`}
          onError={e => {
            e.currentTarget.onerror = null;
            e.currentTarget.src = DefaultImage;
          }}/>
          <p>マッチ率：{Math.ceil((
            ((5 - Math.abs(data.person1 - characterScore[0])) / 5) + 
            ((5 - Math.abs(data.person2 - characterScore[1])) / 5) +
            ((5 - Math.abs(data.person3 - characterScore[2])) / 5) +
            ((5 - Math.abs(data.person4 - characterScore[1])) / 5) +
            ((5 - Math.abs(data.person5 - characterScore[2])) / 5)
          ) / 5 * 100)}％</p>
          <div>
            <img src={`https://firebasestorage.googleapis.com/v0/b/trankllc-com.appspot.com/o/companies_logo%2Fresize_images%2F${data.companyId}_200x200?alt=media&token=`}
              onError={e => {
                e.currentTarget.onerror = null;
                e.currentTarget.src = DefaultImage;
              }}/>
            <h4>{data.name}</h4>
          </div>
          <h3>{data.title}</h3>
        </div>
      )}
    </div>
  );
}

export default TopRecruits;
