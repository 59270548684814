import React, { useState, useLayoutEffect } from 'react';
import Styles from './styles.module.css';

type RecruitNumberProps = {
  pentagonStyle: number[];
  triangleTreatTitle: string;
};

const RecruitNumber: React.FC<RecruitNumberProps> = ({ pentagonStyle, triangleTreatTitle }) => {

  var link = new URL(window.location.href).href;
  var recruit = decodeURIComponent(link.slice(link.indexOf('id=') + 3, link.length));

  const [ triangleTreatData1, setTriangleTreatData1 ] = useState('');
  const [ squareTreatData1, setSquareTreatData1 ] = useState('');
  const [ dotTopTreatData1, setDotTopTreatData1 ] = useState('');
  const [ dotLeftTreatData1, setDotLeftTreatData1 ] = useState('');

  const [ triangleTreatData2, setTriangleTreatData2 ] = useState('');
  const [ squareTreatData2, setSquareTreatData2 ] = useState('');
  const [ dotTopTreatData2, setDotTopTreatData2 ] = useState('');
  const [ dotLeftTreatData2, setDotLeftTreatData2 ] = useState('');

  const [ triangleTreatData3, setTriangleTreatData3 ] = useState('');
  const [ squareTreatData3, setSquareTreatData3 ] = useState('');
  const [ dotTopTreatData3, setDotTopTreatData3 ] = useState('');
  const [ dotLeftTreatData3, setDotLeftTreatData3 ] = useState('');

  const [ triangleTreatData4, setTriangleTreatData4 ] = useState('');
  const [ squareTreatData4, setSquareTreatData4 ] = useState('');
  const [ dotTopTreatData4, setDotTopTreatData4 ] = useState('');
  const [ dotLeftTreatData4, setDotLeftTreatData4 ] = useState('');

  const [ triangleTreatData5, setTriangleTreatData5 ] = useState('');
  const [ squareTreatData5, setSquareTreatData5 ] = useState('');
  const [ dotTopTreatData5, setDotTopTreatData5 ] = useState('');
  const [ dotLeftTreatData5, setDotLeftTreatData5 ] = useState('');

  const [ triangleTreatData6, setTriangleTreatData6 ] = useState('');
  const [ squareTreatData6, setSquareTreatData6 ] = useState('');
  const [ dotTopTreatData6, setDotTopTreatData6 ] = useState('');
  const [ dotLeftTreatData6, setDotLeftTreatData6 ] = useState('');

  useLayoutEffect(() => {

    PentagonStyle(pentagonStyle[0], pentagonStyle[1], pentagonStyle[2], pentagonStyle[3], pentagonStyle[4], pentagonStyle[5],);

  }, [recruit]);

  // 69.0983005625, 75.27864045, 81.4589803375, 87.639320225, 93.8196601125
  // 69.4073175569, 75.5876574444, 81.7679973319, 87.9483372194, 94.1286771069

  // 100
  // 底辺：95.105651629515
  // 高さ：30.901699437495 = 69.0983005625
  // 99
  // 底辺：94.15459511322
  // 高さ：30.59268244312 = 69.4073175569

  // 80
  // 底辺：76.084521303612
  // 高さ：24.721359549996 = 75.27864045
  // 79
  // 底辺：75.133464787317
  // 高さ：24.412342555621 = 75.5876574444

  // 60
  // 底辺：57.063390977709
  // 高さ：18.541019662497 = 81.4589803375
  // 59
  // 底辺：56.112334461414
  // 高さ：18.232002668122 = 81.7679973319

  // 40
  // 底辺：38.042260651806
  // 高さ：12.360679774998 = 87.639320225
  // 39
  // 底辺：37.091204135511
  // 高さ：12.051662780623 = 87.9483372194

  // 20
  // 底辺：19.021130325903
  // 高さ：6.1803398874989 = 93.8196601125
  // 19
  // 底辺：18.070073809608
  // 高さ：5.871322893124 = 94.1286771069


  // var position100bottom = 95.105651629515;
  // var position100height = 30.901699437495;

  // var position99bottom = 94.15459511322;
  // var position99height = 30.59268244312;

  // var position80bottom = 76.084521303612;
  // var position80height = 24.721359549996;

  // var position79bottom = 75.133464787317;
  // var position79height = 24.412342555621;

  // var position60bottom = 57.063390977709;
  // var position60height = 18.541019662497;

  // var position59bottom = 56.112334461414;
  // var position59height = 18.232002668122;

  // var position40bottom = 38.042260651806;
  // var position40height = 12.360679774998;

  // var position39bottom = 37.091204135511;
  // var position39height = 12.051662780623;

  // var position20bottom = 19.021130325903;
  // var position20height = 6.1803398874989;

  // var position19bottom = 18.070073809608;
  // var position19height = 5.871322893124;
  
  function PentagonStyle(number1: number, number2: number, number3: number, number4: number, number5: number, number6: number) {

    var positionbottom1 = [ 18.186533479473, 35.507041555162, 52.827549630851, 70.14805770654, 87.468565782228,  ];
    var positionheight1 = [ 89.5, 79.5, 69.5, 59.5, 49.5, ];

    var positionbottom2 = [ 17.28, 34.56, 51.84, 69.12, 86.4,  ];
    var positionheight2 = [ 90, 80, 70, 60, 50, ];

    var triangleString1 = `polygon(0 ${100 - number1 * 20}%, 0 100%, ${positionbottom2[number2 - 1]}% ${positionheight2[number2 - 1]}%)`;
    setTriangleTreatData1(triangleString1);
    var squareString1 = `polygon(0 ${100 - number1 * 20}%, 0 ${101 - number1 * 20}%,  ${positionbottom2[number2 - 1]}% ${positionheight2[number2 - 1]}%, ${positionbottom1[number2 - 1]}% ${positionheight1[number2 - 1]}%)`;
    setSquareTreatData1(squareString1);
    var dotTopString1 = `calc(${positionheight1[number2 - 1]}% - 4px)`;
    setDotTopTreatData1(dotTopString1);
    var dotLeftString1 = `calc(${positionbottom1[number2 - 1]}% - 4px)`;
    setDotLeftTreatData1(dotLeftString1);

    var string2 = `polygon(0 ${100 - number2 * 20}%, 0 100%, ${positionbottom2[number3 - 1]}% ${positionheight2[number3 - 1]}%)`;
    setTriangleTreatData2(string2);
    var squareString2 = `polygon(0 ${100 - number2 * 20}%, 0 ${101 - number2 * 20}%, ${positionbottom2[number3 - 1]}% ${positionheight2[number3 - 1]}%, ${positionbottom1[number3 - 1]}% ${positionheight1[number3 - 1]}%)`;
    setSquareTreatData2(squareString2);
    var dotTopString2 = `calc(${positionheight1[number3 - 1]}% - 4px)`;
    setDotTopTreatData2(dotTopString2);
    var dotLeftString2 = `calc(${positionbottom1[number3 - 1]}% - 4px)`;
    setDotLeftTreatData2(dotLeftString2);

    var string3 = `polygon(0 ${100 - number3 * 20}%, 0 100%, ${positionbottom2[number4 - 1]}% ${positionheight2[number4 - 1]}%)`;
    setTriangleTreatData3(string3);
    var squareString3 = `polygon(0 ${100 - number3 * 20}%, 0 ${101 - number3 * 20}%,  ${positionbottom2[number4 - 1]}% ${positionheight2[number4 - 1]}%, ${positionbottom1[number4 - 1]}% ${positionheight1[number4 - 1]}%)`;
    setSquareTreatData3(squareString3);
    var dotTopString3 = `calc(${positionheight1[number4 - 1]}% - 4px)`;
    setDotTopTreatData3(dotTopString3);
    var dotLeftString3 = `calc(${positionbottom1[number4 - 1]}% - 4px)`;
    setDotLeftTreatData3(dotLeftString3);

    var string4 = `polygon(0 ${100 - number4 * 20}%, 0 100%, ${positionbottom2[number5 - 1]}% ${positionheight2[number5 - 1]}%)`;
    setTriangleTreatData4(string4);
    var squareString4 = `polygon(0 ${100 - number4 * 20}%, 0 ${101 - number4 * 20}%, ${positionbottom2[number5 - 1]}% ${positionheight2[number5 - 1]}%, ${positionbottom1[number5 - 1]}% ${positionheight1[number5 - 1]}%)`;
    setSquareTreatData4(squareString4);
    var dotTopString4 = `calc(${positionheight1[number5 - 1]}% - 4px)`;
    setDotTopTreatData4(dotTopString4);
    var dotLeftString4 = `calc(${positionbottom1[number5 - 1]}% - 4px)`;
    setDotLeftTreatData4(dotLeftString4);

    var string5 = `polygon(0 ${100 - number5 * 20}%, 0 100%, ${positionbottom2[number6 - 1]}% ${positionheight2[number6 - 1]}%)`;
    setTriangleTreatData5(string5);
    var squareString5 = `polygon( 0 ${100 - number5 * 20}%, 0 ${101 - number5 * 20}%, ${positionbottom2[number6 - 1]}% ${positionheight2[number6 - 1]}%, ${positionbottom1[number6 - 1]}% ${positionheight1[number6 - 1]}%)`;
    setSquareTreatData5(squareString5);
    var dotTopString5 = `calc(${positionheight1[number6 - 1]}% - 4px)`;
    setDotTopTreatData5(dotTopString5);
    var dotLeftString5 = `calc(${positionbottom1[number6 - 1]}% - 4px)`;
    setDotLeftTreatData5(dotLeftString5);

    var string6 = `polygon(0 ${100 - number6 * 20}%, 0 100%, ${positionbottom2[number1 - 1]}% ${positionheight2[number1 - 1]}%)`;
    setTriangleTreatData6(string6);
    var squareString6 = `polygon(0 ${100 - number6 * 20}%, 0 ${101 - number6 * 20}%, ${positionbottom2[number1 - 1]}% ${positionheight2[number1 - 1]}%, ${positionbottom1[number1 - 1]}% ${positionheight1[number1 - 1]}%)`;
    setSquareTreatData6(squareString6);
    var dotTopString6 = `calc(${positionheight1[number1 - 1]}% - 4px)`;
    setDotTopTreatData6(dotTopString6);
    var dotLeftString6 = `calc(${positionbottom1[number1 - 1]}% - 4px)`;
    setDotLeftTreatData6(dotLeftString6);
  }

  return (
    <>
    <div className={Styles.text_pentagon}>
      <div className={Styles.pentagon_treat_back}>
        <div className={Styles.pentagon}>
          <div className={Styles.triangle}>
            <h4>{triangleTreatTitle}</h4>

            <div className={Styles.triangle1} style={{clipPath : triangleTreatData1}}></div>
            <div className={Styles.triangleBorder1} style={{clipPath : squareTreatData1}}></div>
            <div className={Styles.triangleDot1}>
              <div style={{marginTop : dotTopTreatData1, marginLeft : dotLeftTreatData1}}></div>
            </div>

            <div className={Styles.triangle2} style={{clipPath : triangleTreatData2}}></div>
            <div className={Styles.triangleBorder2} style={{clipPath : squareTreatData2}}></div>
            <div className={Styles.triangleDot2}>
              <div style={{marginTop : dotTopTreatData2, marginLeft : dotLeftTreatData2}}></div>
            </div>

            <div className={Styles.triangle3} style={{clipPath : triangleTreatData3}}></div>
            <div className={Styles.triangleBorder3} style={{clipPath : squareTreatData3}}></div>
            <div className={Styles.triangleDot3}>
              <div style={{marginTop : dotTopTreatData3, marginLeft : dotLeftTreatData3}}></div>
            </div>

            <div className={Styles.triangle4} style={{clipPath : triangleTreatData4}}></div>
            <div className={Styles.triangleBorder4} style={{clipPath : squareTreatData4}}></div>
            <div className={Styles.triangleDot4}>
              <div style={{marginTop : dotTopTreatData4, marginLeft : dotLeftTreatData4}}></div>
            </div>

            <div className={Styles.triangle5} style={{clipPath : triangleTreatData5}}></div>
            <div className={Styles.triangleBorder5} style={{clipPath : squareTreatData5}}></div>
            <div className={Styles.triangleDot5}>
              <div style={{marginTop : dotTopTreatData5, marginLeft : dotLeftTreatData5}}></div>
            </div>

            <div className={Styles.triangle6} style={{clipPath : triangleTreatData6}}></div>
            <div className={Styles.triangleBorder6} style={{clipPath : squareTreatData6}}></div>
            <div className={Styles.triangleDot6}>
              <div style={{marginTop : dotTopTreatData6, marginLeft : dotLeftTreatData6}}></div>
            </div>

          </div>
        </div>
      </div>

    </div>
    </>
  );
}

export default RecruitNumber;
