import Styles from './styles.module.css';
import React, { useState } from 'react';
import firebase from 'firebase/compat/app';
import DefaultSelect1 from '../../../package/parts/1-select';
import { useSelector } from 'react-redux';

type SettingInviteProps = {
  manageId: string;
  setManageId: React.Dispatch<React.SetStateAction<string>>;
  checks: boolean;
};

const SettingInvite: React.FC<SettingInviteProps> = ({ manageId, setManageId, checks }) => {

  const company = useSelector((state: any) => state.company.company);

  const [ management, setManagement ] = useState('');

  function check() {
    if (management === '') {
      setManageId('')
    } else {
      invite();
    }
  }

  function invite() {
    if (management === '管理者') {
      firebase.firestore().collection('companies').doc(company.id)
      .update({
        administrator: firebase.firestore.FieldValue.arrayUnion(manageId),
      }).then(() => setManageId(''));
    } else if (management === '審査員') {
      firebase.firestore().collection('companies').doc(company.id)
      .update({
        administrator: firebase.firestore.FieldValue.arrayRemove(manageId),
      }).then(() => setManageId(''));
    }
  }

  return (
    <>
    <div className={Styles.box}>
      <div>
        <div className={Styles.box_black} onClick={() => setManageId('')}></div>
        <div className={Styles.contents}>

          <div className={Styles.title}>
            <h4>権限変更</h4>
            <button onClick={() => check()}>変更</button>
          </div>

          <DefaultSelect1
            title={'権限'} required={false} length={0}
            value={management} setValue={setManagement}
            list={checks ? ['未選択', '管理者', ] : ['未選択', '管理者', '審査員', ]}
            desc={'※権限を入力してください。'}
          />

        </div>
      </div>
    </div>
    </>
  );
}

export default SettingInvite;
