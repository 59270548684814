import { Splide, SplideSlide } from "@splidejs/react-splide";
import Styles from "./styles.module.css";
import '@splidejs/splide/css';
import React from "react";

type RecruitInterviewProps = {
  count: number;
  recruit: string;
  name1: string;
  title1: string;
  desc1: string;
  name2: string;
  title2: string;
  desc2: string;
  name3: string;
  title3: string;
  desc3: string;
  name4: string;
  title4: string;
  desc4: string;
  name5: string;
  title5: string;
  desc5: string;
  name6: string;
  title6: string;
  desc6: string;
};

const RecruitInterview: React.FC<RecruitInterviewProps> = ({ count, recruit, name1, title1, desc1, name2, title2, desc2, name3, title3, desc3, name4, title4, desc4, name5, title5, desc5, name6, title6, desc6 }) => {

  return (
    <>
    <div className={Styles.block}>
      <h4>インタビュー</h4>

      <Splide
        options={{
          type: 'loop',
          perPage: 3,
          perMove: 1,
          gap: 20,
          breakpoints: {
            700: {
              perPage: 2,
            },
          }
        }}
      >
        {1 <= count ?
          <SplideSlide>
            <div className={Styles.interview}>
              <img className={Styles.slideImg} 
                src={`https://firebasestorage.googleapis.com/v0/b/trankllc-com.appspot.com/o/interview%2Fresize_images%2F${recruit}_1_1080x1080?alt=media&token=`}
                alt="美容室 求人 memorii works" />
              <h4>{name1}</h4>
              <h6>{title1}</h6>
              <p>{desc1}</p>
            </div>
          </SplideSlide>
        : <></> }
        {2 <= count ?
          <SplideSlide>
            <div className={Styles.interview}>
              <img className={Styles.slideImg} 
                src={`https://firebasestorage.googleapis.com/v0/b/trankllc-com.appspot.com/o/interview%2Fresize_images%2F${recruit}_2_1080x1080?alt=media&token=`}
                alt="美容室 求人 memorii works" />
              <h4>{name2}</h4>
              <h6>{title2}</h6>
              <p>{desc2}</p>
            </div>
          </SplideSlide>
        : <></> }
        {3 <= count ?
          <SplideSlide>
            <div className={Styles.interview}>
              <img className={Styles.slideImg} 
                src={`https://firebasestorage.googleapis.com/v0/b/trankllc-com.appspot.com/o/interview%2Fresize_images%2F${recruit}_3_1080x1080?alt=media&token=`}
                alt="美容室 求人 memorii works" />
              <h4>{name3}</h4>
              <h6>{title3}</h6>
              <p>{desc3}</p>
            </div>
          </SplideSlide>
        : <></> }
        {4 <= count ?
          <SplideSlide>
            <div className={Styles.interview}>
              <img className={Styles.slideImg} 
                src={`https://firebasestorage.googleapis.com/v0/b/trankllc-com.appspot.com/o/interview%2Fresize_images%2F${recruit}_4_1080x1080?alt=media&token=`}
                alt="美容室 求人 memorii works" />
              <h4>{name4}</h4>
              <h6>{title4}</h6>
              <p>{desc4}</p>
            </div>
          </SplideSlide>
        : <></> }
        {5 <= count ?
          <SplideSlide>
            <div className={Styles.interview}>
              <img className={Styles.slideImg} 
                src={`https://firebasestorage.googleapis.com/v0/b/trankllc-com.appspot.com/o/interview%2Fresize_images%2F${recruit}_5_1080x1080?alt=media&token=`}
                alt="美容室 求人 memorii works" />
              <h4>{name5}</h4>
              <h6>{title5}</h6>
              <p>{desc5}</p>
            </div>
          </SplideSlide>
        : <></> }
        {6 <= count ?
          <SplideSlide>
            <div className={Styles.interview}>
              <img className={Styles.slideImg} 
                src={`https://firebasestorage.googleapis.com/v0/b/trankllc-com.appspot.com/o/interview%2Fresize_images%2F${recruit}_6_1080x1080?alt=media&token=`}
                alt="美容室 求人 memorii works" />
              <h4>{name6}</h4>
              <h6>{title6}</h6>
              <p>{desc6}</p>
            </div>
          </SplideSlide>
        : <></> }
      </Splide>
    </div>
    </>
  );
}

export default RecruitInterview;
