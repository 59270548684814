import React, { useState } from 'react';
import Styles from './styles.module.css';
import firebase from 'firebase/compat/app';

type ManageMemoProps = {
  id: string;
  name: string;
  memo: string;
  setMemoToggle: React.Dispatch<React.SetStateAction<boolean>>;
};

const ManageMemo: React.FC<ManageMemoProps> = ({ id, name, memo, setMemoToggle }) => {

  const [ memoText, setMemoText ] = useState<string>(memo);

  function save() {
    firebase.firestore().collection('applicants').doc(id)
    .update({
      managerMemo: memoText,
    }).then(() => setMemoToggle(false));
  }

  return (
    <>
    <div className={Styles.box}>
      <div>
        <div className={Styles.box_black} onClick={() => save()}></div>
        <div className={Styles.box_contents}>
          <h4>メモ：{name}さん</h4>
          <textarea value={memo} onChange={(e) => setMemoText(e.target.value)} />
          <div>
            <button style={{backgroundColor : 'white', color : '#AD0200'}} onClick={() => setMemoToggle(false)}>キャンセル</button>
            <button style={{backgroundColor : '#AD0200', color : 'white'}} onClick={() => save()}>保存</button>
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

export default ManageMemo;
