import Styles from './styles.module.css';
import React, { useState } from 'react';
import firebase from 'firebase/compat/app';
import DefaultInput1 from '../../../package/parts/1-input/index';
import { useDispatch, useSelector } from 'react-redux';

type SettingMailsProps = {
  mailAddress: string;
  setMail: React.Dispatch<React.SetStateAction<boolean>>;
};

const SettingMails: React.FC<SettingMailsProps> = ({ mailAddress, setMail }) => {

  const company = useSelector((state: any) => state.company.company);

  const [ address, setAddress ] = useState<string | number>(mailAddress);

  function check() {
    if (address !== '') {
      invite();
    }
  }

  function invite() {
    firebase.firestore().collection('companies').doc(company.id)
    .update({
      notification_mails: mailAddress,
    })
    .then(() => {
      setMail(false);
    });
  }

  return (
    <>
    <div className={Styles.modal}>
      <div>
        <div className={Styles.modal_black} onClick={() => setMail(false)}></div>
        <div className={Styles.modal_contents}>

          <div className={Styles.modal_contents_title}>
            <h4>通知先アドレス</h4>
            <button onClick={() => check()}>招待</button>
          </div>

          <DefaultInput1
            title={'メールアドレス'} required={true} length={40} place={'memorii-works@trankllc.com'} type={'text'}
            value={address} setValue={setAddress}
            desc={'※メールアドレスを入力してください。'}
          />

        </div>
      </div>
    </div>
    </>
  );
}

export default SettingMails;
