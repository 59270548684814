import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// createAsyncThunkを使って非同期アクションを定義
export const fetchNavigation = createAsyncThunk(
  'navigation/fetchNavigation',
  async (value: number) => {
    return value;
  }
);

// Slice の作成
const navigationNumber = createSlice({
  name: 'navigation',
  initialState: {
    number: 1,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchNavigation.fulfilled, (state, action) => {
        state.number = action.payload;
      })
  },
});

export default navigationNumber.reducer;
