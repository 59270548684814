import Styles from './styles.module.css';
import React from 'react'
import { useDispatch } from 'react-redux';
import { fetchNavigation } from '../../features/0.main/navigation';

interface ComponentProps {
  navigation: number;
}

const NavigationMobile: React.FC<ComponentProps> = ({ navigation }) => {
  
  const dispatch = useDispatch();

  return (
    <div className={Styles.works_nav_mobile}>

      <div className={Styles.works_nav_mobile_item} 
        style={navigation === 4 ? {backgroundColor : '#192651'} : {}}
        onClick={() => dispatch(fetchNavigation(4))}>
        <p style={navigation === 4 ? {color : 'white', fontWeight : 'bold'} : {}}>応募者管理</p>
      </div>

      <div className={Styles.works_nav_mobile_item} 
        style={navigation === 5 ? {backgroundColor : '#192651'} : {}}
        onClick={() => dispatch(fetchNavigation(5))}>
        <p style={navigation === 5 ? {color : 'white', fontWeight : 'bold'} : {}}>スケジュール</p>
      </div>

      <div className={Styles.works_nav_mobile_item} 
        style={navigation === 6 ? {backgroundColor : '#192651'} : {}}
        onClick={() => dispatch(fetchNavigation(6))}>
        <p style={navigation === 6 ? {color : 'white', fontWeight : 'bold'} : {}}>メッセージ</p>
      </div>

    </div>
  );
}

export default NavigationMobile;
