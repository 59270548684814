import Styles from './styles.module.css';
import React, { useState, useEffect } from 'react';
import firebase from "firebase/compat/app";
import DefaultImg from './../../../image/default/account.png';
import { useSelector } from 'react-redux';

type ManageNavProps = {
  chatList: Chat;
  chatStatus: number;
};

interface Chat {
  id: string;
  ids: string[];
  names: string[];
  reason: string;
  time: string;
  applicantId: string;
  badges: number,
  recruitId: string,
}

const ContactChat: React.FC<ManageNavProps> = ({ chatList, chatStatus, }) => {

  const [ uid, setUid ] = useState('');
  const [ name, setName ] = useState('');

  const company = useSelector((state: any) => state.company.company);

  useEffect(() => {    
    setUid(chatList.names.indexOf(company?.id) === 1 ? chatList.names[3] : chatList.names[1]);
    setName(chatList.names.indexOf(company?.id) === 1 ? chatList.names[2] : chatList.names[0]);
  }, [company]);

  // バッジをリセット
  function badgeUpdate(id: string) {
    let badge: { [key: string]: number } = {};
    let key = `${company.id}_badge`;
    badge[key] = 0;
    firebase.firestore().collection('chat').doc(id).update({ ...badge });
  }

  return (
    <>
    {chatStatus === 1 ?
      <div className={Styles.chat} onClick={() => badgeUpdate(chatList.id)}>
        <img src={`https://firebasestorage.googleapis.com/v0/b/trankllc-com.appspot.com/o/profiles%2Fresize_images%2F${uid}_200x200?alt=media&token=`}
          onError={e => {
            e.currentTarget.onerror = null;
            e.currentTarget.src = DefaultImg;
          }}/>
        <div className={Styles.chat_name}>
          <h5>{name === '' ? "unnamed" : name}</h5>
          <p>{new Date(chatList.time).getFullYear()}年{new Date(chatList.time).getMonth() + 1}月{new Date(chatList.time).getDate()}日</p>
        </div>
        {chatList.badges !== 0 ?
          <div className={Styles.chat_badge}></div>
        : <></> }
      </div>
    : <></> }
    
    {chatStatus === 2 && chatList.badges !== 0 ?
      <div className={Styles.chat} onClick={() => badgeUpdate(chatList.id)}>
        <img src={`https://firebasestorage.googleapis.com/v0/b/trankllc-com.appspot.com/o/profiles%2Fresize_images%2F${uid}_200x200?alt=media&token=`}
          onError={e => {
            e.currentTarget.onerror = null;
            e.currentTarget.src = DefaultImg;
          }}/>
        <div className={Styles.chat_name}>
          <h5>{name}</h5>
          <p>{chatList.time}</p>
        </div>
        {chatList.badges !== 0 ?
          <div className={Styles.chat_badge}></div>
        : <></> }
      </div>
    : <></> }
    
    {chatStatus === 3 && chatList.badges === 0 ?
      <div className={Styles.chat} onClick={() => badgeUpdate(chatList.id)}>
        <img src={`https://firebasestorage.googleapis.com/v0/b/trankllc-com.appspot.com/o/profiles%2Fresize_images%2F${uid}_200x200?alt=media&token=`}
          onError={e => {
            e.currentTarget.onerror = null;
            e.currentTarget.src = DefaultImg;
          }}/>
        <div className={Styles.chat_name}>
          <h5>{name}</h5>
          <p>{chatList.time}</p>
        </div>
        {chatList.badges !== 0 ?
          <div className={Styles.chat_badge}></div>
        : <></> }
      </div>
    : <></> }
    </>
  );
}

export default ContactChat;