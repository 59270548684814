import Styles from './styles.module.css';
import React, { useState } from 'react';
import firebase from 'firebase/compat/app';
import DefaultTitle from '../../../package/parts/title/index';
import Style from '../../../package/styleTags/index';

type editList = {
  post_tags: string[]; id: string;
};

type PhotoEditProps = {
  editInfo: editList;
  setEditToggle: React.Dispatch<React.SetStateAction<boolean>>;
};

const PhotoEdit: React.FC<PhotoEditProps> = ({ editInfo, setEditToggle }) => {

  const [ style, setStyle ] = useState(editInfo.post_tags);
  const [ switchStyle, setSwitchStyle ] = useState(false);

  function upload() {
    firebase.firestore().collection('posts').doc(editInfo.id)
    .update({
      post_tags: style,
    }).then(() => setEditToggle(false));
  }

  function deletes() {

    firebase.storage().ref('image').child(`${editInfo.id}`).delete();
    firebase.storage().ref('image/resize_images').child(`${editInfo.id}_1080x1080`).delete();
    firebase.storage().ref('image/resize_images').child(`${editInfo.id}_750x750`).delete();
    firebase.storage().ref('image/resize_images').child(`${editInfo.id}_500x500`).delete();
    firebase.storage().ref('image/resize_images').child(`${editInfo.id}_200x200`).delete();

    firebase.firestore().collection('posts').doc(editInfo.id).delete();

    firebase.firestore().collection('users').where('user_likes', 'in', [ editInfo.id ]).get()
    .then(snapShot => {
      snapShot.forEach((doc) => {
        firebase.firestore().collection('users').doc(doc.id)
        .update({
          user_likes: firebase.firestore.FieldValue.arrayRemove([doc.id])
        });
      });
    });
    setEditToggle(false);
  }

  return (
    <>
    {!switchStyle ?
      <div className={Styles.modal}>
        <div>
          <div className={Styles.modal_black} onClick={() => setEditToggle(false)}></div>
          <div className={Styles.modal_contents}>

            <div className={Styles.modal_contents_title}>
              <h4>店舗の施術・作品撮りを編集</h4>
              <div>
                <button onClick={() => deletes()}>削除</button>
                <button onClick={() => upload()}>更新</button>
              </div>
            </div>
            
            <p className={Styles.desc}>※タグを編集して閲覧数増加を見込みましょう。</p>

            <div className={Styles.drop_area}>
              <img src={`https://firebasestorage.googleapis.com/v0/b/trankllc-com.appspot.com/o/image%2Fresize_images%2F${editInfo.id}_500x500?alt=media&token=d47a24ea-7ffa-497d-b476-2db5278a2087`} />
              <div className={Styles.drop_area_select}>

                <DefaultTitle title={'スタイル'} required={true} />
                <select className={Styles.select} 
                  value={style.includes('メンズ') ? 'メンズ' : 'レディース'} 
                  onChange={(e) => {
                    var option = style;
                    option = option.filter((v, index) => (v !== 'メンズ')).filter((v, index) => (v !== 'レディース'));
                    setStyle([...option, e.target.value]);
                  }}>
                  <option value='メンズ'>メンズ</option>
                  <option value='レディース'>レディース</option>
                </select>

                <div style={{height : '10px'}}></div>

                <DefaultTitle title={'その他のタグ'} required={false} />
                <h5 onClick={() => {setSwitchStyle(true);}}>その他のタグを見る</h5>
                <p>
                  {style.map((data, index) =>
                    <>{data},</>
                  )}
                </p>

              </div>
            </div>
            
          </div>
        </div>
      </div>
    : <></> }

    {switchStyle ?
      <Style style={style} setStyle={setStyle} setSwitchStyle={setSwitchStyle} />
    : <></> }
    </>
  );
}

export default PhotoEdit;


