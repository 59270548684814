import React, { useState, useEffect } from 'react';
import firebase from 'firebase/compat/app';

const Footer = () => {

  const [ recruit, setRecruits ] = useState<any>([]);
  const [ company, setCompany ] = useState([]);

  useEffect(() => {

    firebase.firestore().collection('recruits').orderBy("time", 'asc').get()
    .then(snapShot => {
      var tmp: any = [];
      snapShot.forEach((doc) => {
        tmp.push({...doc.data(), id: doc.id });
        companyData(doc.data().companyId);
      });
      setRecruits(tmp);
    });

  }, []);

  var tmps: any = [];
  function companyData(value: any) {
    firebase.firestore().collection('companies').doc(value).get()
    .then((doc) => {
      tmps.push(doc.data()?.name === 'undefined' ? '' : doc.data()?.name);
    });
    setCompany(tmps);
  }

  return (
    <>
    {recruit.map((data: any, index: any) =>
    <>
      <h4>会社名：{data.store_name}</h4>
      <p>{index}：ID：{data.id}、status：{data.status}、会社名：{data.store_name}：年月日：{new Date(data.time.seconds * 1000).getFullYear()}年{new Date(data.time.seconds * 1000).getMonth()+1}月{new Date(data.time.seconds * 1000).getDate()}日</p>
      <p>{data.recruit_point1Title}、{data.recruit_point2Title}、{data.recruit_point3Title}</p>
      <p>{data.recruit_image1Title}、{data.recruit_image1Desc}、{data.recruit_image2Title}、{data.recruit_image2Desc}、{data.recruit_image3Title}、{data.recruit_image3Desc}、</p>
    </>
    )}
    </>
  );
}

export default Footer;
