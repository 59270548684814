import './styles.css';
import React from 'react';
import Strong1 from "./../../../image/landing/strong_1.png";
import Strong2 from "./../../../image/landing/strong_2.png";
import Strong3 from "./../../../image/landing/strong_3.png";
import { FaRegHandPointRight } from "react-icons/fa6";

const LandingStrong: React.FC = () => {

  return (
    <>
    <div className='works_landing_strong'>
      <h5>memorii worksを選ぶ理由</h5>
      <div>
        <div>
          <h4 style={{color: '#279A30'}}>01</h4>
          <h5>美容学生に直接情報を届けられる</h5>
          <div>
            <p>このサービスでは、memorii photoという美容学生向けの作品撮りに求人を掲載できる上、indeedやgoogleに公開するため、美容学生に確実に情報を届けることが出来ます。</p>
            <div>
              <FaRegHandPointRight className='works_landing_strong_icon' />
              <p>美容学生専用アプリへの求人掲載</p>
            </div>
            <div>
              <FaRegHandPointRight className='works_landing_strong_icon' />
              <p>作品撮りをしている優秀な美容学生にリーチ</p>
            </div>
          </div>
        </div>
        <img src={Strong1} alt='' />
      </div>
      <div>
        <img src={Strong2} alt='' />
        <div>
          <h4 style={{color: '#FFD04F'}}>02</h4>
          <h5>求人募集から採用までを一括で管理</h5>
          <div>
            <p>このサービスでは、求人募集が出来るだけでなく、美容学生の履歴書閲覧、連絡、選考状況管理等の採用に付随する業務が1つのサービスで完結します。</p>
            <div>
              <FaRegHandPointRight className='works_landing_strong_icon' />
              <p>応募者からの情報を履歴書に変換</p>
            </div>
            <div>
              <FaRegHandPointRight className='works_landing_strong_icon' />
              <p>選考状況の把握や連絡までを一括提供</p>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div>
          <h4 style={{color: '#FFD04F'}}>02</h4>
          <h5>求人募集から採用までを一括で管理</h5>
          <div>
            <p>このサービスでは、求人募集が出来るだけでなく、美容学生の履歴書閲覧、連絡、選考状況管理等の採用に付随する業務が1つのサービスで完結します。</p>
            <div>
              <FaRegHandPointRight className='works_landing_strong_icon' />
              <p>応募者からの情報を履歴書に変換</p>
            </div>
            <div>
              <FaRegHandPointRight className='works_landing_strong_icon' />
              <p>選考状況の把握や連絡までを一括提供</p>
            </div>
          </div>
        </div>
        <img src={Strong2} alt='' />
      </div>
      <div>
        <div>
          <h4 style={{color: '#2E81B0'}}>03</h4>
          <h5>応募者との価値観マッチング</h5>
          <div>
            <p>このサービスでは、過去に手がけた施術写真が投稿できるため、美容室の技術力・スタイルを応募者に伝えられ、価値観の合う応募者を募集することが出来ます。</p>
            <div>
              <FaRegHandPointRight className='works_landing_strong_icon' />
              <p>美容室の価値観が伝わる施術写真の投稿</p>
            </div>
            <div>
              <FaRegHandPointRight className='works_landing_strong_icon' />
              <p>スタイルに合わせた募集で価値観マッチング</p>
            </div>
          </div>
        </div>
        <img src={Strong3} alt='' />
      </div>
    </div>
    </>
  );
}

export default LandingStrong;
