import DocumentMeta from 'react-document-meta';
import Styles from '../styles.module.css';
import React from 'react';
import { AiFillCheckCircle } from "react-icons/ai";
import AnchorLink from 'react-anchor-link-smooth-scroll';
import Icon from './../../image/media/1/top.png';
import Image from './../../image/media/6/top.png';
import Image1 from './../../image/media/6/1.png';
import Image2 from './../../image/media/6/2.png';

const Article0001 = () => {

  const meta = {
    title: '【志望動機】第一志望サロンで美容師になる履歴書を例文付きで0から解説！',
    description: '志望動機とは、「なぜ自分がこのサロンで働きたいと思ったか」を伝える文章となっており、履歴書やエントリーシートで書く必要があります。履歴書の中で一番重要とされている部分ですので、この記事を読んで魅力的な志望動機を書けるようになりましょう！',
    canonical: 'https://memorii-works.com/',
    meta: { charset: 'utf-8', }
  };

  return (
    <>
    <DocumentMeta {...meta}>
      <div className={Styles.media_nav}>
        <a className={Styles.media_nav_icon} href='https://trankllc.com/media/subsidy/business/0000'>
          <img src={Icon} loading="lazy" alt='memorii' />
          <h2>美容学生の教科書</h2>
        </a>
        <a className={Styles.media_nav_contact} href='https://trankllc.com/contact'>
          <p>問い合わせ</p>
        </a>
      </div>
      <div className={Styles.media}>
        <div className={Styles.media_article}>
          
          <p className={Styles.media_date}>2024/04/20 更新日: -</p>
          <h1 className={Styles.media_title}>【志望動機】第一志望サロンで美容師になる履歴書を例文付きで0から解説！</h1>
          <img src={Image} className={Styles.media_title_img} alt='美容 志望動機' />
    
          <p className={Styles.media_text_text}>
            <br />
            志望動機とは、「なぜ自分がこのサロンで働きたいと思ったか」を伝える文章となっており、履歴書やエントリーシートで書く必要があります。<br />
            <strong>履歴書の中で一番重要とされている部分ですので、この記事を読んで魅力的な志望動機を書けるようになりましょう！</strong><br />
          </p>
    
          <div className={Styles.media_introduction}>
            <div>
              <AiFillCheckCircle className={Styles.media_introduction_icon} />
              <h3>この記事でこの3点が分かる！</h3>
            </div>
            <hr></hr>
            <ul>
              <li><span>志望動機に書く内容</span></li>
              <li><span>あなたが志望動機で書くべきこと</span></li>
              <li><span>大手サロンに内定する！志望動機の例文</span></li>
            </ul>
          </div>
    
          <div className={Styles.media_contents}>
            <div className={Styles.media_contents_title}>
              本記事の目次
            </div>
            <div className={Styles.media_contents_contents}>
              <ol>
                <li><AnchorLink href="#1" offset="100"><strong>志望動機に何を書けばいいのかを理解しよう！</strong></AnchorLink></li>
                <li><AnchorLink href="#2" offset="100"><strong>自分だけの志望動機を一緒に作ろう！</strong></AnchorLink></li>
                <li><AnchorLink href="#3" offset="100"><strong>大手サロンから内定を貰った志望動機を確認しよう！</strong></AnchorLink></li>
                <li><AnchorLink href="#4" offset="100"><strong>まとめ</strong></AnchorLink></li>
              </ol>
            </div>
          </div>
    
          <div id='1' className={Styles.media_text_title}>
            <h2>1.志望動機に何を書けばいいのかを理解しよう！</h2>
            <br />
          </div>
          <h3 className={Styles.media_text_text_title}>志望動機で伝えること</h3><br />
          <p className={Styles.media_text_text}>
            履歴書では<strong>「この人と一緒に働きたい」と思ってもらうことが1番重要</strong>なことになります。<br />
            そのため、志望動機では「サロンを志望する理由」と「自分の特徴」を伝えることがポイントとなります。<br />
            <br />
            具体的にサロンを志望する理由では、サロンのどのような部分を魅力的だと思っているのかを伝えましょう。<br />
            そして、<strong>自分の特徴も踏まえて「なぜ自分が魅力的だと感じるか」や「サロンに入ることで、どのような自分になっていきたいのか」を伝えると魅力的な志望動機になります！</strong><br />
            <br />
          </p>
          <h3 className={Styles.media_text_text_title}>志望動機の文章構成</h3><br />
          <p className={Styles.media_text_text}>
            志望動機では以下のような順番で文章を書くことで、分かりやすく魅力的になります。<br />
            <br />
            <strong>(1)なぜ応募したかの理由を書く</strong><br />
            <strong>(2)自分がそう思うようになった出来事・背景を書く</strong><br />
            <strong>(3)サロンに入社したら、どのような活躍が出来るかを書く</strong><br />
            <br />
            <img src={Image1} className={Styles.media_img} alt='美容 志望動機' />
            <br />
            <br />
            <strong>(1)なぜ応募したのか</strong><br />
            自分が理想とする美容師像と、会社の理念がマッチしていると考えたから<br />
            <br />
            <strong>(2)自分がそう思うようになった出来事・背景</strong><br />
            もともと美容に興味がなかったが、美容室でファッションやセットを教えてもらったことで、美容に興味を持つようになった経験<br />
            <br />
            <strong>(3)サロンに入社したら、どのような活躍が出来るかを書く</strong><br />
            様々な人に美容情報を届けることが出来る、自分が理想とする美容師像に近づく<br />
            <br />
            いかがでしょうか？<br />
            自分の経験や特徴と会社の考えがマッチしていることが分かり、会社に入ったらすぐに活躍できそうなイメージが持てますね！<br />
            <br />
            では次は、自分の経験や特徴でどのようなことを書けばいいのかを詳しく解説していきます！<br />
            <br />
          </p>
          <div className={Styles.media_introduction}>
            <div>
              <AiFillCheckCircle className={Styles.media_introduction_icon} />
              <h3>志望動機に書く内容のまとめ</h3>
            </div>
            <hr></hr>
            <ul>
              <li><span>・志望動機では「この人と一緒に働きたい！」と思ってもらえる内容を書く</span></li>
              <li><span>・そのために、自分のことだけでなくサロンの特徴や思いも踏まえて書く</span></li>
              <li><span>・志望動機は「なぜ応募したか→自分がそう思うようになった出来事・背景→サロンに入社したら、どのような活躍が出来るか」の順番で書く</span></li>
            </ul>
          </div>

          <div id='2' className={Styles.media_text_title}>
            <h2>2.自分だけの志望動機を一緒に作ろう！</h2>
          </div>
          <p className={Styles.media_text_text}>
            <br />
            ここからは、構成ごとにどのような内容を書けばいいのかを解説していきます。<br />
            <br />
          </p>
          <h3 className={Styles.media_text_text_title}>なぜ応募したのか</h3><br />
          <p className={Styles.media_text_text}>
            ここで書く内容は、「自分とサロンの相性が良い」ことを伝えることが重要です。そのため、下のどちらかを書くことがおすすめです。<br />
            <br />
            ・私が貴社に応募した理由は、<strong>「自分の考え」と「サロンの行動」がマッチしている</strong>と感じたからです。<br />
            ・私が貴社に応募した理由は、<strong>貴社で働くことで「自分の特徴」を活かし、大きく成長することが出来る</strong>と考えたからです。<br />
            <br />
            「」の中には、以下のような内容を書くといいでしょう。<br />
            〇自分の考え<br />
            自分の理想の美容師像、自分が大切にしている考え<br />
            <br />
            〇サロンの行動<br />
            サロンの思い、サロンが行っている事業(ワックスの製造など)、働き方の特徴<br />
            <br />
            〇自分の特徴<br />
            性格、今までの経験、強み、好きなこと、今まで勉強したこと<br />
            <br />
            <strong>☆Point：サロンの思いや特徴ってどうやって調べるの？</strong><br />
            結果を伝えるときには、数字をうまく使うことで相手の評価を高くすることが出来ます。参考として、以下の例を見てみましょう。<br />
            <br />
            <img src={Image2} className={Styles.media_img} alt='美容 志望動機' />
            <br />
            <br />
            ただ数字を並べるよりも、期間や増えた数を数字で表現することで、印象は大きく変わります。<br />
            皆さんも数字を上手に使うことで、相手の評価を高めましょう！<br />
          </p>
          <h3 className={Styles.media_text_text_title}>自分がそう思うようになった出来事・背景</h3><br />
          <p className={Styles.media_text_text}>
            ここでは、前で書いた「自分の考え」や「サロンの行動」、「自分の特徴」を詳しく書くことで、サロンの人に自分の情熱を伝える必要があります。<br />
            具体的には、以下のような内容を書くと情熱が伝わりやすく、オススメです。<br />
            <br />
            <strong>〇自分の実体験</strong><br />
            体験をした時の年代やその時の気持ち、現在の自分にどうつながっているかを詳しく書きましょう。<br />
            <br />
            <strong>〇強み</strong><br />
            なぜそれが強みだと思うか、学生生活でどう活きたか、どのような場面で発揮されるかということを書き、具体的に伝えましょう。<br />
            <br />
            <strong>〇自分が頑張ったこと</strong><br />
            なぜそれを頑張れたのか、どのような結果を出すことが出来たか、その頑張りからどのようなことを学んだかを書き、サロンに入った後も同じように頑張れることを書きましょう。<br />
            <br />
            <strong>☆Point：結果を話すときは数字をうまく使い、相手に凄いと思わせろ！</strong><br />
            サロンの思いや特徴を調べる方法は、「サロンに行き、働いている人の話を聞く」と「サロンのHPを見る」の2つの方法があります。HPの文章からでもサロンの雰囲気はなんとなく分かりますが、働いている人の声を聞くことが一番ですので、第一志望のサロンへはなるべく行くようにしましょう！<br />
            <br />
            ・私が貴社に応募した理由は、<strong>「自分の考え」と「サロンの行動」がマッチしている</strong>と感じたからです。<br />
            ・私が貴社に応募した理由は、<strong>貴社で働くことで「自分の特徴」を活かし、大きく成長することが出来る</strong>と考えたからです。<br />
            <br />
            「」の中には、以下のような内容を書くといいでしょう。<br />
            〇自分の考え<br />
            自分の理想の美容師像、自分が大切にしている考え<br />
            <br />
            〇サロンの行動<br />
            サロンの思い、サロンが行っている事業(ワックスの製造など)、働き方の特徴<br />
            <br />
            〇自分の特徴<br />
            性格、今までの経験、強み、好きなこと、今まで勉強したこと<br />
            <br />
            <strong>☆Point：サロンの思いや特徴ってどうやって調べるの？</strong><br />
            サロンの思いや特徴を調べる方法は、「サロンに行き、働いている人の話を聞く」と「サロンのHPを見る」の2つの方法があります。HPの文章からでもサロンの雰囲気はなんとなく分かりますが、働いている人の声を聞くことが一番ですので、第一志望のサロンへはなるべく行くようにしましょう！<br />
          </p>
          <h3 className={Styles.media_text_text_title}>サロンに入社したら、どのような活躍が出来るかを書く</h3><br />
          <p className={Styles.media_text_text}>
            ここでは、前に書いた会社の特徴や自分の特徴を踏まえて、自分はサロンでどのような働き方をしたいのかを書きましょう。ここでも大事なことは、サロン側の目線を持つことです。サロンの人に「こんな人が多いな」と思ってもらえるような文章にしましょう！<br />
            具体的には以下のような内容を書いて、自分がサロンで活躍できることを伝えましょう！<br />
            <br />
            <strong>貴社に入社した後は、「自分の強み」を活かして施術を行い、多くのお客様を笑顔にすることで理想の美容師像に近づくとともに、貴社に貢献していきたいと考えております。</strong><br />
            <br />
            いかがでしたでしょうか？皆さんの志望動機のイメージは固まりましたか？<br />
            ここまでくれば完璧な志望動機まであと一歩です！<br />
            最後にGOALDやocean tokyoに内定を貰った人の志望動機を掲載しているので、そちらを踏まえて解説をしていきます！<br />
          </p>
          <div className={Styles.media_introduction}>
            <div>
              <AiFillCheckCircle className={Styles.media_introduction_icon} />
              <h3>志望動機の書き方まとめ</h3>
            </div>
            <hr></hr>
            <ul>
              <li><span>・志望動機では「自分の特徴」と「サロンの特徴」が合致していることを伝える</span></li>
              <li><span>・「自分の特徴」では性格や強み、今まで体験してきたことを書く</span></li>
              <li><span>・「サロンの特徴」は、HPの閲覧やサロンへの来店で自分が感じたことを書く</span></li>
            </ul>
          </div>

          <div id='3' className={Styles.media_text_title}>
            <h2>3.大手サロンから内定を貰った志望動機を確認しよう！</h2>
          </div>
          <h3 className={Styles.media_text_text_title}>志望動機の例文</h3><br />
          <p className={Styles.media_text_text}>
            さて、ここからは実際に有名サロンから内定を貰った方の志望動機を見て、ポイントを確認していきましょう！<br />
            <br />
            <img src={Image2} className={Styles.media_img} alt='ACQUA 履歴書' />
            <br />
            <br />
            いかがでしょうか？読んでいて一緒に働きたいと思うような、魅力的な志望動機となっていますね！<br />
            皆さんもこのような志望動機が書けるよう、何度も文章を書いて「一回会ってみたい！」と美容院の方に思わせられるようにしましょう！<br />
          </p>
  
          <div id='4' className={Styles.media_text_title}>
            <h2>4.まとめ</h2>
          </div>
          <p className={Styles.media_text_text}>
            <br />
            以上で、志望動機の書き方における記事は終了です！<br />
            例文をみながらこの記事を読み返し、最高の志望動機を書いていきましょう！<br />
            本サイトでは、他にも美容学生に向けた記事を作成していますので、是非ご覧ください！！！<br />
            <br />
            この記事を読んだ皆さんが、第一志望のサロンに合格することを祈っています！<br />
          </p>
        </div>
      </div>
    </DocumentMeta>
    </>
  );
}


export default Article0001;
