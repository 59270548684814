import Styles from './styles.module.css';
import 'firebase/auth'
import React, { useState, useEffect } from 'react';
import firebase from "firebase/compat/app";
import { useNavigate } from 'react-router-dom';
import DefaultTitle from '../../../../package/parts/title/index';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import BackModal from '../12.back/index';
import Header from '../0.header/index';
import DefaultSlider from '../../../../package/parts/slider/index';

const RecruitPerson: React.FC = () => {

  const navigate = useNavigate();

  // 雰囲気
  const [ person1, setPerson1 ] = useState(1);
  const [ person2, setPerson2 ] = useState(1);
  const [ person3, setPerson3 ] = useState(1);
  const [ person4, setPerson4 ] = useState(1);
  const [ person5, setPerson5 ] = useState(1);
  
  const link: string = new URL(window.location.href).href;
  const recruitLink = decodeURIComponent(link.slice(link.indexOf('id=') + 3, link.length));
    
  useEffect(() => {
    window.scrollTo(0, 0);
    return onAuthStateChanged(getAuth(), (user) => {
      if (user) {
        firebase.firestore().collection("recruits").doc(recruitLink)
        .get().then((doc) => {
          const data = doc.data();
          if (data) {
            setPerson1(data.recruit_person1);
            setPerson2(data.recruit_person2);
            setPerson3(data.recruit_person3);
            setPerson4(data.recruit_person4);
            setPerson5(data.recruit_person5);
          }
        });
      } else {
        navigate('/company/login')
      }
    })
  }, [link]);

  function save(e: boolean) {

    firebase.firestore().collection("recruits").doc(recruitLink)
    .update({
      status_person: e,

      recruit_person1: Number(person1),
      recruit_person2: Number(person2),
      recruit_person3: Number(person3),
      recruit_person4: Number(person4),
      recruit_person5: Number(person5),

      last_time: new Date(),
    }).then(() => {
      navigate(`/edit/id=${recruitLink}`);
    });
  }

  const [ backStatus, setBackStatus ] = useState(false);

  return (
    <>

    <Header />

    <div className={Styles.box}>
      <div className={Styles.box_inline}>

        <div className={Styles.contents}>
          <h5 className={Styles.title}>基本情報</h5>
          
          <div className={Styles.store_contents}>

            <div>
              <DefaultTitle title={'行動指針'} required={true} />
              <DefaultSlider
                range1={'まずは考える'} range2={'とにかく行動'}
                value={person1} setValue={setPerson1}
              />
              <p className={Styles.desc}></p>

              <DefaultTitle title={'施術スタイル'} required={true} />
              <DefaultSlider
                range1={'オールマイティー'} range2={'専門性'}
                value={person2} setValue={setPerson2}
              />
              <p className={Styles.desc}></p>

              <DefaultTitle title={'重視する項目'} required={true} />
              <DefaultSlider
                range1={'再現性'} range2={'創造性'}
                value={person3} setValue={setPerson3}
              />
              <p className={Styles.desc}></p>

              <DefaultTitle title={'キャリアプラン'} required={true} />
              <DefaultSlider
                range1={'チームリーダー'} range2={'トッププレイヤー'}
                value={person4} setValue={setPerson4}
              />
              <p className={Styles.desc}></p>

              <DefaultTitle title={'アピールポイント'} required={true} />
              <DefaultSlider
                range1={'コミュニケーション重視'} range2={'技術重視'}
                value={person5} setValue={setPerson5}
              />
              <p className={Styles.desc}></p>

            </div>
          </div>

        </div>

        <div className={Styles.save_box}>
          <button className={Styles.save_box_now} onClick={() => setBackStatus(true)}>戻る</button>
          <button className={Styles.save_box_now} onClick={() => save(false)}>一時保存</button>
          <button className={Styles.save_box_save} onClick={() => save(true)}>保存</button>
        </div>
        
      </div>
    </div>

    {backStatus ?
      <BackModal setBackStatus={setBackStatus} />
    : <></> }
    </>
  );
}

export default RecruitPerson;