import Styles from './styles.module.css';
import React, { useState, useEffect } from 'react';
import Header from '../../0.header/index';
import MakeStore from './2.make/index';
import EditStore from './3.edit/index';
import DeleteStore from './4.delete/index';
import DefaultImage from './../../../../../image/default/home.png';
import { useNavigate } from 'react-router-dom';
import firebase from "firebase/compat/app";
import { FaChevronRight, FaRegCheckCircle } from "react-icons/fa";
import { BsThreeDots } from "react-icons/bs";
import { collection, onSnapshot, query, orderBy, where } from 'firebase/firestore';
import { useSelector } from 'react-redux';

const RecruitEditStore: React.FC = () => {
  
  const navigate = useNavigate();

  const company = useSelector((state: any) => state.company.company);

  const link: string = new URL(window.location.href).href;
  const recruitLink = decodeURIComponent(link.slice(link.indexOf('id=') + 3, link.length));

  const [ storeList, setStoreList ] = useState<storeList[]>([]);
  const [ makeStore, setMakeStore ] = useState(false);
  const [ deleteStore, setDeleteStore ] = useState('');

  const [ desc, setDesc ] = useState('');
  const [ editInfo, setEditInfo ] = useState<editInfoList>({ id: '', name: '' });

  interface storeList {
    id: string,
    name: string,
    image: string,
    statusPlace: boolean,
    statusPentagon: boolean,
    statusType: boolean,
  }

  interface editInfoList {
    id: string,
    name: string,
  }

  useEffect(() => {
    if (company.length !== 0) {
      getRecruits();
    }
  }, [company]);

  function getRecruits() {
    const docRef = collection(firebase.firestore(), 'stores')
    const queryRef = query(docRef, where('companyId', '==', company.id))
    const queryRefs = query(queryRef, orderBy('number', 'asc'))
    const unsub = onSnapshot(queryRefs, snapshot => {
      var tmp: storeList[] = [];
      snapshot.forEach((doc) => {
        tmp.push({
          id: doc.id,
          name: doc.data().name,
          image: doc.data().image,
          statusPlace: doc.data().status_place,
          statusPentagon: doc.data().status_pentagon,
          statusType: doc.data().status_type,
        })
      });
      setStoreList(tmp);
    })
    return () => unsub();
  }

  return (
    <>
    <Header />

    <div className={Styles.box} onClick={(e) => e.target === e.currentTarget && setDesc('')}>

      <h4 className={Styles.store_title}>店 舗 編 集 （{storeList.length}件）</h4>

      <div className={Styles.store_list}>
        {storeList.map((data, index) =>
          <div className={Styles.store_list_items} key={index}>
            <img src={`https://firebasestorage.googleapis.com/v0/b/trankllc-com.appspot.com/o/stores%2Fresize_images%2F${data.image}_500x500?alt=media&token=?${new Date().getTime()}`}
              onError={e => {
                e.currentTarget.onerror = null;
                e.currentTarget.src = DefaultImage;
              }}/>
            <div className={Styles.store_list_items_right}>
              <div className={Styles.store_list_items_right_top}>
                <h4>{data.name}</h4>
                <div className={Styles.store_list_items_right_top_details}>
                  <BsThreeDots className={Styles.store_dots} onClick={() => setDesc(data.id)} />
                  {desc === data.id ?
                    <div>
                      <p onClick={() => setEditInfo({ id: data.id,  name: data.name })}>編集する</p>
                      <p onClick={() => setDeleteStore(data.id)}>削除する</p>
                    </div>
                  : <></> }
                </div>
              </div>

              <div className={Styles.store_list_items_right_bottom}>
                <div onClick={() => navigate(`/edit/area/id=${data.id}`)}
                  style={data.statusPlace ? {border : '1px solid #222222', boxShadow : 'none'} : {border : '1px solid #AD0200'}}>
                  <p style={data.statusPlace ? {color : '#222222'} : {color : '#AD0200'}}>最寄り駅</p>
                  {data.statusPlace ?
                    <FaRegCheckCircle className={Styles.store_items_icons_red} />
                    :
                    <FaChevronRight className={Styles.store_items_icons} />
                  }
                </div>
                <div onClick={() => navigate(`/edit/pentagon/id=${data.id}`)}
                  style={data.statusPentagon ? {border : '1px solid #222222', boxShadow : 'none'} : {border : '1px solid #AD0200'}}>
                  <p style={data.statusPentagon ? {color : '#222222'} : {color : '#AD0200'}}>六角形</p>
                  {data.statusPentagon ?
                    <FaRegCheckCircle className={Styles.store_items_icons_red} />
                    :
                    <FaChevronRight className={Styles.store_items_icons} />
                  }
                </div>
                <div onClick={() => navigate(`/edit/type/id=${data.id}`)}
                  style={data.statusType ? {border : '1px solid #222222', boxShadow : 'none'} : {border : '1px solid #AD0200'}}>
                  <p style={data.statusType ? {color : '#222222'} : {color : '#AD0200'}}>8タイプ診断</p>
                  {data.statusType ?
                    <FaRegCheckCircle className={Styles.store_items_icons_red} />
                    :
                    <FaChevronRight className={Styles.store_items_icons} />
                  }
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      <div className={Styles.button}>
        <button onClick={() => navigate(`/edit/storeSelect/id=${recruitLink}`)}>戻る</button>
        <button onClick={() => setMakeStore(true)}>店舗追加</button>
      </div>

    </div>

    {makeStore ?
      <MakeStore companyId={company.id} setMakeStore={setMakeStore} length={storeList.length} />
    : <></> }

    {editInfo.id !== '' ?
      <EditStore setEditInfo={setEditInfo} editInfo={editInfo} />
    : <></> }

    {deleteStore !== '' ?
      <DeleteStore setDeleteStore={setDeleteStore} deleteStore={deleteStore} />
    : <></> }

    </>
  );
}

export default RecruitEditStore;
