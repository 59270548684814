import './styles.css';
import React, { useState } from 'react';
import Worry1 from './../../../image/landing/management/1.png';
import Worry2 from './../../../image/landing/management/2.png';
import Worry3 from './../../../image/landing/management/3.png';
import Worry4 from './../../../image/landing/management/4.png';
import Worry5 from './../../../image/landing/management/5.png';
import Worry6 from './../../../image/landing/management/6.png';

const LandingManagement: React.FC = () => {

  const [ navigation, setNavigation ] = useState(1);

  return (
    <>
    <div className='works_landing_flow'>
      <div className='works_landing_flow_title'>
        <div></div>
        <h4>応募から採用までの流れ</h4>
      </div>
      <p>応募管理から採用までをシンプルな操作でこなす事ができます。</p>
      <div className='works_landing_flow_screen'>
        <div className='works_landing_flow_management_title'>
          <div>
            <h4 style={navigation === 1 ? {backgroundColor : '#192651', color: 'white'} : {backgroundColor : 'white', color: '#EBEBEB'}}
              onClick={() => setNavigation(1)}>応募者管理</h4>
            <h4 style={navigation === 2 ? {backgroundColor : '#192651', color: 'white'} : {backgroundColor : 'white', color: '#EBEBEB'}}
              onClick={() => setNavigation(2)}>履歴書確認</h4>
            <h4 style={navigation === 3 ? {backgroundColor : '#192651', color: 'white'} : {backgroundColor : 'white', color: '#EBEBEB'}}
              onClick={() => setNavigation(3)}>メッセージ</h4>
            <h4 style={navigation === 4 ? {backgroundColor : '#192651', color: 'white'} : {backgroundColor : 'white', color: '#EBEBEB'}}
              onClick={() => setNavigation(4)}>スケジュール</h4>
            <h4 style={navigation === 5 ? {backgroundColor : '#192651', color: 'white'} : {backgroundColor : 'white', color: '#EBEBEB'}}
              onClick={() => setNavigation(5)}>評価</h4>
            <h4 style={navigation === 6 ? {backgroundColor : '#192651', color: 'white'} : {backgroundColor : 'white', color: '#EBEBEB'}}
              onClick={() => setNavigation(6)}>評価管理</h4>
          </div>
        </div>
        <div className='works_landing_flow_screen_screen'>
          {navigation === 1 ?
            <img src={Worry1} alt='' />
          : navigation === 2 ?
            <img src={Worry2} alt='' />
          : navigation === 3 ?
            <img src={Worry3} alt='' />
          : navigation === 4 ?
            <img src={Worry4} alt='' />
          : navigation === 5 ?
            <img src={Worry5} alt='' />
          : navigation === 6 ?
            <img src={Worry6} alt='' />
          : <></>
          }
        </div>
      </div>
    </div>
    </>
  );
}

export default LandingManagement;
