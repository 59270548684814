import Styles from './styles.module.css';
import Items from './items/index';
import firebase from 'firebase/compat/app';
import { useState, useEffect } from 'react';
import { FaSearch } from "react-icons/fa";
import { collection, onSnapshot, query, where } from 'firebase/firestore';
import Navigation from "./nav/index";
// import History from "../../package/info/pc/history/index";

import { fetchManageApplicants } from '../../features/manage/applicants';
import { useDispatch, useSelector } from 'react-redux';
import React from 'react';

interface Applicant {
  id: string;
  recruitId: string;
  name: string;
  uid: string;
  examination: number;
  sex: string;
  time: string;
  managerMemo: string;
  hopeStore: string[];
  examList1: string[];
  examList2: string[];
  examList3: string[];
  examList4: string[];
  examList5: string[];
}

interface recruitList {
  id: string;
  status: number;
  title: string;
  employment: string;
  time: string;
  pv: number;
  saves: number;
  applicants: string[];
  recruitment: number;
  goal: number;
  apply: number;
}

const ManagePc: React.FC = () => {

  const dispatch = useDispatch();

  const users = useSelector((state: any) => state.auth.users);
  const recruitList = useSelector((state: any) => state.recruit.recruitList as recruitList[]);
  const company = useSelector((state: any) => state.company.company);
  const recruitListOnlyId = useSelector((state: any) => state.recruit.recruitIds);

  const [ choiceRecruit, setChoiceRecruit ] = useState(1000);
  const [ choiceNav, setChoiceNav ] = useState(1000);

  const [ searchText, setSearchText ] = useState('');

  const applicantsListTrue = useSelector((state: any) => state.manageApplicants.applicantsListTrue) as Applicant[];
  const applicantsListFalse = useSelector((state: any) => state.manageApplicants.applicantsListFalse) as Applicant[];

  useEffect(() => {    
    const fetchData = async () => {
      await dispatch(fetchManageApplicants({ uid: users.uid, ids: recruitListOnlyId}));
    }
    fetchData();
  }, [dispatch, recruitListOnlyId]);


  const isAllIncludes = (arr: string[], target: string | any[]) => arr.some(el => target.includes(el));

  return (
    <>
    <div className='works_service'>

      <Navigation 
        choiceNav={choiceNav} setChoiceNav={setChoiceNav}
        choiceRecruit={choiceRecruit}
      />

      <div className={Styles.manage}>
        
        <div className={Styles.manage_header}>
          <div className={Styles.manage_header_word}>
            <select 
              onChange={async (e) => {
                if (e.target.value === '')  {
                  setChoiceRecruit(1000);
                } else {
                  setChoiceRecruit(Number(e.target.value));
                }
              }}>
              <option value=''>全ての求人</option>
              {recruitList.map((data, index) =>
                <option value={index}>{data.title}</option>
              )}
            </select>
            <div>
              <FaSearch className={Styles.manage_header_word_icon} />
              <input placeholder='応募者名 検索' value={searchText} onChange={(e) => setSearchText(e.target.value)} />
            </div>
          </div>
          <div className={Styles.manage_table}>
            <p className={Styles.manage_table_1}>応募者名</p>
            <p className={Styles.manage_table_2}>性別</p>
            <p className={Styles.manage_table_3}>応募日時</p>
            <p className={Styles.manage_table_5}>希望店舗</p>
            <p className={Styles.manage_table_4}>メモ</p>
            <p className={Styles.manage_table_6}>審査</p>
            <p className={Styles.manage_table_6}>保存</p>
            {company.administrator && company?.administrator?.includes(users.uid) ?
              <p className={Styles.manage_table_7}>連絡</p>
            : <></> }
          </div>
        </div>

        {applicantsListTrue.map((data) =>
        <>
          {choiceRecruit === 1000 && (
          <>
            {data.examination === choiceNav && isAllIncludes([searchText], data.name + data.managerMemo) && (
              <Items applicant={data} save={true} navigation={choiceNav} />
            )}

            {choiceNav === 1000 && isAllIncludes([searchText], data.name + data.managerMemo) && (
              <Items applicant={data} save={true} navigation={choiceNav} />
            )}
          </> )}

          {choiceRecruit !== 1000 && recruitList[choiceRecruit]?.id === data.recruitId && (
          <>
            {data.examination === choiceNav && isAllIncludes([searchText], data.name + data.managerMemo) && (
              <Items applicant={data} save={true} navigation={choiceNav} />
            )}

            {choiceNav === 1000 && isAllIncludes([searchText], data.name + data.managerMemo) && (
              <Items applicant={data} save={true} navigation={choiceNav} />
            )}
          </>)}
        </> )}
        
        {applicantsListFalse.map((data) =>
        <>
          {choiceRecruit === 1000 && (
          <>
            {data.examination === choiceNav && isAllIncludes([searchText], data.name + data.managerMemo) && (
              <Items applicant={data} save={false} navigation={choiceNav} />
            )}

            {choiceNav === 1000 && isAllIncludes([searchText], data.name + data.managerMemo) && (
              <Items applicant={data} save={false} navigation={choiceNav} />
            )}
          </> )}
          
          {choiceRecruit !== 1000 && recruitList[choiceRecruit]?.id === data.recruitId && (
          <>
            {data.examination === choiceNav && isAllIncludes([searchText], data.name + data.managerMemo) && (
              <Items applicant={data} save={false} navigation={choiceNav} />
            )}

            {choiceNav === 1000 && isAllIncludes([searchText], data.name + data.managerMemo) && (
              <Items applicant={data} save={false} navigation={choiceNav} />
            )}
          </>)}

        </> )}
      </div>
    </div>

    {/* {history && recruitList.length !== 0 && applicantsList.length !== 0 ?
      <History recruitList={recruitList} applicantsList={applicantsList} setHistory={setHistory} />
    : <></> } */}
    </>
  );
}

export default ManagePc;
