import Styles from './styles.module.css';
import React, { useState, useEffect } from 'react';
import firebase from 'firebase/compat/app';
import DefaultTitle from "../../../package/parts/title/index";
import DefaultTextarea1 from '../../../package/parts/1-textarea/index';
import { MdError } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

type evaluationProps = {
  applicant: ApplicantList;
  setExamPanelToggle: React.Dispatch<React.SetStateAction<boolean>>;
};

type ApplicantList = {
  id: string;
  recruitId: string;
  name: string;
  uid: string;
  examination: number;
  sex: string;
  time: string;
  managerMemo: string;
  hopeStore: string[];
  examList1: string[];
  examList2: string[];
  examList3: string[];
  examList4: string[];
  examList5: string[];
};

const EvaluationPc: React.FC<evaluationProps> = ({ applicant, setExamPanelToggle }) => {

  const users = useSelector((state: any) => state.auth.users);

  const company = useSelector((state: any) => state.company.company);

  const navigate = useNavigate();

  const [ recruitList, setRecruitList ] = useState<recruitList>({
    id: '',
    selectionCount: 0,
    selectionText1: '',
    selectionText2: '',
    selectionText3: '',
    selectionText4: '',
    selectionText5: '',
    selectionText6: '',
    selectionText7: '',
    selectionText8: '',
  });

  const [ errorMessage, setErrorMessage ] = useState('');
  const [ banner, setBanner ] = useState(false);

  const [ score1, setScore1 ] = useState<number>(0);
  const [ comment1, setComment1 ] = useState('');
  const [ score2, setScore2 ] = useState<number>(0);
  const [ comment2, setComment2 ] = useState('');
  const [ score3, setScore3 ] = useState<number>(0);
  const [ comment3, setComment3 ] = useState('');
  const [ score4, setScore4 ] = useState<number>(0);
  const [ comment4, setComment4 ] = useState('');
  const [ score5, setScore5 ] = useState<number>(0);
  const [ comment5, setComment5 ] = useState('');
  const [ score6, setScore6 ] = useState<number>(0);
  const [ comment6, setComment6 ] = useState('');
  const [ score7, setScore7 ] = useState<number>(0);
  const [ comment7, setComment7 ] = useState('');
  const [ score8, setScore8 ] = useState<number>(0);
  const [ comment8, setComment8 ] = useState('');

  interface recruitList {
    id: string;
    selectionCount: number;
    selectionText1: string;
    selectionText2: string;
    selectionText3: string;
    selectionText4: string;
    selectionText5: string;
    selectionText6: string;
    selectionText7: string;
    selectionText8: string;
  };

  useEffect(() => {
    firebase.firestore().collection('recruits').doc(applicant.recruitId).get()
    .then((doc) => {
      if (applicant.examination === 1) {
        setRecruitList({
          id: doc.id,
          selectionCount: doc.data()?.exam_selection1Count || 0,
          selectionText1: doc.data()?.exam_selection1Text1 || "",
          selectionText2: doc.data()?.exam_selection1Text2 || "",
          selectionText3: doc.data()?.exam_selection1Text3 || "",
          selectionText4: doc.data()?.exam_selection1Text4 || "",
          selectionText5: doc.data()?.exam_selection1Text5 || "",
          selectionText6: doc.data()?.exam_selection1Text6 || "",
          selectionText7: doc.data()?.exam_selection1Text7 || "",
          selectionText8: doc.data()?.exam_selection1Text8 || "",
        });
      } else if (applicant.examination === 2) {
        setRecruitList({
          id: doc.id,
          selectionCount: doc.data()?.exam_selection2Count || 0,
          selectionText1: doc.data()?.exam_selection2Text1 || "",
          selectionText2: doc.data()?.exam_selection2Text2 || "",
          selectionText3: doc.data()?.exam_selection2Text3 || "",
          selectionText4: doc.data()?.exam_selection2Text4 || "",
          selectionText5: doc.data()?.exam_selection2Text5 || "",
          selectionText6: doc.data()?.exam_selection2Text6 || "",
          selectionText7: doc.data()?.exam_selection2Text7 || "",
          selectionText8: doc.data()?.exam_selection2Text8 || "",
        });
      } else if (applicant.examination === 3) {
        setRecruitList({
          id: doc.id,
          selectionCount: doc.data()?.exam_selection3Count || 0,
          selectionText1: doc.data()?.exam_selection3Text1 || "",
          selectionText2: doc.data()?.exam_selection3Text2 || "",
          selectionText3: doc.data()?.exam_selection3Text3 || "",
          selectionText4: doc.data()?.exam_selection3Text4 || "",
          selectionText5: doc.data()?.exam_selection3Text5 || "",
          selectionText6: doc.data()?.exam_selection3Text6 || "",
          selectionText7: doc.data()?.exam_selection3Text7 || "",
          selectionText8: doc.data()?.exam_selection3Text8 || "",
        });
      } else if (applicant.examination === 4) {
        setRecruitList({
          id: doc.id,
          selectionCount: doc.data()?.exam_selection4Count || 0,
          selectionText1: doc.data()?.exam_selection4Text1 || "",
          selectionText2: doc.data()?.exam_selection4Text2 || "",
          selectionText3: doc.data()?.exam_selection4Text3 || "",
          selectionText4: doc.data()?.exam_selection4Text4 || "",
          selectionText5: doc.data()?.exam_selection4Text5 || "",
          selectionText6: doc.data()?.exam_selection4Text6 || "",
          selectionText7: doc.data()?.exam_selection4Text7 || "",
          selectionText8: doc.data()?.exam_selection4Text8 || "",
        });
      } else if (applicant.examination === 5) {
        setRecruitList({
          id: doc.id,
          selectionCount: doc.data()?.exam_selection5Count || 0,
          selectionText1: doc.data()?.exam_selection5Text1 || "",
          selectionText2: doc.data()?.exam_selection5Text2 || "",
          selectionText3: doc.data()?.exam_selection5Text3 || "",
          selectionText4: doc.data()?.exam_selection5Text4 || "",
          selectionText5: doc.data()?.exam_selection5Text5 || "",
          selectionText6: doc.data()?.exam_selection5Text6 || "",
          selectionText7: doc.data()?.exam_selection5Text7 || "",
          selectionText8: doc.data()?.exam_selection5Text8 || "",
        });
      }
    });
  }, []);
  
  function check() {

    var target = document.getElementById('contents');
	  target!.scrollTop = 0;

    if (1 <= Number(recruitList.selectionCount) && Number(recruitList.selectionCount) <= 8 && score1 === 0) {
      setErrorMessage('審査①を入力してください。');
    } else if (2 <= Number(recruitList.selectionCount) && Number(recruitList.selectionCount) <= 8 && score2 === 0) {
      setErrorMessage('審査②を入力してください。');
    } else if (3 <= Number(recruitList.selectionCount) && Number(recruitList.selectionCount) <= 8 && score3 === 0) {
      setErrorMessage('審査③を入力してください。');
    } else if (4 <= Number(recruitList.selectionCount) && Number(recruitList.selectionCount) <= 8 && score4 === 0) {
      setErrorMessage('審査④を入力してください。');
    } else if (5 <= Number(recruitList.selectionCount) && Number(recruitList.selectionCount) <= 8 && score5 === 0) {
      setErrorMessage('審査⑤を入力してください。');
    } else if (6 <= Number(recruitList.selectionCount) && Number(recruitList.selectionCount) <= 8 && score6 === 0) {
      setErrorMessage('審査⑥を入力してください。');
    } else if (7 <= Number(recruitList.selectionCount) && Number(recruitList.selectionCount) <= 8 && score7 === 0) {
      setErrorMessage('審査⑦を入力してください。');
    } else if (8 <= Number(recruitList.selectionCount) && Number(recruitList.selectionCount) <= 8 && score8 === 0) {
      setErrorMessage('審査⑧を入力してください。');
    } else {
      save();
    }
  }

  function save() {
    setBanner(true);

    var docId = applicant.examination + '_' + users.uid;
  
    firebase.firestore().collection('applicants').doc(applicant.id).collection('examination').doc(docId)
    .set({
      uid: users.uid,
      name: users.name,
      
      evaluation: applicant.examination,

      evaluation1Score: score1,
      evaluation1Comment: comment1,
      evaluation2Score: score2,
      evaluation2Comment: comment2,
      evaluation3Score: score3,
      evaluation3Comment: comment3,
      evaluation4Score: score4,
      evaluation4Comment: comment4,
      evaluation5Score: score5,
      evaluation5Comment: comment5,
      evaluation6Score: score6,
      evaluation6Comment: comment6,
      evaluation7Score: score7,
      evaluation7Comment: comment7,
      evaluation8Score: score8,
      evaluation8Comment: comment8,
    })
    
    firebase.firestore().collection('applicants').doc(applicant.id)
    .update(
      Number(applicant.examination) === 1 ?
        {examList1 : firebase.firestore.FieldValue.arrayUnion(users.uid)}
      : Number(applicant.examination) === 2 ?
        {examList2 : firebase.firestore.FieldValue.arrayUnion(users.uid)}
      : Number(applicant.examination) === 3 ?
        {examList3 : firebase.firestore.FieldValue.arrayUnion(users.uid)}
      : Number(applicant.examination) === 4 ?
        {examList4 : firebase.firestore.FieldValue.arrayUnion(users.uid)}
      : Number(applicant.examination) === 5 ?
        {examList5 : firebase.firestore.FieldValue.arrayUnion(users.uid)}
      : {}
    ).then(() => setExamPanelToggle(false));

    setTimeout(() => {
      setBanner(false);
    }, 1500);
  }

  return (
    <>
    <div className={Styles.box}>
      <div className={Styles.box_black} onClick={() => setExamPanelToggle(false)}></div>
      <div className={Styles.box_contents} id='contents'>

        {errorMessage !== '' ?
          <div className={Styles.error}>
            <MdError className={Styles.error_icon} />
            <h4>エラー：{errorMessage}</h4>
          </div>
        : <></> }

        {recruitList.selectionCount === 0 ?
          <div>
            <p className={Styles.setting_desc}>
              現在、選考評価の設定が行われていません。<br />応募者の設定を行う場合は以下の選考評価の設定ボタンより評価設定を行ってください。</p>
            <button className={Styles.setting_btn}
              onClick={() => navigate(`/edit/evaluation/id=${company.id}+page=2+recruitId=${recruitList.id}`)}
            >選考評価の設定</button>
            <p className={Styles.setting_attention}>※選考評価の設定は「求人管理画面 {">"} 求人 {">"} 詳細ボタン {">"} 評価設定」より設定を行うことが出来ます。</p>
          </div>
          :
          <div>
            <h4 className={Styles.title}>{applicant.examination}次選考　{applicant.name}</h4>

            {1 <= Number(recruitList.selectionCount) && Number(recruitList.selectionCount) <= 8 ?
              <div className='works_service_info_evaluation'>
                <div className='works_service_manage_contents_examination_table_score'>
                  <DefaultTitle title={`審査①：${recruitList.selectionText1}`} required={true} />
                  <div className='works_service_manage_contents_examination_table_score_results'>
                    <p onClick={() => setScore1(4)} 
                      style={score1 === 4 ? {backgroundColor : '#AD0200', color : 'white'} : {}}>◎</p>
                    <p onClick={() => setScore1(3)} 
                      style={score1 === 3 ? {backgroundColor : '#AD0200', color : 'white'} : {}}>○</p>
                    <p onClick={() => setScore1(2)} 
                      style={score1 === 2 ? {backgroundColor : '#AD0200', color : 'white'} : {}}>△</p>
                    <p onClick={() => setScore1(1)} 
                      style={score1 === 1 ? {backgroundColor : '#AD0200', color : 'white'} : {}}>×</p>
                  </div>
                </div>
                <DefaultTextarea1
                  title={'コメント'} required={false} length={100} type={'text'} 
                  place={'コメント'}
                  value={comment1} setValue={setComment1}
                  desc={'　'}
                />
              </div>
            : <></> }
            {2 <= Number(recruitList.selectionCount) && Number(recruitList.selectionCount) <= 8 ?
              <div className='works_service_info_evaluation'>
                <div className='works_service_manage_contents_examination_table_score'>
                  <DefaultTitle title={`審査②：${recruitList.selectionText2}`} required={true} />
                  <div className='works_service_manage_contents_examination_table_score_results'>
                    <p onClick={() => setScore2(4)} 
                      style={score2 === 4 ? {backgroundColor : '#AD0200', color : 'white'} : {}}>◎</p>
                    <p onClick={() => setScore2(3)} 
                      style={score2 === 3 ? {backgroundColor : '#AD0200', color : 'white'} : {}}>○</p>
                    <p onClick={() => setScore2(2)} 
                      style={score2 === 2 ? {backgroundColor : '#AD0200', color : 'white'} : {}}>△</p>
                    <p onClick={() => setScore2(1)} 
                      style={score2 === 1 ? {backgroundColor : '#AD0200', color : 'white'} : {}}>×</p>
                  </div>
                </div>
                <DefaultTextarea1
                  title={'コメント'} required={false} length={100} type={'text'} 
                  place={'コメント'}
                  value={comment2} setValue={setComment2}
                  desc={'　'}
                />
              </div>
            : <></> }
            {3 <= Number(recruitList.selectionCount) && Number(recruitList.selectionCount) <= 8 ?
              <div className='works_service_info_evaluation'>
                <div className='works_service_manage_contents_examination_table_score'>
                  <DefaultTitle title={`審査③：${recruitList.selectionText3}`} required={true} />
                  <div className='works_service_manage_contents_examination_table_score_results'>
                    <p onClick={() => setScore3(4)} 
                      style={score3 === 4 ? {backgroundColor : '#AD0200', color : 'white'} : {}}>◎</p>
                    <p onClick={() => setScore3(3)} 
                      style={score3 === 3 ? {backgroundColor : '#AD0200', color : 'white'} : {}}>○</p>
                    <p onClick={() => setScore3(2)} 
                      style={score3 === 2 ? {backgroundColor : '#AD0200', color : 'white'} : {}}>△</p>
                    <p onClick={() => setScore3(1)} 
                      style={score3 === 1 ? {backgroundColor : '#AD0200', color : 'white'} : {}}>×</p>
                  </div>
                </div>
                <DefaultTextarea1
                  title={'コメント'} required={false} length={100} type={'text'} 
                  place={'コメント'}
                  value={comment3} setValue={setComment3}
                  desc={'　'}
                />
              </div>
            : <></> }
            {4 <= Number(recruitList.selectionCount) && Number(recruitList.selectionCount) <= 8 ?
              <div className='works_service_info_evaluation'>
                <div className='works_service_manage_contents_examination_table_score'>
                  <DefaultTitle title={`審査④：${recruitList.selectionText4}`} required={true} />
                  <div className='works_service_manage_contents_examination_table_score_results'>
                    <p onClick={() => setScore4(4)} 
                      style={score4 === 4 ? {backgroundColor : '#AD0200', color : 'white'} : {}}>◎</p>
                    <p onClick={() => setScore4(3)} 
                      style={score4 === 3 ? {backgroundColor : '#AD0200', color : 'white'} : {}}>○</p>
                    <p onClick={() => setScore4(2)} 
                      style={score4 === 2 ? {backgroundColor : '#AD0200', color : 'white'} : {}}>△</p>
                    <p onClick={() => setScore4(1)} 
                      style={score4 === 1 ? {backgroundColor : '#AD0200', color : 'white'} : {}}>×</p>
                  </div>
                </div>
                <DefaultTextarea1
                  title={'コメント'} required={false} length={100} type={'text'} 
                  place={'コメント'}
                  value={comment4} setValue={setComment4}
                  desc={'　'}
                />
              </div>
            : <></> }
            {5 <= Number(recruitList.selectionCount) && Number(recruitList.selectionCount) <= 8 ?
              <div className='works_service_info_evaluation'>
                <div className='works_service_manage_contents_examination_table_score'>
                  <DefaultTitle title={`審査⑤：${recruitList.selectionText5}`} required={true} />
                  <div className='works_service_manage_contents_examination_table_score_results'>
                    <p onClick={() => setScore5(4)} 
                      style={score5 === 4 ? {backgroundColor : '#AD0200', color : 'white'} : {}}>◎</p>
                    <p onClick={() => setScore5(3)} 
                      style={score5 === 3 ? {backgroundColor : '#AD0200', color : 'white'} : {}}>○</p>
                    <p onClick={() => setScore5(2)} 
                      style={score5 === 2 ? {backgroundColor : '#AD0200', color : 'white'} : {}}>△</p>
                    <p onClick={() => setScore5(1)} 
                      style={score5 === 1 ? {backgroundColor : '#AD0200', color : 'white'} : {}}>×</p>
                  </div>
                </div>
                <DefaultTextarea1
                  title={'コメント'} required={false} length={100} type={'text'} 
                  place={'コメント'}
                  value={comment5} setValue={setComment5}
                  desc={'　'}
                />
              </div>
            : <></> }
            {6 <= Number(recruitList.selectionCount) && Number(recruitList.selectionCount) <= 8 ?
              <div className='works_service_info_evaluation'>
                <div className='works_service_manage_contents_examination_table_score'>
                  <DefaultTitle title={`審査⑥：${recruitList.selectionText6}`} required={true} />
                  <div className='works_service_manage_contents_examination_table_score_results'>
                    <p onClick={() => setScore6(4)} 
                      style={score6 === 4 ? {backgroundColor : '#AD0200', color : 'white'} : {}}>◎</p>
                    <p onClick={() => setScore6(3)} 
                      style={score6 === 3 ? {backgroundColor : '#AD0200', color : 'white'} : {}}>○</p>
                    <p onClick={() => setScore6(2)} 
                      style={score6 === 2 ? {backgroundColor : '#AD0200', color : 'white'} : {}}>△</p>
                    <p onClick={() => setScore6(1)} 
                      style={score6 === 1 ? {backgroundColor : '#AD0200', color : 'white'} : {}}>×</p>
                  </div>
                </div>
                <DefaultTextarea1
                  title={'コメント'} required={false} length={100} type={'text'} 
                  place={'コメント'}
                  value={comment6} setValue={setComment6}
                  desc={'　'}
                />
              </div>
            : <></> }
            {7 <= Number(recruitList.selectionCount) && Number(recruitList.selectionCount) <= 8 ?
              <div className='works_service_info_evaluation'>
                <div className='works_service_manage_contents_examination_table_score'>
                  <DefaultTitle title={`審査⑦：${recruitList.selectionText7}`} required={true} />
                  <div className='works_service_manage_contents_examination_table_score_results'>
                    <p onClick={() => setScore7(4)} 
                      style={score7 === 4 ? {backgroundColor : '#AD0200', color : 'white'} : {}}>◎</p>
                    <p onClick={() => setScore7(3)} 
                      style={score7 === 3 ? {backgroundColor : '#AD0200', color : 'white'} : {}}>○</p>
                    <p onClick={() => setScore7(2)} 
                      style={score7 === 2 ? {backgroundColor : '#AD0200', color : 'white'} : {}}>△</p>
                    <p onClick={() => setScore7(1)} 
                      style={score7 === 1 ? {backgroundColor : '#AD0200', color : 'white'} : {}}>×</p>
                  </div>
                </div>
                <DefaultTextarea1
                  title={'コメント'} required={false} length={100} type={'text'} 
                  place={'コメント'}
                  value={comment7} setValue={setComment7}
                  desc={'　'}
                />
              </div>
            : <></> }
            {8 <= Number(recruitList.selectionCount) && Number(recruitList.selectionCount) <= 8 ?
              <div className='works_service_info_evaluation'>
                <div className='works_service_manage_contents_examination_table_score'>
                  <DefaultTitle title={`審査⑧：${recruitList.selectionText8}`} required={true} />
                  <div className='works_service_manage_contents_examination_table_score_results'>
                    <p onClick={() => setScore8(4)} 
                      style={score8 === 4 ? {backgroundColor : '#AD0200', color : 'white'} : {}}>◎</p>
                    <p onClick={() => setScore8(3)} 
                      style={score8 === 3 ? {backgroundColor : '#AD0200', color : 'white'} : {}}>○</p>
                    <p onClick={() => setScore8(2)} 
                      style={score8 === 2 ? {backgroundColor : '#AD0200', color : 'white'} : {}}>△</p>
                    <p onClick={() => setScore8(1)} 
                      style={score8 === 1 ? {backgroundColor : '#AD0200', color : 'white'} : {}}>×</p>
                  </div>
                </div>
                <DefaultTextarea1
                  title={'コメント'} required={false} length={100} type={'text'} 
                  place={'コメント'}
                  value={comment8} setValue={setComment8}
                  desc={'　'}
                />
              </div>
            : <></> }

            <div className={Styles.button}>
              <button style={{color: '#AD0200', backgroundColor: 'white'}} onClick={() => setExamPanelToggle(false)}>キャンセル</button>
              <button style={{color: 'white', backgroundColor: '#AD0200'}} onClick={() => check()}>送信</button>
            </div>

          </div>
        }
      </div>
    </div>

    {banner ?
      <div className='works_banner'>
        保存しました。
      </div> : <></>
    }
    </>
  );
}

export default EvaluationPc;


