import React from 'react';
import Styles from './styles.module.css';

type RecruitPersonProps = {
  bar1: number;
  bar2: number;
  bar3: number;
  bar4: number;
  bar5: number;
};

const RecruitPerson: React.FC<RecruitPersonProps> = ({ bar1, bar2, bar3, bar4, bar5, }) => {


  return (
    <>
    <div className={Styles.person}>

      <h5>求める人物像</h5>

      <div className={Styles.person_bar} style={{backgroundColor : '#e8f9f5'}}>
        <h4>行動指針</h4>
        <div>
          <div className={Styles.bar_status_title}>
            <h5>まずは考える</h5>
            <h5>とにかく行動</h5>
          </div>
          
          <div className={Styles.bar_status_bar}>
            <div style={{backgroundColor : bar1 === 1 ? '#448374' : '#b9e6db'}}></div>
            <div style={{backgroundColor : bar1 === 2 ? '#448374' : '#b9e6db'}}></div>
            <div style={{backgroundColor : bar1 === 3 ? '#448374' : '#b9e6db'}}></div>
            <div style={{backgroundColor : bar1 === 4 ? '#448374' : '#b9e6db'}}></div>
            <div style={{backgroundColor : bar1 === 5 ? '#448374' : '#b9e6db'}}></div>
          </div>
        </div>
      </div>

      <div className={Styles.person_bar} style={{backgroundColor : '#fff5ec'}}>
        <h4>施術スタイル</h4>
        <div className={Styles.bar_status}>
          <div className={Styles.bar_status_title}>
            <h5>オールマイティー</h5>
            <h5>専門性</h5>
          </div>
          <div className={Styles.bar_status_bar}>
            <div style={{backgroundColor : bar2 === 1 ? '#FFA452' : '#FEDDAC'}}></div>
            <div style={{backgroundColor : bar2 === 2 ? '#FFA452' : '#FEDDAC'}}></div>
            <div style={{backgroundColor : bar2 === 3 ? '#FFA452' : '#FEDDAC'}}></div>
            <div style={{backgroundColor : bar2 === 4 ? '#FFA452' : '#FEDDAC'}}></div>
            <div style={{backgroundColor : bar2 === 5 ? '#FFA452' : '#FEDDAC'}}></div>
          </div>
        </div>
      </div>

      <div className={Styles.person_bar} style={{backgroundColor : '#e8faff'}}>
        <h4>重視する項目</h4>
        <div className={Styles.bar_status}>
          <div className={Styles.bar_status_title}>
            <h5>再現性</h5>
            <h5>創造性</h5>
          </div>
          <div className={Styles.bar_status_bar}>
            <div style={{backgroundColor : bar3 === 1 ? '#39C8ED' : '#C6EEFF'}}></div>
            <div style={{backgroundColor : bar3 === 2 ? '#39C8ED' : '#C6EEFF'}}></div>
            <div style={{backgroundColor : bar3 === 3 ? '#39C8ED' : '#C6EEFF'}}></div>
            <div style={{backgroundColor : bar3 === 4 ? '#39C8ED' : '#C6EEFF'}}></div>
            <div style={{backgroundColor : bar3 === 5 ? '#39C8ED' : '#C6EEFF'}}></div>
          </div>
        </div>
      </div>

      <div className={Styles.person_bar} style={{backgroundColor : '#ffedef'}}>
        <h4>キャリアプラン</h4>
        <div className={Styles.bar_status}>
          <div className={Styles.bar_status_title}>
            <h5>チームリーダー</h5>
            <h5>トッププレイヤー</h5>
          </div>
          <div className={Styles.bar_status_bar}>
            <div style={{backgroundColor : bar4 === 1 ? '#FE6970' : '#FFD8DA'}}></div>
            <div style={{backgroundColor : bar4 === 2 ? '#FE6970' : '#FFD8DA'}}></div>
            <div style={{backgroundColor : bar4 === 3 ? '#FE6970' : '#FFD8DA'}}></div>
            <div style={{backgroundColor : bar4 === 4 ? '#FE6970' : '#FFD8DA'}}></div>
            <div style={{backgroundColor : bar4 === 5 ? '#FE6970' : '#FFD8DA'}}></div>
          </div>
        </div>
      </div>

      <div className={Styles.person_bar} style={{backgroundColor : '#f6f1ff'}}>
        <h4>アピールポイント</h4>
        <div className={Styles.bar_status}>
          <div className={Styles.bar_status_title}>
            <h5>コミュニケーション重視</h5>
            <h5>技術重視</h5>
          </div>
          <div className={Styles.bar_status_bar}>
            <div style={{backgroundColor : bar5 === 1 ? '#7F5EBB' : '#DED6ED'}}></div>
            <div style={{backgroundColor : bar5 === 2 ? '#7F5EBB' : '#DED6ED'}}></div>
            <div style={{backgroundColor : bar5 === 3 ? '#7F5EBB' : '#DED6ED'}}></div>
            <div style={{backgroundColor : bar5 === 4 ? '#7F5EBB' : '#DED6ED'}}></div>
            <div style={{backgroundColor : bar5 === 5 ? '#7F5EBB' : '#DED6ED'}}></div>
          </div>
        </div>
      </div>

    </div>
    </>
  );
}

export default RecruitPerson;
