import Styles from './styles.module.css';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTasks } from '../../../features/1.home/tasks';

const HomeTasks: React.FC = () => {

  const dispatch = useDispatch();
  
  const company = useSelector((state: any) => state.company.company);
  const tasks = useSelector((state: any) => state.tasks.tasks);
  const days = useSelector((state: any) => state.tasks.days);

  useEffect(() => {
    dispatch(fetchTasks(company.id));
  }, [ company ]);

  return (
    <>
    <div className={Styles.task}>
      <h4 className={Styles.task_title_top}>1週間のタスク</h4>
      <div>
        <div className={Styles.task_title}>
          <h4>日程</h4>
          <h4>件数</h4>
        </div>
        <div className={Styles.task_data}>
          <h4>{days[0].getFullYear()}年{days[0].getMonth() + 1}月{days[0].getDate()}日({days[0].getDay() === 0 ? '日' : days[0].getDay() === 1 ? '月' : days[0].getDay() === 2 ? '火' : days[0].getDay() === 3 ? '水' : days[0].getDay() === 4 ? '木' : days[0].getDay() === 5 ? '金' : '土' })</h4>
          <h4>{tasks[0] / 2} 件</h4>
        </div>
        <div className={Styles.task_data}>
          <h4>{days[1].getFullYear()}年{days[1].getMonth() + 1}月{days[1].getDate()}日({days[1].getDay() === 0 ? '日' : days[1].getDay() === 1 ? '月' : days[1].getDay() === 2 ? '火' : days[1].getDay() === 3 ? '水' : days[1].getDay() === 4 ? '木' : days[1].getDay() === 5 ? '金' : '土' })</h4>
          <h4>{tasks[1] / 2} 件</h4>
        </div>
        <div className={Styles.task_data}>
          <h4>{days[2].getFullYear()}年{days[2].getMonth() + 1}月{days[2].getDate()}日({days[2].getDay() === 0 ? '日' : days[2].getDay() === 1 ? '月' : days[2].getDay() === 2 ? '火' : days[2].getDay() === 3 ? '水' : days[2].getDay() === 4 ? '木' : days[2].getDay() === 5 ? '金' : '土' })</h4>
          <h4>{tasks[2] / 2} 件</h4>
        </div>
        <div className={Styles.task_data}>
          <h4>{days[3].getFullYear()}年{days[3].getMonth() + 1}月{days[3].getDate()}日({days[3].getDay() === 0 ? '日' : days[3].getDay() === 1 ? '月' : days[3].getDay() === 2 ? '火' : days[3].getDay() === 3 ? '水' : days[3].getDay() === 4 ? '木' : days[3].getDay() === 5 ? '金' : '土' })</h4>
          <h4>{tasks[3] / 2} 件</h4>
        </div>
        <div className={Styles.task_data}>
          <h4>{days[4].getFullYear()}年{days[4].getMonth() + 1}月{days[4].getDate()}日({days[4].getDay() === 0 ? '日' : days[5].getDay() === 1 ? '月' : days[5].getDay() === 2 ? '火' : days[5].getDay() === 3 ? '水' : days[5].getDay() === 4 ? '木' : days[5].getDay() === 5 ? '金' : '土' })</h4>
          <h4>{tasks[4] / 2} 件</h4>
        </div>
        <div className={Styles.task_data}>
          <h4>{days[5].getFullYear()}年{days[5].getMonth() + 1}月{days[5].getDate()}日({days[5].getDay() === 0 ? '日' : days[5].getDay() === 1 ? '月' : days[5].getDay() === 2 ? '火' : days[5].getDay() === 3 ? '水' : days[5].getDay() === 4 ? '木' : days[5].getDay() === 5 ? '金' : '土' })</h4>
          <h4>{tasks[5] / 2} 件</h4>
        </div>
        <div className={Styles.task_data} style={{borderRadius: '0 0 5px 5px'}}>
          <h4>{days[6].getFullYear()}年{days[6].getMonth() + 1}月{days[6].getDate()}日({days[6].getDay() === 0 ? '日' : days[6].getDay() === 1 ? '月' : days[6].getDay() === 2 ? '火' : days[6].getDay() === 3 ? '水' : days[6].getDay() === 4 ? '木' : days[6].getDay() === 5 ? '金' : '土' })</h4>
          <h4>{tasks[6] / 2} 件</h4>
        </div>
      </div>
    </div>
    </>
  );
}

export default HomeTasks;
