import Styles from './styles.module.css';
import React, { useState } from 'react'
import firebase from 'firebase/compat/app';
import DefaultTitle from '../../../package/parts/title/index';
import Style from '../../../package/styleTags/index';
import { v4 as uuid } from 'uuid';
import { useSelector } from 'react-redux';

type PhotoPostProps = {
  setPostToggle: React.Dispatch<React.SetStateAction<boolean>>;
};

const PhotoPost: React.FC<PhotoPostProps> = ({ setPostToggle }) => {

  const users = useSelector((state: any) => state.auth.users);
  const company = useSelector((state: any) => state.company.company);

  const [ image, setImage ] = useState(true);
  const [ imageTmp, setimageTmp ] = useState('');
  const [ imagePathTmp, setImagePathTmp ] = useState<File | null>(null);

  const [ style, setStyle ] = useState<string[]>([]);
  const [ switchStyle, setSwitchStyle ] = useState(false);

  const [ errorImage, setErrorImage ] = useState(true);

  const handlechange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setimageTmp(URL.createObjectURL(e.target.files[0]))
      setImagePathTmp(e.target.files[0]);
      setImage(false);
    }
  }

  function upload() {
    var docId = uuid();

    firebase.firestore().collection('posts').doc(docId)
    .set({
      companyId: company.id,
      post_count: 0,
      post_liker: [],
      post_likes: [],
      post_instagram: '',
      post_tiktok: '',
      post_user_image_1080: '',
      post_user_image_500: '',
      post_name: users.name,
      post_image_1080: '',
      post_image_500: '',
      post_image_name: '',
      post_image_path: '',
      post_tags: style,
      post_uid: users.uid,
      post_time: new Date(),
      post_views: [],
      post_student: false,
    });

    if (imagePathTmp) {
      firebase.storage().ref(`image/${docId}`).put(imagePathTmp).then(() => setPostToggle(false));
    }
  }


  return (
    <>
    {!switchStyle ?
    <div className={Styles.modal}>
      <div>
        <div className={Styles.modal_black} onClick={() => setPostToggle(false)}></div>
        {image ?
        <div className={Styles.modal_contents}>

          <div className={Styles.modal_contents_title}>
            <h4>店舗の施術・作品撮りを投稿</h4>
          </div>

          <p className={Styles.desc}><span>※施術画像や作品撮り等を投稿してください。</span></p>

          <div className={Styles.drop}>
            <input type='file' onChange={(e) => handlechange(e)} />
            <h5>画像を添付する</h5>
            <p>
              画像ファイルをドラッグ&ドロップ、<br />
              もしくは、クリックで画像ファイルを選択してください。
            </p>
          </div>

        </div>
        :
        <div className={Styles.modal_contents}>

          <div className={Styles.modal_contents_title}>
            <h4>店舗の施術・作品撮りを投稿</h4>
            <button onClick={() => {style.includes('メンズ') || style.includes('レディース') ? upload() : setErrorImage(false)}}>投稿</button>
          </div>
          
          <p className={Styles.desc}>
            {errorImage ? '※美容学生に魅力的な施術画像や作品撮り等を投稿してください。' : <span>※スタイル（メンズ、レディース）を選択してください。</span>}</p>

          <div className={Styles.drop_area}>
            <img src={imageTmp} alt='' />
            <div className={Styles.drop_area_select}>

              <DefaultTitle title={'スタイル'} required={true} />
              <select className={Styles.select} 
                value={style.includes('メンズ') ? 'メンズ' : style.includes('レディース') ? 'レディース' : ''} 
                onChange={(e) => {
                  if (e.target.value !== '') {
                    var option = style;
                    option = option.filter((v, index) => (v !== 'メンズ')).filter((v, index) => (v !== 'レディース'));
                    setStyle([...option, e.target.value]);
                  }
                }}>
                <option value=''>未選択</option>
                <option value='メンズ'>メンズ</option>
                <option value='レディース'>レディース</option>
              </select>
              <div style={{height : '10px'}}></div>

              <DefaultTitle title={'その他のタグ'} required={false} />
              <h5 onClick={() => setSwitchStyle(true)}>その他のタグを見る</h5>
              <p>
                {style.map((data, index) =>
                  <>{data},</>
                )}
              </p>

            </div>
          </div>
        </div> }
      </div>
    </div>
    : <></> }

    {switchStyle ?
      <Style style={style} setStyle={setStyle} setSwitchStyle={setSwitchStyle} />
    : <></> }
    </>
  );
}

export default PhotoPost;
