import Styles from './styles.module.css';
import React from 'react';

type PopModalProps = {
  title: string,
  desc: string,
  setError: React.Dispatch<React.SetStateAction<boolean>>;
};

const PopModal: React.FC<PopModalProps> = ({ title, desc, setError }) => {

  return (
    <>
    <div className={Styles.box}>
      <div>
        <div className={Styles.box_black} onClick={() => setError(false)}></div>
        <div className={Styles.box_error}>
          <h4>{title}</h4>
          <p>{desc}</p>
          <div>
            <button onClick={() => setError(false)}>閉じる</button>
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

export default PopModal;
