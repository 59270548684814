import './styles.css';
import React from 'react';

const LandingWhile: React.FC = () => {

  return (
    <>
    <div className='works_landing_while'>
      <div className='works_landing_while_left'>
        <div></div>
        <div></div>
      </div>
      <div className='works_landing_while_right'>
        <h4>一人ひとりに合わせた<br />体験づくりをはじめましょう</h4>
        <div>
          <button>無料トライアル受付中</button>
          <button>料金表ダウンロード</button>
        </div>
      </div>
    </div>
    </>
  );
}

export default LandingWhile;
