import Styles from './styles.module.css';
import React, { useState } from 'react';
import DefaultTitle from '../../../package/parts/title/index';
import firebase from 'firebase/compat/app';
import DefaultImage from './../../../image/default/account.png';
import { FaPen } from "react-icons/fa";
import { useSelector } from 'react-redux';

type HeaderEditProps = {
  setAccountEdit: React.Dispatch<React.SetStateAction<boolean>>;
};

const HeaderEdit: React.FC<HeaderEditProps> = ({ setAccountEdit }) => {

  const users = useSelector((state: any) => state.auth.users);

  const [ name, setName ] = useState(users.name);
  const [ errorName, setErrorName ] = useState(false);

  const [styleImageFile, setStyleImageFile] = useState<string | null>(null);
  const [styleImageFilePath, setStyleImageFilePath] = useState<File | null>(null);

  const styleImages = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setStyleImageFile(URL.createObjectURL(e.target.files[0]));
      setStyleImageFilePath(e.target.files[0]);
    }
  };

  function save() {
    firebase.firestore().collection('users').doc(users)
    .update({
      user_name: name,
    });
    // firebase.storage().ref().child(`/profiles/${users}`).put(styleImageFilePath).then(() => props.setAccountEdit(false));
    if (styleImageFilePath) {
      fetch(URL.createObjectURL(styleImageFilePath)).then(res => res.blob())
      .then(blob => {
        firebase.storage().ref().child(`/profiles/${users}`).put(blob).then(() => {
          setAccountEdit(false);
        });
      });
    }

  }

  return (
    <>
    <div className={Styles.box}>
      <div>
        <div className={Styles.box_black} onClick={() => setAccountEdit(false)}></div>
        <div className={Styles.box_contents}>

          <div className={Styles.title}>
            <h4>アカウント編集</h4>
            <button onClick={() => name === '' ? setErrorName(true) : save()}>保存</button>
          </div>

          <p className={Styles.desc}>※社内の人にも伝わる設定を行ってください。</p>

          <DefaultTitle title={'プロフィール写真'} required={false} />

          <div className={Styles.make_image}>
            <FaPen className={Styles.make_image_pen} />
            <input type="file" onChange={styleImages} />
            {styleImageFile === '' ?
              <img className={Styles.make_image_file} alt=''
                src={`https://firebasestorage.googleapis.com/v0/b/trankllc-com.appspot.com/o/profiles%2Fresize_images%2F${users.uid}_500x500?alt=media&token=?${new Date().getTime()}`} 
                onError={e => {
                  e.currentTarget.onerror = null;
                  e.currentTarget.src = DefaultImage;
                }}/>
            : 
              <img className={Styles.make_image_file} src={styleImageFile || ''} alt='' />
            }
          </div>

          <div className={Styles.title}>
            <div>
              <p>名前</p>
              <div className={Styles.title_yes}>※必須</div>
            </div>
            <p>{name.length} / 20</p>
          </div>
          <input className={Styles.input} placeholder={'メモリー 太郎'} maxLength={20} type='text' 
            value={name} onChange={(e) => setName(e.target.value)} />
          <p className={Styles.desc} style={errorName ? { color : '#AD0200', fontWeight : 'bold' } : {}}>※アカウント名を入力してください。</p>

        </div>
      </div>
    </div>
    </>
  );
}

export default HeaderEdit;
