import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import firebase from "firebase/compat/app";

// 認証状態を監視するThunk
export const fetchCompanyState = createAsyncThunk(
  'company/fetchCompanyState',
  async (id: string, { rejectWithValue }) => {
    try {
      const querySnapshot = await firebase.firestore().collection('companies').where('member', 'array-contains', id).get();

      const companyDataList: companyData[] = [];

      querySnapshot.forEach((doc) => {
        companyDataList.push({
          id: doc.id,
          name: doc.data()?.name || '',
          administrator: doc.data()?.administrator || [],
          member: doc.data()?.member || [],
          recruits: doc.data()?.recruits || [],
          mails: doc.data()?.notification_mails || '',
          invite: doc.data()?.invite || [],
          lineId: doc.data()?.user_lineId || '',
          linePicture: doc.data()?.user_linePicture || '',
          lineName: doc.data()?.user_lineName || '',
        });
      });

      return companyDataList[0] || [];
    } catch (error) {
      return rejectWithValue('Error fetching recruits');
    }
  }
);


interface companyData {
  id: string;
  name: string;
  administrator: string[];
  member: string[];
  recruits: string[];
  mails: string;
  invite: string[];
  lineId: string;
  linePicture: string;
  lineName: string;
}

interface CompanyState {
  company: companyData | [];
}

const initialState: CompanyState = {
  company: [],
};

const companySlice = createSlice({
  name: 'company',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCompanyState.fulfilled, (state, action) => {
        state.company = action.payload;
      })
  },
});

export default companySlice.reducer;
