import Styles from './styles.module.css';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore'; 
import { collection, onSnapshot, query, where } from 'firebase/firestore';
import PostPhoto from './1.post/index';
import EditPhoto from './2.edit/index';
import WaitImg from '../../image/default/wait.png';
import { BsThreeDots } from "react-icons/bs";

const Photo: React.FC = () => {

  const company = useSelector((state: any) => state.company.company);

  const [ photoList, setPhotoList ] = useState<editList[]>([]);
  const [ postToggle, setPostToggle ] = useState<boolean>(false);
  const [ editToggle, setEditToggle ] = useState<boolean>(false);
  
  type editList = {
    id: string; post_tags: string[];
  };
  const [ editInfo, setEditInfo ] = useState<editList>({id: '', post_tags: []});

  useEffect(() => {
    getPhotos();
  }, []);

  function getPhotos() {
    const docRef = collection(firebase.firestore(), 'posts')
    const queryRef = query(docRef, where('companyId', '==', company.id))
    const unsub = onSnapshot(queryRef, snapshot => {
      var tmp: editList[] = [];
      snapshot.docs.forEach(doc => {
        tmp.push({
          id: doc.id,
          post_tags: doc.data().post_tags,
        });
      })
      setPhotoList(tmp);
    })
    return () => unsub();
  }

  return (
    <>
    <div className='works_service'>

      <div className={Styles.photo_title}>
        <div>
          <h4>{company.name}</h4>
        </div>
        <button onClick={() => setPostToggle(true)}>画像を投稿する</button>
      </div>

      <div className={Styles.photo_contents}>
        {photoList.map((photo, index) =>
        <div className={Styles.photo_contents_box} key={index}
          onClick={() => {setEditToggle(true); setEditInfo({ id: photo.id, post_tags: photo.post_tags });}}>
          <div>
            <div className={Styles.photo_contents_box_background}></div>
            <img className={Styles.photo_contents_box_image}
              src={`https://firebasestorage.googleapis.com/v0/b/trankllc-com.appspot.com/o/image%2Fresize_images%2F${photo.id}_500x500?alt=media&token=?${new Date().getTime()}`}
              onError={e => {
                e.currentTarget.onerror = null;
                e.currentTarget.src = WaitImg;
              }} />
            <div className={Styles.photo_contents_box_data}>
              <BsThreeDots className={Styles.photo_contents_box_data_icon} />
            </div>
          </div>
        </div> )}
      </div>

    </div>

    {postToggle ?
      <PostPhoto setPostToggle={setPostToggle} />
    : <></> }

    {editToggle ?
      <EditPhoto editInfo={editInfo} setEditToggle={setEditToggle} />
    : <></> }
    </>
  );
}

export default Photo;
