import Styles from './styles.module.css';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../0.header/index';
import firebase from "firebase/compat/app"
import ErrorMessage from '../../../../package/parts/error/index';
import DefaultInput1 from '../../../../package/parts/1-input/index';
import DefaultTextarea1 from '../../../../package/parts/1-textarea/index';
import { v4 as uuid } from 'uuid';
import DefaultImage from './../../../../image/default/wait.png';
import { ImCross } from "react-icons/im";
import BackModal from '../12.back/index';

const RecruitPhoto: React.FC = () => {

  const navigate = useNavigate();

  // リンク
  const link: string = new URL(window.location.href).href;
  const recruitLink = decodeURIComponent(link.slice(link.indexOf('id=') + 3, link.length));

  var time = new Date();

  const [ errorMessage, setErrorMessage ] = useState('');

  const [ image1Title, setImage1Title ] = useState<string | number>('');
  const [ image1Desc, setImage1Desc ] = useState<string>('');

  const [ image2Title, setImage2Title ] = useState<string | number>('');
  const [ image2Desc, setImage2Desc ] = useState<string>('');

  const [ image3Title, setImage3Title ] = useState<string | number>('');
  const [ image3Desc, setImage3Desc ] = useState<string>('');

  // 写真
  const [ image1Before, setImage1Before ] = useState<string[]>([]);
  const [ image1Files, setImage1Files ] = useState<string[]>([]);
  const [ image1FilesName, setImage1FilesName ] = useState<string[]>([]);
  const [ image1FilePaths, setImage1FilePaths ] = useState<File[]>([]);

  // 写真
  const [ image2Before, setImage2Before ] = useState<string[]>([]);
  const [ image2Files, setImage2Files ] = useState<string[]>([]);
  const [ image2FilesName, setImage2FilesName ] = useState<string[]>([]);
  const [ image2FilePaths, setImage2FilePaths ] = useState<File[]>([]);

  // 写真
  const [ image3Before, setImage3Before ] = useState<string[]>([]);
  const [ image3Files, setImage3Files ] = useState<string[]>([]);
  const [ image3FilesName, setImage3FilesName ] = useState<string[]>([]);
  const [ image3FilePaths, setImage3FilePaths ] = useState<File[]>([]);

  // 戻るボタン
  const [ backStatus, setBackStatus ] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);

    setImage1Title(recruitLink);
    firebase.firestore().collection("recruits").doc(recruitLink).get()
    .then((doc) => {
      const data = doc.data();
      if (data) {

        setImage1Title(data.recruit_image1Title);
        setImage1Desc(data.recruit_image1Desc);

        setImage1Before(data.recruit_image1FilesName);
        setImage1Files(data.recruit_image1FilesName);
        setImage1FilesName(data.recruit_image1FilesName);
        setImage1FilePaths(data.recruit_image1FilesName);

        // 店舗情報 > 紹介
        setImage2Title(data.recruit_image2Title);
        setImage2Desc(data.recruit_image2Desc);
        
        setImage2Before(data.recruit_image2FilesName)
        setImage2Files(data.recruit_image2FilesName);
        setImage2FilesName(data.recruit_image2FilesName);
        setImage2FilePaths(data.recruit_image2FilesName);

        // 店舗情報 > 紹介
        setImage3Title(data.recruit_image3Title);
        setImage3Desc(data.recruit_image3Desc);

        setImage3Before(data.recruit_image3FilesName)
        setImage3Files(data.recruit_image3FilesName);
        setImage3FilesName(data.recruit_image3FilesName);
        setImage3FilePaths(data.recruit_image3FilesName);
      }
    });
  }, [recruitLink]);

  function check() {
    window.scrollTo(0, 0);

    if (image1Files.length === 0) {
      setErrorMessage('No.1 アピールの写真を1枚以上添付してください。');
    } else if (image1Title === '') {
      setErrorMessage('No.1 アピールのタイトルを入力してください。');
    } else if (image1Desc === '') {
      setErrorMessage('No.1 アピールの詳細を入力してください。');
    } else if (image2Files.length === 0) {
      setErrorMessage('No.2 アピールの写真を1枚以上添付してください。');
    } else if (image2Title === '') {
      setErrorMessage('No.2 アピールのタイトルを入力してください。');
    } else if (image2Desc === '') {
      setErrorMessage('No.2 アピールの詳細を入力してください。');
    } else if (image3Files.length === 0) {
      setErrorMessage('No.3 アピールの写真を1枚以上添付してください。');
    } else if (image3Title === '') {
      setErrorMessage('No.3 アピールのタイトルを入力してください。');
    } else if (image3Desc === '') {
      setErrorMessage('No.3 アピールの詳細を入力してください。');
    } else {
      save(true);
    }
  }

  async function save(e: boolean) {

    firebase.firestore().collection("recruits").doc(recruitLink)
    .update({
      status_image: e,

      // 店舗情報 > 情報
      recruit_image1Title: image1Title,
      recruit_image1Desc: image1Desc,
      recruit_image1FilesName: image1FilesName,
      
      recruit_image2Title: image2Title,
      recruit_image2Desc: image2Desc,
      recruit_image2FilesName: image2FilesName,

      recruit_image3Title: image3Title,
      recruit_image3Desc: image3Desc,
      recruit_image3FilesName: image3FilesName,

      last_time: new Date(),
    }).then(() => {
      navigate(`/edit/id=${recruitLink}`);
    });

    for (let i = 0; i < image1FilePaths.length; i++){
      if (image1FilesName[i].length === 36) {
        firebase.storage().ref().child(`/recruitImage1/${image1FilesName[i]}`).put(image1FilePaths[i]);
      }
    }

    for (let i = 0; i < image2FilePaths.length; i++){
      if (image2FilesName[i].length === 36) {
        firebase.storage().ref().child(`/recruitImage2/${image2FilesName[i]}`).put(image2FilePaths[i]);
      }
    }

    for (let i = 0; i < image3FilePaths.length; i++){
      if (image3FilesName[i].length === 36) {
        firebase.storage().ref().child(`/recruitImage3/${image3FilesName[i]}`).put(image3FilePaths[i]);
      }
    }
  }

  const styleImages1 = (e: React.ChangeEvent<HTMLInputElement>) => {    
    if (!e.target.files || e.target.files.length === 0) {
      return;
    }

    var styleImageTmp: string[] = [];
    var styleImagePathsTmp: File[] = [];
    var uid = uuid();

    styleImageTmp.push(URL.createObjectURL(e.target.files[0]));
    styleImagePathsTmp.push(e.target.files[0]);
    
    setImage1Files(image1Files.length === 0 ? styleImageTmp : [...image1Files, ...styleImageTmp]);
    setImage1FilePaths(image1FilePaths.length === 0 ? styleImagePathsTmp : [...image1FilePaths, ...styleImagePathsTmp]);
    setImage1FilesName([...image1FilesName, uid])
  }

  const styleImages2 = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || e.target.files.length === 0) {
      return;
    }

    var styleImageTmp: string[] = [];
    var styleImagePathsTmp: File[] = [];
    var uid = uuid();

    styleImageTmp.push(URL.createObjectURL(e.target.files[0]));
    styleImagePathsTmp.push(e.target.files[0]);
    
    setImage2Files(image2Files.length === 0 ? styleImageTmp : [...image2Files, ...styleImageTmp]);
    setImage2FilePaths(image2FilePaths.length === 0 ? styleImagePathsTmp : [...image2FilePaths, ...styleImagePathsTmp]);
    setImage2FilesName([...image2FilesName, uid])
  }

  const styleImages3 = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || e.target.files.length === 0) {
      return;
    }

    var styleImageTmp: string[] = [];
    var styleImagePathsTmp: File[] = [];
    var uid = uuid();

    styleImageTmp.push(URL.createObjectURL(e.target.files[0]));
    styleImagePathsTmp.push(e.target.files[0]);
    
    setImage3Files(image3Files.length === 0 ? styleImageTmp : [...image3Files, ...styleImageTmp]);
    setImage3FilePaths(image3FilePaths.length === 0 ? styleImagePathsTmp : [...image3FilePaths, ...styleImagePathsTmp]);
    setImage3FilesName([...image3FilesName, uid])
  }
  
  
  return (
    <>
    <Header />

    <div className={Styles.store}>
      <div className={Styles.store_box}>

        {errorMessage !== '' ?
          <ErrorMessage message={errorMessage} />
        : <></> }


        <div className={Styles.store_contents}>

          <h5 className={Styles.store_title}>No.1 アピール</h5>

          <div className={Styles.store_input_photo}>
            <div className={Styles.store_input_photo_title}>
              <p>アピール写真（3枚まで）</p>
              <div className={Styles.store_input_photo_yes}>※必須</div>
            </div>

            {image1Files.length >= 3 ? <></> :
              <div className={Styles.store_input_btn}>
                <label>
                  写真添付
                  <input type="file" onChange={styleImages1} />
                </label>
              </div>
            }
          </div>

          <div className={Styles.image_prev}>
            {image1Files.length === 0 ?
              <p className={Styles.desc}>写真が添付されていません。</p>
              :
              <p className={Styles.desc}></p>
            }

            {image1Files.map((data, index) =>
              <div>
                <img src={data.length === 36 ? `https://firebasestorage.googleapis.com/v0/b/trankllc-com.appspot.com/o/recruitImage1%2Fresize_images%2F${data}_500x500?alt=media&token=?${time.getTime()}` : data} alt=''
                  onError={e => {
                    e.currentTarget.onerror = null;
                    e.currentTarget.src = DefaultImage;
                  }}/>
                <ImCross className={Styles.image_prev_icon}
                  onClick={() => {
                    var v = [ ...image1Files ];
                    v.splice( index, 1 );
                    var array = v;
                    setImage1Files(array);
                    
                    var x = [ ...image1FilePaths ];
                    x.splice( index, 1 );
                    var arrays = x;
                    setImage1FilePaths(arrays);
                    
                    var y = [ ...image1FilesName ];
                    y.splice( index, 1 );
                    var arrayss = y;
                    setImage1FilesName(arrayss);
                  }}
                />
              </div>
            )}
          </div>

          <DefaultInput1
            title={'タイトル'} required={true} length={30} place={''} type={'text'}
            value={image1Title} setValue={setImage1Title}
            desc={''}
          />

          <DefaultTextarea1
            title={'詳細'} required={true} length={100} type={'text'}
            place={''}
            value={image1Desc} setValue={setImage1Desc}
            desc={''}
          />

        </div>

        <div className={Styles.store_contents}>

          <h5 className={Styles.store_title}>No.2 アピール</h5>

          <div className={Styles.store_input_photo}>
            <div className={Styles.store_input_photo_title}>
              <p>アピール写真（3枚まで）</p>
              <div className={Styles.store_input_photo_yes}>※必須</div>
            </div>

            {image2Files.length >= 3 ? <></> :
              <div className={Styles.store_input_btn}>
                <label>
                  写真添付
                  <input type="file" onChange={styleImages2} />
                </label>
              </div>
            }
          </div>

          <div className={Styles.image_prev}>
            {image2Files.length === 0 ?
              <p className={Styles.desc}>写真が添付されていません。</p>
              :
              <p className={Styles.desc}></p>
            }

            {image2Files.map((data, index) =>
              <div>
                <img src={data.length === 36 ? `https://firebasestorage.googleapis.com/v0/b/trankllc-com.appspot.com/o/recruitImage2%2Fresize_images%2F${data}_500x500?alt=media&token=?${time.getTime()}` : data} alt=''
                  onError={e => {
                    e.currentTarget.onerror = null;
                    e.currentTarget.src = DefaultImage;
                  }}/>
                <ImCross className={Styles.image_prev_icon}
                  onClick={() => {
                    var v = [ ...image2Files ];
                    v.splice( index, 1 );
                    var array = v;
                    setImage2Files(array);
                    
                    var x = [ ...image2FilePaths ];
                    x.splice( index, 1 );
                    var arrays = x;
                    setImage2FilePaths(arrays);
                    
                    var y = [ ...image2FilesName ];
                    y.splice( index, 1 );
                    var arrayss = y;
                    setImage2FilesName(arrayss);
                  }}
                />
              </div>
            )}
          </div>

          <DefaultInput1
            title={'タイトル'} required={true} length={30} place={''} type={'text'}
            value={image2Title} setValue={setImage2Title}
            desc={''}
          />

          <DefaultTextarea1
            title={'詳細'} required={true} length={100} type={'text'}
            place={''}
            value={image2Desc} setValue={setImage2Desc}
            desc={''}
          />

        </div>

        <div className={Styles.store_contents}>

          <h5 className={Styles.store_title}>No.3 アピール</h5>

          <div className={Styles.store_input_photo}>
            <div className={Styles.store_input_photo_title}>
              <p>アピール写真（3枚まで）</p>
              <div className={Styles.store_input_photo_yes}>※必須</div>
            </div>

            {image3Files.length >= 3 ? <></> :
              <div className={Styles.store_input_btn}>
                <label>
                  写真添付
                  <input type="file" onChange={styleImages3} />
                </label>
              </div>
            }
          </div>

          <div className={Styles.image_prev}>
            {image3Files.length === 0 ?
              <p className={Styles.desc}>写真が添付されていません。</p>
              :
              <p className={Styles.desc}></p>
            }

            {image3Files.map((data, index) =>
              <div>
                <img src={data.length === 36 ? `https://firebasestorage.googleapis.com/v0/b/trankllc-com.appspot.com/o/recruitImage3%2Fresize_images%2F${data}_500x500?alt=media&token=?${time.getTime()}` : data} alt=''
                  onError={e => {
                    e.currentTarget.onerror = null;
                    e.currentTarget.src = DefaultImage;
                  }}/>
                  
                <ImCross className={Styles.image_prev_icon}
                  onClick={() => {
                    var v = [ ...image3Files ];
                    v.splice( index, 1 );
                    var array = v;
                    setImage3Files(array);
                    
                    var x = [ ...image3FilePaths ];
                    x.splice( index, 1 );
                    var arrays = x;
                    setImage3FilePaths(arrays);
                    
                    var y = [ ...image3FilesName ];
                    y.splice( index, 1 );
                    var arrayss = y;
                    setImage3FilesName(arrayss);
                  }}
                />
              </div>
            )}
          </div>

          <DefaultInput1
            title={'タイトル'} required={true} length={30} place={''} type={'text'}
            value={image3Title} setValue={setImage3Title}
            desc={''}
          />

          <DefaultTextarea1
            title={'詳細'} required={true} length={100} type={'text'}
            place={''}
            value={image3Desc} setValue={setImage3Desc}
            desc={''}
          />

        </div>

        <div className={Styles.save_box}>
          <button className={Styles.save_box_now} onClick={() => setBackStatus(true)}>戻る</button>
          <button className={Styles.save_box_now} onClick={() => save(false)}>一時保存</button>
          <button className={Styles.save_box_save} onClick={() => check()}>保存</button>
        </div>

      </div>
    </div>

    {backStatus ?
      <BackModal setBackStatus={setBackStatus} />
    : <></> }

    </>
  );
}

export default RecruitPhoto;
