import Styles from './styles.module.css';
import React, { useState, useEffect } from 'react';
import Header from './0.header/index';
import { useNavigate } from 'react-router-dom';
import firebase from "firebase/compat/app";
import { FaChevronRight } from "react-icons/fa";
import { FaCheckCircle } from "react-icons/fa";
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { IoIosSettings } from "react-icons/io";
import { useSelector } from 'react-redux';

const RecruitEdit: React.FC = () => {
  
  const navigate = useNavigate();

  const company = useSelector((state: any) => state.company.company);

  const link: string = new URL(window.location.href).href;
  const recruitLink = decodeURIComponent(link.slice(link.indexOf('id=') + 3, link.length));

  const [ status, setStatus ] = useState(0);
  const [ progress, setProgress ] = useState( Array(11).fill(false) );

  const [ searchTags, setSearchTags ] = useState<string[]>([]);

  useEffect(() => {
    getRecruit();
  }, []);

  async function getRecruit() {
    await firebase.firestore().collection("recruits").doc(recruitLink).get()
    .then((doc) => {
      const data = doc.data();
      if (data) {
        setStatus(data.status);

        const statusKeys = [
          'status_store',
          'status_place',
          'status_point',
          'status_number',
          'status_image',
          'status_interview',
          'status_person',
          'status_hashTags',
          'status_recruit',
          'status_document',
          'status_examination',
        ];
    
        setProgress(statusKeys.map((key) => data[key]));

        var nameSplit: string[] = [];
        nameSplit = data.store_name.split('');
  
        let hashtag: (string | number)[] = [
          17 <= data.recruit_monthlySalaryStart ? 17 : '',
          18 <= data.recruit_monthlySalaryStart ? 18 : '',
          19 <= data.recruit_monthlySalaryStart ? 19 : '',
          20 <= data.recruit_monthlySalaryStart ? 20 : '',
          21 <= data.recruit_monthlySalaryStart ? 21 : '',
          22 <= data.recruit_monthlySalaryStart ? 22 : '',
          23 <= data.recruit_monthlySalaryStart ? 23 : '',
          24 <= data.recruit_monthlySalaryStart ? 24 : '',
          25 <= data.recruit_monthlySalaryStart ? 25 : '',
          data.recruit_employmentStatus,
          ...data.recruit_hashTag,
          ...nameSplit,
        ];

        hashTagsList(data.place_stores, hashtag);
      }
    });
  }

  async function hashTagsList(ids: string[], list: (string | number)[]) {

    var tags: string[] = [];

    for (let i = 0; i < ids.length; i++) {
      const doc = await firebase.firestore().collection("stores").doc(ids[i]).get();
      const data = doc.data();
      if (!data) continue;
  
      const tmp = [...data.stationTags, ...data.pentagonHash, data.type];
  
      if (i + 1 === ids.length) {
        setSearchTags([...list, ...searchTags, ...tmp, ...tags]);
      } else {
        tags.push(...tmp);
      }
    }

  }

  async function save() {

    var array: string[] = searchTags;
    var tags: string[] = array.filter(function(s){return s !== '';});
    var recruit_searchTags: { [key: string]: boolean } = {};

    for (let i = 0; i < tags.length; i++) {
      recruit_searchTags[tags[i]] = true;
    }

    await firebase.firestore().collection("recruits").where('companyId', '==', company.id).get()
    .then(snapShot => {
      var count = 0;
      snapShot.forEach((doc) => {
        if (doc.data().status === 1) {
          count = count + 1;
        }
      });

      if (status === 3) {
        firebase.firestore().collection("recruits").doc(recruitLink)
        .update({
          alreadyPublic: true,
          status: 2,
          recruit_searchTags : { ...recruit_searchTags },
        })
        .then(() => {
          navigate(`/service`)
        });
      } else {
        navigate(`/service`);
      }
    });
  }

  return (
    <>
    <Header />

    <div className={Styles.box}>

      <h4 className={Styles.store_title}>求人情報の編集<br />
      {progress.every(status => status) ? '' : `（ 完成まで、あと ${11 - progress.filter(status => status).length} ${11 - progress.filter(status => status).length === 0 ? '' : 'つ'} ）` }
      </h4>
      <h4 className={Styles.store_title}></h4>

      <div className={Styles.store_items}>
        <div 
          style={{
            backgroundColor: progress[0] ? '#FFFFFF' : '#2E81B0', 
            boxShadow: progress[0] ?  '0 0 0' : '0px 0px 5px #cccccc',
            border: progress[0] ? '1px solid #EBEBEB' : 'none'}}
          onClick={() => navigate(`/edit/store/id=${recruitLink}`)}>
          <p style={{color: progress[0] ? '#222222' : '#FFFFFF'}}>1.店舗概要</p>
          {progress[0] ?
            <FaCheckCircle className={Styles.store_items_icons_red} />
            :
            <FaChevronRight className={Styles.store_items_icons} />
          }
        </div>
        <div 
          style={{
            backgroundColor: progress[1] ? '#FFFFFF' : '#2E81B0', 
            boxShadow: progress[1] ?  '0 0 0' : '0px 0px 5px #cccccc',
            border: progress[1] ? '1px solid #EBEBEB' : 'none'}}
          onClick={() => navigate(`/edit/storeSelect/id=${recruitLink}`)}>
          <p style={{color: progress[1] ? '#222222' : '#FFFFFF'}}>2.募集店舗</p>
          {progress[1] ?
            <FaCheckCircle className={Styles.store_items_icons_red} />
            :
            <FaChevronRight className={Styles.store_items_icons} />
          }
        </div>
      </div>

      <div className={Styles.store_items}>
        <div 
          style={{
            backgroundColor: progress[2] ? '#FFFFFF' : '#2E81B0', 
            boxShadow: progress[2] ?  '0 0 0' : '0px 0px 5px #cccccc',
            border: progress[2] ? '1px solid #EBEBEB' : 'none'}}
          onClick={() => navigate(`/edit/point/id=${recruitLink}`)}>
          <p style={{color: progress[2] ? '#222222' : '#FFFFFF'}}>3.会社の魅力3選</p>
          {progress[2] ?
            <FaCheckCircle className={Styles.store_items_icons_red} />
            :
            <FaChevronRight className={Styles.store_items_icons} />
          }
        </div>
        <div 
          style={{
            backgroundColor: progress[3] ? '#FFFFFF' : '#2E81B0', 
            boxShadow: progress[3] ?  '0 0 0' : '0px 0px 5px #cccccc',
            border: progress[3] ? '1px solid #EBEBEB' : 'none'}}
          onClick={() => navigate(`/edit/number/id=${recruitLink}`)}>
          <p style={{color: progress[3] ? '#222222' : '#FFFFFF'}}>4.数字で見る</p>
          {progress[3] ?
            <FaCheckCircle className={Styles.store_items_icons_red} />
            :
            <FaChevronRight className={Styles.store_items_icons} />
          }
        </div>
      </div>
      <div className={Styles.store_items}>
        <div 
          style={{
            backgroundColor: progress[4] ? '#FFFFFF' : '#2E81B0', 
            boxShadow: progress[4] ?  '0 0 0' : '0px 0px 5px #cccccc',
            border: progress[4] ? '1px solid #EBEBEB' : 'none'}}
          onClick={() => navigate(`/edit/photo/id=${recruitLink}`)}>
          <p style={{color: progress[4] ? '#222222' : '#FFFFFF'}}>5.写真で見る</p>
          {progress[4] ?
            <FaCheckCircle className={Styles.store_items_icons_red} />
            :
            <FaChevronRight className={Styles.store_items_icons} />
          }
        </div>
        <div 
          style={{
            backgroundColor: progress[5] ? '#FFFFFF' : '#2E81B0', 
            boxShadow: progress[5] ?  '0 0 0' : '0px 0px 5px #cccccc',
            border: progress[5] ? '1px solid #EBEBEB' : 'none'}}
          onClick={() => navigate(`/edit/interview/id=${recruitLink}`)}>
          <p style={{color: progress[5] ? '#222222' : '#FFFFFF'}}>6.インタビュー</p>
          {progress[5] ?
            <FaCheckCircle className={Styles.store_items_icons_red} />
            :
            <FaChevronRight className={Styles.store_items_icons} />
          }
        </div>
      </div>
      <div className={Styles.store_items}>
        <div 
          style={{
            backgroundColor: progress[6] ? '#FFFFFF' : '#2E81B0', 
            boxShadow: progress[6] ?  '0 0 0' : '0px 0px 5px #cccccc',
            border: progress[6] ? '1px solid #EBEBEB' : 'none'}}
          onClick={() => navigate(`/edit/person/id=${recruitLink}`)}>
          <p style={{color: progress[6] ? '#222222' : '#FFFFFF'}}>7.求める人物像</p>
          {progress[6] ?
            <FaCheckCircle className={Styles.store_items_icons_red} />
            :
            <FaChevronRight className={Styles.store_items_icons} />
          }
        </div>
        <div 
          style={{
            backgroundColor: progress[7] ? '#FFFFFF' : '#2E81B0', 
            boxShadow: progress[7] ?  '0 0 0' : '0px 0px 5px #cccccc',
            border: progress[7] ? '1px solid #EBEBEB' : 'none'}}
          onClick={() => navigate(`/edit/hashtag/id=${recruitLink}`)}>
          <p style={{color: progress[7] ? '#222222' : '#FFFFFF'}}>8.ハッシュタグ</p>
          {progress[7] ?
            <FaCheckCircle className={Styles.store_items_icons_red} />
            :
            <FaChevronRight className={Styles.store_items_icons} />
          }
        </div>
      </div>
      <div className={Styles.store_items}>
        <div 
          style={{
            backgroundColor: progress[8] ? '#FFFFFF' : '#2E81B0', 
            boxShadow: progress[8] ?  '0 0 0' : '0px 0px 5px #cccccc',
            border: progress[8] ? '1px solid #EBEBEB' : 'none'}}
          onClick={() => navigate(`/edit/recruit/id=${recruitLink}`)}>
          <p style={{color: progress[8] ? '#222222' : '#FFFFFF'}}>9.求人情報</p>
          {progress[8] ?
            <FaCheckCircle className={Styles.store_items_icons_red} />
            :
            <FaChevronRight className={Styles.store_items_icons} />
          }
        </div>
      </div>

      <div className={Styles.store_min_title}>
        <IoIosSettings className={Styles.store_items_icons_setting} />
        <h5>求人設定（求人公開後の変更不可）</h5>
      </div>

      <div className={Styles.store_items}>
        <div 
          style={{
            backgroundColor: progress[9] ? '#FFFFFF' : '#2E81B0', 
            boxShadow: progress[9] ?  '0 0 0' : '0px 0px 5px #cccccc',
            border: progress[9] ? '1px solid #EBEBEB' : 'none'}}
          onClick={() => navigate(`/edit/template/id=${recruitLink}`)}>
          <p style={{color: progress[9] ? '#222222' : '#FFFFFF'}}>1.応募設定</p>
          {progress[9] ?
            <FaCheckCircle className={Styles.store_items_icons_red} />
            :
            <FaChevronRight className={Styles.store_items_icons} />
          }
        </div>
        <div 
          style={{
            backgroundColor: progress[10] ? '#FFFFFF' : '#2E81B0', 
            boxShadow: progress[10] ?  '0 0 0' : '0px 0px 5px #cccccc',
            border: progress[10] ? '1px solid #EBEBEB' : 'none'}}
          onClick={() => navigate(`/edit/evaluation/id=${recruitLink}`)}>
          <p style={{color: progress[10] ? '#222222' : '#FFFFFF'}}>2.評価設定</p>
          {progress[10] ?
            <FaCheckCircle className={Styles.store_items_icons_red} />
            :
            <FaChevronRight className={Styles.store_items_icons} />
          }
        </div>
      </div>

      <div className={Styles.button}>
        <button onClick={() => navigate(`/service`)}>戻る</button>
        <button onClick={() =>  progress.every(status => status) ? save() : null}
          style={progress.every(status => status) ? 
            { backgroundColor: '#AD0200', color: 'white' } : { backgroundColor: '#FFFFFF', color: '#EBEBEB' }}>募集開始</button>
      </div>

    </div>
    </>
  );
}

export default RecruitEdit;
