import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getFirestore, collection, query, orderBy, getDocs, Timestamp } from 'firebase/firestore';
import firebase from "firebase/compat/app";

interface Message {
  id: string;
  send: string;
  receive: string;
  text: string;
  time: string;
}

interface MessageState {
  list: Message[];
}

// createAsyncThunkを使って非同期アクションを定義
export const fetchMessages = createAsyncThunk<Message[], string>(
  'messages/fetchMessages',
  async (id, { rejectWithValue }) => {
    try {
      const db = firebase.firestore();
      const link = `chat/${id}/message`;
      const docRef = collection(db, link);
      const queryRefs = query(docRef, orderBy('message_time', 'asc'));

      const snapshot = await getDocs(queryRefs);

      const tmp: Message[] = [];
      snapshot.docs.map((doc) => (
        tmp.push({
          id: doc.id,
          send: doc.data()?.message_send,
          receive: doc.data()?.message_receive,
          text: doc.data()?.message_text,
          time: new Date(doc.data()?.message_time.seconds * 1000 + doc.data()?.message_time.nanoseconds / 1000000)?.toISOString(),
        })
      ));

      const messages: Message[] = tmp;
      return messages;

    } catch (error: any) {
      console.error('Error fetching messages:', error);
      return rejectWithValue(error.message || 'Failed to fetch messages');
    }
  }
);

// Slice の作成
const messageList = createSlice({
  name: 'messages',
  initialState: {
    list: [] as Message[],
    status: 'idle',
    error: null,
  } as MessageState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchMessages.fulfilled, (state, action) => {
        state.list = action.payload;
      })
  },
});

export default messageList.reducer;
