import Styles from './styles.module.css';
import React, { useState, useEffect } from 'react';
import firebase from "firebase/compat/app";
import DefaultImg from './../../../image/default/account.png';
import { useSelector } from 'react-redux';
import { MdMessage } from "react-icons/md";


type ManageNavProps = {
  chatList: Chat;
  chatStatus: number;
};

interface Chat {
  id: string;
  ids: string[];
  names: string[];
  reason: string;
  time: string;
  applicantId: string;
  badges: number,
  recruitId: string,
}

const ContactChat: React.FC<ManageNavProps> = ({ chatList }) => {

  const [ uid, setUid ] = useState('');
  const [ name, setName ] = useState('');

  const company = useSelector((state: any) => state.company.company);

  useEffect(() => {    
    setUid(chatList.names.indexOf(company?.id) === 1 ? chatList.names[3] : chatList.names[1]);
    setName(chatList.names.indexOf(company?.id) === 1 ? chatList.names[2] : chatList.names[0]);
  }, [company]);

  // バッジをリセット
  function badgeUpdate(id: string) {
    let badge: { [key: string]: number } = {};
    let key = `${company.id}_badge`;
    badge[key] = 0;
    firebase.firestore().collection('chat').doc(id).update({ ...badge });
  }

  return (
    <>
    <div className={Styles.box}>
      <div className={Styles.box_item_1} onClick={() => badgeUpdate(chatList.id)}>
        <img src={`https://firebasestorage.googleapis.com/v0/b/trankllc-com.appspot.com/o/profiles%2Fresize_images%2F${uid}_200x200?alt=media&token=`} alt=''
          onError={e => {
            e.currentTarget.onerror = null;
            e.currentTarget.src = DefaultImg;
          }}/>
        <div className={Styles.box_1_post}>
          <div>
            <div style={{backgroundColor: '#192651'}}></div>
            <p>{new Date(chatList.time).getFullYear()}年{new Date(chatList.time).getMonth() + 1}月{new Date(chatList.time).getDate()}日</p>
          </div>
          <h2>{name === '' ? "unnamed" : name}</h2>
        </div>

        <p className={Styles.box_item_2}>{chatList.badges !== 0 ? '未読' : '既読'}</p>

        <MdMessage className={Styles.box_item_4} style={{color : '#222222'}} />

      </div>
    </div>
    </>
  );
}

export default ContactChat;