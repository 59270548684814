import './styles.css';
import React, { useState, useEffect } from 'react';
import firebase from 'firebase/compat/app';
import { FaChevronLeft } from 'react-icons/fa';
import { FaChevronRight } from 'react-icons/fa';
import { collection, onSnapshot, query, where } from 'firebase/firestore';
import { useDispatch, useSelector } from 'react-redux';

import { fetchDetails } from '../../features/package/detailsPanel';
import { fetchRecruit } from '../../features/package/detailsPanel';
import { fetchApplicant } from '../../features/package/detailsPanel';

const SchedulePc: React.FC = () => {

  const dispatch = useDispatch();

  const company = useSelector((state: any) => state.company.company);
  const recruitIds = useSelector((state: any) => state.recruit.recruitIds ?? []);

  const [ nowYears, setNowYears ] = useState(0);
  const [ nowMonth, setNowMonth ] = useState(0);
  const [ nowDay, setNowDay ] = useState(0);

  const [ yearsStart, setYearsStart ] = useState(0);
  const [ yearsLast, setYearsLast ] = useState(0);
  const [ monthStart, setMonthStart ] = useState(0);
  const [ monthLast, setMonthLast ] = useState(0);
  const [ day, setDay ] = useState(0);

  const [ todayTask, setTodayTask ] = useState<any>([]);

  const [ standard1, setStandard1 ] = useState(0);
  const [ standard2, setStandard2 ] = useState(0);
  const [ standard3, setStandard3 ] = useState(0);
  const [ standard4, setStandard4 ] = useState(0);
  const [ standard5, setStandard5 ] = useState(0);
  const [ standard6, setStandard6 ] = useState(0);
  const [ standard7, setStandard7 ] = useState(0);

  // 日曜日
  const [ calendar1Data, setCalendar1Data ] = useState<any>([]);
  const [ gridCount1, setGridCount1 ] = useState('');
  const [ calendar1MaxLength, setCalendar1MaxLength ] = useState(0);

  // 月曜日
  const [ calendar2Data, setCalendar2Data ] = useState<any>([]);
  const [ gridCount2, setGridCount2 ] = useState('');
  const [ calendar2MaxLength, setCalendar2MaxLength ] = useState(0);

  // 火曜日
  const [ calendar3Data, setCalendar3Data ] = useState<any>([]);
  const [ gridCount3, setGridCount3 ] = useState('');
  const [ calendar3MaxLength, setCalendar3MaxLength ] = useState(0);

  // 水曜日
  const [ calendar4Data, setCalendar4Data ] = useState<any>([]);
  const [ gridCount4, setGridCount4 ] = useState('');
  const [ calendar4MaxLength, setCalendar4MaxLength ] = useState(0);

  // 木曜日
  const [ calendar5Data, setCalendar5Data ] = useState<any>([]);
  const [ gridCount5, setGridCount5 ] = useState('');
  const [ calendar5MaxLength, setCalendar5MaxLength ] = useState(0);
  
  // 金曜日
  const [ calendar6Data, setCalendar6Data ] = useState<any>([]);
  const [ gridCount6, setGridCount6 ] = useState('');
  const [ calendar6MaxLength, setCalendar6MaxLength ] = useState(0);

  // 土曜日
  const [ calendar7Data, setCalendar7Data ] = useState<any>([]);
  const [ gridCount7, setGridCount7 ] = useState('');
  const [ calendar7MaxLength, setCalendar7MaxLength ] = useState(0);

  // 右のタブ系
  const [ recruitId, setRecruitId ] = useState('');
  const [ applicantId, setApplicantId ] = useState('');

  useEffect(() => {
    var today = new Date();
    var day = today.getDay();

    getCalendar(today.getFullYear(), today.getMonth(), today.getDate() - day);

    setNowYears(today.getFullYear());
    setNowMonth(today.getMonth());
    setNowDay(today.getDate());

  }, [company]);

  function getCalc(time: number) {
    var calcDate = new Date(yearsStart, monthStart, day + (time * 7));
    getCalendar(calcDate.getFullYear(), calcDate.getMonth(), calcDate.getDate());
  }
  
  function getCalendar(years: number, month: number, day: number) {

    var now = new Date();
    var today = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0);
    var tomorrow = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1, 0, 0, 0);

    var Sunday = new Date(years, month, day);
    var Monday = new Date(years, month, day + 1);
    var Tuesday = new Date(years, month, day + 2);
    var Wednesday = new Date(years, month, day + 3);
    var Thursday = new Date(years, month, day + 4);
    var Friday = new Date(years, month, day + 5);
    var Saturday = new Date(years, month, day + 6);
    var NextSunday = new Date(years, month, day + 7);

    setYearsStart(Sunday.getFullYear());
    setYearsLast(Saturday.getFullYear());
    setMonthStart(Sunday.getMonth());
    setMonthLast(Saturday.getMonth());
    setDay(day);

    setStandard1(Sunday.getDate());
    setStandard2(Monday.getDate());
    setStandard3(Tuesday.getDate());
    setStandard4(Wednesday.getDate());
    setStandard5(Thursday.getDate());
    setStandard6(Friday.getDate());
    setStandard7(Saturday.getDate());

    const docRef = collection(firebase.firestore(), 'applicants')
    const queryRef = query(docRef, where('recruitId', 'in', recruitIds))
    const unsub = onSnapshot(queryRef, snapshot => {

      var TodayTmp: any[] = [];
      var SundayTmp: any[] = [];
      var MondayTmp: any[] = [];
      var TuesdayTmp: any[] = [];
      var WednesdayTmp: any[] = [];
      var ThursdayTmp: any[] = [];
      var FridayTmp: any[] = [];
      var SaturdayTmp: any[] = [];

      snapshot.docs.forEach(doc => {
        
        var dataTime1Before: Date = new Date(doc.data().examination1_schedule[0].seconds * 1000);
        var dataTime1After: Date = new Date(doc.data().examination1_schedule[1].seconds * 1000);
        var dataTime2Before: Date = new Date(doc.data().examination2_schedule[0].seconds * 1000);
        var dataTime2After: Date = new Date(doc.data().examination2_schedule[1].seconds * 1000);
        var dataTime3Before: Date = new Date(doc.data().examination3_schedule[0].seconds * 1000);
        var dataTime3After: Date = new Date(doc.data().examination3_schedule[1].seconds * 1000);
        var dataTime4Before: Date = new Date(doc.data().examination4_schedule[0].seconds * 1000);
        var dataTime4After: Date = new Date(doc.data().examination4_schedule[1].seconds * 1000);
        var dataTime5Before: Date = new Date(doc.data().examination5_schedule[0].seconds * 1000);
        var dataTime5After: Date = new Date(doc.data().examination5_schedule[1].seconds * 1000);

        // 本日のタスク
        if (today <= dataTime1Before && dataTime1Before <= tomorrow) {
          TodayTmp.push([ dataTime1Before, dataTime1After, ((dataTime1After.getTime() - dataTime1Before.getTime()) / (1000 * 60) / 15), `1次：${doc.data().name}`, doc.data().recruitId, doc.id, ]);
        }

        if (today <= dataTime2Before && dataTime2Before <= tomorrow) {
          TodayTmp.push([ dataTime2Before, dataTime2After, ((dataTime2After.getTime() - dataTime2Before.getTime()) / (1000 * 60) / 15), `2次：${doc.data().name}`, doc.data().recruitId, doc.id, ]);
        }

        if (today <= dataTime3Before && dataTime3Before <= tomorrow) {
          TodayTmp.push([ dataTime3Before, dataTime3After, ((dataTime3After.getTime() - dataTime3Before.getTime()) / (1000 * 60) / 15), `3次：${doc.data().name}`, doc.data().recruitId, doc.id, ]);
        }

        if (today <= dataTime4Before && dataTime4Before <= tomorrow) {
          TodayTmp.push([ dataTime4Before, dataTime4After, ((dataTime4After.getTime() - dataTime4Before.getTime()) / (1000 * 60) / 15), `4次：${doc.data().name}`, doc.data().recruitId, doc.id, ]);
        }

        if (today <= dataTime5Before && dataTime5Before <= tomorrow) {
          TodayTmp.push([ dataTime5Before, dataTime5After, ((dataTime5After.getTime() - dataTime5Before.getTime()) / (1000 * 60) / 15), `5次：${doc.data().name}`, doc.data().recruitId, doc.id, ]);
        }

        // 日曜日
        if (Sunday <= dataTime1Before && dataTime1Before <= Monday) {
          SundayTmp.push([ dataTime1Before, dataTime1After, ((dataTime1After.getTime() - dataTime1Before.getTime()) / (1000 * 60) / 15), `1次：${doc.data().name}`, doc.data().recruitId, doc.id, ]);
        }

        if (Sunday <= dataTime2Before && dataTime2Before <= Monday) {
          SundayTmp.push([ dataTime2Before, dataTime2After, ((dataTime2After.getTime() - dataTime2Before.getTime()) / (1000 * 60) / 15), `2次：${doc.data().name}`, doc.data().recruitId, doc.id, ]);
        }

        if (Sunday <= dataTime3Before && dataTime3Before <= Monday) {
          SundayTmp.push([ dataTime3Before, dataTime3After, ((dataTime3After.getTime() - dataTime3Before.getTime()) / (1000 * 60) / 15), `3次：${doc.data().name}`, doc.data().recruitId, doc.id, ]);
        }

        if (Sunday <= dataTime4Before && dataTime4Before <= Monday) {
          SundayTmp.push([ dataTime4Before, dataTime4After, ((dataTime4After.getTime() - dataTime4Before.getTime()) / (1000 * 60) / 15), `4次：${doc.data().name}`, doc.data().recruitId, doc.id, ]);
        }

        if (Sunday <= dataTime5Before && dataTime5Before <= Monday) {
          SundayTmp.push([ dataTime5Before, dataTime5After, ((dataTime5After.getTime() - dataTime5Before.getTime()) / (1000 * 60) / 15), `5次：${doc.data().name}`, doc.data().recruitId, doc.id, ]);
        }

        // 月曜日
        if (Monday <= dataTime1Before && dataTime1Before <= Tuesday) {
          MondayTmp.push([ dataTime1Before, dataTime1After, ((dataTime1After.getTime() - dataTime1Before.getTime()) / (1000 * 60) / 15), `1次：${doc.data().name}`, doc.data().recruitId, doc.id, ]);
        }

        if (Monday <= dataTime2Before && dataTime2Before <= Tuesday) {
          MondayTmp.push([ dataTime2Before, dataTime2After, ((dataTime2After.getTime() - dataTime2Before.getTime()) / (1000 * 60) / 15), `2次：${doc.data().name}`, doc.data().recruitId, doc.id, ]);
        }

        if (Monday <= dataTime3Before && dataTime3Before <= Tuesday) {
          MondayTmp.push([ dataTime3Before, dataTime3After, ((dataTime3After.getTime() - dataTime3Before.getTime()) / (1000 * 60) / 15), `3次：${doc.data().name}`, doc.data().recruitId, doc.id, ]);
        }

        if (Monday <= dataTime4Before && dataTime4Before <= Tuesday) {
          MondayTmp.push([ dataTime4Before, dataTime4After, ((dataTime4After.getTime() - dataTime4Before.getTime()) / (1000 * 60) / 15), `4次：${doc.data().name}`, doc.data().recruitId, doc.id, ]);
        }

        if (Monday <= dataTime5Before && dataTime5Before <= Tuesday) {
          MondayTmp.push([ dataTime5Before, dataTime5After, ((dataTime5After.getTime() - dataTime5Before.getTime()) / (1000 * 60) / 15), `5次：${doc.data().name}`, doc.data().recruitId, doc.id, ]);
        }

        // 火曜日
        if (Tuesday <= dataTime1Before && dataTime1Before <= Wednesday) {
          TuesdayTmp.push([ dataTime1Before, dataTime1After, ((dataTime1After.getTime() - dataTime1Before.getTime()) / (1000 * 60) / 15), `1次：${doc.data().name}`, doc.data().recruitId, doc.id, ]);
        }
        
        if (Tuesday <= dataTime2Before && dataTime2Before <= Wednesday) {
          TuesdayTmp.push([ dataTime2Before, dataTime2After, ((dataTime2After.getTime() - dataTime2Before.getTime()) / (1000 * 60) / 15), `2次：${doc.data().name}`, doc.data().recruitId, doc.id, ]);
        }

        if (Tuesday <= dataTime3Before && dataTime3Before <= Wednesday) {
          TuesdayTmp.push([ dataTime3Before, dataTime3After, ((dataTime3After.getTime() - dataTime3Before.getTime()) / (1000 * 60) / 15), `3次：${doc.data().name}`, doc.data().recruitId, doc.id, ]);
        }

        if (Tuesday <= dataTime4Before && dataTime4Before <= Wednesday) {
          TuesdayTmp.push([ dataTime4Before, dataTime4After, ((dataTime4After.getTime() - dataTime4Before.getTime()) / (1000 * 60) / 15), `4次：${doc.data().name}`, doc.data().recruitId, doc.id, ]);
        }

        if (Tuesday <= dataTime5Before && dataTime5Before <= Wednesday) {
          TuesdayTmp.push([ dataTime5Before, dataTime5After, ((dataTime5After.getTime() - dataTime5Before.getTime()) / (1000 * 60) / 15), `5次：${doc.data().name}`, doc.data().recruitId, doc.id, ]);
        }

        // 水曜日
        if (Wednesday <= dataTime1Before && dataTime1Before <= Thursday) {
          WednesdayTmp.push([ dataTime1Before, dataTime1After, ((dataTime1After.getTime() - dataTime1Before.getTime()) / (1000 * 60) / 15), `1次：${doc.data().name}`, doc.data().recruitId, doc.id, ]);
        }

        if (Wednesday <= dataTime2Before && dataTime2Before <= Thursday) {
          WednesdayTmp.push([ dataTime2Before, dataTime2After, ((dataTime2After.getTime() - dataTime2Before.getTime()) / (1000 * 60) / 15), `2次：${doc.data().name}`, doc.data().recruitId, doc.id, ]);
        }

        if (Wednesday <= dataTime3Before && dataTime3Before <= Thursday) {
          WednesdayTmp.push([ dataTime3Before, dataTime3After, ((dataTime3After.getTime() - dataTime3Before.getTime()) / (1000 * 60) / 15), `3次：${doc.data().name}`, doc.data().recruitId, doc.id, ]);
        }

        if (Wednesday <= dataTime4Before && dataTime4Before <= Thursday) {
          WednesdayTmp.push([ dataTime4Before, dataTime4After, ((dataTime4After.getTime() - dataTime4Before.getTime()) / (1000 * 60) / 15), `3次：${doc.data().name}`, doc.data().recruitId, doc.id, ]);
        }

        if (Wednesday <= dataTime5Before && dataTime5Before <= Thursday) {
          WednesdayTmp.push([ dataTime5Before, dataTime5After, ((dataTime5After.getTime() - dataTime5Before.getTime()) / (1000 * 60) / 15), `5次：${doc.data().name}`, doc.data().recruitId, doc.id, ]);
        }

        // 木曜日
        if (Thursday <= dataTime1Before && dataTime1Before <= Friday) {
          ThursdayTmp.push([ dataTime1Before, dataTime1After, ((dataTime1After.getTime() - dataTime1Before.getTime()) / (1000 * 60) / 15), `1次：${doc.data().name}`, doc.data().recruitId, doc.id, ]);
        }

        if (Thursday <= dataTime2Before && dataTime2Before <= Friday) {
          ThursdayTmp.push([ dataTime2Before, dataTime2After, ((dataTime2After.getTime() - dataTime2Before.getTime()) / (1000 * 60) / 15), `2次：${doc.data().name}`, doc.data().recruitId, doc.id, ]);
        }

        if (Thursday <= dataTime3Before && dataTime3Before <= Friday) {
          ThursdayTmp.push([ dataTime3Before, dataTime3After, ((dataTime3After.getTime() - dataTime3Before.getTime()) / (1000 * 60) / 15), `3次：${doc.data().name}`, doc.data().recruitId, doc.id, ]);
        }

        if (Thursday <= dataTime4Before && dataTime4Before <= Friday) {
          ThursdayTmp.push([ dataTime4Before, dataTime4After, ((dataTime4After.getTime() - dataTime4Before.getTime()) / (1000 * 60) / 15), `4次：${doc.data().name}`, doc.data().recruitId, doc.id, ]);
        }

        if (Thursday <= dataTime5Before && dataTime5Before <= Friday) {
          ThursdayTmp.push([ dataTime5Before, dataTime5After, ((dataTime5After.getTime() - dataTime5Before.getTime()) / (1000 * 60) / 15), `5次：${doc.data().name}`, doc.data().recruitId, doc.id, ]);
        }

        // 金曜日
        if (Friday <= dataTime1Before && dataTime1Before <= Saturday) {
          FridayTmp.push([ dataTime1Before, dataTime1After, ((dataTime1After.getTime() - dataTime1Before.getTime()) / (1000 * 60) / 15), `1次：${doc.data().name}`, doc.data().recruitId, doc.id, ]);
        }

        if (Friday <= dataTime2Before && dataTime2Before <= Saturday) {
          FridayTmp.push([ dataTime2Before, dataTime2After, ((dataTime2After.getTime() - dataTime2Before.getTime()) / (1000 * 60) / 15), `2次：${doc.data().name}`, doc.data().recruitId, doc.id, ]);
        }

        if (Friday <= dataTime3Before && dataTime3Before <= Saturday) {
          FridayTmp.push([ dataTime3Before, dataTime3After, ((dataTime3After.getTime() - dataTime3Before.getTime()) / (1000 * 60) / 15), `3次：${doc.data().name}`, doc.data().recruitId, doc.id, ]);
        }

        if (Friday <= dataTime4Before && dataTime4Before <= Saturday) {
          FridayTmp.push([ dataTime4Before, dataTime4After, ((dataTime4After.getTime() - dataTime4Before.getTime()) / (1000 * 60) / 15), `4次：${doc.data().name}`, doc.data().recruitId, doc.id, ]);
        }

        if (Friday <= dataTime5Before && dataTime5Before <= Saturday) {
          FridayTmp.push([ dataTime5Before, dataTime5After, ((dataTime5After.getTime() - dataTime5Before.getTime()) / (1000 * 60) / 15), `5次：${doc.data().name}`, doc.data().recruitId, doc.id, ]);
        }

        // 土曜日
        if (Saturday <= dataTime1Before && dataTime1Before <= NextSunday) {
          SaturdayTmp.push([ dataTime1Before, dataTime1After, ((dataTime1After.getTime() - dataTime1Before.getTime()) / (1000 * 60) / 15), `1次：${doc.data().name}`, doc.data().recruitId, doc.id, ]);
        }
        
        if (Saturday <= dataTime2Before && dataTime2Before <= NextSunday) {
          SaturdayTmp.push([ dataTime2Before, dataTime2After, ((dataTime2After.getTime() - dataTime2Before.getTime()) / (1000 * 60) / 15), `2次：${doc.data().name}`, doc.data().recruitId, doc.id, ]);
        }
        
        if (Saturday <= dataTime3Before && dataTime3Before <= NextSunday) {
          SaturdayTmp.push([ dataTime3Before, dataTime3After, ((dataTime3After.getTime() - dataTime3Before.getTime()) / (1000 * 60) / 15), `3次：${doc.data().name}`, doc.data().recruitId, doc.id, ]);
        }
        
        if (Saturday <= dataTime4Before && dataTime4Before <= NextSunday) {
          SaturdayTmp.push([ dataTime4Before, dataTime4After, ((dataTime4After.getTime() - dataTime4Before.getTime()) / (1000 * 60) / 15), `4次：${doc.data().name}`, doc.data().recruitId, doc.id, ]);
        }
        
        if (Saturday <= dataTime5Before && dataTime5Before <= NextSunday) {
          SaturdayTmp.push([ dataTime5Before, dataTime5After, ((dataTime5After.getTime() - dataTime5Before.getTime()) / (1000 * 60) / 15), `5次：${doc.data().name}`, doc.data().recruitId, doc.id, ]);
        }
      })

      TodayTmp.sort( (x, y) => (x[0]) - (y[0]), )
      SundayTmp.sort( (x, y) => (x[0]) - (y[0]), )
      MondayTmp.sort( (x, y) => (x[0]) - (y[0]), )
      TuesdayTmp.sort( (x, y) => (x[0]) - (y[0]), )
      WednesdayTmp.sort( (x, y) => (x[0]) - (y[0]), )
      ThursdayTmp.sort( (x, y) => (x[0]) - (y[0]), )
      FridayTmp.sort( (x, y) => (x[0]) - (y[0]), )
      SaturdayTmp.sort( (x, y) => (x[0]) - (y[0]), )

      getTodayArray(TodayTmp);
      getCalendar1Array(SundayTmp);
      getCalendar2Array(MondayTmp);
      getCalendar3Array(TuesdayTmp);
      getCalendar4Array(WednesdayTmp);
      getCalendar5Array(ThursdayTmp);
      getCalendar6Array(FridayTmp);
      getCalendar7Array(SaturdayTmp);
    })
    return () => {
      unsub();
    };
  }

  function getTodayArray(data: any[]) {
    data.sort((a, b) =>  Date.parse(a[0]) - Date.parse(b[0]));
    setTodayTask(data);
  }

  function getCalendar1Array(data: any[]) {

    var calendar: any[] = [];
    
    var times900: any[] = [];
    var times915: any[] = [];
    var times930: any[] = [];
    var times945: any[] = [];
    var times1000: any[] = [];
    var times1015: any[] = [];
    var times1030: any[] = [];
    var times1045: any[] = [];
    var times1100: any[] = [];
    var times1115: any[] = [];
    var times1130: any[] = [];
    var times1145: any[] = [];
    var times1200: any[] = [];
    var times1215: any[] = [];
    var times1230: any[] = [];
    var times1245: any[] = [];
    var times1300: any[] = [];
    var times1315: any[] = [];
    var times1330: any[] = [];
    var times1345: any[] = [];
    var times1400: any[] = [];
    var times1415: any[] = [];
    var times1430: any[] = [];
    var times1445: any[] = [];
    var times1500: any[] = [];
    var times1515: any[] = [];
    var times1530: any[] = [];
    var times1545: any[] = [];
    var times1600: any[] = [];
    var times1615: any[] = [];
    var times1630: any[] = [];
    var times1645: any[] = [];
    var times1700: any[] = [];
    var times1715: any[] = [];
    var times1730: any[] = [];
    var times1745: any[] = [];
    var times1800: any[] = [];
    var times1815: any[] = [];
    var times1830: any[] = [];
    var times1845: any[] = [];
    var times1900: any[] = [];
    var times1915: any[] = [];
    var times1930: any[] = [];
    var times1945: any[] = [];
    var times2000: any[] = [];
    var times2015: any[] = [];
    var times2030: any[] = [];
    var times2045: any[] = [];
    var times2100: any[] = [];

    data.map((times, i) => {
      
      var timeBefore = Number(`${times[0].getHours()}` + `${times[0].getMinutes() === 0 ? '00' : times[0].getMinutes()}`);
      var timeAfter = Number(`${times[1].getHours()}` + `${times[1].getMinutes() === 0 ? '00' : times[1].getMinutes()}`);

      if (timeBefore <= 900 && 900 <= timeAfter) {
        if (timeBefore === 900) {
          times900.push( times );
        } else {
          times900.push( '' );
        }
      }

      if (timeBefore <= 915 && 915 <= timeAfter) {
        if (timeBefore === 915) {
          times915.push( times );
        } else {
          times915.push( '' );
        }
      }

      if (timeBefore <= 930 && 930 <= timeAfter) {
        if (timeBefore === 930) {
          times930.push( times );
        } else {
          times930.push( '' );
        }
      }

      if (timeBefore <= 945 && 945 <= timeAfter) {
        if (timeBefore === 945) {
          times945.push( times );
        } else {
          times945.push( '' );
        }
      }

      if (timeBefore <= 1000 && 1000 <= timeAfter) {
        if (timeBefore === 1000) {
          times1000.push( times );
        } else {
          times1000.push( '' );
        }
      }

      if (timeBefore <= 1015 && 1015 <= timeAfter) {
        if (timeBefore === 1015) {
          times1015.push( times );
        } else {
          times1015.push( '' );
        }
      }

      if (timeBefore <= 1030 && 1030 <= timeAfter) {
        if (timeBefore === 1030) {
          times1030.push( times );
        } else {
          times1030.push( '' );
        }
      }

      if (timeBefore <= 1045 && 1045 <= timeAfter) {
        if (timeBefore === 1045) {
          times1045.push( times );
        } else {
          times1045.push( '' );
        }
      }

      if (timeBefore <= 1100 && 1100 <= timeAfter) {
        if (timeBefore === 1100) {
          times1100.push( times );
        } else {
          times1100.push( '' );
        }
      }

      if (timeBefore <= 1115 && 1115 <= timeAfter) {
        if (timeBefore === 1115) {
          times1115.push( times );
        } else {
          times1115.push( '' );
        }
      }

      if (timeBefore <= 1130 && 1130 <= timeAfter) {
        if (timeBefore === 1130) {
          times1130.push( times );
        } else {
          times1130.push( '' );
        }
      }

      if (timeBefore <= 1145 && 1145 <= timeAfter) {
        if (timeBefore === 1145) {
          times1145.push( times );
        } else {
          times1145.push( '' );
        }
      }

      if (timeBefore <= 1200 && 1200 <= timeAfter) {
        if (timeBefore === 1200) {
          times1200.push( times );
        } else {
          times1200.push( '' );
        }
      }

      if (timeBefore <= 1215 && 1215 <= timeAfter) {
        if (timeBefore === 1215) {
          times1215.push( times );
        } else {
          times1215.push( '' );
        }
      }

      if (timeBefore <= 1230 && 1230 <= timeAfter) {
        if (timeBefore === 1230) {
          times1230.push( times );
        } else {
          times1230.push( '' );
        }
      }

      if (timeBefore <= 1245 && 1245 <= timeAfter) {
        if (timeBefore === 1245) {
          times1245.push( times );
        } else {
          times1245.push( '' );
        }
      }

      if (timeBefore <= 1300 && 1300 <= timeAfter) {
        if (timeBefore === 1300) {
          times1300.push( times );
        } else {
          times1300.push( '' );
        }
      }

      if (timeBefore <= 1315 && 1315 <= timeAfter) {
        if (timeBefore === 1315) {
          times1315.push( times );
        } else {
          times1315.push( '' );
        }
      }

      if (timeBefore <= 1330 && 1330 <= timeAfter) {
        if (timeBefore === 1330) {
          times1330.push( times );
        } else {
          times1330.push( '' );
        }
      }

      if (timeBefore <= 1345 && 1345 <= timeAfter) {
        if (timeBefore === 1345) {
          times1345.push( times );
        } else {
          times1345.push( '' );
        }
      }

      if (timeBefore <= 1400 && 1400 <= timeAfter) {
        if (timeBefore === 1400) {
          times1400.push( times );
        } else {
          times1400.push( '' );
        }
      }

      if (timeBefore <= 1415 && 1415 <= timeAfter) {
        if (timeBefore === 1415) {
          times1415.push( times );
        } else {
          times1415.push( '' );
        }
      }

      if (timeBefore <= 1430 && 1430 <= timeAfter) {
        if (timeBefore === 1430) {
          times1430.push( times );
        } else {
          times1430.push( '' );
        }
      }

      if (timeBefore <= 1445 && 1445 <= timeAfter) {
        if (timeBefore === 1445) {
          times1445.push( times );
        } else {
          times1445.push( '' );
        }
      }

      if (timeBefore <= 1500 && 1500 <= timeAfter) {
        if (timeBefore === 1500) {
          times1500.push( times );
        } else {
          times1500.push( '' );
        }
      }

      if (timeBefore <= 1515 && 1515 <= timeAfter) {
        if (timeBefore === 1515) {
          times1515.push( times );
        } else {
          times1515.push( '' );
        }
      }

      if (timeBefore <= 1530 && 1530 <= timeAfter) {
        if (timeBefore === 1530) {
          times1530.push( times );
        } else {
          times1530.push( '' );
        }
      }

      if (timeBefore <= 1545 && 1545 <= timeAfter) {
        if (timeBefore === 1545) {
          times1545.push( times );
        } else {
          times1545.push( '' );
        }
      }

      if (timeBefore <= 1600 && 1600 <= timeAfter) {
        if (timeBefore === 1600) {
          times1600.push( times );
        } else {
          times1600.push( '' );
        }
      }

      if (timeBefore <= 1615 && 1615 <= timeAfter) {
        if (timeBefore === 1615) {
          times1615.push( times );
        } else {
          times1615.push( '' );
        }
      }

      if (timeBefore <= 1630 && 1630 <= timeAfter) {
        if (timeBefore === 1630) {
          times1630.push( times );
        } else {
          times1630.push( '' );
        }
      }

      if (timeBefore <= 1645 && 1645 <= timeAfter) {
        if (timeBefore === 1645) {
          times1645.push( times );
        } else {
          times1645.push( '' );
        }
      }

      if (timeBefore <= 1700 && 1700 <= timeAfter) {
        if (timeBefore === 1700) {
          times1700.push( times );
        } else {
          times1700.push( '' );
        }
      }

      if (timeBefore <= 1715 && 1715 <= timeAfter) {
        if (timeBefore === 1715) {
          times1715.push( times );
        } else {
          times1715.push( '' );
        }
      }

      if (timeBefore <= 1730 && 1730 <= timeAfter) {
        if (timeBefore === 1730) {
          times1730.push( times );
        } else {
          times1730.push( '' );
        }
      }

      if (timeBefore <= 1745 && 1745 <= timeAfter) {
        if (timeBefore === 1745) {
          times1745.push( times );
        } else {
          times1745.push( '' );
        }
      }

      if (timeBefore <= 1800 && 1800 <= timeAfter) {
        if (timeBefore === 1800) {
          times1800.push( times );
        } else {
          times1800.push( '' );
        }
      }

      if (timeBefore <= 1815 && 1815 <= timeAfter) {
        if (timeBefore === 1815) {
          times1815.push( times );
        } else {
          times1815.push( '' );
        }
      }

      if (timeBefore <= 1830 && 1830 <= timeAfter) {
        if (timeBefore === 1830) {
          times1830.push( times );
        } else {
          times1830.push( '' );
        }
      }

      if (timeBefore <= 1845 && 1845 <= timeAfter) {
        if (timeBefore === 1845) {
          times1845.push( times );
        } else {
          times1845.push( '' );
        }
      }

      if (timeBefore <= 1900 && 1900 <= timeAfter) {
        if (timeBefore === 1900) {
          times1900.push( times );
        } else {
          times1900.push( '' );
        }
      }

      if (timeBefore <= 1915 && 1915 <= timeAfter) {
        if (timeBefore === 1915) {
          times1915.push( times );
        } else {
          times1915.push( '' );
        }
      }

      if (timeBefore <= 1930 && 1930 <= timeAfter) {
        if (timeBefore === 1930) {
          times1930.push( times );
        } else {
          times1930.push( '' );
        }
      }

      if (timeBefore <= 1945 && 1945 <= timeAfter) {
        if (timeBefore === 1945) {
          times1945.push( times );
        } else {
          times1945.push( '' );
        }
      }

      if (timeBefore <= 2000 && 2000 <= timeAfter) {
        if (timeBefore === 2000) {
          times2000.push( times );
        } else {
          times2000.push( '' );
        }
      }

      if (timeBefore <= 2015 && 2015 <= timeAfter) {
        if (timeBefore === 2015) {
          times2015.push( times );
        } else {
          times2015.push( '' );
        }
      }

      if (timeBefore <= 2030 && 2030 <= timeAfter) {
        if (timeBefore === 2030) {
          times2030.push( times );
        } else {
          times2030.push( '' );
        }
      }

      if (timeBefore <= 2045 && 2045 <= timeAfter) {
        if (timeBefore === 2045) {
          times2045.push( times );
        } else {
          times2045.push( '' );
        }
      }

      if (timeBefore <= 2100 && 2100 <= timeAfter) {
        if (timeBefore === 2100) {
          times2100.push( times );
        } else {
          times2100.push( '' );
        }
      }
    })

    calendar.push( times900, times915, times930, times945, times1000, times1015, times1030, times1045, times1100, times1115, times1130, times1145, times1200, times1215, times1230, times1245, times1300, times1315, times1330, times1345, times1400, times1415, times1430, times1445, times1500, times1515, times1530, times1545, times1600, times1615, times1630, times1645, times1700, times1715, times1730, times1745, times1800, times1815, times1830, times1845, times1900, times1915, times1930, times1945, times2000, times2015, times2030, times2045, times2100 )
    setCalendar1Data( calendar );

    var gridCount = Math.max(
      times900.length, times915.length, times930.length, times945.length, times1000.length, times1015.length, times1030.length, times1045.length, times1100.length, times1115.length, times1130.length, times1145.length, times1200.length, times1215.length, times1230.length, times1245.length, times1300.length, times1315.length, times1330.length, times1345.length, times1400.length, times1415.length, times1430.length, times1445.length, times1500.length, times1515.length, times1530.length, times1545.length, times1600.length, times1615.length, times1630.length, times1645.length, times1700.length, times1715.length, times1730.length, times1745.length, times1800.length, times1815.length, times1830.length, times1845.length, times1900.length, times1915.length, times1930.length, times1945.length, times2000.length, times2015.length, times2030.length, times2045.length, times2100.length,
    );

    setCalendar1MaxLength(gridCount);

    if (gridCount === 0) {
      setGridCount1('');
    } else if (gridCount === 1) {
      setGridCount1('1fr');
    } else if (gridCount === 2) {
      setGridCount1('1fr 1fr');
    } else if (gridCount === 3) {
      setGridCount1('1fr 1fr 1fr');
    } else if (gridCount === 4) {
      setGridCount1('1fr 1fr 1fr 1fr');
    } else if (gridCount === 5) {
      setGridCount1('1fr 1fr 1fr 1fr 1fr');
    } else if (gridCount === 6) {
      setGridCount1('1fr 1fr 1fr 1fr 1fr 1fr');
    } else if (gridCount === 7) {
      setGridCount1('1fr 1fr 1fr 1fr 1fr 1fr 1fr');
    } else if (gridCount === 8) {
      setGridCount1('1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr');
    } else if (gridCount === 9) {
      setGridCount1('1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr');
    } else if (gridCount === 10) {
      setGridCount1('1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr');
    }
  }

  function getCalendar2Array(data: any[]) {

    var calendar: any[] = [];
    
    var times900: any[] = [];
    var times915: any[] = [];
    var times930: any[] = [];
    var times945: any[] = [];
    var times1000: any[] = [];
    var times1015: any[] = [];
    var times1030: any[] = [];
    var times1045: any[] = [];
    var times1100: any[] = [];
    var times1115: any[] = [];
    var times1130: any[] = [];
    var times1145: any[] = [];
    var times1200: any[] = [];
    var times1215: any[] = [];
    var times1230: any[] = [];
    var times1245: any[] = [];
    var times1300: any[] = [];
    var times1315: any[] = [];
    var times1330: any[] = [];
    var times1345: any[] = [];
    var times1400: any[] = [];
    var times1415: any[] = [];
    var times1430: any[] = [];
    var times1445: any[] = [];
    var times1500: any[] = [];
    var times1515: any[] = [];
    var times1530: any[] = [];
    var times1545: any[] = [];
    var times1600: any[] = [];
    var times1615: any[] = [];
    var times1630: any[] = [];
    var times1645: any[] = [];
    var times1700: any[] = [];
    var times1715: any[] = [];
    var times1730: any[] = [];
    var times1745: any[] = [];
    var times1800: any[] = [];
    var times1815: any[] = [];
    var times1830: any[] = [];
    var times1845: any[] = [];
    var times1900: any[] = [];
    var times1915: any[] = [];
    var times1930: any[] = [];
    var times1945: any[] = [];
    var times2000: any[] = [];
    var times2015: any[] = [];
    var times2030: any[] = [];
    var times2045: any[] = [];
    var times2100: any[] = [];

    data.map((times, i) => {
      
      var timeBefore = Number(`${times[0].getHours()}` + `${times[0].getMinutes() === 0 ? '00' : times[0].getMinutes()}`);
      var timeAfter = Number(`${times[1].getHours()}` + `${times[1].getMinutes() === 0 ? '00' : times[1].getMinutes()}`);

      if (timeBefore <= 900 && 900 <= timeAfter) {
        if (timeBefore === 900) {
          times900.push( times );
        } else {
          times900.push( '' );
        }
      }

      if (timeBefore <= 915 && 915 <= timeAfter) {
        if (timeBefore === 915) {
          times915.push( times );
        } else {
          times915.push( '' );
        }
      }

      if (timeBefore <= 930 && 930 <= timeAfter) {
        if (timeBefore === 930) {
          times930.push( times );
        } else {
          times930.push( '' );
        }
      }

      if (timeBefore <= 945 && 945 <= timeAfter) {
        if (timeBefore === 945) {
          times945.push( times );
        } else {
          times945.push( '' );
        }
      }

      if (timeBefore <= 1000 && 1000 <= timeAfter) {
        if (timeBefore === 1000) {
          times1000.push( times );
        } else {
          times1000.push( '' );
        }
      }

      if (timeBefore <= 1015 && 1015 <= timeAfter) {
        if (timeBefore === 1015) {
          times1015.push( times );
        } else {
          times1015.push( '' );
        }
      }

      if (timeBefore <= 1030 && 1030 <= timeAfter) {
        if (timeBefore === 1030) {
          times1030.push( times );
        } else {
          times1030.push( '' );
        }
      }

      if (timeBefore <= 1045 && 1045 <= timeAfter) {
        if (timeBefore === 1045) {
          times1045.push( times );
        } else {
          times1045.push( '' );
        }
      }

      if (timeBefore <= 1100 && 1100 <= timeAfter) {
        if (timeBefore === 1100) {
          times1100.push( times );
        } else {
          times1100.push( '' );
        }
      }

      if (timeBefore <= 1115 && 1115 <= timeAfter) {
        if (timeBefore === 1115) {
          times1115.push( times );
        } else {
          times1115.push( '' );
        }
      }

      if (timeBefore <= 1130 && 1130 <= timeAfter) {
        if (timeBefore === 1130) {
          times1130.push( times );
        } else {
          times1130.push( '' );
        }
      }

      if (timeBefore <= 1145 && 1145 <= timeAfter) {
        if (timeBefore === 1145) {
          times1145.push( times );
        } else {
          times1145.push( '' );
        }
      }

      if (timeBefore <= 1200 && 1200 <= timeAfter) {
        if (timeBefore === 1200) {
          times1200.push( times );
        } else {
          times1200.push( '' );
        }
      }

      if (timeBefore <= 1215 && 1215 <= timeAfter) {
        if (timeBefore === 1215) {
          times1215.push( times );
        } else {
          times1215.push( '' );
        }
      }

      if (timeBefore <= 1230 && 1230 <= timeAfter) {
        if (timeBefore === 1230) {
          times1230.push( times );
        } else {
          times1230.push( '' );
        }
      }

      if (timeBefore <= 1245 && 1245 <= timeAfter) {
        if (timeBefore === 1245) {
          times1245.push( times );
        } else {
          times1245.push( '' );
        }
      }

      if (timeBefore <= 1300 && 1300 <= timeAfter) {
        if (timeBefore === 1300) {
          times1300.push( times );
        } else {
          times1300.push( '' );
        }
      }

      if (timeBefore <= 1315 && 1315 <= timeAfter) {
        if (timeBefore === 1315) {
          times1315.push( times );
        } else {
          times1315.push( '' );
        }
      }

      if (timeBefore <= 1330 && 1330 <= timeAfter) {
        if (timeBefore === 1330) {
          times1330.push( times );
        } else {
          times1330.push( '' );
        }
      }

      if (timeBefore <= 1345 && 1345 <= timeAfter) {
        if (timeBefore === 1345) {
          times1345.push( times );
        } else {
          times1345.push( '' );
        }
      }

      if (timeBefore <= 1400 && 1400 <= timeAfter) {
        if (timeBefore === 1400) {
          times1400.push( times );
        } else {
          times1400.push( '' );
        }
      }

      if (timeBefore <= 1415 && 1415 <= timeAfter) {
        if (timeBefore === 1415) {
          times1415.push( times );
        } else {
          times1415.push( '' );
        }
      }

      if (timeBefore <= 1430 && 1430 <= timeAfter) {
        if (timeBefore === 1430) {
          times1430.push( times );
        } else {
          times1430.push( '' );
        }
      }

      if (timeBefore <= 1445 && 1445 <= timeAfter) {
        if (timeBefore === 1445) {
          times1445.push( times );
        } else {
          times1445.push( '' );
        }
      }

      if (timeBefore <= 1500 && 1500 <= timeAfter) {
        if (timeBefore === 1500) {
          times1500.push( times );
        } else {
          times1500.push( '' );
        }
      }

      if (timeBefore <= 1515 && 1515 <= timeAfter) {
        if (timeBefore === 1515) {
          times1515.push( times );
        } else {
          times1515.push( '' );
        }
      }

      if (timeBefore <= 1530 && 1530 <= timeAfter) {
        if (timeBefore === 1530) {
          times1530.push( times );
        } else {
          times1530.push( '' );
        }
      }

      if (timeBefore <= 1545 && 1545 <= timeAfter) {
        if (timeBefore === 1545) {
          times1545.push( times );
        } else {
          times1545.push( '' );
        }
      }

      if (timeBefore <= 1600 && 1600 <= timeAfter) {
        if (timeBefore === 1600) {
          times1600.push( times );
        } else {
          times1600.push( '' );
        }
      }

      if (timeBefore <= 1615 && 1615 <= timeAfter) {
        if (timeBefore === 1615) {
          times1615.push( times );
        } else {
          times1615.push( '' );
        }
      }

      if (timeBefore <= 1630 && 1630 <= timeAfter) {
        if (timeBefore === 1630) {
          times1630.push( times );
        } else {
          times1630.push( '' );
        }
      }

      if (timeBefore <= 1645 && 1645 <= timeAfter) {
        if (timeBefore === 1645) {
          times1645.push( times );
        } else {
          times1645.push( '' );
        }
      }

      if (timeBefore <= 1700 && 1700 <= timeAfter) {
        if (timeBefore === 1700) {
          times1700.push( times );
        } else {
          times1700.push( '' );
        }
      }

      if (timeBefore <= 1715 && 1715 <= timeAfter) {
        if (timeBefore === 1715) {
          times1715.push( times );
        } else {
          times1715.push( '' );
        }
      }

      if (timeBefore <= 1730 && 1730 <= timeAfter) {
        if (timeBefore === 1730) {
          times1730.push( times );
        } else {
          times1730.push( '' );
        }
      }

      if (timeBefore <= 1745 && 1745 <= timeAfter) {
        if (timeBefore === 1745) {
          times1745.push( times );
        } else {
          times1745.push( '' );
        }
      }

      if (timeBefore <= 1800 && 1800 <= timeAfter) {
        if (timeBefore === 1800) {
          times1800.push( times );
        } else {
          times1800.push( '' );
        }
      }

      if (timeBefore <= 1815 && 1815 <= timeAfter) {
        if (timeBefore === 1815) {
          times1815.push( times );
        } else {
          times1815.push( '' );
        }
      }

      if (timeBefore <= 1830 && 1830 <= timeAfter) {
        if (timeBefore === 1830) {
          times1830.push( times );
        } else {
          times1830.push( '' );
        }
      }

      if (timeBefore <= 1845 && 1845 <= timeAfter) {
        if (timeBefore === 1845) {
          times1845.push( times );
        } else {
          times1845.push( '' );
        }
      }

      if (timeBefore <= 1900 && 1900 <= timeAfter) {
        if (timeBefore === 1900) {
          times1900.push( times );
        } else {
          times1900.push( '' );
        }
      }

      if (timeBefore <= 1915 && 1915 <= timeAfter) {
        if (timeBefore === 1915) {
          times1915.push( times );
        } else {
          times1915.push( '' );
        }
      }

      if (timeBefore <= 1930 && 1930 <= timeAfter) {
        if (timeBefore === 1930) {
          times1930.push( times );
        } else {
          times1930.push( '' );
        }
      }

      if (timeBefore <= 1945 && 1945 <= timeAfter) {
        if (timeBefore === 1945) {
          times1945.push( times );
        } else {
          times1945.push( '' );
        }
      }

      if (timeBefore <= 2000 && 2000 <= timeAfter) {
        if (timeBefore === 2000) {
          times2000.push( times );
        } else {
          times2000.push( '' );
        }
      }

      if (timeBefore <= 2015 && 2015 <= timeAfter) {
        if (timeBefore === 2015) {
          times2015.push( times );
        } else {
          times2015.push( '' );
        }
      }

      if (timeBefore <= 2030 && 2030 <= timeAfter) {
        if (timeBefore === 2030) {
          times2030.push( times );
        } else {
          times2030.push( '' );
        }
      }

      if (timeBefore <= 2045 && 2045 <= timeAfter) {
        if (timeBefore === 2045) {
          times2045.push( times );
        } else {
          times2045.push( '' );
        }
      }

      if (timeBefore <= 2100 && 2100 <= timeAfter) {
        if (timeBefore === 2100) {
          times2100.push( times );
        } else {
          times2100.push( '' );
        }
      }
    })

    calendar.push( times900, times915, times930, times945, times1000, times1015, times1030, times1045, times1100, times1115, times1130, times1145, times1200, times1215, times1230, times1245, times1300, times1315, times1330, times1345, times1400, times1415, times1430, times1445, times1500, times1515, times1530, times1545, times1600, times1615, times1630, times1645, times1700, times1715, times1730, times1745, times1800, times1815, times1830, times1845, times1900, times1915, times1930, times1945, times2000, times2015, times2030, times2045, times2100 )
    setCalendar2Data( calendar );

    var gridCount = Math.max(
      times900.length, times915.length, times930.length, times945.length, times1000.length, times1015.length, times1030.length, times1045.length, times1100.length, times1115.length, times1130.length, times1145.length, times1200.length, times1215.length, times1230.length, times1245.length, times1300.length, times1315.length, times1330.length, times1345.length, times1400.length, times1415.length, times1430.length, times1445.length, times1500.length, times1515.length, times1530.length, times1545.length, times1600.length, times1615.length, times1630.length, times1645.length, times1700.length, times1715.length, times1730.length, times1745.length, times1800.length, times1815.length, times1830.length, times1845.length, times1900.length, times1915.length, times1930.length, times1945.length, times2000.length, times2015.length, times2030.length, times2045.length, times2100.length,
    );

    setCalendar2MaxLength(gridCount);

    if (gridCount === 0) {
      setGridCount2('');
    } else if (gridCount === 1) {
      setGridCount2('1fr');
    } else if (gridCount === 2) {
      setGridCount2('1fr 1fr');
    } else if (gridCount === 3) {
      setGridCount2('1fr 1fr 1fr');
    } else if (gridCount === 4) {
      setGridCount2('1fr 1fr 1fr 1fr');
    } else if (gridCount === 5) {
      setGridCount2('1fr 1fr 1fr 1fr 1fr');
    } else if (gridCount === 6) {
      setGridCount2('1fr 1fr 1fr 1fr 1fr 1fr');
    } else if (gridCount === 7) {
      setGridCount2('1fr 1fr 1fr 1fr 1fr 1fr 1fr');
    } else if (gridCount === 8) {
      setGridCount2('1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr');
    } else if (gridCount === 9) {
      setGridCount2('1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr');
    } else if (gridCount === 10) {
      setGridCount2('1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr');
    }
  }

  function getCalendar3Array(data: any[]) {

    var calendar: any[] = [];
    
    var times900: any[] = [];
    var times915: any[] = [];
    var times930: any[] = [];
    var times945: any[] = [];
    var times1000: any[] = [];
    var times1015: any[] = [];
    var times1030: any[] = [];
    var times1045: any[] = [];
    var times1100: any[] = [];
    var times1115: any[] = [];
    var times1130: any[] = [];
    var times1145: any[] = [];
    var times1200: any[] = [];
    var times1215: any[] = [];
    var times1230: any[] = [];
    var times1245: any[] = [];
    var times1300: any[] = [];
    var times1315: any[] = [];
    var times1330: any[] = [];
    var times1345: any[] = [];
    var times1400: any[] = [];
    var times1415: any[] = [];
    var times1430: any[] = [];
    var times1445: any[] = [];
    var times1500: any[] = [];
    var times1515: any[] = [];
    var times1530: any[] = [];
    var times1545: any[] = [];
    var times1600: any[] = [];
    var times1615: any[] = [];
    var times1630: any[] = [];
    var times1645: any[] = [];
    var times1700: any[] = [];
    var times1715: any[] = [];
    var times1730: any[] = [];
    var times1745: any[] = [];
    var times1800: any[] = [];
    var times1815: any[] = [];
    var times1830: any[] = [];
    var times1845: any[] = [];
    var times1900: any[] = [];
    var times1915: any[] = [];
    var times1930: any[] = [];
    var times1945: any[] = [];
    var times2000: any[] = [];
    var times2015: any[] = [];
    var times2030: any[] = [];
    var times2045: any[] = [];
    var times2100: any[] = [];
    

    data.map((times, i) => {
      
      var timeBefore = Number(`${times[0].getHours()}` + `${times[0].getMinutes() === 0 ? '00' : times[0].getMinutes()}`);
      var timeAfter = Number(`${times[1].getHours()}` + `${times[1].getMinutes() === 0 ? '00' : times[1].getMinutes()}`);

      if (timeBefore <= 900 && 900 <= timeAfter) {
        if (timeBefore === 900) {
          times900.push( times );
        } else {
          times900.push( '' );
        }
      }

      if (timeBefore <= 915 && 915 <= timeAfter) {
        if (timeBefore === 915) {
          times915.push( times );
        } else {
          times915.push( '' );
        }
      }

      if (timeBefore <= 930 && 930 <= timeAfter) {
        if (timeBefore === 930) {
          times930.push( times );
        } else {
          times930.push( '' );
        }
      }

      if (timeBefore <= 945 && 945 <= timeAfter) {
        if (timeBefore === 945) {
          times945.push( times );
        } else {
          times945.push( '' );
        }
      }

      if (timeBefore <= 1000 && 1000 <= timeAfter) {
        if (timeBefore === 1000) {
          times1000.push( times );
        } else {
          times1000.push( '' );
        }
      }

      if (timeBefore <= 1015 && 1015 <= timeAfter) {
        if (timeBefore === 1015) {
          times1015.push( times );
        } else {
          times1015.push( '' );
        }
      }

      if (timeBefore <= 1030 && 1030 <= timeAfter) {
        if (timeBefore === 1030) {
          times1030.push( times );
        } else {
          times1030.push( '' );
        }
      }

      if (timeBefore <= 1045 && 1045 <= timeAfter) {
        if (timeBefore === 1045) {
          times1045.push( times );
        } else {
          times1045.push( '' );
        }
      }

      if (timeBefore <= 1100 && 1100 <= timeAfter) {
        if (timeBefore === 1100) {
          times1100.push( times );
        } else {
          times1100.push( '' );
        }
      }

      if (timeBefore <= 1115 && 1115 <= timeAfter) {
        if (timeBefore === 1115) {
          times1115.push( times );
        } else {
          times1115.push( '' );
        }
      }

      if (timeBefore <= 1130 && 1130 <= timeAfter) {
        if (timeBefore === 1130) {
          times1130.push( times );
        } else {
          times1130.push( '' );
        }
      }

      if (timeBefore <= 1145 && 1145 <= timeAfter) {
        if (timeBefore === 1145) {
          times1145.push( times );
        } else {
          times1145.push( '' );
        }
      }

      if (timeBefore <= 1200 && 1200 <= timeAfter) {
        if (timeBefore === 1200) {
          times1200.push( times );
        } else {
          times1200.push( '' );
        }
      }

      if (timeBefore <= 1215 && 1215 <= timeAfter) {
        if (timeBefore === 1215) {
          times1215.push( times );
        } else {
          times1215.push( '' );
        }
      }

      if (timeBefore <= 1230 && 1230 <= timeAfter) {
        if (timeBefore === 1230) {
          times1230.push( times );
        } else {
          times1230.push( '' );
        }
      }

      if (timeBefore <= 1245 && 1245 <= timeAfter) {
        if (timeBefore === 1245) {
          times1245.push( times );
        } else {
          times1245.push( '' );
        }
      }

      if (timeBefore <= 1300 && 1300 <= timeAfter) {
        if (timeBefore === 1300) {
          times1300.push( times );
        } else {
          times1300.push( '' );
        }
      }

      if (timeBefore <= 1315 && 1315 <= timeAfter) {
        if (timeBefore === 1315) {
          times1315.push( times );
        } else {
          times1315.push( '' );
        }
      }

      if (timeBefore <= 1330 && 1330 <= timeAfter) {
        if (timeBefore === 1330) {
          times1330.push( times );
        } else {
          times1330.push( '' );
        }
      }

      if (timeBefore <= 1345 && 1345 <= timeAfter) {
        if (timeBefore === 1345) {
          times1345.push( times );
        } else {
          times1345.push( '' );
        }
      }

      if (timeBefore <= 1400 && 1400 <= timeAfter) {
        if (timeBefore === 1400) {
          times1400.push( times );
        } else {
          times1400.push( '' );
        }
      }

      if (timeBefore <= 1415 && 1415 <= timeAfter) {
        if (timeBefore === 1415) {
          times1415.push( times );
        } else {
          times1415.push( '' );
        }
      }

      if (timeBefore <= 1430 && 1430 <= timeAfter) {
        if (timeBefore === 1430) {
          times1430.push( times );
        } else {
          times1430.push( '' );
        }
      }

      if (timeBefore <= 1445 && 1445 <= timeAfter) {
        if (timeBefore === 1445) {
          times1445.push( times );
        } else {
          times1445.push( '' );
        }
      }

      if (timeBefore <= 1500 && 1500 <= timeAfter) {
        if (timeBefore === 1500) {
          times1500.push( times );
        } else {
          times1500.push( '' );
        }
      }

      if (timeBefore <= 1515 && 1515 <= timeAfter) {
        if (timeBefore === 1515) {
          times1515.push( times );
        } else {
          times1515.push( '' );
        }
      }

      if (timeBefore <= 1530 && 1530 <= timeAfter) {
        if (timeBefore === 1530) {
          times1530.push( times );
        } else {
          times1530.push( '' );
        }
      }

      if (timeBefore <= 1545 && 1545 <= timeAfter) {
        if (timeBefore === 1545) {
          times1545.push( times );
        } else {
          times1545.push( '' );
        }
      }

      if (timeBefore <= 1600 && 1600 <= timeAfter) {
        if (timeBefore === 1600) {
          times1600.push( times );
        } else {
          times1600.push( '' );
        }
      }

      if (timeBefore <= 1615 && 1615 <= timeAfter) {
        if (timeBefore === 1615) {
          times1615.push( times );
        } else {
          times1615.push( '' );
        }
      }

      if (timeBefore <= 1630 && 1630 <= timeAfter) {
        if (timeBefore === 1630) {
          times1630.push( times );
        } else {
          times1630.push( '' );
        }
      }

      if (timeBefore <= 1645 && 1645 <= timeAfter) {
        if (timeBefore === 1645) {
          times1645.push( times );
        } else {
          times1645.push( '' );
        }
      }

      if (timeBefore <= 1700 && 1700 <= timeAfter) {
        if (timeBefore === 1700) {
          times1700.push( times );
        } else {
          times1700.push( '' );
        }
      }

      if (timeBefore <= 1715 && 1715 <= timeAfter) {
        if (timeBefore === 1715) {
          times1715.push( times );
        } else {
          times1715.push( '' );
        }
      }

      if (timeBefore <= 1730 && 1730 <= timeAfter) {
        if (timeBefore === 1730) {
          times1730.push( times );
        } else {
          times1730.push( '' );
        }
      }

      if (timeBefore <= 1745 && 1745 <= timeAfter) {
        if (timeBefore === 1745) {
          times1745.push( times );
        } else {
          times1745.push( '' );
        }
      }

      if (timeBefore <= 1800 && 1800 <= timeAfter) {
        if (timeBefore === 1800) {
          times1800.push( times );
        } else {
          times1800.push( '' );
        }
      }

      if (timeBefore <= 1815 && 1815 <= timeAfter) {
        if (timeBefore === 1815) {
          times1815.push( times );
        } else {
          times1815.push( '' );
        }
      }

      if (timeBefore <= 1830 && 1830 <= timeAfter) {
        if (timeBefore === 1830) {
          times1830.push( times );
        } else {
          times1830.push( '' );
        }
      }

      if (timeBefore <= 1845 && 1845 <= timeAfter) {
        if (timeBefore === 1845) {
          times1845.push( times );
        } else {
          times1845.push( '' );
        }
      }

      if (timeBefore <= 1900 && 1900 <= timeAfter) {
        if (timeBefore === 1900) {
          times1900.push( times );
        } else {
          times1900.push( '' );
        }
      }

      if (timeBefore <= 1915 && 1915 <= timeAfter) {
        if (timeBefore === 1915) {
          times1915.push( times );
        } else {
          times1915.push( '' );
        }
      }

      if (timeBefore <= 1930 && 1930 <= timeAfter) {
        if (timeBefore === 1930) {
          times1930.push( times );
        } else {
          times1930.push( '' );
        }
      }

      if (timeBefore <= 1945 && 1945 <= timeAfter) {
        if (timeBefore === 1945) {
          times1945.push( times );
        } else {
          times1945.push( '' );
        }
      }

      if (timeBefore <= 2000 && 2000 <= timeAfter) {
        if (timeBefore === 2000) {
          times2000.push( times );
        } else {
          times2000.push( '' );
        }
      }

      if (timeBefore <= 2015 && 2015 <= timeAfter) {
        if (timeBefore === 2015) {
          times2015.push( times );
        } else {
          times2015.push( '' );
        }
      }

      if (timeBefore <= 2030 && 2030 <= timeAfter) {
        if (timeBefore === 2030) {
          times2030.push( times );
        } else {
          times2030.push( '' );
        }
      }

      if (timeBefore <= 2045 && 2045 <= timeAfter) {
        if (timeBefore === 2045) {
          times2045.push( times );
        } else {
          times2045.push( '' );
        }
      }

      if (timeBefore <= 2100 && 2100 <= timeAfter) {
        if (timeBefore === 2100) {
          times2100.push( times );
        } else {
          times2100.push( '' );
        }
      }
    })

    calendar.push( times900, times915, times930, times945, times1000, times1015, times1030, times1045, times1100, times1115, times1130, times1145, times1200, times1215, times1230, times1245, times1300, times1315, times1330, times1345, times1400, times1415, times1430, times1445, times1500, times1515, times1530, times1545, times1600, times1615, times1630, times1645, times1700, times1715, times1730, times1745, times1800, times1815, times1830, times1845, times1900, times1915, times1930, times1945, times2000, times2015, times2030, times2045, times2100 )
    setCalendar3Data( calendar );

    var gridCount = Math.max(
      times900.length, times915.length, times930.length, times945.length, times1000.length, times1015.length, times1030.length, times1045.length, times1100.length, times1115.length, times1130.length, times1145.length, times1200.length, times1215.length, times1230.length, times1245.length, times1300.length, times1315.length, times1330.length, times1345.length, times1400.length, times1415.length, times1430.length, times1445.length, times1500.length, times1515.length, times1530.length, times1545.length, times1600.length, times1615.length, times1630.length, times1645.length, times1700.length, times1715.length, times1730.length, times1745.length, times1800.length, times1815.length, times1830.length, times1845.length, times1900.length, times1915.length, times1930.length, times1945.length, times2000.length, times2015.length, times2030.length, times2045.length, times2100.length,
    );

    setCalendar3MaxLength(gridCount);

    if (gridCount === 0) {
      setGridCount3('');
    } else if (gridCount === 1) {
      setGridCount3('1fr');
    } else if (gridCount === 2) {
      setGridCount3('1fr 1fr');
    } else if (gridCount === 3) {
      setGridCount3('1fr 1fr 1fr');
    } else if (gridCount === 4) {
      setGridCount3('1fr 1fr 1fr 1fr');
    } else if (gridCount === 5) {
      setGridCount3('1fr 1fr 1fr 1fr 1fr');
    } else if (gridCount === 6) {
      setGridCount3('1fr 1fr 1fr 1fr 1fr 1fr');
    } else if (gridCount === 7) {
      setGridCount3('1fr 1fr 1fr 1fr 1fr 1fr 1fr');
    } else if (gridCount === 8) {
      setGridCount3('1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr');
    } else if (gridCount === 9) {
      setGridCount3('1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr');
    } else if (gridCount === 10) {
      setGridCount3('1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr');
    }
  }

  function getCalendar4Array(data: any[]) {

    var calendar: any[] = [];
    
    var times900: any[] = [];
    var times915: any[] = [];
    var times930: any[] = [];
    var times945: any[] = [];
    var times1000: any[] = [];
    var times1015: any[] = [];
    var times1030: any[] = [];
    var times1045: any[] = [];
    var times1100: any[] = [];
    var times1115: any[] = [];
    var times1130: any[] = [];
    var times1145: any[] = [];
    var times1200: any[] = [];
    var times1215: any[] = [];
    var times1230: any[] = [];
    var times1245: any[] = [];
    var times1300: any[] = [];
    var times1315: any[] = [];
    var times1330: any[] = [];
    var times1345: any[] = [];
    var times1400: any[] = [];
    var times1415: any[] = [];
    var times1430: any[] = [];
    var times1445: any[] = [];
    var times1500: any[] = [];
    var times1515: any[] = [];
    var times1530: any[] = [];
    var times1545: any[] = [];
    var times1600: any[] = [];
    var times1615: any[] = [];
    var times1630: any[] = [];
    var times1645: any[] = [];
    var times1700: any[] = [];
    var times1715: any[] = [];
    var times1730: any[] = [];
    var times1745: any[] = [];
    var times1800: any[] = [];
    var times1815: any[] = [];
    var times1830: any[] = [];
    var times1845: any[] = [];
    var times1900: any[] = [];
    var times1915: any[] = [];
    var times1930: any[] = [];
    var times1945: any[] = [];
    var times2000: any[] = [];
    var times2015: any[] = [];
    var times2030: any[] = [];
    var times2045: any[] = [];
    var times2100: any[] = [];
    

    data.map((times, i) => {
      
      var timeBefore = Number(`${times[0].getHours()}` + `${times[0].getMinutes() === 0 ? '00' : times[0].getMinutes()}`);
      var timeAfter = Number(`${times[1].getHours()}` + `${times[1].getMinutes() === 0 ? '00' : times[1].getMinutes()}`);

      if (timeBefore <= 900 && 900 <= timeAfter) {
        if (timeBefore === 900) {
          times900.push( times );
        } else {
          times900.push( '' );
        }
      }

      if (timeBefore <= 915 && 915 <= timeAfter) {
        if (timeBefore === 915) {
          times915.push( times );
        } else {
          times915.push( '' );
        }
      }

      if (timeBefore <= 930 && 930 <= timeAfter) {
        if (timeBefore === 930) {
          times930.push( times );
        } else {
          times930.push( '' );
        }
      }

      if (timeBefore <= 945 && 945 <= timeAfter) {
        if (timeBefore === 945) {
          times945.push( times );
        } else {
          times945.push( '' );
        }
      }

      if (timeBefore <= 1000 && 1000 <= timeAfter) {
        if (timeBefore === 1000) {
          times1000.push( times );
        } else {
          times1000.push( '' );
        }
      }

      if (timeBefore <= 1015 && 1015 <= timeAfter) {
        if (timeBefore === 1015) {
          times1015.push( times );
        } else {
          times1015.push( '' );
        }
      }

      if (timeBefore <= 1030 && 1030 <= timeAfter) {
        if (timeBefore === 1030) {
          times1030.push( times );
        } else {
          times1030.push( '' );
        }
      }

      if (timeBefore <= 1045 && 1045 <= timeAfter) {
        if (timeBefore === 1045) {
          times1045.push( times );
        } else {
          times1045.push( '' );
        }
      }

      if (timeBefore <= 1100 && 1100 <= timeAfter) {
        if (timeBefore === 1100) {
          times1100.push( times );
        } else {
          times1100.push( '' );
        }
      }

      if (timeBefore <= 1115 && 1115 <= timeAfter) {
        if (timeBefore === 1115) {
          times1115.push( times );
        } else {
          times1115.push( '' );
        }
      }

      if (timeBefore <= 1130 && 1130 <= timeAfter) {
        if (timeBefore === 1130) {
          times1130.push( times );
        } else {
          times1130.push( '' );
        }
      }

      if (timeBefore <= 1145 && 1145 <= timeAfter) {
        if (timeBefore === 1145) {
          times1145.push( times );
        } else {
          times1145.push( '' );
        }
      }

      if (timeBefore <= 1200 && 1200 <= timeAfter) {
        if (timeBefore === 1200) {
          times1200.push( times );
        } else {
          times1200.push( '' );
        }
      }

      if (timeBefore <= 1215 && 1215 <= timeAfter) {
        if (timeBefore === 1215) {
          times1215.push( times );
        } else {
          times1215.push( '' );
        }
      }

      if (timeBefore <= 1230 && 1230 <= timeAfter) {
        if (timeBefore === 1230) {
          times1230.push( times );
        } else {
          times1230.push( '' );
        }
      }

      if (timeBefore <= 1245 && 1245 <= timeAfter) {
        if (timeBefore === 1245) {
          times1245.push( times );
        } else {
          times1245.push( '' );
        }
      }

      if (timeBefore <= 1300 && 1300 <= timeAfter) {
        if (timeBefore === 1300) {
          times1300.push( times );
        } else {
          times1300.push( '' );
        }
      }

      if (timeBefore <= 1315 && 1315 <= timeAfter) {
        if (timeBefore === 1315) {
          times1315.push( times );
        } else {
          times1315.push( '' );
        }
      }

      if (timeBefore <= 1330 && 1330 <= timeAfter) {
        if (timeBefore === 1330) {
          times1330.push( times );
        } else {
          times1330.push( '' );
        }
      }

      if (timeBefore <= 1345 && 1345 <= timeAfter) {
        if (timeBefore === 1345) {
          times1345.push( times );
        } else {
          times1345.push( '' );
        }
      }

      if (timeBefore <= 1400 && 1400 <= timeAfter) {
        if (timeBefore === 1400) {
          times1400.push( times );
        } else {
          times1400.push( '' );
        }
      }

      if (timeBefore <= 1415 && 1415 <= timeAfter) {
        if (timeBefore === 1415) {
          times1415.push( times );
        } else {
          times1415.push( '' );
        }
      }

      if (timeBefore <= 1430 && 1430 <= timeAfter) {
        if (timeBefore === 1430) {
          times1430.push( times );
        } else {
          times1430.push( '' );
        }
      }

      if (timeBefore <= 1445 && 1445 <= timeAfter) {
        if (timeBefore === 1445) {
          times1445.push( times );
        } else {
          times1445.push( '' );
        }
      }

      if (timeBefore <= 1500 && 1500 <= timeAfter) {
        if (timeBefore === 1500) {
          times1500.push( times );
        } else {
          times1500.push( '' );
        }
      }

      if (timeBefore <= 1515 && 1515 <= timeAfter) {
        if (timeBefore === 1515) {
          times1515.push( times );
        } else {
          times1515.push( '' );
        }
      }

      if (timeBefore <= 1530 && 1530 <= timeAfter) {
        if (timeBefore === 1530) {
          times1530.push( times );
        } else {
          times1530.push( '' );
        }
      }

      if (timeBefore <= 1545 && 1545 <= timeAfter) {
        if (timeBefore === 1545) {
          times1545.push( times );
        } else {
          times1545.push( '' );
        }
      }

      if (timeBefore <= 1600 && 1600 <= timeAfter) {
        if (timeBefore === 1600) {
          times1600.push( times );
        } else {
          times1600.push( '' );
        }
      }

      if (timeBefore <= 1615 && 1615 <= timeAfter) {
        if (timeBefore === 1615) {
          times1615.push( times );
        } else {
          times1615.push( '' );
        }
      }

      if (timeBefore <= 1630 && 1630 <= timeAfter) {
        if (timeBefore === 1630) {
          times1630.push( times );
        } else {
          times1630.push( '' );
        }
      }

      if (timeBefore <= 1645 && 1645 <= timeAfter) {
        if (timeBefore === 1645) {
          times1645.push( times );
        } else {
          times1645.push( '' );
        }
      }

      if (timeBefore <= 1700 && 1700 <= timeAfter) {
        if (timeBefore === 1700) {
          times1700.push( times );
        } else {
          times1700.push( '' );
        }
      }

      if (timeBefore <= 1715 && 1715 <= timeAfter) {
        if (timeBefore === 1715) {
          times1715.push( times );
        } else {
          times1715.push( '' );
        }
      }

      if (timeBefore <= 1730 && 1730 <= timeAfter) {
        if (timeBefore === 1730) {
          times1730.push( times );
        } else {
          times1730.push( '' );
        }
      }

      if (timeBefore <= 1745 && 1745 <= timeAfter) {
        if (timeBefore === 1745) {
          times1745.push( times );
        } else {
          times1745.push( '' );
        }
      }

      if (timeBefore <= 1800 && 1800 <= timeAfter) {
        if (timeBefore === 1800) {
          times1800.push( times );
        } else {
          times1800.push( '' );
        }
      }

      if (timeBefore <= 1815 && 1815 <= timeAfter) {
        if (timeBefore === 1815) {
          times1815.push( times );
        } else {
          times1815.push( '' );
        }
      }

      if (timeBefore <= 1830 && 1830 <= timeAfter) {
        if (timeBefore === 1830) {
          times1830.push( times );
        } else {
          times1830.push( '' );
        }
      }

      if (timeBefore <= 1845 && 1845 <= timeAfter) {
        if (timeBefore === 1845) {
          times1845.push( times );
        } else {
          times1845.push( '' );
        }
      }

      if (timeBefore <= 1900 && 1900 <= timeAfter) {
        if (timeBefore === 1900) {
          times1900.push( times );
        } else {
          times1900.push( '' );
        }
      }

      if (timeBefore <= 1915 && 1915 <= timeAfter) {
        if (timeBefore === 1915) {
          times1915.push( times );
        } else {
          times1915.push( '' );
        }
      }

      if (timeBefore <= 1930 && 1930 <= timeAfter) {
        if (timeBefore === 1930) {
          times1930.push( times );
        } else {
          times1930.push( '' );
        }
      }

      if (timeBefore <= 1945 && 1945 <= timeAfter) {
        if (timeBefore === 1945) {
          times1945.push( times );
        } else {
          times1945.push( '' );
        }
      }

      if (timeBefore <= 2000 && 2000 <= timeAfter) {
        if (timeBefore === 2000) {
          times2000.push( times );
        } else {
          times2000.push( '' );
        }
      }

      if (timeBefore <= 2015 && 2015 <= timeAfter) {
        if (timeBefore === 2015) {
          times2015.push( times );
        } else {
          times2015.push( '' );
        }
      }

      if (timeBefore <= 2030 && 2030 <= timeAfter) {
        if (timeBefore === 2030) {
          times2030.push( times );
        } else {
          times2030.push( '' );
        }
      }

      if (timeBefore <= 2045 && 2045 <= timeAfter) {
        if (timeBefore === 2045) {
          times2045.push( times );
        } else {
          times2045.push( '' );
        }
      }

      if (timeBefore <= 2100 && 2100 <= timeAfter) {
        if (timeBefore === 2100) {
          times2100.push( times );
        } else {
          times2100.push( '' );
        }
      }
    })

    calendar.push( times900, times915, times930, times945, times1000, times1015, times1030, times1045, times1100, times1115, times1130, times1145, times1200, times1215, times1230, times1245, times1300, times1315, times1330, times1345, times1400, times1415, times1430, times1445, times1500, times1515, times1530, times1545, times1600, times1615, times1630, times1645, times1700, times1715, times1730, times1745, times1800, times1815, times1830, times1845, times1900, times1915, times1930, times1945, times2000, times2015, times2030, times2045, times2100 )
    setCalendar4Data( calendar );

    var gridCount = Math.max(
      times900.length, times915.length, times930.length, times945.length, times1000.length, times1015.length, times1030.length, times1045.length, times1100.length, times1115.length, times1130.length, times1145.length, times1200.length, times1215.length, times1230.length, times1245.length, times1300.length, times1315.length, times1330.length, times1345.length, times1400.length, times1415.length, times1430.length, times1445.length, times1500.length, times1515.length, times1530.length, times1545.length, times1600.length, times1615.length, times1630.length, times1645.length, times1700.length, times1715.length, times1730.length, times1745.length, times1800.length, times1815.length, times1830.length, times1845.length, times1900.length, times1915.length, times1930.length, times1945.length, times2000.length, times2015.length, times2030.length, times2045.length, times2100.length,
    );

    setCalendar4MaxLength(gridCount);

    if (gridCount === 0) {
      setGridCount4('');
    } else if (gridCount === 1) {
      setGridCount4('1fr');
    } else if (gridCount === 2) {
      setGridCount4('1fr 1fr');
    } else if (gridCount === 3) {
      setGridCount4('1fr 1fr 1fr');
    } else if (gridCount === 4) {
      setGridCount4('1fr 1fr 1fr 1fr');
    } else if (gridCount === 5) {
      setGridCount4('1fr 1fr 1fr 1fr 1fr');
    } else if (gridCount === 6) {
      setGridCount4('1fr 1fr 1fr 1fr 1fr 1fr');
    } else if (gridCount === 7) {
      setGridCount4('1fr 1fr 1fr 1fr 1fr 1fr 1fr');
    } else if (gridCount === 8) {
      setGridCount4('1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr');
    } else if (gridCount === 9) {
      setGridCount4('1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr');
    } else if (gridCount === 10) {
      setGridCount4('1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr');
    }
  }

  function getCalendar5Array(data: any[]) {

    var calendar: any[] = [];
    
    var times900: any[] = [];
    var times915: any[] = [];
    var times930: any[] = [];
    var times945: any[] = [];
    var times1000: any[] = [];
    var times1015: any[] = [];
    var times1030: any[] = [];
    var times1045: any[] = [];
    var times1100: any[] = [];
    var times1115: any[] = [];
    var times1130: any[] = [];
    var times1145: any[] = [];
    var times1200: any[] = [];
    var times1215: any[] = [];
    var times1230: any[] = [];
    var times1245: any[] = [];
    var times1300: any[] = [];
    var times1315: any[] = [];
    var times1330: any[] = [];
    var times1345: any[] = [];
    var times1400: any[] = [];
    var times1415: any[] = [];
    var times1430: any[] = [];
    var times1445: any[] = [];
    var times1500: any[] = [];
    var times1515: any[] = [];
    var times1530: any[] = [];
    var times1545: any[] = [];
    var times1600: any[] = [];
    var times1615: any[] = [];
    var times1630: any[] = [];
    var times1645: any[] = [];
    var times1700: any[] = [];
    var times1715: any[] = [];
    var times1730: any[] = [];
    var times1745: any[] = [];
    var times1800: any[] = [];
    var times1815: any[] = [];
    var times1830: any[] = [];
    var times1845: any[] = [];
    var times1900: any[] = [];
    var times1915: any[] = [];
    var times1930: any[] = [];
    var times1945: any[] = [];
    var times2000: any[] = [];
    var times2015: any[] = [];
    var times2030: any[] = [];
    var times2045: any[] = [];
    var times2100: any[] = [];
    

    data.map((times, i) => {
      
      var timeBefore = Number(`${times[0].getHours()}` + `${times[0].getMinutes() === 0 ? '00' : times[0].getMinutes()}`);
      var timeAfter = Number(`${times[1].getHours()}` + `${times[1].getMinutes() === 0 ? '00' : times[1].getMinutes()}`);

      if (timeBefore <= 900 && 900 <= timeAfter) {
        if (timeBefore === 900) {
          times900.push( times );
        } else {
          times900.push( '' );
        }
      }

      if (timeBefore <= 915 && 915 <= timeAfter) {
        if (timeBefore === 915) {
          times915.push( times );
        } else {
          times915.push( '' );
        }
      }

      if (timeBefore <= 930 && 930 <= timeAfter) {
        if (timeBefore === 930) {
          times930.push( times );
        } else {
          times930.push( '' );
        }
      }

      if (timeBefore <= 945 && 945 <= timeAfter) {
        if (timeBefore === 945) {
          times945.push( times );
        } else {
          times945.push( '' );
        }
      }

      if (timeBefore <= 1000 && 1000 <= timeAfter) {
        if (timeBefore === 1000) {
          times1000.push( times );
        } else {
          times1000.push( '' );
        }
      }

      if (timeBefore <= 1015 && 1015 <= timeAfter) {
        if (timeBefore === 1015) {
          times1015.push( times );
        } else {
          times1015.push( '' );
        }
      }

      if (timeBefore <= 1030 && 1030 <= timeAfter) {
        if (timeBefore === 1030) {
          times1030.push( times );
        } else {
          times1030.push( '' );
        }
      }

      if (timeBefore <= 1045 && 1045 <= timeAfter) {
        if (timeBefore === 1045) {
          times1045.push( times );
        } else {
          times1045.push( '' );
        }
      }

      if (timeBefore <= 1100 && 1100 <= timeAfter) {
        if (timeBefore === 1100) {
          times1100.push( times );
        } else {
          times1100.push( '' );
        }
      }

      if (timeBefore <= 1115 && 1115 <= timeAfter) {
        if (timeBefore === 1115) {
          times1115.push( times );
        } else {
          times1115.push( '' );
        }
      }

      if (timeBefore <= 1130 && 1130 <= timeAfter) {
        if (timeBefore === 1130) {
          times1130.push( times );
        } else {
          times1130.push( '' );
        }
      }

      if (timeBefore <= 1145 && 1145 <= timeAfter) {
        if (timeBefore === 1145) {
          times1145.push( times );
        } else {
          times1145.push( '' );
        }
      }

      if (timeBefore <= 1200 && 1200 <= timeAfter) {
        if (timeBefore === 1200) {
          times1200.push( times );
        } else {
          times1200.push( '' );
        }
      }

      if (timeBefore <= 1215 && 1215 <= timeAfter) {
        if (timeBefore === 1215) {
          times1215.push( times );
        } else {
          times1215.push( '' );
        }
      }

      if (timeBefore <= 1230 && 1230 <= timeAfter) {
        if (timeBefore === 1230) {
          times1230.push( times );
        } else {
          times1230.push( '' );
        }
      }

      if (timeBefore <= 1245 && 1245 <= timeAfter) {
        if (timeBefore === 1245) {
          times1245.push( times );
        } else {
          times1245.push( '' );
        }
      }

      if (timeBefore <= 1300 && 1300 <= timeAfter) {
        if (timeBefore === 1300) {
          times1300.push( times );
        } else {
          times1300.push( '' );
        }
      }

      if (timeBefore <= 1315 && 1315 <= timeAfter) {
        if (timeBefore === 1315) {
          times1315.push( times );
        } else {
          times1315.push( '' );
        }
      }

      if (timeBefore <= 1330 && 1330 <= timeAfter) {
        if (timeBefore === 1330) {
          times1330.push( times );
        } else {
          times1330.push( '' );
        }
      }

      if (timeBefore <= 1345 && 1345 <= timeAfter) {
        if (timeBefore === 1345) {
          times1345.push( times );
        } else {
          times1345.push( '' );
        }
      }

      if (timeBefore <= 1400 && 1400 <= timeAfter) {
        if (timeBefore === 1400) {
          times1400.push( times );
        } else {
          times1400.push( '' );
        }
      }

      if (timeBefore <= 1415 && 1415 <= timeAfter) {
        if (timeBefore === 1415) {
          times1415.push( times );
        } else {
          times1415.push( '' );
        }
      }

      if (timeBefore <= 1430 && 1430 <= timeAfter) {
        if (timeBefore === 1430) {
          times1430.push( times );
        } else {
          times1430.push( '' );
        }
      }

      if (timeBefore <= 1445 && 1445 <= timeAfter) {
        if (timeBefore === 1445) {
          times1445.push( times );
        } else {
          times1445.push( '' );
        }
      }

      if (timeBefore <= 1500 && 1500 <= timeAfter) {
        if (timeBefore === 1500) {
          times1500.push( times );
        } else {
          times1500.push( '' );
        }
      }

      if (timeBefore <= 1515 && 1515 <= timeAfter) {
        if (timeBefore === 1515) {
          times1515.push( times );
        } else {
          times1515.push( '' );
        }
      }

      if (timeBefore <= 1530 && 1530 <= timeAfter) {
        if (timeBefore === 1530) {
          times1530.push( times );
        } else {
          times1530.push( '' );
        }
      }

      if (timeBefore <= 1545 && 1545 <= timeAfter) {
        if (timeBefore === 1545) {
          times1545.push( times );
        } else {
          times1545.push( '' );
        }
      }

      if (timeBefore <= 1600 && 1600 <= timeAfter) {
        if (timeBefore === 1600) {
          times1600.push( times );
        } else {
          times1600.push( '' );
        }
      }

      if (timeBefore <= 1615 && 1615 <= timeAfter) {
        if (timeBefore === 1615) {
          times1615.push( times );
        } else {
          times1615.push( '' );
        }
      }

      if (timeBefore <= 1630 && 1630 <= timeAfter) {
        if (timeBefore === 1630) {
          times1630.push( times );
        } else {
          times1630.push( '' );
        }
      }

      if (timeBefore <= 1645 && 1645 <= timeAfter) {
        if (timeBefore === 1645) {
          times1645.push( times );
        } else {
          times1645.push( '' );
        }
      }

      if (timeBefore <= 1700 && 1700 <= timeAfter) {
        if (timeBefore === 1700) {
          times1700.push( times );
        } else {
          times1700.push( '' );
        }
      }

      if (timeBefore <= 1715 && 1715 <= timeAfter) {
        if (timeBefore === 1715) {
          times1715.push( times );
        } else {
          times1715.push( '' );
        }
      }

      if (timeBefore <= 1730 && 1730 <= timeAfter) {
        if (timeBefore === 1730) {
          times1730.push( times );
        } else {
          times1730.push( '' );
        }
      }

      if (timeBefore <= 1745 && 1745 <= timeAfter) {
        if (timeBefore === 1745) {
          times1745.push( times );
        } else {
          times1745.push( '' );
        }
      }

      if (timeBefore <= 1800 && 1800 <= timeAfter) {
        if (timeBefore === 1800) {
          times1800.push( times );
        } else {
          times1800.push( '' );
        }
      }

      if (timeBefore <= 1815 && 1815 <= timeAfter) {
        if (timeBefore === 1815) {
          times1815.push( times );
        } else {
          times1815.push( '' );
        }
      }

      if (timeBefore <= 1830 && 1830 <= timeAfter) {
        if (timeBefore === 1830) {
          times1830.push( times );
        } else {
          times1830.push( '' );
        }
      }

      if (timeBefore <= 1845 && 1845 <= timeAfter) {
        if (timeBefore === 1845) {
          times1845.push( times );
        } else {
          times1845.push( '' );
        }
      }

      if (timeBefore <= 1900 && 1900 <= timeAfter) {
        if (timeBefore === 1900) {
          times1900.push( times );
        } else {
          times1900.push( '' );
        }
      }

      if (timeBefore <= 1915 && 1915 <= timeAfter) {
        if (timeBefore === 1915) {
          times1915.push( times );
        } else {
          times1915.push( '' );
        }
      }

      if (timeBefore <= 1930 && 1930 <= timeAfter) {
        if (timeBefore === 1930) {
          times1930.push( times );
        } else {
          times1930.push( '' );
        }
      }

      if (timeBefore <= 1945 && 1945 <= timeAfter) {
        if (timeBefore === 1945) {
          times1945.push( times );
        } else {
          times1945.push( '' );
        }
      }

      if (timeBefore <= 2000 && 2000 <= timeAfter) {
        if (timeBefore === 2000) {
          times2000.push( times );
        } else {
          times2000.push( '' );
        }
      }

      if (timeBefore <= 2015 && 2015 <= timeAfter) {
        if (timeBefore === 2015) {
          times2015.push( times );
        } else {
          times2015.push( '' );
        }
      }

      if (timeBefore <= 2030 && 2030 <= timeAfter) {
        if (timeBefore === 2030) {
          times2030.push( times );
        } else {
          times2030.push( '' );
        }
      }

      if (timeBefore <= 2045 && 2045 <= timeAfter) {
        if (timeBefore === 2045) {
          times2045.push( times );
        } else {
          times2045.push( '' );
        }
      }

      if (timeBefore <= 2100 && 2100 <= timeAfter) {
        if (timeBefore === 2100) {
          times2100.push( times );
        } else {
          times2100.push( '' );
        }
      }
    })

    calendar.push( times900, times915, times930, times945, times1000, times1015, times1030, times1045, times1100, times1115, times1130, times1145, times1200, times1215, times1230, times1245, times1300, times1315, times1330, times1345, times1400, times1415, times1430, times1445, times1500, times1515, times1530, times1545, times1600, times1615, times1630, times1645, times1700, times1715, times1730, times1745, times1800, times1815, times1830, times1845, times1900, times1915, times1930, times1945, times2000, times2015, times2030, times2045, times2100 )
    setCalendar5Data( calendar );

    var gridCount = Math.max(
      times900.length, times915.length, times930.length, times945.length, times1000.length, times1015.length, times1030.length, times1045.length, times1100.length, times1115.length, times1130.length, times1145.length, times1200.length, times1215.length, times1230.length, times1245.length, times1300.length, times1315.length, times1330.length, times1345.length, times1400.length, times1415.length, times1430.length, times1445.length, times1500.length, times1515.length, times1530.length, times1545.length, times1600.length, times1615.length, times1630.length, times1645.length, times1700.length, times1715.length, times1730.length, times1745.length, times1800.length, times1815.length, times1830.length, times1845.length, times1900.length, times1915.length, times1930.length, times1945.length, times2000.length, times2015.length, times2030.length, times2045.length, times2100.length,
    );

    setCalendar5MaxLength(gridCount);

    if (gridCount === 0) {
      setGridCount5('');
    } else if (gridCount === 1) {
      setGridCount5('1fr');
    } else if (gridCount === 2) {
      setGridCount5('1fr 1fr');
    } else if (gridCount === 3) {
      setGridCount5('1fr 1fr 1fr');
    } else if (gridCount === 4) {
      setGridCount5('1fr 1fr 1fr 1fr');
    } else if (gridCount === 5) {
      setGridCount5('1fr 1fr 1fr 1fr 1fr');
    } else if (gridCount === 6) {
      setGridCount5('1fr 1fr 1fr 1fr 1fr 1fr');
    } else if (gridCount === 7) {
      setGridCount5('1fr 1fr 1fr 1fr 1fr 1fr 1fr');
    } else if (gridCount === 8) {
      setGridCount5('1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr');
    } else if (gridCount === 9) {
      setGridCount5('1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr');
    } else if (gridCount === 10) {
      setGridCount5('1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr');
    }
  }

  function getCalendar6Array(data: any[]) {

    var calendar: any[] = [];
    
    var times900: any[] = [];
    var times915: any[] = [];
    var times930: any[] = [];
    var times945: any[] = [];
    var times1000: any[] = [];
    var times1015: any[] = [];
    var times1030: any[] = [];
    var times1045: any[] = [];
    var times1100: any[] = [];
    var times1115: any[] = [];
    var times1130: any[] = [];
    var times1145: any[] = [];
    var times1200: any[] = [];
    var times1215: any[] = [];
    var times1230: any[] = [];
    var times1245: any[] = [];
    var times1300: any[] = [];
    var times1315: any[] = [];
    var times1330: any[] = [];
    var times1345: any[] = [];
    var times1400: any[] = [];
    var times1415: any[] = [];
    var times1430: any[] = [];
    var times1445: any[] = [];
    var times1500: any[] = [];
    var times1515: any[] = [];
    var times1530: any[] = [];
    var times1545: any[] = [];
    var times1600: any[] = [];
    var times1615: any[] = [];
    var times1630: any[] = [];
    var times1645: any[] = [];
    var times1700: any[] = [];
    var times1715: any[] = [];
    var times1730: any[] = [];
    var times1745: any[] = [];
    var times1800: any[] = [];
    var times1815: any[] = [];
    var times1830: any[] = [];
    var times1845: any[] = [];
    var times1900: any[] = [];
    var times1915: any[] = [];
    var times1930: any[] = [];
    var times1945: any[] = [];
    var times2000: any[] = [];
    var times2015: any[] = [];
    var times2030: any[] = [];
    var times2045: any[] = [];
    var times2100: any[] = [];
    

    data.map((times, i) => {
      
      var timeBefore = Number(`${times[0].getHours()}` + `${times[0].getMinutes() === 0 ? '00' : times[0].getMinutes()}`);
      var timeAfter = Number(`${times[1].getHours()}` + `${times[1].getMinutes() === 0 ? '00' : times[1].getMinutes()}`);

      if (timeBefore <= 900 && 900 <= timeAfter) {
        if (timeBefore === 900) {
          times900.push( times );
        } else {
          times900.push( '' );
        }
      }

      if (timeBefore <= 915 && 915 <= timeAfter) {
        if (timeBefore === 915) {
          times915.push( times );
        } else {
          times915.push( '' );
        }
      }

      if (timeBefore <= 930 && 930 <= timeAfter) {
        if (timeBefore === 930) {
          times930.push( times );
        } else {
          times930.push( '' );
        }
      }

      if (timeBefore <= 945 && 945 <= timeAfter) {
        if (timeBefore === 945) {
          times945.push( times );
        } else {
          times945.push( '' );
        }
      }

      if (timeBefore <= 1000 && 1000 <= timeAfter) {
        if (timeBefore === 1000) {
          times1000.push( times );
        } else {
          times1000.push( '' );
        }
      }

      if (timeBefore <= 1015 && 1015 <= timeAfter) {
        if (timeBefore === 1015) {
          times1015.push( times );
        } else {
          times1015.push( '' );
        }
      }

      if (timeBefore <= 1030 && 1030 <= timeAfter) {
        if (timeBefore === 1030) {
          times1030.push( times );
        } else {
          times1030.push( '' );
        }
      }

      if (timeBefore <= 1045 && 1045 <= timeAfter) {
        if (timeBefore === 1045) {
          times1045.push( times );
        } else {
          times1045.push( '' );
        }
      }

      if (timeBefore <= 1100 && 1100 <= timeAfter) {
        if (timeBefore === 1100) {
          times1100.push( times );
        } else {
          times1100.push( '' );
        }
      }

      if (timeBefore <= 1115 && 1115 <= timeAfter) {
        if (timeBefore === 1115) {
          times1115.push( times );
        } else {
          times1115.push( '' );
        }
      }

      if (timeBefore <= 1130 && 1130 <= timeAfter) {
        if (timeBefore === 1130) {
          times1130.push( times );
        } else {
          times1130.push( '' );
        }
      }

      if (timeBefore <= 1145 && 1145 <= timeAfter) {
        if (timeBefore === 1145) {
          times1145.push( times );
        } else {
          times1145.push( '' );
        }
      }

      if (timeBefore <= 1200 && 1200 <= timeAfter) {
        if (timeBefore === 1200) {
          times1200.push( times );
        } else {
          times1200.push( '' );
        }
      }

      if (timeBefore <= 1215 && 1215 <= timeAfter) {
        if (timeBefore === 1215) {
          times1215.push( times );
        } else {
          times1215.push( '' );
        }
      }

      if (timeBefore <= 1230 && 1230 <= timeAfter) {
        if (timeBefore === 1230) {
          times1230.push( times );
        } else {
          times1230.push( '' );
        }
      }

      if (timeBefore <= 1245 && 1245 <= timeAfter) {
        if (timeBefore === 1245) {
          times1245.push( times );
        } else {
          times1245.push( '' );
        }
      }

      if (timeBefore <= 1300 && 1300 <= timeAfter) {
        if (timeBefore === 1300) {
          times1300.push( times );
        } else {
          times1300.push( '' );
        }
      }

      if (timeBefore <= 1315 && 1315 <= timeAfter) {
        if (timeBefore === 1315) {
          times1315.push( times );
        } else {
          times1315.push( '' );
        }
      }

      if (timeBefore <= 1330 && 1330 <= timeAfter) {
        if (timeBefore === 1330) {
          times1330.push( times );
        } else {
          times1330.push( '' );
        }
      }

      if (timeBefore <= 1345 && 1345 <= timeAfter) {
        if (timeBefore === 1345) {
          times1345.push( times );
        } else {
          times1345.push( '' );
        }
      }

      if (timeBefore <= 1400 && 1400 <= timeAfter) {
        if (timeBefore === 1400) {
          times1400.push( times );
        } else {
          times1400.push( '' );
        }
      }

      if (timeBefore <= 1415 && 1415 <= timeAfter) {
        if (timeBefore === 1415) {
          times1415.push( times );
        } else {
          times1415.push( '' );
        }
      }

      if (timeBefore <= 1430 && 1430 <= timeAfter) {
        if (timeBefore === 1430) {
          times1430.push( times );
        } else {
          times1430.push( '' );
        }
      }

      if (timeBefore <= 1445 && 1445 <= timeAfter) {
        if (timeBefore === 1445) {
          times1445.push( times );
        } else {
          times1445.push( '' );
        }
      }

      if (timeBefore <= 1500 && 1500 <= timeAfter) {
        if (timeBefore === 1500) {
          times1500.push( times );
        } else {
          times1500.push( '' );
        }
      }

      if (timeBefore <= 1515 && 1515 <= timeAfter) {
        if (timeBefore === 1515) {
          times1515.push( times );
        } else {
          times1515.push( '' );
        }
      }

      if (timeBefore <= 1530 && 1530 <= timeAfter) {
        if (timeBefore === 1530) {
          times1530.push( times );
        } else {
          times1530.push( '' );
        }
      }

      if (timeBefore <= 1545 && 1545 <= timeAfter) {
        if (timeBefore === 1545) {
          times1545.push( times );
        } else {
          times1545.push( '' );
        }
      }

      if (timeBefore <= 1600 && 1600 <= timeAfter) {
        if (timeBefore === 1600) {
          times1600.push( times );
        } else {
          times1600.push( '' );
        }
      }

      if (timeBefore <= 1615 && 1615 <= timeAfter) {
        if (timeBefore === 1615) {
          times1615.push( times );
        } else {
          times1615.push( '' );
        }
      }

      if (timeBefore <= 1630 && 1630 <= timeAfter) {
        if (timeBefore === 1630) {
          times1630.push( times );
        } else {
          times1630.push( '' );
        }
      }

      if (timeBefore <= 1645 && 1645 <= timeAfter) {
        if (timeBefore === 1645) {
          times1645.push( times );
        } else {
          times1645.push( '' );
        }
      }

      if (timeBefore <= 1700 && 1700 <= timeAfter) {
        if (timeBefore === 1700) {
          times1700.push( times );
        } else {
          times1700.push( '' );
        }
      }

      if (timeBefore <= 1715 && 1715 <= timeAfter) {
        if (timeBefore === 1715) {
          times1715.push( times );
        } else {
          times1715.push( '' );
        }
      }

      if (timeBefore <= 1730 && 1730 <= timeAfter) {
        if (timeBefore === 1730) {
          times1730.push( times );
        } else {
          times1730.push( '' );
        }
      }

      if (timeBefore <= 1745 && 1745 <= timeAfter) {
        if (timeBefore === 1745) {
          times1745.push( times );
        } else {
          times1745.push( '' );
        }
      }

      if (timeBefore <= 1800 && 1800 <= timeAfter) {
        if (timeBefore === 1800) {
          times1800.push( times );
        } else {
          times1800.push( '' );
        }
      }

      if (timeBefore <= 1815 && 1815 <= timeAfter) {
        if (timeBefore === 1815) {
          times1815.push( times );
        } else {
          times1815.push( '' );
        }
      }

      if (timeBefore <= 1830 && 1830 <= timeAfter) {
        if (timeBefore === 1830) {
          times1830.push( times );
        } else {
          times1830.push( '' );
        }
      }

      if (timeBefore <= 1845 && 1845 <= timeAfter) {
        if (timeBefore === 1845) {
          times1845.push( times );
        } else {
          times1845.push( '' );
        }
      }

      if (timeBefore <= 1900 && 1900 <= timeAfter) {
        if (timeBefore === 1900) {
          times1900.push( times );
        } else {
          times1900.push( '' );
        }
      }

      if (timeBefore <= 1915 && 1915 <= timeAfter) {
        if (timeBefore === 1915) {
          times1915.push( times );
        } else {
          times1915.push( '' );
        }
      }

      if (timeBefore <= 1930 && 1930 <= timeAfter) {
        if (timeBefore === 1930) {
          times1930.push( times );
        } else {
          times1930.push( '' );
        }
      }

      if (timeBefore <= 1945 && 1945 <= timeAfter) {
        if (timeBefore === 1945) {
          times1945.push( times );
        } else {
          times1945.push( '' );
        }
      }

      if (timeBefore <= 2000 && 2000 <= timeAfter) {
        if (timeBefore === 2000) {
          times2000.push( times );
        } else {
          times2000.push( '' );
        }
      }

      if (timeBefore <= 2015 && 2015 <= timeAfter) {
        if (timeBefore === 2015) {
          times2015.push( times );
        } else {
          times2015.push( '' );
        }
      }

      if (timeBefore <= 2030 && 2030 <= timeAfter) {
        if (timeBefore === 2030) {
          times2030.push( times );
        } else {
          times2030.push( '' );
        }
      }

      if (timeBefore <= 2045 && 2045 <= timeAfter) {
        if (timeBefore === 2045) {
          times2045.push( times );
        } else {
          times2045.push( '' );
        }
      }

      if (timeBefore <= 2100 && 2100 <= timeAfter) {
        if (timeBefore === 2100) {
          times2100.push( times );
        } else {
          times2100.push( '' );
        }
      }
    })

    calendar.push( times900, times915, times930, times945, times1000, times1015, times1030, times1045, times1100, times1115, times1130, times1145, times1200, times1215, times1230, times1245, times1300, times1315, times1330, times1345, times1400, times1415, times1430, times1445, times1500, times1515, times1530, times1545, times1600, times1615, times1630, times1645, times1700, times1715, times1730, times1745, times1800, times1815, times1830, times1845, times1900, times1915, times1930, times1945, times2000, times2015, times2030, times2045, times2100 )
    setCalendar6Data( calendar );

    var gridCount = Math.max(
      times900.length, times915.length, times930.length, times945.length, times1000.length, times1015.length, times1030.length, times1045.length, times1100.length, times1115.length, times1130.length, times1145.length, times1200.length, times1215.length, times1230.length, times1245.length, times1300.length, times1315.length, times1330.length, times1345.length, times1400.length, times1415.length, times1430.length, times1445.length, times1500.length, times1515.length, times1530.length, times1545.length, times1600.length, times1615.length, times1630.length, times1645.length, times1700.length, times1715.length, times1730.length, times1745.length, times1800.length, times1815.length, times1830.length, times1845.length, times1900.length, times1915.length, times1930.length, times1945.length, times2000.length, times2015.length, times2030.length, times2045.length, times2100.length,
    );

    setCalendar6MaxLength(gridCount);

    if (gridCount === 0) {
      setGridCount6('');
    } else if (gridCount === 1) {
      setGridCount6('1fr');
    } else if (gridCount === 2) {
      setGridCount6('1fr 1fr');
    } else if (gridCount === 3) {
      setGridCount6('1fr 1fr 1fr');
    } else if (gridCount === 4) {
      setGridCount6('1fr 1fr 1fr 1fr');
    } else if (gridCount === 5) {
      setGridCount6('1fr 1fr 1fr 1fr 1fr');
    } else if (gridCount === 6) {
      setGridCount6('1fr 1fr 1fr 1fr 1fr 1fr');
    } else if (gridCount === 7) {
      setGridCount6('1fr 1fr 1fr 1fr 1fr 1fr 1fr');
    } else if (gridCount === 8) {
      setGridCount6('1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr');
    } else if (gridCount === 9) {
      setGridCount6('1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr');
    } else if (gridCount === 10) {
      setGridCount6('1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr');
    }
  }

  function getCalendar7Array(data: any[]) {

    var calendar: any[] = [];
    
    var times900: any[] = [];
    var times915: any[] = [];
    var times930: any[] = [];
    var times945: any[] = [];
    var times1000: any[] = [];
    var times1015: any[] = [];
    var times1030: any[] = [];
    var times1045: any[] = [];
    var times1100: any[] = [];
    var times1115: any[] = [];
    var times1130: any[] = [];
    var times1145: any[] = [];
    var times1200: any[] = [];
    var times1215: any[] = [];
    var times1230: any[] = [];
    var times1245: any[] = [];
    var times1300: any[] = [];
    var times1315: any[] = [];
    var times1330: any[] = [];
    var times1345: any[] = [];
    var times1400: any[] = [];
    var times1415: any[] = [];
    var times1430: any[] = [];
    var times1445: any[] = [];
    var times1500: any[] = [];
    var times1515: any[] = [];
    var times1530: any[] = [];
    var times1545: any[] = [];
    var times1600: any[] = [];
    var times1615: any[] = [];
    var times1630: any[] = [];
    var times1645: any[] = [];
    var times1700: any[] = [];
    var times1715: any[] = [];
    var times1730: any[] = [];
    var times1745: any[] = [];
    var times1800: any[] = [];
    var times1815: any[] = [];
    var times1830: any[] = [];
    var times1845: any[] = [];
    var times1900: any[] = [];
    var times1915: any[] = [];
    var times1930: any[] = [];
    var times1945: any[] = [];
    var times2000: any[] = [];
    var times2015: any[] = [];
    var times2030: any[] = [];
    var times2045: any[] = [];
    var times2100: any[] = [];
    

    data.map((times, i) => {
      
      var timeBefore = Number(`${times[0].getHours()}` + `${times[0].getMinutes() === 0 ? '00' : times[0].getMinutes()}`);
      var timeAfter = Number(`${times[1].getHours()}` + `${times[1].getMinutes() === 0 ? '00' : times[1].getMinutes()}`);

      if (timeBefore <= 900 && 900 <= timeAfter) {
        if (timeBefore === 900) {
          times900.push( times );
        } else {
          times900.push( '' );
        }
      }

      if (timeBefore <= 915 && 915 <= timeAfter) {
        if (timeBefore === 915) {
          times915.push( times );
        } else {
          times915.push( '' );
        }
      }

      if (timeBefore <= 930 && 930 <= timeAfter) {
        if (timeBefore === 930) {
          times930.push( times );
        } else {
          times930.push( '' );
        }
      }

      if (timeBefore <= 945 && 945 <= timeAfter) {
        if (timeBefore === 945) {
          times945.push( times );
        } else {
          times945.push( '' );
        }
      }

      if (timeBefore <= 1000 && 1000 <= timeAfter) {
        if (timeBefore === 1000) {
          times1000.push( times );
        } else {
          times1000.push( '' );
        }
      }

      if (timeBefore <= 1015 && 1015 <= timeAfter) {
        if (timeBefore === 1015) {
          times1015.push( times );
        } else {
          times1015.push( '' );
        }
      }

      if (timeBefore <= 1030 && 1030 <= timeAfter) {
        if (timeBefore === 1030) {
          times1030.push( times );
        } else {
          times1030.push( '' );
        }
      }

      if (timeBefore <= 1045 && 1045 <= timeAfter) {
        if (timeBefore === 1045) {
          times1045.push( times );
        } else {
          times1045.push( '' );
        }
      }

      if (timeBefore <= 1100 && 1100 <= timeAfter) {
        if (timeBefore === 1100) {
          times1100.push( times );
        } else {
          times1100.push( '' );
        }
      }

      if (timeBefore <= 1115 && 1115 <= timeAfter) {
        if (timeBefore === 1115) {
          times1115.push( times );
        } else {
          times1115.push( '' );
        }
      }

      if (timeBefore <= 1130 && 1130 <= timeAfter) {
        if (timeBefore === 1130) {
          times1130.push( times );
        } else {
          times1130.push( '' );
        }
      }

      if (timeBefore <= 1145 && 1145 <= timeAfter) {
        if (timeBefore === 1145) {
          times1145.push( times );
        } else {
          times1145.push( '' );
        }
      }

      if (timeBefore <= 1200 && 1200 <= timeAfter) {
        if (timeBefore === 1200) {
          times1200.push( times );
        } else {
          times1200.push( '' );
        }
      }

      if (timeBefore <= 1215 && 1215 <= timeAfter) {
        if (timeBefore === 1215) {
          times1215.push( times );
        } else {
          times1215.push( '' );
        }
      }

      if (timeBefore <= 1230 && 1230 <= timeAfter) {
        if (timeBefore === 1230) {
          times1230.push( times );
        } else {
          times1230.push( '' );
        }
      }

      if (timeBefore <= 1245 && 1245 <= timeAfter) {
        if (timeBefore === 1245) {
          times1245.push( times );
        } else {
          times1245.push( '' );
        }
      }

      if (timeBefore <= 1300 && 1300 <= timeAfter) {
        if (timeBefore === 1300) {
          times1300.push( times );
        } else {
          times1300.push( '' );
        }
      }

      if (timeBefore <= 1315 && 1315 <= timeAfter) {
        if (timeBefore === 1315) {
          times1315.push( times );
        } else {
          times1315.push( '' );
        }
      }

      if (timeBefore <= 1330 && 1330 <= timeAfter) {
        if (timeBefore === 1330) {
          times1330.push( times );
        } else {
          times1330.push( '' );
        }
      }

      if (timeBefore <= 1345 && 1345 <= timeAfter) {
        if (timeBefore === 1345) {
          times1345.push( times );
        } else {
          times1345.push( '' );
        }
      }

      if (timeBefore <= 1400 && 1400 <= timeAfter) {
        if (timeBefore === 1400) {
          times1400.push( times );
        } else {
          times1400.push( '' );
        }
      }

      if (timeBefore <= 1415 && 1415 <= timeAfter) {
        if (timeBefore === 1415) {
          times1415.push( times );
        } else {
          times1415.push( '' );
        }
      }

      if (timeBefore <= 1430 && 1430 <= timeAfter) {
        if (timeBefore === 1430) {
          times1430.push( times );
        } else {
          times1430.push( '' );
        }
      }

      if (timeBefore <= 1445 && 1445 <= timeAfter) {
        if (timeBefore === 1445) {
          times1445.push( times );
        } else {
          times1445.push( '' );
        }
      }

      if (timeBefore <= 1500 && 1500 <= timeAfter) {
        if (timeBefore === 1500) {
          times1500.push( times );
        } else {
          times1500.push( '' );
        }
      }

      if (timeBefore <= 1515 && 1515 <= timeAfter) {
        if (timeBefore === 1515) {
          times1515.push( times );
        } else {
          times1515.push( '' );
        }
      }

      if (timeBefore <= 1530 && 1530 <= timeAfter) {
        if (timeBefore === 1530) {
          times1530.push( times );
        } else {
          times1530.push( '' );
        }
      }

      if (timeBefore <= 1545 && 1545 <= timeAfter) {
        if (timeBefore === 1545) {
          times1545.push( times );
        } else {
          times1545.push( '' );
        }
      }

      if (timeBefore <= 1600 && 1600 <= timeAfter) {
        if (timeBefore === 1600) {
          times1600.push( times );
        } else {
          times1600.push( '' );
        }
      }

      if (timeBefore <= 1615 && 1615 <= timeAfter) {
        if (timeBefore === 1615) {
          times1615.push( times );
        } else {
          times1615.push( '' );
        }
      }

      if (timeBefore <= 1630 && 1630 <= timeAfter) {
        if (timeBefore === 1630) {
          times1630.push( times );
        } else {
          times1630.push( '' );
        }
      }

      if (timeBefore <= 1645 && 1645 <= timeAfter) {
        if (timeBefore === 1645) {
          times1645.push( times );
        } else {
          times1645.push( '' );
        }
      }

      if (timeBefore <= 1700 && 1700 <= timeAfter) {
        if (timeBefore === 1700) {
          times1700.push( times );
        } else {
          times1700.push( '' );
        }
      }

      if (timeBefore <= 1715 && 1715 <= timeAfter) {
        if (timeBefore === 1715) {
          times1715.push( times );
        } else {
          times1715.push( '' );
        }
      }

      if (timeBefore <= 1730 && 1730 <= timeAfter) {
        if (timeBefore === 1730) {
          times1730.push( times );
        } else {
          times1730.push( '' );
        }
      }

      if (timeBefore <= 1745 && 1745 <= timeAfter) {
        if (timeBefore === 1745) {
          times1745.push( times );
        } else {
          times1745.push( '' );
        }
      }

      if (timeBefore <= 1800 && 1800 <= timeAfter) {
        if (timeBefore === 1800) {
          times1800.push( times );
        } else {
          times1800.push( '' );
        }
      }

      if (timeBefore <= 1815 && 1815 <= timeAfter) {
        if (timeBefore === 1815) {
          times1815.push( times );
        } else {
          times1815.push( '' );
        }
      }

      if (timeBefore <= 1830 && 1830 <= timeAfter) {
        if (timeBefore === 1830) {
          times1830.push( times );
        } else {
          times1830.push( '' );
        }
      }

      if (timeBefore <= 1845 && 1845 <= timeAfter) {
        if (timeBefore === 1845) {
          times1845.push( times );
        } else {
          times1845.push( '' );
        }
      }

      if (timeBefore <= 1900 && 1900 <= timeAfter) {
        if (timeBefore === 1900) {
          times1900.push( times );
        } else {
          times1900.push( '' );
        }
      }

      if (timeBefore <= 1915 && 1915 <= timeAfter) {
        if (timeBefore === 1915) {
          times1915.push( times );
        } else {
          times1915.push( '' );
        }
      }

      if (timeBefore <= 1930 && 1930 <= timeAfter) {
        if (timeBefore === 1930) {
          times1930.push( times );
        } else {
          times1930.push( '' );
        }
      }

      if (timeBefore <= 1945 && 1945 <= timeAfter) {
        if (timeBefore === 1945) {
          times1945.push( times );
        } else {
          times1945.push( '' );
        }
      }

      if (timeBefore <= 2000 && 2000 <= timeAfter) {
        if (timeBefore === 2000) {
          times2000.push( times );
        } else {
          times2000.push( '' );
        }
      }

      if (timeBefore <= 2015 && 2015 <= timeAfter) {
        if (timeBefore === 2015) {
          times2015.push( times );
        } else {
          times2015.push( '' );
        }
      }

      if (timeBefore <= 2030 && 2030 <= timeAfter) {
        if (timeBefore === 2030) {
          times2030.push( times );
        } else {
          times2030.push( '' );
        }
      }

      if (timeBefore <= 2045 && 2045 <= timeAfter) {
        if (timeBefore === 2045) {
          times2045.push( times );
        } else {
          times2045.push( '' );
        }
      }

      if (timeBefore <= 2100 && 2100 <= timeAfter) {
        if (timeBefore === 2100) {
          times2100.push( times );
        } else {
          times2100.push( '' );
        }
      }
    })

    calendar.push( times900, times915, times930, times945, times1000, times1015, times1030, times1045, times1100, times1115, times1130, times1145, times1200, times1215, times1230, times1245, times1300, times1315, times1330, times1345, times1400, times1415, times1430, times1445, times1500, times1515, times1530, times1545, times1600, times1615, times1630, times1645, times1700, times1715, times1730, times1745, times1800, times1815, times1830, times1845, times1900, times1915, times1930, times1945, times2000, times2015, times2030, times2045, times2100 )
    setCalendar7Data( calendar );

    var gridCount = Math.max(
      times900.length, times915.length, times930.length, times945.length, times1000.length, times1015.length, times1030.length, times1045.length, times1100.length, times1115.length, times1130.length, times1145.length, times1200.length, times1215.length, times1230.length, times1245.length, times1300.length, times1315.length, times1330.length, times1345.length, times1400.length, times1415.length, times1430.length, times1445.length, times1500.length, times1515.length, times1530.length, times1545.length, times1600.length, times1615.length, times1630.length, times1645.length, times1700.length, times1715.length, times1730.length, times1745.length, times1800.length, times1815.length, times1830.length, times1845.length, times1900.length, times1915.length, times1930.length, times1945.length, times2000.length, times2015.length, times2030.length, times2045.length, times2100.length,
    );

    setCalendar7MaxLength(gridCount);

    if (gridCount === 0) {
      setGridCount7('');
    } else if (gridCount === 1) {
      setGridCount7('1fr');
    } else if (gridCount === 2) {
      setGridCount7('1fr 1fr');
    } else if (gridCount === 3) {
      setGridCount7('1fr 1fr 1fr');
    } else if (gridCount === 4) {
      setGridCount7('1fr 1fr 1fr 1fr');
    } else if (gridCount === 5) {
      setGridCount7('1fr 1fr 1fr 1fr 1fr');
    } else if (gridCount === 6) {
      setGridCount7('1fr 1fr 1fr 1fr 1fr 1fr');
    } else if (gridCount === 7) {
      setGridCount7('1fr 1fr 1fr 1fr 1fr 1fr 1fr');
    } else if (gridCount === 8) {
      setGridCount7('1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr');
    } else if (gridCount === 9) {
      setGridCount7('1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr');
    } else if (gridCount === 10) {
      setGridCount7('1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr');
    }
  }

  return (
    <>
    <div className='works_service'>
      <div className='works_service_manage_contents'>

        <div className='memorii_recruit_manage_contents_schedule'>
          <div className='memorii_recruit_manage_contents_schedule_left'>
            <FaChevronLeft className='memorii_recruit_manage_contents_schedule_left_icon' onClick={() => getCalc(-1)} />
            <h4>{yearsStart}年{monthStart + 1}月{standard1}日 ~ {yearsLast}年{monthLast + 1}月{standard7}日</h4>
            <FaChevronRight className='memorii_recruit_manage_contents_schedule_left_icon' onClick={() => getCalc(1)} />
          </div>
          <div className='memorii_recruit_manage_contents_schedule_right'>
            <h4>本日のタスク</h4>
          </div>
        </div>

        <div className='memorii_recruit_manage_contents_calendar'>
          <div className='memorii_recruit_manage_contents_calendar_left'>
            <div className='memorii_recruit_manage_contents_calendar_left_title'>
              <h4 style={nowYears === yearsStart && nowMonth === monthStart && nowDay === standard1 ? {backgroundColor : '#192651', color : 'white'} : {}}>{standard1}日(日)</h4>
              <h4 style={nowYears === yearsStart && nowMonth === monthStart && nowDay === standard2 ? {backgroundColor : '#192651', color : 'white'} : {}}>{standard2}日(月)</h4>
              <h4 style={nowYears === yearsStart && nowMonth === monthStart && nowDay === standard3 ? {backgroundColor : '#192651', color : 'white'} : {}}>{standard3}日(火)</h4>
              <h4 style={nowYears === yearsStart && nowMonth === monthStart && nowDay === standard4 ? {backgroundColor : '#192651', color : 'white'} : {}}>{standard4}日(水)</h4>
              <h4 style={nowYears === yearsStart && nowMonth === monthStart && nowDay === standard5 ? {backgroundColor : '#192651', color : 'white'} : {}}>{standard5}日(木)</h4>
              <h4 style={nowYears === yearsStart && nowMonth === monthStart && nowDay === standard6 ? {backgroundColor : '#192651', color : 'white'} : {}}>{standard6}日(金)</h4>
              <h4 style={nowYears === yearsStart && nowMonth === monthStart && nowDay === standard7 ? {backgroundColor : '#192651', color : 'white'} : {}}>{standard7}日(土)</h4>
            </div>
            <div className='memorii_recruit_manage_contents_calendar_left_contents_scroll'>
              <div className='memorii_recruit_manage_contents_calendar_left_contents'>
                <div className='memorii_recruit_manage_contents_calendar_left_contents_left'>
                  <h4>午前9時</h4>
                  <h4>午前10時</h4>
                  <h4>午前11時</h4>
                  <h4>午前12時</h4>
                  <h4>午後1時</h4>
                  <h4>午後2時</h4>
                  <h4>午後3時</h4>
                  <h4>午後4時</h4>
                  <h4>午後5時</h4>
                  <h4>午後6時</h4>
                  <h4>午後7時</h4>
                  <h4>午後8時</h4>
                  <h4>午後9時</h4>
                  <h4>午後10時</h4>
                  <h4>午後11時</h4>
                  <h4>午後12時</h4>
                </div>
                <div className='memorii_recruit_manage_contents_calendar_left_contents_right'>


                  {/* 日曜日 */}
                  <div className='memorii_recruit_manage_contents_calendar_left_contents_right_hr'></div>
                  <div className='memorii_recruit_manage_contents_calendar_left_contents_right_box'>
                    <div className='memorii_recruit_calendar_1' style={{gridTemplateColumns: `${gridCount1}`}}>
                      {calendar1Data.map((row: any[], index: number) =>
                      <>
                        {row.length !== 0 ?
                        <>
                          {row.map((column, i) =>
                          <>
                            {column.length !== 0 ?
                              <div className='memorii_recruit_calendar_1_1' key={i} 
                                onClick={() => {
                                  dispatch(fetchDetails(true)); dispatch(fetchRecruit(column[4])); dispatch(fetchApplicant(column[5]));
                                }}
                                style={{ gridRow: `${index + 1} / ${index + 1 + column[2]}`, gridColumn: `${i + 1} / ${calendar1MaxLength + 1}` }}>
                                  {column[3]}
                              </div>
                            : <></> }
                          </> )}
                        </> : <></> }
                      </> )}
                    </div>
                  </div>

                  {/* 月曜日 */}
                  <div className='memorii_recruit_manage_contents_calendar_left_contents_right_hr'></div>
                  <div className='memorii_recruit_manage_contents_calendar_left_contents_right_box'>
                    <div className='memorii_recruit_calendar_1' style={{gridTemplateColumns: `${gridCount2}`}}>
                      {calendar2Data.map((row: any[], index: number) =>
                      <>
                        {row.length !== 0 ?
                        <>
                          {row.map((column, i) =>
                          <>
                            {column.length !== 0 ?
                              <div className='memorii_recruit_calendar_1_1' key={i} 
                                onClick={() => {
                                  dispatch(fetchDetails(true)); dispatch(fetchRecruit(column[4])); dispatch(fetchApplicant(column[5]));
                                }}
                                style={{ gridRow: `${index + 1} / ${index + 1 + column[2]}`, gridColumn: `${i + 1} / ${calendar2MaxLength + 1}` }}>
                                  {column[3]}
                              </div>
                            : <></> }
                          </> )}
                        </> : <></> }
                      </> )}
                    </div>
                  </div>
                  
                  {/* 火曜日 */}
                  <div className='memorii_recruit_manage_contents_calendar_left_contents_right_hr'></div>
                  <div className='memorii_recruit_manage_contents_calendar_left_contents_right_box'>
                    <div className='memorii_recruit_calendar_1' style={{gridTemplateColumns: `${gridCount3}`}}>
                      {calendar3Data.map((row: any[], index: number) =>
                      <>
                        {row.length !== 0 ?
                        <>
                          {row.map((column, i) =>
                          <>
                            {column.length !== 0 ?
                              <div className='memorii_recruit_calendar_1_1' key={i} 
                                onClick={() => {
                                  dispatch(fetchDetails(true)); dispatch(fetchRecruit(column[4])); dispatch(fetchApplicant(column[5]));
                                }}
                                style={{ gridRow: `${index + 1} / ${index + 1 + column[2]}`, gridColumn: `${i + 1} / ${calendar3MaxLength + 1}` }}>
                                  {column[3]}
                              </div>
                            : <></> }
                          </> )}
                        </> : <></> }
                      </> )}
                    </div>
                  </div>

                  {/* 水曜日 */}
                  <div className='memorii_recruit_manage_contents_calendar_left_contents_right_hr'></div>
                  <div className='memorii_recruit_manage_contents_calendar_left_contents_right_box'>
                    <div className='memorii_recruit_calendar_1' style={{gridTemplateColumns: `${gridCount4}`}}>
                      {calendar4Data.map((row: any[], index: number) =>
                      <>
                        {row.length !== 0 ?
                        <>
                          {row.map((column, i) =>
                          <>
                            {column.length !== 0 ?
                              <div className='memorii_recruit_calendar_1_1' key={i} 
                                onClick={() => {
                                  dispatch(fetchDetails(true)); dispatch(fetchRecruit(column[4])); dispatch(fetchApplicant(column[5]));
                                }}
                                style={{ gridRow: `${index + 1} / ${index + 1 + column[2]}`, gridColumn: `${i + 1} / ${calendar4MaxLength + 1}` }}>
                                  {column[3]}
                              </div>
                            : <></> }
                          </> )}
                        </> : <></> }
                      </> )}
                    </div>
                  </div>

                  {/* 木曜日 */}
                  <div className='memorii_recruit_manage_contents_calendar_left_contents_right_hr'></div>
                  <div className='memorii_recruit_manage_contents_calendar_left_contents_right_box'>
                    <div className='memorii_recruit_calendar_1' style={{gridTemplateColumns: `${gridCount5}`}}>
                      {calendar5Data.map((row: any[], index: number) =>
                      <>
                        {row.length !== 0 ?
                        <>
                          {row.map((column, i) =>
                          <>
                            {column.length !== 0 ?
                              <div className='memorii_recruit_calendar_1_1' key={i} 
                                onClick={() => {
                                  dispatch(fetchDetails(true)); dispatch(fetchRecruit(column[4])); dispatch(fetchApplicant(column[5]));
                                }}
                                style={{ gridRow: `${index + 1} / ${index + 1 + column[2]}`, gridColumn: `${i + 1} / ${calendar5MaxLength + 1}` }}>
                                  {column[3]}
                              </div>
                            : <></> }
                          </> )}
                        </> : <></> }
                      </> )}
                    </div>
                  </div>

                  {/* 金曜日 */}
                  <div className='memorii_recruit_manage_contents_calendar_left_contents_right_hr'></div>
                  <div className='memorii_recruit_manage_contents_calendar_left_contents_right_box'>
                    <div className='memorii_recruit_calendar_1' style={{gridTemplateColumns: `${gridCount6}`}}>
                      {calendar6Data.map((row: any[], index: number) =>
                      <>
                        {row.length !== 0 ?
                        <>
                          {row.map((column, i) =>
                          <>
                            {column.length !== 0 ?
                              <div className='memorii_recruit_calendar_1_1' key={i} 
                                onClick={() => {
                                  dispatch(fetchDetails(true)); dispatch(fetchRecruit(column[4])); dispatch(fetchApplicant(column[5]));
                                }}
                                style={{ gridRow: `${index + 1} / ${index + 1 + column[2]}`, gridColumn: `${i + 1} / ${calendar6MaxLength + 1}` }}>
                                  {column[3]}
                              </div>
                            : <></> }
                          </> )}
                        </> : <></> }
                      </> )}
                    </div>
                  </div>

                  {/* 土曜日 */}
                  <div className='memorii_recruit_manage_contents_calendar_left_contents_right_hr'></div>
                  <div className='memorii_recruit_manage_contents_calendar_left_contents_right_box'>
                    <div className='memorii_recruit_calendar_1' style={{gridTemplateColumns: `${gridCount7}`}}>
                      {calendar7Data.map((row: any[], index: number) =>
                      <>
                        {row.length !== 0 ?
                        <>
                          {row.map((column, i) =>
                          <>
                            {column.length !== 0 ?
                              <div className='memorii_recruit_calendar_1_1' key={i} 
                                onClick={() => {
                                  dispatch(fetchDetails(true)); dispatch(fetchRecruit(column[4])); dispatch(fetchApplicant(column[5]));
                                }}
                                style={{ gridRow: `${index + 1} / ${index + 1 + column[2]}`, gridColumn: `${i + 1} / ${calendar7MaxLength + 1}` }}>
                                  {column[3]}
                              </div>
                            : <></> }
                          </> )}
                        </> : <></> }
                      </> )}
                    </div>
                  </div>
                  <div className='memorii_recruit_manage_contents_calendar_left_contents_right_hr'></div>
                </div>
              </div>
            </div>
          </div>
          <div className='memorii_recruit_manage_contents_calendar_right'>
            {todayTask.map((data:any[], index: number) =>
            <div onClick={() => {
              dispatch(fetchDetails(true)); dispatch(fetchRecruit(data[4])); dispatch(fetchApplicant(data[5]));
            }}>
              <p>{data[0].getHours()}時{data[0].getMinutes() === 0 ? '00' : data[0].getMinutes()}分 ~ {data[1].getHours()}時{data[1].getMinutes() === 0 ? '00' : data[1].getMinutes()}分</p>
              <h4>{data[3]}</h4>
            </div> )}
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

export default SchedulePc;
