import Styles from './styles.module.css';
import React, { useState, useRef, useEffect } from 'react';
import firebase from 'firebase/compat/app';
import { v4 as uuid } from 'uuid';
import { FaEye } from 'react-icons/fa';
import { IoPersonSharp } from 'react-icons/io5';
import { IoMdBookmark } from 'react-icons/io';
import { BsThreeDots } from 'react-icons/bs';
import DeleteRecruit from './deleteRecruit/index';
import Image1 from './../../../image/employment/1.png';
import { useNavigate } from 'react-router-dom';
import EditConfirm from './editConfirm/index';
import { useDispatch, useSelector } from 'react-redux';
import { fetchRecruits } from '../../../features/0.main/recruit';

type RecruitData = {
  id: string;
  status: number;
  title: string;
  employment: string;
  time: string;
  pv: number;
  saves: number;
  applicants: string[];
  recruitment: number;
  goal: number;
  apply: number;
};

type RecruitItemProps = {
  recruit: RecruitData;
};

const RecruitItem: React.FC<RecruitItemProps> = ({ recruit }) => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const company = useSelector((state: any) => state.company.company);

  const [ recruitId, setRecruitId ] = useState<string>('');
  const [ deleteToggle, setDeleteToggle ] = useState(false);
  const [ editToggle, setEditToggle ] = useState(false);

  async function duplicate(data: string) {
    var docId = uuid();

    await firebase.firestore().collection('recruits').doc(data).get()
    .then(async (doc) => {
      if (doc.exists) {
        const data = doc.data();
        if (data) {
          await firebase.firestore().collection('recruits').doc(docId).set(data);
  
          await firebase.firestore().collection('recruits').doc(docId)
          .update({
            store_title: `コピー：${recruit.title}`,
            last_time: new Date(),
            time: new Date(),
      
            // ステータス
            status: 3,
            status_store: false,
            status_recruit: false,
            status_place: false,
            status_document: false,
      
            // データ
            data_applicants: [],
            data_pv: [],
            data_recruitment: [],
            data_save: [],
          });
          await firebase.firestore().collection('companies').doc(company.id)
          .update({ recruits: firebase.firestore.FieldValue.arrayUnion(docId) })
          .then(() => { dispatch(fetchRecruits(company.id)) });
        }
      }
    });
  }

  const [ visible1, setVisible1 ] = useState(false);
  const buttonRef1 = useRef<HTMLDivElement | null>(null);
  const divRef1 = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        divRef1.current && !divRef1.current.contains(event.target as Node) &&
        buttonRef1.current && !buttonRef1.current.contains(event.target as Node)
      ) {
        setVisible1(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleButtonClick1 = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    setVisible1((prev) => !prev);
  };

  return (
    <>
    <div className={Styles.items_table}>
      <div className={Styles.items_table_box_1}
        onClick={() => recruit.status === 3 ? navigate(`/edit/id=${recruit.id}`) : ''}>
        <img src={Image1} className={Styles.items_table_box_1_img} alt='' />
        <div className={Styles.items_table_box_post}>
          <div>
            <div style={recruit.status === 1 ? {backgroundColor: '#59C47C'} : recruit.status === 2 ? {backgroundColor: '#F1AE54'} : {backgroundColor: '#D14F60'}}></div>
            <p>{recruit.status === 1 ? '公開中' : recruit.status === 2 ? '審査中' : '作成中'}</p>
          </div>
          <h2>新卒採用（正社員）</h2>
        </div>
      </div>
      <div className={Styles.items_table_box_2}
        onClick={() => recruit.status === 3 ? navigate(`/edit/id=${recruit.id}`) : ''}>
        <h4>{recruit.title}</h4>
      </div>
      <p className={Styles.items_table_box_3}>
        {new Date(recruit.time).getFullYear()}年
        {new Date(recruit.time).getMonth()+1}月
        {new Date(recruit.time).getDate()}日
      </p>
      <div className={Styles.items_table_date}>
        <div>
          <FaEye className={Styles.items_table_date_icon} />
          <p>{recruit.pv}</p>
        </div>
        <div>
          <IoMdBookmark className={Styles.items_table_date_icon} />
          <p>{recruit.saves}</p>
        </div>
        <div>
          <IoPersonSharp className={Styles.items_table_date_icon} />
          <p>{recruit.applicants}</p>
        </div>
      </div>
      <div className={Styles.items_table_list}  ref={buttonRef1} onClick={handleButtonClick1}>
        <BsThreeDots className={Styles.items_table_list_icon} />

        {visible1 && (
          <div ref={divRef1}>
            <p onClick={() => duplicate(recruit.id)}>複製する</p>
            <p onClick={() => {setDeleteToggle(true); setRecruitId(recruit.id);}}>削除する</p>
            <p onClick={() => {
              if (recruit.status === 1 || recruit.status === 2) {
                setRecruitId(recruit.id); setEditToggle(true);
              } else {
                navigate(`/edit/id=${recruit.id}`)
              }
            }}>編集・停止</p>
          </div>
        )}

      </div>
    </div>

    {deleteToggle && (
      <DeleteRecruit recruitId={recruitId} setToggle={setDeleteToggle} />
    )}

    {editToggle && (
      <EditConfirm recruitId={recruitId} setToggle={setEditToggle} />
    )}
    </>
  );
}

export default RecruitItem;
