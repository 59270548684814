import Styles from './styles.module.css';
import React, { useState, useEffect } from 'react';
import { FaSearch } from "react-icons/fa";
import Chat from './1.chat/index';
import Schedule from './3.schedule/index';
import Message from './2.message/index';
import Navigation from './0.nav/index';
import { fetchManageApplicants } from '../../features/manage/applicants';

import { useDispatch, useSelector } from 'react-redux';
import { fetchChats } from '../../features/6.contact/chatList';

interface recruitList {
  id: string;
  status: number;
  title: string;
  employment: string;
  time: string;
  pv: number;
  saves: number;
  applicants: string[];
  recruitment: number;
  goal: number;
  apply: number;
  exam_selection: number,
  place_storeCount: number;
  place_store1Name: string;
  place_store2Name: string;
  place_store3Name: string;
  place_store4Name: string;
  place_store5Name: string;
}

interface ChatList {
  id: string;
  ids: string[];
  names: string[];
  reason: string;
  time: string;
  applicantId: string;
  badges: number,
  recruitId: string,
}

const Contact: React.FC = () => {

  const dispatch = useDispatch();

  const company = useSelector((state: any) => state.company.company);
  const recruitList = useSelector((state: any) => state.recruit.recruitList as recruitList[]);
  const chatList = useSelector((state: any) => state.contacts.list as ChatList[]);
  const recruitListOnlyId = useSelector((state: any) => state.recruit.recruitIds);

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(fetchChats(company.id));
      await dispatch(fetchManageApplicants(recruitListOnlyId));
    }
    fetchData();
  }, [dispatch, recruitListOnlyId, company.id]);

  const [ choiceNav, setChoiceNav ] = useState(1000);
  const [ choiceRecruit, setChoiceRecruit ] = useState(1000);

  const [ chatStatus, setChatStatus ] = useState(1);
  const [ searchText, setSearchText ] = useState('');
  const [ chatNumber, setChatNumber ] = useState(1000);

  var link: string = new URL(window.location.href).href;
  var linkApplicant = decodeURIComponent(link.slice(link.indexOf('+search') + 8, link.length));
  
  useEffect(() => {
    if (link.indexOf('search') !== -1) {
      setSearchText(linkApplicant);
    }
  }, [linkApplicant]);
  
  const isAllIncludes = (arr: string[], target: string | any[]) => arr.some(el => target.includes(el));

  return (
    <>
    <div className='works_service'>

      <Navigation 
        choiceNav={choiceNav} setChoiceNav={setChoiceNav}
        choiceRecruit={choiceRecruit}
      />

      <div className={Styles.contact}>
        <div className={Styles.contact_nav}>
          <select 
            onChange={async (e) => {
              if (e.target.value === '')  {
                setChoiceRecruit(1000);
              } else {
                setChoiceRecruit(Number(e.target.value));
              }
            }}>
            <option value=''>全ての求人</option>
            {recruitList.map((data, index) =>
              <option value={index} key={index}>{data.title}</option>
            )}
          </select>
          <div>
            <FaSearch className={Styles.contact_nav_icon} />
            <input placeholder='応募者名 検索' value={searchText} onChange={(e) => setSearchText(e.target.value)} />
          </div>
        </div>

        <div className={Styles.contact_box}>
          <div className={Styles.contact_box_list}>
            <div className={Styles.contact_box_list_bar}>
              <p onClick={() => setChatStatus(1)} 
                style={chatStatus === 1 ? {backgroundColor : '#AD0200', color: 'white', fontWeight : 'bold'} : {}}>一覧</p>
              <p onClick={() => setChatStatus(2)} 
                style={chatStatus === 2 ? {backgroundColor : '#AD0200', color: 'white', fontWeight : 'bold'} : {}}>未読</p>
              <p onClick={() => setChatStatus(3)} 
                style={chatStatus === 3 ? {backgroundColor : '#AD0200', color: 'white', fontWeight : 'bold'} : {}}>既読</p>
            </div>

            {chatList.map((data, index) =>
            <div onClick={() => { setChatNumber(index); }} key={index}>
              
              {choiceRecruit === 1000 && (
              <>
                {isAllIncludes([searchText], data.names.indexOf(company.id) === 3 ? data.names[2] : data.names[0]) ?
                <>
                  {choiceNav === 1000 ?
                    <Chat
                      chatList={data}
                      chatStatus={chatStatus}
                    />
                  :
                  <>
                    <Chat
                      chatList={data}
                      chatStatus={chatStatus}
                    />
                  </> }
                </> : <></> }
              </> )}

              {choiceRecruit !== 1000 && recruitList[choiceRecruit]?.id === data.recruitId && (
              <>
                {isAllIncludes([searchText], data.names.indexOf(company.id) === 3 ? data.names[2] : data.names[0]) ?
                <>
                  {choiceNav === 1000 ?
                    <Chat
                      chatList={data}
                      chatStatus={chatStatus}
                    />
                  :
                  <>
                    <Chat
                      chatList={data}
                      chatStatus={chatStatus}
                    />
                  </> }
                </> : <></> }
              </>)}

            </div> )}
          </div>

          <div className={Styles.contact_box_chat}>
            {chatNumber !== 1000 ?
              <Message
                chatList={chatList[chatNumber]}
              />
            : <></> }
          </div>

          <div className={Styles.contact_box_info}>
            {chatList[chatNumber]?.reason === '応募' ? 
              <Schedule applicantId={chatList[chatNumber].applicantId}  />
            : <></> }
          </div>

        </div>
      </div>
    </div>
    </>
  );
}

export default Contact;
