import DocumentMeta from 'react-document-meta';
import React, { useState, useEffect, useLayoutEffect } from 'react';
import firebase from 'firebase/compat/app';
import Nav from '../header/index';
import Styles from './styles.module.css';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import Slider from "./strongImage/index";
import Header from "./header/index";
import Point from "./point/index";
import Person from "./person/index";
import Employment from "./employment/index";
import Number from "./number/index";
import Posts from "./posts/index";
import Interview from "./interviews/index";
import Stores from "./stores/index";
import { getFunctions, httpsCallable } from "firebase/functions";
import { initializeApp } from "firebase/app";

const Recruit: React.FC = () => {

  var link = new URL(window.location.href).href;
  const recruitLink = decodeURIComponent(link.slice(link.indexOf('id=') + 3, link.length));
  
  const [ recruitList, setRecruitList ] = useState<recruitList>({
    companyId: '',
    name: '',
    title: '',
    image: [ '', '', '', '', '', ],

    point1: '',
    point2: '',
    point3: '',

    numberTags: [],
    number1: 0,
    number2: 0,
    number3: 0,
    number4: 0,
    number5: 0,
    number6: 0,
    number7: 0,
    number8: 0,
    number9: 0,
    number10: 0,
    number11: 0,
    number12: 0,
    number13: 0,

    imageCount:  0,
    image1FilesName: [],
    image1Title: '',
    image1Desc: '',

    image2FilesName: [],
    image2Title: '',
    image2Desc: '',

    image3FilesName: [],
    image3Title: '',
    image3Desc: '',

    interviewCount:  0,
    interview1Name: '',
    interview1Title: '',
    interview1Desc: '',
    interview2Name: '',
    interview2Title: '',
    interview2Desc: '',
    interview3Name: '',
    interview3Title: '',
    interview3Desc: '',
    interview4Name: '',
    interview4Title: '',
    interview4Desc: '',
    interview5Name: '',
    interview5Title: '',
    interview5Desc: '',
    interview6Name: '',
    interview6Title: '',
    interview6Desc: '',

    person1: 0,
    person2: 0,
    person3: 0,
    person4: 0,
    person5: 0,

    employmentStatus: '',
    monthlySalaryStart: '',
    annualIncomeStart: '',
    annualIncomeEnd: '',
    workingHoursStart: '',
    workingHoursEnd: '',

    overtime: false,
    overtimeOther: '',
    welfare: [],
    welfareCheck: false,
    welfareOther: '',
    salaryWelfare: '',
    holiday: [],
    holidayCheck: false,
    holidayOther: '',
  });

  interface recruitList {
    companyId: string;
    name: string;
    title: string;
    image: string[];

    point1: string;
    point2: string;
    point3: string;

    numberTags: string[];
    number1: number;
    number2: number;
    number3: number;
    number4: number;
    number5: number;
    number6: number;
    number7: number;
    number8: number;
    number9: number;
    number10: number;
    number11: number;
    number12: number;
    number13: number;

    imageCount: number;
    image1FilesName: string[];
    image1Title: string;
    image1Desc: string;
    image2FilesName: string[];
    image2Title: string;
    image2Desc: string;
    image3FilesName: string[];
    image3Title: string;
    image3Desc: string;

    interviewCount: number;
    interview1Name: string;
    interview1Title: string;
    interview1Desc: string;
    interview2Name: string;
    interview2Title: string;
    interview2Desc: string;
    interview3Name: string;
    interview3Title: string;
    interview3Desc: string;
    interview4Name: string;
    interview4Title: string;
    interview4Desc: string;
    interview5Name: string;
    interview5Title: string;
    interview5Desc: string;
    interview6Name: string;
    interview6Title: string;
    interview6Desc: string;

    person1: number;
    person2: number;
    person3: number;
    person4: number;
    person5: number;

    employmentStatus: string;
    monthlySalaryStart: string;
    annualIncomeStart: string;
    annualIncomeEnd: string;
    workingHoursStart: string;
    workingHoursEnd: string;

    overtime: boolean;
    overtimeOther: string;
    welfare: string[];
    welfareCheck: boolean;
    welfareOther: string;
    salaryWelfare: string;
    holiday: string[];
    holidayCheck: boolean;
    holidayOther: string;
  }

  interface FetchRecruitsResponse {
    recruit: any;
  }


  const firebaseConfig = {
    apiKey: process.env.REACT_APP_APIKEY,
    authDomain: process.env.REACT_APP_AUTHDOMAIN,
    databaseURL: process.env.REACT_APP_DATABASEURL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID,
  };
  
  const app = firebase.initializeApp(firebaseConfig);

  useEffect(() => {
    const fetchRecruit = async () => {

      const functionsForRegion = getFunctions(app, "asia-northeast1");
      const getSearchRecruits = httpsCallable<unknown, FetchRecruitsResponse>(functionsForRegion, "getRecruit");

      // const getRecruit = firebase.functions().httpsCallable('getRecruit');
      try {
        const result = await getSearchRecruits({ searchUrl: recruitLink });
        setRecruitList(result.data.recruit as any);
      } catch (error) {
        console.error("Error calling Cloud Function:", error);
      }
      update();
    };
    fetchRecruit();
  }, []);

  function update() {
    return onAuthStateChanged(getAuth(), (user) => {
      if (user) {
        firebase.firestore().collection('recruits').doc(recruitLink)
          .update({ data_pv: firebase.firestore.FieldValue.arrayUnion(new Date()), });
      } else {
        firebase.firestore().collection('recruits').doc(recruitLink)
          .update({ data_pv: firebase.firestore.FieldValue.arrayUnion(new Date()), });
      }
    })
  }

  const meta = {
    title: `${recruitList.name} | 美容師の新卒・アシスタント採用 求人情報サイト memorii works`,
    description: `${recruitList.name}の新卒募集サイト！このサロンの特徴は、①${recruitList.point1} ②${recruitList.point2} ③${recruitList.point3}です！サロン見学も募集しているので、ぜひお越しくださいませ！`,
    canonical: 'https://memorii-works.com',
    meta: { charset: 'utf-8', }
  };

  return (
    <DocumentMeta {...meta}>
    <>
      <Nav />

      <div className={Styles.recruit}>

        <Header 
          companyId={recruitList.companyId} 
          name={recruitList.name} 
          title={recruitList.title} 
          image={recruitList.image} 
        />

        <Point
          name={recruitList.name}
          point1={recruitList.point1}
          point2={recruitList.point2}
          point3={recruitList.point3}
        />

        <Stores recruitId={recruitLink} />

        <Number 
          storeNumberTags={recruitList.numberTags}
          storeNumber1={recruitList.number1}
          storeNumber2={recruitList.number2}
          storeNumber3={recruitList.number3}
          storeNumber4={recruitList.number4}
          storeNumber5={recruitList.number5}
          storeNumber6={recruitList.number6}
          storeNumber7={recruitList.number7}
          storeNumber8={recruitList.number8}
          storeNumber9={recruitList.number9}
          storeNumber10={recruitList.number10}
          storeNumber11={recruitList.number11}
          storeNumber12={recruitList.number12}
          storeNumber13={recruitList.number13}
        />

        <div className={Styles.text}>
          <h3>写真で強みを知る</h3>

          {1 <= recruitList.imageCount ?
            <div className={Styles.image_appeal}>
              <Slider imageName={recruitList.image1FilesName} number={1} />
              <h4>{recruitList.image1Title}</h4>
              <p>{recruitList.image1Desc}</p>
            </div>
          : <></> }

          {2 <= recruitList.imageCount ?
            <div className={Styles.image_appeal}>
              <Slider imageName={recruitList.image2FilesName} number={2} />
              <h4>{recruitList.image2Title}</h4>
              <p>{recruitList.image2Desc}</p>
            </div>
          : <></> }

          {3 <= recruitList.imageCount ?
            <div className={Styles.image_appeal}>
              <Slider imageName={recruitList.image3FilesName} number={3} />
              <h4>{recruitList.image3Title}</h4>
              <p>{recruitList.image3Desc}</p>
            </div>
          : <></> }
        </div>

        <div className={Styles.text}>

          <Interview
            count={recruitList.interviewCount} recruit={recruitLink}
            name1={recruitList.interview1Name} title1={recruitList.interview1Title} desc1={recruitList.interview1Desc}
            name2={recruitList.interview2Name} title2={recruitList.interview2Title} desc2={recruitList.interview2Desc}
            name3={recruitList.interview3Name} title3={recruitList.interview3Title} desc3={recruitList.interview3Desc}
            name4={recruitList.interview4Name} title4={recruitList.interview4Title} desc4={recruitList.interview4Desc}
            name5={recruitList.interview5Name} title5={recruitList.interview5Title} desc5={recruitList.interview5Desc}
            name6={recruitList.interview6Name} title6={recruitList.interview6Title} desc6={recruitList.interview6Desc}
          />

        </div>

        <Person 
          bar1={recruitList.person1 === undefined ? 1 : recruitList.person1}
          bar2={recruitList.person2 === undefined ? 1 : recruitList.person2}
          bar3={recruitList.person3 === undefined ? 1 : recruitList.person3}
          bar4={recruitList.person4 === undefined ? 1 : recruitList.person4}
          bar5={recruitList.person5 === undefined ? 1 : recruitList.person5}
        />

        <Employment
          employmentStatus={recruitList.employmentStatus}
          monthlySalaryStart={recruitList.monthlySalaryStart}
          annualIncomeStart={recruitList.annualIncomeStart}
          annualIncomeEnd={recruitList.annualIncomeEnd}
          overtime={recruitList.overtime}
          overtimeOther={recruitList.overtimeOther}
          welfare={recruitList.welfare}
          welfareCheck={recruitList.welfareCheck}
          welfareOther={recruitList.welfareOther}
          salaryWelfare={recruitList.salaryWelfare}
          holiday={recruitList.holiday}
          holidayCheck={recruitList.holidayCheck}
          holidayOther={recruitList.holidayOther}
          workingHoursStart={recruitList.workingHoursStart}
          workingHoursEnd={recruitList.workingHoursEnd}
        />

        <Posts companyId={recruitList.companyId} />
      </div>
    </>
    </DocumentMeta>
  );
}

export default Recruit;
