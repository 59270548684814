import './styles.css';
import React from 'react';
import Icon from "./../../../image/logo.png";
import { IoDocumentTextOutline } from "react-icons/io5";
import { useNavigate } from 'react-router-dom';

const LandingNav: React.FC = () => {

  const navigate = useNavigate();

  return (
    <>
    <div className='works_landing'>
      <div className='works_landing_nav' onClick={() => navigate('/')}>
        <img src={Icon} alt='memorii works' />
        <h4>memorii works</h4>
      </div>
      <div className='works_landing_link'>
        <div onClick={() => navigate('/contact')}>
          <IoDocumentTextOutline className='works_landing_link_img' />
          <p>サービス資料</p>
        </div>
        <button onClick={() => navigate('/company/login')}>ログイン</button>
      </div>
    </div>
    </>
  );
}

export default LandingNav;
