import authStyles from './styles.module.css';
import React, { useState } from 'react';
import Icon from './../../image/logo.png';
import { getAuth } from 'firebase/auth';
import Header from '../header/index';
import { sendEmailVerification } from "firebase/auth";

const AuthComfirm: React.FC = () => {

  const [ confirm, setConfirm ] = useState(true);

  function send() {
    const auth = getAuth();
    const currentUser = auth.currentUser;
    if (currentUser) {
      sendEmailVerification(currentUser).then(() => { setConfirm(false); });
    }
  }
  
  return (
    <>
    <Header />

    <div className={authStyles.background}>
      <div className={authStyles.contents}>

        <div className={authStyles.contents_icon}>
          <img src={Icon} alt='memorii works' />
          <h1>memorii works</h1>
        </div>

        <h2 className={authStyles.contents_title}>メールアドレス 未認証</h2>

        <div className={authStyles.contents_message}>
          <p>
          ご登録いただいたメールアドレスに届いたURLより<br />本登録を完了してください。
          </p>
        </div>

        <button className={authStyles.contents_btn}
          onClick={() => confirm ? send() : ''} style={confirm ? {backgroundColor: '#AD0200'} : {backgroundColor: '#EBEBEB'}}>
          {confirm ? 'メールを再送信する' : 'メールを再送信しました'}
        </button>

        <p className={authStyles.contents_link} onClick={() => window.location.reload()}>
          本登録完了済み
        </p>

      </div>
    </div>
    </>
  );
}

export default AuthComfirm;

