import Styles from './styles.module.css';
import React from 'react';
import DefaultTitle from '../../../../../package/parts/title/index';
import DefaultInput1 from '../../../../../package/parts/1-input/index';

type RecruitExaminationItemProps = {
  contents: string;
  setContents: React.Dispatch<React.SetStateAction<string>>;
  count: number;
  setCount: React.Dispatch<React.SetStateAction<number>>;
  selectionText1: string | number;
  setSelectionText1: React.Dispatch<React.SetStateAction<string | number>>;
  selectionText2: string | number;
  setSelectionText2: React.Dispatch<React.SetStateAction<string | number>>;
  selectionText3: string | number;
  setSelectionText3: React.Dispatch<React.SetStateAction<string | number>>;
  selectionText4: string | number;
  setSelectionText4: React.Dispatch<React.SetStateAction<string | number>>;
  selectionText5: string | number;
  setSelectionText5: React.Dispatch<React.SetStateAction<string | number>>;
  selectionText6: string | number;
  setSelectionText6: React.Dispatch<React.SetStateAction<string | number>>;
  selectionText7: string | number;
  setSelectionText7: React.Dispatch<React.SetStateAction<string | number>>;
  selectionText8: string | number;
  setSelectionText8: React.Dispatch<React.SetStateAction<string | number>>;
};

const RecruitExaminationItem: React.FC<RecruitExaminationItemProps> = ({ 
    contents, setContents, count, setCount, 
    selectionText1, setSelectionText1, selectionText2, setSelectionText2, selectionText3, setSelectionText3, 
    selectionText4, setSelectionText4, selectionText5, setSelectionText5, selectionText6, setSelectionText6, 
    selectionText7, setSelectionText7, selectionText8, setSelectionText8,
  }) => {

  return (
    <>
    <div className={Styles.contents}>

      <h5 className={Styles.title}>1次選考</h5>
      <DefaultTitle title={'1次選考'} required={true} />
      <select className={Styles.select}
        value={contents} onChange={(e) => setContents(e.target.value)}>
        <option value=''>未選択</option>
        <option value='書類審査'>書類審査</option>
        <option value='面接'>面接</option>
        <option value='グループ面接'>グループ面接</option>
        <option value='技術チェック'>技術チェック</option>
        <option value='サロンワーク'>サロンワーク</option>
        <option value='グループディスカッション'>グループディスカッション</option>
      </select>
      <p className={Styles.desc}>　</p>

      <DefaultTitle title={'評価項目の数'} required={true} />
      <select className={Styles.select} value={count} onChange={(e) => setCount(Number(e.target.value))}>
        <option value={0}>未選択</option>
        <option value={1}>1つ</option>
        <option value={2}>2つ</option>
        <option value={3}>3つ</option>
        <option value={4}>4つ</option>
        <option value={5}>5つ</option>
        <option value={6}>6つ</option>
        <option value={7}>7つ</option>
        <option value={8}>8つ</option>
      </select>
      <p className={Styles.desc}>　</p>

      {1 <= Number(count) && Number(count) <= 8 ?
      <DefaultInput1
        title={'評価①'} required={true} length={20} place={'評価内容'} type={'text'}
        value={selectionText1} setValue={setSelectionText1}
        desc={'　'}
      /> : <></> }

      {2 <= Number(count) && Number(count) <= 8 ?
      <DefaultInput1
        title={'評価②'} required={true} length={20} place={'評価内容'} type={'text'}
        value={selectionText2} setValue={setSelectionText2}
        desc={'　'}
      /> : <></> }

      {3 <= Number(count) && Number(count) <= 8 ?
      <DefaultInput1
        title={'評価③'} required={true} length={20} place={'評価内容'} type={'text'}
        value={selectionText3} setValue={setSelectionText3}
        desc={'　'}
      /> : <></> }

      {4 <= Number(count) && Number(count) <= 8 ?
      <DefaultInput1
        title={'評価④'} required={true} length={20} place={'評価内容'} type={'text'}
        value={selectionText4} setValue={setSelectionText4}
        desc={'　'}
      /> : <></> }

      {5 <= Number(count) && Number(count) <= 8 ?
      <DefaultInput1
        title={'評価⑤'} required={true} length={20} place={'評価内容'} type={'text'}
        value={selectionText5} setValue={setSelectionText5}
        desc={'　'}
      /> : <></> }

      {6 <= Number(count) && Number(count) <= 8 ?
      <DefaultInput1
        title={'評価⑥'} required={true} length={20} place={'評価内容'} type={'text'}
        value={selectionText6} setValue={setSelectionText6}
        desc={'　'}
      /> : <></> }

      {7 <= Number(count) && Number(count) <= 8 ?
      <DefaultInput1
        title={'評価⑦'} required={true} length={20} place={'評価内容'} type={'text'}
        value={selectionText7} setValue={setSelectionText7}
        desc={'　'}
      /> : <></> }

      {8 <= Number(count) && Number(count) <= 8 ?
      <DefaultInput1
        title={'評価⑧'} required={true} length={20} place={'評価内容'} type={'text'}
        value={selectionText8} setValue={setSelectionText8}
        desc={'　'}
      /> : <></> }

    </div>
    </>
  );
}

export default RecruitExaminationItem;
