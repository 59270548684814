import './styles.css';
import React from 'react';
import Worry1 from './../../../image/landing/worry_1.png';
import Worry2 from './../../../image/landing/worry_2.png';
import Worry3 from './../../../image/landing/worry_3.png';

const LandingWorry: React.FC = () => {

  return (
    <>
    <div className='works_landing_worry'>
      <h4>こんな方におすすめのサービス</h4>
      <p>このサービスは「美容業界の採用管理に特化したサービス」であるため、応募者情報の管理の煩雑化<br />といった美容業界特有の課題に対する解決を目指すサービスです。</p>
      <div>
        <div>
          <div>
            <h4 style={{color : '#279A30'}}>01</h4>
            <p>新卒採用数が足りない...</p>
          </div>
          <p>毎年SNS広告やDM、就職フェア参加をしてるけど、採用人数が足りず人材不足に悩んでいる。</p>
          <img src={Worry1} alt='' />
        </div>
        <div>
          <div>
            <h4 style={{color : '#FCDC4A'}}>02</h4>
            <p>選考コストがかさむ...</p>
          </div>
          <p>広告費、履歴書管理、選考、連絡など、やる事が多すぎて現在の人員では手が回らない。</p>
          <img src={Worry2} alt='' />
        </div>
        <div>
          <div>
            <h4 style={{color : '#2E81B0'}}>03</h4>
            <p>採用しても辞めてしまう...</p>
          </div>
          <p>広告費をかけて採用しても価値観が合わないという理由で新卒の離職が頻繁に発生する。</p>
          <img src={Worry3} alt='' />
        </div>
      </div>
    </div>
    </>
  );
}

export default LandingWorry;
