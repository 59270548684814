import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Pages404: React.FC = () => {

  const navigate = useNavigate();

  useEffect(() => {
    // navigate('/');
  }, []);
  

  return (
    <>
    <h4 style={{fontSize: '30px', textAlign: 'center'}}>404 ページ</h4>
    </>
  );
}

export default Pages404;
