import Styles from './styles.module.css';
import firebase from 'firebase/compat/app';
import { useState } from 'react';
import { FaBookmark } from "react-icons/fa";
import { FaRegBookmark } from "react-icons/fa6";
import ExamPanel from './examination/index';
import { useNavigate } from 'react-router-dom';
// import Examination from '../../info/evaluation/index';
import { LuClipboardEdit } from "react-icons/lu";
import DefaultImg from './../../../image/default/account.png';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchManageApplicants } from '../../../features/manage/applicants';
import { fetchApplicant, fetchDetails, fetchRecruit } from '../../../features/package/detailsPanel';


type ManageItemProps = {
  applicant: ApplicantList;
  save: boolean;
  navigation: number;
};

type ApplicantList = {
  id: string;
  recruitId: string;
  name: string;
  uid: string;
  examination: number;
  sex: string;
  time: string;
  managerMemo: string;
  hopeStore: string[];
  examList1: string[];
  examList2: string[];
  examList3: string[];
  examList4: string[];
  examList5: string[];
};

const ManageItem: React.FC<ManageItemProps> = ({ applicant, save }) => {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const users = useSelector((state: any) => state.auth.users);
  const recruitListOnlyId = useSelector((state: any) => state.recruit.recruitIds);

  const [ examinationPanel, setExaminationPanel ] = useState([]);

  const [ examToggle, setExamToggle ] = useState(false);
  const [ examPanelToggle, setExamPanelToggle ] = useState(false);

  return (
    <>
    <div className={Styles.box}>

      <div className={Styles.box_item_1}
        onClick={() => { 
          dispatch(fetchDetails(true)); dispatch(fetchRecruit(applicant.recruitId)); dispatch(fetchApplicant(applicant.id)); }}>
        <img src={`https://firebasestorage.googleapis.com/v0/b/trankllc-com.appspot.com/o/profiles%2Fresize_images%2F${applicant.uid}_500x500?alt=media&token=`} alt=''
          onError={e => {
            e.currentTarget.onerror = null;
            e.currentTarget.src = DefaultImg;
          }}/>
        <div className={Styles.box_1_post}>
          <div>
            <div style={{backgroundColor: '#192651'}}></div>
            <p>{applicant.examination === 1 ? '1次選考' : applicant.examination === 2 ? '2次選考' : applicant.examination === 3 ? '3次選考' : applicant.examination === 4 ? '4次選考' : applicant.examination === 5 ? '5次選考' : applicant.examination === 6 ? '採用' : '不採用'}</p>
          </div>
          <h2>{applicant.name}</h2>
        </div>
      </div>

      <p className={Styles.box_item_2} 
        onClick={() => { 
          dispatch(fetchDetails(true)); dispatch(fetchRecruit(applicant.recruitId)); dispatch(fetchApplicant(applicant.id)); }}>
        {applicant.sex}性
      </p>

      {applicant.examination === 5 && !applicant.examList5.includes(users.uid) && applicant.examList5.length < 8 ?
        <LuClipboardEdit className={Styles.box_item_3} style={{color : '#222222'}} onClick={() => setExamToggle(true)} />
      : applicant.examination === 4 && !applicant.examList4.includes(users.uid) && applicant.examList4.length < 8 ?
        <LuClipboardEdit className={Styles.box_item_3} style={{color : '#222222'}} onClick={() => setExamToggle(true)} />
      : applicant.examination === 3 && !applicant.examList3.includes(users.uid) && applicant.examList3.length < 8 ?
        <LuClipboardEdit className={Styles.box_item_3} style={{color : '#222222'}} onClick={() => setExamToggle(true)} />
      : applicant.examination === 2 && !applicant.examList2.includes(users.uid) && applicant.examList2.length < 8 ?
        <LuClipboardEdit className={Styles.box_item_3} style={{color : '#222222'}} onClick={() => setExamToggle(true)} />
      : applicant.examination === 1 && !applicant.examList1.includes(users.uid) && applicant.examList1.length < 8 ?
        <LuClipboardEdit className={Styles.box_item_3} style={{color : '#222222'}} onClick={() => setExamToggle(true)} />
      : <div className={Styles.box_item_3}></div> }

      {save ?
        <FaBookmark className={Styles.box_item_4} style={{color : '#AD0200'}}
          onClick={() => {
            firebase.firestore().collection('applicants').doc(applicant.id)
              .update({save: firebase.firestore.FieldValue.arrayRemove(users.uid)});
            dispatch(fetchManageApplicants({ uid: users.uid, ids: recruitListOnlyId}));
          }}
        />
      : <></> }

      {!save ?
        <FaRegBookmark className={Styles.box_item_4} style={{color : '#222222'}}
          onClick={() => {
            firebase.firestore().collection('applicants').doc(applicant.id)
              .update({save: firebase.firestore.FieldValue.arrayUnion(users.uid)});
            dispatch(fetchManageApplicants({ uid: users.uid, ids: recruitListOnlyId}));
          }}
        />
      : <></> }

    </div>

    {examToggle ?
      <ExamPanel setExamToggle={setExamToggle} setExamPanelToggle={setExamPanelToggle} />
    : <></> }

    {/* {examPanelToggle ?
      <Examination applicant={applicant} setExamPanelToggle={setExamPanelToggle} />
    : <></> } */}

    </>
  );
}

export default ManageItem;
