// app/rootReducer.ts
import { combineReducers } from 'redux';
import navigationReducer from '../features/0.main/navigation';
import contactReducer from '../features/6.contact/chatList';
import messageReducer from '../features/6.contact/messages';
import analyticsReducer from '../features/1.home/analytics';
import taskReducer from '../features/1.home/tasks';
import manageApplicantsReducer from './../features/manage/applicants';
import authReducer from './../features/0.main/auth';
import companyMainReducer from './../features/0.main/company';
import recruitMainReducer from './../features/0.main/recruit';
import detailsPanelReducer from './../features/package/detailsPanel';

const rootReducer = combineReducers({
  navigation: navigationReducer,
  contacts: contactReducer,
  messages: messageReducer,
  analytics: analyticsReducer,
  tasks: taskReducer,
  manageApplicants: manageApplicantsReducer,
  auth: authReducer,
  company: companyMainReducer,
  recruit: recruitMainReducer,
  detailsPanel: detailsPanelReducer,
});

export default rootReducer;
