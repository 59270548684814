import Styles from './styles.module.css';
import React from 'react';
import Titles from '../../../package/parts/title/index';

type DefaultTitleProps = {
  name: number,
  date: string,
  setDate: React.Dispatch<React.SetStateAction<string>>;
  datetime1: string,
  setDatetime1: React.Dispatch<React.SetStateAction<string>>;
  datetime2: string,
  setDatetime2: React.Dispatch<React.SetStateAction<string>>;
};

const DefaultTitle: React.FC<DefaultTitleProps> = ({ name, date, setDate, datetime1, setDatetime1, datetime2, setDatetime2 }) => {

  return (
    <>
    <div style={{height : '10px'}}></div>
    <Titles title={`${name}次選考日程`} required={false} />
    <div className={Styles.schedule}>

      <input type='date' className={Styles.schedule_date}
        onChange={(e) => setDate(e.target.value)} value={date} />

      <select className={Styles.schedule_time}
        onChange={(e) => {setDatetime1(e.target.value)}} value={datetime1}>
        <option value={'09:00'}>9:00</option>
        <option value={'09:15'}>9:15</option>
        <option value={'09:30'}>9:30</option>
        <option value={'09:45'}>9:45</option>
        <option value={'10:00'}>10:00</option>
        <option value={'10:15'}>10:15</option>
        <option value={'10:30'}>10:30</option>
        <option value={'10:45'}>10:45</option>
        <option value={'11:00'}>11:00</option>
        <option value={'11:15'}>11:15</option>
        <option value={'11:30'}>11:30</option>
        <option value={'11:45'}>11:45</option>
        <option value={'12:00'}>12:00</option>
        <option value={'12:15'}>12:15</option>
        <option value={'12:30'}>12:30</option>
        <option value={'12:45'}>12:45</option>
        <option value={'13:00'}>13:00</option>
        <option value={'13:15'}>13:15</option>
        <option value={'13:30'}>13:30</option>
        <option value={'13:45'}>13:45</option>
        <option value={'14:00'}>14:00</option>
        <option value={'14:15'}>14:15</option>
        <option value={'14:30'}>14:30</option>
        <option value={'14:45'}>14:45</option>
        <option value={'15:00'}>15:00</option>
        <option value={'15:15'}>15:15</option>
        <option value={'15:30'}>15:30</option>
        <option value={'15:45'}>15:45</option>
        <option value={'16:00'}>16:00</option>
        <option value={'16:15'}>16:15</option>
        <option value={'16:30'}>16:30</option>
        <option value={'16:45'}>16:45</option>
        <option value={'17:00'}>17:00</option>
        <option value={'17:15'}>17:15</option>
        <option value={'17:30'}>17:30</option>
        <option value={'17:45'}>17:45</option>
        <option value={'18:00'}>18:00</option>
        <option value={'18:15'}>18:15</option>
        <option value={'18:30'}>18:30</option>
        <option value={'18:45'}>18:45</option>
        <option value={'19:00'}>19:00</option>
        <option value={'19:15'}>19:15</option>
        <option value={'19:30'}>19:30</option>
        <option value={'19:45'}>19:45</option>
        <option value={'20:00'}>20:00</option>
        <option value={'20:15'}>20:15</option>
        <option value={'20:30'}>20:30</option>
        <option value={'20:45'}>20:45</option>
        <option value={'21:00'}>21:00</option>
      </select>

      <p>~</p>

      <select className={Styles.schedule_time}
        onChange={(e) => setDatetime2(e.target.value)} value={datetime2}>
        {datetime1 === '09:00' ?
          <option value={'09:15'}>9:15</option>
        : <></> }
        {datetime1 === '09:00' || datetime1 === '09:15' ?
          <option value={'09:30'}>9:30</option>
        : <></> }
        {datetime1 === '09:00' || datetime1 === '09:15' || datetime1 === '09:30' ?
          <option value={'09:45'}>9:45</option>
        : <></> }
        {datetime1 === '09:00' || datetime1 === '09:15' || datetime1 === '09:30' || datetime1 === '09:45' ?
          <option value={'10:00'}>10:00</option>
        : <></> }
        {datetime1 === '09:00' || datetime1 === '09:15' || datetime1 === '09:30' || datetime1 === '09:45' ||
        datetime1 === '10:00' ?
          <option value={'10:15'}>10:15</option>
        :<></> }
        {datetime1 === '09:00' || datetime1 === '09:15' || datetime1 === '09:30' || datetime1 === '09:45' ||
        datetime1 === '10:00' || datetime1 === '10:15' ?
          <option value={'10:30'}>10:30</option>
        : <></> }
        {datetime1 === '09:00' || datetime1 === '09:15' || datetime1 === '09:30' || datetime1 === '09:45' ||
        datetime1 === '10:00' || datetime1 === '10:15' || datetime1 === '10:30' ?
          <option value={'10:45'}>10:45</option>
        : <></> }
        {datetime1 === '09:00' || datetime1 === '09:15' || datetime1 === '09:30' || datetime1 === '09:45' ||
        datetime1 === '10:00' || datetime1 === '10:15' || datetime1 === '10:30' || datetime1 === '10:45' ?
          <option value={'11:00'}>11:00</option>
        : <></> }
        {datetime1 === '09:00' || datetime1 === '09:15' || datetime1 === '09:30' || datetime1 === '09:45' ||
        datetime1 === '10:00' || datetime1 === '10:15' || datetime1 === '10:30' || datetime1 === '10:45' ||
        datetime1 === '11:00' ?
          <option value={'11:15'}>11:15</option>
        : <></> }
        {datetime1 === '09:00' || datetime1 === '09:15' || datetime1 === '09:30' || datetime1 === '09:45' ||
        datetime1 === '10:00' || datetime1 === '10:15' || datetime1 === '10:30' || datetime1 === '10:45' ||
        datetime1 === '11:00' || datetime1 === '11:15' ?
          <option value={'11:30'}>11:30</option>
        : <></> }
        {datetime1 === '09:00' || datetime1 === '09:15' || datetime1 === '09:30' || datetime1 === '09:45' ||
        datetime1 === '10:00' || datetime1 === '10:15' || datetime1 === '10:30' || datetime1 === '10:45' ||
        datetime1 === '11:00' || datetime1 === '11:15' || datetime1 === '11:30' ?
          <option value={'11:45'}>11:45</option>
        : <></> }
        {datetime1 === '09:15' || datetime1 === '09:30' || datetime1 === '09:45' || datetime1 === '10:00' || 
        datetime1 === '10:15' || datetime1 === '10:30' || datetime1 === '10:45' || datetime1 === '11:00' || 
        datetime1 === '11:15' || datetime1 === '11:30' || datetime1 === '11:45' || datetime1 === '12:00' ?
          <option value={'12:00'}>12:00</option>
        : <></> }
        {datetime1 === '09:30' || datetime1 === '09:45' || datetime1 === '10:00' || datetime1 === '10:15' || 
        datetime1 === '10:30' || datetime1 === '10:45' || datetime1 === '11:00' || datetime1 === '11:15' || 
        datetime1 === '11:30' || datetime1 === '11:45' || datetime1 === '12:00' || datetime1 === '12:15' ?
          <option value={'12:15'}>12:15</option>
        : <></> }
        {datetime1 === '09:45' || datetime1 === '10:00' || datetime1 === '10:15' || datetime1 === '10:30' || 
        datetime1 === '10:45' || datetime1 === '11:00' || datetime1 === '11:15' || datetime1 === '11:30' || 
        datetime1 === '11:45' || datetime1 === '12:00' || datetime1 === '12:15' || datetime1 === '12:30' ?
          <option value={'12:30'}>12:30</option>
        : <></> }
        {datetime1 === '10:00' || datetime1 === '10:15' || datetime1 === '10:30' || datetime1 === '10:45' || 
        datetime1 === '11:00' || datetime1 === '11:15' || datetime1 === '11:30' || datetime1 === '11:45' || 
        datetime1 === '12:00' || datetime1 === '12:15' || datetime1 === '12:30' || datetime1 === '12:45' ?
          <option value={'12:45'}>12:45</option>
        : <></> }
        {datetime1 === '10:15' || datetime1 === '10:30' || datetime1 === '10:45' || datetime1 === '11:00' || 
        datetime1 === '11:15' || datetime1 === '11:30' || datetime1 === '11:45' || datetime1 === '12:00' || 
        datetime1 === '12:15' || datetime1 === '12:30' || datetime1 === '12:45' || datetime1 === '13:00' ?
          <option value={'13:00'}>13:00</option>
        : <></> }
        {datetime1 === '10:30' || datetime1 === '10:45' || datetime1 === '11:00' || datetime1 === '11:15' || 
        datetime1 === '11:30' || datetime1 === '11:45' || datetime1 === '12:00' || datetime1 === '12:15' || 
        datetime1 === '12:30' || datetime1 === '12:45' || datetime1 === '13:00' || datetime1 === '13:15' ?
          <option value={'13:15'}>13:15</option>
        : <></> }
        {datetime1 === '10:45' || datetime1 === '11:00' || datetime1 === '11:15' || datetime1 === '11:30' || 
        datetime1 === '11:45' || datetime1 === '12:00' || datetime1 === '12:15' || datetime1 === '12:30' || 
        datetime1 === '12:45' || datetime1 === '12:00' || datetime1 === '12:15' || datetime1 === '13:30' ?
          <option value={'13:30'}>13:30</option>
        : <></> }
        {datetime1 === '11:00' || datetime1 === '11:15' || datetime1 === '11:30' || datetime1 === '11:45' || 
        datetime1 === '12:00' || datetime1 === '12:15' || datetime1 === '12:30' || datetime1 === '12:45' || 
        datetime1 === '13:00' || datetime1 === '13:15' || datetime1 === '13:30' || datetime1 === '13:45' ?
          <option value={'13:45'}>13:45</option>
        : <></> }
        {datetime1 === '11:15' || datetime1 === '11:30' || datetime1 === '11:45' || datetime1 === '12:00' || 
        datetime1 === '12:15' || datetime1 === '12:30' || datetime1 === '12:45' || datetime1 === '13:00' || 
        datetime1 === '13:15' || datetime1 === '13:30' || datetime1 === '13:45' || datetime1 === '14:00' ?
          <option value={'14:00'}>14:00</option>
        : <></> }
        {datetime1 === '11:30' || datetime1 === '11:45' || datetime1 === '12:00' || datetime1 === '12:15' || 
        datetime1 === '12:30' || datetime1 === '12:45' || datetime1 === '13:00' || datetime1 === '13:15' || 
        datetime1 === '13:30' || datetime1 === '13:45' || datetime1 === '14:00' || datetime1 === '14:15' ?
          <option value={'14:15'}>14:15</option>
        : <></> }
        {datetime1 === '11:45' || datetime1 === '12:00' || datetime1 === '12:15' || datetime1 === '12:30' || 
        datetime1 === '12:45' || datetime1 === '13:00' || datetime1 === '13:15' || datetime1 === '13:30' || 
        datetime1 === '13:45' || datetime1 === '14:00' || datetime1 === '14:15' || datetime1 === '14:30' ?
          <option value={'14:30'}>14:30</option>
        : <></> }
        {datetime1 === '12:00' || datetime1 === '12:15' || datetime1 === '12:30' || datetime1 === '12:45' || 
        datetime1 === '13:00' || datetime1 === '13:15' || datetime1 === '13:30' || datetime1 === '13:45' || 
        datetime1 === '14:00' || datetime1 === '14:15' || datetime1 === '14:30' || datetime1 === '14:45' ?
          <option value={'14:45'}>14:45</option>
        : <></> }
        {datetime1 === '12:15' || datetime1 === '12:30' || datetime1 === '12:45' || datetime1 === '13:00' || 
        datetime1 === '13:15' || datetime1 === '13:30' || datetime1 === '13:45' || datetime1 === '14:00' || 
        datetime1 === '14:15' || datetime1 === '14:30' || datetime1 === '14:45' || datetime1 === '15:00' ?
          <option value={'15:00'}>15:00</option>
        : <></> }
        {datetime1 === '12:30' || datetime1 === '12:45' || datetime1 === '13:00' || datetime1 === '13:15' || 
        datetime1 === '13:30' || datetime1 === '13:45' || datetime1 === '14:00' || datetime1 === '14:15' || 
        datetime1 === '14:30' || datetime1 === '14:45' || datetime1 === '15:00' || datetime1 === '15:15' ?
          <option value={'15:15'}>15:15</option>
        : <></> }
        {datetime1 === '12:45' || datetime1 === '13:00' || datetime1 === '13:15' || datetime1 === '13:30' || 
        datetime1 === '13:45' || datetime1 === '14:00' || datetime1 === '14:15' || datetime1 === '14:30' || 
        datetime1 === '14:45' || datetime1 === '15:00' || datetime1 === '15:15' || datetime1 === '15:30' ?
          <option value={'15:30'}>15:30</option>
        : <></> }
        {datetime1 === '13:00' || datetime1 === '13:15' || datetime1 === '13:30' || datetime1 === '13:45' || 
        datetime1 === '14:00' || datetime1 === '14:15' || datetime1 === '14:30' || datetime1 === '14:45' || 
        datetime1 === '15:00' || datetime1 === '15:15' || datetime1 === '15:30' || datetime1 === '15:45' ?
          <option value={'15:45'}>15:45</option>
        : <></> }
        {datetime1 === '13:15' || datetime1 === '13:30' || datetime1 === '13:45' || datetime1 === '14:00' || 
        datetime1 === '14:15' || datetime1 === '14:30' || datetime1 === '14:45' || datetime1 === '15:00' || 
        datetime1 === '15:15' || datetime1 === '15:30' || datetime1 === '15:45' || datetime1 === '16:00' ?
          <option value={'16:00'}>16:00</option>
        : <></> }
        {datetime1 === '13:30' || datetime1 === '13:45' || datetime1 === '14:00' || datetime1 === '14:15' || 
        datetime1 === '14:30' || datetime1 === '14:45' || datetime1 === '15:00' || datetime1 === '15:15' || 
        datetime1 === '15:30' || datetime1 === '15:45' || datetime1 === '16:00' || datetime1 === '16:15' ?
          <option value={'16:15'}>16:15</option>
        : <></> }
        {datetime1 === '13:45' || datetime1 === '14:00' || datetime1 === '14:15' || datetime1 === '14:30' || 
        datetime1 === '14:45' || datetime1 === '15:00' || datetime1 === '15:15' || datetime1 === '15:30' || 
        datetime1 === '15:45' || datetime1 === '16:00' || datetime1 === '16:15' || datetime1 === '16:30' ?
          <option value={'16:30'}>16:30</option>
        : <></> }
        {datetime1 === '14:00' || datetime1 === '14:15' || datetime1 === '14:30' || datetime1 === '14:45' || 
        datetime1 === '15:00' || datetime1 === '15:15' || datetime1 === '15:30' || datetime1 === '15:45' || 
        datetime1 === '16:00' || datetime1 === '16:15' || datetime1 === '16:30' || datetime1 === '16:45' ?
          <option value={'16:45'}>16:45</option>
        : <></> }
        {datetime1 === '14:15' || datetime1 === '14:30' || datetime1 === '14:45' || datetime1 === '15:00' || 
        datetime1 === '15:15' || datetime1 === '15:30' || datetime1 === '15:45' || datetime1 === '16:00' || 
        datetime1 === '16:15' || datetime1 === '16:30' || datetime1 === '16:45' || datetime1 === '17:00' ?
          <option value={'17:00'}>17:00</option>
        : <></> }
        {datetime1 === '14:30' || datetime1 === '14:45' || datetime1 === '15:00' || datetime1 === '15:15' || 
        datetime1 === '15:30' || datetime1 === '15:45' || datetime1 === '16:00' ||  datetime1 === '16:15' || 
        datetime1 === '16:30' || datetime1 === '16:45' || datetime1 === '17:00' ?  <option value={'17:15'}>17:15</option>
        : <></> }
        {datetime1 === '14:45' || datetime1 === '15:00' || datetime1 === '15:15' || datetime1 === '15:30' || 
        datetime1 === '15:45' || datetime1 === '16:00' || datetime1 === '16:15' || datetime1 === '16:30' || 
        datetime1 === '16:45' || datetime1 === '17:00' || datetime1 === '17:15' || datetime1 === '17:30' ?
          <option value={'17:30'}>17:30</option>
        : <></> }
        {datetime1 === '15:00' || datetime1 === '15:15' || datetime1 === '15:30' || datetime1 === '15:45' || 
        datetime1 === '16:00' || datetime1 === '16:15' || datetime1 === '16:30' || datetime1 === '16:45' || 
        datetime1 === '17:00' || datetime1 === '17:15' || datetime1 === '17:30' || datetime1 === '17:45' ?
          <option value={'17:45'}>17:45</option>
        : <></> }
        {datetime1 === '15:15' || datetime1 === '15:30' || datetime1 === '15:45' || datetime1 === '16:00' || 
        datetime1 === '16:15' || datetime1 === '16:30' || datetime1 === '16:45' || datetime1 === '17:00' || 
        datetime1 === '17:15' || datetime1 === '17:30' || datetime1 === '18:00' || datetime1 === '18:00' ?
          <option value={'18:00'}>18:00</option>
        : <></> }
        {datetime1 === '15:30' || datetime1 === '15:45' || datetime1 === '16:00' || datetime1 === '16:15' || 
        datetime1 === '16:30' || datetime1 === '16:45' || datetime1 === '17:00' || datetime1 === '17:15' || 
        datetime1 === '17:30' || datetime1 === '18:00' || datetime1 === '18:15' || datetime1 === '18:15' ?
          <option value={'18:15'}>18:15</option>
        : <></> }
        {datetime1 === '15:45' || datetime1 === '16:00' || datetime1 === '16:15' || datetime1 === '16:30' || 
        datetime1 === '16:45' || datetime1 === '17:00' || datetime1 === '17:15' || datetime1 === '17:30' || 
        datetime1 === '17:30' || datetime1 === '18:00' || datetime1 === '18:15' || datetime1 === '18:30' ?
          <option value={'18:30'}>18:30</option>
        : <></> }
        {datetime1 === '16:00' || datetime1 === '16:15' || datetime1 === '16:30' || datetime1 === '16:45' || 
        datetime1 === '17:00' || datetime1 === '17:15' || datetime1 === '17:30' || datetime1 === '17:45' || 
        datetime1 === '18:00' || datetime1 === '18:15' || datetime1 === '18:30' || datetime1 === '18:45' ?
          <option value={'18:45'}>18:45</option>
        : <></> }
        {datetime1 === '16:15' || datetime1 === '16:30' || datetime1 === '16:45' || datetime1 === '17:00' || 
        datetime1 === '17:15' || datetime1 === '17:30' || datetime1 === '17:45' || datetime1 === '18:00' || 
        datetime1 === '18:15' || datetime1 === '18:30' || datetime1 === '18:45' || datetime1 === '19:00' ?
          <option value={'19:00'}>19:00</option>
        : <></> }
        {datetime1 === '16:30' || datetime1 === '16:45' || datetime1 === '17:00' || datetime1 === '17:15' || 
        datetime1 === '17:30' || datetime1 === '17:45' || datetime1 === '18:00' || datetime1 === '18:15' || 
        datetime1 === '18:30' || datetime1 === '18:45' || datetime1 === '19:00' || datetime1 === '19:15' ?
          <option value={'19:15'}>19:15</option>
        : <></> }
        {datetime1 === '16:45' || datetime1 === '17:00' || datetime1 === '17:15' || datetime1 === '17:30' || 
        datetime1 === '17:45' || datetime1 === '18:00' || datetime1 === '18:15' || datetime1 === '18:30' || 
        datetime1 === '18:45' || datetime1 === '19:00' || datetime1 === '19:15' || datetime1 === '19:30' ?
          <option value={'19:30'}>19:30</option>
        : <></> }
        {datetime1 === '17:00' || datetime1 === '17:15' || datetime1 === '17:30' || datetime1 === '17:45' || 
        datetime1 === '18:00' || datetime1 === '18:15' || datetime1 === '18:30' || datetime1 === '18:45' || 
        datetime1 === '19:00' || datetime1 === '19:15' || datetime1 === '19:30' || datetime1 === '19:45' ?
          <option value={'19:45'}>19:45</option>
        : <></> }
        {datetime1 === '17:15' || datetime1 === '17:30' || datetime1 === '17:30' || datetime1 === '18:00' || 
        datetime1 === '18:15' || datetime1 === '18:30' || datetime1 === '18:45' || datetime1 === '19:00' || 
        datetime1 === '19:15' || datetime1 === '19:30' || datetime1 === '19:45' || datetime1 === '20:00' ?
          <option value={'20:00'}>20:00</option>
        : <></> }
        {datetime1 === '17:30' || datetime1 === '17:30' || datetime1 === '18:00' || datetime1 === '18:15' || 
        datetime1 === '18:30' || datetime1 === '18:45' || datetime1 === '19:00' || datetime1 === '19:15' || 
        datetime1 === '19:30' || datetime1 === '19:45' || datetime1 === '20:00' || datetime1 === '20:15' ?
          <option value={'20:15'}>20:15</option>
        : <></> }
        {datetime1 === '17:45' || datetime1 === '18:00' || datetime1 === '18:15' || datetime1 === '18:30' || 
        datetime1 === '18:45' || datetime1 === '19:00' || datetime1 === '19:15' || datetime1 === '19:30' || 
        datetime1 === '19:45' || datetime1 === '20:00' || datetime1 === '20:15' || datetime1 === '20:30' ?
          <option value={'20:30'}>20:30</option>
        : <></> }
        {datetime1 === '18:00' || datetime1 === '18:15' || datetime1 === '18:30' || datetime1 === '18:45' || 
        datetime1 === '19:00' || datetime1 === '19:15' || datetime1 === '19:30' || datetime1 === '19:45' || 
        datetime1 === '20:00' || datetime1 === '20:15' || datetime1 === '20:30' || datetime1 === '20:45' ?
          <option value={'20:45'}>20:45</option>
        : <></> }
        {datetime1 === '18:15' || datetime1 === '18:30' || datetime1 === '18:45' || datetime1 === '19:00' || 
        datetime1 === '19:15' || datetime1 === '19:30' || datetime1 === '19:45' || datetime1 === '20:00' || 
        datetime1 === '20:15' || datetime1 === '20:30' || datetime1 === '20:45' || datetime1 === '21:00' ?
          <option value={'21:00'}>21:00</option>
        : <></> }
        {datetime1 === '18:30' || datetime1 === '18:45' || datetime1 === '19:00' || datetime1 === '19:15' || 
        datetime1 === '19:30' || datetime1 === '19:45' || datetime1 === '20:00' || datetime1 === '20:15' || 
        datetime1 === '20:30' || datetime1 === '20:45' || datetime1 === '21:00' || datetime1 === '21:15' ?
          <option value={'21:15'}>21:15</option>
        : <></> }
        {datetime1 === '18:45' || datetime1 === '19:00' || datetime1 === '19:15' || datetime1 === '19:30' || 
        datetime1 === '19:45' || datetime1 === '20:00' || datetime1 === '20:15' || datetime1 === '20:30' || 
        datetime1 === '20:45' || datetime1 === '21:00' || datetime1 === '21:15' || datetime1 === '21:30' ?
          <option value={'21:30'}>21:30</option>
        : <></> }
        {datetime1 === '19:00' || datetime1 === '19:15' || datetime1 === '19:30' || datetime1 === '19:45' || 
        datetime1 === '20:00' || datetime1 === '20:15' || datetime1 === '20:30' || datetime1 === '20:45' || 
        datetime1 === '21:00' || datetime1 === '21:15' || datetime1 === '21:30' || datetime1 === '21:45' ?
          <option value={'21:45'}>21:45</option>
        : <></> }
        {datetime1 === '19:15' || datetime1 === '19:30' || datetime1 === '19:45' || datetime1 === '20:00' || 
        datetime1 === '20:15' || datetime1 === '20:30' || datetime1 === '20:45' || datetime1 === '21:00' || 
        datetime1 === '21:15' || datetime1 === '21:30' || datetime1 === '21:45' || datetime1 === '22:00' ?
          <option value={'22:00'}>22:00</option>
        : <></> }
        {datetime1 === '19:30' || datetime1 === '19:45' || datetime1 === '20:00' || datetime1 === '20:15' || 
        datetime1 === '20:30' || datetime1 === '20:45' || datetime1 === '21:00' || datetime1 === '21:15' || 
        datetime1 === '21:30' || datetime1 === '21:45' || datetime1 === '22:00' || datetime1 === '22:15' ?
          <option value={'22:15'}>22:15</option>
        : <></> }
        {datetime1 === '19:45' || datetime1 === '20:00' || datetime1 === '20:15' || datetime1 === '20:30' || 
        datetime1 === '20:45' || datetime1 === '21:00' || datetime1 === '21:15' || datetime1 === '21:30' || 
        datetime1 === '21:45' || datetime1 === '22:00' || datetime1 === '22:15' || datetime1 === '22:30' ?
          <option value={'22:30'}>22:30</option>
        : <></> }
        {datetime1 === '20:00' || datetime1 === '20:15' || datetime1 === '20:30' || datetime1 === '20:45' || 
        datetime1 === '21:00' || datetime1 === '21:15' || datetime1 === '21:30' || datetime1 === '21:45' || 
        datetime1 === '22:00' || datetime1 === '22:15' || datetime1 === '22:30' || datetime1 === '22:45' ?
          <option value={'22:45'}>22:45</option>
        : <></> }
        {datetime1 === '20:15' || datetime1 === '20:30' || datetime1 === '20:45' || datetime1 === '21:00' || 
        datetime1 === '21:15' || datetime1 === '21:30' || datetime1 === '21:45' || datetime1 === '22:00' || 
        datetime1 === '22:15' || datetime1 === '22:30' || datetime1 === '22:45' || datetime1 === '23:00' ?
          <option value={'23:00'}>23:00</option>
        : <></> }
        {datetime1 === '20:30' || datetime1 === '20:45' || datetime1 === '21:00' || datetime1 === '21:15' || 
        datetime1 === '21:30' || datetime1 === '21:45' || datetime1 === '22:00' || datetime1 === '22:15' || 
        datetime1 === '22:30' || datetime1 === '22:45' || datetime1 === '23:00' || datetime1 === '23:15' ?
          <option value={'23:15'}>23:15</option>
        : <></> }
        {datetime1 === '21:00' || datetime1 === '21:15' || datetime1 === '21:30' || datetime1 === '21:45' || 
        datetime1 === '22:00' || datetime1 === '22:15' || datetime1 === '22:30' || datetime1 === '22:45' || 
        datetime1 === '23:00' || datetime1 === '23:15' || datetime1 === '23:15' || datetime1 === '23:30' ?
          <option value={'23:30'}>23:30</option>
        : <></> }
        {datetime1 === '21:15' || datetime1 === '21:30' || datetime1 === '21:45' || datetime1 === '22:00' || 
        datetime1 === '22:15' || datetime1 === '22:30' || datetime1 === '22:45' || datetime1 === '23:00' || 
        datetime1 === '23:15' || datetime1 === '23:15' || datetime1 === '23:30' || datetime1 === '23:45' ?
          <option value={'23:45'}>23:45</option>
        : <></> }
        {datetime1 === '21:30' || datetime1 === '21:45' || datetime1 === '22:00' || datetime1 === '22:15' || 
        datetime1 === '22:30' || datetime1 === '22:45' || datetime1 === '23:00' || datetime1 === '23:15' || 
        datetime1 === '23:15' || datetime1 === '23:30' || datetime1 === '23:45' || datetime1 === '24:00' ?
          <option value={'24:00'}>24:00</option>
        : <></> }
      </select>
    </div>
    </>
  );
}

export default DefaultTitle;


