import React from 'react';
import './styles.css';

type DefaultInputProps = {
  title: String;
  required: Boolean;
};

const DefaultInput: React.FC<DefaultInputProps> = ({ title, required }) => {

  return (
    <>
    <div className='memorii_recruit_body_edit_area_content_item_title'>
      <p>{title}</p>
      <div className={
        required ? 'memorii_recruit_body_edit_area_content_item_yes' : 'memorii_recruit_body_edit_area_content_item_no'
      }>
      {required ? '※必須' : '任意'}</div>
    </div>
    </>
  );
}

export default DefaultInput;
