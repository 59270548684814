import Styles from './styles.module.css';
import React from 'react';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ImCross } from "react-icons/im";
import Header from '../0.header/index';
import firebase from "firebase/compat/app"
import ErrorMessage from '../../../../package/parts/error/index';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import BackModal from '../12.back/index';
import DefaultInput1 from '../../../../package/parts/1-input/index';
import { v4 as uuid } from 'uuid';
import DefaultImage from './../../../../image/default/wait.png';

const RecruitSummary: React.FC = () => {

  const navigate = useNavigate();

  // リンク
  const link: string = new URL(window.location.href).href;
  const recruitLink = decodeURIComponent(link.slice(link.indexOf('id=') + 3, link.length));

  var time = new Date();

  const [ errorMessage, setErrorMessage ] = useState('');
  
  // 店舗名
  const [ name, setName ] = useState<string | number>('');
  const [ title, setTitle ] = useState<string | number>('');

  // 写真
  const [ imageBefore, setImageBefore ] = useState<string[]>([]);
  const [ imageFiles, setImageFiles ] = useState<string[]>([]);
  const [ imageFilesName, setImageFilesName ] = useState<string[]>([]);
  const [ imageFilePaths, setImageFilePaths ] = useState<File[]>([]);

  // 戻るボタン
  const [ backStatus, setBackStatus ] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);

    return onAuthStateChanged(getAuth(), (user) => {
      if (user) {
        firebase.firestore().collection("recruits").doc(recruitLink).get()
        .then((doc) => {
          const data = doc.data();
          if (data) {
            setName(data.store_name);
            setTitle(data.store_title);

            setImageBefore(data.store_imageName);
            setImageFiles(data.store_imageName);
            setImageFilesName(data.store_imageName);
            setImageFilePaths(data.store_imageName);
          }
        });
      } else {
        navigate('/company/login')
      }
    })
  }, [link]);

  function check() {
    window.scrollTo(0, 0);
    if (name === '') {
      setErrorMessage('サロン名を入力してください。');
    } else if (title === '') {
      setErrorMessage('求人名を入力してください。');
    } else if (imageFiles.length === 0) {
      setErrorMessage('写真は1枚以上添付してください。');
    } else {
      save(true);
    }
  }

  async function save(e: boolean) {

    firebase.firestore().collection("recruits").doc(recruitLink)
    .update({
      status_store: e,
      // 店舗情報 > 基本
      store_name: name,
      store_title: title,

      // 店舗情報 > 情報
      store_imageName: imageFilesName,

      last_time: new Date(),
    }).then(() => {
      navigate(`/edit/id=${recruitLink}`);
    })

    for (let i = 0; i < imageFilePaths.length; i++){
      if (imageFilesName[i].length === 36) {
        firebase.storage().ref().child(`/styles/${imageFilesName[i]}`).put(imageFilePaths[i]);
      }
    }

    for (let i = 0; i < imageBefore.length; i++){
      // const isImageInPaths = imageFilePaths.some((file) => file.name === imageBefore[i]);
      // if (!isImageInPaths) {
      //   firebase.storage().ref().child(`/styles/${imageBefore[i]}`).delete();
      //   firebase.storage().ref().child(`/styles/resize_images/${imageBefore[i]}_1080x1080`).delete();
      //   firebase.storage().ref().child(`/styles/resize_images/${imageBefore[i]}_750x750`).delete();
      //   firebase.storage().ref().child(`/styles/resize_images/${imageBefore[i]}_500x500`).delete();
      //   firebase.storage().ref().child(`/styles/resize_images/${imageBefore[i]}_200x200`).delete();
      // }
    }
  }

  // 画像追加処理
  const styleImages = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || e.target.files.length === 0) {
      return;
    }

    const newFiles = Array.from(e.target.files);
    const newImageUrls = newFiles.map(file => URL.createObjectURL(file));
    const newUids = newFiles.map(() => uuid());

    setImageFiles(prev => [...prev, ...newImageUrls].slice(0, 5));
    setImageFilePaths(prev => [...prev, ...newFiles].slice(0, 5));
    setImageFilesName(prev => [...prev, ...newUids].slice(0, 5));
  };

  // 画像削除処理
  const removeImage = (index: number) => {
    setImageFiles(prev => prev.filter((_, i) => i !== index));
    setImageFilePaths(prev => prev.filter((_, i) => i !== index));
    setImageFilesName(prev => prev.filter((_, i) => i !== index));
  };
  
  return (
    <>
    <Header />

    <div className={Styles.store}>
      <div className={Styles.store_box}>

        {errorMessage !== '' ?
          <ErrorMessage message={errorMessage} />
        : <></> }

        <div className={Styles.store_contents}>
          <h5 className={Styles.store_title}>店舗概要</h5>

          <DefaultInput1
            title={'サロン名'} required={true} length={20} place={'memorii美容院、memorii美容院 横浜店'} type={'text'}
            value={name} setValue={setName}
            desc={''}
          />

          <DefaultInput1
            title={'求人タイトル'} required={true} length={30} place={'<メディア掲載実績多数>　急成長中のトレンドサロンで美容師になりませんか？'} type={'text'}
            value={title} setValue={setTitle}
            desc={''}
          />

          <div className={Styles.store_input_photo}>
            <div className={Styles.store_input_photo_title}>
              <p>店舗写真（5枚まで）</p>
              <div className={Styles.store_input_photo_yes}>※必須</div>
            </div>

            {imageFiles.length < 5 && (
              <div className={Styles.store_input_btn}>
                <label>
                  写真添付
                  <input type="file" onChange={styleImages} />
                </label>
              </div>
            )}
          </div>

          <div className={Styles.image_prev}>
            {imageFiles.length === 0 ? (
              <p className={Styles.desc}>写真が添付されていません。</p>
            ) : (
              imageFiles.map((data, index) =>
              <div>
                <img src={data.length === 36 ? `https://firebasestorage.googleapis.com/v0/b/trankllc-com.appspot.com/o/styles%2Fresize_images%2F${data}_500x500?alt=media&token=?${time.getTime()}` : data} alt=''
                  onError={e => {
                    e.currentTarget.onerror = null;
                    e.currentTarget.src = DefaultImage;
                  }}/>
                <ImCross className={Styles.image_prev_icon}
                  onClick={() => removeImage(index)}
                />
              </div>
            ))}
          </div>
        </div>

        <div className={Styles.save_box}>
          <button className={Styles.save_box_now} onClick={() => setBackStatus(true)}>戻る</button>
          <button className={Styles.save_box_now} onClick={() => save(false)}>一時保存</button>
          <button className={Styles.save_box_save} onClick={() => check()}>保存</button>
        </div>

      </div>
    </div>

    {backStatus ?
      <BackModal setBackStatus={setBackStatus} />
    : <></> }

    </>
  );
}

export default RecruitSummary;

