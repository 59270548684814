import Styles from './styles.module.css';
import React from 'react';
import firebase from 'firebase/compat/app';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

type RecruitEditProps = {
  recruitId: string;
  setToggle: React.Dispatch<React.SetStateAction<boolean>>;
};

const RecruitEdit: React.FC<RecruitEditProps> = ({ recruitId, setToggle }) => {

  const navigate = useNavigate();

  function edit() {
    firebase.firestore().collection('recruits').doc(recruitId).update({ status: 3 })
    .then(() => {  
      navigate(`/edit/id=${recruitId}`);
      setToggle(false);
    });
  }

  return (
    <>
    <div className={Styles.modal}>
      <div>
        <div className={Styles.modal_black} onClick={() => setToggle(false)}></div>
        <div className={Styles.modal_contents}>
          <h4>求人を編集しますか？</h4>
          <p>
            編集を行う場合は、再審査を行う必要があります。<br />
            ※編集された求人は再度審査を行うため、再掲載までに2～3営業日を要します。
          </p>
          <div>
            <button style={{backgroundColor : 'white', color : '#AD0200'}} onClick={() => setToggle(false)}>キャンセル</button>
            <button style={{backgroundColor : '#AD0200', color : 'white'}} onClick={() => edit()}>編集する</button>
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

export default RecruitEdit;
