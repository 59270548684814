import Styles from './styles.module.css';
import React, { useState, useEffect } from 'react';
import Chat from './1.chat/index';
import Message from './1.message/index';
import { useDispatch, useSelector } from 'react-redux';
import { fetchChats } from '../../features/6.contact/chatList';
import { fetchManageApplicants } from '../../features/manage/applicants';
import { FaSearch } from "react-icons/fa";

interface recruitList {
  id: string;
  status: number;
  title: string;
  employment: string;
  time: string;
  pv: number;
  saves: number;
  applicants: string[];
  recruitment: number;
  goal: number;
  apply: number;
  exam_selection: number,
  place_storeCount: number;
  place_store1Name: string;
  place_store2Name: string;
  place_store3Name: string;
  place_store4Name: string;
  place_store5Name: string;
}

interface ChatList {
  id: string;
  ids: string[];
  names: string[];
  reason: string;
  time: string;
  applicantId: string;
  badges: number,
  recruitId: string,
}

const ContactMobileMessage: React.FC = () => {

  const dispatch = useDispatch();

  const company = useSelector((state: any) => state.company.company);
  const chatList = useSelector((state: any) => state.contacts.list as ChatList[]);
  const recruitList = useSelector((state: any) => state.recruit.recruitList as recruitList[]);
  const recruitListOnlyId = useSelector((state: any) => state.recruit.recruitIds);

  const [ choiceNav, setChoiceNav ] = useState(1000);
  const [ choiceRecruit, setChoiceRecruit ] = useState(1000);

  const [ chatStatus, setChatStatus ] = useState(1);
  const [ searchText, setSearchText ] = useState('');
  const [ chatNumber, setChatNumber ] = useState(1000);

  const [ chatScreen, setChatScreen ] = useState(false);

  const isAllIncludes = (arr: string[], target: string | any[]) => arr.some(el => target.includes(el));

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(fetchChats(company.id));
      await dispatch(fetchManageApplicants(recruitListOnlyId));
    }
    fetchData();
  }, [dispatch, recruitListOnlyId, company.id]);
  
  return (
    <>
    <div className={Styles.works_service_mobile}>
      <div className={Styles.box}>

        {chatNumber === 1000 ?
          <div className={Styles.box_header}>
            <div className={Styles.box_header_search}>
              <select className={Styles.box_select_recruit}
                onChange={async (e) => {
                  if (e.target.value === '')  {
                    setChoiceRecruit(1000);
                  } else {
                    setChoiceRecruit(Number(e.target.value));
                  }
                }}>
                <option value=''>全ての求人</option>
                {recruitList.map((data, index) =>
                  <option value={index}>{data.title}</option>
                )}
              </select>
              <div>
                <FaSearch className={Styles.box_header_search_icon} />
                <input placeholder='応募者名 検索' value={searchText} onChange={(e) => setSearchText(e.target.value)} />
              </div>
            </div>

            <div className={Styles.table_header}>
              <p className={Styles.table_header_1}>応募者名</p>
              <p className={Styles.table_header_4}>状況</p>
              <p className={Styles.table_header_4}>連絡</p>
            </div>
          </div>
        : <></> }

        {chatNumber === 1000 ?
          <div className={Styles.contents}>
            {chatList.map((data, index) =>
            <div onClick={() => { setChatNumber(index); }} key={index}>
              
              {choiceRecruit === 1000 && (
              <>
                {isAllIncludes([searchText], data.names.indexOf(company.id) === 3 ? data.names[2] : data.names[0]) ?
                <>
                  {choiceNav === 1000 ?
                    <Chat
                      chatList={data}
                      chatStatus={chatStatus}
                    />
                  :
                  <>
                    <Chat
                      chatList={data}
                      chatStatus={chatStatus}
                    />
                  </> }
                </> : <></> }
              </> )}

              {choiceRecruit !== 1000 && recruitList[choiceRecruit]?.id === data.recruitId && (
              <>
                {isAllIncludes([searchText], data.names.indexOf(company.id) === 3 ? data.names[2] : data.names[0]) ?
                <>
                  {choiceNav === 1000 ?
                    <Chat
                      chatList={data}
                      chatStatus={chatStatus}
                    />
                  :
                  <>
                    <Chat
                      chatList={data}
                      chatStatus={chatStatus}
                    />
                  </> }
                </> : <></> }
              </>)}

            </div> )}
          </div>
        : <></> }

        {chatNumber !== 1000 ?
          <div className={Styles.contact_box_chat}>
            <Message
              chatList={chatList[chatNumber]}
            />
          </div>
        : <></> }
        
      </div>
    </div>
    </>
  );
}

export default ContactMobileMessage;
