import Styles from './styles.module.css';
import React, { useState, useEffect } from 'react';
import firebase from 'firebase/compat/app';
import DetailsTimes from './../../info/time/index';
import { Timestamp } from 'firebase-admin/firestore';

type ContactScheduleProps = {
  applicantId: string;
};

const ContactSchedule: React.FC<ContactScheduleProps> = ({ applicantId }) => {

  const [ applicantsList, setApplicantsList ] = useState<number>(0);

  const [ banner, setBanner ] = useState(false);

  // 一時面談
  const [ date1, setDate1 ] = useState('2024-01-01');
  const [ date1time1, setDate1time1 ] = useState('01:00');
  const [ date1time2, setDate1time2 ] = useState('01:00');
  
  // 二時面談
  const [ date2, setDate2 ] = useState('2024-01-01');
  const [ date2time1, setDate2time1 ] = useState('00:00');
  const [ date2time2, setDate2time2 ] = useState('00:00');
  
  // 三時面談
  const [ date3, setDate3 ] = useState('2024-01-01');
  const [ date3time1, setDate3time1 ] = useState('00:00');
  const [ date3time2, setDate3time2 ] = useState('00:00');
  
  // 四時面談
  const [ date4, setDate4 ] = useState('2024-01-01');
  const [ date4time1, setDate4time1 ] = useState('00:00');
  const [ date4time2, setDate4time2 ] = useState('00:00');
  
  // 五時面談
  const [ date5, setDate5 ] = useState('2024-01-01');
  const [ date5time1, setDate5time1 ] = useState('00:00');
  const [ date5time2, setDate5time2 ] = useState('00:00');

  useEffect(() => {
    if (applicantId !== '') {
      firebase.firestore().collection('applicants').doc(applicantId).get()
      .then((doc) => {
        const data = doc.data();
        if (data) {
          calc({
            examination1_schedule: data.examination1_schedule,
            examination2_schedule: data.examination2_schedule,
            examination3_schedule: data.examination3_schedule,
            examination4_schedule: data.examination4_schedule,
            examination5_schedule: data.examination5_schedule,
          });

          setApplicantsList(Number(data.examination));
        }
      });
    }
  }, [applicantId]);

  interface applicants {
    examination1_schedule: Timestamp[];
    examination2_schedule: Timestamp[];
    examination3_schedule: Timestamp[];
    examination4_schedule: Timestamp[];
    examination5_schedule: Timestamp[];
  }

  function calc(applicants: applicants) {
    // 1次面接
    var date1_1 = new Date(applicants.examination1_schedule[0].seconds * 1000);
    var date1_2 = new Date(applicants.examination1_schedule[1].seconds * 1000);

    var year1_1 = `${date1_1.getFullYear()}`;
    var month1_1 = 0 <= date1_1.getMonth() && date1_1.getMonth() <= 8 ? '0' + `${date1_1.getMonth() + 1}` : `${date1_1.getMonth() + 1}`;
    var day1_1 = 0 <= date1_1.getDate() && date1_1.getDate() <= 9 ? '0' + `${date1_1.getDate()}` : `${date1_1.getDate()}`;

    var hour1_1 = 0 <= date1_1.getHours() && date1_1.getHours() <= 9 ? '0' + `${date1_1.getHours()}` : `${date1_1.getHours()}`;
    var minutes1_1 = 0 <= date1_1.getMinutes() && date1_1.getMinutes() <= 9 ? '0' + `${date1_1.getMinutes()}` : `${date1_1.getMinutes()}`;

    var hour1_2 = 0 <= date1_2.getHours() && date1_2.getHours() <= 9 ? '0' + `${date1_2.getHours()}` : `${date1_2.getHours()}`;
    var minutes1_2 = 0 <= date1_2.getMinutes() && date1_2.getMinutes() <= 9 ? '0' + `${date1_2.getMinutes()}` : `${date1_2.getMinutes()}`;

    setDate1(year1_1 + '-' + month1_1 + '-' + day1_1);
    setDate1time1(hour1_1 + ':' + minutes1_1);
    setDate1time2(hour1_2 + ':' + minutes1_2);

    // ニ次面接
    var date2_1 = new Date(applicants.examination2_schedule[0].seconds * 1000);
    var date2_2 = new Date(applicants.examination2_schedule[1].seconds * 1000);

    var year2_1 = `${date2_1.getFullYear()}`;
    var month2_1 = 0 <= date2_1.getMonth() && date2_1.getMonth() <= 8 ? '0' + `${date2_1.getMonth() + 1}` : `${date2_1.getMonth() + 1}`;
    var day2_1 = 0 <= date2_1.getDate() && date2_1.getDate() <= 9 ? '0' + `${date2_1.getDate()}` : `${date2_1.getDate()}`;

    var hour2_1 = 0 <= date2_1.getHours() && date2_1.getHours() <= 9 ? '0' + `${date2_1.getHours()}` : `${date2_1.getHours()}`;
    var minutes2_1 = 0 <= date2_1.getMinutes() && date2_1.getMinutes() <= 9 ? '0' + `${date2_1.getMinutes()}` : `${date2_1.getMinutes()}`;

    var hour2_2 = 0 <= date2_2.getHours() && date2_2.getHours() <= 9 ? '0' + `${date2_2.getHours()}` : `${date2_2.getHours()}`;
    var minutes2_2 = 0 <= date2_2.getMinutes() && date2_2.getMinutes() <= 9 ? '0' + `${date2_2.getMinutes()}` : `${date2_2.getMinutes()}`;

    setDate2(year2_1 + '-' + month2_1 + '-' + day2_1);
    setDate2time1(hour2_1 + ':' + minutes2_1);
    setDate2time2(hour2_2 + ':' + minutes2_2);

    // 3次面接
    var date3_1 = new Date(applicants.examination3_schedule[0].seconds * 1000);
    var date3_2 = new Date(applicants.examination3_schedule[1].seconds * 1000);

    var year3_1 = `${date3_1.getFullYear()}`;
    var month3_1 = 0 <= date3_1.getMonth() && date3_1.getMonth() <= 8 ? '0' + `${date3_1.getMonth() + 1}` : `${date3_1.getMonth() + 1}`;
    var day3_1 = 0 <= date3_1.getDate() && date3_1.getDate() <= 9 ? '0' + `${date3_1.getDate()}` : `${date3_1.getDate()}`;

    var hour3_1 = 0 <= date3_1.getHours() && date3_1.getHours() <= 9 ? '0' + `${date3_1.getHours()}` : `${date3_1.getHours()}`;
    var minutes3_1 = 0 <= date3_1.getMinutes() && date3_1.getMinutes() <= 9 ? '0' + `${date3_1.getMinutes()}` : `${date3_1.getMinutes()}`;

    var hour3_2 = 0 <= date3_2.getHours() && date3_2.getHours() <= 9 ? '0' + `${date3_2.getHours()}` : `${date3_2.getHours()}`;
    var minutes3_2 = 0 <= date3_2.getMinutes() && date3_2.getMinutes() <= 9 ? '0' + `${date3_2.getMinutes()}` : `${date3_2.getMinutes()}`;

    setDate3(year3_1 + '-' + month3_1 + '-' + day3_1);
    setDate3time1(hour3_1 + ':' + minutes3_1);
    setDate3time2(hour3_2 + ':' + minutes3_2);

    // 4次面接
    var date4_1 = new Date(applicants.examination4_schedule[0].seconds * 1000);
    var date4_2 = new Date(applicants.examination4_schedule[1].seconds * 1000);

    var year4_1 = `${date4_1.getFullYear()}`;
    var month4_1 = 0 <= date4_1.getMonth() && date4_1.getMonth() <= 8 ? '0' + `${date4_1.getMonth() + 1}` : `${date4_1.getMonth() + 1}`;
    var day4_1 = 0 <= date4_1.getDate() && date4_1.getDate() <= 9 ? '0' + `${date4_1.getDate()}` : `${date4_1.getDate()}`;

    var hour4_1 = 0 <= date4_1.getHours() && date4_1.getHours() <= 9 ? '0' + `${date4_1.getHours()}` : `${date4_1.getHours()}`;
    var minutes4_1 = 0 <= date4_1.getMinutes() && date4_1.getMinutes() <= 9 ? '0' + `${date4_1.getMinutes()}` : `${date4_1.getMinutes()}`;

    var hour4_2 = 0 <= date4_2.getHours() && date4_2.getHours() <= 9 ? '0' + `${date4_2.getHours()}` : `${date4_2.getHours()}`;
    var minutes4_2 = 0 <= date4_2.getMinutes() && date4_2.getMinutes() <= 9 ? '0' + `${date4_2.getMinutes()}` : `${date4_2.getMinutes()}`;

    setDate4(year4_1 + '-' + month4_1 + '-' + day4_1);
    setDate4time1(hour4_1 + ':' + minutes4_1);
    setDate4time2(hour4_2 + ':' + minutes4_2);

    // 5次面接
    var date5_1 = new Date(applicants.examination5_schedule[0].seconds * 1000);
    var date5_2 = new Date(applicants.examination5_schedule[1].seconds * 1000);

    var year5_1 = `${date5_1.getFullYear()}`;
    var month5_1 = 0 <= date5_1.getMonth() && date5_1.getMonth() <= 8 ? '0' + `${date5_1.getMonth() + 1}` : `${date5_1.getMonth() + 1}`;
    var day5_1 = 0 <= date5_1.getDate() && date5_1.getDate() <= 9 ? '0' + `${date5_1.getDate()}` : `${date5_1.getDate()}`;

    var hour5_1 = 0 <= date5_1.getHours() && date5_1.getHours() <= 9 ? '0' + `${date5_1.getHours()}` : `${date5_1.getHours()}`;
    var minutes5_1 = 0 <= date5_1.getMinutes() && date5_1.getMinutes() <= 9 ? '0' + `${date5_1.getMinutes()}` : `${date5_1.getMinutes()}`;

    var hour5_2 = 0 <= date5_2.getHours() && date5_2.getHours() <= 9 ? '0' + `${date5_2.getHours()}` : `${date5_2.getHours()}`;
    var minutes5_2 = 0 <= date5_2.getMinutes() && date5_2.getMinutes() <= 9 ? '0' + `${date5_2.getMinutes()}` : `${date5_2.getMinutes()}`;

    setDate5(year5_1 + '-' + month5_1 + '-' + day5_1);
    setDate5time1(hour5_1 + ':' + minutes5_1);
    setDate5time2(hour5_2 + ':' + minutes5_2);
  }

  function save() {
    setBanner(true);

    firebase.firestore().collection('applicants').doc(applicantId)
    .update({
      examination1_schedule: [ new Date(Date.parse(`${date1} ${date1time1}:00`)), new Date(Date.parse(`${date1} ${date1time2}:00`)), ],
      examination2_schedule: [ new Date(Date.parse(`${date2} ${date2time1}:00`)), new Date(Date.parse(`${date2} ${date2time2}:00`)), ],
      examination3_schedule: [ new Date(Date.parse(`${date3} ${date3time1}:00`)), new Date(Date.parse(`${date3} ${date3time2}:00`)), ],
      examination4_schedule: [ new Date(Date.parse(`${date4} ${date4time1}:00`)), new Date(Date.parse(`${date4} ${date4time2}:00`)), ],
      examination5_schedule: [ new Date(Date.parse(`${date5} ${date5time1}:00`)), new Date(Date.parse(`${date5} ${date5time2}:00`)), ],
    });

    setTimeout(() => {
      setBanner(false);
    }, 1500);
  }

  return (
    <>
    <div className={Styles.box}>

      {1 <= applicantsList && applicantsList <= 5 ?
        <DetailsTimes name={1} setDate={setDate1} date={date1} 
          setDatetime1={setDate1time1} datetime1={date1time1} setDatetime2={setDate1time2} datetime2={date1time2} />
      : <></> }

      {2 <= applicantsList && applicantsList <= 5 ?
        <DetailsTimes name={2} setDate={setDate2} date={date2} 
          setDatetime1={setDate2time1} datetime1={date2time1} setDatetime2={setDate2time2} datetime2={date2time2} />
      : <></> }

      {3 <= applicantsList && applicantsList <= 5 ?
        <DetailsTimes name={3} setDate={setDate3} date={date3} 
          setDatetime1={setDate3time1} datetime1={date3time1} setDatetime2={setDate3time2} datetime2={date3time2} />
      : <></> }

      {4 <= applicantsList && applicantsList <= 5 ?
        <DetailsTimes name={4} setDate={setDate4} date={date4} 
          setDatetime1={setDate4time1} datetime1={date4time1} setDatetime2={setDate4time2} datetime2={date4time2} />
      : <></> }

      {5 === applicantsList ?
        <DetailsTimes name={5} setDate={setDate5} date={date5} 
          setDatetime1={setDate5time1} datetime1={date5time1} setDatetime2={setDate5time2} datetime2={date5time2} />
      : <></> }
    </div>
      
    <button className={Styles.save} onClick={() => save()}>保存</button>
    
    {banner ?
      <div className={Styles.banner}>
        保存しました。
      </div>
    : <></> }
    </>
  );
}

export default ContactSchedule;
