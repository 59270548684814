import React, { useState, useEffect } from 'react';
import DefaultImg from './../../../../image/default/recruit.png'
import Styles from './styles.module.css';

type RecruitHeaderProps = {
  companyId: string;
  name: string;
  title: string;
  image: string[];
};

const RecruitHeader: React.FC<RecruitHeaderProps> = ({ companyId, name, title, image }) => {

  const [ storeImages, setStoreImages ] = useState<string[]>(image);

  useEffect(() => {
    setStoreImages(image);
  }, [image]);

  return (
    <>
    <div className={Styles.header_title}>
      <img src={`https://firebasestorage.googleapis.com/v0/b/trankllc-com.appspot.com/o/companies_logo%2Fresize_images%2F${companyId}_200x200?alt=media&token=?${new Date().getTime()}`} alt=''
        onError={e => {
          e.currentTarget.onerror = null;
          e.currentTarget.src = DefaultImg;
        }}/>
      <h2>{name}</h2>
    </div>
    
    <div className={Styles.header}>

      <h1>{title}</h1>

      <div className={Styles.header_image}>

        {storeImages.length >= 1 ?
          <img alt='' 
            src={`https://firebasestorage.googleapis.com/v0/b/trankllc-com.appspot.com/o/styles%2Fresize_images%2F${storeImages[0]}_1080x1080?alt=media&token=?${new Date().getTime()}`}
            onError={e => {
              e.currentTarget.onerror = null;
              e.currentTarget.src = DefaultImg;
            }} />
        : <img src={DefaultImg} alt='' /> }

        <div>

          {storeImages.length >= 2 ?
            <img alt='' onClick={() => setStoreImages([ storeImages[1], storeImages[0], storeImages[2], storeImages[3], storeImages[4], ])}
              src={`https://firebasestorage.googleapis.com/v0/b/trankllc-com.appspot.com/o/styles%2Fresize_images%2F${storeImages[1]}_500x500?alt=media&token=?${new Date().getTime()}`}
              onError={e => {
                e.currentTarget.onerror = null;
                e.currentTarget.src = DefaultImg;
              }} />
          : <img src={DefaultImg} alt='' /> }

          {storeImages.length >= 3 ?
            <img alt='' onClick={() => setStoreImages([ storeImages[2], storeImages[1], storeImages[0], storeImages[3], storeImages[4], ])}
              src={`https://firebasestorage.googleapis.com/v0/b/trankllc-com.appspot.com/o/styles%2Fresize_images%2F${storeImages[2]}_500x500?alt=media&token=?${new Date().getTime()}`}
              onError={e => {
                e.currentTarget.onerror = null;
                e.currentTarget.src = DefaultImg;
              }} />
          : <img src={DefaultImg} alt='' /> }

          {storeImages.length >= 4 ?
            <img alt='' onClick={() => setStoreImages([ storeImages[3], storeImages[1], storeImages[2], storeImages[0], storeImages[4], ])}
              src={`https://firebasestorage.googleapis.com/v0/b/trankllc-com.appspot.com/o/styles%2Fresize_images%2F${storeImages[3]}_500x500?alt=media&token=?${new Date().getTime()}`}
              onError={e => {
                e.currentTarget.onerror = null;
                e.currentTarget.src = DefaultImg;
              }} />
          : <img src={DefaultImg} alt='' /> }

          {storeImages.length >= 5 ?
            <img alt='' onClick={() => setStoreImages([ storeImages[4], storeImages[1], storeImages[2], storeImages[3], storeImages[0], ])}
              src={`https://firebasestorage.googleapis.com/v0/b/trankllc-com.appspot.com/o/styles%2Fresize_images%2F${storeImages[4]}_500x500?alt=media&token=?${new Date().getTime()}`}
              onError={e => {
                e.currentTarget.onerror = null;
                e.currentTarget.src = DefaultImg;
              }} />
          : <img src={DefaultImg} alt='' /> }

        </div>

      </div>

      {/* <div className={Styles.hashTags}>{props.recruitList.store_hashTagList.map((data, index) => <p>#{data} </p> )}</div> */}

    </div>
    </>
  );
}

export default RecruitHeader;

