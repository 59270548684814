import React from 'react';
import Styles from './styles.module.css';

type RecruitNumberProps = {
  storeNumberTags: string[];
  storeNumber1: number;
  storeNumber2: number;
  storeNumber3: number;
  storeNumber4: number;
  storeNumber5: number;
  storeNumber6: number;
  storeNumber7: number;
  storeNumber8: number;
  storeNumber9: number;
  storeNumber10: number;
  storeNumber11: number;
  storeNumber12: number;
  storeNumber13: number;
};

const RecruitNumber: React.FC<RecruitNumberProps> = ({ storeNumberTags, storeNumber1, storeNumber2, storeNumber3, storeNumber4, storeNumber5, storeNumber6, storeNumber7, storeNumber8, storeNumber9, storeNumber10, storeNumber11, storeNumber12, storeNumber13 }) => {

  return (
    <>
    <div className={Styles.text}>
      <h3>数字で見る</h3>

      <div className={Styles.score}>
        <div>
          <h4>

            {storeNumberTags[0] === '創業から' ? `${storeNumber1}年`
              : storeNumberTags[0] === '平均勤続年数' ? `${storeNumber2}年`
              : storeNumberTags[0] === '店舗数' ? `${storeNumber3}店舗`
              : storeNumberTags[0] === '男女比' ? `${storeNumber4}`
              : storeNumberTags[0] === '採用人数' ? `${storeNumber5}人`
              : storeNumberTags[0] === '最短デビュー' ? `${storeNumber6}ヶ月`
              : storeNumberTags[0] === '平均顧客単価' ? `${storeNumber7}円`
              : storeNumberTags[0] === 'アシスタント給与' ? `${storeNumber8}万円`
              : storeNumberTags[0] === '引越し金' ? `${storeNumber9}万円`
              : storeNumberTags[0] === '平均年齢' ? `${storeNumber10}歳`
              : storeNumberTags[0] === 'リピート率' ? `${storeNumber11}％`
              : storeNumberTags[0] === 'コンテスト受賞数' ? `${storeNumber12}回`
              : storeNumberTags[0] === '地方出身者割合' ? `${storeNumber13}％` : '-'
            }
          </h4>
          <h3>{storeNumberTags[0]}</h3>
        </div>
        <div>
          <h4>
            {storeNumberTags[1] === '創業から' ? `${storeNumber1}年`
              : storeNumberTags[1] === '平均勤続年数' ? `${storeNumber2}年`
              : storeNumberTags[1] === '店舗数' ? `${storeNumber3}店`
              : storeNumberTags[1] === '男女比' ? `${storeNumber4}`
              : storeNumberTags[1] === '採用人数' ? `${storeNumber5}人`
              : storeNumberTags[1] === '最短デビュー' ? `${storeNumber6}ヶ月`
              : storeNumberTags[1] === '平均顧客単価' ? `${storeNumber7}円`
              : storeNumberTags[1] === 'アシスタント給与' ? `${storeNumber8}万円`
              : storeNumberTags[1] === '引越し金' ? `${storeNumber9}万円`
              : storeNumberTags[1] === '平均年齢' ? `${storeNumber10}歳`
              : storeNumberTags[1] === 'リピート率' ? `${storeNumber11}％`
              : storeNumberTags[1] === 'コンテスト受賞数' ? `${storeNumber12}回`
              : storeNumberTags[1] === '地方出身者割合' ? `${storeNumber13}％` : '-'
            }
          </h4>
          <h3>{storeNumberTags[1]}</h3>
        </div>
        <div>
          <h4>
            {storeNumberTags[2] === '創業から' ? `${storeNumber1}年`
              : storeNumberTags[2] === '平均勤続年数' ? `${storeNumber2}年`
              : storeNumberTags[2] === '店舗数' ? `${storeNumber3}店`
              : storeNumberTags[2] === '男女比' ? `${storeNumber4}`
              : storeNumberTags[2] === '採用人数' ? `${storeNumber5}人`
              : storeNumberTags[2] === '最短デビュー' ? `${storeNumber6}ヶ月`
              : storeNumberTags[2] === '平均顧客単価' ? `${storeNumber7}円`
              : storeNumberTags[2] === 'アシスタント給与' ? `${storeNumber8}万円`
              : storeNumberTags[2] === '引越し金' ? `${storeNumber9}万円`
              : storeNumberTags[2] === '平均年齢' ? `${storeNumber10}歳`
              : storeNumberTags[2] === 'リピート率' ? `${storeNumber11}％`
              : storeNumberTags[2] === 'コンテスト受賞数' ? `${storeNumber12}回`
              : storeNumberTags[2] === '地方出身者割合' ? `${storeNumber13}％` : '-'
            }
          </h4>
          <h3>{storeNumberTags[2]}</h3>
        </div>
        <div>
          <h4>
            {storeNumberTags[3] === '創業から' ? `${storeNumber1}年`
              : storeNumberTags[3] === '平均勤続年数' ? `${storeNumber2}年`
              : storeNumberTags[3] === '店舗数' ? `${storeNumber3}店`
              : storeNumberTags[3] === '男女比' ? `${storeNumber4}`
              : storeNumberTags[3] === '採用人数' ? `${storeNumber5}人`
              : storeNumberTags[3] === '最短デビュー' ? `${storeNumber6}ヶ月`
              : storeNumberTags[3] === '平均顧客単価' ? `${storeNumber7}円`
              : storeNumberTags[3] === 'アシスタント給与' ? `${storeNumber8}万円`
              : storeNumberTags[3] === '引越し金' ? `${storeNumber9}万円`
              : storeNumberTags[3] === '平均年齢' ? `${storeNumber10}歳`
              : storeNumberTags[3] === 'リピート率' ? `${storeNumber11}％`
              : storeNumberTags[3] === 'コンテスト受賞数' ? `${storeNumber12}回`
              : storeNumberTags[3] === '地方出身者割合' ? `${storeNumber13}％` : '-'
            }
          </h4>
          <h3>{storeNumberTags[3]}</h3>
        </div>
      </div>

    </div>
    </>
  );
}

export default RecruitNumber;
