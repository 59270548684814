import React from 'react';
import Styles from './styles.module.css';
import { FaRegHandPointRight } from "react-icons/fa6";

type RecruitPointProps = {
  name: string;
  point1: string;
  point2: string;
  point3: string;
};

const RecruitPoint: React.FC<RecruitPointProps> = ({ name, point1, point2, point3 }) => {

  return (
    <>
    <div className={Styles.point}>
      <div>
        <FaRegHandPointRight className={Styles.point_icon} />
        <h3>{name} の見どころはこの3つ！</h3>
      </div>
      <hr></hr>
      <ul>
        <li><span>{point1}</span></li>
        <li><span>{point2}</span></li>
        <li><span>{point3}</span></li>
      </ul>
    </div>
    </>
  );
}

export default RecruitPoint;

