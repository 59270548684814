import './styles.css';
import Icon from "./../../../image/logo.png";
import { useNavigate } from 'react-router-dom';
import React from 'react';

const TabNav: React.FC = () => {

  const navigate = useNavigate();

  return (
    <>
    <div className="nav">
      <div className="nav_icon" onClick={() => navigate('/')}>
        <img src={Icon} alt='' />
        <p>memorii</p>
      </div>
      <div className="nav_text">
        <p onClick={() => navigate('/')}>美容学生</p>
        <p onClick={() => navigate('/company')}>事業者様</p>
        <p onClick={() => navigate('/contact')}>問い合わせ</p>
      </div>
    </div>
    </>
  );
}

export default TabNav;