import Styles from './styles.module.css';
import React from 'react';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../0.header/index';
import firebase from "firebase/compat/app";
import ErrorMessage from '../../../../package/parts/error/index';
import DefaultInput1 from '../../../../package/parts/1-input/index';
import BackModal from '../12.back/index';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

const RecruitPoint: React.FC = () => {

  const navigate = useNavigate();

  // リンク
  const link: string = new URL(window.location.href).href;
  const recruitLink = decodeURIComponent(link.slice(link.indexOf('id=') + 3, link.length));

  const [ errorMessage, setErrorMessage ] = useState('');
  
  // 哲学
  const [ point1Title, setPoint1Title ] = useState<string | number>('');
  const [ point2Title, setPoint2Title ] = useState<string | number>('');
  const [ point3Title, setPoint3Title ] = useState<string | number>('');

  // 戻るボタン
  const [ backStatus, setBackStatus ] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);

    return onAuthStateChanged(getAuth(), (user) => {
      if (user) {
        firebase.firestore().collection("recruits").doc(recruitLink).get()
        .then((doc) => {
          const data = doc.data();
          if (data) {
            setPoint1Title(data.recruit_point1Title);
            setPoint2Title(data.recruit_point2Title);
            setPoint3Title(data.recruit_point3Title);
          }
        });
      } else {
        navigate('/company/login')
      }
    })
  }, [link]);

  function check() {
    window.scrollTo(0, 0);
    if (point1Title === '') {
      setErrorMessage('No.1 魅力のタイトルを入力してください。');
    } else if (point2Title === '') {
      setErrorMessage('No.2 魅力のタイトルを入力してください。');
    } else if (point3Title === '') {
      setErrorMessage('No.3 魅力のタイトルを入力してください。');
    } else {
      save(true);
    }
  }

  async function save(e: boolean) {

    firebase.firestore().collection("recruits").doc(recruitLink)
    .update({
      status_point: e,

      recruit_point1Title: point1Title,
      recruit_point2Title: point2Title,
      recruit_point3Title: point3Title,

      last_time: new Date(),
    }).then(() => {
      navigate(`/edit/id=${recruitLink}`);
    })
  }
  
  return (
    <>
    <Header />

    <div className={Styles.store}>
      <div className={Styles.store_box}>

        {errorMessage !== '' ?
          <ErrorMessage message={errorMessage} />
        : <></> }

        <div className={Styles.store_contents}>

          <h5 className={Styles.store_title}>会社の魅力3選</h5>

          <DefaultInput1
            title={'No.1 魅力'} required={true} length={40} place={''} type={'text'}
            value={point1Title} setValue={setPoint1Title}
            desc={''}
          />

          <DefaultInput1
            title={'No.2 魅力'} required={true} length={40} place={''} type={'text'}
            value={point2Title} setValue={setPoint2Title}
            desc={''}
          />

          <DefaultInput1
            title={'No.3 魅力'} required={true} length={40} place={''} type={'text'}
            value={point3Title} setValue={setPoint3Title}
            desc={''}
          />
          
        </div>

        <div className={Styles.save_box}>
          <button className={Styles.save_box_now} onClick={() => setBackStatus(true)}>戻る</button>
          <button className={Styles.save_box_now} onClick={() => save(false)}>一時保存</button>
          <button className={Styles.save_box_save} onClick={() => check()}>保存</button>
        </div>

      </div>
    </div>

    {backStatus ?
      <BackModal setBackStatus={setBackStatus} />
    : <></> }

    </>
  );
}

export default RecruitPoint;
