import React from 'react';
import Styles from './styles.module.css';

type DefaultSliderProps = {
  range1: string,
  range2: string,
  value: number,
  setValue: React.Dispatch<React.SetStateAction<number>>;
};

const DefaultSlider: React.FC<DefaultSliderProps> = ({ range1, range2, value, setValue }) => {

  return (
    <>
    <div className={Styles.title}>
      <p>{range1}</p>
      <p>{range2}</p>
    </div>
    <div className={Styles.bar}>
      <input type="range" className="form-range" id="customRange1" min={1} max={5}
        value={value} onChange={(e) => setValue(Number(e.target.value))} />
    </div>
    </>
  );
}

export default DefaultSlider;
