import Styles from './styles.module.css';
import React, { useState, useEffect, useRef } from 'react';
import AccountEdit from "./accountEdit/index";
import DefaultImg from "./../../image/default/home.png";
import DefaultAccountImg from "./../../image/default/account.png";
import { getAuth, signOut } from "firebase/auth";
import { useNavigate } from 'react-router-dom';
import { IoPersonSharp } from "react-icons/io5";
import { RiLogoutBoxRLine } from "react-icons/ri";
import { IoMdHelpCircleOutline } from "react-icons/io";
import { IoMailOutline } from "react-icons/io5";
import { useSelector } from 'react-redux';

const HeaderPc: React.FC = () => {

  const users = useSelector((state: any) => state.auth.users);
  const company = useSelector((state: any) => state.company.company);

  const navigate = useNavigate();

  const [ accountEdit, setAccountEdit ] = useState(false);

  async function logOut() {
    const auth = getAuth();
    await signOut(auth).then(() => navigate('/company/login'));
  }

  const [ visible1, setVisible1 ] = useState(false);
  const buttonRef1 = useRef<HTMLDivElement | null>(null);
  const divRef1 = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        divRef1.current && !divRef1.current.contains(event.target as Node) &&
        buttonRef1.current && !buttonRef1.current.contains(event.target as Node)
      ) {
        setVisible1(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleButtonClick1 = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    setVisible1((prev) => !prev);
  };

  return (
    <>
    <div className={Styles.box}>
      <div className={Styles.nav}>
        <img
          src={`https://firebasestorage.googleapis.com/v0/b/trankllc-com.appspot.com/o/companies_logo%2Fresize_images%2F${company.id}_200x200?alt=media&token=?${new Date().getTime()}`}
          alt=""
          onError={(e) => {
            e.currentTarget.onerror = null;
            e.currentTarget.src = DefaultImg;
          }}
        />
        <h4>{company.name}</h4>
      </div>

      <div className={Styles.link}>
        <div onClick={() => navigate(`/`)}>
          <IoMdHelpCircleOutline className={Styles.link_img} />
          <p>ヘルプ</p>
        </div>
        <div onClick={() => navigate(`/contact`)}>
          <IoMailOutline className={Styles.link_img} />
          <p>問い合わせ</p>
        </div>
        {/* <FaBell className={Styles.link_notice} /> */}
        <div className={Styles.account}>
          <div
            className={Styles.account_info}
            ref={buttonRef1}
            onClick={handleButtonClick1}
          >
            <img
              src={`https://firebasestorage.googleapis.com/v0/b/trankllc-com.appspot.com/o/profiles%2Fresize_images%2F${users.uid}_200x200?alt=media&token=?${new Date().getTime()}`}
              alt=""
              onError={(e) => {
                e.currentTarget.onerror = null;
                e.currentTarget.src = DefaultAccountImg;
              }}
            />
          </div>

          {visible1 && (
            <div className={Styles.account_nav} ref={divRef1}>
              <div onClick={() => setAccountEdit(true)}>
                <IoPersonSharp className={Styles.account_icons} />
                <p>アカウント</p>
              </div>
              <div>
                <RiLogoutBoxRLine className={Styles.account_icons} />
                <p onClick={() => logOut()}>ログアウト</p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>

    {accountEdit ?
      <AccountEdit setAccountEdit={setAccountEdit} />
    : <></>}
    </>
  );
};

export default HeaderPc;
