import React from 'react';
import Styles from './styles.module.css';

type DefaultInputSalaryProps = {
  title: string,
  required: Boolean,
  value1: number,
  setValue1: React.Dispatch<React.SetStateAction<number>>;
  unit: string;
  desc: string;
};

const DefaultInputSalary: React.FC<DefaultInputSalaryProps> = ({ title, required, value1, setValue1, unit, desc }) => {

  return (
    <>
    <div className={Styles.title}>
      <div>
        <p>{title}</p>
        <div className={ required ? Styles.title_yes : Styles.title_no }>{required ? '※必須' : '任意'}</div>
      </div>
      <p></p>
    </div>
    <div className={Styles.input}>
      <input placeholder='' type='number' value={value1} onChange={(e) => setValue1(Number(e.target.value))} />
      <p>{unit}</p>
    </div>
    <p className={Styles.desc}>{desc}</p>
    </>
  );
}

export default DefaultInputSalary;
