import Styles from './styles.module.css';
import React from 'react';
import { MdError } from 'react-icons/md';

type RecruitExaminationItemProps = {
  message: string;
};

const PartsError: React.FC<RecruitExaminationItemProps> = ({ message }) => {

  return (
    <>
    <div className={Styles.box}>
      <MdError className={Styles.box_icon} />
      <h4>エラー：{message}</h4>
    </div>
    </>
  );
}

export default PartsError;
