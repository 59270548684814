import DocumentMeta from 'react-document-meta';
import Styles from '../styles.module.css';
import React from 'react';
import { AiFillCheckCircle } from "react-icons/ai";
import AnchorLink from 'react-anchor-link-smooth-scroll';
import Icon from './../../image/media/1/top.png';
import Image from './../../image/media/6/top.png';
import Image1 from './../../image/media/6/1.png';
import Image2 from './../../image/media/6/2.png';
import Image3 from './../../image/media/6/3.png';
import Image4 from './../../image/media/6/4.png';
import Image5 from './../../image/media/6/5.png';
import Image6 from './../../image/media/6/6.png';
import Image7 from './../../image/media/6/7.png';
import Image8 from './../../image/media/6/8.png';
import Image9 from './../../image/media/6/9.png';
import Image10 from './../../image/media/6/10.png';

const Article0001 = () => {

  const meta = {
    title: '【美容師・美容学生向け】履歴書の志望動機タイプをサロン別に解説！',
    description: 'この記事では就活の際に記載する志望動機の書き方について解説します。志望動機とは、履歴書やエントリーシートに書く、「なぜ自分がこのサロンで働きたいと思ったか」を伝える文章です。300字から500字で書くことが多く、企業の採用担当者が重要視するポイントとなっています。',
    canonical: 'https://memorii-works.com/',
    meta: { charset: 'utf-8', }
  };

  return (
    <>
    <DocumentMeta {...meta}>
      <div className={Styles.media_nav}>
        <a className={Styles.media_nav_icon} href='https://trankllc.com/media/subsidy/business/0000'>
          <img src={Icon} loading="lazy" alt='memorii' />
          <h2>美容学生の教科書</h2>
        </a>
        <a className={Styles.media_nav_contact} href='https://trankllc.com/contact'>
          <p>問い合わせ</p>
        </a>
      </div>
      <div className={Styles.media}>
        <div className={Styles.media_article}>
          
          <p className={Styles.media_date}>2024/04/20 更新日: -</p>
          <h1 className={Styles.media_title}>【美容師・美容学生向け】履歴書の志望動機タイプをサロン別に解説！</h1>
          <img src={Image} className={Styles.media_title_img} alt='美容 志望動機' />

          <p className={Styles.media_text_text}>
            <br />
            志望動機とは、履歴書やエントリーシートに書く、「なぜ自分がこのサロンで働きたいと思ったか」を伝える文章です。300字から500字で書くことが多く、企業の採用担当者が重要視するポイントとなっています。<br />
          </p>

          <div className={Styles.media_introduction}>
            <div>
              <AiFillCheckCircle className={Styles.media_introduction_icon} />
              <h3>この記事でこの3点が分かる！</h3>
            </div>
            <hr></hr>
            <ul>
              <li><span>志望動機はどれくらい重要なのか</span></li>
              <li><span>良い志望動機の内容</span></li>
              <li><span>サロンタイプ別！志望動機の例文</span></li>
            </ul>
          </div>

          <div className={Styles.media_contents}>
            <div className={Styles.media_contents_title}>
              本記事の目次
            </div>
            <div className={Styles.media_contents_contents}>
              <ol>
                <li><AnchorLink href="#1" offset="100"><strong>志望動機の重要性とは？</strong></AnchorLink></li>
                <li><AnchorLink href="#2" offset="100"><strong>良い志望動機とは？</strong></AnchorLink></li>
                <li><AnchorLink href="#3" offset="100"><strong>志望動機のタイプを理解する(例文あり)</strong></AnchorLink></li>
                <li><AnchorLink href="#4" offset="100"><strong>まとめ</strong></AnchorLink></li>
              </ol>
            </div>
          </div>

          <div id='1' className={Styles.media_text_title}>
            <h2>1.志望動機の重要性とは？</h2>
            <br />
          </div>
          <p className={Styles.media_text_text}>
            志望動機は、履歴書の中で一番重要な部分になっています。<br />
            株式会社リクルートが行ったアンケート調査によると、<strong>履歴書で重視する項目の第一位が志望動機となっており、70%の採用担当者が志望動機を重視すると回答</strong>しています。<br />
            <br />
            そのため、履歴書を書く際には志望動機に多くの時間をかけて、魅力的な志望動機を伝えられるようにしましょう！<br />
            <br />
            <img src={Image1} className={Styles.media_img} alt='美容学生 志望動機' />
            参照：<a href='https://next.rikunabi.com/tenshokuknowhow/archives/5135/' target="_blank" rel="nofollow noopener noreferrer">
              株式会社リクルート 「採用担当者がチェックしているポイントは？」
            </a><br />
          </p>
          <div className={Styles.media_introduction}>
              <div>
                <AiFillCheckCircle className={Styles.media_introduction_icon} />
                <h3>志望動機の重要性のまとめ</h3>
              </div>
              <hr></hr>
              <ul>
                <li><span>・志望動機は履歴書の中で一番重視される部分！</span></li>
                <li><span>・70%のサロンで志望動機が重視される！</span></li>
                <li><span>・志望動機には、履歴書の中でも多くの時間をかけるべし！</span></li>
              </ul>
          </div>

          <div id='2' className={Styles.media_text_title}>
              <h2>2.良い志望動機とは？</h2>
          </div>
          <p className={Styles.media_text_text}>
            <br />
            <img src={Image2} className={Styles.media_img} alt='美容 志望動機' />
            参照：<a href='https://doda.jp/guide/rireki/douki/sample/' target="_blank" rel="nofollow noopener noreferrer">
              パーソルキャリア株式会社 受かる志望動機・志望理由
            </a><br />
            <br />
            上のグラフは、パーソルキャリア株式会社が行った、採用担当者への「志望動機・志望理由で重要視する点」というアンケートへの回答結果です。<br />
            これによると、1位の「実現したい姿が明確であること」と2位の「活かせるスキルや経験が明確であること」が全体の8割以上を占めていることが分かります。3位の「自社でならなければいけない理由が明確であること」も加味すると…<br />
            <br />
            <strong>①今後、どのような美容師になりたいか</strong><br />
            <strong>②自分の強みは何か</strong><br />
            <strong>③なりたい美容師の姿と、そのサロンが求めている人の特徴は一致しているか</strong><br />
            の3点が書かれていることが、良い志望動機であると言えるでしょう。<br />
            <br />
            これで、良い志望動機がどんなものかは理解することができましたね！<br />
            次は、志望動機の種類を解説していきます。<br />
            自分が応募するサロンの志望動機がどのタイプかを確認してみましょう！<br />
          </p>

          <div id='3' className={Styles.media_text_title}>
              <h2>志望動機のタイプを理解する</h2>
          </div>
          <p className={Styles.media_text_text}>
            <br />
            志望動機には、大きく分けて3つの種類があります！<br />
            それは、<strong>「美容師の志望動機」、「サロンの志望動機」と「両方の志望動機」</strong>です。<br />
            これらの種類によって書く内容も変わってきますが、文字数は300字～500字で固定する必要があります。<br />
            <br />
            そのため、まずは自分が行きたいサロンがどのような志望動機を求めているのかを確認し、それに沿った回答をできるようにしましょう！<br />
          </p>
          <h3 className={Styles.media_text_text_title}>美容師の志望動機</h3><br />
          <p className={Styles.media_text_text}>
            美容師の志望動機では「なぜ自分が美容師になりたいのか」「美容師になってどのようなことをしていきたいのか」を強く伝える必要があります。<br />
            また、美容師の志望動機は、以下のようなサロンで書く必要があります。<br />
            <br />
            <strong>ACQUA</strong>
            <img src={Image3} className={Styles.media_img} alt='ACQUA 履歴書' />
            参照：<a href='https://acqua.co.jp/company-profile' target="_blank" rel="nofollow noopener noreferrer">
              ACQUA 2023年度 採用サイト
            </a><br />
            <br />
            <strong>air</strong>
            <img src={Image4} className={Styles.media_img} alt='air 履歴書' />
            参照：<a href='https://www.air.st/recruit/' target="_blank" rel="nofollow noopener noreferrer">
              air 2024年度 採用サイト
            </a><br />
            <br />
            いかがでしょうか。毎年多くの応募が集まる有名サロンでも美容師の志望動機に関する質問をしていることが分かりますね。<br />
            <br />
            美容師の志望動機は、自分のなりたい姿と美容師が出来ることが一致しているということを伝えることが非常に重要になってきます。<br />
            美容師の志望動機に関する例文を下に記載しますので、ご覧ください！<br />
            <br />
            <img src={Image5} className={Styles.media_img} alt='美容師 志望動機' /><br />
            <br />
            上のような内容に加えて、自分の強みなどをアピールできるとさらに良いですね！<br />
            美容師の志望動機を書く必要がある際は、参考にしていただけると嬉しいです！<br />
          </p>
          <h3 className={Styles.media_text_text_title}>サロンの志望動機</h3><br />
          <p className={Styles.media_text_text}>
            サロンの志望動機では「なぜこのサロンを選んだのか」「自分の力を出せる特徴」を強く伝える必要があります。<br />
            また、サロンの志望動機は、以下のようなサロンで書く必要があります<br />
            <br />
            <strong>ACQUA</strong>
            <img src={Image6} className={Styles.media_img} alt='ACQUA 履歴書' />
            参照：<a href='https://acqua.co.jp/company-profile' target="_blank" rel="nofollow noopener noreferrer">
              ACQUA 2023年度 採用サイト
            </a><br />
            <br />
            ACQUAは志望動機に関する質問が多く、書類選考で非常に重要視していることがわかりますね！<br />
            書類以外でも、面接時に「なぜこのサロンを選んだのか」ということを聞かれることもありますので、書類に書く必要がない方でも理由を話せるようにしておきましょう！<br />
            <br />
            サロンの志望動機で重要なことは、自分の特徴やなりたい姿と、美容院の特徴が一致していることを伝えることです。<br />
            サロンの志望動機に関する例文を下に載せておりますので、是非ご覧ください！<br />
            <br />
            <img src={Image7} className={Styles.media_img} alt='美容師 志望動機' /><br />
            <br />
            サロンの志望動機を担当者が聞く理由は、あなたとサロンがマッチしているかを確かめたいというのが大きな理由になっています。<br />
            応募サロンに訪れたり、サロンで働いている美容師と話してみたりすることで、サロンの雰囲気を確実に把握しましょう！<br />
          </p>
          <h3 className={Styles.media_text_text_title}>両方の志望動機</h3><br />
          <p className={Styles.media_text_text}>
            両方の志望動機では、先ほど説明した「美容師の志望動機」と「サロンの志望動機」の両方を書く必要があります。<br />
            2つの内容を約500字で伝える必要があるので、シンプルな文章にすることを意識しましょう！<br />
            両方の志望動機は以下のようなサロンで書く必要があります。<br />
            <br />
            <strong>OCEAN TOKYO</strong>
            <img src={Image8} className={Styles.media_img} alt='OCEAN TOKYO 履歴書' />
            参照：<a href='https://www.oceantokyo.com/recruit/assistant/' target="_blank" rel="nofollow noopener noreferrer">
              OCEAN TOKYO 2024年度 採用サイト
            </a><br />
            <br />
            <strong>PEEK-A-BOO</strong>
            <img src={Image9} className={Styles.media_img} alt='PEEK-A-BOO 履歴書' />
            参照：<a href='https://www.peek-a-boo.co.jp/recruit/' target="_blank" rel="nofollow noopener noreferrer">
              PEEK-A-BOO 令和6年度 新卒採用情報
            </a><br />
            <br />
            多くのサロンで両方の志望動機を求められており、最も一般的な種類となっています。簡潔に自分の言いたいことを伝えられるよう、整理しながら書いていきましょう！<br />
            <br />
            両方の志望動機は、美容師になりたい！このサロンに入りたい！という自分の気持ちと、美容師やサロンの特徴をバランスよく書いていく必要があります。<br />
            またしても例文を下に記載しますので、ご覧ください！<br />
            <br />
            <img src={Image10} className={Styles.media_img} alt='美容師 志望動機' /><br />
            <br />
            いかがでしたでしょうか？以上で、志望動機の種類に関する説明は終了です。<br />
            志望動機に関する理解は深まったでしょうか？<br />
            次の記事では、実際に志望動機を書いていく方法をお伝えします！<br />
          </p>
          <div className={Styles.media_introduction}>
              <div>
                <AiFillCheckCircle className={Styles.media_introduction_icon} />
                <h3>志望動機の重要性のまとめ</h3>
              </div>
              <hr></hr>
              <ul>
                <li><span>・志望動機には、「美容師の志望動機」・「サロンの志望動機」・「両方の志望動機」の3パターンがある！</span></li>
                <li><span>・志望動機は300～500字で書くべし！</span></li>
                <li><span>・自分のことだけでなく、美容院の特徴や思いを踏まえた志望動機にするべし！</span></li>
              </ul>
          </div>

          <div id='4' className={Styles.media_text_title}>
            <h2>4.まとめ</h2>
          </div>
          <p className={Styles.media_text_text}>
            <br />
            さて、志望動機について理解することはできましたか？<br />
            理解することが出来たら、いよいよ次は実際に書くことになります！<br />
            本サイトでは、魅力的で、わかりやすい志望動機を書くための方法に関する記事もありますので、是非ご覧ください！！！<br />
            <br />
            次はこちら！：<a href='https://next.rikunabi.com/tenshokuknowhow/archives/5135/' target="_blank" rel="nofollow noopener noreferrer">
              志望動機を書くための方法
            </a><br />
            <br />
            この記事を読んだ皆さんが、第一志望のサロンに合格することを祈っています！<br />
          </p>
        </div>
      </div>
    </DocumentMeta>
    </>
  );
}


export default Article0001;

