import Styles from './styles.module.css';
import 'firebase/auth'
import React from 'react';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import firebase from "firebase/compat/app";
import DefaultTitle from '../../../../package/parts/title/index';
import ErrorMessage from '../../../../package/parts/error/index';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import DefaultInput1 from '../../../../package/parts/1-input/index';
import BackModal from '../12.back/index';
import Header from "../0.header/index";

const RecruitDocument: React.FC = () => {

  const navigate = useNavigate();
  
  const link: string = new URL(window.location.href).href;
  const recruitLink = decodeURIComponent(link.slice(link.indexOf('id=') + 3, link.length));
  
  const [ errorMessage, setErrorMessage ] = useState('');

  const [ saveBtn, setSaveBtn ] = useState(false);
  
  // 書類提出
  const [ document, setDocument ] = useState(false);
  // 履歴書の有無
  const [ resume, setResume ] = useState(false);
  // 質問欄の有無
  const [ question, setQuestion ] = useState(false);
  const [ questionCount, setQuestionCount ] = useState(0);
  // コーディネートの有無
  const [ coordinate, setCoordinate ] = useState(false);
  // その他の提出書類
  const [ otherDocument, setOtherDocument ] = useState(false);
  const [ otherDocumentCount, setOtherDocumentCount ] = useState(0);

  const [ question01, setQuestion01 ] = useState<string | number>('');
  const [ question02, setQuestion02 ] = useState<string | number>('');
  const [ question03, setQuestion03 ] = useState<string | number>('');
  
  const [ otherDocument01, setOtherDocument01 ] = useState<string | number>('');
  const [ otherDocument02, setOtherDocument02 ] = useState<string | number>('');
  const [ otherDocument03, setOtherDocument03 ] = useState<string | number>('');

  const [ backStatus, setBackStatus ] = useState(false);

  const [ purpose, setPurpose ] = useState(false);
  
  useEffect(() => {
    window.scrollTo(0, 0);

    firebase.firestore().collection("recruits").doc(recruitLink).get()
    .then((doc) => {
      const data = doc.data();
      if (data) {
        setSaveBtn(data.alreadyPublic ?? false);

        setPurpose(data.document_visit ?? false);

        setDocument(data.document_document ?? false);
        
        setResume(data.document_resume ?? false);
        setQuestion(data.document_question ?? false);
        setCoordinate(data.document_coordinate ?? false);
        setOtherDocument(data.document_otherDocument ?? false);

        setQuestionCount(Number(data.document_questionCount ?? 0));
        setQuestion01(data.document_question01 ?? '');
        setQuestion02(data.document_question02 ?? '');
        setQuestion03(data.document_question03 ?? '');

        setOtherDocumentCount(Number(data.document_otherDocumentCount ?? 0));
        setOtherDocument01(data.document_otherDocument01 ?? '');
        setOtherDocument02(data.document_otherDocument02 ?? '');
        setOtherDocument03(data.document_otherDocument03 ?? '');
      }
    });
  }, [recruitLink]);

  function check(e: boolean) {

    window.scrollTo(0, 0);

    if (question) {
      if (questionCount === 0) {
        setErrorMessage('1.質問数を入力してください。');
      } else if (questionCount === 1) {
        if (question01 === '') {
          setErrorMessage('1.質問を入力してください。');
        } else {
          check1(e);
        }
      } else if (questionCount === 2) {
        if (question01 === '') {
          setErrorMessage('1.質問を入力してください。');
        } else if (question02 === '') {
          setErrorMessage('2.質問を入力してください。');
        } else {
          check1(e);
        }
      } else if (questionCount === 3) {
        if (question01 === '') {
          setErrorMessage('1.質問を入力してください。');
        } else if (question02 === '') {
          setErrorMessage('2.質問を入力してください。');
        } else if (question03 === '') {
          setErrorMessage('3.質問を入力してください。');
        } else {
          check1(e);
        }
      }
    } else {
      check1(e);
    }
  }

  function check1(e: boolean) {

    window.scrollTo(0, 0);

    if (otherDocument) {
      if (otherDocumentCount === 0) {
        setErrorMessage('その他の提出物の数を入力してください。');
      } else if (otherDocumentCount === 1) {
        if (otherDocument01 === '') {
          setErrorMessage('1.その他の提出物を入力してください。');
        } else {
          save(e);
        }
      } else if (otherDocumentCount === 2) {
        if (otherDocument01 === '') {
          setErrorMessage('1.その他の提出物を入力してください。');
        } else if (otherDocument02 === '') {
          setErrorMessage('2.その他の提出物を入力してください。');
        } else {
          save(e);
        }
      } else if (otherDocumentCount === 3) {
        if (otherDocument01 === '') {
          setErrorMessage('1.その他の提出物を入力してください。');
        } else if (otherDocument02 === '') {
          setErrorMessage('2.その他の提出物を入力してください。');
        } else if (otherDocument03 === '') {
          setErrorMessage('3.その他の提出物を入力してください。');
        } else {
          save(e);
        }
      }
    } else {
      save(e);
    }
  }

  function save(e: boolean) {
    firebase.firestore().collection("recruits").doc(recruitLink)
    .update({
      status_document: e,
      
      // 目的
      document_visit: purpose,
      // 全体
      document_document: document,
      document_resume: resume,
      document_question: question,
      // 質問
      document_coordinate: coordinate,
      document_questionCount: Number(questionCount),
      document_question01: question01,
      document_question02: question02,
      document_question03: question03,
      // その他
      document_otherDocument: otherDocument,
      document_otherDocumentCount: Number(otherDocumentCount),
      document_otherDocument01: otherDocument01,
      document_otherDocument02: otherDocument02,
      document_otherDocument03: otherDocument03,
      
      last_time: new Date(),
    })
    .then(() => {
      navigate(`/edit/id=${recruitLink}`);
    });
  }

  return (
    <>
    <Header />

    <div className={Styles.box}>
      <div className={Styles.box_inline}>

        <p className={Styles.desc} style={{color : '#AD0200', fontWeight : 'bold', textAlign : 'center'}}>注意事項：このページは一度保存すると、変更することはできません。</p>
        <p className={Styles.desc}>　</p>

        {errorMessage !== '' ?
          <ErrorMessage message={errorMessage} />
        : <></> }

        <div className={Styles.contents}>

          <h5 className={Styles.title}>サロン見学</h5>

          <div className={Styles.radio_btn}>
            <div>
              <input type="radio" name='0' checked={purpose === true} onClick={() => setPurpose(true)} />
              <p>募集する</p>
            </div>
            <div>
              <input type="radio" name='0' checked={purpose === false} onClick={() => setPurpose(false)} />
              <p>募集しない</p>
            </div>
          </div>
          <p className={Styles.desc}>　</p>

        </div>
        
        <div className={Styles.contents}>
          <h5 className={Styles.title}>応募設定</h5>

          <DefaultTitle title={'書類提出'} required={true} />
          <div className={Styles.radio_btn}>
            <div>
              <input type="radio" name='1' checked={document === true} onClick={() => setDocument(true)} />
              <p>応募時に書類を求める</p>
            </div>
            <div>
              <input type="radio" name='1' checked={document === false} onClick={() => setDocument(false)} />
              <p>応募時に書類を求めない</p>
            </div>
          </div>
          <p className={Styles.desc}>　</p>

          {document ?
          <>
          <h5 className={Styles.title}>応募内容</h5>
          <DefaultTitle title={'履歴書の有無'} required={true} />
          <div className={Styles.radio_btn}>
            <div>
              <input name="2" type="radio" checked={resume === true} onClick={() => setResume(true)} />
              <p>提出を求める</p>
            </div>
            <div>
              <input name="2" type="radio" checked={resume === false} onClick={() => setResume(false)} />
              <p>提出を求めない</p>
            </div>
          </div>
          <p className={Styles.desc}>　</p>
          
          <DefaultTitle title={'追加質問の有無'} required={true} />
          <div className={Styles.radio_btn}>
            <div>
              <input name="3" type="radio" checked={question === true} onClick={() => setQuestion(true)} />
              <p>回答を求める</p>
            </div>
            <div>
              <input name="3" type="radio" checked={question === false} onClick={() => setQuestion(false)} />
              <p>回答を求めない</p>
            </div>
          </div>
          <p className={Styles.desc}>　</p>

          <DefaultTitle title={'コーディネート写真の有無'} required={true} />
          <div className={Styles.radio_btn}>
            <div>
              <input name="4" type="radio" checked={coordinate === true} onClick={() => setCoordinate(true)} />
              <p>提出を求める</p>
            </div>
            <div>
              <input name="4" type="radio" checked={coordinate === false} onClick={() => setCoordinate(false)} />
              <p>提出を求めない</p>
            </div>
          </div>
          <p className={Styles.desc}>　</p>

          <DefaultTitle title={'その他の提出書類'} required={true} />
          <div className={Styles.radio_btn}>
            <div>
              <input name="5" type="radio" checked={otherDocument === true} onClick={() => setOtherDocument(true)} />
              <p>提出を求める</p>
            </div>
            <div>
              <input name="5" type="radio" checked={otherDocument === false} onClick={() => setOtherDocument(false)} />
              <p>提出を求めない</p>
            </div>
          </div>
          <p className={Styles.desc}>　</p>
          </> : <></>}

        </div>

        {question && document ?
        <>
          <div className={Styles.contents}>
            <h5 className={Styles.title}>追加質問</h5>

            <DefaultTitle title={'質問数'} required={true} />
            <select className={Styles.select}
              value={questionCount} onChange={(e) => setQuestionCount(Number(e.target.value))}>
              <option value={0}>未選択</option>
              <option value={1}>1つ</option>
              <option value={2}>2つ</option>
              <option value={3}>3つ</option>
            </select>
            <p className={Styles.desc}>　</p>
            
            {questionCount === 1 || questionCount === 2 || questionCount === 3 ?
            <DefaultInput1
              title={'1.質問'} required={true} length={20} place={''} type={'text'}
              value={question01} setValue={setQuestion01}
              desc={'　'}
            /> : <></> }
            
            {questionCount === 2 || questionCount === 3 ?
            <DefaultInput1
              title={'2.質問'} required={true} length={20} place={''} type={'text'}
              value={question02} setValue={setQuestion02}
              desc={'　'}
            /> : <></> }
            
            {questionCount === 3 ?
            <DefaultInput1
              title={'3.質問'} required={true} length={20} place={''} type={'text'}
              value={question03} setValue={setQuestion03}
              desc={'　'}
            /> : <></> }

          </div>
        </> : <></>}
        
        {otherDocument && document ?
        <div className={Styles.contents}>
          
          <h5 className={Styles.title}>その他の提出物</h5>

          <DefaultTitle title={'その他の提出物の数'} required={true} />
          <select className={Styles.select}
            value={otherDocumentCount} onChange={(e) => setOtherDocumentCount(Number(e.target.value))}>
            <option value={0}>未選択</option>
            <option value={1}>1つ</option>
            <option value={2}>2つ</option>
            <option value={3}>3つ</option>
          </select>
          <p className={Styles.desc}>　</p>
          
          {otherDocumentCount === 1 || otherDocumentCount === 2 || otherDocumentCount === 3 ?
          <DefaultInput1
            title={'1.提出書類'} required={true} length={20} place={''} type={'text'}
            value={otherDocument01} setValue={setOtherDocument01}
            desc={'　'}
          /> : <></> }
          
          {otherDocumentCount === 2 || otherDocumentCount === 3 ?
          <DefaultInput1
            title={'2.提出書類'} required={true} length={20} place={''} type={'text'}
            value={otherDocument02} setValue={setOtherDocument02}
            desc={'　'}
          /> : <></> }
          
          {otherDocumentCount === 3 ?
          <DefaultInput1
            title={'3.提出書類'} required={true} length={20} place={''} type={'text'}
            value={otherDocument03} setValue={setOtherDocument03}
            desc={'　'}
          /> : <></> }

        </div> : <></>}

        {!saveBtn ?
          <div className={Styles.save_box}>
            <button className={Styles.save_box_now} onClick={() => setBackStatus(true)}>戻る</button>
            <button className={Styles.save_box_now} onClick={() => save(false)}>一時保存</button>
            <button className={Styles.save_box_save} onClick={() => check(true)}>保存</button>
          </div>
          :
          <p className={Styles.desc}>既に保存されているため、変更する事はできません。</p>
        }

      </div>

    </div>

    {backStatus ?
      <BackModal setBackStatus={setBackStatus} />
    : <></> }
    </>
  );
}

export default RecruitDocument;