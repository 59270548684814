import Styles from './styles.module.css';
import React, { useState, useEffect, useRef } from 'react';
import AccountEdit from "./accountEdit/index";
import DefaultImg from "./../../image/default/home.png";
import DefaultAccountImg from "./../../image/default/account.png";
import { IoMdHelpCircleOutline } from "react-icons/io";
import { IoMailOutline } from "react-icons/io5";
import { getAuth, signOut } from "firebase/auth";
import { useNavigate } from 'react-router-dom';
import { IoPersonSharp } from "react-icons/io5";
import { RiLogoutBoxRLine } from "react-icons/ri";
import { useSelector } from 'react-redux';

const HeaderMobile: React.FC = () => {

  const users = useSelector((state: any) => state.auth.users);
  const company = useSelector((state: any) => state.company.company);
  
  const navigate = useNavigate();

  const [ accountEdit, setAccountEdit ] = useState(false);

  async function logOut() {
    const auth = getAuth();
    await signOut(auth).then(() => navigate('/company/login'));
  }

  const [ visible1, setVisible1 ] = useState(false);
  const buttonRef1 = useRef<HTMLDivElement | null>(null);
  const divRef1 = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        divRef1.current && !divRef1.current.contains(event.target as Node) &&
        buttonRef1.current && !buttonRef1.current.contains(event.target as Node)
      ) {
        setVisible1(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleButtonClick1 = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    setVisible1((prev) => !prev);
  };

  return (
    <>
    <div className={Styles.header}>
      <div className={Styles.header_nav}>
        <img src={`https://firebasestorage.googleapis.com/v0/b/trankllc-com.appspot.com/o/companies_logo%2Fresize_images%2F${company.id}_200x200?alt=media&token=?${new Date().getTime()}`} alt=''
          onError={e => {
            e.currentTarget.onerror = null;
            e.currentTarget.src = DefaultImg;
          }} />
        <h4>{company.name}</h4>
      </div>
      
      <div className={Styles.header_link}>
        <div className={Styles.header_link_account}>
          <div className={Styles.header_link_account_info}
            ref={buttonRef1}
            onClick={handleButtonClick1}
          >
            <img src={`https://firebasestorage.googleapis.com/v0/b/trankllc-com.appspot.com/o/profiles%2Fresize_images%2F${users.uid}_200x200?alt=media&token=?${new Date().getTime()}`} alt=''
              onError={(e) => {
                e.currentTarget.onerror = null;
                e.currentTarget.src = DefaultAccountImg;
              }}
            />
          </div>
          
          {visible1 && (
            <div className={Styles.header_link_accoun_nav}>
              <div onClick={() => {navigate(`/`)}}>
                <IoMdHelpCircleOutline className={Styles.header_link_accoun_nav_icon} />
                <p>お問い合わせ</p>
              </div>
              <div onClick={() => {navigate(`/`)}}>
                <IoMailOutline className={Styles.header_link_accoun_nav_icon} />
                <p>ヘルプ</p>
              </div>
              <div onClick={() => setAccountEdit(true)}>
                <IoPersonSharp className={Styles.header_link_accoun_nav_icon} />
                <p>アカウント</p>
              </div>
              <div>
                <RiLogoutBoxRLine className={Styles.header_link_accoun_nav_icon} />
                <p onClick={() => logOut()}>ログアウト</p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>

    {visible1 && (
      <AccountEdit setAccountEdit={setAccountEdit} />
    )}
    </>
  );
}

export default HeaderMobile;
