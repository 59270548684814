import './styles.css';
import React, { useState } from 'react'
import logoCompany from './../../image/logo.png';
import { IoPersonSharp } from "react-icons/io5";
import { GoHomeFill } from "react-icons/go";
import { IoMdSettings } from "react-icons/io";
import { useNavigate } from 'react-router-dom';
import { BsBuildingFill } from "react-icons/bs";
import { MdOutlineInsertPhoto } from "react-icons/md";
import { FaCalendarAlt } from "react-icons/fa";
import { IoMdMail } from "react-icons/io";
import { FaChevronLeft } from "react-icons/fa6";
import { FaChevronRight } from "react-icons/fa";
import { isMobile } from "react-device-detect";
import { fetchNavigation } from '../../features/0.main/navigation';
import { useDispatch } from 'react-redux';

interface ComponentProps {
  navigation: number;
  authority: boolean;
}

const NavigationPc: React.FC<ComponentProps> = (props) => {
  
  const { navigation, authority } = props;
  const dispatch = useDispatch();

  const [ wide, setWide ] = useState(false);

  return (
    <>
    <div className='works_nav_pc'>

      <div className='works_nav_pc_logo'>
        <img src={logoCompany} alt='memorii recruit' />
        {wide ?
          <h1>memorii works</h1>
        : <></> }
      </div>

      {authority && !isMobile ?
        <div className='works_nav_pc_item' 
          style={navigation === 1 ? {backgroundColor : '#192651'} : {}}
          onClick={() => dispatch(fetchNavigation(1))}>
          <GoHomeFill className='works_nav_pc_item_icon' style={navigation === 1 ? {color : 'white'} : {}} />
          {wide ?
            <p style={navigation === 1 ? {color : 'white', fontWeight : 'bold'} : {}}>ホーム</p>
          : <></> }
        </div>
      : <></> }

      {authority && !isMobile ?
        <div className='works_nav_pc_item' 
          style={navigation === 2 ? {backgroundColor : '#192651'} : {}}
          onClick={() => dispatch(fetchNavigation(2))}>
          <BsBuildingFill className='works_nav_pc_item_icon' style={navigation === 2 ? {color : 'white'} : {}} />
          {wide ?
            <p style={navigation === 2 ? {color : 'white', fontWeight : 'bold'} : {}}>店舗情報/求人管理</p>
          : <></> }
        </div>
      : <></> }

      {authority && !isMobile ?
        <div className='works_nav_pc_item' 
          style={navigation === 3 ? {backgroundColor : '#192651'} : {}}
          onClick={() => dispatch(fetchNavigation(3))}>
          <MdOutlineInsertPhoto className='works_nav_pc_item_icon' style={navigation === 3 ? {color : 'white'} : {}} />
          {wide ?
            <p style={navigation === 3 ? {color : 'white', fontWeight : 'bold'} : {}}>作品・写真管理</p>
          : <></> }
        </div>
      : <></> }

      <div className='works_nav_pc_item' 
        style={navigation === 4 ? {backgroundColor : '#192651'} : {}}
        onClick={() => dispatch(fetchNavigation(4))}>
        <IoPersonSharp className='works_nav_pc_item_icon' style={navigation === 4 ? {color : 'white'} : {}} />
        {wide ?
          <p style={navigation === 4 ? {color : 'white', fontWeight : 'bold'} : {}}>応募者管理</p>
        : <></> }
      </div>

      <div className='works_nav_pc_item' 
        style={navigation === 5 ? {backgroundColor : '#192651'} : {}}
        onClick={() => dispatch(fetchNavigation(5))}>
        <FaCalendarAlt className='works_nav_pc_item_icon' style={navigation === 5 ? {color : 'white'} : {}} />
        {wide ?
          <p style={navigation === 5 ? {color : 'white', fontWeight : 'bold'} : {}}>スケジュール</p>
        : <></> }
      </div>

      {authority && !isMobile ?
        <div className='works_nav_pc_item' 
          style={navigation === 6 ? {backgroundColor : '#192651'} : {}}
          onClick={() => dispatch(fetchNavigation(6))}>
          <IoMdMail className='works_nav_pc_item_icon' style={navigation === 6 ? {color : 'white'} : {}} />
          {wide ?
            <p style={navigation === 6 ? {color : 'white', fontWeight : 'bold'} : {}}>メッセージ</p>
          : <></> }
        </div>
      : <></> }

      {authority && !isMobile ?
        <div className='works_nav_pc_item' 
          style={navigation === 7 ? {backgroundColor : '#192651'} : {}}
          onClick={() => dispatch(fetchNavigation(7))}>
          <IoMdSettings className='works_nav_pc_item_icon' style={navigation === 7 ? {color : 'white'} : {}} />
          {wide ?
            <p style={navigation === 7 ? {color : 'white', fontWeight : 'bold'} : {}}>設定・管理</p>
          : <></> }
        </div>
      : <></> }

      <div className='works_nav_pc_toggle'>
        {wide ?
          <FaChevronLeft className='works_nav_pc_toggle_icon' onClick={() =>  setWide(!wide)} />
          :
          <FaChevronRight className='works_nav_pc_toggle_icon' onClick={() =>  setWide(!wide)} />
        }
      </div>

    </div>
    </>
  );
}

export default NavigationPc;
