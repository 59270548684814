// redux/recruitSlice.ts
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { Timestamp } from 'firebase-admin/firestore';
import firebase from "firebase/compat/app";

interface Recruit {
  id: string;
  status: number;
  title: string;
  employment: string;
  time: string;
  pv: number;
  saves: number;
  applicants: string[];
  recruitment: number;
  goal: number;
  apply: number;
}

interface RecruitState {
  list: Recruit[];
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
}

// リクルートデータを取得
export const fetchRecruits = createAsyncThunk(
  'recruitData/fetchRecruits',
  async (recruitIds: string[], { dispatch }) => {
    const recruits: Recruit[] = [];
    const applicants: any[] = [];

    for (const id of recruitIds) {
      const doc = await firebase.firestore().collection('recruits').doc(id).get();

      const data = doc.data()!;
      
      if (doc.exists) {
        recruits.push({
          id: doc.id,
          status: data?.status || 3,
          title: data?.store_title || 'Untitled',
          employment: data?.recruit_employmentStatus || 'Unemployed',
          time: new Date(data?.time.seconds * 1000 + data?.time.nanoseconds / 1000000)?.toISOString() || 'Unemployed',
          pv: data?.data_pv?.length || 0,
          saves: data?.data_saves?.length || 0,
          applicants: data?.data_applicants?.length || 0,
          recruitment: data?.data_recruitment?.length || 0,
          goal: data?.store_goal?.length || 0,
          apply: data?.data_apply || 0,
        });
  
        if (doc.data()?.data_applicants?.length) {
          applicants.push(...data.data_applicants);
        }
      }
    }

    return { recruits, applicants };
  }
);

// 応募者データを取得
export const fetchApplicants = createAsyncThunk(
  'recruitData/fetchApplicants',
  async (applicantIds: string[]) => {
    const now = new Date();
    const start = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 5, 0, 0, 0);
    const end = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0);

    const applicants: any[] = [];

    for (const id of applicantIds) {

      const doc = await firebase.firestore().collection('applicants').doc(id).get();
      const data = doc.data()!;
      const applicantDate = new Date(data.time.seconds * 1000 + data.time.nanoseconds / 1000000);
      
      if (doc.exists && applicantDate >= start && applicantDate < end) {
        applicants.push({ 
          id: data.id,
          recruitId: data.recruitId,
          name: data.name,
          sex: data.sex,
          time: data.time.toISOString(),
        });
      }

    }
    
    return applicants;
  }
);

interface RecruitState {
  recruits: any[];
  applicants: any[];
  applicantsList: any[];
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
}

const initialState: RecruitState = {
  recruits: [],
  applicants: [],
  applicantsList: [],
  list: [],
  status: 'idle',
  error: null,
};

const recruitSlice = createSlice({
  name: 'recruitData',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchRecruits.fulfilled, (state, action) => {
        state.recruits = action.payload.recruits;
        state.applicants = action.payload.applicants;
      })
      .addCase(fetchApplicants.fulfilled, (state, action) => {
        state.applicantsList = action.payload;
      });
  },
});

export default recruitSlice.reducer;
