import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import firebase from "firebase/compat/app";

export const fetchAuthState = createAsyncThunk(
  'auth/fetchAuthState',
  async (_, { rejectWithValue }) => {
    const auth = getAuth();

    return new Promise<Users>((resolve, reject) => {
      onAuthStateChanged(auth,
        async (user) => {
          if (user) {
            try {
              const doc = await firebase.firestore().collection('users').doc(user.uid).get();
              resolve({
                id: doc.id,
                uid: user.uid,
                name: doc.data()?.user_name || '',
                lineId: doc.data()?.user_lineId,
                linePicture: doc.data()?.user_linePicture,
                lineName: doc.data()?.user_lineName,
              });
            } catch (error) {
              reject(error);
            }
          } else {
            reject(rejectWithValue('User is not authenticated'));
          }
        },
        (error) => rejectWithValue(error.message)
      );
    });
  }
);

interface UserState {
  users: Users | '';
}

interface Users {
  id: string;
  uid: string;
  name: string;
  lineId: string;
  linePicture: string;
  lineName: string;
}

const initialState: UserState = {
  users: '',
};

// Slice
const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAuthState.fulfilled, (state, action) => {
        state.users = action.payload;
      })
  },
});

export default authSlice.reducer;
