import Styles from './styles.module.css';
import React, { useState, useEffect } from 'react';
import Header from '../0.header/index';
import { useNavigate } from 'react-router-dom';
import firebase from "firebase/compat/app";
import ErrorMessage from '../../../../package/parts/error/index';
import BackModal from '../12.back/index';
import { useSelector } from 'react-redux';

const RecruitSummary: React.FC = () => {
  
  const navigate = useNavigate();

  const company = useSelector((state: any) => state.company.company);
  
  const link: string = new URL(window.location.href).href;
  const recruitLink = decodeURIComponent(link.slice(link.indexOf('id=') + 3, link.length));

  const [ backStatus, setBackStatus ] = useState(false);
  const [ errorMessage, setErrorMessage ] = useState('');
  
  const [ allList, setAllList ] = useState<string[]>([]);
  const [ addList, setAddList ] = useState<string[]>([]);
  const [ storeList, setStoreList ] = useState<TypeStoreList[]>([]);
  
  useEffect(() => {
    if (company.length !== 0) {
      getRecruits();
    }
  }, [company]);

  type TypeStoreList = {
    id: string,
    image: string,
    name: string,
  }

  function getRecruits() {
    firebase.firestore().collection('stores')
    .where('companyId', '==', company.id).orderBy('number', 'asc').get()
    .then(snapShot => {
      var list: string[] = [];
      var tmp: TypeStoreList[] = [];
      var addlist: string[] = [];
      snapShot.forEach((doc) => {
        const data = doc.data();
        console.log(data.status);
        if (data.status && (data.recruitId === '' || data.recruitId === recruitLink)) {
          list.push(doc.id);
          tmp.push({id: doc.id, name: data.name, image: data.image });
          if (doc.data().recruitId === recruitLink) {
            addlist.push(doc.id);
          }
        }
      });
      setAllList(list);
      setStoreList(tmp);
      setAddList(addlist);
    });
  }

  function check(v: boolean) {
    setErrorMessage('');

    if (addList.length === 0) {
      setErrorMessage('店舗を追加してください。');
    } else {
      deleteList(v);
    }
  }

  function deleteList(v: boolean) {
    for (var i = 0; i < allList.length; i++) {
      firebase.firestore().collection("stores").doc(allList[i]).update({ recruitId: '' });
    }
    save(v);
  }

  async function save(v: boolean) {
    for (var i = 0; i < addList.length; i++) {
      await firebase.firestore().collection("stores").doc(addList[i]).update({ recruitId: recruitLink });
    }

    await firebase.firestore().collection("recruits").doc(recruitLink)
    .update({
      place_stores: addList,
      status_place: v,
    }).then(() => {
      navigate(`/edit/id=${recruitLink}`);
    });
  }

  return (
    <>
    
    <Header />

    <div className={Styles.box}>

      {errorMessage !== '' ?
        <ErrorMessage message={errorMessage} />
      : <></> }

      <h4 className={Styles.store_title}>店 舗 選 択（{storeList.length}件）</h4>

      <div className={Styles.store_list}>
        {storeList.map((data, index) => 
          <div className={Styles.store_list_items} key={index}>
            <input type="checkbox"
              checked={addList.indexOf(data.id) !== -1}
              onChange={(e) => e.target.checked ? setAddList([...addList, data.id]) : setAddList(addList.filter((v) => (v !== data.id)))}/>
            <img src={`https://firebasestorage.googleapis.com/v0/b/trankllc-com.appspot.com/o/stores%2Fresize_images%2F${data.image}_500x500?alt=media&token=?${new Date().getTime()}`} />
            <h4>{data.name}</h4>
          </div>
        )}
      </div>

      <button className={Styles.store_setting} 
        onClick={() => navigate(`/edit/storeSetting/id=${recruitLink}`)}>店舗一覧の編集</button>

      <div className={Styles.save_box}>
        <button className={Styles.save_box_now} onClick={() => setBackStatus(true)}>戻る</button>
        <button className={Styles.save_box_now} onClick={() => deleteList(false)}>一時保存</button>
        <button className={Styles.save_box_save} onClick={() => check(true)}>保存</button>
      </div>

    </div>

    {backStatus ?
      <BackModal setBackStatus={setBackStatus} />
    : <></> }
    </>
  );
}

export default RecruitSummary;
