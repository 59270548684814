
import DocumentMeta from 'react-document-meta';
import Styles from '../styles.module.css';
import React from 'react';
import { AiFillCheckCircle } from "react-icons/ai";
import AnchorLink from 'react-anchor-link-smooth-scroll';
import Icon from './../../image/logo.png';
import Image from './../../image/media/1/top.png';
import Image1 from './../../image/media/1/1.png';
import Image2 from './../../image/media/1/2.png';
import Image3 from './../../image/media/1/3.png';
import Image4 from './../../image/media/1/4.png';
import Image5 from './../../image/media/1/5.png';
import Image6 from './../../image/media/1/6.png';
import Image7 from './../../image/media/1/7.png';
import Image8 from './../../image/media/1/8.png';

const Article0001 = (props) => {

  const meta = {
    title: '【初心者/美容学生向け】インスタグラムの基本的な使い方を分かりやすく解説！',
    description: 'この記事では、すでに多くの人たちが利用しているSNSインスタグラムの機能や使い方を改めて説明する記事です。毎日使用してるけど、実はあんまり使い方を分かっていないという方もいらっしゃるんじゃないでしょうか。この記事ではそんな方達向けにインスタグラムの基本的な機能を解説していきます。まだインスタグラムをインストールされていない方はこの記事を読んだらぜひ入れてみてください！',
    canonical: 'https://memorii-works.com/',
    meta: { charset: 'utf-8', }
  };

  return (
    <>
    <DocumentMeta {...meta}>
      <div className={Styles.media_nav}>
        <a className={Styles.media_nav_icon}>
          <img src={Icon} loading="lazy" alt='memorii' />
          <h2>美容学生の教科書</h2>
        </a>
        <a className={Styles.media_nav_contact} href='https://trankllc.com/contact'>
          <p>問い合わせ</p>
        </a>
      </div>
      <div className={Styles.media}>
        <div className={Styles.media_article}>
          
          <p className={Styles.media_date}>2024/04/19 更新日: -</p>
          <h1 className={Styles.media_title}>【初心者/美容学生向け】インスタグラムの基本的な使い方を分かりやすく解説！</h1>
          <img src={Image} className={Styles.media_title_img} alt='事業再構築補助金' />

          <p className={Styles.media_text_text}>
            <br />
            すでに多くの人たちが利用しているSNS インスタグラム<br />
            「昨日インスタに投稿した写真見た？」<br />
            「あの人のストーリー見た？」<br />
            なんて日常会話でよく耳にすることもあるかと思います。<br />
            <br />
            けど、実はあんまり使い方を分かっていないという方もいらっしゃるんじゃないでしょうか。<br />
            <strong>この記事ではそんな方達向けにインスタグラムの基本的な機能を解説していきます。</strong><br />
            <br />
            まだインスタグラムをインストールされていない方はこの記事を読んだらぜひ入れてみてください！<br />
          </p>

          <div className={Styles.media_introduction}>
            <div>
              <AiFillCheckCircle className={Styles.media_introduction_icon} />
              <h3>この記事でこの3点が分かる！</h3>
            </div>
            <hr></hr>
            <ul>
              <li><span>インスタグラムの概要　（どんなもの？何ができる？）</span></li>
              <li><span>インスタグラムの使用方法　（実際どうやってやるの？）</span></li>
              <li><span>機能の紹介　（サブ機能の紹介）</span></li>
            </ul>
          </div>

          <div className={Styles.media_contents}>
            <div className={Styles.media_contents_title}>
              本記事の目次
            </div>
            <div className={Styles.media_contents_contents}>
              <ol>
                <li><AnchorLink href="#1" offset="100"><strong>インスタグラムとは？</strong></AnchorLink></li>
                <li><AnchorLink href="#2" offset="100"><strong>プロフィールの設定</strong></AnchorLink></li>
                <li><AnchorLink href="#3" offset="100"><strong>投稿のやり方</strong></AnchorLink></li>
                <li><AnchorLink href="#4" offset="100"><strong>その他の機能</strong></AnchorLink></li>
                <li><AnchorLink href="#5" offset="100"><strong>まとめ</strong></AnchorLink></li>
              </ol>
            </div>
          </div>

          <div id='1' className={Styles.media_text_title}>
            <h2>1.インスタグラムとは？</h2>
          </div>
          <p className={Styles.media_text_text}>
            <br />
            インスタグラム通称「インスタ」と呼ばれ、<strong>写真や動画の投稿に特化した世界的に人気なSNS</strong>です。<br />
            インスタグラムの楽しみ方は以下のものが挙げられます。<br />
          </p>
          <h3 className={Styles.media_text_text_title}>スマホなどで撮影した写真や動画を投稿できる</h3><br />
          <p className={Styles.media_text_text}>
            普段の生活で何気なく撮った写真や映えた写真などを気兼ねなく投稿します。<br />
            「<strong>友人に今日どんなことがあったか共有したり、思い出を残す</strong>」という理由で自分のために投稿をします。<br />
            <br />
            また、友人や自分に向けたものではなく世界中に自分を発信するために投稿している方もいます。<br />
            <strong>そういった方達はフォロワーが多く「インフルエンサー」とも呼ばれています。</strong><br />
            <br />
            <img src={Image1} className={Styles.media_img} alt='インスタグラム ホームページ' />
            参照：<a href='https://about.インスタグラム.com/ja-jp' target="_blank" rel="nofollow noopener noreferrer">
              インスタグラム ホームページ
            </a><br />
          </p>
          <h3 className={Styles.media_text_text_title}>友人や好きな有名人の投稿を閲覧する</h3><br />
          <p className={Styles.media_text_text}>
            <storng>日本の芸能人だけに留まらず世界中のスポーツ選手がインスタグラムを利用していたりしています。</storng><br />
            そんな有名人の普段見ることができないような姿をインスタグラムでは見ることができます。<br />
            <br />
            もしかしたらあなたの好きな有名人がインスタグラムで投稿しているかもしれません。ぜひそのアカウントをフォローしてみてください。フォローすると、そのアカウントの投稿が表示されあなたの目に留まりやすくなります。<br />
            <br />
            <strong>左側のホームのマークをタップするとあなたにおすすめの投稿が表示され、あなたが見たいアカウントや投稿を探すこともできます。</strong><br />
            <br />
            <img src={Image2} className={Styles.media_img} alt='' />
            参照：<a href='https://www.インスタグラム.com/watanabenaomi703/?hl=ja' target="_blank" rel="nofollow noopener noreferrer">
              渡辺 直美さんのインスタグラム
            </a><br />
          </p>
          <h3 className={Styles.media_text_text_title}>好きなジャンルの情報収集</h3><br />
          <p className={Styles.media_text_text}>
            インスタグラムには専門的なものを掲載しているアカウントもあります。<strong>美容、観光地、ファッション、グルメなど幅広いコンテンツがあり、多くの場面で情報収集するのに役立ちます</strong>。グルメの場合ではリアルな声と写真が一緒に載っておりイメージしやすいことから多くのユーザーが閲覧しています。<br />
            実際に、観光地で何を食べるのかインスタグラムで調べて決めている方も増えてきています。<br />
            <br />
            <img src={Image3} className={Styles.media_img} alt='好きなジャンルの情報収集' />
            参照：<a href='https://www.インスタグラム.com/explore/tags/%E5%A4%A7%E9%98%AA%E8%A6%B3%E5%85%89/?hl=ja' target="_blank" rel="nofollow noopener noreferrer">
              好きなジャンルの情報収集
            </a><br />
          </p>

          <div id='2' className={Styles.media_text_title}>
            <h2>2.プロフィールの設定</h2>
          </div>
          <p className={Styles.media_text_text}>
            <br />
            インスタグラムを始める上で最初に必要になってくるのは「<strong>プロフィールの設定</strong>」です。<br />
            プロフィールには<strong>「ユーザー名」「名前」「アイコン」「自己紹介欄」「フォロー」「フォロワー」</strong>の要素があります。<br />
          </p>
          <p className={Styles.media_text_text}>
            <br />
            <img src={Image4} className={Styles.media_img} alt='プロフィール設定の種類' />
            引用：<a href='https://twitter.com/nishy03/status/1726900786503045526' target="_blank" rel="nofollow noopener noreferrer">
              プロフィール設定の種類
            </a><br />
            <br />
            <strong>①	ユーザー名</strong><br />
            “@”とそれに付け足された特定の文字列で作られる、インスタグラム上のアカウント名です。アカウントを識別するものなので誰とも被らないオリジナルなユーザー名を作成する必要があります。<br />
            <br />
            <strong>②	名前</strong><br />
            ユーザー名と違い自由に設定できるのが名前です。<br />
            他のアカウントと同じ名前にしても問題ありません。<br />
            <br />
            <strong>③	アイコン</strong><br />
            アカウントのメインの写真です。不適切なものでなければ自由に設定することができます。<br />
            <br />
            <strong>④	自己紹介欄</strong><br />
            自己紹介をする欄です。こちらも自由に設定することができます。<br />
            <br />
            <strong>⑤	フォロー</strong><br />
            あなたがフォローしたアカウントの数が表示され、タップすれば、あなたがフォローしたアカウントも確認することができます。<br />
            <br />
            <strong>⑥ フォロワー</strong><br />
            あなたのことをフォローしているアカウントの数が表示され、タップすれば、あなたをフォローしているアカウントも確認することができます。<br />
          </p>

          <div id='3' className={Styles.media_text_title}>
            <h2>3.投稿のやり方</h2>
          </div>
          <p className={Styles.media_text_text}>
            <br />
            インスタグラムには投稿の種類が大きく分けて３つあります。<br />
            1つ目はインスタグラムのホーム画面に表示される「フィード投稿」<br />
            2つ目は最大90秒の動画を投稿する「リール投稿」<br />
            3つ目は24時間で写真・動画が消える「ストーリー投稿」です。<br />
            それぞれに特徴があるので一つずつ解説します！<br />
          </p>
          <h3 className={Styles.media_text_text_title}>フィード投稿</h3><br />
          <p className={Styles.media_text_text}>
            <img src={Image5} className={Styles.media_img} alt='フィード投稿の方法' />
            引用：<a href='https://twitter.com/nishy03/status/1726900786503045526' target="_blank" rel="nofollow noopener noreferrer">
              フィード投稿の方法
            </a><br />
            <br />
            <strong>1つ目</strong><br />
            1枚目の画像の中央下にある「＋」ボタンをタップ<br />
            ↓<br />
            <strong>2つ目</strong><br />
            2枚目の画像の中央下にある「投稿」をタップし、投稿したい写真を選択。<br />
            ↓<br />
            <strong>3つ目</strong><br />
            2枚目の画像右上の「次へ」をタップしたら3枚目のように必要なら写真の加工を行う<br />
            ↓<br />
            <strong>4つ目</strong><br />
            3枚目の画像右下の「次へ」をタップしたら「キャプションを入力」の欄に文章を記入<br />
            文章の中に「＃」を用いた言葉を入れると他のユーザーにその投稿が見られやすくなる<br />
            （例）「＃ガトーショコラ」「＃スイーツ」「＃カフェ」など<br />
            ↓<br />
            <strong>5つ目</strong><br />
            「タグ付け」の欄には他のアカウントを貼り付けることができ、一緒に行った友人、写っている友人のアカウントを貼るケースが多い<br />
            ↓<br />
            <strong>6つ目</strong><br />
            「場所を追加」の欄に写真を撮った場所、店を記入するとその場所、お店をインスタグラム上で検索したユーザーに表示されるようになる<br />
            ↓<br />
            <strong>7つ目</strong><br />
            「音楽を追加」の欄には自分が好きな音楽をこの投稿につけることができる。<br />
            ↓<br />
            <strong>8つ目</strong><br />
            4枚目の下にある「シェア」をタップすると投稿が完了する<br />
            <br />
            フィード投稿では最大10枚の写真・動画を投稿することができ、インスタグラム内でも最も多くの人に見られやすい投稿の方法です。ぜひ投稿してみてください！<br />
          </p>
          <h3 className={Styles.media_text_text_title}>リール投稿</h3><br />
          <p className={Styles.media_text_text}>
            <img src={Image6} className={Styles.media_img} alt='リール投稿' />
            引用：<a href='https://twitter.com/nishy03/status/1726900786503045526' target="_blank" rel="nofollow noopener noreferrer">
              リール投稿
            </a><br />
            <br />
            <strong>1つ目</strong><br />
            1枚目の画像の中央下にある「＋」ボタンをタップ<br />
            ↓<br />
            <strong>2つ目</strong><br />
            2枚目の画像の中央下にある「リール」をタップし、投稿したい写真・動画を選択。<br />
            ↓<br />
            <strong>3つ目</strong><br />
            3枚目の画像右下の「次へ」をタップしたら４枚目の画面で編集を行う<br />
            ↓<br />
            <strong>4つ目</strong><br />
            ４枚目の画像右下の「次へ」をタップしたら5枚目の「キャプションを入力」の欄に文章を記入<br />
            ↓<br />
            <strong>5つ目</strong><br />
            文章の中に「＃」を用いた言葉を入れると他のユーザーにその投稿が見られやすくなる<br />
            （例）「＃ランニング」「＃アシックス」「マラソン」など<br />
            ↓<br />
            <strong>6つ目</strong><br />
            「人物のタグ付け」の欄には他のアカウントを貼り付けることができ、一緒に行った友人、写っている友人のアカウントを貼るケースが多い<br />
            ↓<br />
            <strong>7つ目</strong><br />
            「場所を追加」の欄に動画を撮った場所、店を記入するとその場所、お店をインスタグラム上で検索したユーザーに表示されるようになる。<br />
            ↓<br />
            <strong>8つ目</strong><br />
            ５枚目の下にある「シェア」をタップすると投稿が完了する。<br />
            <br />
            <img src={Image7} className={Styles.media_img} alt='フィード投稿の具体例' />
            引用：<a href='https://twitter.com/nishy03/status/1726900786503045526' target="_blank" rel="nofollow noopener noreferrer">
              フィード投稿の具体例
            </a><br />
            <br />
            「リール投稿」は「フィード投稿」と比べて非フォロワーに対して届きやすい傾向があります。新規のフォロワーを獲得するために「リール投稿」をするユーザーの方も多くなってきています。<br />
            <br />
            リール動画は画像の下部の右から2番目の「画像マーク」をタップすると動画が表示されます。あなたがフォローしていないアカウントの動画も表示されます。TikTokや YouTubeのshortsを想像してもらえるとわかりやすいと思います。<br />
            ぜひ、リール投稿も活用してみてください！<br />
          </p>
          <h3 className={Styles.media_text_text_title}>ストーリー投稿</h3><br />
          <p className={Styles.media_text_text}>
            <img src={Image8} className={Styles.media_img} alt='フィード投稿の方法' />
            引用：<a href='https://twitter.com/nishy03/status/1726900786503045526' target="_blank" rel="nofollow noopener noreferrer">
              フィード投稿の方法
            </a><br />
            <br />
            <strong>1つ目</strong><br />
            1枚目の画像の中央下にある「＋」ボタンをタップ<br />
            ↓<br />
            <strong>2つ目</strong><br />
            2枚目の画像の中央下にある「ストーリーズ」をタップし写真を撮影、あるいは、左下の写真をタップ。そのまま写真を撮影する際に撮影ボタンのところをスライドさせると様々なエフェクトが出てきます。お気に入りのエフェクトを見つけてみてください。<br />
            ↓<br />
            <strong>3つ目</strong><br />
            2枚目の写真をタップした後、3枚目のように投稿したい動画を選択<br />
            ↓<br />
            <strong>4つ目</strong><br />
            4枚目の右上には「テキスト」「スタンプ」「音楽」「エフェクト」をつけることのできるボタンがありお好みに編集してみてください。編集した後に左下のストーリーズをタップすると投稿は完了です。右下の「親しい友達」をタップすると公開するアカウントを制限することができます。<br />
            ↓<br />
            <strong>5つ目</strong><br />
            ５枚目の下にある「シェア」をタップすると投稿が完了する<br />
            <img src={Image7} className={Styles.media_img} alt='リール投稿' />
            引用：<a href='https://twitter.com/nishy03/status/1726900786503045526' target="_blank" rel="nofollow noopener noreferrer">
              リール投稿
            </a><br />
            <br />
            ストーリー投稿は24時間で投稿が消えるので投稿するちょっとしたハードルも低く、多くのユーザーに使われている機能です。「フィード投稿」「リール投稿」はしないけど「ストーリー投稿」はする、というユーザーの方も多いです。<br />
            <br />
            フォローしているアカウントのストーリーはインスタグラムのホームの上部に表示されます。あなたの投稿したストーリーもここに表示され、基本的にはあなたのことをフォローしているアカウントからしか閲覧されません。気軽に投稿してみてください！<br />
          </p>

          <div id='4' className={Styles.media_text_title}>
            <h2>4.その他の機能</h2>
          </div>
          <p className={Styles.media_text_text}>
            <br />
            インスタグラムには他にも様々な機能があります。<br />
            ・<strong>DM（ダイレクトメール）</strong><br />
            ・<strong>いいね、コメント</strong><br />
            ・<strong>共有</strong><br />
          </p>
          <h3 className={Styles.media_text_text_title}>DM（ダイレクトメッセージ）</h3><br />
          <p className={Styles.media_text_text}>
            <strong>インスタグラムのDMは他のアカウントとLINEのようにやりとりをすることができます。</strong>相手のアカウントの「フォロー中」あるいは「フォロー」の右の「メッセージ」から連絡をすることができます。<br />
            <br />
            ただ、一方的にしか知らない人に対してしつこく連絡を送るのは迷惑になってしまう恐れがあるので注意しください。<br />
          </p>
          <h3 className={Styles.media_text_text_title}>いいね、コメント</h3><br />
          <p className={Styles.media_text_text}>
            <strong>インスタグラムには「いいね」と「コメント」の機能があります。</strong>投稿された写真・動画を2度タップするか写真・動画の下にあるハートのボタンをタップすると「いいね」送ることができます。様々な投稿に「いいね」を送るとインスタグラム側があなたの好みを理解してあなたにおすすめの投稿を表示してくれるようになります。<br />
            <br />
            ハートマークの右側にあるコメントマークをタップするとその投稿に対してコメントを送ることができます。投稿したユーザーを傷つけるような誹謗中傷は避け、楽しんでコメント機能を使ってください。<br />
          </p>
          <h3 className={Styles.media_text_text_title}>共有</h3><br />
          <p className={Styles.media_text_text}>
            <strong>投稿された写真・動画の下にある「紙飛行機マーク」をタップすると「ストーリーズに追加」「シェア」「リンクをコピー」など様々な共有方法がでてきます。</strong><br />
            <br />
            自分の投稿含め様々な投稿をストーリーやインスタグラム外のところに共有することができます。ぜひ活用してみてください。<br />
          </p>

          <div id='5' className={Styles.media_text_title}>
            <h2>5.まとめ</h2>
          </div>
          <p className={Styles.media_text_text}>
            <br />
            インスタグラムについてなんとなくは理解することはできましたか？<br />
            ここでは紹介しきれなかった機能がインスタグラムには多くあります。<br />
            ただ、基本から覚えないと頭の中が混乱してしまうのでここで紹介した機能から使ってみてください。徐々にインスタグラムをもっと使いこなせるようになると思います。<br />
            <br />
            ぜひ活用してみてください！<br />
          </p>
          
        </div>
      </div>
    </DocumentMeta>
    </>
  );
}


export default Article0001;