import React from 'react';
import Styles from './styles.module.css';

type RecruitEmployeeProps = {
  employmentStatus: string;
  monthlySalaryStart: string;
  annualIncomeStart: string;
  annualIncomeEnd: string;
  overtime: boolean;
  overtimeOther: string;
  welfare: string[];
  welfareCheck: boolean;
  welfareOther: string;
  salaryWelfare: string;
  holiday: string[];
  holidayCheck: boolean;
  holidayOther: string;
  workingHoursStart: string;
  workingHoursEnd: string;
};

const RecruitEmployee: React.FC<RecruitEmployeeProps> = ({ employmentStatus, monthlySalaryStart,annualIncomeStart,annualIncomeEnd,overtime,overtimeOther,welfare,welfareCheck,welfareOther,salaryWelfare,holiday,holidayCheck,holidayOther,workingHoursStart,workingHoursEnd, }) => {

  return (
    <>
    <div className={Styles.content}>
      <h4>募集内容</h4>
      <table className={Styles.table}>
        <tbody>
          <tr>
            <th style={{width : '150px', backgroundColor : '#FFEFF1'}}><strong>雇用形態</strong></th>
            <td><p>{employmentStatus}</p></td>
          </tr>
          <tr>
            <th style={{width : '150px', backgroundColor : '#FFEFF1'}}><strong>給与</strong></th>
            <td><p>
              月給：{monthlySalaryStart}万円<br />
              想定年収：{annualIncomeStart}万円~{annualIncomeEnd}万円<br />
            </p></td>
          </tr>
          {overtime ? 
            <tr>
              <th style={{width : '150px', backgroundColor : '#FFEFF1'}}><strong>みなし残業代</strong></th>
              <td><p>
                {overtime ? overtimeOther : ''}
              </p></td>
            </tr>
          : <></> }
          {welfare.length === 0 ? <></> :
            <tr>
              <th style={{width : '150px', backgroundColor : '#FFEFF1'}}><strong>福利厚生</strong></th>
              <td><p>
                {welfare.map((data, index) => (
                  <React.Fragment key={index}>
                    {data}、
                  </React.Fragment>
                ))}
                {welfareCheck ? `${welfareOther}` : ""}
              </p></td>
            </tr>
          }
          {salaryWelfare === "" ? <></> :
            <tr>
              <th style={{width : '150px', backgroundColor : '#FFEFF1'}}><strong>給与体系・福利厚生に関する備考</strong></th>
              <td><p>
                {salaryWelfare}
              </p></td>
            </tr>
          }
          {holiday.length === 0 ? <></> :
            <tr>
              <th style={{width : '150px', backgroundColor : '#FFEFF1'}}><strong>休日・休暇</strong></th>
              <td><p>
                {holiday.map((data, index) => (
                  <React.Fragment key={index}>
                    {data}、
                  </React.Fragment>
                ))}
                {holidayCheck ? `${holidayOther}` : ""}</p></td>
            </tr>
          }
          <tr>
            <th style={{width : '150px', backgroundColor : '#FFEFF1'}}><strong>勤務時間</strong></th>
            <td><p>{workingHoursStart}~{workingHoursEnd}</p></td>
          </tr>
        </tbody>
      </table>
    </div>
    </>
  );
}

export default RecruitEmployee;
