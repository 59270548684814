import Styles from './styles.module.css';
import firebase from 'firebase/compat/app';
import React, { useState, useEffect } from 'react';
import { FaChevronRight } from 'react-icons/fa';
import { BsFillPersonFill } from 'react-icons/bs';
import { MdContentPasteSearch } from 'react-icons/md';
import { FiMail } from 'react-icons/fi';
import { FaPlus } from "react-icons/fa";
import { BiMessageDetail } from "react-icons/bi";
import { useNavigate } from 'react-router-dom';
import Image1 from './../../../image/employment/1.png';
import Image2 from './../../../image/employment/2.png';
import Image3 from './../../../image/employment/3.png';
import Image4 from './../../../image/employment/4.png';
import Image5 from './../../../image/employment/5.png';
import Image6 from './../../../image/employment/6.png';
import NewGraduate from './../../../image/employment/1.png';
// import Details from '../../package/info/index';

import { fetchRecruits } from '../../../features/1.home/recruitdata';
import { fetchApplicants } from '../../../features/1.home/recruitdata';

import { useDispatch, useSelector } from 'react-redux';

import { fetchDetails } from '../../../features/package/detailsPanel';
import { fetchRecruit } from '../../../features/package/detailsPanel';
import { fetchApplicant } from '../../../features/package/detailsPanel';

const HomeRecruit: React.FC = () => {

  const navigate = useNavigate();

  const recruitIds = useSelector((state: any) => state.recruit.recruitIds);
  const company = useSelector((state: any) => state.company.company);

  const dispatch = useDispatch();

  const [ recruitList, setRecruitList ] = useState<any[]>([]);
  const [ applicantsList, setApplicantsList ] = useState<any[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      
      const recruits = await dispatch(fetchRecruits(recruitIds));

      if (fetchRecruits.fulfilled.match(recruits)) {
        const recruitsData = recruits.payload;
        setRecruitList(recruitsData.recruits);

        const actionResult = await dispatch(fetchApplicants(recruitsData.applicants));
      
        if (fetchApplicants.fulfilled.match(actionResult)) {
          const applicants = actionResult.payload;
          setApplicantsList(applicants);
        }
      }
    }
    fetchData();
  }, [dispatch]);

  return (
    <>
    <div className={Styles.recruit}>
      <h4 className={Styles.recruit_title}>採用状況の進捗</h4>

      {recruitList.map((data, index) =>
      <>
        {data.status === 1 ?
        <>
          <div className={Styles.recruit_box_title}>
            <img src={
              data.recruit_employmentStatus === '新卒採用（正社員）' ? Image1 :
              data.recruit_employmentStatus === '中途採用（正社員）' ? Image2 :
              data.recruit_employmentStatus === '中途採用（契約社員）' ? Image3 :
              data.recruit_employmentStatus === 'アルバイト・パート採用' ? Image4 :
              data.recruit_employmentStatus === '業務委託（フリーランス）' ? Image5 : Image6
            } />
            <h4>{data.title}</h4>
          </div>
          
          <div className={Styles.recruit_box_data}>
            <div className={Styles.recruit_box_data_before}>
              <div className={Styles.recruit_box_data_1}>
                <BsFillPersonFill className={Styles.recruit_box_data_1_icon} />
                <h4>PV数</h4>
                <h3>{data?.pv}人</h3>
              </div>
              <FaPlus className={Styles.recruit_box_data_between_icon} />
              <div className={Styles.recruit_box_data_1}>
                <MdContentPasteSearch className={Styles.recruit_box_data_1_icon} />
                <h4>スカウト数</h4>
                <h3>-</h3>
              </div>
              <FaChevronRight className={Styles.recruit_box_data_between_icon} />
              <div className={Styles.recruit_box_data_1}>
                <FiMail className={Styles.recruit_box_data_1_icon} />
                <h4>応募者数</h4>
                <h3>{data?.applicants}人</h3>
              </div>
              <FaChevronRight className={Styles.recruit_box_data_between_icon} />
              <div className={Styles.recruit_box_data_2}>
                <BsFillPersonFill className={Styles.recruit_box_data_2_icon} />
                <h4>採用数</h4>
                <h3>{data?.recruitment}人</h3>
              </div>
            </div>
            <div className={Styles.recruit_box_data_3}>
              <BsFillPersonFill className={Styles.recruit_box_data_3_icon} />
              <h4>目標人数</h4>
              <h3>{data?.goal}人</h3>
            </div>
          </div>

          {data.apply !== 0 && applicantsList.length !== 0 ?
          <>
            <h4 className={Styles.new_title} key={index}>New 新規応募者！</h4>
              <div className={Styles.new}>
                <div className={Styles.new_table_title}>
                  <h3>応募者</h3>
                  <h4>性別</h4>
                  <h5>応募日時</h5>
                  <div></div>
                </div>
                {applicantsList.map((app, index) =>
                <>
                  {data.id === app.recruitId ?
                    <div className={Styles.new_table_data} 
                      onClick={() => {
                        dispatch(fetchDetails(true)); dispatch(fetchRecruit(app.recruitId)); dispatch(fetchApplicant(app.id));}}>
                      <div>
                        <img src={NewGraduate} />
                        <h3>{app.name}</h3>
                      </div>
                      <h4>{app.sex}</h4>
                      <h5>{new Date(app.time.seconds * 1000).getFullYear()}年{new Date(app.time.seconds * 1000).getMonth()+1}月{new Date(app.time.seconds * 1000).getDate()}日</h5>
                      <BiMessageDetail className={Styles.new_table_data_icon}
                        onClick={() => {navigate(`/works/service/id=${company.id}+page=6+recruit=${app.recruitId}+applicant=${app.id}`);}}
                      />
                    </div>
                  : <></> }
                </> )}
            </div>
          </> : <></> }

          <div style={{height : '30px'}}></div>
        </> : <></> }
      </> )}
    </div>
    </>
  );
}

export default HomeRecruit;
