import Styles from './styles.module.css';
import 'firebase/auth'
import React from 'react';
import { useState, useEffect } from 'react';
import firebase from "firebase/compat/app";
import { useNavigate } from 'react-router-dom';
import ErrorMessage from '../../../../package/parts/error/index';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import BackModal from '../12.back/index';
import Header from '../0.header/index';

const RecruitHashTags: React.FC = () => {

  const navigate = useNavigate();

  const [ errorMessage, setErrorMessage ] = useState('');
  
  const link: string = new URL(window.location.href).href;
  const recruitLink = decodeURIComponent(link.slice(link.indexOf('id=') + 3, link.length));

  const [ hashTags1, setHashTags1 ] = useState<string[]>([]);
  const [ hashTags2, setHashTags2 ] = useState<string[]>([]);
  const [ hashTags3, setHashTags3 ] = useState<string[]>([]);

  var 教育体制の特徴 = [
    'デビューまで2年以内',
    'SNS研修あり',
    '自社アカデミーあり',
    '動画研修制度あり',
    'セミナー参加費補助',
    'ウィッグ代補助',
    '奨学金返済サポートあり',
    '営業中の練習OK',
    '資格取得支援',
    '社内コンテストあり',
    '複数の教育体制から選択可',
    '複数のキャリアプランを選択可',
    '独立・開業支援あり',
    '海外研修あり',
  ]

  var 給与待遇福利厚生の特徴 = [
    '社会保険完備',
    'ボーナス支給実績あり',
    '寮・社宅あり',
    '住宅手当あり',
    '育児休暇あり',
    '車・バイク出勤可',
  ]

  var 働き方の特徴 = [
    '年間休日120日以上',
    '完全週休2日制',
    '残業月20時間以内',
    '20時までに退勤可能',
    '有給休暇消化率90%以上',
    '配属店舗の選択可',
    '時短勤務可',
  ]
    
  useEffect(() => {
    window.scrollTo(0, 0);
    return onAuthStateChanged(getAuth(), (user) => {
      if (user) {
        firebase.firestore().collection("recruits").doc(recruitLink)
        .get().then((doc) => {
          const data = doc.data();
          if (data) {
            setHashTags1(data.recruit_hashTag1);
            setHashTags2(data.recruit_hashTag2);
            setHashTags3(data.recruit_hashTag3);
          }
        });
      } else {
        navigate('/company/login')
      }
    });
  }, [link])

  function check() {
    window.scrollTo(0, 0);

    if (hashTags1.length === 0 || hashTags2.length === 0 || hashTags3.length === 0) {
      setErrorMessage('ハッシュタグを3つ以上選択してください。');
    } else {
      save(true);
    }
  }

  function save(e: boolean) {
    firebase.firestore().collection("recruits").doc(recruitLink)
    .update({
      status_hashTags: e,

      recruit_hashTag: [ ...hashTags1, ...hashTags2, ...hashTags3, ],

      recruit_hashTag1: hashTags1,
      recruit_hashTag2: hashTags2,
      recruit_hashTag3: hashTags3,

      last_time: new Date(),
    }).then(() => {
      navigate(`/edit/id=${recruitLink}`);
    });
  }

  function pushStyle1(value: string) {
    setHashTags1([...hashTags1, value])
  }

  function pushStyle2(value: string) {
    setHashTags2([...hashTags2, value])
  }

  function pushStyle3(value: string) {
    setHashTags3([...hashTags3, value])
  }

  const [ backStatus, setBackStatus ] = useState(false);

  return (
    <>

    <Header />

    <div className={Styles.box}>
      <div className={Styles.box_inline}>

        {errorMessage !== '' ?
          <ErrorMessage message={errorMessage} />
        : <></> }

        <div className={Styles.contents}>

          <h5 className={Styles.title}>教育体制の特徴</h5>
          <div className={Styles.numberTag}>
            {教育体制の特徴.map((data, index) =>
              <div key={index}>
                <input type="checkbox"
                  checked={hashTags1.indexOf(data) !== -1}
                  onChange={(e) => e.target.checked ? pushStyle1(data) : setHashTags1(hashTags1.filter((v, index) => (v !== data)))}/>
                <p>{data}</p>
              </div>
            )}
          </div>

        </div>

        <div className={Styles.contents}>

          <h5 className={Styles.title}>給与・待遇・福利厚生の特徴</h5>
          <div className={Styles.numberTag}>
            {給与待遇福利厚生の特徴.map((data, index) =>
              <div key={index}>
                <input type="checkbox"
                  checked={hashTags2.indexOf(data) !== -1}
                  onChange={(e) => e.target.checked ? pushStyle2(data) : setHashTags2(hashTags2.filter((v, index) => (v !== data)))}/>
                <p>{data}</p>
              </div>
            )}
          </div>

        </div>

        <div className={Styles.contents}>

          <h5 className={Styles.title}>働き方の特徴</h5>
          <div className={Styles.numberTag}>
            {働き方の特徴.map((data, index) =>
              <div key={index}>
                <input type="checkbox"
                  checked={hashTags3.indexOf(data) !== -1}
                  onChange={(e) => e.target.checked ? pushStyle3(data) : setHashTags3(hashTags3.filter((v, index) => (v !== data)))}/>
                <p>{data}</p>
              </div>
            )}
          </div>

        </div>

        <div className={Styles.save_box}>
          <button className={Styles.save_box_now} onClick={() => setBackStatus(true)}>戻る</button>
          <button className={Styles.save_box_now} onClick={() => save(false)}>一時保存</button>
          <button className={Styles.save_box_save} onClick={() => check()}>保存</button>
        </div>
        
      </div>
    </div>

    {backStatus ?
      <BackModal setBackStatus={setBackStatus} />
    : <></> }
    </>
  );
}

export default RecruitHashTags;