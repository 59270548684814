import { Timestamp } from '@google-cloud/firestore';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import firebase from "firebase/compat/app";

export const fetchAnalytics = createAsyncThunk(
  'analytics/fetchAnalytics',
  (list: any[]) => {
    const years = new Date().getFullYear();
    const month = new Date().getMonth();
    const day = new Date().getDate() + 1;

    const date = Array.from({ length: 31 }, (_, i) => {
      return new Date(years, month, day - i).toISOString();;
    });

    const standardDate = date.slice(1);

    return standardDate;
  }
);

export const getRecruitDate = createAsyncThunk(
  'analytics/getRecruitDate',
  async ({ recruitList, date } : { recruitList: string[]; date: string[] }) => {
    
    const pvTmp: Date[] = [];
    const saveTmp: Date[] = [];
    const applyTmp: Date[] = [];

    for (const recruit of recruitList) {
      const doc = await firebase.firestore().collection('recruits').doc(recruit).get();
      const dataPv = doc.data()?.data_pv || [];
      const dataSave = doc.data()?.data_saveTime || [];
      const dataApply = doc.data()?.data_apply || [];

      dataPv.forEach((data: Timestamp) => {
        pvTmp.push(new Date(data.seconds * 1000 + data.nanoseconds / 1000000));
      });
      dataSave.forEach((data: Timestamp) => {
        saveTmp.push(new Date(data.seconds * 1000 + data.nanoseconds / 1000000));
      });
      dataApply.forEach((data: Timestamp) => {
        applyTmp.push(new Date(data.seconds * 1000 + data.nanoseconds / 1000000));
      });
    };
    
    return { pvTmp, saveTmp, applyTmp, date };
  }
);

interface AnalyticsState {
  analyticsPvCount: number[];
  analyticsSaveCount: number[];
  analyticsApplyCount: number[];
  analyticsMemory1: number;
  analyticsMemory2: number;
  analyticsMemory3: number;
  standardDate: string[];
}

const initialState: AnalyticsState = {
  analyticsPvCount: [],
  analyticsSaveCount: [],
  analyticsApplyCount: [],
  analyticsMemory1: 0,
  analyticsMemory2: 0,
  analyticsMemory3: 0,
  standardDate: [],
};

const analyticsSlice = createSlice({
  name: 'analytics',
  initialState,
  reducers: {
    setAnalyticsPvCount(state, action: PayloadAction<number[]>) {
      state.analyticsPvCount = action.payload;
    },
    setAnalyticsSaveCount(state, action: PayloadAction<number[]>) {
      state.analyticsSaveCount = action.payload;
    },
    setAnalyticsApplyCount(state, action: PayloadAction<number[]>) {
      state.analyticsApplyCount = action.payload;
    },
    setAnalyticsMemory1(state, action: PayloadAction<number>) {
      state.analyticsMemory1 = action.payload;
    },
    setAnalyticsMemory2(state, action: PayloadAction<number>) {
      state.analyticsMemory2 = action.payload;
    },
    setAnalyticsMemory3(state, action: PayloadAction<number>) {
      state.analyticsMemory3 = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAnalytics.fulfilled, (state, action) => {
        state.standardDate = action.payload;
      })
      .addCase(getRecruitDate.fulfilled, (state, action) => {
        const { pvTmp, saveTmp, applyTmp, date } = action.payload;
        calcAnalytics(1, pvTmp, date, state);
        calcAnalytics(2, saveTmp, date, state);
        calcAnalytics(3, applyTmp, date, state);
      });
  },
});

const calcAnalytics = (number: number, data: Date[], date: any[], state: AnalyticsState) => {

  const counts = Array(30).fill(0);
  
  for (let j = 0; j < 30; j++) {
    const BeforeDate: Date = new Date(date[j]);
    const afterDate: Date = new Date(date[j + 1]);
    for (let i = 0; i < data.length; i++) {
      if (BeforeDate >= data[i] && data[i] > afterDate) {
        counts[j]++;
      }
    };
  };

  if (number === 1) {
    state.analyticsPvCount = counts;
    const max = Math.max(...counts);
    const memory = Math.ceil(max / 6) * 6;
    state.analyticsMemory1 = memory;
  }

  if (number === 2) {
    state.analyticsSaveCount = counts;
    const max = Math.max(...counts);
    const memory = Math.ceil(max / 6) * 6;
    state.analyticsMemory2 = memory;
  }

  if (number === 3) {
    state.analyticsApplyCount = counts;
    const max = Math.max(...counts);
    const memory = Math.ceil(max / 6) * 6;
    state.analyticsMemory3 = memory;
  }
};

export const { 
  setAnalyticsPvCount,
  setAnalyticsSaveCount,
  setAnalyticsApplyCount,
  setAnalyticsMemory1,
  setAnalyticsMemory2,
  setAnalyticsMemory3
} = analyticsSlice.actions;

export default analyticsSlice.reducer;
