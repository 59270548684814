import DocumentMeta from 'react-document-meta';
import Styles from '../styles.module.css';
import React from 'react';
import { AiFillCheckCircle } from "react-icons/ai";
import AnchorLink from 'react-anchor-link-smooth-scroll';
import Icon from './../../image/media/1/top.png';
import Image from './../../image/media/4/top.png';
import Image1 from './../../image/media/4/1.png';
import Image2 from './../../image/media/4/2.png';
import Image5 from './../../image/media/4/5.png';

const Article0001 = () => {

  const meta = {
    title: '【X(元Twitter)】美容専門アカウントの作り方-美容学生',
    description: '現在、世界中の人々がX(旧Twitter)を利用しています。Xは文章と画像や動画を組み合わせて投稿することが出来、美容学生の情報発信におすすめのSNSです。この記事では美容学生向けに「X」を最大有効活用する方法を紹介します。「X」を上手に活用して、世界中に自分が持つ情報を発信していきましょう！',
    canonical: 'https://memorii-works.com/',
    meta: { charset: 'utf-8', }
  };

  return (
    <>
    <DocumentMeta {...meta}>
      <div className={Styles.media_nav}>
        <a className={Styles.media_nav_icon} href='https://trankllc.com/media/subsidy/business/0000'>
          <img src={Icon} loading="lazy" alt='memorii' />
          <h2>美容学生の教科書</h2>
        </a>
        <a className={Styles.media_nav_contact} href='https://trankllc.com/contact'>
          <p>問い合わせ</p>
        </a>
      </div>
      <div className={Styles.media}>
        <div className={Styles.media_article}>
          
          <p className={Styles.media_date}>2024/04/20 更新日: -</p>
          <h1 className={Styles.media_title}>【X(元Twitter)】美容専門アカウントの作り方-美容学生</h1>
          <img src={Image} className={Styles.media_title_img} alt='美容 Twitter(X)' />
    
          <p className={Styles.media_text_text}>
            <br />
            現在、世界中の人々がX(旧Twitter)を利用しています。<br />
            Xは文章と画像や動画を組み合わせて投稿することが出来、美容学生の情報発信におすすめのSNSです。<br />
            <br />
            この記事では美容学生向けに「X」を最大有効活用する方法を紹介します。<br />
            「X」を上手に活用して、世界中に自分が持つ情報を発信していきましょう！<br />
          </p>
    
          <div className={Styles.media_introduction}>
            <div>
              <AiFillCheckCircle className={Styles.media_introduction_icon} />
              <h3>この記事でこの3点が分かる！</h3>
            </div>
            <hr></hr>
            <ul>
              <li><span>美容専門アカウントの作り方</span></li>
              <li><span>Xの投稿を多くの人に見てもらう方法</span></li>
              <li><span>分かりやすい投稿にするための工夫</span></li>
            </ul>
          </div>
    
          <div className={Styles.media_contents}>
            <div className={Styles.media_contents_title}>
              本記事の目次
            </div>
            <div className={Styles.media_contents_contents}>
              <ol>
                <li><AnchorLink href="#1" offset="100"><strong>専門アカウントについて</strong></AnchorLink></li>
                <li><AnchorLink href="#2" offset="100"><strong>多くの人に見てもらう投稿にするためには？</strong></AnchorLink></li>
                <li><AnchorLink href="#3" offset="100"><strong>分かりやすい投稿にする方法</strong></AnchorLink></li>
                <li><AnchorLink href="#4" offset="100"><strong>まとめ</strong></AnchorLink></li>
              </ol>
            </div>
          </div>
    
          <div id='1' className={Styles.media_text_title}>
            <h2>1.専門アカウントについて</h2>
          </div>
          <h3 className={Styles.media_text_text_title}>専用アカウントを作る理由</h3><br />
          <p className={Styles.media_text_text}>
            「X」を有効活用するために<strong>専用アカウントの作成</strong>をまずは行いましょう。<br />
            「X」には多くのユーザーがおり、さまざまな情報に溢れていますが、1人1人が「X」内の情報を全て見ることはありません。<br />
            他のSNSでも同じですが、SNSでは興味関心のある情報しか基本的には目にしません。<br />
            <br />
            そのため、この人は<strong>自分が興味のある情報を発信していると思ってもらう</strong>ことが、フォロワー数を増やすうえで重要になります。<br />
            「美容といえば○○」と言ってもらえるような、美容に関して一貫性のある投稿を行い、多くのフォロワーを獲得しましょう。<br />
            <br />
            また、<strong>美容にもヘアカットやコスメなど色々なジャンルがありますが、ジャンルを絞った投稿を行った方が効果的に伝えることが出来ます。</strong><br />
            <br />
            <img src={Image1} className={Styles.media_img} alt='美容 Twitter X' />
            参照：<a href='https://twitter.com/m_iiikokoko' target="_blank" rel="nofollow noopener noreferrer">
              mee|垢抜けメイク|ノウハウ|美容アイテム さんのX
            </a><br />
            参照：<a href='https://twitter.com/ikkun_78' target="_blank" rel="nofollow noopener noreferrer">
              小宮 郁巳 (いっくん) さんのX
            </a><br />
            参照：<a href='https://twitter.com/colorcle_' target="_blank" rel="nofollow noopener noreferrer">
              カラクルちゃん さんのX
            </a><br />
            <br />
            皆さん個人で活動をしているアカウントですが、専門的な一貫性のある情報を発信することで多くのフォロワーを獲得しています。<br />
            あなたの得意な分野を定め、一貫性のある投稿をしていきましょう！<br />
          </p>

          <h3 className={Styles.media_text_text_title}>ターゲットの選定</h3><br />
          <p className={Styles.media_text_text}>
            投稿をする内容が決まったら、次はターゲットを決めていきましょう。<br />
            例えば、あなたが美容関連の投稿をしようとした時に、どんな人があなたの投稿を見ると思いますか？<br />
            <br />
            ・年齢<br />
            ・性別<br />
            ・職業<br />
            ・趣味、特技<br />
            ・ライフスタイル<br />
            <br />
            <strong>こういった特徴を考えながら、あなたのターゲットの具体像を考えてみましょう。</strong><br />
            例えば、メイクに関する投稿をよくチェックする人たちはこのようなターゲットであると考えられます。<br />
            <br />
            ・10代〜30代<br />
            ・女性<br />
            ・学生、OL<br />
            ・カフェ巡りやお出かけ<br />
            ・日中はお仕事、学校<br />
            <br />
            では、<strong>この人たちにどのような投稿をすれば、興味を持ってもらえるでしょうか？</strong><br />
            それらを踏まえたうえで、以下のような投稿をすることでフォロワー数を伸ばしていくことが出来ると考えられます。<br />
            <br />
            ・最先端美容など、若者ウケする投稿<br />
            ・骨格診断やパーソナルカラーなど、女性が興味を持つ投稿<br />
            ・学生や社会人1～2年目に向けた、コスパがいい商品の紹介<br />
            ・カフェ巡りやお出かけなど、娯楽に関係する美容に関する投稿<br />
            ・ターゲットがスマホを見ている時間に投稿︎（朝の通学通勤の時間、昼休みの時間、家に帰ってきてからのリラックスしている時間）<br />
            <br />
            このように投稿の内容をどんどん具体的にすることが大切です。<br />
            実際に美容系の投稿をしている方の投稿を見てみましょう。<br />
          </p>
          <h3 className={Styles.media_text_text_title}>実際のX活用例</h3><br />
          <p className={Styles.media_text_text}>
            <img src={Image2} className={Styles.media_img} alt='美容 Twitter X' />
            参照：<a href='https://twitter.com/minamininaritai?s=20' target="_blank" rel="nofollow noopener noreferrer">
            OLちゃん/美容オタク さんのX
            </a><br />
            <br />
            OLちゃんの投稿は、上記のように化粧品をはじめとした美容専門の投稿を行っています。<br />
            <strong>では、このアカウントがどのようなターゲットを対象としているのか考えてみましょう。</strong><br />
            <br />
            ・20～30代<br />
            ・女性<br />
            ・綺麗になりたい働く女性<br />
            ・自分磨き<br />
            ・日中はお仕事<br />
            <br />
            おそらくこのような感じなのではないでしょうか。<br />
            フォロワーのアカウントを見てみると、若い働く女性が多いように感じます。<br />
            また、そのフォロワー投稿を見てみると美容に関する投稿をしていることから、上記のようなターゲット層に見事に刺さっていると言えます。<br />
            <br />
            また、OLちゃんさんは夜に投稿をしており、仕事終わりの方向けに投稿をしていることが考えられます。<br />
            このように、ターゲットを絞って的確に狙い撃ちするこの運用は参考になる点が多いと思います。<br />
            ぜひ参考にしてみてください！<br />
          </p>
          
          <div id='3' className={Styles.media_text_title}>
            <h2>3.分かりやすい投稿にする方法</h2>
          </div>
          <h3 className={Styles.media_text_text_title}>写真を積極的に使う！</h3><br />
          <p className={Styles.media_text_text}>
            <img src={Image5} className={Styles.media_img} alt='美容 Twitter X' />
            参照：<a href='https://twitter.com/NASA/status/1746912055947345938' target="_blank" rel="nofollow noopener noreferrer">
            NASA さんのX
            </a><br />
            <br />
            「X」は、文章だけでなく写真や動画も一緒に投稿することができます。<br />
            やはり文章だけでは印象に残りづらいことが多いです。先ほど紹介したOLちゃんさんも必ず写真と一緒に投稿しています。<br />
            <br />
            <strong>SNS運用において、ユーザーの印象に残ることは非常に大事です。どんなにいい情報を投稿していてもユーザーの目に止まらなければ意味がありません。</strong><br />
            パッとみた時にいかに簡単に伝わるかが非常に大事なため、発信する際は投稿の見栄えも意識するといいでしょう。<br />
          </p>

          <h3 className={Styles.media_text_text_title}>運用を焦りすぎない！</h3><br />
          <p className={Styles.media_text_text}>
            SNS運用は難しく、フォロワーが爆発的に増えることはまずありません。<br />
            「X」は他のSNSより共有性が高く、アカウントも伸びやすいとされていますが、一時的な「ブーム」のようなことが多くみられるのも「X」の特徴です。<br />
            <strong>伸びやすいが、飽きられやすいSNSであることを意識して運用した方がいいでしょう。</strong><br />
            <br />
            一方で、ユーザーに飽きられずに程よい距離感を保つためにはコツコツやるしかありません。<br />
            「X」の運用とはバズることが目的ではなく長期的に多くの人に見られることが目的です。<br />
            そのためには、<strong>長期的かつ安定的な投稿と、アカウント運用が必要となります。</strong><br />
            <br />
            「美容」のような少しニッチな分野では、なおさらその意識が必要となります。<br />
            バズりやすい「踊ってみた」や「料理」は関わるユーザーが多いためバズりやすいのです。<br />
            <strong>ニッチな分野は一人一人のユーザーが非常に貴重なので嫌われず、飽きられず、ずっと愛されるアカウントの構築が非常に重要です。</strong><br />
            焦らずに、コツコツとやっていきましょう<br />
          </p>

          <div id='4' className={Styles.media_text_title}>
            <h2>4.まとめ</h2>
          </div>
          <br />
          <p className={Styles.media_text_text}>
            「X」の運用に向けてやるべきことを理解することはできましたか？<br />
            運用を始める前からやるべきことが多くあるので、突発的に始めるのではなく余裕を持って始めましょう。<br />
            <strong>また、アカウントはすぐに伸びるものではありません。 コツコツと、自分のペースで焦らず頑張ってみてください！</strong><br />
          </p>
        </div>
      </div>
    </DocumentMeta>
    </>
  );
}


export default Article0001;
