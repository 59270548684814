import React from 'react';
import Styles from './styles.module.css';

type DefaultInput2Props = {
  title: string,
  required: Boolean,
  value1: number,
  setValue1: React.Dispatch<React.SetStateAction<number>>;
  value2: number,
  setValue2: React.Dispatch<React.SetStateAction<number>>;
  unit: string,
  desc: string,
};

const DefaultInput2: React.FC<DefaultInput2Props> = ({title, required, value1, setValue1, value2, setValue2, unit, desc, }) => {

  return (
    <>
    <div className={Styles.title}>
      <div>
        <p>{title}</p>
        <div className={ required ? Styles.title_yes : Styles.title_no }>{required ? '※必須' : '任意'}</div>
      </div>
      <p></p>
    </div>
    <div className={Styles.input}>
      <div>
        <input placeholder='' type='number' value={value1} onChange={(e) => setValue1(Number(e.target.value))} />
        <p>{unit}</p>
      </div>
      <p>〜</p>
      <div>
        <input placeholder='' type='number' value={value2} onChange={(e) => setValue2(Number(e.target.value))} />
        <p>{unit}</p>
      </div>
    </div>
    <p className={Styles.desc}>{desc}</p>
    </>
  );
}

export default DefaultInput2;
