import Styles from './styles.module.css';
import React from 'react';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../0.header/index';
import firebase from "firebase/compat/app"
import ErrorMessage from '../../../../package/parts/error/index';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import DefaultInput1 from '../../../../package/parts/1-input/index';
import DefaultTextarea1 from '../../../../package/parts/1-textarea/index';
import DefaultImage from './../../../../image/default/wait.png';
import BackModal from '../12.back/index';
import DefaultTitle from '../../../../package/parts/title/index';

const RecruitInterview: React.FC = () => {
  
  const navigate = useNavigate();

  // リンク
  const link: string = new URL(window.location.href).href;
  const recruitLink = decodeURIComponent(link.slice(link.indexOf('id=') + 3, link.length));

  const [ errorMessage, setErrorMessage ] = useState('');

  const [ interviewCount, setiInterviewCount ] = useState(0);

  const [ interview1Name, setInterview1Name ] = useState<string | number>('');
  const [ interview1Title, setInterview1Title ] = useState<string | number>('');
  const [ interview1Desc, setInterview1Desc ] = useState<string>('');
  const [ interview1Image, setInterview1Image ] = useState(false);

  const [ interview2Name, setInterview2Name ] = useState<string | number>('');
  const [ interview2Title, setInterview2Title ] = useState<string | number>('');
  const [ interview2Desc, setInterview2Desc ] = useState<string>('');
  const [ interview2Image, setInterview2Image ] = useState(false);

  const [ interview3Name, setInterview3Name ] = useState<string | number>('');
  const [ interview3Title, setInterview3Title ] = useState<string | number>('');
  const [ interview3Desc, setInterview3Desc ] = useState<string>('');
  const [ interview3Image, setInterview3Image ] = useState(false);

  const [ interview4Name, setInterview4Name ] = useState<string | number>('');
  const [ interview4Title, setInterview4Title ] = useState<string | number>('');
  const [ interview4Desc, setInterview4Desc ] = useState<string>('');
  const [ interview4Image, setInterview4Image ] = useState(false);

  const [ interview5Name, setInterview5Name ] = useState<string | number>('');
  const [ interview5Title, setInterview5Title ] = useState<string | number>('');
  const [ interview5Desc, setInterview5Desc ] = useState<string>('');
  const [ interview5Image, setInterview5Image ] = useState(false);

  const [ interview6Name, setInterview6Name ] = useState<string | number>('');
  const [ interview6Title, setInterview6Title ] = useState<string | number>('');
  const [ interview6Desc, setInterview6Desc ] = useState<string>('');
  const [ interview6Image, setInterview6Image ] = useState(false);

  // 戻るボタン
  const [ backStatus, setBackStatus ] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);

    return onAuthStateChanged(getAuth(), (user) => {
      if (user) {
        firebase.firestore().collection("recruits").doc(recruitLink).get()
        .then((doc) => {
          const data = doc.data();
          if (data) {
            setiInterviewCount(data.recruit_interviewCount ?? 0);

            setInterview1Name(data.recruit_interview1Name ?? '');
            setInterview1Title(data.recruit_interview1Title ?? '');
            setInterview1Desc(data.recruit_interview1Desc ?? '');
            setInterview1Image(data.recruit_interview1Image ?? '');

            setInterview2Name(data.recruit_interview2Name ?? '');
            setInterview2Title(data.recruit_interview2Title ?? '');
            setInterview2Desc(data.recruit_interview2Desc ?? '');
            setInterview2Image(data.recruit_interview2Image ?? '');

            setInterview3Name(data.recruit_interview3Name ?? '');
            setInterview3Title(data.recruit_interview3Title ?? '');
            setInterview3Desc(data.recruit_interview3Desc ?? '');
            setInterview3Image(data.recruit_interview3Image ?? '');

            setInterview4Name(data.recruit_interview4Name ?? '');
            setInterview4Title(data.recruit_interview4Title ?? '');
            setInterview4Desc(data.recruit_interview4Desc ?? '');
            setInterview4Image(data.recruit_interview4Image ?? '');

            setInterview5Name(data.recruit_interview5Name ?? '');
            setInterview5Title(data.recruit_interview5Title ?? '');
            setInterview5Desc(data.recruit_interview5Desc ?? '');
            setInterview5Image(data.recruit_interview5Image ?? '');

            setInterview6Name(data.recruit_interview6Name ?? '');
            setInterview6Title(data.recruit_interview6Title ?? '');
            setInterview6Desc(data.recruit_interview6Desc ?? '');
            setInterview6Image(data.recruit_interview6Image ?? '');
          }
        });
      } else {
        navigate('/company/login')
      }
    })
  }, [link]);

  function check1(v: boolean) {
    window.scrollTo(0, 0);

    if (1 <= Number(interviewCount)) {
      if (!interview1Image && Image1File === '') {
        setErrorMessage('No.1 インタビューの写真を添付してください。');
      } else if (interview1Name === '') {
        setErrorMessage('No.1 インタビューの氏名を入力してください。');
      } else if (interview1Title === '') {
        setErrorMessage('No.1 インタビューの役職を入力してください。');
      } else if (interview1Desc === '') {
        setErrorMessage('No.1 インタビューの詳細を入力してください。');
      } else {
        check2(v);
      }
    } else {
      save(v);
    }
  }

  function check2(v: boolean) {
    window.scrollTo(0, 0);

    if (2 <= Number(interviewCount)) {
      if (!interview2Image && Image2File === '') {
        setErrorMessage('No.2 インタビューの写真を添付してください。');
      } else if (interview2Name === '') {
        setErrorMessage('No.2 インタビューの氏名を入力してください。');
      } else if (interview2Title === '') {
        setErrorMessage('No.2 インタビューの役職を入力してください。');
      } else if (interview2Desc === '') {
        setErrorMessage('No.2 インタビューの詳細を入力してください。');
      } else {
        check3(v);
      }
    } else {
      save(v);
    }
  }

  function check3(v: boolean) {
    window.scrollTo(0, 0);

    if (3 <= Number(interviewCount)) {
      if (!interview3Image && Image3File === '') {
        setErrorMessage('No.3 インタビューの写真を添付してください。');
      } else if (interview3Name === '') {
        setErrorMessage('No.3 インタビューの氏名を入力してください。');
      } else if (interview3Title === '') {
        setErrorMessage('No.3 インタビューの役職を入力してください。');
      } else if (interview3Desc === '') {
        setErrorMessage('No.3 インタビューの詳細を入力してください。');
      } else {
        check4(v);
      }
    } else {
      save(v);
    }
  }

  function check4(v: boolean) {
    window.scrollTo(0, 0);

    if (4 <= Number(interviewCount)) {
      if (!interview4Image && Image4File === '') {
        setErrorMessage('No.4 インタビューの写真を添付してください。');
      } else if (interview4Name === '') {
        setErrorMessage('No.4 インタビューの氏名を入力してください。');
      } else if (interview4Title === '') {
        setErrorMessage('No.4 インタビューの役職を入力してください。');
      } else if (interview4Desc === '') {
        setErrorMessage('No.4 インタビューの詳細を入力してください。');
      } else {
        check5(v);
      }
    } else {
      save(v);
    }
  }

  function check5(v: boolean) {
    window.scrollTo(0, 0);

    if (5 <= Number(interviewCount)) {
      if (!interview5Image && Image5File === '') {
        setErrorMessage('No.5 インタビューの写真を添付してください。');
        } else if (interview5Name === '') {
        setErrorMessage('No.5 インタビューの氏名を入力してください。');
      } else if (interview5Title === '') {
        setErrorMessage('No.5 インタビューの役職を入力してください。');
      } else if (interview5Desc === '') {
        setErrorMessage('No.5 インタビューの詳細を入力してください。');
      } else {
        check6(v);
      }
    } else {
      save(v);
    }
  }

  function check6(v: boolean) {
    window.scrollTo(0, 0);

    if (6 <= Number(interviewCount)) {
      if (!interview1Image && Image1File === '') {
        setErrorMessage('No.6 インタビューの写真を添付してください。');
      } else if (interview6Name === '') {
        setErrorMessage('No.6 インタビューの氏名を入力してください。');
      } else if (interview1Title === '') {
        setErrorMessage('No.6 インタビューの役職を入力してください。');
      } else if (interview1Desc === '') {
        setErrorMessage('No.6 インタビューの詳細を入力してください。');
      } else {
        save(v);
      }
    } else {
      save(v);
    }
  }

  async function save(e: boolean) {

    firebase.firestore().collection("recruits").doc(recruitLink)
    .update({
      status_interview: e,

      recruit_interviewCount: interviewCount,

      recruit_interview1Name: interview1Name,
      recruit_interview1Title: interview1Title,
      recruit_interview1Desc: interview1Desc,
      recruit_interview1Image: Image1File !== '' || interview1Image ? true : false, 

      recruit_interview2Name: interview2Name,
      recruit_interview2Title: interview2Title,
      recruit_interview2Desc: interview2Desc,
      recruit_interview2Image: Image2File !== '' || interview2Image ? true : false,

      recruit_interview3Name: interview3Name,
      recruit_interview3Title: interview3Title,
      recruit_interview3Desc: interview3Desc,
      recruit_interview3Image: Image3File !== '' || interview3Image ? true : false,

      recruit_interview4Name: interview4Name,
      recruit_interview4Title: interview4Title,
      recruit_interview4Desc: interview4Desc,
      recruit_interview4Image: Image4File !== '' || interview3Image ? true : false,

      recruit_interview5Name: interview5Name,
      recruit_interview5Title: interview5Title,
      recruit_interview5Desc: interview5Desc,
      recruit_interview5Image: Image5File !== '' || interview5Image ? true : false,

      recruit_interview6Name: interview6Name,
      recruit_interview6Title: interview6Title,
      recruit_interview6Desc: interview6Desc,
      recruit_interview6Image: Image6File !== '' || interview6Image ? true : false,

      last_time: new Date(),
    }).then(() => {
      navigate(`/edit/id=${recruitLink}`);
    });

    if (Image1FilePath) {
      firebase.storage().ref().child(`/interview/${recruitLink}_1`).put(Image1FilePath);
    }

    if (Image2FilePath) {
      firebase.storage().ref().child(`/interview/${recruitLink}_2`).put(Image2FilePath);
    }

    if (Image3FilePath) {
      firebase.storage().ref().child(`/interview/${recruitLink}_3`).put(Image3FilePath);
    }

    if (Image4FilePath) {
      firebase.storage().ref().child(`/interview/${recruitLink}_4`).put(Image4FilePath);
    }

    if (Image5FilePath) {
      firebase.storage().ref().child(`/interview/${recruitLink}_5`).put(Image5FilePath);
    }

    if (Image6FilePath) {
      firebase.storage().ref().child(`/interview/${recruitLink}_6`).put(Image6FilePath);
    }

  }

  const [ Image1File, setImage1File ] = useState('');
  const [ Image1FilePath, setImage1FilePath ] = useState<File>();

  const Images1 = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || e.target.files.length === 0) {
      return;
    }
    setImage1File(URL.createObjectURL(e.target.files[0]));
    setImage1FilePath(e.target.files[0]);
  }

  const [ Image2File, setImage2File ] = useState('');
  const [ Image2FilePath, setImage2FilePath ] = useState<File>();

  const Images2 = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || e.target.files.length === 0) {
      return;
    }
    setImage2File(URL.createObjectURL(e.target.files[0]));
    setImage2FilePath(e.target.files[0]);
  }

  const [ Image3File, setImage3File ] = useState('');
  const [ Image3FilePath, setImage3FilePath ] = useState<File>();

  const Images3 = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || e.target.files.length === 0) {
      return;
    }
    setImage3File(URL.createObjectURL(e.target.files[0]));
    setImage3FilePath(e.target.files[0]);
  }

  const [ Image4File, setImage4File ] = useState('');
  const [ Image4FilePath, setImage4FilePath ] = useState<File>();

  const Images4 = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || e.target.files.length === 0) {
      return;
    }
    setImage4File(URL.createObjectURL(e.target.files[0]));
    setImage4FilePath(e.target.files[0]);
  }

  const [ Image5File, setImage5File ] = useState('');
  const [ Image5FilePath, setImage5FilePath ] = useState<File>();

  const Images5 = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || e.target.files.length === 0) {
      return;
    }
    setImage5File(URL.createObjectURL(e.target.files[0]));
    setImage5FilePath(e.target.files[0]);
  }

  const [ Image6File, setImage6File ] = useState('');
  const [ Image6FilePath, setImage6FilePath ] = useState<File>();

  const Images6 = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || e.target.files.length === 0) {
      return;
    }
    setImage6File(URL.createObjectURL(e.target.files[0]));
    setImage6FilePath(e.target.files[0]);
  }
  
  return (
    <>
    <Header />

    <div className={Styles.store}>
      <div className={Styles.store_box}>

        {errorMessage !== '' ?
          <ErrorMessage message={errorMessage} />
        : <></> }

        <div className={Styles.store_contents}>

          <h5 className={Styles.store_title}>記載数</h5>

          <DefaultTitle title={'記載数'} required={true} />
          <select className={Styles.select}
            value={interviewCount} onChange={(e) => setiInterviewCount(Number(e.target.value))}>
            <option value={0}>未選択</option>
            <option value={2}>2つ</option>
            <option value={4}>4つ</option>
            <option value={6}>6つ</option>
          </select>
          <p className={Styles.desc}>　</p>

        </div>

        {1 <= Number(interviewCount) && Number(interviewCount) <= 6 ?
        <div className={Styles.store_contents}>

          <h5 className={Styles.store_title}>No.1 インタビュー</h5>

          <div className={Styles.store_input_photo}>
            <div className={Styles.store_input_photo_title}>
              <p>従業員写真</p>
              <div className={Styles.store_input_photo_yes}>※必須</div>
            </div>

            <div className={Styles.store_input_btn}>
              <label>
                写真添付
                <input type="file" onChange={Images1} />
              </label>
            </div>
          </div>

          <div className={Styles.image_prev}>
            {Image1File !== '' ?
              <img className={Styles.make_image_file} src={Image1File} alt='' />
            : interview1Image ?
              <img className={Styles.make_image_file} alt=''
                src={`https://firebasestorage.googleapis.com/v0/b/trankllc-com.appspot.com/o/interview%2Fresize_images%2F${recruitLink}_1_500x500?alt=media&token=?${new Date().getTime()}`} 
                onError={e => {
                  e.currentTarget.onerror = null;
                  e.currentTarget.src = DefaultImage;
                }}/>
            : 
              <p className={Styles.desc}>写真が添付されていません。</p>
            }
          </div>

          <DefaultInput1
            title={'氏名'} required={true} length={10} place={''} type={'text'}
            value={interview1Name} setValue={setInterview1Name}
            desc={''}
          />

          <DefaultInput1
            title={'役職'} required={true} length={10} place={''} type={'text'}
            value={interview1Title} setValue={setInterview1Title}
            desc={''}
          />

          <DefaultTextarea1
            title={'詳細'} required={true} length={100} type={'text'}
            place={''}
            value={interview1Desc} setValue={setInterview1Desc}
            desc={''}
          />

        </div> : <></> }

        {2 <= Number(interviewCount) && Number(interviewCount) <= 6 ?
        <div className={Styles.store_contents}>

          <h5 className={Styles.store_title}>No.2 インタビュー</h5>

          <div className={Styles.store_input_photo}>
            <div className={Styles.store_input_photo_title}>
              <p>従業員写真</p>
              <div className={Styles.store_input_photo_yes}>※必須</div>
            </div>

            <div className={Styles.store_input_btn}>
              <label>
                写真添付
                <input type="file" onChange={Images2} />
              </label>
            </div>
          </div>

          <div className={Styles.image_prev}>
            {Image2File !== '' ?
              <img className={Styles.make_image_file} src={Image2File} alt='' />
            : interview2Image ?
              <img className={Styles.make_image_file} alt=''
                src={`https://firebasestorage.googleapis.com/v0/b/trankllc-com.appspot.com/o/interview%2Fresize_images%2F${recruitLink}_2_500x500?alt=media&token=?${new Date().getTime()}`} 
                onError={e => {
                  e.currentTarget.onerror = null;
                  e.currentTarget.src = DefaultImage;
                }}/>
            : 
              <p className={Styles.desc}>写真が添付されていません。</p>
            }
          </div>

          <DefaultInput1
            title={'氏名'} required={true} length={10} place={''} type={'text'}
            value={interview2Name} setValue={setInterview2Name}
            desc={''}
          />

          <DefaultInput1
            title={'役職'} required={true} length={10} place={''} type={'text'}
            value={interview2Title} setValue={setInterview2Title}
            desc={''}
          />

          <DefaultTextarea1
            title={'詳細'} required={true} length={100} type={'text'}
            place={''}
            value={interview2Desc} setValue={setInterview2Desc}
            desc={''}
          />

        </div> : <></> }

        {3 <= Number(interviewCount) && Number(interviewCount) <= 6 ?
        <div className={Styles.store_contents}>

          <h5 className={Styles.store_title}>No.3 インタビュー</h5>

          <div className={Styles.store_input_photo}>
            <div className={Styles.store_input_photo_title}>
              <p>従業員写真</p>
              <div className={Styles.store_input_photo_yes}>※必須</div>
            </div>

            <div className={Styles.store_input_btn}>
              <label>
                写真添付
                <input type="file" onChange={Images3} />
              </label>
            </div>
          </div>

          <div className={Styles.image_prev}>
            {Image3File !== '' ?
              <img className={Styles.make_image_file} src={Image3File} alt='' />
            : interview3Image ?
              <img className={Styles.make_image_file} alt=''
                src={`https://firebasestorage.googleapis.com/v0/b/trankllc-com.appspot.com/o/interview%2Fresize_images%2F${recruitLink}_3_500x500?alt=media&token=?${new Date().getTime()}`} 
                onError={e => {
                  e.currentTarget.onerror = null;
                  e.currentTarget.src = DefaultImage;
                }}/>
            : 
              <p className={Styles.desc}>写真が添付されていません。</p>
            }
          </div>

          <DefaultInput1
            title={'氏名'} required={true} length={10} place={''} type={'text'}
            value={interview3Name} setValue={setInterview3Name}
            desc={''}
          />

          <DefaultInput1
            title={'役職'} required={true} length={10} place={''} type={'text'}
            value={interview3Title} setValue={setInterview3Title}
            desc={''}
          />

          <DefaultTextarea1
            title={'詳細'} required={true} length={100} type={'text'}
            place={''}
            value={interview3Desc} setValue={setInterview3Desc}
            desc={''}
          />

        </div> : <></> }

        {4 <= Number(interviewCount) && Number(interviewCount) <= 6 ?
        <div className={Styles.store_contents}>

          <h5 className={Styles.store_title}>No.4 インタビュー</h5>

          <div className={Styles.store_input_photo}>
            <div className={Styles.store_input_photo_title}>
              <p>従業員写真</p>
              <div className={Styles.store_input_photo_yes}>※必須</div>
            </div>

            <div className={Styles.store_input_btn}>
              <label>
                写真添付
                <input type="file" onChange={Images4} />
              </label>
            </div>
          </div>

          <div className={Styles.image_prev}>
            {Image4File !== '' ?
              <img className={Styles.make_image_file} src={Image4File} alt='' />
            : interview4Image ?
              <img className={Styles.make_image_file} alt=''
                src={`https://firebasestorage.googleapis.com/v0/b/trankllc-com.appspot.com/o/interview%2Fresize_images%2F${recruitLink}_4_500x500?alt=media&token=?${new Date().getTime()}`} 
                onError={e => {
                  e.currentTarget.onerror = null;
                  e.currentTarget.src = DefaultImage;
                }}/>
            : 
              <p className={Styles.desc}>写真が添付されていません。</p>
            }
          </div>

          <DefaultInput1
            title={'氏名'} required={true} length={10} place={''} type={'text'}
            value={interview4Name} setValue={setInterview4Name}
            desc={''}
          />

          <DefaultInput1
            title={'役職'} required={true} length={10} place={''} type={'text'}
            value={interview4Title} setValue={setInterview4Title}
            desc={''}
          />

          <DefaultTextarea1
            title={'詳細'} required={true} length={100} type={'text'}
            place={''}
            value={interview4Desc} setValue={setInterview4Desc}
            desc={''}
          />

        </div> : <></> }

        {5 <= Number(interviewCount) && Number(interviewCount) <= 6 ?
        <div className={Styles.store_contents}>

          <h5 className={Styles.store_title}>No.5 インタビュー</h5>

          <div className={Styles.store_input_photo}>
            <div className={Styles.store_input_photo_title}>
              <p>従業員写真</p>
              <div className={Styles.store_input_photo_yes}>※必須</div>
            </div>

            <div className={Styles.store_input_btn}>
              <label>
                写真添付
                <input type="file" onChange={Images5} />
              </label>
            </div>
          </div>

          <div className={Styles.image_prev}>
            {Image5File !== '' ?
              <img className={Styles.make_image_file} src={Image5File} alt='' />
            : interview5Image ?
              <img className={Styles.make_image_file} alt=''
                src={`https://firebasestorage.googleapis.com/v0/b/trankllc-com.appspot.com/o/interview%2Fresize_images%2F${recruitLink}_5_500x500?alt=media&token=?${new Date().getTime()}`} 
                onError={e => {
                  e.currentTarget.onerror = null;
                  e.currentTarget.src = DefaultImage;
                }}/>
            : 
              <p className={Styles.desc}>写真が添付されていません。</p>
            }
          </div>

          <DefaultInput1
            title={'氏名'} required={true} length={10} place={''} type={'text'}
            value={interview5Name} setValue={setInterview5Name}
            desc={''}
          />

          <DefaultInput1
            title={'役職'} required={true} length={10} place={''} type={'text'}
            value={interview5Title} setValue={setInterview5Title}
            desc={''}
          />

          <DefaultTextarea1
            title={'詳細'} required={true} length={100} type={'text'}
            place={''}
            value={interview5Desc} setValue={setInterview5Desc}
            desc={''}
          />

        </div> : <></> }

        {6 <= Number(interviewCount) && Number(interviewCount) <= 6 ?
        <div className={Styles.store_contents}>

          <h5 className={Styles.store_title}>No.6 インタビュー</h5>

          <div className={Styles.store_input_photo}>
            <div className={Styles.store_input_photo_title}>
              <p>従業員写真</p>
              <div className={Styles.store_input_photo_yes}>※必須</div>
            </div>

            <div className={Styles.store_input_btn}>
              <label>
                写真添付
                <input type="file" onChange={Images6} />
              </label>
            </div>
          </div>

          <div className={Styles.image_prev}>
            {Image6File !== '' ?
              <img className={Styles.make_image_file} src={Image6File} alt='' />
            : interview6Image ?
              <img className={Styles.make_image_file} alt=''
                src={`https://firebasestorage.googleapis.com/v0/b/trankllc-com.appspot.com/o/interview%2Fresize_images%2F${recruitLink}_6_500x500?alt=media&token=?${new Date().getTime()}`} 
                onError={e => {
                  e.currentTarget.onerror = null;
                  e.currentTarget.src = DefaultImage;
                }}/>
            : 
              <p className={Styles.desc}>写真が添付されていません。</p>
            }
          </div>

          <DefaultInput1
            title={'氏名'} required={true} length={10} place={''} type={'text'}
            value={interview6Name} setValue={setInterview6Name}
            desc={''}
          />

          <DefaultInput1
            title={'役職'} required={true} length={10} place={''} type={'text'}
            value={interview6Title} setValue={setInterview6Title}
            desc={''}
          />

          <DefaultTextarea1
            title={'詳細'} required={true} length={100} type={'text'}
            place={''}
            value={interview6Desc} setValue={setInterview6Desc}
            desc={''}
          />

        </div> : <></> }

        <div className={Styles.save_box}>
          <button className={Styles.save_box_now} onClick={() => setBackStatus(true)}>戻る</button>
          <button className={Styles.save_box_now} onClick={() => save(false)}>一時保存</button>
          <button className={Styles.save_box_save} onClick={() => check1(true)}>保存</button>
        </div>

      </div>
    </div>

    {backStatus ?
      <BackModal setBackStatus={setBackStatus} />
    : <></> }

    </>
  );
}

export default RecruitInterview;
