import Styles from './styles.module.css';
import React from 'react'
import Icon from './../../../image/logo.png';
import { useNavigate } from 'react-router-dom';

const StudentHeader: React.FC = () => {

  const navigate = useNavigate();

  return (
    <>
    <div className={Styles.nav} onClick={() => navigate('/')}>
      <div className={Styles.nav_logo}>
        <img src={Icon} alt='' />
        <h4>memorii works</h4>
      </div>
      <div className={Styles.nav_link}>
        <button onClick={() => navigate('/contact')}>お問い合わせ</button>
      </div>
    </div>
    </>
  );
}

export default StudentHeader;
