import Styles from './styles.module.css';
import { useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import Logo from "./../../../image/logo.png";
import { FaLongArrowAltUp } from "react-icons/fa";
import { FaLongArrowAltDown } from "react-icons/fa";

import Personality1 from "./../../../image/mbti/1.png";
import Personality2 from "./../../../image/mbti/2.png";
import Personality3 from "./../../../image/mbti/3.png";
import Personality4 from "./../../../image/mbti/4.png";
import Personality5 from "./../../../image/mbti/5.png";
import Personality6 from "./../../../image/mbti/6.png";
import Personality7 from "./../../../image/mbti/7.png";
import Personality8 from "./../../../image/mbti/8.png";
import { FaAppStoreIos } from "react-icons/fa";

import { IoFilterOutline } from "react-icons/io5";

type TopHeaderProps = {
  setCharacterScore: React.Dispatch<React.SetStateAction<number[]>>;
};

const TopHeader: React.FC<TopHeaderProps> = ({ setCharacterScore }) => {

  const navigate = useNavigate();
  const [ checkFirst, setCheckFirst ] = useState(false);

  useEffect(() => {
    if (!checkFirst) {
      setCheckFirst(true);
    }
  }, []);

  // 性格診断
  const [ animate1, setAnimate1 ] = useState(false);
  const handleAnimationUp1 = () => { setAnimate1(true); setCharacterScore([ 1, 1, 5 ]);}; // 2

  const [ animate2, setAnimate2 ] = useState(false);
  const handleAnimationUp2 = () => { setAnimate2(true); setCharacterScore([ 1, 5, 1 ]);}; // 3
  const handleAnimationDown2 = () => { setAnimate1(false); setCharacterScore([ 1, 1, 1 ]);}; // 1

  const [ animate3, setAnimate3 ] = useState(false);
  const handleAnimationUp3 = () => { setAnimate3(true); setCharacterScore([ 1, 5, 5 ]);}; // 4
  const handleAnimationDown3 = () => { setAnimate2(false); setCharacterScore([ 1, 1, 5 ]);}; // 2

  const [ animate4, setAnimate4 ] = useState(false);
  const handleAnimationUp4 = () => { setAnimate4(true); setCharacterScore([ 5, 1, 1 ]);}; // 5
  const handleAnimationDown4 = () => { setAnimate3(false); setCharacterScore([ 1, 5, 1 ]);}; // 3

  const [ animate5, setAnimate5 ] = useState(false);
  const handleAnimationUp5 = () => { setAnimate5(true); setCharacterScore([ 5, 1, 5 ]);}; // 6
  const handleAnimationDown5 = () => { setAnimate4(false); setCharacterScore([ 1, 5, 5 ])}; // 4

  const [ animate6, setAnimate6 ] = useState(false);
  const handleAnimationUp6 = () => { setAnimate6(true); setCharacterScore([ 5, 5, 1 ]);}; // 7
  const handleAnimationDown6 = () => { setAnimate5(false); setCharacterScore([ 5, 1, 1 ]);}; // 5

  const [ animate7, setAnimate7 ] = useState(false);
  const handleAnimationUp7 = () => { setAnimate7(true); setCharacterScore([ 5, 5, 5 ]);}; // 8
  const handleAnimationDown7 = () => { setAnimate6(false); setCharacterScore([ 5, 1, 5 ]);}; // 6

  const [ animate8, setAnimate8 ] = useState(false);
  const handleAnimationDown8 = () => { setAnimate7(false); setCharacterScore([ 5, 5, 1 ]);}; // 7


  return (
    <>
    <div className={Styles.app_download}>
      <div>
        <img src={Logo} />
        <p>memorii works</p>
      </div>
      <a href='https://apps.apple.com/jp/app/memorii-works/id1578278781'>
        <button>アプリを入手</button>
      </a>
    </div>

    <div className={Styles.header}>
      <div className={Styles.logo}>
        <img src={Logo} />
        <h4>memorii works</h4>
      </div>
      <div className={Styles.topSearch}>
        <div className={Styles.topSearch_hidden}>
          <div className={animate1 ? Styles.topSearch_box_true : Styles.topSearch_box_false} 
            style={animate1 ? {transform : "translateY(-80px)", backgroundColor : '#DB6262', zIndex : "10"} : 
              {transform : "translateY(0px)", backgroundColor : '#DB6262', zIndex : "10"}}>
            <div className={Styles.topSearch_contents}>
              <img src={Personality1} className={Styles.topSearch_charisma} />
              <div>
                <p>自分のタイプは？</p>
                <h4>実力No.1</h4>
              </div>
            </div>
            <button className={Styles.topSearch_arrow} onClick={() => handleAnimationUp1()}>
              <FaLongArrowAltUp className={Styles.topSearch_arrow_icon} style={{color : '#DB6262'}} />
            </button>
            <button className={Styles.topSearch_arrow}>
            </button>
          </div>
          <div className={animate2 ? Styles.topSearch_box_true : Styles.topSearch_box_false} 
            style={animate2 ? {transform : "translateY(-80px)", backgroundColor : '#7764C2', zIndex : "9"} 
              : {transform : "translateY(0px)", backgroundColor : '#7764C2', zIndex : "9"}}>
            <div className={Styles.topSearch_contents}>
              <img src={Personality2} className={Styles.topSearch_charisma} />
              <div>
                <p>自分のタイプは？</p>
                <h4>スペシャリスト</h4>
              </div>
            </div>
            <button className={Styles.topSearch_arrow} onClick={() => handleAnimationUp2()}>
              <FaLongArrowAltUp className={Styles.topSearch_arrow_icon} style={{color : '#7764C2'}} />
            </button>
            <button className={Styles.topSearch_arrow} onClick={() => handleAnimationDown2()}>
              <FaLongArrowAltDown className={Styles.topSearch_arrow_icon} style={{color : '#7764C2'}} />
            </button>
          </div>
          <div className={animate3 ? Styles.topSearch_box_true : Styles.topSearch_box_false} 
            style={animate3 ? {transform : "translateY(-80px)", backgroundColor : '#769FCE', zIndex : "8"} 
              : {transform : "translateY(0px)", backgroundColor : '#769FCE', zIndex : "8"}}>
            <div className={Styles.topSearch_contents}>
              <img src={Personality3} className={Styles.topSearch_charisma} />
              <div>
                <p>自分のタイプは？</p>
                <h4>完璧主義</h4>
              </div>
            </div>
            <button className={Styles.topSearch_arrow} onClick={() => handleAnimationUp3()}>
              <FaLongArrowAltUp className={Styles.topSearch_arrow_icon} style={{color : '#769FCE'}} />
            </button>
            <button className={Styles.topSearch_arrow} onClick={() => handleAnimationDown3()}>
              <FaLongArrowAltDown className={Styles.topSearch_arrow_icon} style={{color : '#769FCE'}} />
            </button>
          </div>
          <div className={animate4 ? Styles.topSearch_box_true : Styles.topSearch_box_false} 
            style={animate4 ? {transform : "translateY(-80px)", backgroundColor : '#D4C145', zIndex : "7"} 
              : {transform : "translateY(0px)", backgroundColor : '#D4C145', zIndex : "7"}}>
            <div className={Styles.topSearch_contents}>
              <img src={Personality4} className={Styles.topSearch_charisma} />
              <div>
                <p>自分のタイプは？</p>
                <h4>カリスマ</h4>
              </div>
            </div>
            <button className={Styles.topSearch_arrow} style={{color : '#D4C145'}} onClick={() => handleAnimationUp4()}>
              <FaLongArrowAltUp className={Styles.topSearch_arrow_icon} />
            </button>
            <button className={Styles.topSearch_arrow} onClick={() => handleAnimationDown4()}>
              <FaLongArrowAltDown className={Styles.topSearch_arrow_icon} style={{color : '#D4C145'}} />
            </button>
          </div>
          <div className={animate5 ? Styles.topSearch_box_true : Styles.topSearch_box_false} 
            style={animate5 ? {transform : "translateY(-80px)", backgroundColor : '#428D60', zIndex : "6"} 
              : {transform : "translateY(0px)", backgroundColor : '#428D60', zIndex : "6"}}>
            <div className={Styles.topSearch_contents}>
              <img src={Personality5} className={Styles.topSearch_charisma} />
              <div>
                <p>自分のタイプは？</p>
                <h4>イノベーション</h4>
              </div>
            </div>
            <button className={Styles.topSearch_arrow} onClick={() => handleAnimationUp5()}>
              <FaLongArrowAltUp className={Styles.topSearch_arrow_icon} style={{color : '#428D60'}} />
            </button>
            <button className={Styles.topSearch_arrow} onClick={() => handleAnimationDown5()}>
              <FaLongArrowAltDown className={Styles.topSearch_arrow_icon} style={{color : '#428D60'}} />
            </button>
          </div>
          <div className={animate6 ? Styles.topSearch_box_true : Styles.topSearch_box_false} 
            style={animate6 ? {transform : "translateY(-80px)", backgroundColor : '#EC8CB4', zIndex : "5"} 
              : {transform : "translateY(0px)", backgroundColor : '#EC8CB4', zIndex : "5"}}>
            <div className={Styles.topSearch_contents}>
              <img src={Personality6} className={Styles.topSearch_charisma} />
              <div>
                <p>自分のタイプは？</p>
                <h4>トレンド創出</h4>
              </div>
            </div>
            <button className={Styles.topSearch_arrow} onClick={() => handleAnimationUp6()}>
              <FaLongArrowAltUp className={Styles.topSearch_arrow_icon} style={{color : '#EC8CB4'}} />
            </button>
            <button className={Styles.topSearch_arrow} onClick={() => handleAnimationDown6()}>
              <FaLongArrowAltDown className={Styles.topSearch_arrow_icon} style={{color : '#EC8CB4'}} />
            </button>
          </div>
          <div className={animate7 ? Styles.topSearch_box_true : Styles.topSearch_box_false} 
            style={animate7 ? {transform : "translateY(-80px)", backgroundColor : '#303030', zIndex : "4"} 
              : {transform : "translateY(0px)", backgroundColor : '#303030', zIndex : "4"}}>
            <div className={Styles.topSearch_contents}>
              <img src={Personality7} className={Styles.topSearch_charisma} />
              <div>
                <p>自分のタイプは？</p>
                <h4>独立成功型</h4>
              </div>
            </div>
            <button className={Styles.topSearch_arrow} onClick={() => handleAnimationUp7()}>
              <FaLongArrowAltUp className={Styles.topSearch_arrow_icon} style={{color : '#303030'}} />
            </button>
            <button className={Styles.topSearch_arrow} onClick={() => handleAnimationDown7()}>
              <FaLongArrowAltDown className={Styles.topSearch_arrow_icon} style={{color : '#303030'}} />
            </button>
          </div>
          <div className={animate8 ? Styles.topSearch_box_true : Styles.topSearch_box_false} 
            style={animate8 ? {transform : "translateY(-80px)", backgroundColor : '#BD8C57', zIndex : "3"} 
              : {transform : "translateY(0px)", backgroundColor : '#BD8C57', zIndex : "3"}}>
            <div className={Styles.topSearch_contents}>
              <img src={Personality8} className={Styles.topSearch_charisma} />
              <div>
                <p>自分のタイプは？</p>
                <h4>経営者タイプ</h4>
              </div>
            </div>
            <button className={Styles.topSearch_arrow}>
            </button>
            <button className={Styles.topSearch_arrow} onClick={() => handleAnimationDown8()} >
              <FaLongArrowAltDown className={Styles.topSearch_arrow_icon} style={{color : '#BD8C57'}} />
            </button>
          </div>
        </div>

      </div>
      
      <div className={Styles.links}>
        <h4 onClick={() => navigate('/company')}>求人を掲載する</h4>
        <div>
          <a href='https://apps.apple.com/jp/app/memorii-works/id1578278781'>
            <FaAppStoreIos className={Styles.links_icon} />
          </a>
        </div>
      </div>

      <div className={Styles.phone_filter}>
        <IoFilterOutline className={Styles.phone_filter_icon} />
      </div>
    </div>
    </>
  );
}

export default TopHeader;
