import Styles from './styles.module.css';
import React, { useState, useEffect, useRef } from 'react';
import { IoMdHelpCircleOutline } from "react-icons/io";
import { IoMailOutline } from "react-icons/io5";
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import { useNavigate } from 'react-router-dom';
import { IoPersonSharp } from "react-icons/io5";
import { RiLogoutBoxRLine } from "react-icons/ri";
import Icon from './../../../../image/logo.png';
import AccountEdit from '../../../0.header/accountEdit';
import DefaultImage from "./../../../../image/default/account.png";
import { useDispatch, useSelector } from 'react-redux';
import { fetchAuthState } from '../../../../features/0.main/auth';
import { fetchCompanyState } from '../../../../features/0.main/company';

const RecruitEditHeader: React.FC = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const users = useSelector((state: any) => state.auth.users);

  const [ accountEdit, setAccountEdit ] = useState(false);

  const [ visible1, setVisible1 ] = useState(false);
  const buttonRef1 = useRef<HTMLDivElement | null>(null);
  const divRef1 = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    window.scrollTo(0, 0);

    const fetchData = async () => {
      var fetchUsers =  await dispatch(fetchAuthState());
      if (fetchAuthState.fulfilled.match(fetchUsers)) {
        const users = fetchUsers.payload;
        await dispatch(fetchCompanyState(users.id));
      }
    }
    fetchData();
  }, []);
  
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        divRef1.current && !divRef1.current.contains(event.target as Node) &&
        buttonRef1.current && !buttonRef1.current.contains(event.target as Node)
      ) {
        setVisible1(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleButtonClick1 = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    setVisible1((prev) => !prev);
  };

  async function logOut() {
    try {
      const auth = getAuth()
      await signOut(auth).then(() => navigate('/company/login'))
    } catch (e) {}
  }

  return (
    <>
    <div className={Styles.header}>

      <div className={Styles.header_logo}>
        <img src={Icon} alt='memorii works' />
        <h1>memorii works</h1>
      </div>
      
      <div className={Styles.header_right}>
        <div>
          <IoMdHelpCircleOutline className={Styles.header_right_icon} />
          <p>ヘルプ</p>
        </div>
        <div>
          <IoMailOutline className={Styles.header_right_icon} />
          <p>問い合わせ</p>
        </div>
        <div className={Styles.header_right_account}>
          <div className={Styles.header_right_account_info} 
            ref={buttonRef1}
            onClick={handleButtonClick1}>
            <img className={Styles.header_right_image}
              src={`https://firebasestorage.googleapis.com/v0/b/trankllc-com.appspot.com/o/profiles%2Fresize_images%2F${users.uid}_200x200?alt=media&token=?${new Date().getTime()}`}
              onError={e => {
                e.currentTarget.onerror = null;
                e.currentTarget.src = DefaultImage;
              }}/>
          </div>

          {visible1 && (
            <div className={Styles.header_right_account_nav} ref={divRef1}>
              <div onClick={() => setAccountEdit(true)}>
                <IoPersonSharp className={Styles.header_right_account_nav_icon} />
                <p>アカウント</p>
              </div>
              <div>
                <RiLogoutBoxRLine className={Styles.header_right_account_nav_icon} />
                <p onClick={() => logOut()}>ログアウト</p>
              </div>
            </div>
          )}

        </div>
      </div>
    </div>

    {accountEdit ?
      <AccountEdit setAccountEdit={setAccountEdit} />
    : <></> }
    </>
  );
}

export default RecruitEditHeader;
