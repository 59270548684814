import React, { useState, useEffect } from 'react';
import firebase from 'firebase/compat/app';

import telePhone from './phone.json';

const Footer = () => {

  const [ recruit, setRecruits ] = useState([]);

  const [ before, setBefore ] = useState(0);
  const [ think, setThink ] = useState(0);
  const [ get, setGet ] = useState(0);
  const [ lost, setLost ] = useState(0);
  const [ noCall, setNoCall ] = useState(0);
  const [ oneMore, setOneMore ] = useState(0);
  const [ block, setBlock ] = useState(0);

  const [ number1, setNumber1 ] = useState<any>([]);
  const [ number2, setNumber2 ] = useState<any>([]);
  const [ number3, setNumber3 ] = useState<any>([]);
  const [ number4, setNumber4 ] = useState<any>([]);
  const [ number5, setNumber5 ] = useState<any>([]);
  const [ number6, setNumber6 ] = useState<any>([]);
  const [ number7, setNumber7 ] = useState<any>([]);


  const phoneNumbers: string[] = [
    "03-6426-7505",
"03-3739-3363",
"045-743-1616",
"042-723-9011",
"03-5392-0215",
"03-3460-4158",
"047-1289-233",
"03-5969-8922",
"03-3571-3076",
"03-5942-4878",
"080-3159-4531",
"042-649-1707",
"03-3414-3344",
"03-6459-6789",
"03-6805-3711",
"0422-47-0043",
"042-775-6501",
"042-356-2772",
"03-5941-7375",
"045-392-3125",
"03-3406-1700",
"03-3407-7171",
"03-6427-0410",
"03-6382-8997",
"03-5935-0502",
"03-3229-6325",
"03-5727-9374",
"03-5799-6288",
"03-6413-6522",
"03-6427-6637",
"070-1545-1122",
"03-5787-6715",
"03-5661-1031",
"03-5981-8422",
"03-5766-0045",
"03-3983-2007",
"03-5524-0048",
"03-6435-1890",
"0120-10-4416",
"0422-70-1030",
"042-521-5500",
"042-645-2824",
"03-5610-0450",
"03-3868-2631",
"03-3579-5992",
"03-5797-3555",
"042-201-4435",
"042-313-6695",
"042-224-7010",
"03-5452-1153",
"03-4400-9192",
"03-6458-5347",
"03-5879-9171",
"03-3477-5567",
"03-5950-7758",
"03-6912-6756",
"03-6912-7500",
"03-6809-0322",
"049-203-0808",
"03-6263-8155",
"03-5364-9334",
"03-6807-0049",
"03-6454-4882",
"03-6809-8160",
"03-5489-549",
"03-5948-8688",
"03-6228-5569",
"03-5284-8990",
"03-5414-2207",
"042-721-2030",
"03-5427-0678",
"03-5946-9344",
"03-6806-2425",
"080-4890-1668 ",
"03-5579-9887",
"0466-54-9068",
"042-512-9266",
"03-6907-2557",
"042-505-6103",
"045-577-0916",
"0422-21-6054",
"03-3635-6188",
"042-860-6291",
"090-3707-7350",
"03-3780-7020",
"03-6274-6088",
"03-6434-5906",
"03‐6458‐7044",
"042-850-8199",
"03-5982-3008",
"03-5962-6405",
"03-3399-9067",
"03-6770-1252",
"03-5315-0956",
"03-6276-1955",
"03-3202-1680",
"03-6273-8013",
"080-4890-1591",
"044-387-6670",
"03-3520-8733",
"042-698-4875 ",
"03-6806-2570",
"0422-38-7743",
"045-532-8402",
"03-6404-6609",
"03-6410-8128",
"045-370-8455",
"03-5843-1724",
"03-5784-0737",
"045-548-8441",
"080-9514-8922",
"03-6434-1146",
"042-664-1710",
"03-6431-0530",
"03-6228-6080",
"042-394-5922",
"03-3482-1441",
"03-5798-7735",
"03-6809-3090",
"03-6379-5006",
"03-6426-4141",
"03-5784-0737",
"042-337-7600",
"042-483-5218",
"03-5916-7788",
"03-6911-3099",
"03-3417-6669",
"042-357-8830",
"03-6408-9302",
"090-3434-6148",
"042-621-0961",
"03-5731-7205",
"03-6809-0055",
"03-3746-0068",
"080-4126-4136",
"03-6300-0210",
"03-6658-8487",
"03-6284-7288",
"0493-88-9280",
"03-6662-6560",
"03-6805-0980",
"03-3604-9341",
"0898-25-7065",
"03-5261-0062",
"03-6416-3988",
"03-6264-5656",
"03-5797-9899",
"03-5944-8733",
"03-6905-8855",
"042-313-8056",
"03-3882-7122 ",
"03-3454-5246",
"03-6240-2958",
"03-5784-3405",
"03-5411-1151",
"0422-44-8003",
"042-728-7888",
"03-6421-4738",
"03-6427-5357 ",
"03-3367-6555",
"03-6456-1733",
"03-3770-8877",
"03-6915-6465",
"042-312-0695",
"046-834-7000",
"042-444-8850",
"03-6435-2136",
"03-5809-7123",
"03-6658-4343",
"03-6659-3323",
"03-6240-1555",
"03--6456-1189",
"045-534-6513",
"03-6912-5112",
"03-3641-2023",
"03-6658-8072",
"03-5284-8006",
"03-5669-0910",
"03-5875-5744",
"045-663-1981",
"045-334-8558",
"045-294-9089",
  ];

  useEffect(() => {

    const matchedData: any = [];

    // 一致するデータを検索してpush
    telePhone.forEach((entry) => {
      if (phoneNumbers.includes(entry['電話番号'])) {
        matchedData.push(entry);
      }
    });

    // 結果を表示
    setNumber1(matchedData);
    console.log(matchedData);


    // const reversedArray = [...telePhone].reverse();
  
    // var number = [];

    // for (let i = 0; i < reversedArray.length; i++) {
    //   if (reversedArray[i]['電話番号'] !== undefined) {
    //     number.push(reversedArray[i]['電話番号']);
    //   }
    // }

    // console.log(number);
      
    // const tmp: object[] = [];

    // var lastNumber = [];
    // lastNumber = Array.from(new Set(number));

    // // setNumber(lastNumber);
   
    // for (let i = 0; i < reversedArray.length; i++) {
    //   for (let j = 0; j < lastNumber.length; j++) {
    //     if (reversedArray[i]['電話番号'] === lastNumber[j]) {
    //       tmp.push(reversedArray[i]);
    //       break;
    //     }
    //   }
    // }
    
    // countFunc(tmp);

  }, []);

  function countFunc(list: any) {

    var before = 0;
    var think = 0;
    var get = 0;
    var lost = 0;
    var noCall = 0;
    var oneMore = 0;
    var block = 0;

    var pickUpList1 = [];
    var pickUpList2 = [];
    var pickUpList3 = [];
    var pickUpList4 = [];
    var pickUpList5 = [];
    var pickUpList6 = [];
    var pickUpList7 = [];

    for (let i = 0; i < list.length; i++) {
      if (list[i]['ステータス'] === '電話前') {
        before = before + 1;

        pickUpList1.push(list[i]);
      }
  
      if (list[i]['ステータス'] === '失注') {
        think = think + 1;

        pickUpList2.push(list[i]);
      }
  
      if (list[i]['ステータス'] === '不通') {
        get = get + 1;
        
        pickUpList3.push(list[i]);
      }
  
      if (list[i]['ステータス'] === '受付ブロック') {
        lost = lost + 1;

        pickUpList4.push(list[i]);
      }
  
      if (list[i]['ステータス'] === 'アポ獲得') {
        noCall = noCall + 1;

        pickUpList5.push(list[i]);
      }
  
      if (list[i]['ステータス'] === '検討中') {
        oneMore = oneMore + 1;

        pickUpList6.push(list[i]);
      }
  
      if (list[i]['ステータス'] === '再架電') {
        block = block + 1;

        pickUpList7.push(list[i]);
      }
    }

    setBefore(before);
    setThink(think);
    setGet(get);
    setLost(lost);
    setNoCall(noCall);
    setOneMore(oneMore);
    setBlock(block);

    setNumber1(pickUpList1);
    setNumber2(pickUpList2);
    setNumber3(pickUpList3);
    setNumber4(pickUpList4);
    setNumber5(pickUpList5);
    setNumber6(pickUpList6);
    setNumber7(pickUpList7);
  }

  return (
    <>
    {/* <p>電話前：{before}</p>
    <p>検討中：{think}</p>
    <p>アポ獲得：{get}</p>
    <p>失注：{lost}</p>
    <p>不通：{noCall}</p>
    <p>再架電：{oneMore}</p>
    <p>受付ブロック：{block}</p>
    <p>合計：{before + think + get + lost + noCall+ oneMore + block}</p>
    <p>　</p> */}

    <table>
      <tbody>
        <tr>
          <th>No</th>
          <th>サロン名</th>
          <th>電話番号</th>
          <th>ステータス</th>
        </tr>
        {number1.map((data : any, index: number) =>
          <tr>
            <th>{index + 1}</th>
            <th>{data['サロン名']}</th>
            <th>{data['電話番号']}</th>
            <th>{data['ステータス']}</th>
          </tr>
        )}
        {number2.map((data : any, index: number) =>
          <tr>
            <th>{index + 1}</th>
            <th>{data['サロン名']}</th>
            <th>{data['電話番号']}</th>
            <th>{data['ステータス']}</th>
          </tr>
        )}
        {number3.map((data : any, index: number) =>
          <tr>
            <th>{index + 1}</th>
            <th>{data['サロン名']}</th>
            <th>{data['電話番号']}</th>
            <th>{data['ステータス']}</th>
          </tr>
        )}
        {number4.map((data : any, index: number) =>
          <tr>
            <th>{index + 1}</th>
            <th>{data['サロン名']}</th>
            <th>{data['電話番号']}</th>
            <th>{data['ステータス']}</th>
          </tr>
        )}
        {number5.map((data : any, index: number) =>
          <tr>
            <th>{index + 1}</th>
            <th>{data['サロン名']}</th>
            <th>{data['電話番号']}</th>
            <th>{data['ステータス']}</th>
          </tr>
        )}
        {number6.map((data : any, index: number) =>
          <tr>
            <th>{index + 1}</th>
            <th>{data['サロン名']}</th>
            <th>{data['電話番号']}</th>
            <th>{data['ステータス']}</th>
          </tr>
        )}
        {number7.map((data : any, index: number) =>
          <tr>
            <th>{index + 1}</th>
            <th>{data['サロン名']}</th>
            <th>{data['電話番号']}</th>
            <th>{data['ステータス']}</th>
          </tr>
        )}
      </tbody>
    </table>

    {/* {number.map((data : any) =>
    <>
      <p>{data['電話番号']},</p>
    </>
    )} */}
    </>
  );
}

export default Footer;
