import React from 'react';
import Styles from './styles.module.css';

type DefaultSelect1Props = {
  title: string,
  required: Boolean,
  length: number,
  list: string[],
  value: string,
  desc: string,
  setValue: React.Dispatch<React.SetStateAction<string>>;
};

const DefaultSelect1: React.FC<DefaultSelect1Props> = ({ title, required, length, list, value, desc, setValue}) => {

  return (
    <>
    <div className={Styles.title}>
      <div>
        <p>{title}</p>
        <div className={ required ? Styles.title_yes : Styles.title_no }>{required ? '※必須' : '任意'}</div>
      </div>
      {length === 0 ? <></> :
        <p>{value.length}/{length}</p>
      }
    </div>
    <select className={Styles.select} value={value} onChange={(e) => setValue(e.target.value)}>
      {list.map((data, index) =>
        <option value={data === '未選択' ? '' : data} key={index}>{data}</option>
      )}
    </select>
    <p className={Styles.desc}>{desc}</p>
    </>
  );
}

export default DefaultSelect1;
