import { Splide, SplideSlide } from "@splidejs/react-splide";
import Styles from "./styles.module.css";
import '@splidejs/splide/css';
import React from "react";

type SpliderProps = {
  number: number;
  imageName: string[];
};

const RecruitSplider: React.FC<SpliderProps> = ({ number, imageName }) => {

  return (
    <>
      <Splide
        aria-label="私のお気に入りの画像集"
        options={{
          perPage: 1,
          // autoplay: true,
          interval: 3000,
        }}
      >
        {1 <= imageName.length ?
          <SplideSlide>
            <img className={Styles.slideImg} src={`https://firebasestorage.googleapis.com/v0/b/trankllc-com.appspot.com/o/recruitImage${number}%2Fresize_images%2F${imageName[0]}_1080x1080?alt=media&token=`}
              alt="美容室 求人 memorii works" />
          </SplideSlide>
        : <></> }
        {2 <= imageName.length ?
          <SplideSlide>
            <img className={Styles.slideImg} src={`https://firebasestorage.googleapis.com/v0/b/trankllc-com.appspot.com/o/recruitImage${number}%2Fresize_images%2F${imageName[1]}_1080x1080?alt=media&token=`}
              alt="美容室 求人 memorii works" />
          </SplideSlide>
        : <></> }
        {3 <= imageName.length ?
          <SplideSlide>
            <img className={Styles.slideImg} src={`https://firebasestorage.googleapis.com/v0/b/trankllc-com.appspot.com/o/recruitImage${number}%2Fresize_images%2F${imageName[2]}_1080x1080?alt=media&token=`}
              alt="美容室 求人 memorii works" />
          </SplideSlide>
        : <></> }
      </Splide>
    </>
  );
}

export default RecruitSplider;
