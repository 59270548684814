import Styles from './styles.module.css';
import React from 'react';
import firebase from 'firebase/compat/app';
import { useDispatch, useSelector } from 'react-redux';
import { fetchRecruits } from '../../../../features/0.main/recruit';

type RecruitEditProps = {
  recruitId: string;
  setToggle: React.Dispatch<React.SetStateAction<boolean>>;
};

const RecruitEdit: React.FC<RecruitEditProps> = ({ recruitId, setToggle }) => {

  const company = useSelector((state: any) => state.company.company);

  const dispatch = useDispatch();

  async function Delete() {
    await firebase.firestore().collection('companies').doc(company.id)
      .update({ recruits: firebase.firestore.FieldValue.arrayRemove(recruitId) });
    await firebase.firestore().collection('stores').where('recruitId', '==', recruitId).get()
    .then(snapShot => {
      snapShot.forEach((doc) => {
        firebase.firestore().collection('stores').doc(doc.id).update({ recruitId: '' });
      });
    });
    await firebase.firestore().collection('recruits').doc(recruitId).delete()
    .then(() => {
      dispatch(fetchRecruits(company.id));
      setToggle(false);
    });
  }

  return (
    <>
    <div className={Styles.modal}>
      <div>
        <div className={Styles.modal_black} onClick={() => setToggle(false)}></div>
        <div className={Styles.modal_contents}>
          <h4>求人を削除しますか？</h4>
          <p>
            応募者情報を含む全てのデータが削除されます。<br />
            復元はできませんのでご注意ください。
          </p>
          <div>
            <button style={{backgroundColor : 'white', color : '#AD0200'}} onClick={() => setToggle(false)}>キャンセル</button>
            <button style={{backgroundColor : '#AD0200', color : 'white'}} onClick={() => Delete()}>削除する</button>
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

export default RecruitEdit;


