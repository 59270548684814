import Styles from './styles.module.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'firebase/auth'
import Items from './items/index';
import firebase from 'firebase/compat/app';
import { useState, useEffect } from 'react';
import { FaSearch } from "react-icons/fa";
import { collection, onSnapshot, query, where } from 'firebase/firestore';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchManageApplicants } from '../../features/manage/applicants';

interface Applicant {
  id: string;
  recruitId: string;
  name: string;
  uid: string;
  examination: number;
  sex: string;
  time: string;
  managerMemo: string;
  hopeStore: string[];
  examList1: string[];
  examList2: string[];
  examList3: string[];
  examList4: string[];
  examList5: string[];
}

interface recruitList {
  id: string;
  status: number;
  title: string;
  employment: string;
  time: string;
  pv: number;
  saves: number;
  applicants: string[];
  recruitment: number;
  goal: number;
  apply: number;
}

const ManageMobile: React.FC = () => {
  
  const dispatch = useDispatch();

  const users = useSelector((state: any) => state.auth.users);
  const recruitList = useSelector((state: any) => state.recruit.recruitList as recruitList[]);
  const company = useSelector((state: any) => state.company.company);
  const recruitListOnlyId = useSelector((state: any) => state.recruit.recruitIds);

  const [ choiceRecruit, setChoiceRecruit ] = useState(1000);
  const [ choiceNav, setChoiceNav ] = useState(1000);

  const [ searchText, setSearchText ] = useState('');

  const applicantsListTrue = useSelector((state: any) => state.manageApplicants.applicantsListTrue) as Applicant[];
  const applicantsListFalse = useSelector((state: any) => state.manageApplicants.applicantsListFalse) as Applicant[];

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(fetchManageApplicants({ uid: users.uid, ids: recruitListOnlyId}));
    }
    fetchData();
  }, [dispatch, recruitListOnlyId]);


  const isAllIncludes = (arr: string[], target: string | any[]) => arr.some(el => target.includes(el));
  
  return (
    <>
    <div className={Styles.works_service_mobile}>
      <div className={Styles.box}>
        <div className={Styles.box_header}>
          <div className={Styles.box_header_search}>
            <select className={Styles.box_select_recruit}
              onChange={async (e) => {
                if (e.target.value === '')  {
                  setChoiceRecruit(1000);
                } else {
                  setChoiceRecruit(Number(e.target.value));
                }
              }}>
              <option value=''>全ての求人</option>
              {recruitList.map((data, index) =>
                <option value={index}>{data.title}</option>
              )}
            </select>
            <div>
              <FaSearch className={Styles.box_header_search_icon} />
              <input placeholder='応募者名 検索' value={searchText} onChange={(e) => setSearchText(e.target.value)} />
            </div>
          </div>

          <div className={Styles.table_header}>
            <p className={Styles.table_header_1}>応募者名</p>
            <p className={Styles.table_header_2}>性別</p>
            <p className={Styles.table_header_3}>審査</p>
            <p className={Styles.table_header_4}>保存</p>
          </div>
        </div>

        {applicantsListTrue.map((data) =>
        <>
          {choiceRecruit === 1000 && (
          <>
            {data.examination === choiceNav && isAllIncludes([searchText], data.name + data.managerMemo) && (
              <Items applicant={data} save={true} navigation={choiceNav} />
            )}

            {choiceNav === 1000 && isAllIncludes([searchText], data.name + data.managerMemo) && (
              <Items applicant={data} save={true} navigation={choiceNav} />
            )}
          </> )}

          {choiceRecruit !== 1000 && recruitList[choiceRecruit]?.id === data.recruitId && (
          <>
            {data.examination === choiceNav && isAllIncludes([searchText], data.name + data.managerMemo) && (
              <Items applicant={data} save={true} navigation={choiceNav} />
            )}

            {choiceNav === 1000 && isAllIncludes([searchText], data.name + data.managerMemo) && (
              <Items applicant={data} save={true} navigation={choiceNav} />
            )}
          </>)}
        </> )}
        
        {applicantsListFalse.map((data) =>
        <>
          {choiceRecruit === 1000 && (
          <>
            {data.examination === choiceNav && isAllIncludes([searchText], data.name + data.managerMemo) && (
              <Items applicant={data} save={false} navigation={choiceNav} />
            )}

            {choiceNav === 1000 && isAllIncludes([searchText], data.name + data.managerMemo) && (
              <Items applicant={data} save={false} navigation={choiceNav} />
            )}
          </> )}
          
          {choiceRecruit !== 1000 && recruitList[choiceRecruit]?.id === data.recruitId && (
          <>
            {data.examination === choiceNav && isAllIncludes([searchText], data.name + data.managerMemo) && (
              <Items applicant={data} save={false} navigation={choiceNav} />
            )}

            {choiceNav === 1000 && isAllIncludes([searchText], data.name + data.managerMemo) && (
              <Items applicant={data} save={false} navigation={choiceNav} />
            )}
          </>)}

        </> )}

      </div>
    </div>
    </>
  );
}

export default ManageMobile;
