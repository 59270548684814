import Styles from './styles.module.css';
import 'firebase/auth'
import React, { useState, useEffect } from 'react';
import firebase from "firebase/compat/app";
import { useNavigate } from 'react-router-dom';
import ErrorMessage from '../../../../package/parts/error/index';
import DefaultTitle from '../../../../package/parts/title/index';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import BackModal from '../12.back/index';
import DefaultInput1 from '../../../../package/parts/1-input/index';
import DefaultSelect1 from '../../../../package/parts/1-select/index';
import DefaultInput2 from '../../../../package/parts/2-input/index';
import DefaultSelect2 from '../../../../package/parts/2-select/index';
import DefaultInput1Salary from '../../../../package/parts/2-input-salary/index';
import DefaultTextarea1 from '../../../../package/parts/1-textarea/index';
import Header from '../0.header/index';

const RecruitRecruit: React.FC = () => {

  const navigate = useNavigate();

  const [ errorMessage, setErrorMessage ] = useState('');

  // みなし残業
  const [ overtime, setOvertime ] = useState(false);
  const [ overtimeOther, setOvertimeOther ] = useState('');
  
  // 職種・役職
  const [ post, setPost ] = useState('');
  
  // 中途採用
  const [ employmentStatus, setEmploymentStatus ] = useState<string>('');
  
  // 給与形態
  const [ salary, setSalary ] = useState('');
  
  // 年俸
  const [ annualSalaryStart, setAnnualSalaryStart ] = useState(0);
  const [ annualSalaryEnd, setAnnualSalaryEnd ] = useState(0);
  
  // 想定年収
  const [ annualIncomeStart, setAnnualIncomeStart ] = useState(0);
  const [ annualIncomeEnd, setAnnualIncomeEnd ] = useState(0);
  
  // 月収
  const [ monthlySalaryStart, setMonthlySalaryStart ] = useState(0);

  // 日給
  const [ dailyWageStart, setDailyWageStart ] = useState('');
  const [ dailyWageEnd, setDailyWageEnd ] = useState('');
  
  // 時給
  const [ hourWageStart, setHourWageStart ] = useState('');
  const [ hourWageEnd, setHourWageEnd ] = useState('');
  
  // 成功報酬金額
  const [ successFee, setSuccessFee ] = useState('');

  // 勤務時間
  const [ workingHoursStart, setWorkingHoursStart ] = useState('');
  const [ workingHoursEnd, setWorkingHoursEnd ] = useState('');

  // 休暇
  const [ holiday, setHoliday ] = useState<string[]>([]);
  const [ holidayCheck, setHolidayCheck ] = useState(false);
  const [ holidayOther, setHolidayOther ] = useState<string | number>('');

  // 福利厚生
  const [ welfare, setWelfare ] = useState<string[]>([]);
  const [ welfareCheck, setWelfareCheck ] = useState(false);
  const [ welfareOther, setWelfareOther ] = useState<string | number>('');

  // 給与体系・福利厚生に関する備考
  const [ salaryWelfare, setSalaryWelfare ] = useState('');
  
  // 必須資格
  const [ personStatue, setPersonStatue ] = useState('');
  
  // 仕事内容
  const [ workContents, setWorkContents ] = useState('');

  const link: string = new URL(window.location.href).href;
  const recruitLink = decodeURIComponent(link.slice(link.indexOf('id=') + 3, link.length));
    
  useEffect(() => {
    window.scrollTo(0, 0);
    return onAuthStateChanged(getAuth(), (user) => {
      if (user) {

        firebase.firestore().collection("recruits").doc(recruitLink)
        .get().then((doc) => {
          const data = doc.data();
          if (data) {
            setPost(data.recruit_post);
            
            setEmploymentStatus(data.recruit_employmentStatus);
            setSalary(data.recruit_salary);
            setAnnualSalaryStart(data.recruit_annualSalaryStart);
            setAnnualSalaryEnd(data.recruit_annualSalaryEnd);
            setAnnualIncomeStart(data.recruit_annualIncomeStart);
            setAnnualIncomeEnd(data.recruit_annualIncomeEnd);
            setMonthlySalaryStart(data.recruit_monthlySalaryStart);
            setDailyWageStart(data.recruit_dailyWageStart);
            setDailyWageEnd(data.recruit_dailyWageEnd);
            setHourWageStart(data.recruit_hourWageStart);
            setHourWageEnd(data.recruit_hourWageEnd);
            setSuccessFee(data.recruit_successFee);
  
            setWorkingHoursStart(data.recruit_workingHoursStart);
            setWorkingHoursEnd(data.recruit_workingHoursEnd);
  
            setOvertime(data.recruit_overtime);
            setOvertimeOther(data.recruit_overtimeOther);
  
            setHoliday(data.recruit_holiday);
            setHolidayCheck(data.recruit_holidayCheck);
            setHolidayOther(data.recruit_holidayOther);
  
            setWelfare(data.recruit_welfare);
            setWelfareCheck(data.recruit_welfareCheck);
            setWelfareOther(data.recruit_welfareOther);
  
            setWorkContents(data.recruit_workContents);
            setPersonStatue(data.recruit_personStatue);
  
            setSalaryWelfare(data.recruit_salaryWelfare);
          }
        });
      } else {
        navigate('/company/login')
      }
    })

  }, [link])

  function check() {
    window.scrollTo(0, 0);

    if (employmentStatus === '') {
      setErrorMessage('雇用形態を入力してください。');
    } else if (monthlySalaryStart === 0) {
      setErrorMessage('月給を入力してください。');
    } else if (annualIncomeStart === 0 || annualIncomeEnd === 0) {
      setErrorMessage('想定年収を入力してください。');
    } else {
      check2();
    }
  }

  function check2() {

    if (workingHoursStart === '' || workingHoursEnd === '') {
      setErrorMessage('勤務時間を入力してください。');
    } else if (holidayCheck && holidayOther === '') {
      setErrorMessage('休日・休暇のその他を入力してください。');
    } else if (salaryWelfare === '') {
      setErrorMessage('給与体系・福利厚生に関する備考を入力してください。');
    } else if (overtime && overtimeOther === '') {
      setErrorMessage('みなし残業代の備考を入力してください。');
    } else if (welfareCheck && welfareOther === '') {
      setErrorMessage('福利厚生のその他を入力してください。');
    } else {
      save(true);
    }
  }

  function save(e: boolean) {
    firebase.firestore().collection("recruits").doc(recruitLink)
    .update({
      status_recruit: e,

      recruit_post: post,
      recruit_employmentStatus: employmentStatus,

      recruit_salary: salary,
      recruit_annualSalaryStart: Number(annualSalaryStart),
      recruit_annualSalaryEnd: Number(annualSalaryEnd),
      recruit_annualIncomeStart: Number(annualIncomeStart),
      recruit_annualIncomeEnd: Number(annualIncomeEnd),
      recruit_monthlySalaryStart: Number(monthlySalaryStart),
      recruit_dailyWageStart: Number(dailyWageStart),
      recruit_dailyWageEnd: Number(dailyWageEnd),
      recruit_hourWageStart: Number(hourWageStart),
      recruit_hourWageEnd: Number(hourWageEnd),
      recruit_successFee: successFee,
      recruit_workingHoursStart: workingHoursStart,
      recruit_workingHoursEnd: workingHoursEnd,
      recruit_overtime: overtime,
      recruit_overtimeOther: overtimeOther,

      recruit_holidayCheck: holidayCheck,
      recruit_holiday: holiday,
      recruit_holidayOther: holidayOther,
      recruit_welfareCheck: welfareCheck,
      recruit_welfare: welfare,
      recruit_welfareOther: welfareOther,
      recruit_salaryWelfare: salaryWelfare,
      recruit_searchTags: [ employmentStatus ],

      recruit_workContents: workContents,
      recruit_personStatue: personStatue,
      last_time: new Date(),
    }).then(() => {
      navigate(`/edit/id=${recruitLink}`);
    });
  }

  const [ backStatus, setBackStatus ] = useState(false);

  return (
    <>

    <Header />

    <div className={Styles.box}>
      <div className={Styles.box_inline}>

        {errorMessage !== '' ?
          <ErrorMessage message={errorMessage} />
        : <></> }

        <div className={Styles.contents}>
          <h5 className={Styles.title}>雇用情報</h5>

          <DefaultSelect1
            title={'雇用形態'} required={true} length={0}
            value={employmentStatus} setValue={setEmploymentStatus}
            list={['未選択', '新卒採用（正社員）', '新卒採用（契約社員）' ]}
            desc={''}
          />

          <DefaultInput1Salary
            title={'月給'} required={true} unit={'万円'}
            value1={monthlySalaryStart} setValue1={setMonthlySalaryStart}
            desc={''}
          />

          <DefaultInput2
            title={'想定年収'} required={true} unit={'万円'}
            value1={annualIncomeStart} setValue1={setAnnualIncomeStart}
            value2={annualIncomeEnd} setValue2={setAnnualIncomeEnd}
            desc={''}
          />

          <p className={Styles.desc}>　</p>

          <DefaultTitle title={'みなし残業代'} required={true} />
          
          <div className={Styles.overtime}>
            <div>
              <input id="overtime" type="radio" name="overtime" checked={overtime === true} onClick={() => setOvertime(true)} />
              <p>みなし残業代を含む</p>
            </div>
            <div>
              <input id="overtime" type="radio" name="overtime" checked={overtime === false} onClick={() => setOvertime(false)} />
              <p>みなし残業代を含まない</p>
            </div>
          </div>
          <p className={Styles.desc}>　</p>

          {overtime ?
            <DefaultTextarea1
              title={'みなし残業代の備考'} required={true} length={400} type={'text'}
              place={'上記額には固定残業代(20時間分、35,000円)を含みます。超過分は全額支給します。'}
              value={overtimeOther} setValue={setOvertimeOther}
              desc={'※固定残業代（みなし残業代）を賃金に含める場合は、以下2点をすべて記載してください。\n（1）固定残業代に該当する金額と、固定残業に含まれる時間\n（2）上記を超える際は割増賃金を追加で支払う旨\n'}
            />
          : <></> }

          <DefaultSelect2 
            title={'勤務時間'} required={true} 
            value1={workingHoursStart} setValue1={setWorkingHoursStart}
            list1={['未選択', '6:00', '6:30', '7:00', '7:30', '8:00', '8:30', '9:00', '9:30', '10:00', '10:30', '11:00', '11:30', '12:00',]}
            value2={workingHoursEnd} setValue2={setWorkingHoursEnd}
            list2={['未選択', '18:00', '18:30', '19:00', '19:30', '20:00', '20:30', '21:00', '21:30', '22:00', '22:30', '23:00', '23:30', '24:00',]}
            desc={''}
          />

          <DefaultTitle title={'休日・休暇'} required={false} />
          <div className={Styles.check}>
            <div>
              <input type='checkbox' checked={holiday.indexOf('年次有給休暇') !== -1}
                onChange={(e) => 
                  e.target.checked ? setHoliday([...holiday, '年次有給休暇']) : setHoliday(holiday.filter((v, index) => (v !== '年次有給休暇')))
                }/>
              <p>年次有給休暇</p>
            </div>
            <div>
              <input type='checkbox' checked={holiday.indexOf('産前休業') !== -1}
                onChange={(e) => 
                  e.target.checked ? setHoliday([...holiday, '産前休業']) : setHoliday(holiday.filter((v, index) => (v !== '産前休業')))
                }/>
              <p>産前休業</p>
            </div>
            <div>
              <input type='checkbox' checked={holiday.indexOf('育児休業') !== -1}
                onChange={(e) => 
                  e.target.checked ? setHoliday([...holiday, '育児休業']) : setHoliday(holiday.filter((v, index) => (v !== '育児休業')))
                }/>
              <p>育児休業</p>
            </div>
          </div>
          <div className={Styles.check}>
            <div>
              <input type='checkbox' checked={holiday.indexOf('介護休業') !== -1}
                onChange={(e) => 
                  e.target.checked ? setHoliday([...holiday, '介護休業']) : setHoliday(holiday.filter((v, index) => (v !== '介護休業')))
                }/>
              <p>介護休業</p>
            </div>
            <div>
              <input type='checkbox' checked={holiday.indexOf('慶弔休暇') !== -1}
                onChange={(e) => 
                  e.target.checked ? setHoliday([...holiday, '慶弔休暇']) : setHoliday(holiday.filter((v, index) => (v !== '慶弔休暇')))
                }/>
              <p>慶弔休暇</p>
            </div>
            <div>
              <input type='checkbox' checked={holiday.indexOf('夏季休暇') !== -1}
                onChange={(e) => 
                  e.target.checked ? setHoliday([...holiday, '夏季休暇']) : setHoliday(holiday.filter((v, index) => (v !== '夏季休暇')))
                }/>
              <p>夏季休暇</p>
            </div>
          </div>
          <div className={Styles.check}>
            <div>
              <input type='checkbox' checked={holiday.indexOf('冬期休暇') !== -1}
                onChange={(e) => 
                  e.target.checked ? setHoliday([...holiday, '冬期休暇']) : setHoliday(holiday.filter((v, index) => (v !== '冬期休暇')))
                }/>
              <p>冬期休暇</p>
            </div>
            <div>
              <input type='checkbox' checked={holiday.indexOf('年末年始休暇') !== -1}
                onChange={(e) => 
                  e.target.checked ? setHoliday([...holiday, '年末年始休暇']) : setHoliday(holiday.filter((v, index) => (v !== '年末年始休暇')))
                }/>
              <p>年末年始休暇</p>
            </div>
            <div>
              <input type='checkbox' checked={holidayCheck} onChange={(e) => setHolidayCheck(e.target.checked)} />
              <p>その他</p>
            </div>
          </div>

          {holidayCheck ?
          <>
            <DefaultInput1
              title={'その他'} required={true} length={40} place={'リフレッシュ休暇、資格取得休暇、ボランティア休暇　等'} type={'text'}
              value={holidayOther} setValue={setHolidayOther}
              desc={''}
            />
          </> : <p className='memorii_recruit_body_page_company_edit_input_area_desc_none'>　</p> }

          <DefaultTitle title={'福利厚生'} required={false} />
          <div className={Styles.check}>
            <div>
              <input type='checkbox' checked={welfare.indexOf('雇用保険') !== -1}
                onChange={(e) => {
                  e.target.checked ? setWelfare([...welfare, '雇用保険']) : setWelfare(welfare.filter((v, index) => (v !== '雇用保険')))
                }}/>
              <p>雇用保険</p>
            </div>
            <div>
              <input type='checkbox' checked={welfare.indexOf('労災保険') !== -1}
                onChange={(e) => {
                  e.target.checked ? setWelfare([...welfare, '労災保険']) : setWelfare(welfare.filter((v, index) => (v !== '労災保険')))
                }}/>
              <p>労災保険</p>
            </div>
            <div>
              <input type='checkbox' checked={welfare.indexOf('厚生年金') !== -1}
                onChange={(e) => {
                  e.target.checked ? setWelfare([...welfare, '厚生年金']) : setWelfare(welfare.filter((v, index) => (v !== '厚生年金')))
                }}/>
              <p>厚生年金</p>
            </div>
          </div>
          <div className={Styles.check}>
            <div>
              <input type='checkbox' checked={welfare.indexOf('健康保険') !== -1}
                onChange={(e) => {
                  e.target.checked ? setWelfare([...welfare, '健康保険']) : setWelfare(welfare.filter((v, index) => (v !== '健康保険')))
                }}/>
              <p>健康保険</p>
            </div>
            <div>
              <input type='checkbox' checked={welfare.indexOf('交通費支給') !== -1}
                onChange={(e) => {
                  e.target.checked ? setWelfare([...welfare, '交通費支給']) : setWelfare(welfare.filter((v, index) => (v !== '交通費支給')))
                }}/>
              <p>交通費支給</p>
            </div>
            <div>
              <input type='checkbox' checked={welfare.indexOf('資格取得手当') !== -1}
                onChange={(e) => {
                  e.target.checked ? setWelfare([...welfare, '資格取得手当']) : setWelfare(welfare.filter((v, index) => (v !== '資格取得手当')))
                }}/>
              <p>資格取得手当</p>
            </div>
          </div>
          <div className={Styles.check}>
            <div>
              <input type='checkbox' checked={welfareCheck} onChange={(e) => setWelfareCheck(e.target.checked)} />
              <p>その他</p>
            </div>
            <div></div>
            <div></div>
            <div></div>
          </div>

          {welfareCheck ?
          <DefaultInput1
            title={'その他'} required={true} length={40} place={'昼食まかない制度、運動不足改善支援、友人施術費用半額制度　等'} type={'text'}
            value={welfareOther} setValue={setWelfareOther}
            desc={''}
          /> : <p className={Styles.desc}></p> }

          <DefaultTextarea1
            title={'給与体系・福利厚生に関する備考'} required={true} length={400} type={'text'} 
            place={'【インセンティブ】\n指名料：40%・店頭販売：20%'}
            value={salaryWelfare} setValue={setSalaryWelfare}
            desc={''}
          />
        </div>

        <div className={Styles.save_box}>
          <button className={Styles.save_box_now} onClick={() => setBackStatus(true)}>戻る</button>
          <button className={Styles.save_box_now} onClick={() => save(false)}>一時保存</button>
          <button className={Styles.save_box_save} onClick={() => check()}>保存</button>
        </div>
        
      </div>
    </div>

    {backStatus ?
      <BackModal setBackStatus={setBackStatus} />
    : <></> }
    </>
  );
}

export default RecruitRecruit;