import Styles from './styles.module.css';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import firebase from "firebase/compat/app";
import ErrorMessage from '../../../../package/parts/error/index';
import Header from "../0.header/index";
import Items from './items/index';
import BackModal from '../12.back/index';
import DefaultTitle from '../../../../package/parts/title/index';

const RecruitExamination: React.FC = () => {

  const navigate = useNavigate();

  const link: string = new URL(window.location.href).href;
  const recruitLink = decodeURIComponent(link.slice(link.indexOf('id=') + 3, link.length));

  const [ saveBtn, setSaveBtn ] = useState(false);

  const [ errorMessage, setErrorMessage ] = useState('');

  const [ backStatus, setBackStatus ] = useState(false);

  // 選考数
  const [ selection, setSelection ] = useState(0);

  // 1次選考 評価
  const [ selection1Count, setSelection1Count ] = useState<number>(0);
  const [ selection1Contents, setSelection1Contents ] = useState<string>('');

  const [ selection1Text1, setSelection1Text1 ] = useState<string | number>('');
  const [ selection1Text2, setSelection1Text2 ] = useState<string | number>('');
  const [ selection1Text3, setSelection1Text3 ] = useState<string | number>('');
  const [ selection1Text4, setSelection1Text4 ] = useState<string | number>('');
  const [ selection1Text5, setSelection1Text5 ] = useState<string | number>('');
  const [ selection1Text6, setSelection1Text6 ] = useState<string | number>('');
  const [ selection1Text7, setSelection1Text7 ] = useState<string | number>('');
  const [ selection1Text8, setSelection1Text8 ] = useState<string | number>('');

  // 二次選考 評価
  const [ selection2Count, setSelection2Count ] = useState<number>(0);
  const [ selection2Contents, setSelection2Contents ] = useState<string>('');

  const [ selection2Text1, setSelection2Text1 ] = useState<string | number>('');
  const [ selection2Text2, setSelection2Text2 ] = useState<string | number>('');
  const [ selection2Text3, setSelection2Text3 ] = useState<string | number>('');
  const [ selection2Text4, setSelection2Text4 ] = useState<string | number>('');
  const [ selection2Text5, setSelection2Text5 ] = useState<string | number>('');
  const [ selection2Text6, setSelection2Text6 ] = useState<string | number>('');
  const [ selection2Text7, setSelection2Text7 ] = useState<string | number>('');
  const [ selection2Text8, setSelection2Text8 ] = useState<string | number>('');

  // 3次選考 評価
  const [ selection3Count, setSelection3Count ] = useState<number>(0);
  const [ selection3Contents, setSelection3Contents ] = useState<string>('');

  const [ selection3Text1, setSelection3Text1 ] = useState<string | number>('');
  const [ selection3Text2, setSelection3Text2 ] = useState<string | number>('');
  const [ selection3Text3, setSelection3Text3 ] = useState<string | number>('');
  const [ selection3Text4, setSelection3Text4 ] = useState<string | number>('');
  const [ selection3Text5, setSelection3Text5 ] = useState<string | number>('');
  const [ selection3Text6, setSelection3Text6 ] = useState<string | number>('');
  const [ selection3Text7, setSelection3Text7 ] = useState<string | number>('');
  const [ selection3Text8, setSelection3Text8 ] = useState<string | number>('');

  // 4次選考 評価
  const [ selection4Count, setSelection4Count ] = useState<number>(0);
  const [ selection4Contents, setSelection4Contents ] = useState<string>('');

  const [ selection4Text1, setSelection4Text1 ] = useState<string | number>('');
  const [ selection4Text2, setSelection4Text2 ] = useState<string | number>('');
  const [ selection4Text3, setSelection4Text3 ] = useState<string | number>('');
  const [ selection4Text4, setSelection4Text4 ] = useState<string | number>('');
  const [ selection4Text5, setSelection4Text5 ] = useState<string | number>('');
  const [ selection4Text6, setSelection4Text6 ] = useState<string | number>('');
  const [ selection4Text7, setSelection4Text7 ] = useState<string | number>('');
  const [ selection4Text8, setSelection4Text8 ] = useState<string | number>('');

  // 5次選考 評価
  const [ selection5Count, setSelection5Count ] = useState<number>(0);
  const [ selection5Contents, setSelection5Contents ] = useState<string>('');

  const [ selection5Text1, setSelection5Text1 ] = useState<string | number>('');
  const [ selection5Text2, setSelection5Text2 ] = useState<string | number>('');
  const [ selection5Text3, setSelection5Text3 ] = useState<string | number>('');
  const [ selection5Text4, setSelection5Text4 ] = useState<string | number>('');
  const [ selection5Text5, setSelection5Text5 ] = useState<string | number>('');
  const [ selection5Text6, setSelection5Text6 ] = useState<string | number>('');
  const [ selection5Text7, setSelection5Text7 ] = useState<string | number>('');
  const [ selection5Text8, setSelection5Text8 ] = useState<string | number>('');


  useEffect(() => {
    firebase.firestore().collection("recruits").doc(recruitLink).get()
    .then((doc) => {
      const data = doc.data();
      if (data) {
        setSaveBtn(data.alreadyPublic ?? false);

        setSelection(data.exam_selection ?? 0);
  
        // 1次選考
        setSelection1Count(data.exam_selection1Count ?? 0);
        setSelection1Contents(data.exam_selection1Contents ?? '');
        setSelection1Text1(data.exam_selection1Text1 ?? '');
        setSelection1Text2(data.exam_selection1Text2 ?? '');
        setSelection1Text3(data.exam_selection1Text3 ?? '');
        setSelection1Text4(data.exam_selection1Text4 ?? '');
        setSelection1Text5(data.exam_selection1Text5 ?? '');
        setSelection1Text6(data.exam_selection1Text6 ?? '');
        setSelection1Text7(data.exam_selection1Text7 ?? '');
        setSelection1Text8(data.exam_selection1Text8 ?? '');
  
        // 二次選考
        setSelection2Count(data.exam_selection2Count ?? 0);
        setSelection2Contents(data.exam_selection2Contents ?? '');
        setSelection2Text1(data.exam_selection2Text1 ?? '');
        setSelection2Text2(data.exam_selection2Text2 ?? '');
        setSelection2Text3(data.exam_selection2Text3 ?? '');
        setSelection2Text4(data.exam_selection2Text4 ?? '');
        setSelection2Text5(data.exam_selection2Text5 ?? '');
        setSelection2Text6(data.exam_selection2Text6 ?? '');
        setSelection2Text7(data.exam_selection2Text7 ?? '');
        setSelection2Text8(data.exam_selection2Text8 ?? '');
  
        // 3次選考
        setSelection3Count(data.exam_selection3Count ?? 0);
        setSelection3Contents(data.exam_selection3Contents ?? '');
        setSelection3Text1(data.exam_selection3Text1 ?? '');
        setSelection3Text2(data.exam_selection3Text2 ?? '');
        setSelection3Text3(data.exam_selection3Text3 ?? '');
        setSelection3Text4(data.exam_selection3Text4 ?? '');
        setSelection3Text5(data.exam_selection3Text5 ?? '');
        setSelection3Text6(data.exam_selection3Text6 ?? '');
        setSelection3Text7(data.exam_selection3Text7 ?? '');
        setSelection3Text8(data.exam_selection3Text8 ?? '');
  
        // 4次選考
        setSelection4Count(data.exam_selection4Count ?? 0);
        setSelection4Contents(data.exam_selection4Contents ?? '');
        setSelection4Text1(data.exam_selection4Text1 ?? '');
        setSelection4Text2(data.exam_selection4Text2 ?? '');
        setSelection4Text3(data.exam_selection4Text3 ?? '');
        setSelection4Text4(data.exam_selection4Text4 ?? '');
        setSelection4Text5(data.exam_selection4Text5 ?? '');
        setSelection4Text6(data.exam_selection4Text6 ?? '');
        setSelection4Text7(data.exam_selection4Text7 ?? '');
        setSelection4Text8(data.exam_selection4Text8 ?? '');
        
        // 5次選考
        setSelection5Count(data.exam_selection5Count ?? 0);
        setSelection5Contents(data.exam_selection5Contents ?? '');
        setSelection5Text1(data.exam_selection5Text1 ?? '');
        setSelection5Text2(data.exam_selection5Text2 ?? '');
        setSelection5Text3(data.exam_selection5Text3 ?? '');
        setSelection5Text4(data.exam_selection5Text4 ?? '');
        setSelection5Text5(data.exam_selection5Text5 ?? '');
        setSelection5Text6(data.exam_selection5Text6 ?? '');
        setSelection5Text7(data.exam_selection5Text7 ?? '');
        setSelection5Text8(data.exam_selection5Text8 ?? '');
      }
    });
  
  }, [])

  function check1(e: boolean) {
    window.scrollTo(0, 0);
    setErrorMessage('');

    if (1 <= selection && selection <= 5) {
      if (selection1Contents === '') {
        setErrorMessage('1次選考の選考方法を選択してください。');
      } else if (selection1Count === 0) {
        setErrorMessage('1次選考の評価数を選択してください。');
      } else if (1 <= selection1Count && selection1Count <= 8 && selection1Text1 === '') {
        setErrorMessage('1次選考の評価①を入力してください。');
      } else if (2 <= selection1Count && selection1Count <= 8 && selection1Text2 === '') {
        setErrorMessage('1次選考の評価②を入力してください。');
      } else if (3 <= selection1Count && selection1Count <= 8 && selection1Text3 === '') {
        setErrorMessage('1次選考の評価③を入力してください。');
      } else if (4 <= selection1Count && selection1Count <= 8 && selection1Text4 === '') {
        setErrorMessage('1次選考の評価④を入力してください。');
      } else if (5 <= selection1Count && selection1Count <= 8 && selection1Text5 === '') {
        setErrorMessage('1次選考の評価⑤を入力してください。');
      } else if (6 <= selection1Count && selection1Count <= 8 && selection1Text6 === '') {
        setErrorMessage('1次選考の評価⑥を入力してください。');
      } else if (7 <= selection1Count && selection1Count <= 8 && selection1Text7 === '') {
        setErrorMessage('1次選考の評価⑦を入力してください。');
      } else if (8 === selection1Count && selection1Text8 === '') {
        setErrorMessage('1次選考の評価⑧を入力してください。');
      } else {
        check2(e);
      }
    } else {
      save(e);
    }
  }

  function check2(e: boolean) {
    window.scrollTo(0, 0);
    setErrorMessage('');

    if (2 <= selection && selection <= 5) {
      if (selection2Contents === '') {
        setErrorMessage('2次選考の選考方法を選択してください。');
      } else if (selection2Count === 0) {
        setErrorMessage('2次選考の評価数を選択してください。');
      } else if (1 <= selection2Count && selection2Count <= 8 && selection2Text1 === '') {
        setErrorMessage('2次選考の評価①を入力してください。');
      } else if (2 <= selection2Count && selection2Count <= 8 && selection2Text2 === '') {
        setErrorMessage('2次選考の評価②を入力してください。');
      } else if (3 <= selection2Count && selection2Count <= 8 && selection2Text3 === '') {
        setErrorMessage('2次選考の評価③を入力してください。');
      } else if (4 <= selection2Count && selection2Count <= 8 && selection2Text4 === '') {
        setErrorMessage('2次選考の評価④を入力してください。');
      } else if (5 <= selection2Count && selection2Count <= 8 && selection2Text5 === '') {
        setErrorMessage('2次選考の評価⑤を入力してください。');
      } else if (6 <= selection2Count && selection2Count <= 8 && selection2Text6 === '') {
        setErrorMessage('2次選考の評価⑥を入力してください。');
      } else if (7 <= selection2Count && selection2Count <= 8 && selection2Text7 === '') {
        setErrorMessage('2次選考の評価⑦を入力してください。');
      } else if (8 === selection2Count && selection2Text8 === '') {
        setErrorMessage('2次選考の評価⑧を入力してください。');
      } else {
        check3(e);
      }
    } else {
      save(e);
    }
  }

  function check3(e: boolean) {
    window.scrollTo(0, 0);
    setErrorMessage('');

    if (3 <= selection && selection <= 5) {
      if (selection3Contents === '') {
        setErrorMessage('3次選考の選考方法を選択してください。');
      } else if (selection3Count === 0) {
        setErrorMessage('3次選考の評価数を選択してください。');
      } else if (1 <= selection3Count && selection3Count <= 8 && selection3Text1 === '') {
        setErrorMessage('3次選考の評価①を入力してください。');
      } else if (2 <= selection3Count && selection3Count <= 8 && selection3Text2 === '') {
        setErrorMessage('3次選考の評価②を入力してください。');
      } else if (3 <= selection3Count && selection3Count <= 8 && selection3Text3 === '') {
        setErrorMessage('3次選考の評価③を入力してください。');
      } else if (4 <= selection3Count && selection3Count <= 8 && selection3Text4 === '') {
        setErrorMessage('3次選考の評価④を入力してください。');
      } else if (5 <= selection3Count && selection3Count <= 8 && selection3Text5 === '') {
        setErrorMessage('3次選考の評価⑤を入力してください。');
      } else if (6 <= selection3Count && selection3Count <= 8 && selection3Text6 === '') {
        setErrorMessage('3次選考の評価⑥を入力してください。');
      } else if (7 <= selection3Count && selection3Count <= 8 && selection3Text7 === '') {
        setErrorMessage('3次選考の評価⑦を入力してください。');
      } else if (8 === selection3Count && selection3Text8 === '') {
        setErrorMessage('3次選考の評価⑧を入力してください。');
      } else {
        check4(e);
      }
    } else {
      save(e);
    }
  }

  function check4(e: boolean) {
    window.scrollTo(0, 0);
    setErrorMessage('');

    if (4 <= selection && selection <= 5) {
      if (selection4Contents === '') {
        setErrorMessage('4次選考の選考方法を選択してください。');
      } else if (selection4Count === 0) {
        setErrorMessage('4次選考の評価数を選択してください。');
      } else if (1 <= selection4Count && selection4Count <= 8 && selection4Text1 === '') {
        setErrorMessage('4次選考の評価①を入力してください。');
      } else if (2 <= selection4Count && selection4Count <= 8 && selection4Text2 === '') {
        setErrorMessage('4次選考の評価②を入力してください。');
      } else if (3 <= selection4Count && selection4Count <= 8 && selection4Text3 === '') {
        setErrorMessage('4次選考の評価③を入力してください。');
      } else if (4 <= selection4Count && selection4Count <= 8 && selection4Text4 === '') {
        setErrorMessage('4次選考の評価④を入力してください。');
      } else if (5 <= selection4Count && selection4Count <= 8 && selection4Text5 === '') {
        setErrorMessage('4次選考の評価⑤を入力してください。');
      } else if (6 <= selection4Count && selection4Count <= 8 && selection4Text6 === '') {
        setErrorMessage('4次選考の評価⑥を入力してください。');
      } else if (7 <= selection4Count && selection4Count <= 8 && selection4Text7 === '') {
        setErrorMessage('4次選考の評価⑦を入力してください。');
      } else if (8 === selection4Count && selection4Text8 === '') {
        setErrorMessage('4次選考の評価⑧を入力してください。');
      } else {
        check5(e);
      }
    } else {
      save(e);
    }
  }

  function check5(e: boolean) {
    window.scrollTo(0, 0);
    setErrorMessage('');

    if (5 === selection) {
      if (selection5Contents === '') {
        setErrorMessage('5次選考の選考方法を選択してください。');
      } else if (selection5Count === 0) {
        setErrorMessage('5次選考の評価数を選択してください。');
      } else if (1 <= selection5Count && selection5Count <= 8 && selection5Text1 === '') {
        setErrorMessage('5次選考の評価①を入力してください。');
      } else if (2 <= selection5Count && selection5Count <= 8 && selection5Text2 === '') {
        setErrorMessage('5次選考の評価②を入力してください。');
      } else if (3 <= selection5Count && selection5Count <= 8 && selection5Text3 === '') {
        setErrorMessage('5次選考の評価③を入力してください。');
      } else if (4 <= selection5Count && selection5Count <= 8 && selection5Text4 === '') {
        setErrorMessage('5次選考の評価④を入力してください。');
      } else if (5 <= selection5Count && selection5Count <= 8 && selection5Text5 === '') {
        setErrorMessage('5次選考の評価⑤を入力してください。');
      } else if (6 <= selection5Count && selection5Count <= 8 && selection5Text6 === '') {
        setErrorMessage('5次選考の評価⑥を入力してください。');
      } else if (7 <= selection5Count && selection5Count <= 8 && selection5Text7 === '') {
        setErrorMessage('5次選考の評価⑦を入力してください。');
      } else if (8 === selection5Count && selection5Text8 === '') {
        setErrorMessage('5次選考の評価⑧を入力してください。');
      } else {
        save(e);
      }
    } else {
      save(e);
    }
  }

  function save(e: boolean) {

    firebase.firestore().collection("recruits").doc(recruitLink)
    .update({
      status_examination: e,

      exam_selection: Number(selection),

      // 1次選考
      exam_selection1Count: Number(selection1Count),
      exam_selection1Contents: selection1Contents,
      exam_selection1Text1: selection1Text1,
      exam_selection1Text2: selection1Text2,
      exam_selection1Text3: selection1Text3,
      exam_selection1Text4: selection1Text4,
      exam_selection1Text5: selection1Text5,
      exam_selection1Text6: selection1Text6,
      exam_selection1Text7: selection1Text7,
      exam_selection1Text8: selection1Text8,

      // 二次選考
      exam_selection2Count: Number(selection2Count),
      exam_selection2Contents: selection2Contents,
      exam_selection2Text1: selection2Text1,
      exam_selection2Text2: selection2Text2,
      exam_selection2Text3: selection2Text3,
      exam_selection2Text4: selection2Text4,
      exam_selection2Text5: selection2Text5,
      exam_selection2Text6: selection2Text6,
      exam_selection2Text7: selection2Text7,
      exam_selection2Text8: selection2Text8,

      // 3次選考
      exam_selection3Count: Number(selection3Count),
      exam_selection3Contents: selection3Contents,
      exam_selection3Text1: selection3Text1,
      exam_selection3Text2: selection3Text2,
      exam_selection3Text3: selection3Text3,
      exam_selection3Text4: selection3Text4,
      exam_selection3Text5: selection3Text5,
      exam_selection3Text6: selection3Text6,
      exam_selection3Text7: selection3Text7,
      exam_selection3Text8: selection3Text8,

      // 4次選考
      exam_selection4Count: Number(selection4Count),
      exam_selection4Contents: selection4Contents,
      exam_selection4Text1: selection4Text1,
      exam_selection4Text2: selection4Text2,
      exam_selection4Text3: selection4Text3,
      exam_selection4Text4: selection4Text4,
      exam_selection4Text5: selection4Text5,
      exam_selection4Text6: selection4Text6,
      exam_selection4Text7: selection4Text7,
      exam_selection4Text8: selection4Text8,
      
      // 5次選考
      exam_selection5Count: Number(selection5Count),
      exam_selection5Contents: selection5Contents,
      exam_selection5Text1: selection5Text1,
      exam_selection5Text2: selection5Text2,
      exam_selection5Text3: selection5Text3,
      exam_selection5Text4: selection5Text4,
      exam_selection5Text5: selection5Text5,
      exam_selection5Text6: selection5Text6,
      exam_selection5Text7: selection5Text7,
      exam_selection5Text8: selection5Text8,

    }).then(() => {
      navigate(`/edit/id=${recruitLink}`)
    })

  }

  return (
    <>
    <Header />

    <div className={Styles.box}>
      
      <div className={Styles.box_inline}>

        <p className={Styles.desc} style={{color : '#AD0200', fontWeight : 'bold', textAlign : 'center'}}>注意事項：このページは一度保存すると、変更することはできません。</p>
        <p className={Styles.desc}>　</p>

        {errorMessage !== '' ?
          <ErrorMessage message={errorMessage} />
        : <></> }

        <div className={Styles.contents}>
          <h5 className={Styles.title}>選考回数</h5>
          <DefaultTitle title={'選考回数'} required={true} />
          <select className={Styles.select}
            value={selection} onChange={(e) => setSelection(Number(e.target.value))}>
            <option value={0}>未選択</option>
            <option value={1}>1回</option>
            <option value={2}>2回</option>
            <option value={3}>3回</option>
            <option value={4}>4回</option>
            <option value={5}>5回</option>
          </select>
          <p className={Styles.desc}>　</p>
          <p className={Styles.desc}>　</p>
        </div>

        {1 <= selection && selection <= 5 ?
          <Items 
            contents={selection1Contents}
            setContents={setSelection1Contents}
            count={selection1Count}
            setCount={setSelection1Count}

            selectionText1={selection1Text1}
            setSelectionText1={setSelection1Text1}
            selectionText2={selection1Text2}
            setSelectionText2={setSelection1Text2}
            selectionText3={selection1Text3}
            setSelectionText3={setSelection1Text3}
            selectionText4={selection1Text4}
            setSelectionText4={setSelection1Text4}
            selectionText5={selection1Text5}
            setSelectionText5={setSelection1Text5}
            selectionText6={selection1Text6}
            setSelectionText6={setSelection1Text6}
            selectionText7={selection1Text7}
            setSelectionText7={setSelection1Text7}
            selectionText8={selection1Text8}
            setSelectionText8={setSelection1Text8}
          />
        : <></> }

        {2 <= selection && selection <= 5 ?
          <Items 
            contents={selection2Contents}
            setContents={setSelection2Contents}
            count={selection2Count}
            setCount={setSelection2Count}

            selectionText1={selection2Text1}
            setSelectionText1={setSelection2Text1}
            selectionText2={selection2Text2}
            setSelectionText2={setSelection2Text2}
            selectionText3={selection2Text3}
            setSelectionText3={setSelection2Text3}
            selectionText4={selection2Text4}
            setSelectionText4={setSelection2Text4}
            selectionText5={selection2Text5}
            setSelectionText5={setSelection2Text5}
            selectionText6={selection2Text6}
            setSelectionText6={setSelection2Text6}
            selectionText7={selection2Text7}
            setSelectionText7={setSelection2Text7}
            selectionText8={selection2Text8}
            setSelectionText8={setSelection2Text8}
          />
        : <></> }

        {3 <= selection && selection <= 5 ?
          <Items 
            contents={selection3Contents}
            setContents={setSelection3Contents}
            count={selection3Count}
            setCount={setSelection3Count}

            selectionText1={selection3Text1}
            setSelectionText1={setSelection3Text1}
            selectionText2={selection3Text2}
            setSelectionText2={setSelection3Text2}
            selectionText3={selection3Text3}
            setSelectionText3={setSelection3Text3}
            selectionText4={selection3Text4}
            setSelectionText4={setSelection3Text4}
            selectionText5={selection3Text5}
            setSelectionText5={setSelection3Text5}
            selectionText6={selection3Text6}
            setSelectionText6={setSelection3Text6}
            selectionText7={selection3Text7}
            setSelectionText7={setSelection3Text7}
            selectionText8={selection3Text8}
            setSelectionText8={setSelection3Text8}
          />
        : <></> }

        {4 <= selection && selection <= 5 ?
          <Items 
            contents={selection4Contents}
            setContents={setSelection4Contents}
            count={selection4Count}
            setCount={setSelection4Count}

            selectionText1={selection4Text1}
            setSelectionText1={setSelection4Text1}
            selectionText2={selection4Text2}
            setSelectionText2={setSelection4Text2}
            selectionText3={selection4Text3}
            setSelectionText3={setSelection4Text3}
            selectionText4={selection4Text4}
            setSelectionText4={setSelection4Text4}
            selectionText5={selection4Text5}
            setSelectionText5={setSelection4Text5}
            selectionText6={selection4Text6}
            setSelectionText6={setSelection4Text6}
            selectionText7={selection4Text7}
            setSelectionText7={setSelection4Text7}
            selectionText8={selection4Text8}
            setSelectionText8={setSelection4Text8}
          />
        : <></> }

        {5 <= selection && selection <= 5 ?
          <Items 
            contents={selection5Contents}
            setContents={setSelection5Contents}
            count={selection5Count}
            setCount={setSelection5Count}

            selectionText1={selection5Text1}
            setSelectionText1={setSelection5Text1}
            selectionText2={selection5Text2}
            setSelectionText2={setSelection5Text2}
            selectionText3={selection5Text3}
            setSelectionText3={setSelection5Text3}
            selectionText4={selection5Text4}
            setSelectionText4={setSelection5Text4}
            selectionText5={selection5Text5}
            setSelectionText5={setSelection5Text5}
            selectionText6={selection5Text6}
            setSelectionText6={setSelection5Text6}
            selectionText7={selection5Text7}
            setSelectionText7={setSelection5Text7}
            selectionText8={selection5Text8}
            setSelectionText8={setSelection5Text8}
          />
        : <></> }

        {!saveBtn ?
          <div className={Styles.save_box}>
            <button className={Styles.save_box_now} onClick={() => setBackStatus(true)}>戻る</button>
            <button className={Styles.save_box_now} onClick={() => save(false)}>一時保存</button>
            <button className={Styles.save_box_save} onClick={() => check1(true)}>保存</button>
          </div>
          :
          <p className={Styles.desc}>既に保存されているため、変更する事はできません。</p>
        }

      </div>
    </div>

    {backStatus ?
      <BackModal setBackStatus={setBackStatus} />
    : <></> }
    </>
  );
}

export default RecruitExamination;
