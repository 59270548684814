import './styles.css';
import React, { useState, useEffect } from 'react';
import firebase from 'firebase/compat/app';
import { FaAward } from "react-icons/fa6";

type DetailsPhotoMobileProps = {
  uid: string;
};

const DetailsPhotoMobile: React.FC<DetailsPhotoMobileProps> = ({ uid }) => {

  const [ photoList, setPhotoList ] = useState<string[]>([]);
  const [ score, setScore ] = useState(0);

  useEffect(() => {

    firebase.firestore().collection('posts').where('post_uid', '==', uid).orderBy("post_time", 'asc')
    .get().then(snapShot => {
      var tmp: string[] = [];
      snapShot.forEach((doc) => {
        tmp.push(doc.id)
      });
      setPhotoList(tmp);
    });

    firebase.firestore().collection("users").doc(uid).get()
    .then((doc) => {
      const data = doc.data();
      if (data) {
        setScore(data.memorii_score);
      }
    })

  }, []);

  return (
    <>
    <div className='works_service_info_score'>
      <FaAward className='works_service_info_score_icon' />
      <h4>memorii score</h4>
      <p>{score}</p>
    </div>

    <h5 className='works_service_info_post_title'>投稿済み作品撮り</h5>
    {photoList.map((photo, index) =>
      <img className='works_service_info_post' key={index} src={`https://firebasestorage.googleapis.com/v0/b/trankllc-com.appspot.com/o/image%2Fresize_images%2F${photo}_500x500?alt=media&token=`} alt="美容学生作品撮り" />
    )}
    </>
  );
}

export default DetailsPhotoMobile;


