import Styles from './styles.module.css';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../../../../0.header/index';
import firebase from "firebase/compat/app";
import ErrorMessage from '../../../../../../../package/parts/error';
import DefaultStation from '../../../../../../../package/parts/station/index';
import DefaultTitle from '../../../../../../../package/parts/title';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import BackModal from '../11.back/index';
import DefaultInput1 from '../../../../../../../package/parts/1-input';
import DefaultSelect1 from '../../../../../../../package/parts/1-select';

const RecruitEditStorPlace: React.FC = () => {

  const navigate = useNavigate();

  const link: string = new URL(window.location.href).href;
  const store = decodeURIComponent(link.slice(link.indexOf('id=') + 3, link.length));

  const [ backStatus, setBackStatus ] = useState(false);
  
  const [ errorMessage, setErrorMessage ] = useState('');

  // 店舗1住所
  const [ storePostCode, setStorePostCode ] = useState('');
  const [ storeAddress, setStoreAddress ] = useState<string | number>('');
  
  const [ storeCount, setStationCount ] = useState(0);
  // 最寄り駅①
  const [ station1Name, setStation1Name ] = useState('');
  const [ station1Time, setStation1Time ] = useState('');
  // 最寄り駅②
  const [ station2Name, setStation2Name ] = useState('');
  const [ station2Time, setStation2Time ] = useState('');
  // 最寄り駅③
  const [ station3Name, setStation3Name ] = useState('');
  const [ station3Time, setStation3Time ] = useState('');

  const [ statusPentagon, setStatusPentagon ] = useState(false);
  const [ statusType, setStatusType ] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);

    return onAuthStateChanged(getAuth(), (user) => {
      if (user) {
        firebase.firestore().collection("stores").doc(store)
        .get().then((doc) => {
          const data = doc.data();
          if (data) {
            setStorePostCode(data.postCode);
            setStoreAddress(data.address);
            setStationCount(data.stationCount);
  
            setStation1Name(data.station1Name);
            setStation1Time(data.station1Time);
            setStation2Name(data.station2Name);
            setStation2Time(data.station2Time);
            setStation3Name(data.station3Name);
            setStation3Time(data.station3Time);
  
            setStatusPentagon(data.status_pentagon);
            setStatusType(data.status_type);
          }
        });
      } else {
        navigate('/company/login')
      }
    })

  }, [store])

  var train = [
    [ "函館駅", "北海道", "JR函館本線" ],
    [ "五稜郭駅", "北海道", "JR函館本線" ],
    [ "桔梗駅", "北海道", "JR函館本線" ],
    [ "大中山駅", "北海道", "JR函館本線" ],
    [ "七飯駅", "北海道", "JR函館本線" ],
    [ "新函館北斗駅", "北海道", "JR函館本線" ],
    [ "仁山駅", "北海道", "JR函館本線" ],
    [ "大沼駅", "北海道", "JR函館本線" ],
    [ "大沼公園駅", "北海道", "JR函館本線" ],
    [ "赤井川駅", "北海道", "JR函館本線" ],
    [ "駒ケ岳駅", "北海道", "JR函館本線" ],
    [ "東山駅", "北海道", "JR函館本線" ],
    [ "姫川駅", "北海道", "JR函館本線" ],
    [ "池田園駅", "北海道", "JR函館本線" ],
    [ "流山温泉駅", "北海道", "JR函館本線" ],
    [ "銚子口駅", "北海道", "JR函館本線" ],
    [ "鹿部駅", "北海道", "JR函館本線" ],
    [ "渡島沼尻駅", "北海道", "JR函館本線" ],
    [ "渡島砂原駅", "北海道", "JR函館本線" ],
    [ "掛澗駅", "北海道", "JR函館本線" ],
    [ "尾白内駅", "北海道", "JR函館本線" ],
    [ "東森駅", "北海道", "JR函館本線" ],
    [ "森駅", "北海道", "JR函館本線" ],
    [ "桂川駅", "北海道", "JR函館本線" ],
    [ "石谷駅", "北海道", "JR函館本線" ],
    [ "本石倉駅", "北海道", "JR函館本線" ],
    [ "石倉駅", "北海道", "JR函館本線" ],
    [ "落部駅", "北海道", "JR函館本線" ],
    [ "野田生駅", "北海道", "JR函館本線" ],
    [ "山越駅", "北海道", "JR函館本線" ],
    [ "八雲駅", "北海道", "JR函館本線" ],
    [ "鷲ノ巣駅", "北海道", "JR函館本線" ],
    [ "山崎駅", "北海道", "JR函館本線" ],
    [ "黒岩駅", "北海道", "JR函館本線" ],
    [ "北豊津駅", "北海道", "JR函館本線" ],
    [ "国縫駅", "北海道", "JR函館本線" ],
    [ "中ノ沢駅", "北海道", "JR函館本線" ],
    [ "長万部駅", "北海道", "JR函館本線" ],
    [ "長万部駅", "北海道", "JR函館本線" ],
    [ "二股駅", "北海道", "JR函館本線" ],
    [ "蕨岱駅", "北海道", "JR函館本線" ],
    [ "黒松内駅", "北海道", "JR函館本線" ],
    [ "熱郛駅", "北海道", "JR函館本線" ],
    [ "目名駅", "北海道", "JR函館本線" ],
    [ "蘭越駅", "北海道", "JR函館本線" ],
    [ "昆布駅", "北海道", "JR函館本線" ],
    [ "ニセコ駅", "北海道", "JR函館本線" ],
    [ "比羅夫駅", "北海道", "JR函館本線" ],
    [ "倶知安駅", "北海道", "JR函館本線" ],
    [ "小沢駅", "北海道", "JR函館本線" ],
    [ "銀山駅", "北海道", "JR函館本線" ],
    [ "然別駅", "北海道", "JR函館本線" ],
    [ "仁木駅", "北海道", "JR函館本線" ],
    [ "余市駅", "北海道", "JR函館本線" ],
    [ "蘭島駅", "北海道", "JR函館本線" ],
    [ "塩谷駅", "北海道", "JR函館本線" ],
    [ "小樽駅", "北海道", "JR函館本線" ],
    [ "小樽駅", "北海道", "JR函館本線" ],
    [ "南小樽駅", "北海道", "JR函館本線" ],
    [ "小樽築港駅", "北海道", "JR函館本線" ],
    [ "朝里駅", "北海道", "JR函館本線" ],
    [ "銭函駅", "北海道", "JR函館本線" ],
    [ "ほしみ駅", "北海道", "JR函館本線" ],
    [ "星置駅", "北海道", "JR函館本線" ],
    [ "稲穂駅", "北海道", "JR函館本線" ],
    [ "手稲駅", "北海道", "JR函館本線" ],
    [ "稲積公園駅", "北海道", "JR函館本線" ],
    [ "発寒駅", "北海道", "JR函館本線" ],
    [ "発寒中央駅", "北海道", "JR函館本線" ],
    [ "琴似駅", "北海道", "JR函館本線" ],
    [ "桑園駅", "北海道", "JR函館本線" ],
    [ "札幌駅", "北海道", "JR函館本線" ],
    [ "苗穂駅", "北海道", "JR函館本線" ],
    [ "白石駅", "北海道", "JR函館本線" ],
    [ "厚別駅", "北海道", "JR函館本線" ],
    [ "森林公園駅", "北海道", "JR函館本線" ],
    [ "大麻駅", "北海道", "JR函館本線" ],
    [ "野幌駅", "北海道", "JR函館本線" ],
    [ "高砂駅", "北海道", "JR函館本線" ],
    [ "江別駅", "北海道", "JR函館本線" ],
    [ "豊幌駅", "北海道", "JR函館本線" ],
    [ "幌向駅", "北海道", "JR函館本線" ],
    [ "上幌向駅", "北海道", "JR函館本線" ],
    [ "岩見沢駅", "北海道", "JR函館本線" ],
    [ "峰延駅", "北海道", "JR函館本線" ],
    [ "光珠内駅", "北海道", "JR函館本線" ],
    [ "美唄駅", "北海道", "JR函館本線" ],
    [ "茶志内駅", "北海道", "JR函館本線" ],
    [ "奈井江駅", "北海道", "JR函館本線" ],
    [ "豊沼駅", "北海道", "JR函館本線" ],
    [ "砂川駅", "北海道", "JR函館本線" ],
    [ "滝川駅", "北海道", "JR函館本線" ],
    [ "江部乙駅", "北海道", "JR函館本線" ],
    [ "妹背牛駅", "北海道", "JR函館本線" ],
    [ "深川駅", "北海道", "JR函館本線" ],
    [ "納内駅", "北海道", "JR函館本線" ],
    [ "伊納駅", "北海道", "JR函館本線" ],
    [ "近文駅", "北海道", "JR函館本線" ],
    [ "旭川駅", "北海道", "JR函館本線" ],
    [ "長万部駅", "北海道", "JR函館本線" ],
    [ "静狩駅", "北海道", "JR函館本線" ],
    [ "小幌駅", "北海道", "JR函館本線" ],
    [ "礼文駅", "北海道", "JR函館本線" ],
    [ "大岸駅", "北海道", "JR函館本線" ],
    [ "豊浦駅", "北海道", "JR函館本線" ],
    [ "洞爺駅", "北海道", "JR函館本線" ],
    [ "有珠駅", "北海道", "JR函館本線" ],
    [ "長和駅", "北海道", "JR函館本線" ],
    [ "伊達紋別駅", "北海道", "JR函館本線" ],
    [ "北舟岡駅", "北海道", "JR函館本線" ],
    [ "稀府駅", "北海道", "JR函館本線" ],
    [ "黄金駅", "北海道", "JR函館本線" ],
    [ "崎守駅", "北海道", "JR函館本線" ],
    [ "本輪西駅", "北海道", "JR函館本線" ],
    [ "室蘭駅", "北海道", "JR函館本線" ],
    [ "母恋駅", "北海道", "JR函館本線" ],
    [ "御崎駅", "北海道", "JR函館本線" ],
    [ "輪西駅", "北海道", "JR函館本線" ],
    [ "東室蘭駅", "北海道", "JR函館本線" ],
    [ "鷲別駅", "北海道", "JR函館本線" ],
    [ "幌別駅", "北海道", "JR函館本線" ],
    [ "富浦駅", "北海道", "JR函館本線" ],
    [ "登別駅", "北海道", "JR函館本線" ],
    [ "虎杖浜駅", "北海道", "JR函館本線" ],
    [ "竹浦駅", "北海道", "JR函館本線" ],
    [ "北吉原駅", "北海道", "JR函館本線" ],
    [ "萩野駅", "北海道", "JR函館本線" ],
    [ "白老駅", "北海道", "JR函館本線" ],
    [ "社台駅", "北海道", "JR函館本線" ],
    [ "錦岡駅", "北海道", "JR函館本線" ],
    [ "糸井駅", "北海道", "JR函館本線" ],
    [ "青葉駅", "北海道", "JR函館本線" ],
    [ "苫小牧駅", "北海道", "JR函館本線" ],
    [ "苫小牧駅", "北海道", "JR函館本線" ],
    [ "沼ノ端駅", "北海道", "JR函館本線" ],
    [ "遠浅駅", "北海道", "JR函館本線" ],
    [ "早来駅", "北海道", "JR函館本線" ],
    [ "安平駅", "北海道", "JR函館本線" ],
    [ "追分駅", "北海道", "JR函館本線" ],
    [ "三川駅", "北海道", "JR函館本線" ],
    [ "古山駅", "北海道", "JR函館本線" ],
    [ "由仁駅", "北海道", "JR函館本線" ],
    [ "栗山駅", "北海道", "JR函館本線" ],
    [ "栗丘駅", "北海道", "JR函館本線" ],
    [ "栗沢駅", "北海道", "JR函館本線" ],
    [ "志文駅", "北海道", "JR函館本線" ],
    [ "岩見沢駅", "北海道", "JR函館本線" ],
    [ "滝川駅", "北海道", "JR函館本線" ],
    [ "東滝川駅", "北海道", "JR函館本線" ],
    [ "赤平駅", "北海道", "JR函館本線" ],
    [ "茂尻駅", "北海道", "JR函館本線" ],
    [ "平岸駅", "北海道", "JR函館本線" ],
    [ "芦別駅", "北海道", "JR函館本線" ],
    [ "上芦別駅", "北海道", "JR函館本線" ],
    [ "野花南駅", "北海道", "JR函館本線" ],
    [ "島ノ下駅", "北海道", "JR函館本線" ],
    [ "富良野駅", "北海道", "JR函館本線" ],
    [ "布部駅", "北海道", "JR函館本線" ],
    [ "山部駅", "北海道", "JR函館本線" ],
    [ "下金山駅", "北海道", "JR函館本線" ],
    [ "金山駅", "北海道", "JR函館本線" ],
    [ "東鹿越駅", "北海道", "JR函館本線" ],
    [ "幾寅駅", "北海道", "JR函館本線" ],
    [ "落合駅", "北海道", "JR函館本線" ],
    [ "新得駅", "北海道", "JR函館本線" ],
    [ "新得駅", "北海道", "JR函館本線" ],
    [ "十勝清水駅", "北海道", "JR函館本線" ],
    [ "羽帯駅", "北海道", "JR函館本線" ],
    [ "御影駅", "北海道", "JR函館本線" ],
    [ "芽室駅", "北海道", "JR函館本線" ],
    [ "大成駅", "北海道", "JR函館本線" ],
    [ "西帯広駅", "北海道", "JR函館本線" ],
    [ "柏林台駅", "北海道", "JR函館本線" ],
    [ "帯広駅", "北海道", "JR函館本線" ],
    [ "札内駅", "北海道", "JR函館本線" ],
    [ "稲士別駅", "北海道", "JR函館本線" ],
    [ "幕別駅", "北海道", "JR函館本線" ],
    [ "利別駅", "北海道", "JR函館本線" ],
    [ "池田駅", "北海道", "JR函館本線" ],
    [ "十弗駅", "北海道", "JR函館本線" ],
    [ "豊頃駅", "北海道", "JR函館本線" ],
    [ "新吉野駅", "北海道", "JR函館本線" ],
    [ "浦幌駅", "北海道", "JR函館本線" ],
    [ "上厚内駅", "北海道", "JR函館本線" ],
    [ "厚内駅", "北海道", "JR函館本線" ],
    [ "直別駅", "北海道", "JR函館本線" ],
    [ "尺別駅", "北海道", "JR函館本線" ],
    [ "音別駅", "北海道", "JR函館本線" ],
    [ "古瀬駅", "北海道", "JR函館本線" ],
    [ "白糠駅", "北海道", "JR函館本線" ],
    [ "西庶路駅", "北海道", "JR函館本線" ],
    [ "庶路駅", "北海道", "JR函館本線" ],
    [ "大楽毛駅", "北海道", "JR函館本線" ],
    [ "新大楽毛駅", "北海道", "JR函館本線" ],
    [ "新富士駅", "北海道", "JR函館本線" ],
    [ "釧路駅", "北海道", "JR函館本線" ],
    [ "釧路駅", "北海道", "花咲線" ],
    [ "東釧路駅", "北海道", "花咲線" ],
    [ "武佐駅", "北海道", "花咲線" ],
    [ "別保駅", "北海道", "花咲線" ],
    [ "上尾幌駅", "北海道", "花咲線" ],
    [ "尾幌駅", "北海道", "花咲線" ],
    [ "門静駅", "北海道", "花咲線" ],
    [ "厚岸駅", "北海道", "花咲線" ],
    [ "糸魚沢駅", "北海道", "花咲線" ],
    [ "茶内駅", "北海道", "花咲線" ],
    [ "浜中駅", "北海道", "花咲線" ],
    [ "姉別駅", "北海道", "花咲線" ],
    [ "厚床駅", "北海道", "花咲線" ],
    [ "初田牛駅", "北海道", "花咲線" ],
    [ "別当賀駅", "北海道", "花咲線" ],
    [ "落石駅", "北海道", "花咲線" ],
    [ "昆布盛駅", "北海道", "花咲線" ],
    [ "西和田駅", "北海道", "花咲線" ],
    [ "花咲駅", "北海道", "花咲線" ],
    [ "東根室駅", "北海道", "花咲線" ],
    [ "根室駅", "北海道", "花咲線" ],
    [ "苫小牧駅", "北海道", "JR千歳線" ],
    [ "沼ノ端駅", "北海道", "JR千歳線" ],
    [ "植苗駅", "北海道", "JR千歳線" ],
    [ "美々駅", "北海道", "JR千歳線" ],
    [ "新千歳空港駅", "北海道", "JR千歳線" ],
    [ "南千歳駅", "北海道", "JR千歳線" ],
    [ "千歳駅", "北海道", "JR千歳線" ],
    [ "長都駅", "北海道", "JR千歳線" ],
    [ "サッポロビール庭園駅", "北海道", "JR千歳線" ],
    [ "恵庭駅", "北海道", "JR千歳線" ],
    [ "恵み野駅", "北海道", "JR千歳線" ],
    [ "島松駅", "北海道", "JR千歳線" ],
    [ "北広島駅", "北海道", "JR千歳線" ],
    [ "上野幌駅", "北海道", "JR千歳線" ],
    [ "新札幌駅", "北海道", "JR千歳線" ],
    [ "平和駅", "北海道", "JR千歳線" ],
    [ "白石駅", "北海道", "JR千歳線" ],
    [ "苗穂駅", "北海道", "JR千歳線" ],
    [ "札幌駅", "北海道", "JR千歳線" ],
    [ "南千歳駅", "北海道", "JR石勝線" ],
    [ "追分駅", "北海道", "JR石勝線" ],
    [ "東追分駅", "北海道", "JR石勝線" ],
    [ "川端駅", "北海道", "JR石勝線" ],
    [ "滝ノ上駅", "北海道", "JR石勝線" ],
    [ "十三里駅", "北海道", "JR石勝線" ],
    [ "新夕張駅", "北海道", "JR石勝線" ],
    [ "沼ノ沢駅", "北海道", "JR石勝線" ],
    [ "南清水沢駅", "北海道", "JR石勝線" ],
    [ "清水沢駅", "北海道", "JR石勝線" ],
    [ "鹿ノ谷駅", "北海道", "JR石勝線" ],
    [ "夕張駅", "北海道", "JR石勝線" ],
    [ "占冠駅", "北海道", "JR石勝線" ],
    [ "トマム駅", "北海道", "JR石勝線" ],
    [ "新得駅", "北海道", "JR石勝線" ],
    [ "苫小牧駅", "北海道", "JR日高本線" ],
    [ "勇払駅", "北海道", "JR日高本線" ],
    [ "浜厚真駅", "北海道", "JR日高本線" ],
    [ "浜田浦駅", "北海道", "JR日高本線" ],
    [ "鵡川駅", "北海道", "JR日高本線" ],
    [ "汐見駅", "北海道", "JR日高本線" ],
    [ "富川駅", "北海道", "JR日高本線" ],
    [ "日高門別駅", "北海道", "JR日高本線" ],
    [ "豊郷駅", "北海道", "JR日高本線" ],
    [ "清畠駅", "北海道", "JR日高本線" ],
    [ "厚賀駅", "北海道", "JR日高本線" ],
    [ "大狩部駅", "北海道", "JR日高本線" ],
    [ "節婦駅", "北海道", "JR日高本線" ],
    [ "新冠駅", "北海道", "JR日高本線" ],
    [ "静内駅", "北海道", "JR日高本線" ],
    [ "静内海水浴場駅", "北海道", "JR日高本線" ],
    [ "東静内駅", "北海道", "JR日高本線" ],
    [ "春立駅", "北海道", "JR日高本線" ],
    [ "日高東別駅", "北海道", "JR日高本線" ],
    [ "日高三石駅", "北海道", "JR日高本線" ],
    [ "蓬栄駅", "北海道", "JR日高本線" ],
    [ "本桐駅", "北海道", "JR日高本線" ],
    [ "荻伏駅", "北海道", "JR日高本線" ],
    [ "絵笛駅", "北海道", "JR日高本線" ],
    [ "浦河駅", "北海道", "JR日高本線" ],
    [ "東町駅", "北海道", "JR日高本線" ],
    [ "日高幌別駅", "北海道", "JR日高本線" ],
    [ "鵜苫駅", "北海道", "JR日高本線" ],
    [ "西様似駅", "北海道", "JR日高本線" ],
    [ "様似駅", "北海道", "JR日高本線" ],
    [ "札幌駅", "北海道", "JR札沼線" ],
    [ "桑園駅", "北海道", "JR札沼線" ],
    [ "八軒駅", "北海道", "JR札沼線" ],
    [ "新川駅", "北海道", "JR札沼線" ],
    [ "新琴似駅", "北海道", "JR札沼線" ],
    [ "太平駅", "北海道", "JR札沼線" ],
    [ "百合が原駅", "北海道", "JR札沼線" ],
    [ "篠路駅", "北海道", "JR札沼線" ],
    [ "拓北駅", "北海道", "JR札沼線" ],
    [ "あいの里教育大駅", "北海道", "JR札沼線" ],
    [ "あいの里公園駅", "北海道", "JR札沼線" ],
    [ "ロイズタウン駅", "北海道", "JR札沼線" ],
    [ "太美駅", "北海道", "JR札沼線" ],
    [ "当別駅", "北海道", "JR札沼線" ],
    [ "北海道医療大学駅", "北海道", "JR札沼線" ],
    [ "石狩金沢駅", "北海道", "JR札沼線" ],
    [ "本中小屋駅", "北海道", "JR札沼線" ],
    [ "中小屋駅", "北海道", "JR札沼線" ],
    [ "月ケ岡駅", "北海道", "JR札沼線" ],
    [ "知来乙駅", "北海道", "JR札沼線" ],
    [ "石狩月形駅", "北海道", "JR札沼線" ],
    [ "豊ケ岡駅", "北海道", "JR札沼線" ],
    [ "札比内駅", "北海道", "JR札沼線" ],
    [ "晩生内駅", "北海道", "JR札沼線" ],
    [ "札的駅", "北海道", "JR札沼線" ],
    [ "浦臼駅", "北海道", "JR札沼線" ],
    [ "鶴沼駅", "北海道", "JR札沼線" ],
    [ "於札内駅", "北海道", "JR札沼線" ],
    [ "南下徳富駅", "北海道", "JR札沼線" ],
    [ "下徳富駅", "北海道", "JR札沼線" ],
    [ "新十津川駅", "北海道", "JR札沼線" ],
    [ "深川駅", "北海道", "JR留萌本線" ],
    [ "北一已駅", "北海道", "JR留萌本線" ],
    [ "秩父別駅", "北海道", "JR留萌本線" ],
    [ "北秩父別駅", "北海道", "JR留萌本線" ],
    [ "石狩沼田駅", "北海道", "JR留萌本線" ],
    [ "真布駅", "北海道", "JR留萌本線" ],
    [ "恵比島駅", "北海道", "JR留萌本線" ],
    [ "峠下駅", "北海道", "JR留萌本線" ],
    [ "幌糠駅", "北海道", "JR留萌本線" ],
    [ "藤山駅", "北海道", "JR留萌本線" ],
    [ "大和田駅", "北海道", "JR留萌本線" ],
    [ "留萌駅", "北海道", "JR留萌本線" ],
    [ "瀬越駅", "北海道", "JR留萌本線" ],
    [ "浜中海水浴場駅", "北海道", "JR留萌本線" ],
    [ "礼受駅", "北海道", "JR留萌本線" ],
    [ "阿分駅", "北海道", "JR留萌本線" ],
    [ "信砂駅", "北海道", "JR留萌本線" ],
    [ "舎熊駅", "北海道", "JR留萌本線" ],
    [ "朱文別駅", "北海道", "JR留萌本線" ],
    [ "箸別駅", "北海道", "JR留萌本線" ],
    [ "増毛駅", "北海道", "JR留萌本線" ],
    [ "旭川駅", "北海道", "JR富良野線" ],
    [ "神楽岡駅", "北海道", "JR富良野線" ],
    [ "緑が丘駅", "北海道", "JR富良野線" ],
    [ "西御料駅", "北海道", "JR富良野線" ],
    [ "西瑞穂駅", "北海道", "JR富良野線" ],
    [ "西神楽駅", "北海道", "JR富良野線" ],
    [ "西聖和駅", "北海道", "JR富良野線" ],
    [ "千代ケ岡駅", "北海道", "JR富良野線" ],
    [ "北美瑛駅", "北海道", "JR富良野線" ],
    [ "美瑛駅", "北海道", "JR富良野線" ],
    [ "美馬牛駅", "北海道", "JR富良野線" ],
    [ "上富良野駅", "北海道", "JR富良野線" ],
    [ "西中駅", "北海道", "JR富良野線" ],
    [ "ラベンダー畑駅", "北海道", "JR富良野線" ],
    [ "中富良野駅", "北海道", "JR富良野線" ],
    [ "鹿討駅", "北海道", "JR富良野線" ],
    [ "学田駅", "北海道", "JR富良野線" ],
    [ "富良野駅", "北海道", "JR富良野線" ],
    [ "旭川駅", "北海道", "JR宗谷本線" ],
    [ "旭川四条駅", "北海道", "JR宗谷本線" ],
    [ "新旭川駅", "北海道", "JR宗谷本線" ],
    [ "永山駅", "北海道", "JR宗谷本線" ],
    [ "北永山駅", "北海道", "JR宗谷本線" ],
    [ "南比布駅", "北海道", "JR宗谷本線" ],
    [ "比布駅", "北海道", "JR宗谷本線" ],
    [ "北比布駅", "北海道", "JR宗谷本線" ],
    [ "蘭留駅", "北海道", "JR宗谷本線" ],
    [ "塩狩駅", "北海道", "JR宗谷本線" ],
    [ "和寒駅", "北海道", "JR宗谷本線" ],
    [ "東六線駅", "北海道", "JR宗谷本線" ],
    [ "剣淵駅", "北海道", "JR宗谷本線" ],
    [ "北剣淵駅", "北海道", "JR宗谷本線" ],
    [ "士別駅", "北海道", "JR宗谷本線" ],
    [ "下士別駅", "北海道", "JR宗谷本線" ],
    [ "多寄駅", "北海道", "JR宗谷本線" ],
    [ "瑞穂駅", "北海道", "JR宗谷本線" ],
    [ "風連駅", "北海道", "JR宗谷本線" ],
    [ "名寄高校駅", "北海道", "JR宗谷本線" ],
    [ "名寄駅", "北海道", "JR宗谷本線" ],
    [ "日進駅", "北海道", "JR宗谷本線" ],
    [ "北星駅", "北海道", "JR宗谷本線" ],
    [ "智恵文駅", "北海道", "JR宗谷本線" ],
    [ "智北駅", "北海道", "JR宗谷本線" ],
    [ "南美深駅", "北海道", "JR宗谷本線" ],
    [ "美深駅", "北海道", "JR宗谷本線" ],
    [ "初野駅", "北海道", "JR宗谷本線" ],
    [ "紋穂内駅", "北海道", "JR宗谷本線" ],
    [ "恩根内駅", "北海道", "JR宗谷本線" ],
    [ "豊清水駅", "北海道", "JR宗谷本線" ],
    [ "天塩川温泉駅", "北海道", "JR宗谷本線" ],
    [ "咲来駅", "北海道", "JR宗谷本線" ],
    [ "音威子府駅", "北海道", "JR宗谷本線" ],
    [ "筬島駅", "北海道", "JR宗谷本線" ],
    [ "佐久駅", "北海道", "JR宗谷本線" ],
    [ "天塩中川駅", "北海道", "JR宗谷本線" ],
    [ "歌内駅", "北海道", "JR宗谷本線" ],
    [ "問寒別駅", "北海道", "JR宗谷本線" ],
    [ "糠南駅", "北海道", "JR宗谷本線" ],
    [ "雄信内駅", "北海道", "JR宗谷本線" ],
    [ "安牛駅", "北海道", "JR宗谷本線" ],
    [ "南幌延駅", "北海道", "JR宗谷本線" ],
    [ "上幌延駅", "北海道", "JR宗谷本線" ],
    [ "幌延駅", "北海道", "JR宗谷本線" ],
    [ "下沼駅", "北海道", "JR宗谷本線" ],
    [ "豊富駅", "北海道", "JR宗谷本線" ],
    [ "徳満駅", "北海道", "JR宗谷本線" ],
    [ "兜沼駅", "北海道", "JR宗谷本線" ],
    [ "勇知駅", "北海道", "JR宗谷本線" ],
    [ "抜海駅", "北海道", "JR宗谷本線" ],
    [ "南稚内駅", "北海道", "JR宗谷本線" ],
    [ "稚内駅", "北海道", "JR宗谷本線" ],
    [ "旭川駅", "北海道", "JR石北本線" ],
    [ "旭川四条駅", "北海道", "JR石北本線" ],
    [ "新旭川駅", "北海道", "JR石北本線" ],
    [ "南永山駅", "北海道", "JR石北本線" ],
    [ "東旭川駅", "北海道", "JR石北本線" ],
    [ "北日ノ出駅", "北海道", "JR石北本線" ],
    [ "桜岡駅", "北海道", "JR石北本線" ],
    [ "当麻駅", "北海道", "JR石北本線" ],
    [ "将軍山駅", "北海道", "JR石北本線" ],
    [ "伊香牛駅", "北海道", "JR石北本線" ],
    [ "愛別駅", "北海道", "JR石北本線" ],
    [ "中愛別駅", "北海道", "JR石北本線" ],
    [ "愛山駅", "北海道", "JR石北本線" ],
    [ "安足間駅", "北海道", "JR石北本線" ],
    [ "東雲駅", "北海道", "JR石北本線" ],
    [ "上川駅", "北海道", "JR石北本線" ],
    [ "上白滝駅", "北海道", "JR石北本線" ],
    [ "白滝駅", "北海道", "JR石北本線" ],
    [ "旧白滝駅", "北海道", "JR石北本線" ],
    [ "下白滝駅", "北海道", "JR石北本線" ],
    [ "丸瀬布駅", "北海道", "JR石北本線" ],
    [ "瀬戸瀬駅", "北海道", "JR石北本線" ],
    [ "遠軽駅", "北海道", "JR石北本線" ],
    [ "安国駅", "北海道", "JR石北本線" ],
    [ "生野駅", "北海道", "JR石北本線" ],
    [ "生田原駅", "北海道", "JR石北本線" ],
    [ "金華駅", "北海道", "JR石北本線" ],
    [ "西留辺蘂駅", "北海道", "JR石北本線" ],
    [ "留辺蘂駅", "北海道", "JR石北本線" ],
    [ "相内駅", "北海道", "JR石北本線" ],
    [ "東相内駅", "北海道", "JR石北本線" ],
    [ "西北見駅", "北海道", "JR石北本線" ],
    [ "北見駅", "北海道", "JR石北本線" ],
    [ "柏陽駅", "北海道", "JR石北本線" ],
    [ "愛し野駅", "北海道", "JR石北本線" ],
    [ "端野駅", "北海道", "JR石北本線" ],
    [ "緋牛内駅", "北海道", "JR石北本線" ],
    [ "美幌駅", "北海道", "JR石北本線" ],
    [ "西女満別駅", "北海道", "JR石北本線" ],
    [ "女満別駅", "北海道", "JR石北本線" ],
    [ "呼人駅", "北海道", "JR石北本線" ],
    [ "網走駅", "北海道", "JR石北本線" ],
    [ "網走駅", "北海道", "JR釧網本線" ],
    [ "桂台駅", "北海道", "JR釧網本線" ],
    [ "鱒浦駅", "北海道", "JR釧網本線" ],
    [ "藻琴駅", "北海道", "JR釧網本線" ],
    [ "北浜駅", "北海道", "JR釧網本線" ],
    [ "原生花園駅", "北海道", "JR釧網本線" ],
    [ "浜小清水駅", "北海道", "JR釧網本線" ],
    [ "止別駅", "北海道", "JR釧網本線" ],
    [ "知床斜里駅", "北海道", "JR釧網本線" ],
    [ "中斜里駅", "北海道", "JR釧網本線" ],
    [ "南斜里駅", "北海道", "JR釧網本線" ],
    [ "清里町駅", "北海道", "JR釧網本線" ],
    [ "札弦駅", "北海道", "JR釧網本線" ],
    [ "緑駅", "北海道", "JR釧網本線" ],
    [ "川湯温泉駅", "北海道", "JR釧網本線" ],
    [ "美留和駅", "北海道", "JR釧網本線" ],
    [ "摩周駅", "北海道", "JR釧網本線" ],
    [ "南弟子屈駅", "北海道", "JR釧網本線" ],
    [ "磯分内駅", "北海道", "JR釧網本線" ],
    [ "標茶駅", "北海道", "JR釧網本線" ],
    [ "五十石駅", "北海道", "JR釧網本線" ],
    [ "茅沼駅", "北海道", "JR釧網本線" ],
    [ "塘路駅", "北海道", "JR釧網本線" ],
    [ "細岡駅", "北海道", "JR釧網本線" ],
    [ "釧路湿原駅", "北海道", "JR釧網本線" ],
    [ "遠矢駅", "北海道", "JR釧網本線" ],
    [ "東釧路駅", "北海道", "JR釧網本線" ],
    [ "釧路駅", "北海道", "JR釧網本線" ],
    [ "中小国駅", "青森県", "JR海峡線" ],
    [ "奥津軽いまべつ駅", "青森県", "JR海峡線" ],
    [ "竜飛海底駅", "青森県", "JR海峡線" ],
    [ "吉岡海底駅", "北海道", "JR海峡線" ],
    [ "知内駅", "北海道", "JR海峡線" ],
    [ "木古内駅", "北海道", "JR海峡線" ],
    [ "江差駅", "北海道", "JR江差線" ],
    [ "上ノ国駅", "北海道", "JR江差線" ],
    [ "中須田駅", "北海道", "JR江差線" ],
    [ "桂岡駅", "北海道", "JR江差線" ],
    [ "宮越駅", "北海道", "JR江差線" ],
    [ "湯ノ岱駅", "北海道", "JR江差線" ],
    [ "神明駅", "北海道", "JR江差線" ],
    [ "吉堀駅", "北海道", "JR江差線" ],
    [ "渡島鶴岡駅", "北海道", "JR江差線" ],
    [ "木古内駅", "北海道", "道南いさりび鉄道線" ],
    [ "札苅駅", "北海道", "道南いさりび鉄道線" ],
    [ "泉沢駅", "北海道", "道南いさりび鉄道線" ],
    [ "釜谷駅", "北海道", "道南いさりび鉄道線" ],
    [ "渡島当別駅", "北海道", "道南いさりび鉄道線" ],
    [ "茂辺地駅", "北海道", "道南いさりび鉄道線" ],
    [ "上磯駅", "北海道", "道南いさりび鉄道線" ],
    [ "清川口駅", "北海道", "道南いさりび鉄道線" ],
    [ "久根別駅", "北海道", "道南いさりび鉄道線" ],
    [ "東久根別駅", "北海道", "道南いさりび鉄道線" ],
    [ "七重浜駅", "北海道", "道南いさりび鉄道線" ],
    [ "五稜郭駅", "北海道", "道南いさりび鉄道線" ],
    [ "函館駅", "北海道", "道南いさりび鉄道線" ],
    [ "八戸駅", "青森県", "JR東北本線" ],
    [ "新庄駅", "山形県", "JR東北本線" ],
    [ "泉田駅", "山形県", "JR東北本線" ],
    [ "羽前豊里駅", "山形県", "JR東北本線" ],
    [ "真室川駅", "山形県", "JR東北本線" ],
    [ "釜淵駅", "山形県", "JR東北本線" ],
    [ "大滝駅", "山形県", "JR東北本線" ],
    [ "及位駅", "山形県", "JR東北本線" ],
    [ "院内駅", "秋田県", "JR東北本線" ],
    [ "横堀駅", "秋田県", "JR東北本線" ],
    [ "三関駅", "秋田県", "JR東北本線" ],
    [ "上湯沢駅", "秋田県", "JR東北本線" ],
    [ "湯沢駅", "秋田県", "JR東北本線" ],
    [ "下湯沢駅", "秋田県", "JR東北本線" ],
    [ "十文字駅", "秋田県", "JR東北本線" ],
    [ "醍醐駅", "秋田県", "JR東北本線" ],
    [ "柳田駅", "秋田県", "JR東北本線" ],
    [ "横手駅", "秋田県", "JR東北本線" ],
    [ "後三年駅", "秋田県", "JR東北本線" ],
    [ "飯詰駅", "秋田県", "JR東北本線" ],
    [ "大曲駅", "秋田県", "JR東北本線" ],
    [ "神宮寺駅", "秋田県", "JR東北本線" ],
    [ "刈和野駅", "秋田県", "JR東北本線" ],
    [ "峰吉川駅", "秋田県", "JR東北本線" ],
    [ "羽後境駅", "秋田県", "JR東北本線" ],
    [ "大張野駅", "秋田県", "JR東北本線" ],
    [ "和田駅", "秋田県", "JR東北本線" ],
    [ "四ツ小屋駅", "秋田県", "JR東北本線" ],
    [ "秋田駅", "秋田県", "JR東北本線" ],
    [ "泉外旭川駅", "秋田県", "JR東北本線" ],
    [ "土崎駅", "秋田県", "JR東北本線" ],
    [ "上飯島駅", "秋田県", "JR東北本線" ],
    [ "追分駅", "秋田県", "JR東北本線" ],
    [ "大久保駅", "秋田県", "JR東北本線" ],
    [ "羽後飯塚駅", "秋田県", "JR東北本線" ],
    [ "井川さくら駅", "秋田県", "JR東北本線" ],
    [ "八郎潟駅", "秋田県", "JR東北本線" ],
    [ "鯉川駅", "秋田県", "JR東北本線" ],
    [ "鹿渡駅", "秋田県", "JR東北本線" ],
    [ "森岳駅", "秋田県", "JR東北本線" ],
    [ "北金岡駅", "秋田県", "JR東北本線" ],
    [ "東能代駅", "秋田県", "JR東北本線" ],
    [ "鶴形駅", "秋田県", "JR東北本線" ],
    [ "富根駅", "秋田県", "JR東北本線" ],
    [ "二ツ井駅", "秋田県", "JR東北本線" ],
    [ "前山駅", "秋田県", "JR東北本線" ],
    [ "鷹ノ巣駅", "秋田県", "JR東北本線" ],
    [ "糠沢駅", "秋田県", "JR東北本線" ],
    [ "早口駅", "秋田県", "JR東北本線" ],
    [ "下川沿駅", "秋田県", "JR東北本線" ],
    [ "大館駅", "秋田県", "JR東北本線" ],
    [ "白沢駅", "秋田県", "JR東北本線" ],
    [ "陣場駅", "秋田県", "JR東北本線" ],
    [ "津軽湯の沢駅", "青森県", "JR東北本線" ],
    [ "碇ケ関駅", "青森県", "JR東北本線" ],
    [ "長峰駅", "青森県", "JR東北本線" ],
    [ "大鰐温泉駅", "青森県", "JR東北本線" ],
    [ "石川駅", "青森県", "JR東北本線" ],
    [ "弘前駅", "青森県", "JR東北本線" ],
    [ "撫牛子駅", "青森県", "JR東北本線" ],
    [ "川部駅", "青森県", "JR東北本線" ],
    [ "北常盤駅", "青森県", "JR東北本線" ],
    [ "浪岡駅", "青森県", "JR東北本線" ],
    [ "大釈迦駅", "青森県", "JR東北本線" ],
    [ "鶴ケ坂駅", "青森県", "JR東北本線" ],
    [ "津軽新城駅", "青森県", "JR東北本線" ],
    [ "新青森駅", "青森県", "JR東北本線" ],
    [ "青森駅", "青森県", "JR東北本線" ],
    [ "野辺地駅", "青森県", "はまなすベイライン大湊線" ],
    [ "北野辺地駅", "青森県", "はまなすベイライン大湊線" ],
    [ "有戸駅", "青森県", "はまなすベイライン大湊線" ],
    [ "吹越駅", "青森県", "はまなすベイライン大湊線" ],
    [ "陸奥横浜駅", "青森県", "はまなすベイライン大湊線" ],
    [ "有畑駅", "青森県", "はまなすベイライン大湊線" ],
    [ "近川駅", "青森県", "はまなすベイライン大湊線" ],
    [ "金谷沢駅", "青森県", "はまなすベイライン大湊線" ],
    [ "赤川駅", "青森県", "はまなすベイライン大湊線" ],
    [ "下北駅", "青森県", "はまなすベイライン大湊線" ],
    [ "大湊駅", "青森県", "はまなすベイライン大湊線" ],
    [ "東能代駅", "秋田県", "JR五能線" ],
    [ "能代駅", "秋田県", "JR五能線" ],
    [ "向能代駅", "秋田県", "JR五能線" ],
    [ "北能代駅", "秋田県", "JR五能線" ],
    [ "鳥形駅", "秋田県", "JR五能線" ],
    [ "沢目駅", "秋田県", "JR五能線" ],
    [ "東八森駅", "秋田県", "JR五能線" ],
    [ "八森駅", "秋田県", "JR五能線" ],
    [ "滝ノ間駅", "秋田県", "JR五能線" ],
    [ "あきた白神駅", "秋田県", "JR五能線" ],
    [ "岩館駅", "秋田県", "JR五能線" ],
    [ "大間越駅", "青森県", "JR五能線" ],
    [ "白神岳登山口駅", "青森県", "JR五能線" ],
    [ "松神駅", "青森県", "JR五能線" ],
    [ "十二湖駅", "青森県", "JR五能線" ],
    [ "陸奥岩崎駅", "青森県", "JR五能線" ],
    [ "陸奥沢辺駅", "青森県", "JR五能線" ],
    [ "ウェスパ椿山駅", "青森県", "JR五能線" ],
    [ "艫作駅", "青森県", "JR五能線" ],
    [ "横磯駅", "青森県", "JR五能線" ],
    [ "深浦駅", "青森県", "JR五能線" ],
    [ "広戸駅", "青森県", "JR五能線" ],
    [ "追良瀬駅", "青森県", "JR五能線" ],
    [ "驫木駅", "青森県", "JR五能線" ],
    [ "風合瀬駅", "青森県", "JR五能線" ],
    [ "大戸瀬駅", "青森県", "JR五能線" ],
    [ "千畳敷駅", "青森県", "JR五能線" ],
    [ "北金ケ沢駅", "青森県", "JR五能線" ],
    [ "陸奥柳田駅", "青森県", "JR五能線" ],
    [ "陸奥赤石駅", "青森県", "JR五能線" ],
    [ "鰺ケ沢駅", "青森県", "JR五能線" ],
    [ "鳴沢駅", "青森県", "JR五能線" ],
    [ "越水駅", "青森県", "JR五能線" ],
    [ "陸奥森田駅", "青森県", "JR五能線" ],
    [ "中田駅", "青森県", "JR五能線" ],
    [ "木造駅", "青森県", "JR五能線" ],
    [ "五所川原駅", "青森県", "JR五能線" ],
    [ "陸奥鶴田駅", "青森県", "JR五能線" ],
    [ "鶴泊駅", "青森県", "JR五能線" ],
    [ "板柳駅", "青森県", "JR五能線" ],
    [ "林崎駅", "青森県", "JR五能線" ],
    [ "藤崎駅", "青森県", "JR五能線" ],
    [ "川部駅", "青森県", "JR五能線" ],
    [ "青森駅", "青森県", "JR津軽線" ],
    [ "油川駅", "青森県", "JR津軽線" ],
    [ "津軽宮田駅", "青森県", "JR津軽線" ],
    [ "奥内駅", "青森県", "JR津軽線" ],
    [ "左堰駅", "青森県", "JR津軽線" ],
    [ "後潟駅", "青森県", "JR津軽線" ],
    [ "中沢駅", "青森県", "JR津軽線" ],
    [ "蓬田駅", "青森県", "JR津軽線" ],
    [ "郷沢駅", "青森県", "JR津軽線" ],
    [ "瀬辺地駅", "青森県", "JR津軽線" ],
    [ "蟹田駅", "青森県", "JR津軽線" ],
    [ "中小国駅", "青森県", "JR津軽線" ],
    [ "大平駅", "青森県", "JR津軽線" ],
    [ "津軽二股駅", "青森県", "JR津軽線" ],
    [ "大川平駅", "青森県", "JR津軽線" ],
    [ "今別駅", "青森県", "JR津軽線" ],
    [ "津軽浜名駅", "青森県", "JR津軽線" ],
    [ "三厩駅", "青森県", "JR津軽線" ],
    [ "八戸駅", "青森県", "JR八戸線" ],
    [ "長苗代駅", "青森県", "JR八戸線" ],
    [ "本八戸駅", "青森県", "JR八戸線" ],
    [ "小中野駅", "青森県", "JR八戸線" ],
    [ "陸奥湊駅", "青森県", "JR八戸線" ],
    [ "白銀駅", "青森県", "JR八戸線" ],
    [ "鮫駅", "青森県", "JR八戸線" ],
    [ "プレイピア白浜駅", "青森県", "JR八戸線" ],
    [ "陸奥白浜駅", "青森県", "JR八戸線" ],
    [ "種差海岸駅", "青森県", "JR八戸線" ],
    [ "大久喜駅", "青森県", "JR八戸線" ],
    [ "金浜駅", "青森県", "JR八戸線" ],
    [ "大蛇駅", "青森県", "JR八戸線" ],
    [ "階上駅", "青森県", "JR八戸線" ],
    [ "角の浜駅", "岩手県", "JR八戸線" ],
    [ "平内駅", "岩手県", "JR八戸線" ],
    [ "種市駅", "岩手県", "JR八戸線" ],
    [ "玉川駅", "岩手県", "JR八戸線" ],
    [ "宿戸駅", "岩手県", "JR八戸線" ],
    [ "陸中八木駅", "岩手県", "JR八戸線" ],
    [ "有家駅", "岩手県", "JR八戸線" ],
    [ "陸中中野駅", "岩手県", "JR八戸線" ],
    [ "侍浜駅", "岩手県", "JR八戸線" ],
    [ "陸中夏井駅", "岩手県", "JR八戸線" ],
    [ "久慈駅", "岩手県", "JR八戸線" ],
    [ "茂市駅", "岩手県", "JR岩泉線" ],
    [ "岩手刈屋駅", "岩手県", "JR岩泉線" ],
    [ "中里駅", "岩手県", "JR岩泉線" ],
    [ "岩手和井内駅", "岩手県", "JR岩泉線" ],
    [ "押角駅", "岩手県", "JR岩泉線" ],
    [ "岩手大川駅", "岩手県", "JR岩泉線" ],
    [ "浅内駅", "岩手県", "JR岩泉線" ],
    [ "二升石駅", "岩手県", "JR岩泉線" ],
    [ "岩泉駅", "岩手県", "JR岩泉線" ],
    [ "一ノ関駅", "岩手県", "ドラゴンレール大船渡線" ],
    [ "真滝駅", "岩手県", "ドラゴンレール大船渡線" ],
    [ "陸中門崎駅", "岩手県", "ドラゴンレール大船渡線" ],
    [ "岩ノ下駅", "岩手県", "ドラゴンレール大船渡線" ],
    [ "陸中松川駅", "岩手県", "ドラゴンレール大船渡線" ],
    [ "猊鼻渓駅", "岩手県", "ドラゴンレール大船渡線" ],
    [ "柴宿駅", "岩手県", "ドラゴンレール大船渡線" ],
    [ "摺沢駅", "岩手県", "ドラゴンレール大船渡線" ],
    [ "千厩駅", "岩手県", "ドラゴンレール大船渡線" ],
    [ "小梨駅", "岩手県", "ドラゴンレール大船渡線" ],
    [ "矢越駅", "岩手県", "ドラゴンレール大船渡線" ],
    [ "折壁駅", "岩手県", "ドラゴンレール大船渡線" ],
    [ "新月駅", "岩手県", "ドラゴンレール大船渡線" ],
    [ "気仙沼駅", "宮城県", "ドラゴンレール大船渡線" ],
    [ "鹿折唐桑駅", "宮城県", "ドラゴンレール大船渡線" ],
    [ "上鹿折駅", "宮城県", "ドラゴンレール大船渡線" ],
    [ "陸前矢作駅", "岩手県", "ドラゴンレール大船渡線" ],
    [ "竹駒駅", "岩手県", "ドラゴンレール大船渡線" ],
    [ "陸前高田駅", "岩手県", "ドラゴンレール大船渡線" ],
    [ "脇ノ沢駅", "岩手県", "ドラゴンレール大船渡線" ],
    [ "小友駅", "岩手県", "ドラゴンレール大船渡線" ],
    [ "細浦駅", "岩手県", "ドラゴンレール大船渡線" ],
    [ "下船渡駅", "岩手県", "ドラゴンレール大船渡線" ],
    [ "大船渡駅", "岩手県", "ドラゴンレール大船渡線" ],
    [ "盛駅", "岩手県", "ドラゴンレール大船渡線" ],
    [ "花巻駅", "岩手県", "銀河ドリームライン釜石線" ],
    [ "似内駅", "岩手県", "銀河ドリームライン釜石線" ],
    [ "新花巻駅", "岩手県", "銀河ドリームライン釜石線" ],
    [ "小山田駅", "岩手県", "銀河ドリームライン釜石線" ],
    [ "土沢駅", "岩手県", "銀河ドリームライン釜石線" ],
    [ "晴山駅", "岩手県", "銀河ドリームライン釜石線" ],
    [ "岩根橋駅", "岩手県", "銀河ドリームライン釜石線" ],
    [ "宮守駅", "岩手県", "銀河ドリームライン釜石線" ],
    [ "柏木平駅", "岩手県", "銀河ドリームライン釜石線" ],
    [ "鱒沢駅", "岩手県", "銀河ドリームライン釜石線" ],
    [ "荒谷前駅", "岩手県", "銀河ドリームライン釜石線" ],
    [ "岩手二日町駅", "岩手県", "銀河ドリームライン釜石線" ],
    [ "綾織駅", "岩手県", "銀河ドリームライン釜石線" ],
    [ "遠野駅", "岩手県", "銀河ドリームライン釜石線" ],
    [ "青笹駅", "岩手県", "銀河ドリームライン釜石線" ],
    [ "岩手上郷駅", "岩手県", "銀河ドリームライン釜石線" ],
    [ "平倉駅", "岩手県", "銀河ドリームライン釜石線" ],
    [ "足ケ瀬駅", "岩手県", "銀河ドリームライン釜石線" ],
    [ "上有住駅", "岩手県", "銀河ドリームライン釜石線" ],
    [ "陸中大橋駅", "岩手県", "銀河ドリームライン釜石線" ],
    [ "洞泉駅", "岩手県", "銀河ドリームライン釜石線" ],
    [ "松倉駅", "岩手県", "銀河ドリームライン釜石線" ],
    [ "小佐野駅", "岩手県", "銀河ドリームライン釜石線" ],
    [ "釜石駅", "岩手県", "銀河ドリームライン釜石線" ],
    [ "北上駅", "岩手県", "JR北上線" ],
    [ "柳原駅", "岩手県", "JR北上線" ],
    [ "江釣子駅", "岩手県", "JR北上線" ],
    [ "藤根駅", "岩手県", "JR北上線" ],
    [ "立川目駅", "岩手県", "JR北上線" ],
    [ "横川目駅", "岩手県", "JR北上線" ],
    [ "岩沢駅", "岩手県", "JR北上線" ],
    [ "和賀仙人駅", "岩手県", "JR北上線" ],
    [ "ゆだ錦秋湖駅", "岩手県", "JR北上線" ],
    [ "ほっとゆだ駅", "岩手県", "JR北上線" ],
    [ "ゆだ高原駅", "岩手県", "JR北上線" ],
    [ "黒沢駅", "秋田県", "JR北上線" ],
    [ "小松川駅", "秋田県", "JR北上線" ],
    [ "平石駅", "秋田県", "JR北上線" ],
    [ "相野々駅", "秋田県", "JR北上線" ],
    [ "矢美津駅", "秋田県", "JR北上線" ],
    [ "横手駅", "秋田県", "JR北上線" ],
    [ "盛岡駅", "岩手県", "JR田沢湖線" ],
    [ "前潟駅", "岩手県", "JR田沢湖線" ],
    [ "大釜駅", "岩手県", "JR田沢湖線" ],
    [ "小岩井駅", "岩手県", "JR田沢湖線" ],
    [ "雫石駅", "岩手県", "JR田沢湖線" ],
    [ "春木場駅", "岩手県", "JR田沢湖線" ],
    [ "赤渕駅", "岩手県", "JR田沢湖線" ],
    [ "田沢湖駅", "秋田県", "JR田沢湖線" ],
    [ "刺巻駅", "秋田県", "JR田沢湖線" ],
    [ "神代駅", "秋田県", "JR田沢湖線" ],
    [ "生田駅", "秋田県", "JR田沢湖線" ],
    [ "角館駅", "秋田県", "JR田沢湖線" ],
    [ "鶯野駅", "秋田県", "JR田沢湖線" ],
    [ "羽後長野駅", "秋田県", "JR田沢湖線" ],
    [ "鑓見内駅", "秋田県", "JR田沢湖線" ],
    [ "羽後四ツ屋駅", "秋田県", "JR田沢湖線" ],
    [ "北大曲駅", "秋田県", "JR田沢湖線" ],
    [ "大曲駅", "秋田県", "JR田沢湖線" ],
    [ "好摩駅", "岩手県", "十和田八幡平四季彩ライン" ],
    [ "東大更駅", "岩手県", "十和田八幡平四季彩ライン" ],
    [ "大更駅", "岩手県", "十和田八幡平四季彩ライン" ],
    [ "平館駅", "岩手県", "十和田八幡平四季彩ライン" ],
    [ "北森駅", "岩手県", "十和田八幡平四季彩ライン" ],
    [ "松尾八幡平駅", "岩手県", "十和田八幡平四季彩ライン" ],
    [ "安比高原駅", "岩手県", "十和田八幡平四季彩ライン" ],
    [ "赤坂田駅", "岩手県", "十和田八幡平四季彩ライン" ],
    [ "小屋の畑駅", "岩手県", "十和田八幡平四季彩ライン" ],
    [ "荒屋新町駅", "岩手県", "十和田八幡平四季彩ライン" ],
    [ "横間駅", "岩手県", "十和田八幡平四季彩ライン" ],
    [ "田山駅", "岩手県", "十和田八幡平四季彩ライン" ],
    [ "兄畑駅", "岩手県", "十和田八幡平四季彩ライン" ],
    [ "湯瀬温泉駅", "秋田県", "十和田八幡平四季彩ライン" ],
    [ "八幡平駅", "秋田県", "十和田八幡平四季彩ライン" ],
    [ "陸中大里駅", "秋田県", "十和田八幡平四季彩ライン" ],
    [ "鹿角花輪駅", "秋田県", "十和田八幡平四季彩ライン" ],
    [ "柴平駅", "秋田県", "十和田八幡平四季彩ライン" ],
    [ "十和田南駅", "秋田県", "十和田八幡平四季彩ライン" ],
    [ "末広駅", "秋田県", "十和田八幡平四季彩ライン" ],
    [ "土深井駅", "秋田県", "十和田八幡平四季彩ライン" ],
    [ "沢尻駅", "秋田県", "十和田八幡平四季彩ライン" ],
    [ "十二所駅", "秋田県", "十和田八幡平四季彩ライン" ],
    [ "大滝温泉駅", "秋田県", "十和田八幡平四季彩ライン" ],
    [ "扇田駅", "秋田県", "十和田八幡平四季彩ライン" ],
    [ "東大館駅", "秋田県", "十和田八幡平四季彩ライン" ],
    [ "大館駅", "秋田県", "十和田八幡平四季彩ライン" ],
    [ "盛岡駅", "岩手県", "JR山田線" ],
    [ "上盛岡駅", "岩手県", "JR山田線" ],
    [ "山岸駅", "岩手県", "JR山田線" ],
    [ "上米内駅", "岩手県", "JR山田線" ],
    [ "大志田駅", "岩手県", "JR山田線" ],
    [ "浅岸駅", "岩手県", "JR山田線" ],
    [ "区界駅", "岩手県", "JR山田線" ],
    [ "松草駅", "岩手県", "JR山田線" ],
    [ "平津戸駅", "岩手県", "JR山田線" ],
    [ "川内駅", "岩手県", "JR山田線" ],
    [ "箱石駅", "岩手県", "JR山田線" ],
    [ "陸中川井駅", "岩手県", "JR山田線" ],
    [ "腹帯駅", "岩手県", "JR山田線" ],
    [ "茂市駅", "岩手県", "JR山田線" ],
    [ "蟇目駅", "岩手県", "JR山田線" ],
    [ "花原市駅", "岩手県", "JR山田線" ],
    [ "千徳駅", "岩手県", "JR山田線" ],
    [ "宮古駅", "岩手県", "JR山田線" ],
    [ "磯鶏駅", "岩手県", "JR山田線" ],
    [ "津軽石駅", "岩手県", "JR山田線" ],
    [ "豊間根駅", "岩手県", "JR山田線" ],
    [ "陸中山田駅", "岩手県", "JR山田線" ],
    [ "織笠駅", "岩手県", "JR山田線" ],
    [ "岩手船越駅", "岩手県", "JR山田線" ],
    [ "浪板海岸駅", "岩手県", "JR山田線" ],
    [ "吉里吉里駅", "岩手県", "JR山田線" ],
    [ "大槌駅", "岩手県", "JR山田線" ],
    [ "鵜住居駅", "岩手県", "JR山田線" ],
    [ "両石駅", "岩手県", "JR山田線" ],
    [ "釜石駅", "岩手県", "JR山田線" ],
    [ "新津駅", "新潟県", "JR羽越本線" ],
    [ "京ケ瀬駅", "新潟県", "JR羽越本線" ],
    [ "水原駅", "新潟県", "JR羽越本線" ],
    [ "神山駅", "新潟県", "JR羽越本線" ],
    [ "月岡駅", "新潟県", "JR羽越本線" ],
    [ "中浦駅", "新潟県", "JR羽越本線" ],
    [ "新発田駅", "新潟県", "JR羽越本線" ],
    [ "加治駅", "新潟県", "JR羽越本線" ],
    [ "金塚駅", "新潟県", "JR羽越本線" ],
    [ "中条駅", "新潟県", "JR羽越本線" ],
    [ "平木田駅", "新潟県", "JR羽越本線" ],
    [ "坂町駅", "新潟県", "JR羽越本線" ],
    [ "平林駅", "新潟県", "JR羽越本線" ],
    [ "岩船町駅", "新潟県", "JR羽越本線" ],
    [ "村上駅", "新潟県", "JR羽越本線" ],
    [ "間島駅", "新潟県", "JR羽越本線" ],
    [ "越後早川駅", "新潟県", "JR羽越本線" ],
    [ "桑川駅", "新潟県", "JR羽越本線" ],
    [ "今川駅", "新潟県", "JR羽越本線" ],
    [ "越後寒川駅", "新潟県", "JR羽越本線" ],
    [ "勝木駅", "新潟県", "JR羽越本線" ],
    [ "府屋駅", "新潟県", "JR羽越本線" ],
    [ "鼠ケ関駅", "山形県", "JR羽越本線" ],
    [ "小岩川駅", "山形県", "JR羽越本線" ],
    [ "あつみ温泉駅", "山形県", "JR羽越本線" ],
    [ "五十川駅", "山形県", "JR羽越本線" ],
    [ "小波渡駅", "山形県", "JR羽越本線" ],
    [ "三瀬駅", "山形県", "JR羽越本線" ],
    [ "羽前水沢駅", "山形県", "JR羽越本線" ],
    [ "羽前大山駅", "山形県", "JR羽越本線" ],
    [ "鶴岡駅", "山形県", "JR羽越本線" ],
    [ "藤島駅", "山形県", "JR羽越本線" ],
    [ "西袋駅", "山形県", "JR羽越本線" ],
    [ "余目駅", "山形県", "JR羽越本線" ],
    [ "北余目駅", "山形県", "JR羽越本線" ],
    [ "砂越駅", "山形県", "JR羽越本線" ],
    [ "東酒田駅", "山形県", "JR羽越本線" ],
    [ "酒田駅", "山形県", "JR羽越本線" ],
    [ "本楯駅", "山形県", "JR羽越本線" ],
    [ "南鳥海駅", "山形県", "JR羽越本線" ],
    [ "遊佐駅", "山形県", "JR羽越本線" ],
    [ "吹浦駅", "山形県", "JR羽越本線" ],
    [ "女鹿駅", "山形県", "JR羽越本線" ],
    [ "小砂川駅", "秋田県", "JR羽越本線" ],
    [ "上浜駅", "秋田県", "JR羽越本線" ],
    [ "象潟駅", "秋田県", "JR羽越本線" ],
    [ "金浦駅", "秋田県", "JR羽越本線" ],
    [ "仁賀保駅", "秋田県", "JR羽越本線" ],
    [ "西目駅", "秋田県", "JR羽越本線" ],
    [ "羽後本荘駅", "秋田県", "JR羽越本線" ],
    [ "羽後岩谷駅", "秋田県", "JR羽越本線" ],
    [ "折渡駅", "秋田県", "JR羽越本線" ],
    [ "羽後亀田駅", "秋田県", "JR羽越本線" ],
    [ "岩城みなと駅", "秋田県", "JR羽越本線" ],
    [ "道川駅", "秋田県", "JR羽越本線" ],
    [ "下浜駅", "秋田県", "JR羽越本線" ],
    [ "桂根駅", "秋田県", "JR羽越本線" ],
    [ "新屋駅", "秋田県", "JR羽越本線" ],
    [ "羽後牛島駅", "秋田県", "JR羽越本線" ],
    [ "秋田駅", "秋田県", "JR羽越本線" ],
    [ "追分駅", "秋田県", "JR男鹿線" ],
    [ "出戸浜駅", "秋田県", "JR男鹿線" ],
    [ "上二田駅", "秋田県", "JR男鹿線" ],
    [ "二田駅", "秋田県", "JR男鹿線" ],
    [ "天王駅", "秋田県", "JR男鹿線" ],
    [ "船越駅", "秋田県", "JR男鹿線" ],
    [ "脇本駅", "秋田県", "JR男鹿線" ],
    [ "羽立駅", "秋田県", "JR男鹿線" ],
    [ "男鹿駅", "秋田県", "JR男鹿線" ],
    [ "福島駅", "福島県", "山形線" ],
    [ "笹木野駅", "福島県", "山形線" ],
    [ "庭坂駅", "福島県", "山形線" ],
    [ "赤岩駅", "福島県", "山形線" ],
    [ "板谷駅", "山形県", "山形線" ],
    [ "峠駅", "山形県", "山形線" ],
    [ "大沢駅", "山形県", "山形線" ],
    [ "関根駅", "山形県", "山形線" ],
    [ "米沢駅", "山形県", "山形線" ],
    [ "置賜駅", "山形県", "山形線" ],
    [ "高畠駅", "山形県", "山形線" ],
    [ "赤湯駅", "山形県", "山形線" ],
    [ "中川駅", "山形県", "山形線" ],
    [ "羽前中山駅", "山形県", "山形線" ],
    [ "かみのやま温泉駅", "山形県", "山形線" ],
    [ "茂吉記念館前駅", "山形県", "山形線" ],
    [ "蔵王駅", "山形県", "山形線" ],
    [ "山形駅", "山形県", "山形線" ],
    [ "北山形駅", "山形県", "山形線" ],
    [ "羽前千歳駅", "山形県", "山形線" ],
    [ "南出羽駅", "山形県", "山形線" ],
    [ "漆山駅", "山形県", "山形線" ],
    [ "高擶駅", "山形県", "山形線" ],
    [ "天童南駅", "山形県", "山形線" ],
    [ "天童駅", "山形県", "山形線" ],
    [ "乱川駅", "山形県", "山形線" ],
    [ "神町駅", "山形県", "山形線" ],
    [ "さくらんぼ東根駅", "山形県", "山形線" ],
    [ "東根駅", "山形県", "山形線" ],
    [ "村山駅", "山形県", "山形線" ],
    [ "袖崎駅", "山形県", "山形線" ],
    [ "大石田駅", "山形県", "山形線" ],
    [ "北大石田駅", "山形県", "山形線" ],
    [ "芦沢駅", "山形県", "山形線" ],
    [ "舟形駅", "山形県", "山形線" ],
    [ "新庄駅", "山形県", "山形線" ],
    [ "仙台駅", "宮城県", "JR仙山線" ],
    [ "東照宮駅", "宮城県", "JR仙山線" ],
    [ "北仙台駅", "宮城県", "JR仙山線" ],
    [ "北山駅", "宮城県", "JR仙山線" ],
    [ "東北福祉大前駅", "宮城県", "JR仙山線" ],
    [ "国見駅", "宮城県", "JR仙山線" ],
    [ "葛岡駅", "宮城県", "JR仙山線" ],
    [ "陸前落合駅", "宮城県", "JR仙山線" ],
    [ "愛子駅", "宮城県", "JR仙山線" ],
    [ "陸前白沢駅", "宮城県", "JR仙山線" ],
    [ "熊ケ根駅", "宮城県", "JR仙山線" ],
    [ "西仙台ハイランド駅", "宮城県", "JR仙山線" ],
    [ "作並駅", "宮城県", "JR仙山線" ],
    [ "八ツ森駅", "宮城県", "JR仙山線" ],
    [ "奥新川駅", "宮城県", "JR仙山線" ],
    [ "面白山高原駅", "山形県", "JR仙山線" ],
    [ "山寺駅", "山形県", "JR仙山線" ],
    [ "高瀬駅", "山形県", "JR仙山線" ],
    [ "楯山駅", "山形県", "JR仙山線" ],
    [ "羽前千歳駅", "山形県", "JR仙山線" ],
    [ "北山形駅", "山形県", "JR仙山線" ],
    [ "山形駅", "山形県", "JR仙山線" ],
    [ "山形駅", "山形県", "フルーツライン左沢線" ],
    [ "北山形駅", "山形県", "フルーツライン左沢線" ],
    [ "東金井駅", "山形県", "フルーツライン左沢線" ],
    [ "羽前山辺駅", "山形県", "フルーツライン左沢線" ],
    [ "羽前金沢駅", "山形県", "フルーツライン左沢線" ],
    [ "羽前長崎駅", "山形県", "フルーツライン左沢線" ],
    [ "南寒河江駅", "山形県", "フルーツライン左沢線" ],
    [ "寒河江駅", "山形県", "フルーツライン左沢線" ],
    [ "西寒河江駅", "山形県", "フルーツライン左沢線" ],
    [ "羽前高松駅", "山形県", "フルーツライン左沢線" ],
    [ "柴橋駅", "山形県", "フルーツライン左沢線" ],
    [ "左沢駅", "山形県", "フルーツライン左沢線" ],
    [ "米沢駅", "山形県", "JR米坂線" ],
    [ "南米沢駅", "山形県", "JR米坂線" ],
    [ "西米沢駅", "山形県", "JR米坂線" ],
    [ "成島駅", "山形県", "JR米坂線" ],
    [ "中郡駅", "山形県", "JR米坂線" ],
    [ "羽前小松駅", "山形県", "JR米坂線" ],
    [ "犬川駅", "山形県", "JR米坂線" ],
    [ "今泉駅", "山形県", "JR米坂線" ],
    [ "萩生駅", "山形県", "JR米坂線" ],
    [ "羽前椿駅", "山形県", "JR米坂線" ],
    [ "手ノ子駅", "山形県", "JR米坂線" ],
    [ "羽前沼沢駅", "山形県", "JR米坂線" ],
    [ "伊佐領駅", "山形県", "JR米坂線" ],
    [ "羽前松岡駅", "山形県", "JR米坂線" ],
    [ "小国駅", "山形県", "JR米坂線" ],
    [ "越後金丸駅", "新潟県", "JR米坂線" ],
    [ "越後片貝駅", "新潟県", "JR米坂線" ],
    [ "越後下関駅", "新潟県", "JR米坂線" ],
    [ "越後大島駅", "新潟県", "JR米坂線" ],
    [ "坂町駅", "新潟県", "JR米坂線" ],
    [ "新庄駅", "山形県", "奥の細道最上川ライン" ],
    [ "升形駅", "山形県", "奥の細道最上川ライン" ],
    [ "羽前前波駅", "山形県", "奥の細道最上川ライン" ],
    [ "津谷駅", "山形県", "奥の細道最上川ライン" ],
    [ "古口駅", "山形県", "奥の細道最上川ライン" ],
    [ "高屋駅", "山形県", "奥の細道最上川ライン" ],
    [ "清川駅", "山形県", "奥の細道最上川ライン" ],
    [ "狩川駅", "山形県", "奥の細道最上川ライン" ],
    [ "南野駅", "山形県", "奥の細道最上川ライン" ],
    [ "余目駅", "山形県", "奥の細道最上川ライン" ],
    [ "小牛田駅", "宮城県", "奥の細道湯けむりライン" ],
    [ "北浦駅", "宮城県", "奥の細道湯けむりライン" ],
    [ "陸前谷地駅", "宮城県", "奥の細道湯けむりライン" ],
    [ "古川駅", "宮城県", "奥の細道湯けむりライン" ],
    [ "塚目駅", "宮城県", "奥の細道湯けむりライン" ],
    [ "西古川駅", "宮城県", "奥の細道湯けむりライン" ],
    [ "東大崎駅", "宮城県", "奥の細道湯けむりライン" ],
    [ "西大崎駅", "宮城県", "奥の細道湯けむりライン" ],
    [ "岩出山駅", "宮城県", "奥の細道湯けむりライン" ],
    [ "有備館駅", "宮城県", "奥の細道湯けむりライン" ],
    [ "上野目駅", "宮城県", "奥の細道湯けむりライン" ],
    [ "池月駅", "宮城県", "奥の細道湯けむりライン" ],
    [ "川渡温泉駅", "宮城県", "奥の細道湯けむりライン" ],
    [ "鳴子御殿湯駅", "宮城県", "奥の細道湯けむりライン" ],
    [ "鳴子温泉駅", "宮城県", "奥の細道湯けむりライン" ],
    [ "中山平温泉駅", "宮城県", "奥の細道湯けむりライン" ],
    [ "堺田駅", "山形県", "奥の細道湯けむりライン" ],
    [ "赤倉温泉駅", "山形県", "奥の細道湯けむりライン" ],
    [ "立小路駅", "山形県", "奥の細道湯けむりライン" ],
    [ "最上駅", "山形県", "奥の細道湯けむりライン" ],
    [ "大堀駅", "山形県", "奥の細道湯けむりライン" ],
    [ "鵜杉駅", "山形県", "奥の細道湯けむりライン" ],
    [ "瀬見温泉駅", "山形県", "奥の細道湯けむりライン" ],
    [ "東長沢駅", "山形県", "奥の細道湯けむりライン" ],
    [ "長沢駅", "山形県", "奥の細道湯けむりライン" ],
    [ "南新庄駅", "山形県", "奥の細道湯けむりライン" ],
    [ "新庄駅", "山形県", "奥の細道湯けむりライン" ],
    [ "あおば通駅", "宮城県", "JR仙石線" ],
    [ "仙台駅", "宮城県", "JR仙石線" ],
    [ "榴ケ岡駅", "宮城県", "JR仙石線" ],
    [ "宮城野原駅", "宮城県", "JR仙石線" ],
    [ "陸前原ノ町駅", "宮城県", "JR仙石線" ],
    [ "苦竹駅", "宮城県", "JR仙石線" ],
    [ "小鶴新田駅", "宮城県", "JR仙石線" ],
    [ "福田町駅", "宮城県", "JR仙石線" ],
    [ "陸前高砂駅", "宮城県", "JR仙石線" ],
    [ "中野栄駅", "宮城県", "JR仙石線" ],
    [ "多賀城駅", "宮城県", "JR仙石線" ],
    [ "下馬駅", "宮城県", "JR仙石線" ],
    [ "西塩釜駅", "宮城県", "JR仙石線" ],
    [ "本塩釜駅", "宮城県", "JR仙石線" ],
    [ "東塩釜駅", "宮城県", "JR仙石線" ],
    [ "陸前浜田駅", "宮城県", "JR仙石線" ],
    [ "松島海岸駅", "宮城県", "JR仙石線" ],
    [ "高城町駅", "宮城県", "JR仙石線" ],
    [ "手樽駅", "宮城県", "JR仙石線" ],
    [ "陸前富山駅", "宮城県", "JR仙石線" ],
    [ "陸前大塚駅", "宮城県", "JR仙石線" ],
    [ "東名駅", "宮城県", "JR仙石線" ],
    [ "野蒜駅", "宮城県", "JR仙石線" ],
    [ "陸前小野駅", "宮城県", "JR仙石線" ],
    [ "鹿妻駅", "宮城県", "JR仙石線" ],
    [ "矢本駅", "宮城県", "JR仙石線" ],
    [ "東矢本駅", "宮城県", "JR仙石線" ],
    [ "陸前赤井駅", "宮城県", "JR仙石線" ],
    [ "石巻あゆみ野駅", "宮城県", "JR仙石線" ],
    [ "蛇田駅", "宮城県", "JR仙石線" ],
    [ "陸前山下駅", "宮城県", "JR仙石線" ],
    [ "石巻駅", "宮城県", "JR仙石線" ],
    [ "小牛田駅", "宮城県", "JR石巻線" ],
    [ "上涌谷駅", "宮城県", "JR石巻線" ],
    [ "涌谷駅", "宮城県", "JR石巻線" ],
    [ "前谷地駅", "宮城県", "JR石巻線" ],
    [ "佳景山駅", "宮城県", "JR石巻線" ],
    [ "鹿又駅", "宮城県", "JR石巻線" ],
    [ "曽波神駅", "宮城県", "JR石巻線" ],
    [ "石巻駅", "宮城県", "JR石巻線" ],
    [ "陸前稲井駅", "宮城県", "JR石巻線" ],
    [ "渡波駅", "宮城県", "JR石巻線" ],
    [ "万石浦駅", "宮城県", "JR石巻線" ],
    [ "沢田駅", "宮城県", "JR石巻線" ],
    [ "浦宿駅", "宮城県", "JR石巻線" ],
    [ "女川駅", "宮城県", "JR石巻線" ],
    [ "前谷地駅", "宮城県", "JR気仙沼線" ],
    [ "和渕駅", "宮城県", "JR気仙沼線" ],
    [ "のの岳駅", "宮城県", "JR気仙沼線" ],
    [ "陸前豊里駅", "宮城県", "JR気仙沼線" ],
    [ "御岳堂駅", "宮城県", "JR気仙沼線" ],
    [ "柳津駅", "宮城県", "JR気仙沼線" ],
    [ "陸前横山駅", "宮城県", "JR気仙沼線" ],
    [ "陸前戸倉駅", "宮城県", "JR気仙沼線" ],
    [ "志津川駅", "宮城県", "JR気仙沼線" ],
    [ "清水浜駅", "宮城県", "JR気仙沼線" ],
    [ "歌津駅", "宮城県", "JR気仙沼線" ],
    [ "陸前港駅", "宮城県", "JR気仙沼線" ],
    [ "蔵内駅", "宮城県", "JR気仙沼線" ],
    [ "陸前小泉駅", "宮城県", "JR気仙沼線" ],
    [ "本吉駅", "宮城県", "JR気仙沼線" ],
    [ "小金沢駅", "宮城県", "JR気仙沼線" ],
    [ "大谷海岸駅", "宮城県", "JR気仙沼線" ],
    [ "陸前階上駅", "宮城県", "JR気仙沼線" ],
    [ "最知駅", "宮城県", "JR気仙沼線" ],
    [ "松岩駅", "宮城県", "JR気仙沼線" ],
    [ "南気仙沼駅", "宮城県", "JR気仙沼線" ],
    [ "不動の沢駅", "宮城県", "JR気仙沼線" ],
    [ "気仙沼駅", "宮城県", "JR気仙沼線" ],
    [ "郡山駅", "福島県", "JR磐越西線" ],
    [ "郡山富田駅", "福島県", "JR磐越西線" ],
    [ "喜久田駅", "福島県", "JR磐越西線" ],
    [ "安子ケ島駅", "福島県", "JR磐越西線" ],
    [ "磐梯熱海駅", "福島県", "JR磐越西線" ],
    [ "中山宿駅", "福島県", "JR磐越西線" ],
    [ "上戸駅", "福島県", "JR磐越西線" ],
    [ "猪苗代湖畔駅", "福島県", "JR磐越西線" ],
    [ "関都駅", "福島県", "JR磐越西線" ],
    [ "川桁駅", "福島県", "JR磐越西線" ],
    [ "猪苗代駅", "福島県", "JR磐越西線" ],
    [ "翁島駅", "福島県", "JR磐越西線" ],
    [ "磐梯町駅", "福島県", "JR磐越西線" ],
    [ "東長原駅", "福島県", "JR磐越西線" ],
    [ "広田駅", "福島県", "JR磐越西線" ],
    [ "会津若松駅", "福島県", "JR磐越西線" ],
    [ "会津若松駅", "福島県", "森と水とロマンの鉄道" ],
    [ "堂島駅", "福島県", "森と水とロマンの鉄道" ],
    [ "笈川駅", "福島県", "森と水とロマンの鉄道" ],
    [ "塩川駅", "福島県", "森と水とロマンの鉄道" ],
    [ "姥堂駅", "福島県", "森と水とロマンの鉄道" ],
    [ "会津豊川駅", "福島県", "森と水とロマンの鉄道" ],
    [ "喜多方駅", "福島県", "森と水とロマンの鉄道" ],
    [ "山都駅", "福島県", "森と水とロマンの鉄道" ],
    [ "荻野駅", "福島県", "森と水とロマンの鉄道" ],
    [ "尾登駅", "福島県", "森と水とロマンの鉄道" ],
    [ "野沢駅", "福島県", "森と水とロマンの鉄道" ],
    [ "上野尻駅", "福島県", "森と水とロマンの鉄道" ],
    [ "徳沢駅", "福島県", "森と水とロマンの鉄道" ],
    [ "豊実駅", "新潟県", "森と水とロマンの鉄道" ],
    [ "日出谷駅", "新潟県", "森と水とロマンの鉄道" ],
    [ "鹿瀬駅", "新潟県", "森と水とロマンの鉄道" ],
    [ "津川駅", "新潟県", "森と水とロマンの鉄道" ],
    [ "三川駅", "新潟県", "森と水とロマンの鉄道" ],
    [ "五十島駅", "新潟県", "森と水とロマンの鉄道" ],
    [ "東下条駅", "新潟県", "森と水とロマンの鉄道" ],
    [ "咲花駅", "新潟県", "森と水とロマンの鉄道" ],
    [ "馬下駅", "新潟県", "森と水とロマンの鉄道" ],
    [ "猿和田駅", "新潟県", "森と水とロマンの鉄道" ],
    [ "五泉駅", "新潟県", "森と水とロマンの鉄道" ],
    [ "北五泉駅", "新潟県", "森と水とロマンの鉄道" ],
    [ "新関駅", "新潟県", "森と水とロマンの鉄道" ],
    [ "東新津駅", "新潟県", "森と水とロマンの鉄道" ],
    [ "新津駅", "新潟県", "森と水とロマンの鉄道" ],
    [ "会津若松駅", "福島県", "JR只見線" ],
    [ "七日町駅", "福島県", "JR只見線" ],
    [ "西若松駅", "福島県", "JR只見線" ],
    [ "会津本郷駅", "福島県", "JR只見線" ],
    [ "会津高田駅", "福島県", "JR只見線" ],
    [ "根岸駅", "福島県", "JR只見線" ],
    [ "新鶴駅", "福島県", "JR只見線" ],
    [ "若宮駅", "福島県", "JR只見線" ],
    [ "会津坂下駅", "福島県", "JR只見線" ],
    [ "塔寺駅", "福島県", "JR只見線" ],
    [ "会津坂本駅", "福島県", "JR只見線" ],
    [ "会津柳津駅", "福島県", "JR只見線" ],
    [ "郷戸駅", "福島県", "JR只見線" ],
    [ "滝谷駅", "福島県", "JR只見線" ],
    [ "会津桧原駅", "福島県", "JR只見線" ],
    [ "会津西方駅", "福島県", "JR只見線" ],
    [ "会津宮下駅", "福島県", "JR只見線" ],
    [ "早戸駅", "福島県", "JR只見線" ],
    [ "会津水沼駅", "福島県", "JR只見線" ],
    [ "会津中川駅", "福島県", "JR只見線" ],
    [ "会津川口駅", "福島県", "JR只見線" ],
    [ "本名駅", "福島県", "JR只見線" ],
    [ "会津越川駅", "福島県", "JR只見線" ],
    [ "会津横田駅", "福島県", "JR只見線" ],
    [ "会津大塩駅", "福島県", "JR只見線" ],
    [ "会津塩沢駅", "福島県", "JR只見線" ],
    [ "会津蒲生駅", "福島県", "JR只見線" ],
    [ "只見駅", "福島県", "JR只見線" ],
    [ "田子倉駅", "福島県", "JR只見線" ],
    [ "大白川駅", "新潟県", "JR只見線" ],
    [ "柿ノ木駅", "新潟県", "JR只見線" ],
    [ "入広瀬駅", "新潟県", "JR只見線" ],
    [ "上条駅", "新潟県", "JR只見線" ],
    [ "越後須原駅", "新潟県", "JR只見線" ],
    [ "魚沼田中駅", "新潟県", "JR只見線" ],
    [ "越後広瀬駅", "新潟県", "JR只見線" ],
    [ "藪神駅", "新潟県", "JR只見線" ],
    [ "小出駅", "新潟県", "JR只見線" ],
    [ "いわき駅", "福島県", "ゆうゆうあぶくまライン" ],
    [ "赤井駅", "福島県", "ゆうゆうあぶくまライン" ],
    [ "小川郷駅", "福島県", "ゆうゆうあぶくまライン" ],
    [ "江田駅", "福島県", "ゆうゆうあぶくまライン" ],
    [ "川前駅", "福島県", "ゆうゆうあぶくまライン" ],
    [ "夏井駅", "福島県", "ゆうゆうあぶくまライン" ],
    [ "小野新町駅", "福島県", "ゆうゆうあぶくまライン" ],
    [ "神俣駅", "福島県", "ゆうゆうあぶくまライン" ],
    [ "菅谷駅", "福島県", "ゆうゆうあぶくまライン" ],
    [ "大越駅", "福島県", "ゆうゆうあぶくまライン" ],
    [ "磐城常葉駅", "福島県", "ゆうゆうあぶくまライン" ],
    [ "船引駅", "福島県", "ゆうゆうあぶくまライン" ],
    [ "要田駅", "福島県", "ゆうゆうあぶくまライン" ],
    [ "三春駅", "福島県", "ゆうゆうあぶくまライン" ],
    [ "舞木駅", "福島県", "ゆうゆうあぶくまライン" ],
    [ "郡山駅", "福島県", "ゆうゆうあぶくまライン" ],
    [ "黒磯駅", "栃木県", "JR東北本線" ],
    [ "高久駅", "栃木県", "JR東北本線" ],
    [ "黒田原駅", "栃木県", "JR東北本線" ],
    [ "豊原駅", "栃木県", "JR東北本線" ],
    [ "白坂駅", "福島県", "JR東北本線" ],
    [ "新白河駅", "福島県", "JR東北本線" ],
    [ "白河駅", "福島県", "JR東北本線" ],
    [ "久田野駅", "福島県", "JR東北本線" ],
    [ "泉崎駅", "福島県", "JR東北本線" ],
    [ "矢吹駅", "福島県", "JR東北本線" ],
    [ "鏡石駅", "福島県", "JR東北本線" ],
    [ "須賀川駅", "福島県", "JR東北本線" ],
    [ "安積永盛駅", "福島県", "JR東北本線" ],
    [ "郡山駅", "福島県", "JR東北本線" ],
    [ "日和田駅", "福島県", "JR東北本線" ],
    [ "五百川駅", "福島県", "JR東北本線" ],
    [ "本宮駅", "福島県", "JR東北本線" ],
    [ "杉田駅", "福島県", "JR東北本線" ],
    [ "二本松駅", "福島県", "JR東北本線" ],
    [ "安達駅", "福島県", "JR東北本線" ],
    [ "松川駅", "福島県", "JR東北本線" ],
    [ "金谷川駅", "福島県", "JR東北本線" ],
    [ "南福島駅", "福島県", "JR東北本線" ],
    [ "福島駅", "福島県", "JR東北本線" ],
    [ "東福島駅", "福島県", "JR東北本線" ],
    [ "伊達駅", "福島県", "JR東北本線" ],
    [ "桑折駅", "福島県", "JR東北本線" ],
    [ "藤田駅", "福島県", "JR東北本線" ],
    [ "貝田駅", "福島県", "JR東北本線" ],
    [ "越河駅", "宮城県", "JR東北本線" ],
    [ "白石駅", "宮城県", "JR東北本線" ],
    [ "東白石駅", "宮城県", "JR東北本線" ],
    [ "北白川駅", "宮城県", "JR東北本線" ],
    [ "大河原駅", "宮城県", "JR東北本線" ],
    [ "船岡駅", "宮城県", "JR東北本線" ],
    [ "槻木駅", "宮城県", "JR東北本線" ],
    [ "岩沼駅", "宮城県", "JR東北本線" ],
    [ "館腰駅", "宮城県", "JR東北本線" ],
    [ "名取駅", "宮城県", "JR東北本線" ],
    [ "南仙台駅", "宮城県", "JR東北本線" ],
    [ "太子堂駅", "宮城県", "JR東北本線" ],
    [ "長町駅", "宮城県", "JR東北本線" ],
    [ "仙台駅", "宮城県", "JR東北本線" ],
    [ "東仙台駅", "宮城県", "JR東北本線" ],
    [ "岩切駅", "宮城県", "JR東北本線" ],
    [ "新利府駅", "宮城県", "JR東北本線" ],
    [ "利府駅", "宮城県", "JR東北本線" ],
    [ "陸前山王駅", "宮城県", "JR東北本線" ],
    [ "国府多賀城駅", "宮城県", "JR東北本線" ],
    [ "塩釜駅", "宮城県", "JR東北本線" ],
    [ "松島駅", "宮城県", "JR東北本線" ],
    [ "愛宕駅", "宮城県", "JR東北本線" ],
    [ "品井沼駅", "宮城県", "JR東北本線" ],
    [ "鹿島台駅", "宮城県", "JR東北本線" ],
    [ "松山町駅", "宮城県", "JR東北本線" ],
    [ "小牛田駅", "宮城県", "JR東北本線" ],
    [ "田尻駅", "宮城県", "JR東北本線" ],
    [ "瀬峰駅", "宮城県", "JR東北本線" ],
    [ "梅ケ沢駅", "宮城県", "JR東北本線" ],
    [ "新田駅", "宮城県", "JR東北本線" ],
    [ "石越駅", "宮城県", "JR東北本線" ],
    [ "油島駅", "岩手県", "JR東北本線" ],
    [ "花泉駅", "岩手県", "JR東北本線" ],
    [ "清水原駅", "岩手県", "JR東北本線" ],
    [ "有壁駅", "宮城県", "JR東北本線" ],
    [ "一ノ関駅", "岩手県", "JR東北本線" ],
    [ "山ノ目駅", "岩手県", "JR東北本線" ],
    [ "平泉駅", "岩手県", "JR東北本線" ],
    [ "前沢駅", "岩手県", "JR東北本線" ],
    [ "陸中折居駅", "岩手県", "JR東北本線" ],
    [ "水沢駅", "岩手県", "JR東北本線" ],
    [ "金ケ崎駅", "岩手県", "JR東北本線" ],
    [ "六原駅", "岩手県", "JR東北本線" ],
    [ "北上駅", "岩手県", "JR東北本線" ],
    [ "村崎野駅", "岩手県", "JR東北本線" ],
    [ "花巻駅", "岩手県", "JR東北本線" ],
    [ "花巻空港駅", "岩手県", "JR東北本線" ],
    [ "石鳥谷駅", "岩手県", "JR東北本線" ],
    [ "日詰駅", "岩手県", "JR東北本線" ],
    [ "紫波中央駅", "岩手県", "JR東北本線" ],
    [ "古館駅", "岩手県", "JR東北本線" ],
    [ "矢幅駅", "岩手県", "JR東北本線" ],
    [ "岩手飯岡駅", "岩手県", "JR東北本線" ],
    [ "仙北町駅", "岩手県", "JR東北本線" ],
    [ "盛岡駅", "岩手県", "JR東北本線" ],
    [ "東京駅", "東京都", "JR東海道本線" ],
    [ "新橋駅", "東京都", "JR東海道本線" ],
    [ "品川駅", "東京都", "JR東海道本線" ],
    [ "川崎駅", "神奈川県", "JR東海道本線" ],
    [ "横浜駅", "神奈川県", "JR東海道本線" ],
    [ "戸塚駅", "神奈川県", "JR東海道本線" ],
    [ "大船駅", "神奈川県", "JR東海道本線" ],
    [ "藤沢駅", "神奈川県", "JR東海道本線" ],
    [ "辻堂駅", "神奈川県", "JR東海道本線" ],
    [ "茅ケ崎駅", "神奈川県", "JR東海道本線" ],
    [ "平塚駅", "神奈川県", "JR東海道本線" ],
    [ "大磯駅", "神奈川県", "JR東海道本線" ],
    [ "二宮駅", "神奈川県", "JR東海道本線" ],
    [ "国府津駅", "神奈川県", "JR東海道本線" ],
    [ "鴨宮駅", "神奈川県", "JR東海道本線" ],
    [ "小田原駅", "神奈川県", "JR東海道本線" ],
    [ "早川駅", "神奈川県", "JR東海道本線" ],
    [ "根府川駅", "神奈川県", "JR東海道本線" ],
    [ "真鶴駅", "神奈川県", "JR東海道本線" ],
    [ "湯河原駅", "神奈川県", "JR東海道本線" ],
    [ "熱海駅", "静岡県", "JR東海道本線" ],
    [ "大崎駅", "東京都", "JR山手線" ],
    [ "五反田駅", "東京都", "JR山手線" ],
    [ "目黒駅", "東京都", "JR山手線" ],
    [ "恵比寿駅", "東京都", "JR山手線" ],
    [ "渋谷駅", "東京都", "JR山手線" ],
    [ "原宿駅", "東京都", "JR山手線" ],
    [ "代々木駅", "東京都", "JR山手線" ],
    [ "新宿駅", "東京都", "JR山手線" ],
    [ "新大久保駅", "東京都", "JR山手線" ],
    [ "高田馬場駅", "東京都", "JR山手線" ],
    [ "目白駅", "東京都", "JR山手線" ],
    [ "池袋駅", "東京都", "JR山手線" ],
    [ "大塚駅", "東京都", "JR山手線" ],
    [ "巣鴨駅", "東京都", "JR山手線" ],
    [ "駒込駅", "東京都", "JR山手線" ],
    [ "田端駅", "東京都", "JR山手線" ],
    [ "西日暮里駅", "東京都", "JR山手線" ],
    [ "日暮里駅", "東京都", "JR山手線" ],
    [ "鶯谷駅", "東京都", "JR山手線" ],
    [ "上野駅", "東京都", "JR山手線" ],
    [ "御徒町駅", "東京都", "JR山手線" ],
    [ "秋葉原駅", "東京都", "JR山手線" ],
    [ "神田駅", "東京都", "JR山手線" ],
    [ "東京駅", "東京都", "JR山手線" ],
    [ "有楽町駅", "東京都", "JR山手線" ],
    [ "新橋駅", "東京都", "JR山手線" ],
    [ "浜松町駅", "東京都", "JR山手線" ],
    [ "田町駅", "東京都", "JR山手線" ],
    [ "高輪ゲートウェイ駅", "東京都", "JR山手線" ],
    [ "品川駅", "東京都", "JR山手線" ],
    [ "川崎駅", "神奈川県", "JR南武線" ],
    [ "尻手駅", "神奈川県", "JR南武線" ],
    [ "矢向駅", "神奈川県", "JR南武線" ],
    [ "鹿島田駅", "神奈川県", "JR南武線" ],
    [ "平間駅", "神奈川県", "JR南武線" ],
    [ "向河原駅", "神奈川県", "JR南武線" ],
    [ "武蔵小杉駅", "神奈川県", "JR南武線" ],
    [ "武蔵中原駅", "神奈川県", "JR南武線" ],
    [ "武蔵新城駅", "神奈川県", "JR南武線" ],
    [ "武蔵溝ノ口駅", "神奈川県", "JR南武線" ],
    [ "津田山駅", "神奈川県", "JR南武線" ],
    [ "久地駅", "神奈川県", "JR南武線" ],
    [ "宿河原駅", "神奈川県", "JR南武線" ],
    [ "登戸駅", "神奈川県", "JR南武線" ],
    [ "中野島駅", "神奈川県", "JR南武線" ],
    [ "稲田堤駅", "神奈川県", "JR南武線" ],
    [ "矢野口駅", "東京都", "JR南武線" ],
    [ "稲城長沼駅", "東京都", "JR南武線" ],
    [ "南多摩駅", "東京都", "JR南武線" ],
    [ "府中本町駅", "東京都", "JR南武線" ],
    [ "分倍河原駅", "東京都", "JR南武線" ],
    [ "西府駅", "東京都", "JR南武線" ],
    [ "谷保駅", "東京都", "JR南武線" ],
    [ "矢川駅", "東京都", "JR南武線" ],
    [ "西国立駅", "東京都", "JR南武線" ],
    [ "立川駅", "東京都", "JR南武線" ],
    [ "八丁畷駅", "神奈川県", "JR南武線" ],
    [ "川崎新町駅", "神奈川県", "JR南武線" ],
    [ "小田栄駅", "神奈川県", "JR南武線" ],
    [ "浜川崎駅", "神奈川県", "JR南武線" ],
    [ "鶴見駅", "神奈川県", "JR鶴見線" ],
    [ "国道駅", "神奈川県", "JR鶴見線" ],
    [ "鶴見小野駅", "神奈川県", "JR鶴見線" ],
    [ "弁天橋駅", "神奈川県", "JR鶴見線" ],
    [ "浅野駅", "神奈川県", "JR鶴見線" ],
    [ "新芝浦駅", "神奈川県", "JR鶴見線" ],
    [ "海芝浦駅", "神奈川県", "JR鶴見線" ],
    [ "安善駅", "神奈川県", "JR鶴見線" ],
    [ "大川駅", "神奈川県", "JR鶴見線" ],
    [ "武蔵白石駅", "神奈川県", "JR鶴見線" ],
    [ "浜川崎駅", "神奈川県", "JR鶴見線" ],
    [ "昭和駅", "神奈川県", "JR鶴見線" ],
    [ "扇町駅", "神奈川県", "JR鶴見線" ],
    [ "府中本町駅", "東京都", "JR武蔵野線" ],
    [ "北府中駅", "東京都", "JR武蔵野線" ],
    [ "西国分寺駅", "東京都", "JR武蔵野線" ],
    [ "新小平駅", "東京都", "JR武蔵野線" ],
    [ "新秋津駅", "東京都", "JR武蔵野線" ],
    [ "東所沢駅", "埼玉県", "JR武蔵野線" ],
    [ "新座駅", "埼玉県", "JR武蔵野線" ],
    [ "北朝霞駅", "埼玉県", "JR武蔵野線" ],
    [ "西浦和駅", "埼玉県", "JR武蔵野線" ],
    [ "武蔵浦和駅", "埼玉県", "JR武蔵野線" ],
    [ "南浦和駅", "埼玉県", "JR武蔵野線" ],
    [ "東浦和駅", "埼玉県", "JR武蔵野線" ],
    [ "東川口駅", "埼玉県", "JR武蔵野線" ],
    [ "南越谷駅", "埼玉県", "JR武蔵野線" ],
    [ "越谷レイクタウン駅", "埼玉県", "JR武蔵野線" ],
    [ "吉川駅", "埼玉県", "JR武蔵野線" ],
    [ "吉川美南駅", "埼玉県", "JR武蔵野線" ],
    [ "新三郷駅", "埼玉県", "JR武蔵野線" ],
    [ "三郷駅", "埼玉県", "JR武蔵野線" ],
    [ "南流山駅", "千葉県", "JR武蔵野線" ],
    [ "新松戸駅", "千葉県", "JR武蔵野線" ],
    [ "新八柱駅", "千葉県", "JR武蔵野線" ],
    [ "東松戸駅", "千葉県", "JR武蔵野線" ],
    [ "市川大野駅", "千葉県", "JR武蔵野線" ],
    [ "船橋法典駅", "千葉県", "JR武蔵野線" ],
    [ "西船橋駅", "千葉県", "JR武蔵野線" ],
    [ "東神奈川駅", "神奈川県", "JR横浜線" ],
    [ "大口駅", "神奈川県", "JR横浜線" ],
    [ "菊名駅", "神奈川県", "JR横浜線" ],
    [ "新横浜駅", "神奈川県", "JR横浜線" ],
    [ "小机駅", "神奈川県", "JR横浜線" ],
    [ "鴨居駅", "神奈川県", "JR横浜線" ],
    [ "中山駅", "神奈川県", "JR横浜線" ],
    [ "十日市場駅", "神奈川県", "JR横浜線" ],
    [ "長津田駅", "神奈川県", "JR横浜線" ],
    [ "成瀬駅", "東京都", "JR横浜線" ],
    [ "町田駅", "東京都", "JR横浜線" ],
    [ "古淵駅", "神奈川県", "JR横浜線" ],
    [ "淵野辺駅", "神奈川県", "JR横浜線" ],
    [ "矢部駅", "神奈川県", "JR横浜線" ],
    [ "相模原駅", "神奈川県", "JR横浜線" ],
    [ "橋本駅", "神奈川県", "JR横浜線" ],
    [ "相原駅", "東京都", "JR横浜線" ],
    [ "八王子みなみ野駅", "東京都", "JR横浜線" ],
    [ "片倉駅", "東京都", "JR横浜線" ],
    [ "八王子駅", "東京都", "JR横浜線" ],
    [ "横浜駅", "神奈川県", "JR根岸線" ],
    [ "桜木町駅", "神奈川県", "JR根岸線" ],
    [ "関内駅", "神奈川県", "JR根岸線" ],
    [ "石川町駅", "神奈川県", "JR根岸線" ],
    [ "山手駅", "神奈川県", "JR根岸線" ],
    [ "根岸駅", "神奈川県", "JR根岸線" ],
    [ "磯子駅", "神奈川県", "JR根岸線" ],
    [ "新杉田駅", "神奈川県", "JR根岸線" ],
    [ "洋光台駅", "神奈川県", "JR根岸線" ],
    [ "港南台駅", "神奈川県", "JR根岸線" ],
    [ "本郷台駅", "神奈川県", "JR根岸線" ],
    [ "大船駅", "神奈川県", "JR根岸線" ],
    [ "東京駅", "東京都", "JR横須賀線" ],
    [ "新橋駅", "東京都", "JR横須賀線" ],
    [ "品川駅", "東京都", "JR横須賀線" ],
    [ "西大井駅", "東京都", "JR横須賀線" ],
    [ "武蔵小杉駅", "神奈川県", "JR横須賀線" ],
    [ "新川崎駅", "神奈川県", "JR横須賀線" ],
    [ "横浜駅", "神奈川県", "JR横須賀線" ],
    [ "保土ケ谷駅", "神奈川県", "JR横須賀線" ],
    [ "東戸塚駅", "神奈川県", "JR横須賀線" ],
    [ "戸塚駅", "神奈川県", "JR横須賀線" ],
    [ "大船駅", "神奈川県", "JR横須賀線" ],
    [ "北鎌倉駅", "神奈川県", "JR横須賀線" ],
    [ "鎌倉駅", "神奈川県", "JR横須賀線" ],
    [ "逗子駅", "神奈川県", "JR横須賀線" ],
    [ "東逗子駅", "神奈川県", "JR横須賀線" ],
    [ "田浦駅", "神奈川県", "JR横須賀線" ],
    [ "横須賀駅", "神奈川県", "JR横須賀線" ],
    [ "衣笠駅", "神奈川県", "JR横須賀線" ],
    [ "久里浜駅", "神奈川県", "JR横須賀線" ],
    [ "茅ケ崎駅", "神奈川県", "JR相模線" ],
    [ "北茅ケ崎駅", "神奈川県", "JR相模線" ],
    [ "香川駅", "神奈川県", "JR相模線" ],
    [ "寒川駅", "神奈川県", "JR相模線" ],
    [ "宮山駅", "神奈川県", "JR相模線" ],
    [ "倉見駅", "神奈川県", "JR相模線" ],
    [ "門沢橋駅", "神奈川県", "JR相模線" ],
    [ "社家駅", "神奈川県", "JR相模線" ],
    [ "厚木駅", "神奈川県", "JR相模線" ],
    [ "海老名駅", "神奈川県", "JR相模線" ],
    [ "入谷駅", "神奈川県", "JR相模線" ],
    [ "相武台下駅", "神奈川県", "JR相模線" ],
    [ "下溝駅", "神奈川県", "JR相模線" ],
    [ "原当麻駅", "神奈川県", "JR相模線" ],
    [ "番田駅", "神奈川県", "JR相模線" ],
    [ "上溝駅", "神奈川県", "JR相模線" ],
    [ "南橋本駅", "神奈川県", "JR相模線" ],
    [ "橋本駅", "神奈川県", "JR相模線" ],
    [ "東京駅", "東京都", "JR中央本線" ],
    [ "四ツ谷駅", "東京都", "JR中央本線" ],
    [ "新宿駅", "東京都", "JR中央本線" ],
    [ "吉祥寺駅", "東京都", "JR中央本線" ],
    [ "三鷹駅", "東京都", "JR中央本線" ],
    [ "国分寺駅", "東京都", "JR中央本線" ],
    [ "立川駅", "東京都", "JR中央本線" ],
    [ "日野駅", "東京都", "JR中央本線" ],
    [ "豊田駅", "東京都", "JR中央本線" ],
    [ "八王子駅", "東京都", "JR中央本線" ],
    [ "西八王子駅", "東京都", "JR中央本線" ],
    [ "高尾駅", "東京都", "JR中央本線" ],
    [ "相模湖駅", "神奈川県", "JR中央本線" ],
    [ "藤野駅", "神奈川県", "JR中央本線" ],
    [ "上野原駅", "山梨県", "JR中央本線" ],
    [ "四方津駅", "山梨県", "JR中央本線" ],
    [ "梁川駅", "山梨県", "JR中央本線" ],
    [ "鳥沢駅", "山梨県", "JR中央本線" ],
    [ "猿橋駅", "山梨県", "JR中央本線" ],
    [ "大月駅", "山梨県", "JR中央本線" ],
    [ "初狩駅", "山梨県", "JR中央本線" ],
    [ "笹子駅", "山梨県", "JR中央本線" ],
    [ "甲斐大和駅", "山梨県", "JR中央本線" ],
    [ "勝沼ぶどう郷駅", "山梨県", "JR中央本線" ],
    [ "塩山駅", "山梨県", "JR中央本線" ],
    [ "東山梨駅", "山梨県", "JR中央本線" ],
    [ "山梨市駅", "山梨県", "JR中央本線" ],
    [ "春日居町駅", "山梨県", "JR中央本線" ],
    [ "石和温泉駅", "山梨県", "JR中央本線" ],
    [ "酒折駅", "山梨県", "JR中央本線" ],
    [ "甲府駅", "山梨県", "JR中央本線" ],
    [ "竜王駅", "山梨県", "JR中央本線" ],
    [ "塩崎駅", "山梨県", "JR中央本線" ],
    [ "韮崎駅", "山梨県", "JR中央本線" ],
    [ "新府駅", "山梨県", "JR中央本線" ],
    [ "穴山駅", "山梨県", "JR中央本線" ],
    [ "日野春駅", "山梨県", "JR中央本線" ],
    [ "長坂駅", "山梨県", "JR中央本線" ],
    [ "小淵沢駅", "山梨県", "JR中央本線" ],
    [ "信濃境駅", "長野県", "JR中央本線" ],
    [ "富士見駅", "長野県", "JR中央本線" ],
    [ "すずらんの里駅", "長野県", "JR中央本線" ],
    [ "青柳駅", "長野県", "JR中央本線" ],
    [ "茅野駅", "長野県", "JR中央本線" ],
    [ "上諏訪駅", "長野県", "JR中央本線" ],
    [ "下諏訪駅", "長野県", "JR中央本線" ],
    [ "岡谷駅", "長野県", "JR中央本線" ],
    [ "みどり湖駅", "長野県", "JR中央本線" ],
    [ "川岸駅", "長野県", "JR中央本線" ],
    [ "辰野駅", "長野県", "JR中央本線" ],
    [ "信濃川島駅", "長野県", "JR中央本線" ],
    [ "小野駅", "長野県", "JR中央本線" ],
    [ "塩尻駅", "長野県", "JR中央本線" ],
    [ "東京駅", "東京都", "JR中央線" ],
    [ "神田駅", "東京都", "JR中央線" ],
    [ "御茶ノ水駅", "東京都", "JR中央線" ],
    [ "四ツ谷駅", "東京都", "JR中央線" ],
    [ "新宿駅", "東京都", "JR中央線" ],
    [ "中野駅", "東京都", "JR中央線" ],
    [ "高円寺駅", "東京都", "JR中央線" ],
    [ "阿佐ケ谷駅", "東京都", "JR中央線" ],
    [ "荻窪駅", "東京都", "JR中央線" ],
    [ "西荻窪駅", "東京都", "JR中央線" ],
    [ "吉祥寺駅", "東京都", "JR中央線" ],
    [ "三鷹駅", "東京都", "JR中央線" ],
    [ "武蔵境駅", "東京都", "JR中央線" ],
    [ "東小金井駅", "東京都", "JR中央線" ],
    [ "武蔵小金井駅", "東京都", "JR中央線" ],
    [ "国分寺駅", "東京都", "JR中央線" ],
    [ "西国分寺駅", "東京都", "JR中央線" ],
    [ "国立駅", "東京都", "JR中央線" ],
    [ "立川駅", "東京都", "JR中央線" ],
    [ "日野駅", "東京都", "JR中央線" ],
    [ "豊田駅", "東京都", "JR中央線" ],
    [ "八王子駅", "東京都", "JR中央線" ],
    [ "西八王子駅", "東京都", "JR中央線" ],
    [ "高尾駅", "東京都", "JR中央線" ],
    [ "三鷹駅", "東京都", "JR中央・総武線" ],
    [ "吉祥寺駅", "東京都", "JR中央・総武線" ],
    [ "西荻窪駅", "東京都", "JR中央・総武線" ],
    [ "荻窪駅", "東京都", "JR中央・総武線" ],
    [ "阿佐ケ谷駅", "東京都", "JR中央・総武線" ],
    [ "高円寺駅", "東京都", "JR中央・総武線" ],
    [ "中野駅", "東京都", "JR中央・総武線" ],
    [ "東中野駅", "東京都", "JR中央・総武線" ],
    [ "大久保駅", "東京都", "JR中央・総武線" ],
    [ "新宿駅", "東京都", "JR中央・総武線" ],
    [ "代々木駅", "東京都", "JR中央・総武線" ],
    [ "千駄ケ谷駅", "東京都", "JR中央・総武線" ],
    [ "信濃町駅", "東京都", "JR中央・総武線" ],
    [ "四ツ谷駅", "東京都", "JR中央・総武線" ],
    [ "市ケ谷駅", "東京都", "JR中央・総武線" ],
    [ "飯田橋駅", "東京都", "JR中央・総武線" ],
    [ "水道橋駅", "東京都", "JR中央・総武線" ],
    [ "御茶ノ水駅", "東京都", "JR中央・総武線" ],
    [ "秋葉原駅", "東京都", "JR中央・総武線" ],
    [ "浅草橋駅", "東京都", "JR中央・総武線" ],
    [ "両国駅", "東京都", "JR中央・総武線" ],
    [ "錦糸町駅", "東京都", "JR中央・総武線" ],
    [ "亀戸駅", "東京都", "JR中央・総武線" ],
    [ "平井駅", "東京都", "JR中央・総武線" ],
    [ "新小岩駅", "東京都", "JR中央・総武線" ],
    [ "小岩駅", "東京都", "JR中央・総武線" ],
    [ "市川駅", "千葉県", "JR中央・総武線" ],
    [ "本八幡駅", "千葉県", "JR中央・総武線" ],
    [ "下総中山駅", "千葉県", "JR中央・総武線" ],
    [ "西船橋駅", "千葉県", "JR中央・総武線" ],
    [ "船橋駅", "千葉県", "JR中央・総武線" ],
    [ "東船橋駅", "千葉県", "JR中央・総武線" ],
    [ "津田沼駅", "千葉県", "JR中央・総武線" ],
    [ "幕張本郷駅", "千葉県", "JR中央・総武線" ],
    [ "幕張駅", "千葉県", "JR中央・総武線" ],
    [ "新検見川駅", "千葉県", "JR中央・総武線" ],
    [ "稲毛駅", "千葉県", "JR中央・総武線" ],
    [ "西千葉駅", "千葉県", "JR中央・総武線" ],
    [ "千葉駅", "千葉県", "JR中央・総武線" ],
    [ "東京駅", "東京都", "JR総武本線" ],
    [ "新日本橋駅", "東京都", "JR総武本線" ],
    [ "馬喰町駅", "東京都", "JR総武本線" ],
    [ "錦糸町駅", "東京都", "JR総武本線" ],
    [ "新小岩駅", "東京都", "JR総武本線" ],
    [ "市川駅", "千葉県", "JR総武本線" ],
    [ "船橋駅", "千葉県", "JR総武本線" ],
    [ "津田沼駅", "千葉県", "JR総武本線" ],
    [ "稲毛駅", "千葉県", "JR総武本線" ],
    [ "千葉駅", "千葉県", "JR総武本線" ],
    [ "東千葉駅", "千葉県", "JR総武本線" ],
    [ "都賀駅", "千葉県", "JR総武本線" ],
    [ "四街道駅", "千葉県", "JR総武本線" ],
    [ "物井駅", "千葉県", "JR総武本線" ],
    [ "佐倉駅", "千葉県", "JR総武本線" ],
    [ "南酒々井駅", "千葉県", "JR総武本線" ],
    [ "榎戸駅", "千葉県", "JR総武本線" ],
    [ "八街駅", "千葉県", "JR総武本線" ],
    [ "日向駅", "千葉県", "JR総武本線" ],
    [ "成東駅", "千葉県", "JR総武本線" ],
    [ "松尾駅", "千葉県", "JR総武本線" ],
    [ "横芝駅", "千葉県", "JR総武本線" ],
    [ "飯倉駅", "千葉県", "JR総武本線" ],
    [ "八日市場駅", "千葉県", "JR総武本線" ],
    [ "干潟駅", "千葉県", "JR総武本線" ],
    [ "旭駅", "千葉県", "JR総武本線" ],
    [ "飯岡駅", "千葉県", "JR総武本線" ],
    [ "倉橋駅", "千葉県", "JR総武本線" ],
    [ "猿田駅", "千葉県", "JR総武本線" ],
    [ "松岸駅", "千葉県", "JR総武本線" ],
    [ "銚子駅", "千葉県", "JR総武本線" ],
    [ "立川駅", "東京都", "JR青梅線" ],
    [ "西立川駅", "東京都", "JR青梅線" ],
    [ "東中神駅", "東京都", "JR青梅線" ],
    [ "中神駅", "東京都", "JR青梅線" ],
    [ "昭島駅", "東京都", "JR青梅線" ],
    [ "拝島駅", "東京都", "JR青梅線" ],
    [ "牛浜駅", "東京都", "JR青梅線" ],
    [ "福生駅", "東京都", "JR青梅線" ],
    [ "羽村駅", "東京都", "JR青梅線" ],
    [ "小作駅", "東京都", "JR青梅線" ],
    [ "河辺駅", "東京都", "JR青梅線" ],
    [ "東青梅駅", "東京都", "JR青梅線" ],
    [ "青梅駅", "東京都", "JR青梅線" ],
    [ "宮ノ平駅", "東京都", "JR青梅線" ],
    [ "日向和田駅", "東京都", "JR青梅線" ],
    [ "石神前駅", "東京都", "JR青梅線" ],
    [ "二俣尾駅", "東京都", "JR青梅線" ],
    [ "軍畑駅", "東京都", "JR青梅線" ],
    [ "沢井駅", "東京都", "JR青梅線" ],
    [ "御嶽駅", "東京都", "JR青梅線" ],
    [ "川井駅", "東京都", "JR青梅線" ],
    [ "古里駅", "東京都", "JR青梅線" ],
    [ "鳩ノ巣駅", "東京都", "JR青梅線" ],
    [ "白丸駅", "東京都", "JR青梅線" ],
    [ "奥多摩駅", "東京都", "JR青梅線" ],
    [ "拝島駅", "東京都", "JR五日市線" ],
    [ "熊川駅", "東京都", "JR五日市線" ],
    [ "東秋留駅", "東京都", "JR五日市線" ],
    [ "秋川駅", "東京都", "JR五日市線" ],
    [ "武蔵引田駅", "東京都", "JR五日市線" ],
    [ "武蔵増戸駅", "東京都", "JR五日市線" ],
    [ "武蔵五日市駅", "東京都", "JR五日市線" ],
    [ "八王子駅", "東京都", "JR八高線" ],
    [ "北八王子駅", "東京都", "JR八高線" ],
    [ "小宮駅", "東京都", "JR八高線" ],
    [ "拝島駅", "東京都", "JR八高線" ],
    [ "東福生駅", "東京都", "JR八高線" ],
    [ "箱根ケ崎駅", "東京都", "JR八高線" ],
    [ "金子駅", "埼玉県", "JR八高線" ],
    [ "東飯能駅", "埼玉県", "JR八高線" ],
    [ "高麗川駅", "埼玉県", "JR八高線" ],
    [ "高麗川駅", "埼玉県", "JR八高線" ],
    [ "毛呂駅", "埼玉県", "JR八高線" ],
    [ "越生駅", "埼玉県", "JR八高線" ],
    [ "明覚駅", "埼玉県", "JR八高線" ],
    [ "小川町駅", "埼玉県", "JR八高線" ],
    [ "竹沢駅", "埼玉県", "JR八高線" ],
    [ "折原駅", "埼玉県", "JR八高線" ],
    [ "寄居駅", "埼玉県", "JR八高線" ],
    [ "用土駅", "埼玉県", "JR八高線" ],
    [ "松久駅", "埼玉県", "JR八高線" ],
    [ "児玉駅", "埼玉県", "JR八高線" ],
    [ "丹荘駅", "埼玉県", "JR八高線" ],
    [ "群馬藤岡駅", "群馬県", "JR八高線" ],
    [ "北藤岡駅", "群馬県", "JR八高線" ],
    [ "倉賀野駅", "群馬県", "JR八高線" ],
    [ "高崎駅", "群馬県", "JR八高線" ],
    [ "上野駅", "東京都", "宇都宮線" ],
    [ "尾久駅", "東京都", "宇都宮線" ],
    [ "赤羽駅", "東京都", "宇都宮線" ],
    [ "浦和駅", "埼玉県", "宇都宮線" ],
    [ "さいたま新都心駅", "埼玉県", "宇都宮線" ],
    [ "大宮駅", "埼玉県", "宇都宮線" ],
    [ "土呂駅", "埼玉県", "宇都宮線" ],
    [ "東大宮駅", "埼玉県", "宇都宮線" ],
    [ "蓮田駅", "埼玉県", "宇都宮線" ],
    [ "白岡駅", "埼玉県", "宇都宮線" ],
    [ "新白岡駅", "埼玉県", "宇都宮線" ],
    [ "久喜駅", "埼玉県", "宇都宮線" ],
    [ "東鷲宮駅", "埼玉県", "宇都宮線" ],
    [ "栗橋駅", "埼玉県", "宇都宮線" ],
    [ "古河駅", "茨城県", "宇都宮線" ],
    [ "野木駅", "栃木県", "宇都宮線" ],
    [ "間々田駅", "栃木県", "宇都宮線" ],
    [ "小山駅", "栃木県", "宇都宮線" ],
    [ "小金井駅", "栃木県", "宇都宮線" ],
    [ "自治医大駅", "栃木県", "宇都宮線" ],
    [ "石橋駅", "栃木県", "宇都宮線" ],
    [ "雀宮駅", "栃木県", "宇都宮線" ],
    [ "宇都宮駅", "栃木県", "宇都宮線" ],
    [ "岡本駅", "栃木県", "宇都宮線" ],
    [ "宝積寺駅", "栃木県", "宇都宮線" ],
    [ "氏家駅", "栃木県", "宇都宮線" ],
    [ "蒲須坂駅", "栃木県", "宇都宮線" ],
    [ "片岡駅", "栃木県", "宇都宮線" ],
    [ "矢板駅", "栃木県", "宇都宮線" ],
    [ "野崎駅", "栃木県", "宇都宮線" ],
    [ "西那須野駅", "栃木県", "宇都宮線" ],
    [ "那須塩原駅", "栃木県", "宇都宮線" ],
    [ "黒磯駅", "栃木県", "宇都宮線" ],
    [ "上野駅", "東京都", "JR常磐線" ],
    [ "日暮里駅", "東京都", "JR常磐線" ],
    [ "三河島駅", "東京都", "JR常磐線" ],
    [ "南千住駅", "東京都", "JR常磐線" ],
    [ "北千住駅", "東京都", "JR常磐線" ],
    [ "綾瀬駅", "東京都", "JR常磐線" ],
    [ "亀有駅", "東京都", "JR常磐線" ],
    [ "金町駅", "東京都", "JR常磐線" ],
    [ "松戸駅", "千葉県", "JR常磐線" ],
    [ "北松戸駅", "千葉県", "JR常磐線" ],
    [ "馬橋駅", "千葉県", "JR常磐線" ],
    [ "新松戸駅", "千葉県", "JR常磐線" ],
    [ "北小金駅", "千葉県", "JR常磐線" ],
    [ "南柏駅", "千葉県", "JR常磐線" ],
    [ "柏駅", "千葉県", "JR常磐線" ],
    [ "北柏駅", "千葉県", "JR常磐線" ],
    [ "我孫子駅", "千葉県", "JR常磐線" ],
    [ "天王台駅", "千葉県", "JR常磐線" ],
    [ "取手駅", "茨城県", "JR常磐線" ],
    [ "取手駅", "茨城県", "JR常磐線" ],
    [ "藤代駅", "茨城県", "JR常磐線" ],
    [ "龍ケ崎市駅", "茨城県", "JR常磐線" ],
    [ "牛久駅", "茨城県", "JR常磐線" ],
    [ "ひたち野うしく駅", "茨城県", "JR常磐線" ],
    [ "荒川沖駅", "茨城県", "JR常磐線" ],
    [ "土浦駅", "茨城県", "JR常磐線" ],
    [ "神立駅", "茨城県", "JR常磐線" ],
    [ "高浜駅", "茨城県", "JR常磐線" ],
    [ "石岡駅", "茨城県", "JR常磐線" ],
    [ "羽鳥駅", "茨城県", "JR常磐線" ],
    [ "岩間駅", "茨城県", "JR常磐線" ],
    [ "友部駅", "茨城県", "JR常磐線" ],
    [ "内原駅", "茨城県", "JR常磐線" ],
    [ "赤塚駅", "茨城県", "JR常磐線" ],
    [ "偕楽園駅", "茨城県", "JR常磐線" ],
    [ "水戸駅", "茨城県", "JR常磐線" ],
    [ "勝田駅", "茨城県", "JR常磐線" ],
    [ "佐和駅", "茨城県", "JR常磐線" ],
    [ "東海駅", "茨城県", "JR常磐線" ],
    [ "大甕駅", "茨城県", "JR常磐線" ],
    [ "常陸多賀駅", "茨城県", "JR常磐線" ],
    [ "日立駅", "茨城県", "JR常磐線" ],
    [ "小木津駅", "茨城県", "JR常磐線" ],
    [ "十王駅", "茨城県", "JR常磐線" ],
    [ "高萩駅", "茨城県", "JR常磐線" ],
    [ "南中郷駅", "茨城県", "JR常磐線" ],
    [ "磯原駅", "茨城県", "JR常磐線" ],
    [ "大津港駅", "茨城県", "JR常磐線" ],
    [ "勿来駅", "福島県", "JR常磐線" ],
    [ "植田駅", "福島県", "JR常磐線" ],
    [ "泉駅", "福島県", "JR常磐線" ],
    [ "湯本駅", "福島県", "JR常磐線" ],
    [ "内郷駅", "福島県", "JR常磐線" ],
    [ "いわき駅", "福島県", "JR常磐線" ],
    [ "いわき駅", "福島県", "JR常磐線" ],
    [ "草野駅", "福島県", "JR常磐線" ],
    [ "四ツ倉駅", "福島県", "JR常磐線" ],
    [ "久ノ浜駅", "福島県", "JR常磐線" ],
    [ "末続駅", "福島県", "JR常磐線" ],
    [ "広野駅", "福島県", "JR常磐線" ],
    [ "Jヴィレッジ駅", "福島県", "JR常磐線" ],
    [ "木戸駅", "福島県", "JR常磐線" ],
    [ "竜田駅", "福島県", "JR常磐線" ],
    [ "富岡駅", "福島県", "JR常磐線" ],
    [ "夜ノ森駅", "福島県", "JR常磐線" ],
    [ "大野駅", "福島県", "JR常磐線" ],
    [ "双葉駅", "福島県", "JR常磐線" ],
    [ "浪江駅", "福島県", "JR常磐線" ],
    [ "桃内駅", "福島県", "JR常磐線" ],
    [ "小高駅", "福島県", "JR常磐線" ],
    [ "磐城太田駅", "福島県", "JR常磐線" ],
    [ "原ノ町駅", "福島県", "JR常磐線" ],
    [ "鹿島駅", "福島県", "JR常磐線" ],
    [ "日立木駅", "福島県", "JR常磐線" ],
    [ "相馬駅", "福島県", "JR常磐線" ],
    [ "駒ケ嶺駅", "福島県", "JR常磐線" ],
    [ "新地駅", "福島県", "JR常磐線" ],
    [ "坂元駅", "宮城県", "JR常磐線" ],
    [ "山下駅", "宮城県", "JR常磐線" ],
    [ "浜吉田駅", "宮城県", "JR常磐線" ],
    [ "亘理駅", "宮城県", "JR常磐線" ],
    [ "逢隈駅", "宮城県", "JR常磐線" ],
    [ "岩沼駅", "宮城県", "JR常磐線" ],
    [ "館腰駅", "宮城県", "JR常磐線" ],
    [ "名取駅", "宮城県", "JR常磐線" ],
    [ "南仙台駅", "宮城県", "JR常磐線" ],
    [ "太子堂駅", "宮城県", "JR常磐線" ],
    [ "長町駅", "宮城県", "JR常磐線" ],
    [ "仙台駅", "宮城県", "JR常磐線" ],
    [ "大崎駅", "東京都", "JR埼京線" ],
    [ "恵比寿駅", "東京都", "JR埼京線" ],
    [ "渋谷駅", "東京都", "JR埼京線" ],
    [ "新宿駅", "東京都", "JR埼京線" ],
    [ "池袋駅", "東京都", "JR埼京線" ],
    [ "板橋駅", "東京都", "JR埼京線" ],
    [ "十条駅", "東京都", "JR埼京線" ],
    [ "赤羽駅", "東京都", "JR埼京線" ],
    [ "北赤羽駅", "東京都", "JR埼京線" ],
    [ "浮間舟渡駅", "東京都", "JR埼京線" ],
    [ "戸田公園駅", "埼玉県", "JR埼京線" ],
    [ "戸田駅", "埼玉県", "JR埼京線" ],
    [ "北戸田駅", "埼玉県", "JR埼京線" ],
    [ "武蔵浦和駅", "埼玉県", "JR埼京線" ],
    [ "中浦和駅", "埼玉県", "JR埼京線" ],
    [ "南与野駅", "埼玉県", "JR埼京線" ],
    [ "与野本町駅", "埼玉県", "JR埼京線" ],
    [ "北与野駅", "埼玉県", "JR埼京線" ],
    [ "大宮駅", "埼玉県", "JR埼京線" ],
    [ "日進駅", "埼玉県", "JR埼京線" ],
    [ "指扇駅", "埼玉県", "JR埼京線" ],
    [ "南古谷駅", "埼玉県", "JR埼京線" ],
    [ "川越駅", "埼玉県", "JR埼京線" ],
    [ "大宮駅", "埼玉県", "JR川越線" ],
    [ "日進駅", "埼玉県", "JR川越線" ],
    [ "西大宮駅", "埼玉県", "JR川越線" ],
    [ "指扇駅", "埼玉県", "JR川越線" ],
    [ "南古谷駅", "埼玉県", "JR川越線" ],
    [ "川越駅", "埼玉県", "JR川越線" ],
    [ "西川越駅", "埼玉県", "JR川越線" ],
    [ "的場駅", "埼玉県", "JR川越線" ],
    [ "笠幡駅", "埼玉県", "JR川越線" ],
    [ "武蔵高萩駅", "埼玉県", "JR川越線" ],
    [ "高麗川駅", "埼玉県", "JR川越線" ],
    [ "上野駅", "東京都", "JR高崎線" ],
    [ "尾久駅", "東京都", "JR高崎線" ],
    [ "赤羽駅", "東京都", "JR高崎線" ],
    [ "浦和駅", "埼玉県", "JR高崎線" ],
    [ "さいたま新都心駅", "埼玉県", "JR高崎線" ],
    [ "大宮駅", "埼玉県", "JR高崎線" ],
    [ "宮原駅", "埼玉県", "JR高崎線" ],
    [ "上尾駅", "埼玉県", "JR高崎線" ],
    [ "北上尾駅", "埼玉県", "JR高崎線" ],
    [ "桶川駅", "埼玉県", "JR高崎線" ],
    [ "北本駅", "埼玉県", "JR高崎線" ],
    [ "鴻巣駅", "埼玉県", "JR高崎線" ],
    [ "北鴻巣駅", "埼玉県", "JR高崎線" ],
    [ "吹上駅", "埼玉県", "JR高崎線" ],
    [ "行田駅", "埼玉県", "JR高崎線" ],
    [ "熊谷駅", "埼玉県", "JR高崎線" ],
    [ "籠原駅", "埼玉県", "JR高崎線" ],
    [ "深谷駅", "埼玉県", "JR高崎線" ],
    [ "岡部駅", "埼玉県", "JR高崎線" ],
    [ "本庄駅", "埼玉県", "JR高崎線" ],
    [ "神保原駅", "埼玉県", "JR高崎線" ],
    [ "新町駅", "群馬県", "JR高崎線" ],
    [ "倉賀野駅", "群馬県", "JR高崎線" ],
    [ "高崎駅", "群馬県", "JR高崎線" ],
    [ "千葉駅", "千葉県", "JR外房線" ],
    [ "本千葉駅", "千葉県", "JR外房線" ],
    [ "蘇我駅", "千葉県", "JR外房線" ],
    [ "鎌取駅", "千葉県", "JR外房線" ],
    [ "誉田駅", "千葉県", "JR外房線" ],
    [ "土気駅", "千葉県", "JR外房線" ],
    [ "大網駅", "千葉県", "JR外房線" ],
    [ "永田駅", "千葉県", "JR外房線" ],
    [ "本納駅", "千葉県", "JR外房線" ],
    [ "新茂原駅", "千葉県", "JR外房線" ],
    [ "茂原駅", "千葉県", "JR外房線" ],
    [ "八積駅", "千葉県", "JR外房線" ],
    [ "上総一ノ宮駅", "千葉県", "JR外房線" ],
    [ "東浪見駅", "千葉県", "JR外房線" ],
    [ "太東駅", "千葉県", "JR外房線" ],
    [ "長者町駅", "千葉県", "JR外房線" ],
    [ "三門駅", "千葉県", "JR外房線" ],
    [ "大原駅", "千葉県", "JR外房線" ],
    [ "浪花駅", "千葉県", "JR外房線" ],
    [ "御宿駅", "千葉県", "JR外房線" ],
    [ "勝浦駅", "千葉県", "JR外房線" ],
    [ "鵜原駅", "千葉県", "JR外房線" ],
    [ "上総興津駅", "千葉県", "JR外房線" ],
    [ "行川アイランド駅", "千葉県", "JR外房線" ],
    [ "安房小湊駅", "千葉県", "JR外房線" ],
    [ "安房天津駅", "千葉県", "JR外房線" ],
    [ "安房鴨川駅", "千葉県", "JR外房線" ],
    [ "千葉駅", "千葉県", "JR内房線" ],
    [ "本千葉駅", "千葉県", "JR内房線" ],
    [ "蘇我駅", "千葉県", "JR内房線" ],
    [ "浜野駅", "千葉県", "JR内房線" ],
    [ "八幡宿駅", "千葉県", "JR内房線" ],
    [ "五井駅", "千葉県", "JR内房線" ],
    [ "姉ケ崎駅", "千葉県", "JR内房線" ],
    [ "長浦駅", "千葉県", "JR内房線" ],
    [ "袖ケ浦駅", "千葉県", "JR内房線" ],
    [ "巌根駅", "千葉県", "JR内房線" ],
    [ "木更津駅", "千葉県", "JR内房線" ],
    [ "君津駅", "千葉県", "JR内房線" ],
    [ "青堀駅", "千葉県", "JR内房線" ],
    [ "大貫駅", "千葉県", "JR内房線" ],
    [ "佐貫町駅", "千葉県", "JR内房線" ],
    [ "上総湊駅", "千葉県", "JR内房線" ],
    [ "竹岡駅", "千葉県", "JR内房線" ],
    [ "浜金谷駅", "千葉県", "JR内房線" ],
    [ "保田駅", "千葉県", "JR内房線" ],
    [ "安房勝山駅", "千葉県", "JR内房線" ],
    [ "岩井駅", "千葉県", "JR内房線" ],
    [ "富浦駅", "千葉県", "JR内房線" ],
    [ "那古船形駅", "千葉県", "JR内房線" ],
    [ "館山駅", "千葉県", "JR内房線" ],
    [ "九重駅", "千葉県", "JR内房線" ],
    [ "千倉駅", "千葉県", "JR内房線" ],
    [ "千歳駅", "千葉県", "JR内房線" ],
    [ "南三原駅", "千葉県", "JR内房線" ],
    [ "和田浦駅", "千葉県", "JR内房線" ],
    [ "江見駅", "千葉県", "JR内房線" ],
    [ "太海駅", "千葉県", "JR内房線" ],
    [ "安房鴨川駅", "千葉県", "JR内房線" ],
    [ "東京駅", "東京都", "JR京葉線" ],
    [ "八丁堀駅", "東京都", "JR京葉線" ],
    [ "越中島駅", "東京都", "JR京葉線" ],
    [ "潮見駅", "東京都", "JR京葉線" ],
    [ "新木場駅", "東京都", "JR京葉線" ],
    [ "葛西臨海公園駅", "東京都", "JR京葉線" ],
    [ "舞浜駅", "千葉県", "JR京葉線" ],
    [ "新浦安駅", "千葉県", "JR京葉線" ],
    [ "市川塩浜駅", "千葉県", "JR京葉線" ],
    [ "西船橋駅", "千葉県", "JR京葉線" ],
    [ "二俣新町駅", "千葉県", "JR京葉線" ],
    [ "南船橋駅", "千葉県", "JR京葉線" ],
    [ "新習志野駅", "千葉県", "JR京葉線" ],
    [ "幕張豊砂駅", "千葉県", "JR京葉線" ],
    [ "海浜幕張駅", "千葉県", "JR京葉線" ],
    [ "検見川浜駅", "千葉県", "JR京葉線" ],
    [ "稲毛海岸駅", "千葉県", "JR京葉線" ],
    [ "千葉みなと駅", "千葉県", "JR京葉線" ],
    [ "蘇我駅", "千葉県", "JR京葉線" ],
    [ "佐倉駅", "千葉県", "JR成田線" ],
    [ "酒々井駅", "千葉県", "JR成田線" ],
    [ "成田駅", "千葉県", "JR成田線" ],
    [ "空港第２ビル（第２旅客ターミナル）駅", "千葉県", "JR成田線" ],
    [ "成田空港（第１旅客ターミナル）駅", "千葉県", "JR成田線" ],
    [ "久住駅", "千葉県", "JR成田線" ],
    [ "滑河駅", "千葉県", "JR成田線" ],
    [ "下総神崎駅", "千葉県", "JR成田線" ],
    [ "大戸駅", "千葉県", "JR成田線" ],
    [ "佐原駅", "千葉県", "JR成田線" ],
    [ "香取駅", "千葉県", "JR成田線" ],
    [ "水郷駅", "千葉県", "JR成田線" ],
    [ "小見川駅", "千葉県", "JR成田線" ],
    [ "笹川駅", "千葉県", "JR成田線" ],
    [ "下総橘駅", "千葉県", "JR成田線" ],
    [ "下総豊里駅", "千葉県", "JR成田線" ],
    [ "椎柴駅", "千葉県", "JR成田線" ],
    [ "松岸駅", "千葉県", "JR成田線" ],
    [ "銚子駅", "千葉県", "JR成田線" ],
    [ "我孫子駅", "千葉県", "JR成田線" ],
    [ "東我孫子駅", "千葉県", "JR成田線" ],
    [ "湖北駅", "千葉県", "JR成田線" ],
    [ "新木駅", "千葉県", "JR成田線" ],
    [ "布佐駅", "千葉県", "JR成田線" ],
    [ "木下駅", "千葉県", "JR成田線" ],
    [ "小林駅", "千葉県", "JR成田線" ],
    [ "安食駅", "千葉県", "JR成田線" ],
    [ "下総松崎駅", "千葉県", "JR成田線" ],
    [ "大船駅", "神奈川県", "JR成田エクスプレス" ],
    [ "戸塚駅", "神奈川県", "JR成田エクスプレス" ],
    [ "横浜駅", "神奈川県", "JR成田エクスプレス" ],
    [ "武蔵小杉駅", "神奈川県", "JR成田エクスプレス" ],
    [ "大宮駅", "埼玉県", "JR成田エクスプレス" ],
    [ "池袋駅", "東京都", "JR成田エクスプレス" ],
    [ "新宿駅", "東京都", "JR成田エクスプレス" ],
    [ "高尾駅", "東京都", "JR成田エクスプレス" ],
    [ "八王子駅", "東京都", "JR成田エクスプレス" ],
    [ "立川駅", "東京都", "JR成田エクスプレス" ],
    [ "国分寺駅", "東京都", "JR成田エクスプレス" ],
    [ "三鷹駅", "東京都", "JR成田エクスプレス" ],
    [ "吉祥寺駅", "東京都", "JR成田エクスプレス" ],
    [ "渋谷駅", "東京都", "JR成田エクスプレス" ],
    [ "品川駅", "東京都", "JR成田エクスプレス" ],
    [ "東京駅", "東京都", "JR成田エクスプレス" ],
    [ "千葉駅", "千葉県", "JR成田エクスプレス" ],
    [ "四街道駅", "千葉県", "JR成田エクスプレス" ],
    [ "佐倉駅", "千葉県", "JR成田エクスプレス" ],
    [ "成田駅", "千葉県", "JR成田エクスプレス" ],
    [ "空港第２ビル（第２旅客ターミナル）駅", "千葉県", "JR成田エクスプレス" ],
    [ "成田空港（第１旅客ターミナル）駅", "千葉県", "JR成田エクスプレス" ],
    [ "佐原駅", "千葉県", "JR鹿島線" ],
    [ "香取駅", "千葉県", "JR鹿島線" ],
    [ "十二橋駅", "千葉県", "JR鹿島線" ],
    [ "潮来駅", "茨城県", "JR鹿島線" ],
    [ "延方駅", "茨城県", "JR鹿島線" ],
    [ "鹿島神宮駅", "茨城県", "JR鹿島線" ],
    [ "鹿島サッカースタジアム（臨）駅", "茨城県", "JR鹿島線" ],
    [ "木更津駅", "千葉県", "JR久留里線" ],
    [ "祇園駅", "千葉県", "JR久留里線" ],
    [ "上総清川駅", "千葉県", "JR久留里線" ],
    [ "東清川駅", "千葉県", "JR久留里線" ],
    [ "横田駅", "千葉県", "JR久留里線" ],
    [ "東横田駅", "千葉県", "JR久留里線" ],
    [ "馬来田駅", "千葉県", "JR久留里線" ],
    [ "下郡駅", "千葉県", "JR久留里線" ],
    [ "小櫃駅", "千葉県", "JR久留里線" ],
    [ "俵田駅", "千葉県", "JR久留里線" ],
    [ "久留里駅", "千葉県", "JR久留里線" ],
    [ "平山駅", "千葉県", "JR久留里線" ],
    [ "上総松丘駅", "千葉県", "JR久留里線" ],
    [ "上総亀山駅", "千葉県", "JR久留里線" ],
    [ "大網駅", "千葉県", "JR東金線" ],
    [ "福俵駅", "千葉県", "JR東金線" ],
    [ "東金駅", "千葉県", "JR東金線" ],
    [ "求名駅", "千葉県", "JR東金線" ],
    [ "成東駅", "千葉県", "JR東金線" ],
    [ "大宮駅", "埼玉県", "JR京浜東北線" ],
    [ "さいたま新都心駅", "埼玉県", "JR京浜東北線" ],
    [ "与野駅", "埼玉県", "JR京浜東北線" ],
    [ "北浦和駅", "埼玉県", "JR京浜東北線" ],
    [ "浦和駅", "埼玉県", "JR京浜東北線" ],
    [ "南浦和駅", "埼玉県", "JR京浜東北線" ],
    [ "蕨駅", "埼玉県", "JR京浜東北線" ],
    [ "西川口駅", "埼玉県", "JR京浜東北線" ],
    [ "川口駅", "埼玉県", "JR京浜東北線" ],
    [ "赤羽駅", "東京都", "JR京浜東北線" ],
    [ "東十条駅", "東京都", "JR京浜東北線" ],
    [ "王子駅", "東京都", "JR京浜東北線" ],
    [ "上中里駅", "東京都", "JR京浜東北線" ],
    [ "田端駅", "東京都", "JR京浜東北線" ],
    [ "西日暮里駅", "東京都", "JR京浜東北線" ],
    [ "日暮里駅", "東京都", "JR京浜東北線" ],
    [ "鶯谷駅", "東京都", "JR京浜東北線" ],
    [ "上野駅", "東京都", "JR京浜東北線" ],
    [ "御徒町駅", "東京都", "JR京浜東北線" ],
    [ "秋葉原駅", "東京都", "JR京浜東北線" ],
    [ "神田駅", "東京都", "JR京浜東北線" ],
    [ "東京駅", "東京都", "JR京浜東北線" ],
    [ "有楽町駅", "東京都", "JR京浜東北線" ],
    [ "新橋駅", "東京都", "JR京浜東北線" ],
    [ "浜松町駅", "東京都", "JR京浜東北線" ],
    [ "田町駅", "東京都", "JR京浜東北線" ],
    [ "高輪ゲートウェイ駅", "東京都", "JR京浜東北線" ],
    [ "品川駅", "東京都", "JR京浜東北線" ],
    [ "大井町駅", "東京都", "JR京浜東北線" ],
    [ "大森駅", "東京都", "JR京浜東北線" ],
    [ "蒲田駅", "東京都", "JR京浜東北線" ],
    [ "川崎駅", "神奈川県", "JR京浜東北線" ],
    [ "鶴見駅", "神奈川県", "JR京浜東北線" ],
    [ "新子安駅", "神奈川県", "JR京浜東北線" ],
    [ "東神奈川駅", "神奈川県", "JR京浜東北線" ],
    [ "横浜駅", "神奈川県", "JR京浜東北線" ],
    [ "大宮駅", "埼玉県", "JR湘南新宿ライン" ],
    [ "浦和駅", "埼玉県", "JR湘南新宿ライン" ],
    [ "赤羽駅", "東京都", "JR湘南新宿ライン" ],
    [ "池袋駅", "東京都", "JR湘南新宿ライン" ],
    [ "新宿駅", "東京都", "JR湘南新宿ライン" ],
    [ "渋谷駅", "東京都", "JR湘南新宿ライン" ],
    [ "恵比寿駅", "東京都", "JR湘南新宿ライン" ],
    [ "大崎駅", "東京都", "JR湘南新宿ライン" ],
    [ "西大井駅", "東京都", "JR湘南新宿ライン" ],
    [ "武蔵小杉駅", "神奈川県", "JR湘南新宿ライン" ],
    [ "新川崎駅", "神奈川県", "JR湘南新宿ライン" ],
    [ "横浜駅", "神奈川県", "JR湘南新宿ライン" ],
    [ "保土ケ谷駅", "神奈川県", "JR湘南新宿ライン" ],
    [ "東戸塚駅", "神奈川県", "JR湘南新宿ライン" ],
    [ "戸塚駅", "神奈川県", "JR湘南新宿ライン" ],
    [ "大船駅", "神奈川県", "JR湘南新宿ライン" ],
    [ "宝積寺駅", "栃木県", "JR烏山線" ],
    [ "下野花岡駅", "栃木県", "JR烏山線" ],
    [ "仁井田駅", "栃木県", "JR烏山線" ],
    [ "鴻野山駅", "栃木県", "JR烏山線" ],
    [ "大金駅", "栃木県", "JR烏山線" ],
    [ "小塙駅", "栃木県", "JR烏山線" ],
    [ "滝駅", "栃木県", "JR烏山線" ],
    [ "烏山駅", "栃木県", "JR烏山線" ],
    [ "渋川駅", "群馬県", "JR吾妻線" ],
    [ "金島駅", "群馬県", "JR吾妻線" ],
    [ "祖母島駅", "群馬県", "JR吾妻線" ],
    [ "小野上駅", "群馬県", "JR吾妻線" ],
    [ "小野上温泉駅", "群馬県", "JR吾妻線" ],
    [ "市城駅", "群馬県", "JR吾妻線" ],
    [ "中之条駅", "群馬県", "JR吾妻線" ],
    [ "群馬原町駅", "群馬県", "JR吾妻線" ],
    [ "郷原駅", "群馬県", "JR吾妻線" ],
    [ "矢倉駅", "群馬県", "JR吾妻線" ],
    [ "岩島駅", "群馬県", "JR吾妻線" ],
    [ "川原湯温泉駅", "群馬県", "JR吾妻線" ],
    [ "長野原草津口駅", "群馬県", "JR吾妻線" ],
    [ "群馬大津駅", "群馬県", "JR吾妻線" ],
    [ "羽根尾駅", "群馬県", "JR吾妻線" ],
    [ "袋倉駅", "群馬県", "JR吾妻線" ],
    [ "万座・鹿沢口駅", "群馬県", "JR吾妻線" ],
    [ "大前駅", "群馬県", "JR吾妻線" ],
    [ "高崎駅", "群馬県", "JR信越本線" ],
    [ "北高崎駅", "群馬県", "JR信越本線" ],
    [ "群馬八幡駅", "群馬県", "JR信越本線" ],
    [ "安中駅", "群馬県", "JR信越本線" ],
    [ "磯部駅", "群馬県", "JR信越本線" ],
    [ "松井田駅", "群馬県", "JR信越本線" ],
    [ "西松井田駅", "群馬県", "JR信越本線" ],
    [ "横川駅", "群馬県", "JR信越本線" ],
    [ "水戸駅", "茨城県", "JR水郡線" ],
    [ "常陸青柳駅", "茨城県", "JR水郡線" ],
    [ "常陸津田駅", "茨城県", "JR水郡線" ],
    [ "後台駅", "茨城県", "JR水郡線" ],
    [ "下菅谷駅", "茨城県", "JR水郡線" ],
    [ "中菅谷駅", "茨城県", "JR水郡線" ],
    [ "上菅谷駅", "茨城県", "JR水郡線" ],
    [ "南酒出駅", "茨城県", "JR水郡線" ],
    [ "額田駅", "茨城県", "JR水郡線" ],
    [ "河合駅", "茨城県", "JR水郡線" ],
    [ "谷河原駅", "茨城県", "JR水郡線" ],
    [ "常陸太田駅", "茨城県", "JR水郡線" ],
    [ "常陸鴻巣駅", "茨城県", "JR水郡線" ],
    [ "瓜連駅", "茨城県", "JR水郡線" ],
    [ "静駅", "茨城県", "JR水郡線" ],
    [ "常陸大宮駅", "茨城県", "JR水郡線" ],
    [ "玉川村駅", "茨城県", "JR水郡線" ],
    [ "野上原駅", "茨城県", "JR水郡線" ],
    [ "山方宿駅", "茨城県", "JR水郡線" ],
    [ "中舟生駅", "茨城県", "JR水郡線" ],
    [ "下小川駅", "茨城県", "JR水郡線" ],
    [ "西金駅", "茨城県", "JR水郡線" ],
    [ "上小川駅", "茨城県", "JR水郡線" ],
    [ "袋田駅", "茨城県", "JR水郡線" ],
    [ "常陸大子駅", "茨城県", "JR水郡線" ],
    [ "下野宮駅", "茨城県", "JR水郡線" ],
    [ "矢祭山駅", "福島県", "JR水郡線" ],
    [ "東館駅", "福島県", "JR水郡線" ],
    [ "南石井駅", "福島県", "JR水郡線" ],
    [ "磐城石井駅", "福島県", "JR水郡線" ],
    [ "磐城塙駅", "福島県", "JR水郡線" ],
    [ "近津駅", "福島県", "JR水郡線" ],
    [ "中豊駅", "福島県", "JR水郡線" ],
    [ "磐城棚倉駅", "福島県", "JR水郡線" ],
    [ "磐城浅川駅", "福島県", "JR水郡線" ],
    [ "里白石駅", "福島県", "JR水郡線" ],
    [ "磐城石川駅", "福島県", "JR水郡線" ],
    [ "野木沢駅", "福島県", "JR水郡線" ],
    [ "川辺沖駅", "福島県", "JR水郡線" ],
    [ "泉郷駅", "福島県", "JR水郡線" ],
    [ "川東駅", "福島県", "JR水郡線" ],
    [ "小塩江駅", "福島県", "JR水郡線" ],
    [ "谷田川駅", "福島県", "JR水郡線" ],
    [ "磐城守山駅", "福島県", "JR水郡線" ],
    [ "安積永盛駅", "福島県", "JR水郡線" ],
    [ "郡山駅", "福島県", "JR水郡線" ],
    [ "小山駅", "栃木県", "JR水戸線" ],
    [ "小田林駅", "茨城県", "JR水戸線" ],
    [ "結城駅", "茨城県", "JR水戸線" ],
    [ "東結城駅", "茨城県", "JR水戸線" ],
    [ "川島駅", "茨城県", "JR水戸線" ],
    [ "玉戸駅", "茨城県", "JR水戸線" ],
    [ "下館駅", "茨城県", "JR水戸線" ],
    [ "新治駅", "茨城県", "JR水戸線" ],
    [ "大和駅", "茨城県", "JR水戸線" ],
    [ "岩瀬駅", "茨城県", "JR水戸線" ],
    [ "羽黒駅", "茨城県", "JR水戸線" ],
    [ "福原駅", "茨城県", "JR水戸線" ],
    [ "稲田駅", "茨城県", "JR水戸線" ],
    [ "笠間駅", "茨城県", "JR水戸線" ],
    [ "宍戸駅", "茨城県", "JR水戸線" ],
    [ "友部駅", "茨城県", "JR水戸線" ],
    [ "宇都宮駅", "栃木県", "JR日光線" ],
    [ "鶴田駅", "栃木県", "JR日光線" ],
    [ "鹿沼駅", "栃木県", "JR日光線" ],
    [ "文挟駅", "栃木県", "JR日光線" ],
    [ "下野大沢駅", "栃木県", "JR日光線" ],
    [ "今市駅", "栃木県", "JR日光線" ],
    [ "日光駅", "栃木県", "JR日光線" ],
    [ "高崎駅", "群馬県", "JR両毛線" ],
    [ "高崎問屋町駅", "群馬県", "JR両毛線" ],
    [ "井野駅", "群馬県", "JR両毛線" ],
    [ "新前橋駅", "群馬県", "JR両毛線" ],
    [ "前橋駅", "群馬県", "JR両毛線" ],
    [ "前橋大島駅", "群馬県", "JR両毛線" ],
    [ "駒形駅", "群馬県", "JR両毛線" ],
    [ "伊勢崎駅", "群馬県", "JR両毛線" ],
    [ "国定駅", "群馬県", "JR両毛線" ],
    [ "岩宿駅", "群馬県", "JR両毛線" ],
    [ "桐生駅", "群馬県", "JR両毛線" ],
    [ "小俣駅", "栃木県", "JR両毛線" ],
    [ "山前駅", "栃木県", "JR両毛線" ],
    [ "足利駅", "栃木県", "JR両毛線" ],
    [ "あしかがフラワーパーク駅", "栃木県", "JR両毛線" ],
    [ "富田駅", "栃木県", "JR両毛線" ],
    [ "佐野駅", "栃木県", "JR両毛線" ],
    [ "岩舟駅", "栃木県", "JR両毛線" ],
    [ "大平下駅", "栃木県", "JR両毛線" ],
    [ "栃木駅", "栃木県", "JR両毛線" ],
    [ "思川駅", "栃木県", "JR両毛線" ],
    [ "小山駅", "栃木県", "JR両毛線" ],
    [ "高崎駅", "群馬県", "JR上越線" ],
    [ "高崎問屋町駅", "群馬県", "JR上越線" ],
    [ "井野駅", "群馬県", "JR上越線" ],
    [ "新前橋駅", "群馬県", "JR上越線" ],
    [ "群馬総社駅", "群馬県", "JR上越線" ],
    [ "八木原駅", "群馬県", "JR上越線" ],
    [ "渋川駅", "群馬県", "JR上越線" ],
    [ "敷島駅", "群馬県", "JR上越線" ],
    [ "津久田駅", "群馬県", "JR上越線" ],
    [ "岩本駅", "群馬県", "JR上越線" ],
    [ "沼田駅", "群馬県", "JR上越線" ],
    [ "後閑駅", "群馬県", "JR上越線" ],
    [ "上牧駅", "群馬県", "JR上越線" ],
    [ "水上駅", "群馬県", "JR上越線" ],
    [ "湯檜曽駅", "群馬県", "JR上越線" ],
    [ "土合駅", "群馬県", "JR上越線" ],
    [ "土樽駅", "新潟県", "JR上越線" ],
    [ "越後中里駅", "新潟県", "JR上越線" ],
    [ "岩原スキー場前駅", "新潟県", "JR上越線" ],
    [ "越後湯沢駅", "新潟県", "JR上越線" ],
    [ "石打駅", "新潟県", "JR上越線" ],
    [ "大沢駅", "新潟県", "JR上越線" ],
    [ "上越国際スキー場前駅", "新潟県", "JR上越線" ],
    [ "塩沢駅", "新潟県", "JR上越線" ],
    [ "六日町駅", "新潟県", "JR上越線" ],
    [ "五日町駅", "新潟県", "JR上越線" ],
    [ "浦佐駅", "新潟県", "JR上越線" ],
    [ "八色駅", "新潟県", "JR上越線" ],
    [ "小出駅", "新潟県", "JR上越線" ],
    [ "越後堀之内駅", "新潟県", "JR上越線" ],
    [ "北堀之内駅", "新潟県", "JR上越線" ],
    [ "越後川口駅", "新潟県", "JR上越線" ],
    [ "小千谷駅", "新潟県", "JR上越線" ],
    [ "越後滝谷駅", "新潟県", "JR上越線" ],
    [ "宮内駅", "新潟県", "JR上越線" ],
    [ "長岡駅", "新潟県", "JR上越線" ],
    [ "東京駅", "東京都", "上野東京ライン" ],
    [ "上野駅", "東京都", "上野東京ライン" ],
    [ "小淵沢駅", "山梨県", "小海線" ],
    [ "甲斐小泉駅", "山梨県", "小海線" ],
    [ "甲斐大泉駅", "山梨県", "小海線" ],
    [ "清里駅", "山梨県", "小海線" ],
    [ "野辺山駅", "長野県", "小海線" ],
    [ "信濃川上駅", "長野県", "小海線" ],
    [ "佐久広瀬駅", "長野県", "小海線" ],
    [ "佐久海ノ口駅", "長野県", "小海線" ],
    [ "海尻駅", "長野県", "小海線" ],
    [ "松原湖駅", "長野県", "小海線" ],
    [ "小海駅", "長野県", "小海線" ],
    [ "馬流駅", "長野県", "小海線" ],
    [ "高岩駅", "長野県", "小海線" ],
    [ "八千穂駅", "長野県", "小海線" ],
    [ "海瀬駅", "長野県", "小海線" ],
    [ "羽黒下駅", "長野県", "小海線" ],
    [ "青沼駅", "長野県", "小海線" ],
    [ "臼田駅", "長野県", "小海線" ],
    [ "龍岡城駅", "長野県", "小海線" ],
    [ "太田部駅", "長野県", "小海線" ],
    [ "中込駅", "長野県", "小海線" ],
    [ "滑津駅", "長野県", "小海線" ],
    [ "北中込駅", "長野県", "小海線" ],
    [ "岩村田駅", "長野県", "小海線" ],
    [ "佐久平駅", "長野県", "小海線" ],
    [ "中佐都駅", "長野県", "小海線" ],
    [ "美里駅", "長野県", "小海線" ],
    [ "三岡駅", "長野県", "小海線" ],
    [ "乙女駅", "長野県", "小海線" ],
    [ "東小諸駅", "長野県", "小海線" ],
    [ "小諸駅", "長野県", "小海線" ],
    [ "富士駅", "静岡県", "JR身延線" ],
    [ "柚木駅", "静岡県", "JR身延線" ],
    [ "竪堀駅", "静岡県", "JR身延線" ],
    [ "入山瀬駅", "静岡県", "JR身延線" ],
    [ "富士根駅", "静岡県", "JR身延線" ],
    [ "源道寺駅", "静岡県", "JR身延線" ],
    [ "富士宮駅", "静岡県", "JR身延線" ],
    [ "西富士宮駅", "静岡県", "JR身延線" ],
    [ "沼久保駅", "静岡県", "JR身延線" ],
    [ "芝川駅", "静岡県", "JR身延線" ],
    [ "稲子駅", "静岡県", "JR身延線" ],
    [ "十島駅", "山梨県", "JR身延線" ],
    [ "井出駅", "山梨県", "JR身延線" ],
    [ "寄畑駅", "山梨県", "JR身延線" ],
    [ "内船駅", "山梨県", "JR身延線" ],
    [ "甲斐大島駅", "山梨県", "JR身延線" ],
    [ "身延駅", "山梨県", "JR身延線" ],
    [ "塩之沢駅", "山梨県", "JR身延線" ],
    [ "波高島駅", "山梨県", "JR身延線" ],
    [ "下部温泉駅", "山梨県", "JR身延線" ],
    [ "甲斐常葉駅", "山梨県", "JR身延線" ],
    [ "市ノ瀬駅", "山梨県", "JR身延線" ],
    [ "久那土駅", "山梨県", "JR身延線" ],
    [ "甲斐岩間駅", "山梨県", "JR身延線" ],
    [ "落居駅", "山梨県", "JR身延線" ],
    [ "鰍沢口駅", "山梨県", "JR身延線" ],
    [ "市川大門駅", "山梨県", "JR身延線" ],
    [ "市川本町駅", "山梨県", "JR身延線" ],
    [ "芦川駅", "山梨県", "JR身延線" ],
    [ "甲斐上野駅", "山梨県", "JR身延線" ],
    [ "東花輪駅", "山梨県", "JR身延線" ],
    [ "小井川駅", "山梨県", "JR身延線" ],
    [ "常永駅", "山梨県", "JR身延線" ],
    [ "国母駅", "山梨県", "JR身延線" ],
    [ "甲斐住吉駅", "山梨県", "JR身延線" ],
    [ "南甲府駅", "山梨県", "JR身延線" ],
    [ "善光寺駅", "山梨県", "JR身延線" ],
    [ "金手駅", "山梨県", "JR身延線" ],
    [ "甲府駅", "山梨県", "JR身延線" ],
    [ "篠ノ井駅", "長野県", "JR信越本線" ],
    [ "今井駅", "長野県", "JR信越本線" ],
    [ "川中島駅", "長野県", "JR信越本線" ],
    [ "安茂里駅", "長野県", "JR信越本線" ],
    [ "長野駅", "長野県", "JR信越本線" ],
    [ "直江津駅", "新潟県", "JR信越本線" ],
    [ "黒井駅", "新潟県", "JR信越本線" ],
    [ "犀潟駅", "新潟県", "JR信越本線" ],
    [ "土底浜駅", "新潟県", "JR信越本線" ],
    [ "潟町駅", "新潟県", "JR信越本線" ],
    [ "上下浜駅", "新潟県", "JR信越本線" ],
    [ "柿崎駅", "新潟県", "JR信越本線" ],
    [ "米山駅", "新潟県", "JR信越本線" ],
    [ "笠島駅", "新潟県", "JR信越本線" ],
    [ "青海川駅", "新潟県", "JR信越本線" ],
    [ "鯨波駅", "新潟県", "JR信越本線" ],
    [ "柏崎駅", "新潟県", "JR信越本線" ],
    [ "茨目駅", "新潟県", "JR信越本線" ],
    [ "安田駅", "新潟県", "JR信越本線" ],
    [ "北条駅", "新潟県", "JR信越本線" ],
    [ "越後広田駅", "新潟県", "JR信越本線" ],
    [ "長鳥駅", "新潟県", "JR信越本線" ],
    [ "塚山駅", "新潟県", "JR信越本線" ],
    [ "越後岩塚駅", "新潟県", "JR信越本線" ],
    [ "来迎寺駅", "新潟県", "JR信越本線" ],
    [ "前川駅", "新潟県", "JR信越本線" ],
    [ "宮内駅", "新潟県", "JR信越本線" ],
    [ "長岡駅", "新潟県", "JR信越本線" ],
    [ "北長岡駅", "新潟県", "JR信越本線" ],
    [ "押切駅", "新潟県", "JR信越本線" ],
    [ "見附駅", "新潟県", "JR信越本線" ],
    [ "帯織駅", "新潟県", "JR信越本線" ],
    [ "東光寺駅", "新潟県", "JR信越本線" ],
    [ "三条駅", "新潟県", "JR信越本線" ],
    [ "東三条駅", "新潟県", "JR信越本線" ],
    [ "保内駅", "新潟県", "JR信越本線" ],
    [ "加茂駅", "新潟県", "JR信越本線" ],
    [ "羽生田駅", "新潟県", "JR信越本線" ],
    [ "田上駅", "新潟県", "JR信越本線" ],
    [ "矢代田駅", "新潟県", "JR信越本線" ],
    [ "古津駅", "新潟県", "JR信越本線" ],
    [ "新津駅", "新潟県", "JR信越本線" ],
    [ "さつき野駅", "新潟県", "JR信越本線" ],
    [ "荻川駅", "新潟県", "JR信越本線" ],
    [ "亀田駅", "新潟県", "JR信越本線" ],
    [ "越後石山駅", "新潟県", "JR信越本線" ],
    [ "新潟駅", "新潟県", "JR信越本線" ],
    [ "新潟駅", "新潟県", "JR白新線" ],
    [ "東新潟駅", "新潟県", "JR白新線" ],
    [ "大形駅", "新潟県", "JR白新線" ],
    [ "新崎駅", "新潟県", "JR白新線" ],
    [ "早通駅", "新潟県", "JR白新線" ],
    [ "豊栄駅", "新潟県", "JR白新線" ],
    [ "黒山駅", "新潟県", "JR白新線" ],
    [ "佐々木駅", "新潟県", "JR白新線" ],
    [ "西新発田駅", "新潟県", "JR白新線" ],
    [ "新発田駅", "新潟県", "JR白新線" ],
    [ "長野駅", "長野県", "JR飯山線" ],
    [ "北長野駅", "長野県", "JR飯山線" ],
    [ "三才駅", "長野県", "JR飯山線" ],
    [ "豊野駅", "長野県", "JR飯山線" ],
    [ "信濃浅野駅", "長野県", "JR飯山線" ],
    [ "立ケ花駅", "長野県", "JR飯山線" ],
    [ "上今井駅", "長野県", "JR飯山線" ],
    [ "替佐駅", "長野県", "JR飯山線" ],
    [ "蓮駅", "長野県", "JR飯山線" ],
    [ "飯山駅", "長野県", "JR飯山線" ],
    [ "北飯山駅", "長野県", "JR飯山線" ],
    [ "信濃平駅", "長野県", "JR飯山線" ],
    [ "戸狩野沢温泉駅", "長野県", "JR飯山線" ],
    [ "上境駅", "長野県", "JR飯山線" ],
    [ "上桑名川駅", "長野県", "JR飯山線" ],
    [ "桑名川駅", "長野県", "JR飯山線" ],
    [ "西大滝駅", "長野県", "JR飯山線" ],
    [ "信濃白鳥駅", "長野県", "JR飯山線" ],
    [ "平滝駅", "長野県", "JR飯山線" ],
    [ "横倉駅", "長野県", "JR飯山線" ],
    [ "森宮野原駅", "長野県", "JR飯山線" ],
    [ "足滝駅", "新潟県", "JR飯山線" ],
    [ "越後田中駅", "新潟県", "JR飯山線" ],
    [ "津南駅", "新潟県", "JR飯山線" ],
    [ "越後鹿渡駅", "新潟県", "JR飯山線" ],
    [ "越後田沢駅", "新潟県", "JR飯山線" ],
    [ "越後水沢駅", "新潟県", "JR飯山線" ],
    [ "土市駅", "新潟県", "JR飯山線" ],
    [ "十日町駅", "新潟県", "JR飯山線" ],
    [ "魚沼中条駅", "新潟県", "JR飯山線" ],
    [ "下条駅", "新潟県", "JR飯山線" ],
    [ "越後岩沢駅", "新潟県", "JR飯山線" ],
    [ "内ケ巻駅", "新潟県", "JR飯山線" ],
    [ "越後川口駅", "新潟県", "JR飯山線" ],
    [ "柏崎駅", "新潟県", "JR越後線" ],
    [ "東柏崎駅", "新潟県", "JR越後線" ],
    [ "西中通駅", "新潟県", "JR越後線" ],
    [ "荒浜駅", "新潟県", "JR越後線" ],
    [ "刈羽駅", "新潟県", "JR越後線" ],
    [ "西山駅", "新潟県", "JR越後線" ],
    [ "礼拝駅", "新潟県", "JR越後線" ],
    [ "石地駅", "新潟県", "JR越後線" ],
    [ "小木ノ城駅", "新潟県", "JR越後線" ],
    [ "出雲崎駅", "新潟県", "JR越後線" ],
    [ "妙法寺駅", "新潟県", "JR越後線" ],
    [ "小島谷駅", "新潟県", "JR越後線" ],
    [ "桐原駅", "新潟県", "JR越後線" ],
    [ "寺泊駅", "新潟県", "JR越後線" ],
    [ "分水駅", "新潟県", "JR越後線" ],
    [ "粟生津駅", "新潟県", "JR越後線" ],
    [ "南吉田駅", "新潟県", "JR越後線" ],
    [ "吉田駅", "新潟県", "JR越後線" ],
    [ "北吉田駅", "新潟県", "JR越後線" ],
    [ "岩室駅", "新潟県", "JR越後線" ],
    [ "巻駅", "新潟県", "JR越後線" ],
    [ "越後曽根駅", "新潟県", "JR越後線" ],
    [ "越後赤塚駅", "新潟県", "JR越後線" ],
    [ "内野西が丘駅", "新潟県", "JR越後線" ],
    [ "内野駅", "新潟県", "JR越後線" ],
    [ "新潟大学前駅", "新潟県", "JR越後線" ],
    [ "寺尾駅", "新潟県", "JR越後線" ],
    [ "小針駅", "新潟県", "JR越後線" ],
    [ "青山駅", "新潟県", "JR越後線" ],
    [ "関屋駅", "新潟県", "JR越後線" ],
    [ "白山駅", "新潟県", "JR越後線" ],
    [ "新潟駅", "新潟県", "JR越後線" ],
    [ "松本駅", "長野県", "北アルプス線" ],
    [ "北松本駅", "長野県", "北アルプス線" ],
    [ "島内駅", "長野県", "北アルプス線" ],
    [ "島高松駅", "長野県", "北アルプス線" ],
    [ "梓橋駅", "長野県", "北アルプス線" ],
    [ "一日市場駅", "長野県", "北アルプス線" ],
    [ "中萱駅", "長野県", "北アルプス線" ],
    [ "南豊科駅", "長野県", "北アルプス線" ],
    [ "豊科駅", "長野県", "北アルプス線" ],
    [ "柏矢町駅", "長野県", "北アルプス線" ],
    [ "穂高駅", "長野県", "北アルプス線" ],
    [ "有明駅", "長野県", "北アルプス線" ],
    [ "安曇追分駅", "長野県", "北アルプス線" ],
    [ "細野駅", "長野県", "北アルプス線" ],
    [ "北細野駅", "長野県", "北アルプス線" ],
    [ "信濃松川駅", "長野県", "北アルプス線" ],
    [ "安曇沓掛駅", "長野県", "北アルプス線" ],
    [ "信濃常盤駅", "長野県", "北アルプス線" ],
    [ "南大町駅", "長野県", "北アルプス線" ],
    [ "信濃大町駅", "長野県", "北アルプス線" ],
    [ "北大町駅", "長野県", "北アルプス線" ],
    [ "信濃木崎駅", "長野県", "北アルプス線" ],
    [ "稲尾駅", "長野県", "北アルプス線" ],
    [ "海ノ口駅", "長野県", "北アルプス線" ],
    [ "簗場駅", "長野県", "北アルプス線" ],
    [ "ヤナバスキー場前駅", "長野県", "北アルプス線" ],
    [ "南神城駅", "長野県", "北アルプス線" ],
    [ "神城駅", "長野県", "北アルプス線" ],
    [ "飯森駅", "長野県", "北アルプス線" ],
    [ "白馬駅", "長野県", "北アルプス線" ],
    [ "信濃森上駅", "長野県", "北アルプス線" ],
    [ "白馬大池駅", "長野県", "北アルプス線" ],
    [ "千国駅", "長野県", "北アルプス線" ],
    [ "南小谷駅", "長野県", "北アルプス線" ],
    [ "中土駅", "長野県", "北アルプス線" ],
    [ "北小谷駅", "長野県", "北アルプス線" ],
    [ "平岩駅", "新潟県", "北アルプス線" ],
    [ "小滝駅", "新潟県", "北アルプス線" ],
    [ "根知駅", "新潟県", "北アルプス線" ],
    [ "頸城大野駅", "新潟県", "北アルプス線" ],
    [ "姫川駅", "新潟県", "北アルプス線" ],
    [ "糸魚川駅", "新潟県", "北アルプス線" ],
    [ "弥彦駅", "新潟県", "JR弥彦線" ],
    [ "矢作駅", "新潟県", "JR弥彦線" ],
    [ "吉田駅", "新潟県", "JR弥彦線" ],
    [ "西燕駅", "新潟県", "JR弥彦線" ],
    [ "燕駅", "新潟県", "JR弥彦線" ],
    [ "燕三条駅", "新潟県", "JR弥彦線" ],
    [ "北三条駅", "新潟県", "JR弥彦線" ],
    [ "東三条駅", "新潟県", "JR弥彦線" ],
    [ "名古屋駅", "愛知県", "JR中央本線" ],
    [ "金山駅", "愛知県", "JR中央本線" ],
    [ "鶴舞駅", "愛知県", "JR中央本線" ],
    [ "千種駅", "愛知県", "JR中央本線" ],
    [ "大曽根駅", "愛知県", "JR中央本線" ],
    [ "新守山駅", "愛知県", "JR中央本線" ],
    [ "勝川駅", "愛知県", "JR中央本線" ],
    [ "春日井駅", "愛知県", "JR中央本線" ],
    [ "神領駅", "愛知県", "JR中央本線" ],
    [ "高蔵寺駅", "愛知県", "JR中央本線" ],
    [ "定光寺駅", "愛知県", "JR中央本線" ],
    [ "古虎渓駅", "岐阜県", "JR中央本線" ],
    [ "多治見駅", "岐阜県", "JR中央本線" ],
    [ "土岐市駅", "岐阜県", "JR中央本線" ],
    [ "瑞浪駅", "岐阜県", "JR中央本線" ],
    [ "釜戸駅", "岐阜県", "JR中央本線" ],
    [ "武並駅", "岐阜県", "JR中央本線" ],
    [ "恵那駅", "岐阜県", "JR中央本線" ],
    [ "美乃坂本駅", "岐阜県", "JR中央本線" ],
    [ "中津川駅", "岐阜県", "JR中央本線" ],
    [ "落合川駅", "岐阜県", "JR中央本線" ],
    [ "坂下駅", "岐阜県", "JR中央本線" ],
    [ "田立駅", "長野県", "JR中央本線" ],
    [ "南木曽駅", "長野県", "JR中央本線" ],
    [ "十二兼駅", "長野県", "JR中央本線" ],
    [ "野尻駅", "長野県", "JR中央本線" ],
    [ "大桑駅", "長野県", "JR中央本線" ],
    [ "須原駅", "長野県", "JR中央本線" ],
    [ "倉本駅", "長野県", "JR中央本線" ],
    [ "上松駅", "長野県", "JR中央本線" ],
    [ "木曽福島駅", "長野県", "JR中央本線" ],
    [ "原野駅", "長野県", "JR中央本線" ],
    [ "宮ノ越駅", "長野県", "JR中央本線" ],
    [ "藪原駅", "長野県", "JR中央本線" ],
    [ "奈良井駅", "長野県", "JR中央本線" ],
    [ "木曽平沢駅", "長野県", "JR中央本線" ],
    [ "贄川駅", "長野県", "JR中央本線" ],
    [ "日出塩駅", "長野県", "JR中央本線" ],
    [ "洗馬駅", "長野県", "JR中央本線" ],
    [ "塩尻駅", "長野県", "JR中央本線" ],
    [ "塩尻駅", "長野県", "JR篠ノ井線" ],
    [ "広丘駅", "長野県", "JR篠ノ井線" ],
    [ "村井駅", "長野県", "JR篠ノ井線" ],
    [ "平田駅", "長野県", "JR篠ノ井線" ],
    [ "南松本駅", "長野県", "JR篠ノ井線" ],
    [ "松本駅", "長野県", "JR篠ノ井線" ],
    [ "田沢駅", "長野県", "JR篠ノ井線" ],
    [ "明科駅", "長野県", "JR篠ノ井線" ],
    [ "西条駅", "長野県", "JR篠ノ井線" ],
    [ "坂北駅", "長野県", "JR篠ノ井線" ],
    [ "聖高原駅", "長野県", "JR篠ノ井線" ],
    [ "冠着駅", "長野県", "JR篠ノ井線" ],
    [ "姨捨駅", "長野県", "JR篠ノ井線" ],
    [ "稲荷山駅", "長野県", "JR篠ノ井線" ],
    [ "篠ノ井駅", "長野県", "JR篠ノ井線" ],
    [ "豊橋駅", "愛知県", "JR飯田線" ],
    [ "船町駅", "愛知県", "JR飯田線" ],
    [ "下地駅", "愛知県", "JR飯田線" ],
    [ "小坂井駅", "愛知県", "JR飯田線" ],
    [ "牛久保駅", "愛知県", "JR飯田線" ],
    [ "豊川駅", "愛知県", "JR飯田線" ],
    [ "三河一宮駅", "愛知県", "JR飯田線" ],
    [ "長山駅", "愛知県", "JR飯田線" ],
    [ "江島駅", "愛知県", "JR飯田線" ],
    [ "東上駅", "愛知県", "JR飯田線" ],
    [ "野田城駅", "愛知県", "JR飯田線" ],
    [ "新城駅", "愛知県", "JR飯田線" ],
    [ "東新町駅", "愛知県", "JR飯田線" ],
    [ "茶臼山駅", "愛知県", "JR飯田線" ],
    [ "三河東郷駅", "愛知県", "JR飯田線" ],
    [ "大海駅", "愛知県", "JR飯田線" ],
    [ "鳥居駅", "愛知県", "JR飯田線" ],
    [ "長篠城駅", "愛知県", "JR飯田線" ],
    [ "本長篠駅", "愛知県", "JR飯田線" ],
    [ "三河大野駅", "愛知県", "JR飯田線" ],
    [ "湯谷温泉駅", "愛知県", "JR飯田線" ],
    [ "三河槙原駅", "愛知県", "JR飯田線" ],
    [ "柿平駅", "愛知県", "JR飯田線" ],
    [ "三河川合駅", "愛知県", "JR飯田線" ],
    [ "池場駅", "愛知県", "JR飯田線" ],
    [ "東栄駅", "愛知県", "JR飯田線" ],
    [ "出馬駅", "静岡県", "JR飯田線" ],
    [ "上市場駅", "静岡県", "JR飯田線" ],
    [ "浦川駅", "静岡県", "JR飯田線" ],
    [ "早瀬駅", "静岡県", "JR飯田線" ],
    [ "下川合駅", "静岡県", "JR飯田線" ],
    [ "中部天竜駅", "静岡県", "JR飯田線" ],
    [ "佐久間駅", "静岡県", "JR飯田線" ],
    [ "相月駅", "静岡県", "JR飯田線" ],
    [ "城西駅", "静岡県", "JR飯田線" ],
    [ "向市場駅", "静岡県", "JR飯田線" ],
    [ "水窪駅", "静岡県", "JR飯田線" ],
    [ "大嵐駅", "静岡県", "JR飯田線" ],
    [ "小和田駅", "静岡県", "JR飯田線" ],
    [ "中井侍駅", "長野県", "JR飯田線" ],
    [ "伊那小沢駅", "長野県", "JR飯田線" ],
    [ "鶯巣駅", "長野県", "JR飯田線" ],
    [ "平岡駅", "長野県", "JR飯田線" ],
    [ "為栗駅", "長野県", "JR飯田線" ],
    [ "温田駅", "長野県", "JR飯田線" ],
    [ "田本駅", "長野県", "JR飯田線" ],
    [ "門島駅", "長野県", "JR飯田線" ],
    [ "唐笠駅", "長野県", "JR飯田線" ],
    [ "金野駅", "長野県", "JR飯田線" ],
    [ "千代駅", "長野県", "JR飯田線" ],
    [ "天竜峡駅", "長野県", "JR飯田線" ],
    [ "天竜峡駅", "長野県", "JR飯田線" ],
    [ "川路駅", "長野県", "JR飯田線" ],
    [ "時又駅", "長野県", "JR飯田線" ],
    [ "駄科駅", "長野県", "JR飯田線" ],
    [ "毛賀駅", "長野県", "JR飯田線" ],
    [ "伊那八幡駅", "長野県", "JR飯田線" ],
    [ "下山村駅", "長野県", "JR飯田線" ],
    [ "鼎駅", "長野県", "JR飯田線" ],
    [ "切石駅", "長野県", "JR飯田線" ],
    [ "飯田駅", "長野県", "JR飯田線" ],
    [ "桜町駅", "長野県", "JR飯田線" ],
    [ "伊那上郷駅", "長野県", "JR飯田線" ],
    [ "元善光寺駅", "長野県", "JR飯田線" ],
    [ "下市田駅", "長野県", "JR飯田線" ],
    [ "市田駅", "長野県", "JR飯田線" ],
    [ "下平駅", "長野県", "JR飯田線" ],
    [ "山吹駅", "長野県", "JR飯田線" ],
    [ "伊那大島駅", "長野県", "JR飯田線" ],
    [ "上片桐駅", "長野県", "JR飯田線" ],
    [ "伊那田島駅", "長野県", "JR飯田線" ],
    [ "高遠原駅", "長野県", "JR飯田線" ],
    [ "七久保駅", "長野県", "JR飯田線" ],
    [ "伊那本郷駅", "長野県", "JR飯田線" ],
    [ "飯島駅", "長野県", "JR飯田線" ],
    [ "田切駅", "長野県", "JR飯田線" ],
    [ "伊那福岡駅", "長野県", "JR飯田線" ],
    [ "小町屋駅", "長野県", "JR飯田線" ],
    [ "駒ケ根駅", "長野県", "JR飯田線" ],
    [ "大田切駅", "長野県", "JR飯田線" ],
    [ "宮田駅", "長野県", "JR飯田線" ],
    [ "赤木駅", "長野県", "JR飯田線" ],
    [ "沢渡駅", "長野県", "JR飯田線" ],
    [ "下島駅", "長野県", "JR飯田線" ],
    [ "伊那市駅", "長野県", "JR飯田線" ],
    [ "伊那北駅", "長野県", "JR飯田線" ],
    [ "田畑駅", "長野県", "JR飯田線" ],
    [ "北殿駅", "長野県", "JR飯田線" ],
    [ "木ノ下駅", "長野県", "JR飯田線" ],
    [ "伊那松島駅", "長野県", "JR飯田線" ],
    [ "沢駅", "長野県", "JR飯田線" ],
    [ "羽場駅", "長野県", "JR飯田線" ],
    [ "伊那新町駅", "長野県", "JR飯田線" ],
    [ "宮木駅", "長野県", "JR飯田線" ],
    [ "辰野駅", "長野県", "JR飯田線" ],
    [ "米原駅", "滋賀県", "JR北陸本線" ],
    [ "坂田駅", "滋賀県", "JR北陸本線" ],
    [ "田村駅", "滋賀県", "JR北陸本線" ],
    [ "長浜駅", "滋賀県", "JR北陸本線" ],
    [ "虎姫駅", "滋賀県", "JR北陸本線" ],
    [ "河毛駅", "滋賀県", "JR北陸本線" ],
    [ "高月駅", "滋賀県", "JR北陸本線" ],
    [ "木ノ本駅", "滋賀県", "JR北陸本線" ],
    [ "余呉駅", "滋賀県", "JR北陸本線" ],
    [ "近江塩津駅", "滋賀県", "JR北陸本線" ],
    [ "新疋田駅", "福井県", "JR北陸本線" ],
    [ "敦賀駅", "福井県", "JR北陸本線" ],
    [ "南今庄駅", "福井県", "JR北陸本線" ],
    [ "今庄駅", "福井県", "JR北陸本線" ],
    [ "湯尾駅", "福井県", "JR北陸本線" ],
    [ "南条駅", "福井県", "JR北陸本線" ],
    [ "王子保駅", "福井県", "JR北陸本線" ],
    [ "武生駅", "福井県", "JR北陸本線" ],
    [ "鯖江駅", "福井県", "JR北陸本線" ],
    [ "北鯖江駅", "福井県", "JR北陸本線" ],
    [ "大土呂駅", "福井県", "JR北陸本線" ],
    [ "越前花堂駅", "福井県", "JR北陸本線" ],
    [ "福井駅", "福井県", "JR北陸本線" ],
    [ "森田駅", "福井県", "JR北陸本線" ],
    [ "春江駅", "福井県", "JR北陸本線" ],
    [ "丸岡駅", "福井県", "JR北陸本線" ],
    [ "芦原温泉駅", "福井県", "JR北陸本線" ],
    [ "細呂木駅", "福井県", "JR北陸本線" ],
    [ "牛ノ谷駅", "福井県", "JR北陸本線" ],
    [ "大聖寺駅", "石川県", "JR北陸本線" ],
    [ "加賀温泉駅", "石川県", "JR北陸本線" ],
    [ "動橋駅", "石川県", "JR北陸本線" ],
    [ "粟津駅", "石川県", "JR北陸本線" ],
    [ "小松駅", "石川県", "JR北陸本線" ],
    [ "明峰駅", "石川県", "JR北陸本線" ],
    [ "能美根上駅", "石川県", "JR北陸本線" ],
    [ "小舞子駅", "石川県", "JR北陸本線" ],
    [ "美川駅", "石川県", "JR北陸本線" ],
    [ "加賀笠間駅", "石川県", "JR北陸本線" ],
    [ "松任駅", "石川県", "JR北陸本線" ],
    [ "野々市駅", "石川県", "JR北陸本線" ],
    [ "西金沢駅", "石川県", "JR北陸本線" ],
    [ "金沢駅", "石川県", "JR北陸本線" ],
    [ "岐阜駅", "岐阜県", "JR高山本線" ],
    [ "長森駅", "岐阜県", "JR高山本線" ],
    [ "那加駅", "岐阜県", "JR高山本線" ],
    [ "蘇原駅", "岐阜県", "JR高山本線" ],
    [ "各務ケ原駅", "岐阜県", "JR高山本線" ],
    [ "鵜沼駅", "岐阜県", "JR高山本線" ],
    [ "坂祝駅", "岐阜県", "JR高山本線" ],
    [ "美濃太田駅", "岐阜県", "JR高山本線" ],
    [ "古井駅", "岐阜県", "JR高山本線" ],
    [ "中川辺駅", "岐阜県", "JR高山本線" ],
    [ "下麻生駅", "岐阜県", "JR高山本線" ],
    [ "上麻生駅", "岐阜県", "JR高山本線" ],
    [ "白川口駅", "岐阜県", "JR高山本線" ],
    [ "下油井駅", "岐阜県", "JR高山本線" ],
    [ "飛騨金山駅", "岐阜県", "JR高山本線" ],
    [ "焼石駅", "岐阜県", "JR高山本線" ],
    [ "下呂駅", "岐阜県", "JR高山本線" ],
    [ "禅昌寺駅", "岐阜県", "JR高山本線" ],
    [ "飛騨萩原駅", "岐阜県", "JR高山本線" ],
    [ "上呂駅", "岐阜県", "JR高山本線" ],
    [ "飛騨宮田駅", "岐阜県", "JR高山本線" ],
    [ "飛騨小坂駅", "岐阜県", "JR高山本線" ],
    [ "渚駅", "岐阜県", "JR高山本線" ],
    [ "久々野駅", "岐阜県", "JR高山本線" ],
    [ "飛騨一ノ宮駅", "岐阜県", "JR高山本線" ],
    [ "高山駅", "岐阜県", "JR高山本線" ],
    [ "上枝駅", "岐阜県", "JR高山本線" ],
    [ "飛騨国府駅", "岐阜県", "JR高山本線" ],
    [ "飛騨古川駅", "岐阜県", "JR高山本線" ],
    [ "杉崎駅", "岐阜県", "JR高山本線" ],
    [ "飛騨細江駅", "岐阜県", "JR高山本線" ],
    [ "角川駅", "岐阜県", "JR高山本線" ],
    [ "坂上駅", "岐阜県", "JR高山本線" ],
    [ "打保駅", "岐阜県", "JR高山本線" ],
    [ "杉原駅", "岐阜県", "JR高山本線" ],
    [ "猪谷駅", "富山県", "JR高山本線" ],
    [ "楡原駅", "富山県", "JR高山本線" ],
    [ "笹津駅", "富山県", "JR高山本線" ],
    [ "東八尾駅", "富山県", "JR高山本線" ],
    [ "越中八尾駅", "富山県", "JR高山本線" ],
    [ "千里駅", "富山県", "JR高山本線" ],
    [ "速星駅", "富山県", "JR高山本線" ],
    [ "婦中鵜坂駅", "富山県", "JR高山本線" ],
    [ "西富山駅", "富山県", "JR高山本線" ],
    [ "富山駅", "富山県", "JR高山本線" ],
    [ "高岡駅", "富山県", "JR城端線" ],
    [ "新高岡駅", "富山県", "JR城端線" ],
    [ "二塚駅", "富山県", "JR城端線" ],
    [ "林駅", "富山県", "JR城端線" ],
    [ "戸出駅", "富山県", "JR城端線" ],
    [ "油田駅", "富山県", "JR城端線" ],
    [ "砺波駅", "富山県", "JR城端線" ],
    [ "東野尻駅", "富山県", "JR城端線" ],
    [ "高儀駅", "富山県", "JR城端線" ],
    [ "福野駅", "富山県", "JR城端線" ],
    [ "東石黒駅", "富山県", "JR城端線" ],
    [ "福光駅", "富山県", "JR城端線" ],
    [ "越中山田駅", "富山県", "JR城端線" ],
    [ "城端駅", "富山県", "JR城端線" ],
    [ "高岡駅", "富山県", "JR氷見線" ],
    [ "越中中川駅", "富山県", "JR氷見線" ],
    [ "能町駅", "富山県", "JR氷見線" ],
    [ "伏木駅", "富山県", "JR氷見線" ],
    [ "越中国分駅", "富山県", "JR氷見線" ],
    [ "雨晴駅", "富山県", "JR氷見線" ],
    [ "島尾駅", "富山県", "JR氷見線" ],
    [ "氷見駅", "富山県", "JR氷見線" ],
    [ "津幡駅", "石川県", "JR七尾線" ],
    [ "中津幡駅", "石川県", "JR七尾線" ],
    [ "本津幡駅", "石川県", "JR七尾線" ],
    [ "能瀬駅", "石川県", "JR七尾線" ],
    [ "宇野気駅", "石川県", "JR七尾線" ],
    [ "横山駅", "石川県", "JR七尾線" ],
    [ "高松駅", "石川県", "JR七尾線" ],
    [ "免田駅", "石川県", "JR七尾線" ],
    [ "宝達駅", "石川県", "JR七尾線" ],
    [ "敷浪駅", "石川県", "JR七尾線" ],
    [ "南羽咋駅", "石川県", "JR七尾線" ],
    [ "羽咋駅", "石川県", "JR七尾線" ],
    [ "千路駅", "石川県", "JR七尾線" ],
    [ "金丸駅", "石川県", "JR七尾線" ],
    [ "能登部駅", "石川県", "JR七尾線" ],
    [ "良川駅", "石川県", "JR七尾線" ],
    [ "能登二宮駅", "石川県", "JR七尾線" ],
    [ "徳田駅", "石川県", "JR七尾線" ],
    [ "七尾駅", "石川県", "JR七尾線" ],
    [ "和倉温泉駅", "石川県", "JR七尾線" ],
    [ "敦賀駅", "福井県", "敦賀港線" ],
    [ "敦賀港駅", "福井県", "敦賀港線" ],
    [ "福井駅", "福井県", "九頭竜線" ],
    [ "越前花堂駅", "福井県", "九頭竜線" ],
    [ "六条駅", "福井県", "九頭竜線" ],
    [ "足羽駅", "福井県", "九頭竜線" ],
    [ "越前東郷駅", "福井県", "九頭竜線" ],
    [ "一乗谷駅", "福井県", "九頭竜線" ],
    [ "越前高田駅", "福井県", "九頭竜線" ],
    [ "市波駅", "福井県", "九頭竜線" ],
    [ "小和清水駅", "福井県", "九頭竜線" ],
    [ "美山駅", "福井県", "九頭竜線" ],
    [ "越前薬師駅", "福井県", "九頭竜線" ],
    [ "越前大宮駅", "福井県", "九頭竜線" ],
    [ "計石駅", "福井県", "九頭竜線" ],
    [ "牛ケ原駅", "福井県", "九頭竜線" ],
    [ "北大野駅", "福井県", "九頭竜線" ],
    [ "越前大野駅", "福井県", "九頭竜線" ],
    [ "越前田野駅", "福井県", "九頭竜線" ],
    [ "越前富田駅", "福井県", "九頭竜線" ],
    [ "下唯野駅", "福井県", "九頭竜線" ],
    [ "柿ケ島駅", "福井県", "九頭竜線" ],
    [ "勝原駅", "福井県", "九頭竜線" ],
    [ "越前下山駅", "福井県", "九頭竜線" ],
    [ "九頭竜湖駅", "福井県", "九頭竜線" ],
    [ "東舞鶴駅", "京都府", "JR小浜線" ],
    [ "松尾寺駅", "京都府", "JR小浜線" ],
    [ "青郷駅", "福井県", "JR小浜線" ],
    [ "三松駅", "福井県", "JR小浜線" ],
    [ "若狭高浜駅", "福井県", "JR小浜線" ],
    [ "若狭和田駅", "福井県", "JR小浜線" ],
    [ "若狭本郷駅", "福井県", "JR小浜線" ],
    [ "加斗駅", "福井県", "JR小浜線" ],
    [ "勢浜駅", "福井県", "JR小浜線" ],
    [ "小浜駅", "福井県", "JR小浜線" ],
    [ "東小浜駅", "福井県", "JR小浜線" ],
    [ "新平野駅", "福井県", "JR小浜線" ],
    [ "上中駅", "福井県", "JR小浜線" ],
    [ "若狭有田駅", "福井県", "JR小浜線" ],
    [ "大鳥羽駅", "福井県", "JR小浜線" ],
    [ "十村駅", "福井県", "JR小浜線" ],
    [ "藤井駅", "福井県", "JR小浜線" ],
    [ "三方駅", "福井県", "JR小浜線" ],
    [ "気山駅", "福井県", "JR小浜線" ],
    [ "美浜駅", "福井県", "JR小浜線" ],
    [ "東美浜駅", "福井県", "JR小浜線" ],
    [ "粟野駅", "福井県", "JR小浜線" ],
    [ "西敦賀駅", "福井県", "JR小浜線" ],
    [ "敦賀駅", "福井県", "JR小浜線" ],
    [ "熱海駅", "静岡県", "JR東海道本線" ],
    [ "函南駅", "静岡県", "JR東海道本線" ],
    [ "三島駅", "静岡県", "JR東海道本線" ],
    [ "沼津駅", "静岡県", "JR東海道本線" ],
    [ "片浜駅", "静岡県", "JR東海道本線" ],
    [ "原駅", "静岡県", "JR東海道本線" ],
    [ "東田子の浦駅", "静岡県", "JR東海道本線" ],
    [ "吉原駅", "静岡県", "JR東海道本線" ],
    [ "富士駅", "静岡県", "JR東海道本線" ],
    [ "富士川駅", "静岡県", "JR東海道本線" ],
    [ "新蒲原駅", "静岡県", "JR東海道本線" ],
    [ "蒲原駅", "静岡県", "JR東海道本線" ],
    [ "由比駅", "静岡県", "JR東海道本線" ],
    [ "興津駅", "静岡県", "JR東海道本線" ],
    [ "清水駅", "静岡県", "JR東海道本線" ],
    [ "草薙駅", "静岡県", "JR東海道本線" ],
    [ "東静岡駅", "静岡県", "JR東海道本線" ],
    [ "静岡駅", "静岡県", "JR東海道本線" ],
    [ "安倍川駅", "静岡県", "JR東海道本線" ],
    [ "用宗駅", "静岡県", "JR東海道本線" ],
    [ "焼津駅", "静岡県", "JR東海道本線" ],
    [ "西焼津駅", "静岡県", "JR東海道本線" ],
    [ "藤枝駅", "静岡県", "JR東海道本線" ],
    [ "六合駅", "静岡県", "JR東海道本線" ],
    [ "島田駅", "静岡県", "JR東海道本線" ],
    [ "金谷駅", "静岡県", "JR東海道本線" ],
    [ "菊川駅", "静岡県", "JR東海道本線" ],
    [ "掛川駅", "静岡県", "JR東海道本線" ],
    [ "愛野駅", "静岡県", "JR東海道本線" ],
    [ "袋井駅", "静岡県", "JR東海道本線" ],
    [ "御厨駅", "静岡県", "JR東海道本線" ],
    [ "磐田駅", "静岡県", "JR東海道本線" ],
    [ "豊田町駅", "静岡県", "JR東海道本線" ],
    [ "天竜川駅", "静岡県", "JR東海道本線" ],
    [ "浜松駅", "静岡県", "JR東海道本線" ],
    [ "浜松駅", "静岡県", "JR東海道本線" ],
    [ "高塚駅", "静岡県", "JR東海道本線" ],
    [ "舞阪駅", "静岡県", "JR東海道本線" ],
    [ "弁天島駅", "静岡県", "JR東海道本線" ],
    [ "新居町駅", "静岡県", "JR東海道本線" ],
    [ "鷲津駅", "静岡県", "JR東海道本線" ],
    [ "新所原駅", "静岡県", "JR東海道本線" ],
    [ "二川駅", "愛知県", "JR東海道本線" ],
    [ "豊橋駅", "愛知県", "JR東海道本線" ],
    [ "西小坂井駅", "愛知県", "JR東海道本線" ],
    [ "愛知御津駅", "愛知県", "JR東海道本線" ],
    [ "三河大塚駅", "愛知県", "JR東海道本線" ],
    [ "三河三谷駅", "愛知県", "JR東海道本線" ],
    [ "蒲郡駅", "愛知県", "JR東海道本線" ],
    [ "三河塩津駅", "愛知県", "JR東海道本線" ],
    [ "三ケ根駅", "愛知県", "JR東海道本線" ],
    [ "幸田駅", "愛知県", "JR東海道本線" ],
    [ "相見駅", "愛知県", "JR東海道本線" ],
    [ "岡崎駅", "愛知県", "JR東海道本線" ],
    [ "西岡崎駅", "愛知県", "JR東海道本線" ],
    [ "安城駅", "愛知県", "JR東海道本線" ],
    [ "三河安城駅", "愛知県", "JR東海道本線" ],
    [ "東刈谷駅", "愛知県", "JR東海道本線" ],
    [ "野田新町駅", "愛知県", "JR東海道本線" ],
    [ "刈谷駅", "愛知県", "JR東海道本線" ],
    [ "逢妻駅", "愛知県", "JR東海道本線" ],
    [ "大府駅", "愛知県", "JR東海道本線" ],
    [ "共和駅", "愛知県", "JR東海道本線" ],
    [ "南大高駅", "愛知県", "JR東海道本線" ],
    [ "大高駅", "愛知県", "JR東海道本線" ],
    [ "笠寺駅", "愛知県", "JR東海道本線" ],
    [ "熱田駅", "愛知県", "JR東海道本線" ],
    [ "金山駅", "愛知県", "JR東海道本線" ],
    [ "尾頭橋駅", "愛知県", "JR東海道本線" ],
    [ "名古屋駅", "愛知県", "JR東海道本線" ],
    [ "枇杷島駅", "愛知県", "JR東海道本線" ],
    [ "清洲駅", "愛知県", "JR東海道本線" ],
    [ "稲沢駅", "愛知県", "JR東海道本線" ],
    [ "尾張一宮駅", "愛知県", "JR東海道本線" ],
    [ "木曽川駅", "愛知県", "JR東海道本線" ],
    [ "岐阜駅", "岐阜県", "JR東海道本線" ],
    [ "岐阜駅", "岐阜県", "JR東海道本線" ],
    [ "西岐阜駅", "岐阜県", "JR東海道本線" ],
    [ "穂積駅", "岐阜県", "JR東海道本線" ],
    [ "大垣駅", "岐阜県", "JR東海道本線" ],
    [ "荒尾駅", "岐阜県", "JR東海道本線" ],
    [ "美濃赤坂駅", "岐阜県", "JR東海道本線" ],
    [ "垂井駅", "岐阜県", "JR東海道本線" ],
    [ "関ケ原駅", "岐阜県", "JR東海道本線" ],
    [ "柏原駅", "滋賀県", "JR東海道本線" ],
    [ "近江長岡駅", "滋賀県", "JR東海道本線" ],
    [ "醒ケ井駅", "滋賀県", "JR東海道本線" ],
    [ "米原駅", "滋賀県", "JR東海道本線" ],
    [ "熱海駅", "静岡県", "JR伊東線" ],
    [ "来宮駅", "静岡県", "JR伊東線" ],
    [ "伊豆多賀駅", "静岡県", "JR伊東線" ],
    [ "網代駅", "静岡県", "JR伊東線" ],
    [ "宇佐美駅", "静岡県", "JR伊東線" ],
    [ "伊東駅", "静岡県", "JR伊東線" ],
    [ "国府津駅", "神奈川県", "JR御殿場線" ],
    [ "下曽我駅", "神奈川県", "JR御殿場線" ],
    [ "上大井駅", "神奈川県", "JR御殿場線" ],
    [ "相模金子駅", "神奈川県", "JR御殿場線" ],
    [ "松田駅", "神奈川県", "JR御殿場線" ],
    [ "東山北駅", "神奈川県", "JR御殿場線" ],
    [ "山北駅", "神奈川県", "JR御殿場線" ],
    [ "谷峨駅", "神奈川県", "JR御殿場線" ],
    [ "駿河小山駅", "静岡県", "JR御殿場線" ],
    [ "足柄駅", "静岡県", "JR御殿場線" ],
    [ "御殿場駅", "静岡県", "JR御殿場線" ],
    [ "南御殿場駅", "静岡県", "JR御殿場線" ],
    [ "富士岡駅", "静岡県", "JR御殿場線" ],
    [ "岩波駅", "静岡県", "JR御殿場線" ],
    [ "裾野駅", "静岡県", "JR御殿場線" ],
    [ "長泉なめり駅", "静岡県", "JR御殿場線" ],
    [ "下土狩駅", "静岡県", "JR御殿場線" ],
    [ "大岡駅", "静岡県", "JR御殿場線" ],
    [ "沼津駅", "静岡県", "JR御殿場線" ],
    [ "大府駅", "愛知県", "JR武豊線" ],
    [ "尾張森岡駅", "愛知県", "JR武豊線" ],
    [ "緒川駅", "愛知県", "JR武豊線" ],
    [ "石浜駅", "愛知県", "JR武豊線" ],
    [ "東浦駅", "愛知県", "JR武豊線" ],
    [ "亀崎駅", "愛知県", "JR武豊線" ],
    [ "乙川駅", "愛知県", "JR武豊線" ],
    [ "半田駅", "愛知県", "JR武豊線" ],
    [ "東成岩駅", "愛知県", "JR武豊線" ],
    [ "武豊駅", "愛知県", "JR武豊線" ],
    [ "多治見駅", "岐阜県", "JR太多線" ],
    [ "小泉駅", "岐阜県", "JR太多線" ],
    [ "根本駅", "岐阜県", "JR太多線" ],
    [ "姫駅", "岐阜県", "JR太多線" ],
    [ "下切駅", "岐阜県", "JR太多線" ],
    [ "可児駅", "岐阜県", "JR太多線" ],
    [ "美濃川合駅", "岐阜県", "JR太多線" ],
    [ "美濃太田駅", "岐阜県", "JR太多線" ],
    [ "名古屋駅", "愛知県", "JR関西本線" ],
    [ "八田駅", "愛知県", "JR関西本線" ],
    [ "春田駅", "愛知県", "JR関西本線" ],
    [ "蟹江駅", "愛知県", "JR関西本線" ],
    [ "永和駅", "愛知県", "JR関西本線" ],
    [ "弥富駅", "愛知県", "JR関西本線" ],
    [ "長島駅", "三重県", "JR関西本線" ],
    [ "桑名駅", "三重県", "JR関西本線" ],
    [ "朝日駅", "三重県", "JR関西本線" ],
    [ "富田駅", "三重県", "JR関西本線" ],
    [ "富田浜駅", "三重県", "JR関西本線" ],
    [ "四日市駅", "三重県", "JR関西本線" ],
    [ "南四日市駅", "三重県", "JR関西本線" ],
    [ "河原田駅", "三重県", "JR関西本線" ],
    [ "河曲駅", "三重県", "JR関西本線" ],
    [ "加佐登駅", "三重県", "JR関西本線" ],
    [ "井田川駅", "三重県", "JR関西本線" ],
    [ "亀山駅", "三重県", "JR関西本線" ],
    [ "亀山駅", "三重県", "JR関西本線" ],
    [ "関駅", "三重県", "JR関西本線" ],
    [ "加太駅", "三重県", "JR関西本線" ],
    [ "柘植駅", "三重県", "JR関西本線" ],
    [ "新堂駅", "三重県", "JR関西本線" ],
    [ "佐那具駅", "三重県", "JR関西本線" ],
    [ "伊賀上野駅", "三重県", "JR関西本線" ],
    [ "島ケ原駅", "三重県", "JR関西本線" ],
    [ "月ケ瀬口駅", "京都府", "JR関西本線" ],
    [ "大河原駅", "京都府", "JR関西本線" ],
    [ "笠置駅", "京都府", "JR関西本線" ],
    [ "加茂駅", "京都府", "JR関西本線" ],
    [ "亀山駅", "三重県", "JR紀勢本線" ],
    [ "下庄駅", "三重県", "JR紀勢本線" ],
    [ "一身田駅", "三重県", "JR紀勢本線" ],
    [ "津駅", "三重県", "JR紀勢本線" ],
    [ "阿漕駅", "三重県", "JR紀勢本線" ],
    [ "高茶屋駅", "三重県", "JR紀勢本線" ],
    [ "六軒駅", "三重県", "JR紀勢本線" ],
    [ "松阪駅", "三重県", "JR紀勢本線" ],
    [ "徳和駅", "三重県", "JR紀勢本線" ],
    [ "多気駅", "三重県", "JR紀勢本線" ],
    [ "相可駅", "三重県", "JR紀勢本線" ],
    [ "佐奈駅", "三重県", "JR紀勢本線" ],
    [ "栃原駅", "三重県", "JR紀勢本線" ],
    [ "川添駅", "三重県", "JR紀勢本線" ],
    [ "三瀬谷駅", "三重県", "JR紀勢本線" ],
    [ "滝原駅", "三重県", "JR紀勢本線" ],
    [ "阿曽駅", "三重県", "JR紀勢本線" ],
    [ "伊勢柏崎駅", "三重県", "JR紀勢本線" ],
    [ "大内山駅", "三重県", "JR紀勢本線" ],
    [ "梅ケ谷駅", "三重県", "JR紀勢本線" ],
    [ "紀伊長島駅", "三重県", "JR紀勢本線" ],
    [ "三野瀬駅", "三重県", "JR紀勢本線" ],
    [ "船津駅", "三重県", "JR紀勢本線" ],
    [ "相賀駅", "三重県", "JR紀勢本線" ],
    [ "尾鷲駅", "三重県", "JR紀勢本線" ],
    [ "大曽根浦駅", "三重県", "JR紀勢本線" ],
    [ "九鬼駅", "三重県", "JR紀勢本線" ],
    [ "三木里駅", "三重県", "JR紀勢本線" ],
    [ "賀田駅", "三重県", "JR紀勢本線" ],
    [ "二木島駅", "三重県", "JR紀勢本線" ],
    [ "新鹿駅", "三重県", "JR紀勢本線" ],
    [ "波田須駅", "三重県", "JR紀勢本線" ],
    [ "大泊駅", "三重県", "JR紀勢本線" ],
    [ "熊野市駅", "三重県", "JR紀勢本線" ],
    [ "有井駅", "三重県", "JR紀勢本線" ],
    [ "神志山駅", "三重県", "JR紀勢本線" ],
    [ "紀伊市木駅", "三重県", "JR紀勢本線" ],
    [ "阿田和駅", "三重県", "JR紀勢本線" ],
    [ "紀伊井田駅", "三重県", "JR紀勢本線" ],
    [ "鵜殿駅", "三重県", "JR紀勢本線" ],
    [ "新宮駅", "和歌山県", "JR紀勢本線" ],
    [ "柘植駅", "三重県", "JR草津線" ],
    [ "油日駅", "滋賀県", "JR草津線" ],
    [ "甲賀駅", "滋賀県", "JR草津線" ],
    [ "寺庄駅", "滋賀県", "JR草津線" ],
    [ "甲南駅", "滋賀県", "JR草津線" ],
    [ "貴生川駅", "滋賀県", "JR草津線" ],
    [ "三雲駅", "滋賀県", "JR草津線" ],
    [ "甲西駅", "滋賀県", "JR草津線" ],
    [ "石部駅", "滋賀県", "JR草津線" ],
    [ "手原駅", "滋賀県", "JR草津線" ],
    [ "草津駅", "滋賀県", "JR草津線" ],
    [ "多気駅", "三重県", "JR参宮線" ],
    [ "外城田駅", "三重県", "JR参宮線" ],
    [ "田丸駅", "三重県", "JR参宮線" ],
    [ "宮川駅", "三重県", "JR参宮線" ],
    [ "山田上口駅", "三重県", "JR参宮線" ],
    [ "伊勢市駅", "三重県", "JR参宮線" ],
    [ "五十鈴ケ丘駅", "三重県", "JR参宮線" ],
    [ "二見浦駅", "三重県", "JR参宮線" ],
    [ "松下駅", "三重県", "JR参宮線" ],
    [ "池の浦シーサイド駅", "三重県", "JR参宮線" ],
    [ "鳥羽駅", "三重県", "JR参宮線" ],
    [ "松阪駅", "三重県", "JR名松線" ],
    [ "上ノ庄駅", "三重県", "JR名松線" ],
    [ "権現前駅", "三重県", "JR名松線" ],
    [ "伊勢八太駅", "三重県", "JR名松線" ],
    [ "一志駅", "三重県", "JR名松線" ],
    [ "井関駅", "三重県", "JR名松線" ],
    [ "伊勢大井駅", "三重県", "JR名松線" ],
    [ "伊勢川口駅", "三重県", "JR名松線" ],
    [ "関ノ宮駅", "三重県", "JR名松線" ],
    [ "家城駅", "三重県", "JR名松線" ],
    [ "伊勢竹原駅", "三重県", "JR名松線" ],
    [ "伊勢鎌倉駅", "三重県", "JR名松線" ],
    [ "伊勢八知駅", "三重県", "JR名松線" ],
    [ "比津駅", "三重県", "JR名松線" ],
    [ "伊勢奥津駅", "三重県", "JR名松線" ],
    [ "米原駅", "滋賀県", "琵琶湖線" ],
    [ "彦根駅", "滋賀県", "琵琶湖線" ],
    [ "南彦根駅", "滋賀県", "琵琶湖線" ],
    [ "河瀬駅", "滋賀県", "琵琶湖線" ],
    [ "稲枝駅", "滋賀県", "琵琶湖線" ],
    [ "能登川駅", "滋賀県", "琵琶湖線" ],
    [ "安土駅", "滋賀県", "琵琶湖線" ],
    [ "近江八幡駅", "滋賀県", "琵琶湖線" ],
    [ "篠原駅", "滋賀県", "琵琶湖線" ],
    [ "野洲駅", "滋賀県", "琵琶湖線" ],
    [ "守山駅", "滋賀県", "琵琶湖線" ],
    [ "栗東駅", "滋賀県", "琵琶湖線" ],
    [ "草津駅", "滋賀県", "琵琶湖線" ],
    [ "南草津駅", "滋賀県", "琵琶湖線" ],
    [ "瀬田駅", "滋賀県", "琵琶湖線" ],
    [ "石山駅", "滋賀県", "琵琶湖線" ],
    [ "膳所駅", "滋賀県", "琵琶湖線" ],
    [ "大津駅", "滋賀県", "琵琶湖線" ],
    [ "山科駅", "京都府", "琵琶湖線" ],
    [ "京都駅", "京都府", "琵琶湖線" ],
    [ "京都駅", "京都府", "JR京都線" ],
    [ "西大路駅", "京都府", "JR京都線" ],
    [ "桂川駅", "京都府", "JR京都線" ],
    [ "向日町駅", "京都府", "JR京都線" ],
    [ "長岡京駅", "京都府", "JR京都線" ],
    [ "山崎駅", "京都府", "JR京都線" ],
    [ "島本駅", "大阪府", "JR京都線" ],
    [ "高槻駅", "大阪府", "JR京都線" ],
    [ "摂津富田駅", "大阪府", "JR京都線" ],
    [ "JR総持寺駅", "大阪府", "JR京都線" ],
    [ "茨木駅", "大阪府", "JR京都線" ],
    [ "千里丘駅", "大阪府", "JR京都線" ],
    [ "岸辺駅", "大阪府", "JR京都線" ],
    [ "吹田駅", "大阪府", "JR京都線" ],
    [ "東淀川駅", "大阪府", "JR京都線" ],
    [ "新大阪駅", "大阪府", "JR京都線" ],
    [ "大阪駅", "大阪府", "JR京都線" ],
    [ "大阪駅", "大阪府", "JR神戸線" ],
    [ "塚本駅", "大阪府", "JR神戸線" ],
    [ "尼崎駅", "兵庫県", "JR神戸線" ],
    [ "立花駅", "兵庫県", "JR神戸線" ],
    [ "甲子園口駅", "兵庫県", "JR神戸線" ],
    [ "西宮駅", "兵庫県", "JR神戸線" ],
    [ "さくら夙川駅", "兵庫県", "JR神戸線" ],
    [ "芦屋駅", "兵庫県", "JR神戸線" ],
    [ "甲南山手駅", "兵庫県", "JR神戸線" ],
    [ "摂津本山駅", "兵庫県", "JR神戸線" ],
    [ "住吉駅", "兵庫県", "JR神戸線" ],
    [ "六甲道駅", "兵庫県", "JR神戸線" ],
    [ "摩耶駅", "兵庫県", "JR神戸線" ],
    [ "灘駅", "兵庫県", "JR神戸線" ],
    [ "三ノ宮駅", "兵庫県", "JR神戸線" ],
    [ "元町駅", "兵庫県", "JR神戸線" ],
    [ "神戸駅", "兵庫県", "JR神戸線" ],
    [ "京都駅", "京都府", "JR湖西線" ],
    [ "山科駅", "京都府", "JR湖西線" ],
    [ "大津京駅", "滋賀県", "JR湖西線" ],
    [ "唐崎駅", "滋賀県", "JR湖西線" ],
    [ "比叡山坂本駅", "滋賀県", "JR湖西線" ],
    [ "おごと温泉駅", "滋賀県", "JR湖西線" ],
    [ "堅田駅", "滋賀県", "JR湖西線" ],
    [ "小野駅", "滋賀県", "JR湖西線" ],
    [ "和邇駅", "滋賀県", "JR湖西線" ],
    [ "蓬莱駅", "滋賀県", "JR湖西線" ],
    [ "志賀駅", "滋賀県", "JR湖西線" ],
    [ "比良駅", "滋賀県", "JR湖西線" ],
    [ "近江舞子駅", "滋賀県", "JR湖西線" ],
    [ "北小松駅", "滋賀県", "JR湖西線" ],
    [ "近江高島駅", "滋賀県", "JR湖西線" ],
    [ "安曇川駅", "滋賀県", "JR湖西線" ],
    [ "新旭駅", "滋賀県", "JR湖西線" ],
    [ "近江今津駅", "滋賀県", "JR湖西線" ],
    [ "近江中庄駅", "滋賀県", "JR湖西線" ],
    [ "マキノ駅", "滋賀県", "JR湖西線" ],
    [ "永原駅", "滋賀県", "JR湖西線" ],
    [ "近江塩津駅", "滋賀県", "JR湖西線" ],
    [ "加茂駅", "京都府", "大和路線" ],
    [ "木津駅", "京都府", "大和路線" ],
    [ "平城山駅", "奈良県", "大和路線" ],
    [ "奈良駅", "奈良県", "大和路線" ],
    [ "郡山駅", "奈良県", "大和路線" ],
    [ "大和小泉駅", "奈良県", "大和路線" ],
    [ "法隆寺駅", "奈良県", "大和路線" ],
    [ "王寺駅", "奈良県", "大和路線" ],
    [ "三郷駅", "奈良県", "大和路線" ],
    [ "河内堅上駅", "大阪府", "大和路線" ],
    [ "高井田駅", "大阪府", "大和路線" ],
    [ "柏原駅", "大阪府", "大和路線" ],
    [ "志紀駅", "大阪府", "大和路線" ],
    [ "八尾駅", "大阪府", "大和路線" ],
    [ "久宝寺駅", "大阪府", "大和路線" ],
    [ "加美駅", "大阪府", "大和路線" ],
    [ "平野駅", "大阪府", "大和路線" ],
    [ "東部市場前駅", "大阪府", "大和路線" ],
    [ "天王寺駅", "大阪府", "大和路線" ],
    [ "新今宮駅", "大阪府", "大和路線" ],
    [ "今宮駅", "大阪府", "大和路線" ],
    [ "ＪＲ難波駅", "大阪府", "大和路線" ],
    [ "神戸駅", "兵庫県", "JR神戸線" ],
    [ "兵庫駅", "兵庫県", "JR神戸線" ],
    [ "新長田駅", "兵庫県", "JR神戸線" ],
    [ "鷹取駅", "兵庫県", "JR神戸線" ],
    [ "須磨海浜公園駅", "兵庫県", "JR神戸線" ],
    [ "須磨駅", "兵庫県", "JR神戸線" ],
    [ "塩屋駅", "兵庫県", "JR神戸線" ],
    [ "垂水駅", "兵庫県", "JR神戸線" ],
    [ "舞子駅", "兵庫県", "JR神戸線" ],
    [ "朝霧駅", "兵庫県", "JR神戸線" ],
    [ "明石駅", "兵庫県", "JR神戸線" ],
    [ "西明石駅", "兵庫県", "JR神戸線" ],
    [ "大久保駅", "兵庫県", "JR神戸線" ],
    [ "魚住駅", "兵庫県", "JR神戸線" ],
    [ "土山駅", "兵庫県", "JR神戸線" ],
    [ "東加古川駅", "兵庫県", "JR神戸線" ],
    [ "加古川駅", "兵庫県", "JR神戸線" ],
    [ "宝殿駅", "兵庫県", "JR神戸線" ],
    [ "曽根駅", "兵庫県", "JR神戸線" ],
    [ "ひめじ別所駅", "兵庫県", "JR神戸線" ],
    [ "御着駅", "兵庫県", "JR神戸線" ],
    [ "東姫路駅", "兵庫県", "JR神戸線" ],
    [ "姫路駅", "兵庫県", "JR神戸線" ],
    [ "姫路駅", "兵庫県", "JR山陽本線" ],
    [ "英賀保駅", "兵庫県", "JR山陽本線" ],
    [ "はりま勝原駅", "兵庫県", "JR山陽本線" ],
    [ "網干駅", "兵庫県", "JR山陽本線" ],
    [ "竜野駅", "兵庫県", "JR山陽本線" ],
    [ "相生駅", "兵庫県", "JR山陽本線" ],
    [ "有年駅", "兵庫県", "JR山陽本線" ],
    [ "上郡駅", "兵庫県", "JR山陽本線" ],
    [ "三石駅", "岡山県", "JR山陽本線" ],
    [ "吉永駅", "岡山県", "JR山陽本線" ],
    [ "和気駅", "岡山県", "JR山陽本線" ],
    [ "熊山駅", "岡山県", "JR山陽本線" ],
    [ "万富駅", "岡山県", "JR山陽本線" ],
    [ "瀬戸駅", "岡山県", "JR山陽本線" ],
    [ "上道駅", "岡山県", "JR山陽本線" ],
    [ "東岡山駅", "岡山県", "JR山陽本線" ],
    [ "高島駅", "岡山県", "JR山陽本線" ],
    [ "西川原駅", "岡山県", "JR山陽本線" ],
    [ "岡山駅", "岡山県", "JR山陽本線" ],
    [ "岡山駅", "岡山県", "JR山陽本線" ],
    [ "北長瀬駅", "岡山県", "JR山陽本線" ],
    [ "庭瀬駅", "岡山県", "JR山陽本線" ],
    [ "中庄駅", "岡山県", "JR山陽本線" ],
    [ "倉敷駅", "岡山県", "JR山陽本線" ],
    [ "西阿知駅", "岡山県", "JR山陽本線" ],
    [ "新倉敷駅", "岡山県", "JR山陽本線" ],
    [ "金光駅", "岡山県", "JR山陽本線" ],
    [ "鴨方駅", "岡山県", "JR山陽本線" ],
    [ "里庄駅", "岡山県", "JR山陽本線" ],
    [ "笠岡駅", "岡山県", "JR山陽本線" ],
    [ "大門駅", "広島県", "JR山陽本線" ],
    [ "東福山駅", "広島県", "JR山陽本線" ],
    [ "福山駅", "広島県", "JR山陽本線" ],
    [ "備後赤坂駅", "広島県", "JR山陽本線" ],
    [ "松永駅", "広島県", "JR山陽本線" ],
    [ "東尾道駅", "広島県", "JR山陽本線" ],
    [ "尾道駅", "広島県", "JR山陽本線" ],
    [ "糸崎駅", "広島県", "JR山陽本線" ],
    [ "三原駅", "広島県", "JR山陽本線" ],
    [ "三原駅", "広島県", "JR山陽本線" ],
    [ "本郷駅", "広島県", "JR山陽本線" ],
    [ "河内駅", "広島県", "JR山陽本線" ],
    [ "入野駅", "広島県", "JR山陽本線" ],
    [ "白市駅", "広島県", "JR山陽本線" ],
    [ "西高屋駅", "広島県", "JR山陽本線" ],
    [ "西条駅", "広島県", "JR山陽本線" ],
    [ "寺家駅", "広島県", "JR山陽本線" ],
    [ "八本松駅", "広島県", "JR山陽本線" ],
    [ "瀬野駅", "広島県", "JR山陽本線" ],
    [ "中野東駅", "広島県", "JR山陽本線" ],
    [ "安芸中野駅", "広島県", "JR山陽本線" ],
    [ "海田市駅", "広島県", "JR山陽本線" ],
    [ "向洋駅", "広島県", "JR山陽本線" ],
    [ "天神川駅", "広島県", "JR山陽本線" ],
    [ "広島駅", "広島県", "JR山陽本線" ],
    [ "新白島駅", "広島県", "JR山陽本線" ],
    [ "横川駅", "広島県", "JR山陽本線" ],
    [ "西広島駅", "広島県", "JR山陽本線" ],
    [ "新井口駅", "広島県", "JR山陽本線" ],
    [ "五日市駅", "広島県", "JR山陽本線" ],
    [ "廿日市駅", "広島県", "JR山陽本線" ],
    [ "宮内串戸駅", "広島県", "JR山陽本線" ],
    [ "阿品駅", "広島県", "JR山陽本線" ],
    [ "宮島口駅", "広島県", "JR山陽本線" ],
    [ "前空駅", "広島県", "JR山陽本線" ],
    [ "大野浦駅", "広島県", "JR山陽本線" ],
    [ "玖波駅", "広島県", "JR山陽本線" ],
    [ "大竹駅", "広島県", "JR山陽本線" ],
    [ "和木駅", "山口県", "JR山陽本線" ],
    [ "岩国駅", "山口県", "JR山陽本線" ],
    [ "岩国駅", "山口県", "JR山陽本線" ],
    [ "南岩国駅", "山口県", "JR山陽本線" ],
    [ "藤生駅", "山口県", "JR山陽本線" ],
    [ "通津駅", "山口県", "JR山陽本線" ],
    [ "由宇駅", "山口県", "JR山陽本線" ],
    [ "神代駅", "山口県", "JR山陽本線" ],
    [ "大畠駅", "山口県", "JR山陽本線" ],
    [ "柳井港駅", "山口県", "JR山陽本線" ],
    [ "柳井駅", "山口県", "JR山陽本線" ],
    [ "田布施駅", "山口県", "JR山陽本線" ],
    [ "岩田駅", "山口県", "JR山陽本線" ],
    [ "島田駅", "山口県", "JR山陽本線" ],
    [ "光駅", "山口県", "JR山陽本線" ],
    [ "下松駅", "山口県", "JR山陽本線" ],
    [ "櫛ケ浜駅", "山口県", "JR山陽本線" ],
    [ "徳山駅", "山口県", "JR山陽本線" ],
    [ "新南陽駅", "山口県", "JR山陽本線" ],
    [ "福川駅", "山口県", "JR山陽本線" ],
    [ "戸田駅", "山口県", "JR山陽本線" ],
    [ "富海駅", "山口県", "JR山陽本線" ],
    [ "防府駅", "山口県", "JR山陽本線" ],
    [ "大道駅", "山口県", "JR山陽本線" ],
    [ "四辻駅", "山口県", "JR山陽本線" ],
    [ "新山口駅", "山口県", "JR山陽本線" ],
    [ "嘉川駅", "山口県", "JR山陽本線" ],
    [ "本由良駅", "山口県", "JR山陽本線" ],
    [ "厚東駅", "山口県", "JR山陽本線" ],
    [ "宇部駅", "山口県", "JR山陽本線" ],
    [ "小野田駅", "山口県", "JR山陽本線" ],
    [ "厚狭駅", "山口県", "JR山陽本線" ],
    [ "埴生駅", "山口県", "JR山陽本線" ],
    [ "小月駅", "山口県", "JR山陽本線" ],
    [ "長府駅", "山口県", "JR山陽本線" ],
    [ "新下関駅", "山口県", "JR山陽本線" ],
    [ "幡生駅", "山口県", "JR山陽本線" ],
    [ "下関駅", "山口県", "JR山陽本線" ],
    [ "門司駅", "福岡県", "JR山陽本線" ],
    [ "兵庫駅", "兵庫県", "JR山陽本線" ],
    [ "和田岬駅", "兵庫県", "JR山陽本線" ],
    [ "京都駅", "京都府", "嵯峨野線" ],
    [ "梅小路京都西駅", "京都府", "嵯峨野線" ],
    [ "丹波口駅", "京都府", "嵯峨野線" ],
    [ "二条駅", "京都府", "嵯峨野線" ],
    [ "円町駅", "京都府", "嵯峨野線" ],
    [ "花園駅", "京都府", "嵯峨野線" ],
    [ "太秦駅", "京都府", "嵯峨野線" ],
    [ "嵯峨嵐山駅", "京都府", "嵯峨野線" ],
    [ "保津峡駅", "京都府", "嵯峨野線" ],
    [ "馬堀駅", "京都府", "嵯峨野線" ],
    [ "亀岡駅", "京都府", "嵯峨野線" ],
    [ "並河駅", "京都府", "嵯峨野線" ],
    [ "千代川駅", "京都府", "嵯峨野線" ],
    [ "八木駅", "京都府", "嵯峨野線" ],
    [ "吉富駅", "京都府", "嵯峨野線" ],
    [ "園部駅", "京都府", "嵯峨野線" ],
    [ "園部駅", "京都府", "JR山陰本線" ],
    [ "船岡駅", "京都府", "JR山陰本線" ],
    [ "日吉駅", "京都府", "JR山陰本線" ],
    [ "鍼灸大学前駅", "京都府", "JR山陰本線" ],
    [ "胡麻駅", "京都府", "JR山陰本線" ],
    [ "下山駅", "京都府", "JR山陰本線" ],
    [ "和知駅", "京都府", "JR山陰本線" ],
    [ "安栖里駅", "京都府", "JR山陰本線" ],
    [ "立木駅", "京都府", "JR山陰本線" ],
    [ "山家駅", "京都府", "JR山陰本線" ],
    [ "綾部駅", "京都府", "JR山陰本線" ],
    [ "高津駅", "京都府", "JR山陰本線" ],
    [ "石原駅", "京都府", "JR山陰本線" ],
    [ "福知山駅", "京都府", "JR山陰本線" ],
    [ "上川口駅", "京都府", "JR山陰本線" ],
    [ "下夜久野駅", "京都府", "JR山陰本線" ],
    [ "上夜久野駅", "京都府", "JR山陰本線" ],
    [ "梁瀬駅", "兵庫県", "JR山陰本線" ],
    [ "和田山駅", "兵庫県", "JR山陰本線" ],
    [ "養父駅", "兵庫県", "JR山陰本線" ],
    [ "八鹿駅", "兵庫県", "JR山陰本線" ],
    [ "江原駅", "兵庫県", "JR山陰本線" ],
    [ "国府駅", "兵庫県", "JR山陰本線" ],
    [ "豊岡駅", "兵庫県", "JR山陰本線" ],
    [ "豊岡駅", "兵庫県", "JR山陰本線" ],
    [ "玄武洞駅", "兵庫県", "JR山陰本線" ],
    [ "城崎温泉駅", "兵庫県", "JR山陰本線" ],
    [ "竹野駅", "兵庫県", "JR山陰本線" ],
    [ "きりはまビーチ駅", "兵庫県", "JR山陰本線" ],
    [ "佐津駅", "兵庫県", "JR山陰本線" ],
    [ "柴山駅", "兵庫県", "JR山陰本線" ],
    [ "香住駅", "兵庫県", "JR山陰本線" ],
    [ "鎧駅", "兵庫県", "JR山陰本線" ],
    [ "餘部駅", "兵庫県", "JR山陰本線" ],
    [ "久谷駅", "兵庫県", "JR山陰本線" ],
    [ "浜坂駅", "兵庫県", "JR山陰本線" ],
    [ "諸寄駅", "兵庫県", "JR山陰本線" ],
    [ "居組駅", "兵庫県", "JR山陰本線" ],
    [ "東浜駅", "鳥取県", "JR山陰本線" ],
    [ "岩美駅", "鳥取県", "JR山陰本線" ],
    [ "大岩駅", "鳥取県", "JR山陰本線" ],
    [ "福部駅", "鳥取県", "JR山陰本線" ],
    [ "鳥取駅", "鳥取県", "JR山陰本線" ],
    [ "湖山駅", "鳥取県", "JR山陰本線" ],
    [ "鳥取大学前駅", "鳥取県", "JR山陰本線" ],
    [ "末恒駅", "鳥取県", "JR山陰本線" ],
    [ "宝木駅", "鳥取県", "JR山陰本線" ],
    [ "浜村駅", "鳥取県", "JR山陰本線" ],
    [ "青谷駅", "鳥取県", "JR山陰本線" ],
    [ "泊駅", "鳥取県", "JR山陰本線" ],
    [ "松崎駅", "鳥取県", "JR山陰本線" ],
    [ "倉吉駅", "鳥取県", "JR山陰本線" ],
    [ "下北条駅", "鳥取県", "JR山陰本線" ],
    [ "由良駅", "鳥取県", "JR山陰本線" ],
    [ "浦安駅", "鳥取県", "JR山陰本線" ],
    [ "八橋駅", "鳥取県", "JR山陰本線" ],
    [ "赤碕駅", "鳥取県", "JR山陰本線" ],
    [ "中山口駅", "鳥取県", "JR山陰本線" ],
    [ "下市駅", "鳥取県", "JR山陰本線" ],
    [ "御来屋駅", "鳥取県", "JR山陰本線" ],
    [ "名和駅", "鳥取県", "JR山陰本線" ],
    [ "大山口駅", "鳥取県", "JR山陰本線" ],
    [ "淀江駅", "鳥取県", "JR山陰本線" ],
    [ "伯耆大山駅", "鳥取県", "JR山陰本線" ],
    [ "東山公園駅", "鳥取県", "JR山陰本線" ],
    [ "米子駅", "鳥取県", "JR山陰本線" ],
    [ "木津駅", "京都府", "学研都市線" ],
    [ "西木津駅", "京都府", "学研都市線" ],
    [ "祝園駅", "京都府", "学研都市線" ],
    [ "下狛駅", "京都府", "学研都市線" ],
    [ "ＪＲ三山木駅", "京都府", "学研都市線" ],
    [ "同志社前駅", "京都府", "学研都市線" ],
    [ "京田辺駅", "京都府", "学研都市線" ],
    [ "大住駅", "京都府", "学研都市線" ],
    [ "松井山手駅", "京都府", "学研都市線" ],
    [ "長尾駅", "大阪府", "学研都市線" ],
    [ "藤阪駅", "大阪府", "学研都市線" ],
    [ "津田駅", "大阪府", "学研都市線" ],
    [ "河内磐船駅", "大阪府", "学研都市線" ],
    [ "星田駅", "大阪府", "学研都市線" ],
    [ "寝屋川公園駅", "大阪府", "学研都市線" ],
    [ "忍ケ丘駅", "大阪府", "学研都市線" ],
    [ "四条畷駅", "大阪府", "学研都市線" ],
    [ "野崎駅", "大阪府", "学研都市線" ],
    [ "住道駅", "大阪府", "学研都市線" ],
    [ "鴻池新田駅", "大阪府", "学研都市線" ],
    [ "徳庵駅", "大阪府", "学研都市線" ],
    [ "放出駅", "大阪府", "学研都市線" ],
    [ "鴫野駅", "大阪府", "学研都市線" ],
    [ "京橋駅", "大阪府", "学研都市線" ],
    [ "京都駅", "京都府", "奈良線" ],
    [ "東福寺駅", "京都府", "奈良線" ],
    [ "稲荷駅", "京都府", "奈良線" ],
    [ "ＪＲ藤森駅", "京都府", "奈良線" ],
    [ "桃山駅", "京都府", "奈良線" ],
    [ "六地蔵駅", "京都府", "奈良線" ],
    [ "木幡駅", "京都府", "奈良線" ],
    [ "黄檗駅", "京都府", "奈良線" ],
    [ "宇治駅", "京都府", "奈良線" ],
    [ "ＪＲ小倉駅", "京都府", "奈良線" ],
    [ "新田駅", "京都府", "奈良線" ],
    [ "城陽駅", "京都府", "奈良線" ],
    [ "長池駅", "京都府", "奈良線" ],
    [ "山城青谷駅", "京都府", "奈良線" ],
    [ "山城多賀駅", "京都府", "奈良線" ],
    [ "玉水駅", "京都府", "奈良線" ],
    [ "棚倉駅", "京都府", "奈良線" ],
    [ "上狛駅", "京都府", "奈良線" ],
    [ "木津駅", "京都府", "奈良線" ],
    [ "平城山駅", "奈良県", "奈良線" ],
    [ "奈良駅", "奈良県", "奈良線" ],
    [ "福知山駅", "京都府", "JR舞鶴線" ],
    [ "石原駅", "京都府", "JR舞鶴線" ],
    [ "高津駅", "京都府", "JR舞鶴線" ],
    [ "綾部駅", "京都府", "JR舞鶴線" ],
    [ "淵垣駅", "京都府", "JR舞鶴線" ],
    [ "梅迫駅", "京都府", "JR舞鶴線" ],
    [ "真倉駅", "京都府", "JR舞鶴線" ],
    [ "西舞鶴駅", "京都府", "JR舞鶴線" ],
    [ "東舞鶴駅", "京都府", "JR舞鶴線" ],
    [ "天王寺駅", "大阪府", "大阪環状線" ],
    [ "新今宮駅", "大阪府", "大阪環状線" ],
    [ "今宮駅", "大阪府", "大阪環状線" ],
    [ "芦原橋駅", "大阪府", "大阪環状線" ],
    [ "大正駅", "大阪府", "大阪環状線" ],
    [ "弁天町駅", "大阪府", "大阪環状線" ],
    [ "西九条駅", "大阪府", "大阪環状線" ],
    [ "野田駅", "大阪府", "大阪環状線" ],
    [ "福島駅", "大阪府", "大阪環状線" ],
    [ "大阪駅", "大阪府", "大阪環状線" ],
    [ "天満駅", "大阪府", "大阪環状線" ],
    [ "桜ノ宮駅", "大阪府", "大阪環状線" ],
    [ "京橋駅", "大阪府", "大阪環状線" ],
    [ "大阪城公園駅", "大阪府", "大阪環状線" ],
    [ "森ノ宮駅", "大阪府", "大阪環状線" ],
    [ "玉造駅", "大阪府", "大阪環状線" ],
    [ "鶴橋駅", "大阪府", "大阪環状線" ],
    [ "桃谷駅", "大阪府", "大阪環状線" ],
    [ "寺田町駅", "大阪府", "大阪環状線" ],
    [ "西九条駅", "大阪府", "JRゆめ咲線" ],
    [ "安治川口駅", "大阪府", "JRゆめ咲線" ],
    [ "ユニバーサルシティ駅", "大阪府", "JRゆめ咲線" ],
    [ "桜島駅", "大阪府", "JRゆめ咲線" ],
    [ "京橋駅", "大阪府", "JR東西線" ],
    [ "大阪城北詰駅", "大阪府", "JR東西線" ],
    [ "大阪天満宮駅", "大阪府", "JR東西線" ],
    [ "北新地駅", "大阪府", "JR東西線" ],
    [ "新福島駅", "大阪府", "JR東西線" ],
    [ "海老江駅", "大阪府", "JR東西線" ],
    [ "御幣島駅", "大阪府", "JR東西線" ],
    [ "加島駅", "大阪府", "JR東西線" ],
    [ "尼崎駅", "兵庫県", "JR東西線" ],
    [ "天王寺駅", "大阪府", "阪和線" ],
    [ "美章園駅", "大阪府", "阪和線" ],
    [ "南田辺駅", "大阪府", "阪和線" ],
    [ "鶴ケ丘駅", "大阪府", "阪和線" ],
    [ "長居駅", "大阪府", "阪和線" ],
    [ "我孫子町駅", "大阪府", "阪和線" ],
    [ "杉本町駅", "大阪府", "阪和線" ],
    [ "浅香駅", "大阪府", "阪和線" ],
    [ "堺市駅", "大阪府", "阪和線" ],
    [ "三国ケ丘駅", "大阪府", "阪和線" ],
    [ "百舌鳥駅", "大阪府", "阪和線" ],
    [ "上野芝駅", "大阪府", "阪和線" ],
    [ "津久野駅", "大阪府", "阪和線" ],
    [ "鳳駅", "大阪府", "阪和線" ],
    [ "富木駅", "大阪府", "阪和線" ],
    [ "北信太駅", "大阪府", "阪和線" ],
    [ "信太山駅", "大阪府", "阪和線" ],
    [ "和泉府中駅", "大阪府", "阪和線" ],
    [ "久米田駅", "大阪府", "阪和線" ],
    [ "下松駅", "大阪府", "阪和線" ],
    [ "東岸和田駅", "大阪府", "阪和線" ],
    [ "東貝塚駅", "大阪府", "阪和線" ],
    [ "和泉橋本駅", "大阪府", "阪和線" ],
    [ "東佐野駅", "大阪府", "阪和線" ],
    [ "熊取駅", "大阪府", "阪和線" ],
    [ "日根野駅", "大阪府", "阪和線" ],
    [ "長滝駅", "大阪府", "阪和線" ],
    [ "新家駅", "大阪府", "阪和線" ],
    [ "和泉砂川駅", "大阪府", "阪和線" ],
    [ "和泉鳥取駅", "大阪府", "阪和線" ],
    [ "山中渓駅", "大阪府", "阪和線" ],
    [ "紀伊駅", "和歌山県", "阪和線" ],
    [ "六十谷駅", "和歌山県", "阪和線" ],
    [ "紀伊中ノ島駅", "和歌山県", "阪和線" ],
    [ "和歌山駅", "和歌山県", "阪和線" ],
    [ "鳳駅", "大阪府", "羽衣線" ],
    [ "東羽衣駅", "大阪府", "羽衣線" ],
    [ "日根野駅", "大阪府", "JR関西空港線" ],
    [ "りんくうタウン駅", "大阪府", "JR関西空港線" ],
    [ "関西空港駅", "大阪府", "JR関西空港線" ],
    [ "新大阪駅", "大阪府", "JR宝塚線" ],
    [ "大阪駅", "大阪府", "JR宝塚線" ],
    [ "尼崎駅", "兵庫県", "JR宝塚線" ],
    [ "塚口駅", "兵庫県", "JR宝塚線" ],
    [ "猪名寺駅", "兵庫県", "JR宝塚線" ],
    [ "伊丹駅", "兵庫県", "JR宝塚線" ],
    [ "北伊丹駅", "兵庫県", "JR宝塚線" ],
    [ "川西池田駅", "兵庫県", "JR宝塚線" ],
    [ "中山寺駅", "兵庫県", "JR宝塚線" ],
    [ "宝塚駅", "兵庫県", "JR宝塚線" ],
    [ "生瀬駅", "兵庫県", "JR宝塚線" ],
    [ "西宮名塩駅", "兵庫県", "JR宝塚線" ],
    [ "武田尾駅", "兵庫県", "JR宝塚線" ],
    [ "道場駅", "兵庫県", "JR宝塚線" ],
    [ "三田駅", "兵庫県", "JR宝塚線" ],
    [ "新三田駅", "兵庫県", "JR宝塚線" ],
    [ "広野駅", "兵庫県", "JR宝塚線" ],
    [ "相野駅", "兵庫県", "JR宝塚線" ],
    [ "藍本駅", "兵庫県", "JR宝塚線" ],
    [ "草野駅", "兵庫県", "JR宝塚線" ],
    [ "古市駅", "兵庫県", "JR宝塚線" ],
    [ "南矢代駅", "兵庫県", "JR宝塚線" ],
    [ "篠山口駅", "兵庫県", "JR宝塚線" ],
    [ "篠山口駅", "兵庫県", "福知山線" ],
    [ "丹波大山駅", "兵庫県", "福知山線" ],
    [ "下滝駅", "兵庫県", "福知山線" ],
    [ "谷川駅", "兵庫県", "福知山線" ],
    [ "柏原駅", "兵庫県", "福知山線" ],
    [ "石生駅", "兵庫県", "福知山線" ],
    [ "黒井駅", "兵庫県", "福知山線" ],
    [ "市島駅", "兵庫県", "福知山線" ],
    [ "丹波竹田駅", "兵庫県", "福知山線" ],
    [ "福知山駅", "京都府", "福知山線" ],
    [ "相生駅", "兵庫県", "JR赤穂線" ],
    [ "西相生駅", "兵庫県", "JR赤穂線" ],
    [ "坂越駅", "兵庫県", "JR赤穂線" ],
    [ "播州赤穂駅", "兵庫県", "JR赤穂線" ],
    [ "天和駅", "兵庫県", "JR赤穂線" ],
    [ "備前福河駅", "兵庫県", "JR赤穂線" ],
    [ "寒河駅", "岡山県", "JR赤穂線" ],
    [ "日生駅", "岡山県", "JR赤穂線" ],
    [ "伊里駅", "岡山県", "JR赤穂線" ],
    [ "備前片上駅", "岡山県", "JR赤穂線" ],
    [ "西片上駅", "岡山県", "JR赤穂線" ],
    [ "伊部駅", "岡山県", "JR赤穂線" ],
    [ "香登駅", "岡山県", "JR赤穂線" ],
    [ "長船駅", "岡山県", "JR赤穂線" ],
    [ "邑久駅", "岡山県", "JR赤穂線" ],
    [ "大富駅", "岡山県", "JR赤穂線" ],
    [ "西大寺駅", "岡山県", "JR赤穂線" ],
    [ "大多羅駅", "岡山県", "JR赤穂線" ],
    [ "東岡山駅", "岡山県", "JR赤穂線" ],
    [ "高島駅", "岡山県", "JR赤穂線" ],
    [ "西川原駅", "岡山県", "JR赤穂線" ],
    [ "岡山駅", "岡山県", "JR赤穂線" ],
    [ "加古川駅", "兵庫県", "JR加古川線" ],
    [ "日岡駅", "兵庫県", "JR加古川線" ],
    [ "神野駅", "兵庫県", "JR加古川線" ],
    [ "厄神駅", "兵庫県", "JR加古川線" ],
    [ "市場駅", "兵庫県", "JR加古川線" ],
    [ "小野町駅", "兵庫県", "JR加古川線" ],
    [ "粟生駅", "兵庫県", "JR加古川線" ],
    [ "河合西駅", "兵庫県", "JR加古川線" ],
    [ "青野ケ原駅", "兵庫県", "JR加古川線" ],
    [ "社町駅", "兵庫県", "JR加古川線" ],
    [ "滝野駅", "兵庫県", "JR加古川線" ],
    [ "滝駅", "兵庫県", "JR加古川線" ],
    [ "西脇市駅", "兵庫県", "JR加古川線" ],
    [ "新西脇駅", "兵庫県", "JR加古川線" ],
    [ "比延駅", "兵庫県", "JR加古川線" ],
    [ "日本へそ公園駅", "兵庫県", "JR加古川線" ],
    [ "黒田庄駅", "兵庫県", "JR加古川線" ],
    [ "本黒田駅", "兵庫県", "JR加古川線" ],
    [ "船町口駅", "兵庫県", "JR加古川線" ],
    [ "久下村駅", "兵庫県", "JR加古川線" ],
    [ "谷川駅", "兵庫県", "JR加古川線" ],
    [ "姫路駅", "兵庫県", "JR姫新線" ],
    [ "播磨高岡駅", "兵庫県", "JR姫新線" ],
    [ "余部駅", "兵庫県", "JR姫新線" ],
    [ "太市駅", "兵庫県", "JR姫新線" ],
    [ "本竜野駅", "兵庫県", "JR姫新線" ],
    [ "東觜崎駅", "兵庫県", "JR姫新線" ],
    [ "播磨新宮駅", "兵庫県", "JR姫新線" ],
    [ "千本駅", "兵庫県", "JR姫新線" ],
    [ "西栗栖駅", "兵庫県", "JR姫新線" ],
    [ "三日月駅", "兵庫県", "JR姫新線" ],
    [ "播磨徳久駅", "兵庫県", "JR姫新線" ],
    [ "佐用駅", "兵庫県", "JR姫新線" ],
    [ "佐用駅", "兵庫県", "JR姫新線" ],
    [ "上月駅", "兵庫県", "JR姫新線" ],
    [ "美作土居駅", "岡山県", "JR姫新線" ],
    [ "美作江見駅", "岡山県", "JR姫新線" ],
    [ "楢原駅", "岡山県", "JR姫新線" ],
    [ "林野駅", "岡山県", "JR姫新線" ],
    [ "勝間田駅", "岡山県", "JR姫新線" ],
    [ "西勝間田駅", "岡山県", "JR姫新線" ],
    [ "美作大崎駅", "岡山県", "JR姫新線" ],
    [ "東津山駅", "岡山県", "JR姫新線" ],
    [ "津山駅", "岡山県", "JR姫新線" ],
    [ "院庄駅", "岡山県", "JR姫新線" ],
    [ "美作千代駅", "岡山県", "JR姫新線" ],
    [ "坪井駅", "岡山県", "JR姫新線" ],
    [ "美作追分駅", "岡山県", "JR姫新線" ],
    [ "美作落合駅", "岡山県", "JR姫新線" ],
    [ "古見駅", "岡山県", "JR姫新線" ],
    [ "久世駅", "岡山県", "JR姫新線" ],
    [ "中国勝山駅", "岡山県", "JR姫新線" ],
    [ "月田駅", "岡山県", "JR姫新線" ],
    [ "富原駅", "岡山県", "JR姫新線" ],
    [ "刑部駅", "岡山県", "JR姫新線" ],
    [ "丹治部駅", "岡山県", "JR姫新線" ],
    [ "岩山駅", "岡山県", "JR姫新線" ],
    [ "新見駅", "岡山県", "JR姫新線" ],
    [ "姫路駅", "兵庫県", "JR播但線" ],
    [ "京口駅", "兵庫県", "JR播但線" ],
    [ "野里駅", "兵庫県", "JR播但線" ],
    [ "砥堀駅", "兵庫県", "JR播但線" ],
    [ "仁豊野駅", "兵庫県", "JR播但線" ],
    [ "香呂駅", "兵庫県", "JR播但線" ],
    [ "溝口駅", "兵庫県", "JR播但線" ],
    [ "福崎駅", "兵庫県", "JR播但線" ],
    [ "甘地駅", "兵庫県", "JR播但線" ],
    [ "鶴居駅", "兵庫県", "JR播但線" ],
    [ "新野駅", "兵庫県", "JR播但線" ],
    [ "寺前駅", "兵庫県", "JR播但線" ],
    [ "長谷駅", "兵庫県", "JR播但線" ],
    [ "生野駅", "兵庫県", "JR播但線" ],
    [ "新井駅", "兵庫県", "JR播但線" ],
    [ "青倉駅", "兵庫県", "JR播但線" ],
    [ "竹田駅", "兵庫県", "JR播但線" ],
    [ "和田山駅", "兵庫県", "JR播但線" ],
    [ "王寺駅", "奈良県", "JR和歌山線" ],
    [ "畠田駅", "奈良県", "JR和歌山線" ],
    [ "志都美駅", "奈良県", "JR和歌山線" ],
    [ "香芝駅", "奈良県", "JR和歌山線" ],
    [ "ＪＲ五位堂駅", "奈良県", "JR和歌山線" ],
    [ "高田駅", "奈良県", "JR和歌山線" ],
    [ "大和新庄駅", "奈良県", "JR和歌山線" ],
    [ "御所駅", "奈良県", "JR和歌山線" ],
    [ "玉手駅", "奈良県", "JR和歌山線" ],
    [ "掖上駅", "奈良県", "JR和歌山線" ],
    [ "吉野口駅", "奈良県", "JR和歌山線" ],
    [ "北宇智駅", "奈良県", "JR和歌山線" ],
    [ "五条駅", "奈良県", "JR和歌山線" ],
    [ "大和二見駅", "奈良県", "JR和歌山線" ],
    [ "隅田駅", "和歌山県", "JR和歌山線" ],
    [ "下兵庫駅", "和歌山県", "JR和歌山線" ],
    [ "橋本駅", "和歌山県", "JR和歌山線" ],
    [ "紀伊山田駅", "和歌山県", "JR和歌山線" ],
    [ "高野口駅", "和歌山県", "JR和歌山線" ],
    [ "中飯降駅", "和歌山県", "JR和歌山線" ],
    [ "妙寺駅", "和歌山県", "JR和歌山線" ],
    [ "大谷駅", "和歌山県", "JR和歌山線" ],
    [ "笠田駅", "和歌山県", "JR和歌山線" ],
    [ "西笠田駅", "和歌山県", "JR和歌山線" ],
    [ "名手駅", "和歌山県", "JR和歌山線" ],
    [ "粉河駅", "和歌山県", "JR和歌山線" ],
    [ "紀伊長田駅", "和歌山県", "JR和歌山線" ],
    [ "打田駅", "和歌山県", "JR和歌山線" ],
    [ "下井阪駅", "和歌山県", "JR和歌山線" ],
    [ "岩出駅", "和歌山県", "JR和歌山線" ],
    [ "船戸駅", "和歌山県", "JR和歌山線" ],
    [ "紀伊小倉駅", "和歌山県", "JR和歌山線" ],
    [ "布施屋駅", "和歌山県", "JR和歌山線" ],
    [ "千旦駅", "和歌山県", "JR和歌山線" ],
    [ "田井ノ瀬駅", "和歌山県", "JR和歌山線" ],
    [ "和歌山駅", "和歌山県", "JR和歌山線" ],
    [ "奈良駅", "奈良県", "万葉まほろば線" ],
    [ "京終駅", "奈良県", "万葉まほろば線" ],
    [ "帯解駅", "奈良県", "万葉まほろば線" ],
    [ "櫟本駅", "奈良県", "万葉まほろば線" ],
    [ "天理駅", "奈良県", "万葉まほろば線" ],
    [ "長柄駅", "奈良県", "万葉まほろば線" ],
    [ "柳本駅", "奈良県", "万葉まほろば線" ],
    [ "巻向駅", "奈良県", "万葉まほろば線" ],
    [ "三輪駅", "奈良県", "万葉まほろば線" ],
    [ "桜井駅", "奈良県", "万葉まほろば線" ],
    [ "香久山駅", "奈良県", "万葉まほろば線" ],
    [ "畝傍駅", "奈良県", "万葉まほろば線" ],
    [ "金橋駅", "奈良県", "万葉まほろば線" ],
    [ "高田駅", "奈良県", "万葉まほろば線" ],
    [ "新宮駅", "和歌山県", "きのくに線" ],
    [ "三輪崎駅", "和歌山県", "きのくに線" ],
    [ "紀伊佐野駅", "和歌山県", "きのくに線" ],
    [ "宇久井駅", "和歌山県", "きのくに線" ],
    [ "那智駅", "和歌山県", "きのくに線" ],
    [ "紀伊天満駅", "和歌山県", "きのくに線" ],
    [ "紀伊勝浦駅", "和歌山県", "きのくに線" ],
    [ "湯川駅", "和歌山県", "きのくに線" ],
    [ "太地駅", "和歌山県", "きのくに線" ],
    [ "下里駅", "和歌山県", "きのくに線" ],
    [ "紀伊浦神駅", "和歌山県", "きのくに線" ],
    [ "紀伊田原駅", "和歌山県", "きのくに線" ],
    [ "古座駅", "和歌山県", "きのくに線" ],
    [ "紀伊姫駅", "和歌山県", "きのくに線" ],
    [ "串本駅", "和歌山県", "きのくに線" ],
    [ "紀伊有田駅", "和歌山県", "きのくに線" ],
    [ "田並駅", "和歌山県", "きのくに線" ],
    [ "田子駅", "和歌山県", "きのくに線" ],
    [ "和深駅", "和歌山県", "きのくに線" ],
    [ "江住駅", "和歌山県", "きのくに線" ],
    [ "見老津駅", "和歌山県", "きのくに線" ],
    [ "周参見駅", "和歌山県", "きのくに線" ],
    [ "紀伊日置駅", "和歌山県", "きのくに線" ],
    [ "椿駅", "和歌山県", "きのくに線" ],
    [ "紀伊富田駅", "和歌山県", "きのくに線" ],
    [ "白浜駅", "和歌山県", "きのくに線" ],
    [ "朝来駅", "和歌山県", "きのくに線" ],
    [ "紀伊新庄駅", "和歌山県", "きのくに線" ],
    [ "紀伊田辺駅", "和歌山県", "きのくに線" ],
    [ "芳養駅", "和歌山県", "きのくに線" ],
    [ "南部駅", "和歌山県", "きのくに線" ],
    [ "岩代駅", "和歌山県", "きのくに線" ],
    [ "切目駅", "和歌山県", "きのくに線" ],
    [ "印南駅", "和歌山県", "きのくに線" ],
    [ "稲原駅", "和歌山県", "きのくに線" ],
    [ "和佐駅", "和歌山県", "きのくに線" ],
    [ "道成寺駅", "和歌山県", "きのくに線" ],
    [ "御坊駅", "和歌山県", "きのくに線" ],
    [ "紀伊内原駅", "和歌山県", "きのくに線" ],
    [ "紀伊由良駅", "和歌山県", "きのくに線" ],
    [ "広川ビーチ駅", "和歌山県", "きのくに線" ],
    [ "湯浅駅", "和歌山県", "きのくに線" ],
    [ "藤並駅", "和歌山県", "きのくに線" ],
    [ "紀伊宮原駅", "和歌山県", "きのくに線" ],
    [ "箕島駅", "和歌山県", "きのくに線" ],
    [ "初島駅", "和歌山県", "きのくに線" ],
    [ "下津駅", "和歌山県", "きのくに線" ],
    [ "加茂郷駅", "和歌山県", "きのくに線" ],
    [ "冷水浦駅", "和歌山県", "きのくに線" ],
    [ "海南駅", "和歌山県", "きのくに線" ],
    [ "黒江駅", "和歌山県", "きのくに線" ],
    [ "紀三井寺駅", "和歌山県", "きのくに線" ],
    [ "宮前駅", "和歌山県", "きのくに線" ],
    [ "和歌山駅", "和歌山県", "きのくに線" ],
    [ "和歌山駅", "和歌山県", "紀勢本線" ],
    [ "紀和駅", "和歌山県", "紀勢本線" ],
    [ "和歌山市駅", "和歌山県", "紀勢本線" ],
    [ "大阪駅", "大阪府", "おおさか東線" ],
    [ "新大阪駅", "大阪府", "おおさか東線" ],
    [ "南吹田駅", "大阪府", "おおさか東線" ],
    [ "JR淡路駅", "大阪府", "おおさか東線" ],
    [ "城北公園通駅", "大阪府", "おおさか東線" ],
    [ "JR野江駅", "大阪府", "おおさか東線" ],
    [ "鴫野駅", "大阪府", "おおさか東線" ],
    [ "放出駅", "大阪府", "おおさか東線" ],
    [ "高井田中央駅", "大阪府", "おおさか東線" ],
    [ "ＪＲ河内永和駅", "大阪府", "おおさか東線" ],
    [ "ＪＲ俊徳道駅", "大阪府", "おおさか東線" ],
    [ "ＪＲ長瀬駅", "大阪府", "おおさか東線" ],
    [ "衣摺加美北駅", "大阪府", "おおさか東線" ],
    [ "新加美駅", "大阪府", "おおさか東線" ],
    [ "久宝寺駅", "大阪府", "おおさか東線" ],
    [ "米子駅", "鳥取県", "JR山陰本線" ],
    [ "安来駅", "島根県", "JR山陰本線" ],
    [ "荒島駅", "島根県", "JR山陰本線" ],
    [ "揖屋駅", "島根県", "JR山陰本線" ],
    [ "東松江駅", "島根県", "JR山陰本線" ],
    [ "松江駅", "島根県", "JR山陰本線" ],
    [ "乃木駅", "島根県", "JR山陰本線" ],
    [ "玉造温泉駅", "島根県", "JR山陰本線" ],
    [ "来待駅", "島根県", "JR山陰本線" ],
    [ "宍道駅", "島根県", "JR山陰本線" ],
    [ "荘原駅", "島根県", "JR山陰本線" ],
    [ "直江駅", "島根県", "JR山陰本線" ],
    [ "出雲市駅", "島根県", "JR山陰本線" ],
    [ "西出雲駅", "島根県", "JR山陰本線" ],
    [ "出雲神西駅", "島根県", "JR山陰本線" ],
    [ "江南駅", "島根県", "JR山陰本線" ],
    [ "小田駅", "島根県", "JR山陰本線" ],
    [ "田儀駅", "島根県", "JR山陰本線" ],
    [ "波根駅", "島根県", "JR山陰本線" ],
    [ "久手駅", "島根県", "JR山陰本線" ],
    [ "大田市駅", "島根県", "JR山陰本線" ],
    [ "静間駅", "島根県", "JR山陰本線" ],
    [ "五十猛駅", "島根県", "JR山陰本線" ],
    [ "仁万駅", "島根県", "JR山陰本線" ],
    [ "馬路駅", "島根県", "JR山陰本線" ],
    [ "湯里駅", "島根県", "JR山陰本線" ],
    [ "温泉津駅", "島根県", "JR山陰本線" ],
    [ "石見福光駅", "島根県", "JR山陰本線" ],
    [ "黒松駅", "島根県", "JR山陰本線" ],
    [ "浅利駅", "島根県", "JR山陰本線" ],
    [ "江津駅", "島根県", "JR山陰本線" ],
    [ "都野津駅", "島根県", "JR山陰本線" ],
    [ "敬川駅", "島根県", "JR山陰本線" ],
    [ "波子駅", "島根県", "JR山陰本線" ],
    [ "久代駅", "島根県", "JR山陰本線" ],
    [ "下府駅", "島根県", "JR山陰本線" ],
    [ "浜田駅", "島根県", "JR山陰本線" ],
    [ "西浜田駅", "島根県", "JR山陰本線" ],
    [ "周布駅", "島根県", "JR山陰本線" ],
    [ "折居駅", "島根県", "JR山陰本線" ],
    [ "三保三隅駅", "島根県", "JR山陰本線" ],
    [ "岡見駅", "島根県", "JR山陰本線" ],
    [ "鎌手駅", "島根県", "JR山陰本線" ],
    [ "石見津田駅", "島根県", "JR山陰本線" ],
    [ "益田駅", "島根県", "JR山陰本線" ],
    [ "益田駅", "島根県", "JR山陰本線" ],
    [ "戸田小浜駅", "島根県", "JR山陰本線" ],
    [ "飯浦駅", "島根県", "JR山陰本線" ],
    [ "江崎駅", "山口県", "JR山陰本線" ],
    [ "須佐駅", "山口県", "JR山陰本線" ],
    [ "宇田郷駅", "山口県", "JR山陰本線" ],
    [ "木与駅", "山口県", "JR山陰本線" ],
    [ "奈古駅", "山口県", "JR山陰本線" ],
    [ "長門大井駅", "山口県", "JR山陰本線" ],
    [ "越ケ浜駅", "山口県", "JR山陰本線" ],
    [ "東萩駅", "山口県", "JR山陰本線" ],
    [ "萩駅", "山口県", "JR山陰本線" ],
    [ "玉江駅", "山口県", "JR山陰本線" ],
    [ "三見駅", "山口県", "JR山陰本線" ],
    [ "飯井駅", "山口県", "JR山陰本線" ],
    [ "長門三隅駅", "山口県", "JR山陰本線" ],
    [ "長門市駅", "山口県", "JR山陰本線" ],
    [ "黄波戸駅", "山口県", "JR山陰本線" ],
    [ "長門古市駅", "山口県", "JR山陰本線" ],
    [ "人丸駅", "山口県", "JR山陰本線" ],
    [ "伊上駅", "山口県", "JR山陰本線" ],
    [ "長門粟野駅", "山口県", "JR山陰本線" ],
    [ "阿川駅", "山口県", "JR山陰本線" ],
    [ "特牛駅", "山口県", "JR山陰本線" ],
    [ "滝部駅", "山口県", "JR山陰本線" ],
    [ "長門二見駅", "山口県", "JR山陰本線" ],
    [ "宇賀本郷駅", "山口県", "JR山陰本線" ],
    [ "湯玉駅", "山口県", "JR山陰本線" ],
    [ "小串駅", "山口県", "JR山陰本線" ],
    [ "川棚温泉駅", "山口県", "JR山陰本線" ],
    [ "黒井村駅", "山口県", "JR山陰本線" ],
    [ "梅ケ峠駅", "山口県", "JR山陰本線" ],
    [ "吉見駅", "山口県", "JR山陰本線" ],
    [ "福江駅", "山口県", "JR山陰本線" ],
    [ "安岡駅", "山口県", "JR山陰本線" ],
    [ "梶栗郷台地駅", "山口県", "JR山陰本線" ],
    [ "綾羅木駅", "山口県", "JR山陰本線" ],
    [ "幡生駅", "山口県", "JR山陰本線" ],
    [ "下関駅", "山口県", "JR山陰本線" ],
    [ "門司駅", "福岡県", "JR山陰本線" ],
    [ "岡山駅", "岡山県", "JR伯備線" ],
    [ "北長瀬駅", "岡山県", "JR伯備線" ],
    [ "庭瀬駅", "岡山県", "JR伯備線" ],
    [ "中庄駅", "岡山県", "JR伯備線" ],
    [ "倉敷駅", "岡山県", "JR伯備線" ],
    [ "清音駅", "岡山県", "JR伯備線" ],
    [ "総社駅", "岡山県", "JR伯備線" ],
    [ "豪渓駅", "岡山県", "JR伯備線" ],
    [ "日羽駅", "岡山県", "JR伯備線" ],
    [ "美袋駅", "岡山県", "JR伯備線" ],
    [ "備中広瀬駅", "岡山県", "JR伯備線" ],
    [ "備中高梁駅", "岡山県", "JR伯備線" ],
    [ "木野山駅", "岡山県", "JR伯備線" ],
    [ "備中川面駅", "岡山県", "JR伯備線" ],
    [ "方谷駅", "岡山県", "JR伯備線" ],
    [ "井倉駅", "岡山県", "JR伯備線" ],
    [ "石蟹駅", "岡山県", "JR伯備線" ],
    [ "新見駅", "岡山県", "JR伯備線" ],
    [ "備中神代駅", "岡山県", "JR伯備線" ],
    [ "足立駅", "岡山県", "JR伯備線" ],
    [ "新郷駅", "岡山県", "JR伯備線" ],
    [ "上石見駅", "鳥取県", "JR伯備線" ],
    [ "生山駅", "鳥取県", "JR伯備線" ],
    [ "上菅駅", "鳥取県", "JR伯備線" ],
    [ "黒坂駅", "鳥取県", "JR伯備線" ],
    [ "根雨駅", "鳥取県", "JR伯備線" ],
    [ "武庫駅", "鳥取県", "JR伯備線" ],
    [ "江尾駅", "鳥取県", "JR伯備線" ],
    [ "伯耆溝口駅", "鳥取県", "JR伯備線" ],
    [ "岸本駅", "鳥取県", "JR伯備線" ],
    [ "伯耆大山駅", "鳥取県", "JR伯備線" ],
    [ "東山公園駅", "鳥取県", "JR伯備線" ],
    [ "米子駅", "鳥取県", "JR伯備線" ],
    [ "鳥取駅", "鳥取県", "JR因美線" ],
    [ "津ノ井駅", "鳥取県", "JR因美線" ],
    [ "東郡家駅", "鳥取県", "JR因美線" ],
    [ "郡家駅", "鳥取県", "JR因美線" ],
    [ "河原駅", "鳥取県", "JR因美線" ],
    [ "国英駅", "鳥取県", "JR因美線" ],
    [ "鷹狩駅", "鳥取県", "JR因美線" ],
    [ "用瀬駅", "鳥取県", "JR因美線" ],
    [ "因幡社駅", "鳥取県", "JR因美線" ],
    [ "智頭駅", "鳥取県", "JR因美線" ],
    [ "土師駅", "鳥取県", "JR因美線" ],
    [ "那岐駅", "鳥取県", "JR因美線" ],
    [ "美作河井駅", "岡山県", "JR因美線" ],
    [ "知和駅", "岡山県", "JR因美線" ],
    [ "美作加茂駅", "岡山県", "JR因美線" ],
    [ "三浦駅", "岡山県", "JR因美線" ],
    [ "美作滝尾駅", "岡山県", "JR因美線" ],
    [ "高野駅", "岡山県", "JR因美線" ],
    [ "東津山駅", "岡山県", "JR因美線" ],
    [ "津山駅", "岡山県", "JR因美線" ],
    [ "米子駅", "鳥取県", "JR境線" ],
    [ "博労町駅", "鳥取県", "JR境線" ],
    [ "富士見町駅", "鳥取県", "JR境線" ],
    [ "後藤駅", "鳥取県", "JR境線" ],
    [ "三本松口駅", "鳥取県", "JR境線" ],
    [ "河崎口駅", "鳥取県", "JR境線" ],
    [ "弓ケ浜駅", "鳥取県", "JR境線" ],
    [ "和田浜駅", "鳥取県", "JR境線" ],
    [ "大篠津町駅", "鳥取県", "JR境線" ],
    [ "米子空港駅", "鳥取県", "JR境線" ],
    [ "中浜駅", "鳥取県", "JR境線" ],
    [ "高松町駅", "鳥取県", "JR境線" ],
    [ "余子駅", "鳥取県", "JR境線" ],
    [ "上道駅", "鳥取県", "JR境線" ],
    [ "馬場崎町駅", "鳥取県", "JR境線" ],
    [ "境港駅", "鳥取県", "JR境線" ],
    [ "宍道駅", "島根県", "JR木次線" ],
    [ "南宍道駅", "島根県", "JR木次線" ],
    [ "加茂中駅", "島根県", "JR木次線" ],
    [ "幡屋駅", "島根県", "JR木次線" ],
    [ "出雲大東駅", "島根県", "JR木次線" ],
    [ "南大東駅", "島根県", "JR木次線" ],
    [ "木次駅", "島根県", "JR木次線" ],
    [ "日登駅", "島根県", "JR木次線" ],
    [ "下久野駅", "島根県", "JR木次線" ],
    [ "出雲八代駅", "島根県", "JR木次線" ],
    [ "出雲三成駅", "島根県", "JR木次線" ],
    [ "亀嵩駅", "島根県", "JR木次線" ],
    [ "出雲横田駅", "島根県", "JR木次線" ],
    [ "八川駅", "島根県", "JR木次線" ],
    [ "出雲坂根駅", "島根県", "JR木次線" ],
    [ "三井野原駅", "島根県", "JR木次線" ],
    [ "油木駅", "広島県", "JR木次線" ],
    [ "備後落合駅", "広島県", "JR木次線" ],
    [ "江津駅", "島根県", "JR三江線" ],
    [ "江津本町駅", "島根県", "JR三江線" ],
    [ "千金駅", "島根県", "JR三江線" ],
    [ "川平駅", "島根県", "JR三江線" ],
    [ "川戸駅", "島根県", "JR三江線" ],
    [ "田津駅", "島根県", "JR三江線" ],
    [ "石見川越駅", "島根県", "JR三江線" ],
    [ "鹿賀駅", "島根県", "JR三江線" ],
    [ "因原駅", "島根県", "JR三江線" ],
    [ "石見川本駅", "島根県", "JR三江線" ],
    [ "木路原駅", "島根県", "JR三江線" ],
    [ "竹駅", "島根県", "JR三江線" ],
    [ "乙原駅", "島根県", "JR三江線" ],
    [ "石見簗瀬駅", "島根県", "JR三江線" ],
    [ "明塚駅", "島根県", "JR三江線" ],
    [ "粕淵駅", "島根県", "JR三江線" ],
    [ "浜原駅", "島根県", "JR三江線" ],
    [ "沢谷駅", "島根県", "JR三江線" ],
    [ "潮駅", "島根県", "JR三江線" ],
    [ "石見松原駅", "島根県", "JR三江線" ],
    [ "石見都賀駅", "島根県", "JR三江線" ],
    [ "宇都井駅", "島根県", "JR三江線" ],
    [ "伊賀和志駅", "広島県", "JR三江線" ],
    [ "口羽駅", "島根県", "JR三江線" ],
    [ "江平駅", "島根県", "JR三江線" ],
    [ "作木口駅", "島根県", "JR三江線" ],
    [ "香淀駅", "広島県", "JR三江線" ],
    [ "式敷駅", "広島県", "JR三江線" ],
    [ "信木駅", "広島県", "JR三江線" ],
    [ "所木駅", "広島県", "JR三江線" ],
    [ "船佐駅", "広島県", "JR三江線" ],
    [ "長谷駅", "広島県", "JR三江線" ],
    [ "粟屋駅", "広島県", "JR三江線" ],
    [ "尾関山駅", "広島県", "JR三江線" ],
    [ "3次駅", "広島県", "JR三江線" ],
    [ "新山口駅", "山口県", "JR山口線" ],
    [ "周防下郷駅", "山口県", "JR山口線" ],
    [ "上郷駅", "山口県", "JR山口線" ],
    [ "仁保津駅", "山口県", "JR山口線" ],
    [ "大歳駅", "山口県", "JR山口線" ],
    [ "矢原駅", "山口県", "JR山口線" ],
    [ "湯田温泉駅", "山口県", "JR山口線" ],
    [ "山口駅", "山口県", "JR山口線" ],
    [ "上山口駅", "山口県", "JR山口線" ],
    [ "宮野駅", "山口県", "JR山口線" ],
    [ "仁保駅", "山口県", "JR山口線" ],
    [ "篠目駅", "山口県", "JR山口線" ],
    [ "長門峡駅", "山口県", "JR山口線" ],
    [ "渡川駅", "山口県", "JR山口線" ],
    [ "三谷駅", "山口県", "JR山口線" ],
    [ "名草駅", "山口県", "JR山口線" ],
    [ "地福駅", "山口県", "JR山口線" ],
    [ "鍋倉駅", "山口県", "JR山口線" ],
    [ "徳佐駅", "山口県", "JR山口線" ],
    [ "船平山駅", "山口県", "JR山口線" ],
    [ "津和野駅", "島根県", "JR山口線" ],
    [ "青野山駅", "島根県", "JR山口線" ],
    [ "日原駅", "島根県", "JR山口線" ],
    [ "青原駅", "島根県", "JR山口線" ],
    [ "東青原駅", "島根県", "JR山口線" ],
    [ "石見横田駅", "島根県", "JR山口線" ],
    [ "本俣賀駅", "島根県", "JR山口線" ],
    [ "益田駅", "島根県", "JR山口線" ],
    [ "岡山駅", "岡山県", "JR宇野線" ],
    [ "大元駅", "岡山県", "JR宇野線" ],
    [ "備前西市駅", "岡山県", "JR宇野線" ],
    [ "妹尾駅", "岡山県", "JR宇野線" ],
    [ "備中箕島駅", "岡山県", "JR宇野線" ],
    [ "早島駅", "岡山県", "JR宇野線" ],
    [ "久々原駅", "岡山県", "JR宇野線" ],
    [ "茶屋町駅", "岡山県", "JR宇野線" ],
    [ "彦崎駅", "岡山県", "JR宇野線" ],
    [ "備前片岡駅", "岡山県", "JR宇野線" ],
    [ "迫川駅", "岡山県", "JR宇野線" ],
    [ "常山駅", "岡山県", "JR宇野線" ],
    [ "八浜駅", "岡山県", "JR宇野線" ],
    [ "備前田井駅", "岡山県", "JR宇野線" ],
    [ "宇野駅", "岡山県", "JR宇野線" ],
    [ "茶屋町駅", "岡山県", "瀬戸大橋線" ],
    [ "植松駅", "岡山県", "瀬戸大橋線" ],
    [ "木見駅", "岡山県", "瀬戸大橋線" ],
    [ "上の町駅", "岡山県", "瀬戸大橋線" ],
    [ "児島駅", "岡山県", "瀬戸大橋線" ],
    [ "宇多津駅", "香川県", "瀬戸大橋線" ],
    [ "岡山駅", "岡山県", "JR吉備線" ],
    [ "備前三門駅", "岡山県", "JR吉備線" ],
    [ "大安寺駅", "岡山県", "JR吉備線" ],
    [ "備前一宮駅", "岡山県", "JR吉備線" ],
    [ "吉備津駅", "岡山県", "JR吉備線" ],
    [ "備中高松駅", "岡山県", "JR吉備線" ],
    [ "足守駅", "岡山県", "JR吉備線" ],
    [ "服部駅", "岡山県", "JR吉備線" ],
    [ "東総社駅", "岡山県", "JR吉備線" ],
    [ "総社駅", "岡山県", "JR吉備線" ],
    [ "新見駅", "岡山県", "JR芸備線" ],
    [ "布原駅", "岡山県", "JR芸備線" ],
    [ "備中神代駅", "岡山県", "JR芸備線" ],
    [ "坂根駅", "岡山県", "JR芸備線" ],
    [ "市岡駅", "岡山県", "JR芸備線" ],
    [ "矢神駅", "岡山県", "JR芸備線" ],
    [ "野馳駅", "岡山県", "JR芸備線" ],
    [ "東城駅", "広島県", "JR芸備線" ],
    [ "備後八幡駅", "広島県", "JR芸備線" ],
    [ "内名駅", "広島県", "JR芸備線" ],
    [ "小奴可駅", "広島県", "JR芸備線" ],
    [ "道後山駅", "広島県", "JR芸備線" ],
    [ "備後落合駅", "広島県", "JR芸備線" ],
    [ "比婆山駅", "広島県", "JR芸備線" ],
    [ "備後西城駅", "広島県", "JR芸備線" ],
    [ "平子駅", "広島県", "JR芸備線" ],
    [ "高駅", "広島県", "JR芸備線" ],
    [ "備後庄原駅", "広島県", "JR芸備線" ],
    [ "備後三日市駅", "広島県", "JR芸備線" ],
    [ "七塚駅", "広島県", "JR芸備線" ],
    [ "山ノ内駅", "広島県", "JR芸備線" ],
    [ "下和知駅", "広島県", "JR芸備線" ],
    [ "塩町駅", "広島県", "JR芸備線" ],
    [ "神杉駅", "広島県", "JR芸備線" ],
    [ "八次駅", "広島県", "JR芸備線" ],
    [ "3次駅", "広島県", "JR芸備線" ],
    [ "西3次駅", "広島県", "JR芸備線" ],
    [ "志和地駅", "広島県", "JR芸備線" ],
    [ "上川立駅", "広島県", "JR芸備線" ],
    [ "甲立駅", "広島県", "JR芸備線" ],
    [ "吉田口駅", "広島県", "JR芸備線" ],
    [ "向原駅", "広島県", "JR芸備線" ],
    [ "井原市駅", "広島県", "JR芸備線" ],
    [ "志和口駅", "広島県", "JR芸備線" ],
    [ "上三田駅", "広島県", "JR芸備線" ],
    [ "中三田駅", "広島県", "JR芸備線" ],
    [ "白木山駅", "広島県", "JR芸備線" ],
    [ "狩留家駅", "広島県", "JR芸備線" ],
    [ "上深川駅", "広島県", "JR芸備線" ],
    [ "中深川駅", "広島県", "JR芸備線" ],
    [ "下深川駅", "広島県", "JR芸備線" ],
    [ "玖村駅", "広島県", "JR芸備線" ],
    [ "安芸矢口駅", "広島県", "JR芸備線" ],
    [ "戸坂駅", "広島県", "JR芸備線" ],
    [ "矢賀駅", "広島県", "JR芸備線" ],
    [ "広島駅", "広島県", "JR芸備線" ],
    [ "津山駅", "岡山県", "JR津山線" ],
    [ "津山口駅", "岡山県", "JR津山線" ],
    [ "佐良山駅", "岡山県", "JR津山線" ],
    [ "亀甲駅", "岡山県", "JR津山線" ],
    [ "小原駅", "岡山県", "JR津山線" ],
    [ "誕生寺駅", "岡山県", "JR津山線" ],
    [ "弓削駅", "岡山県", "JR津山線" ],
    [ "神目駅", "岡山県", "JR津山線" ],
    [ "福渡駅", "岡山県", "JR津山線" ],
    [ "建部駅", "岡山県", "JR津山線" ],
    [ "金川駅", "岡山県", "JR津山線" ],
    [ "野々口駅", "岡山県", "JR津山線" ],
    [ "牧山駅", "岡山県", "JR津山線" ],
    [ "玉柏駅", "岡山県", "JR津山線" ],
    [ "備前原駅", "岡山県", "JR津山線" ],
    [ "法界院駅", "岡山県", "JR津山線" ],
    [ "岡山駅", "岡山県", "JR津山線" ],
    [ "糸崎駅", "広島県", "JR呉線" ],
    [ "三原駅", "広島県", "JR呉線" ],
    [ "須波駅", "広島県", "JR呉線" ],
    [ "安芸幸崎駅", "広島県", "JR呉線" ],
    [ "忠海駅", "広島県", "JR呉線" ],
    [ "安芸長浜駅", "広島県", "JR呉線" ],
    [ "大乗駅", "広島県", "JR呉線" ],
    [ "竹原駅", "広島県", "JR呉線" ],
    [ "吉名駅", "広島県", "JR呉線" ],
    [ "安芸津駅", "広島県", "JR呉線" ],
    [ "風早駅", "広島県", "JR呉線" ],
    [ "安浦駅", "広島県", "JR呉線" ],
    [ "安登駅", "広島県", "JR呉線" ],
    [ "安芸川尻駅", "広島県", "JR呉線" ],
    [ "仁方駅", "広島県", "JR呉線" ],
    [ "広駅", "広島県", "JR呉線" ],
    [ "新広駅", "広島県", "JR呉線" ],
    [ "安芸阿賀駅", "広島県", "JR呉線" ],
    [ "呉駅", "広島県", "JR呉線" ],
    [ "川原石駅", "広島県", "JR呉線" ],
    [ "吉浦駅", "広島県", "JR呉線" ],
    [ "かるが浜駅", "広島県", "JR呉線" ],
    [ "天応駅", "広島県", "JR呉線" ],
    [ "呉ポートピア駅", "広島県", "JR呉線" ],
    [ "小屋浦駅", "広島県", "JR呉線" ],
    [ "水尻駅", "広島県", "JR呉線" ],
    [ "坂駅", "広島県", "JR呉線" ],
    [ "矢野駅", "広島県", "JR呉線" ],
    [ "海田市駅", "広島県", "JR呉線" ],
    [ "向洋駅", "広島県", "JR呉線" ],
    [ "天神川駅", "広島県", "JR呉線" ],
    [ "広島駅", "広島県", "JR呉線" ],
    [ "広島駅", "広島県", "JR可部線" ],
    [ "新白島駅", "広島県", "JR可部線" ],
    [ "横川駅", "広島県", "JR可部線" ],
    [ "三滝駅", "広島県", "JR可部線" ],
    [ "安芸長束駅", "広島県", "JR可部線" ],
    [ "下祇園駅", "広島県", "JR可部線" ],
    [ "古市橋駅", "広島県", "JR可部線" ],
    [ "大町駅", "広島県", "JR可部線" ],
    [ "緑井駅", "広島県", "JR可部線" ],
    [ "七軒茶屋駅", "広島県", "JR可部線" ],
    [ "梅林駅", "広島県", "JR可部線" ],
    [ "上八木駅", "広島県", "JR可部線" ],
    [ "中島駅", "広島県", "JR可部線" ],
    [ "可部駅", "広島県", "JR可部線" ],
    [ "河戸帆待川駅", "広島県", "JR可部線" ],
    [ "あき亀山駅", "広島県", "JR可部線" ],
    [ "福山駅", "広島県", "JR福塩線" ],
    [ "備後本庄駅", "広島県", "JR福塩線" ],
    [ "横尾駅", "広島県", "JR福塩線" ],
    [ "神辺駅", "広島県", "JR福塩線" ],
    [ "湯田村駅", "広島県", "JR福塩線" ],
    [ "道上駅", "広島県", "JR福塩線" ],
    [ "万能倉駅", "広島県", "JR福塩線" ],
    [ "駅家駅", "広島県", "JR福塩線" ],
    [ "近田駅", "広島県", "JR福塩線" ],
    [ "戸手駅", "広島県", "JR福塩線" ],
    [ "上戸手駅", "広島県", "JR福塩線" ],
    [ "新市駅", "広島県", "JR福塩線" ],
    [ "高木駅", "広島県", "JR福塩線" ],
    [ "鵜飼駅", "広島県", "JR福塩線" ],
    [ "府中駅", "広島県", "JR福塩線" ],
    [ "下川辺駅", "広島県", "JR福塩線" ],
    [ "中畑駅", "広島県", "JR福塩線" ],
    [ "河佐駅", "広島県", "JR福塩線" ],
    [ "備後三川駅", "広島県", "JR福塩線" ],
    [ "備後矢野駅", "広島県", "JR福塩線" ],
    [ "上下駅", "広島県", "JR福塩線" ],
    [ "甲奴駅", "広島県", "JR福塩線" ],
    [ "梶田駅", "広島県", "JR福塩線" ],
    [ "備後安田駅", "広島県", "JR福塩線" ],
    [ "吉舎駅", "広島県", "JR福塩線" ],
    [ "三良坂駅", "広島県", "JR福塩線" ],
    [ "塩町駅", "広島県", "JR福塩線" ],
    [ "神杉駅", "広島県", "JR福塩線" ],
    [ "八次駅", "広島県", "JR福塩線" ],
    [ "3次駅", "広島県", "JR福塩線" ],
    [ "新山口駅", "山口県", "JR宇部線" ],
    [ "上嘉川駅", "山口県", "JR宇部線" ],
    [ "深溝駅", "山口県", "JR宇部線" ],
    [ "周防佐山駅", "山口県", "JR宇部線" ],
    [ "岩倉駅", "山口県", "JR宇部線" ],
    [ "阿知須駅", "山口県", "JR宇部線" ],
    [ "岐波駅", "山口県", "JR宇部線" ],
    [ "丸尾駅", "山口県", "JR宇部線" ],
    [ "床波駅", "山口県", "JR宇部線" ],
    [ "常盤駅", "山口県", "JR宇部線" ],
    [ "草江駅", "山口県", "JR宇部線" ],
    [ "宇部岬駅", "山口県", "JR宇部線" ],
    [ "東新川駅", "山口県", "JR宇部線" ],
    [ "琴芝駅", "山口県", "JR宇部線" ],
    [ "宇部新川駅", "山口県", "JR宇部線" ],
    [ "居能駅", "山口県", "JR宇部線" ],
    [ "岩鼻駅", "山口県", "JR宇部線" ],
    [ "宇部駅", "山口県", "JR宇部線" ],
    [ "厚狭駅", "山口県", "JR美祢線" ],
    [ "湯ノ峠駅", "山口県", "JR美祢線" ],
    [ "厚保駅", "山口県", "JR美祢線" ],
    [ "四郎ケ原駅", "山口県", "JR美祢線" ],
    [ "南大嶺駅", "山口県", "JR美祢線" ],
    [ "美祢駅", "山口県", "JR美祢線" ],
    [ "重安駅", "山口県", "JR美祢線" ],
    [ "於福駅", "山口県", "JR美祢線" ],
    [ "渋木駅", "山口県", "JR美祢線" ],
    [ "長門湯本駅", "山口県", "JR美祢線" ],
    [ "板持駅", "山口県", "JR美祢線" ],
    [ "長門市駅", "山口県", "JR美祢線" ],
    [ "仙崎駅", "山口県", "JR美祢線" ],
    [ "宇部新川駅", "山口県", "JR小野田線" ],
    [ "居能駅", "山口県", "JR小野田線" ],
    [ "妻崎駅", "山口県", "JR小野田線" ],
    [ "長門長沢駅", "山口県", "JR小野田線" ],
    [ "雀田駅", "山口県", "JR小野田線" ],
    [ "小野田港駅", "山口県", "JR小野田線" ],
    [ "南小野田駅", "山口県", "JR小野田線" ],
    [ "南中川駅", "山口県", "JR小野田線" ],
    [ "目出駅", "山口県", "JR小野田線" ],
    [ "小野田駅", "山口県", "JR小野田線" ],
    [ "浜河内駅", "山口県", "JR小野田線" ],
    [ "長門本山駅", "山口県", "JR小野田線" ],
    [ "岩国駅", "山口県", "JR岩徳線" ],
    [ "西岩国駅", "山口県", "JR岩徳線" ],
    [ "川西駅", "山口県", "JR岩徳線" ],
    [ "柱野駅", "山口県", "JR岩徳線" ],
    [ "欽明路駅", "山口県", "JR岩徳線" ],
    [ "玖珂駅", "山口県", "JR岩徳線" ],
    [ "周防高森駅", "山口県", "JR岩徳線" ],
    [ "米川駅", "山口県", "JR岩徳線" ],
    [ "高水駅", "山口県", "JR岩徳線" ],
    [ "勝間駅", "山口県", "JR岩徳線" ],
    [ "大河内駅", "山口県", "JR岩徳線" ],
    [ "周防久保駅", "山口県", "JR岩徳線" ],
    [ "生野屋駅", "山口県", "JR岩徳線" ],
    [ "周防花岡駅", "山口県", "JR岩徳線" ],
    [ "櫛ケ浜駅", "山口県", "JR岩徳線" ],
    [ "徳山駅", "山口県", "JR岩徳線" ],
    [ "多度津駅", "香川県", "JR土讃線" ],
    [ "金蔵寺駅", "香川県", "JR土讃線" ],
    [ "善通寺駅", "香川県", "JR土讃線" ],
    [ "琴平駅", "香川県", "JR土讃線" ],
    [ "塩入駅", "香川県", "JR土讃線" ],
    [ "黒川駅", "香川県", "JR土讃線" ],
    [ "讃岐財田駅", "香川県", "JR土讃線" ],
    [ "坪尻駅", "徳島県", "JR土讃線" ],
    [ "箸蔵駅", "徳島県", "JR土讃線" ],
    [ "佃駅", "徳島県", "JR土讃線" ],
    [ "阿波池田駅", "徳島県", "JR土讃線" ],
    [ "三縄駅", "徳島県", "JR土讃線" ],
    [ "祖谷口駅", "徳島県", "JR土讃線" ],
    [ "阿波川口駅", "徳島県", "JR土讃線" ],
    [ "小歩危駅", "徳島県", "JR土讃線" ],
    [ "大歩危駅", "徳島県", "JR土讃線" ],
    [ "土佐岩原駅", "高知県", "JR土讃線" ],
    [ "豊永駅", "高知県", "JR土讃線" ],
    [ "大田口駅", "高知県", "JR土讃線" ],
    [ "土佐穴内駅", "高知県", "JR土讃線" ],
    [ "大杉駅", "高知県", "JR土讃線" ],
    [ "土佐北川駅", "高知県", "JR土讃線" ],
    [ "角茂谷駅", "高知県", "JR土讃線" ],
    [ "繁藤駅", "高知県", "JR土讃線" ],
    [ "新改駅", "高知県", "JR土讃線" ],
    [ "土佐山田駅", "高知県", "JR土讃線" ],
    [ "山田西町駅", "高知県", "JR土讃線" ],
    [ "土佐長岡駅", "高知県", "JR土讃線" ],
    [ "後免駅", "高知県", "JR土讃線" ],
    [ "土佐大津駅", "高知県", "JR土讃線" ],
    [ "布師田駅", "高知県", "JR土讃線" ],
    [ "土佐一宮駅", "高知県", "JR土讃線" ],
    [ "薊野駅", "高知県", "JR土讃線" ],
    [ "高知駅", "高知県", "JR土讃線" ],
    [ "入明駅", "高知県", "JR土讃線" ],
    [ "円行寺口駅", "高知県", "JR土讃線" ],
    [ "旭駅", "高知県", "JR土讃線" ],
    [ "高知商業前駅", "高知県", "JR土讃線" ],
    [ "朝倉駅", "高知県", "JR土讃線" ],
    [ "枝川駅", "高知県", "JR土讃線" ],
    [ "伊野駅", "高知県", "JR土讃線" ],
    [ "波川駅", "高知県", "JR土讃線" ],
    [ "小村神社前駅", "高知県", "JR土讃線" ],
    [ "日下駅", "高知県", "JR土讃線" ],
    [ "岡花駅", "高知県", "JR土讃線" ],
    [ "土佐加茂駅", "高知県", "JR土讃線" ],
    [ "西佐川駅", "高知県", "JR土讃線" ],
    [ "佐川駅", "高知県", "JR土讃線" ],
    [ "襟野々駅", "高知県", "JR土讃線" ],
    [ "斗賀野駅", "高知県", "JR土讃線" ],
    [ "吾桑駅", "高知県", "JR土讃線" ],
    [ "多ノ郷駅", "高知県", "JR土讃線" ],
    [ "大間駅", "高知県", "JR土讃線" ],
    [ "須崎駅", "高知県", "JR土讃線" ],
    [ "土佐新荘駅", "高知県", "JR土讃線" ],
    [ "安和駅", "高知県", "JR土讃線" ],
    [ "土佐久礼駅", "高知県", "JR土讃線" ],
    [ "影野駅", "高知県", "JR土讃線" ],
    [ "六反地駅", "高知県", "JR土讃線" ],
    [ "仁井田駅", "高知県", "JR土讃線" ],
    [ "窪川駅", "高知県", "JR土讃線" ],
    [ "高松駅", "香川県", "JR高徳線" ],
    [ "昭和町駅", "香川県", "JR高徳線" ],
    [ "栗林公園北口駅", "香川県", "JR高徳線" ],
    [ "栗林駅", "香川県", "JR高徳線" ],
    [ "木太町駅", "香川県", "JR高徳線" ],
    [ "屋島駅", "香川県", "JR高徳線" ],
    [ "古高松南駅", "香川県", "JR高徳線" ],
    [ "八栗口駅", "香川県", "JR高徳線" ],
    [ "讃岐牟礼駅", "香川県", "JR高徳線" ],
    [ "志度駅", "香川県", "JR高徳線" ],
    [ "オレンジタウン駅", "香川県", "JR高徳線" ],
    [ "造田駅", "香川県", "JR高徳線" ],
    [ "神前駅", "香川県", "JR高徳線" ],
    [ "讃岐津田駅", "香川県", "JR高徳線" ],
    [ "鶴羽駅", "香川県", "JR高徳線" ],
    [ "丹生駅", "香川県", "JR高徳線" ],
    [ "三本松駅", "香川県", "JR高徳線" ],
    [ "讃岐白鳥駅", "香川県", "JR高徳線" ],
    [ "引田駅", "香川県", "JR高徳線" ],
    [ "讃岐相生駅", "香川県", "JR高徳線" ],
    [ "阿波大宮駅", "徳島県", "JR高徳線" ],
    [ "板野駅", "徳島県", "JR高徳線" ],
    [ "阿波川端駅", "徳島県", "JR高徳線" ],
    [ "板東駅", "徳島県", "JR高徳線" ],
    [ "池谷駅", "徳島県", "JR高徳線" ],
    [ "勝瑞駅", "徳島県", "JR高徳線" ],
    [ "吉成駅", "徳島県", "JR高徳線" ],
    [ "佐古駅", "徳島県", "JR高徳線" ],
    [ "徳島駅", "徳島県", "JR高徳線" ],
    [ "徳島駅", "徳島県", "よしの川ブルーライン" ],
    [ "佐古駅", "徳島県", "よしの川ブルーライン" ],
    [ "蔵本駅", "徳島県", "よしの川ブルーライン" ],
    [ "鮎喰駅", "徳島県", "よしの川ブルーライン" ],
    [ "府中駅", "徳島県", "よしの川ブルーライン" ],
    [ "石井駅", "徳島県", "よしの川ブルーライン" ],
    [ "下浦駅", "徳島県", "よしの川ブルーライン" ],
    [ "牛島駅", "徳島県", "よしの川ブルーライン" ],
    [ "麻植塚駅", "徳島県", "よしの川ブルーライン" ],
    [ "鴨島駅", "徳島県", "よしの川ブルーライン" ],
    [ "西麻植駅", "徳島県", "よしの川ブルーライン" ],
    [ "阿波川島駅", "徳島県", "よしの川ブルーライン" ],
    [ "学駅", "徳島県", "よしの川ブルーライン" ],
    [ "山瀬駅", "徳島県", "よしの川ブルーライン" ],
    [ "阿波山川駅", "徳島県", "よしの川ブルーライン" ],
    [ "川田駅", "徳島県", "よしの川ブルーライン" ],
    [ "穴吹駅", "徳島県", "よしの川ブルーライン" ],
    [ "小島駅", "徳島県", "よしの川ブルーライン" ],
    [ "貞光駅", "徳島県", "よしの川ブルーライン" ],
    [ "阿波半田駅", "徳島県", "よしの川ブルーライン" ],
    [ "江口駅", "徳島県", "よしの川ブルーライン" ],
    [ "三加茂駅", "徳島県", "よしの川ブルーライン" ],
    [ "阿波加茂駅", "徳島県", "よしの川ブルーライン" ],
    [ "辻駅", "徳島県", "よしの川ブルーライン" ],
    [ "佃駅", "徳島県", "よしの川ブルーライン" ],
    [ "阿波池田駅", "徳島県", "よしの川ブルーライン" ],
    [ "徳島駅", "徳島県", "JR牟岐線" ],
    [ "阿波富田駅", "徳島県", "JR牟岐線" ],
    [ "二軒屋駅", "徳島県", "JR牟岐線" ],
    [ "文化の森駅", "徳島県", "JR牟岐線" ],
    [ "地蔵橋駅", "徳島県", "JR牟岐線" ],
    [ "中田駅", "徳島県", "JR牟岐線" ],
    [ "南小松島駅", "徳島県", "JR牟岐線" ],
    [ "阿波赤石駅", "徳島県", "JR牟岐線" ],
    [ "立江駅", "徳島県", "JR牟岐線" ],
    [ "羽ノ浦駅", "徳島県", "JR牟岐線" ],
    [ "西原駅", "徳島県", "JR牟岐線" ],
    [ "阿波中島駅", "徳島県", "JR牟岐線" ],
    [ "阿南駅", "徳島県", "JR牟岐線" ],
    [ "見能林駅", "徳島県", "JR牟岐線" ],
    [ "阿波橘駅", "徳島県", "JR牟岐線" ],
    [ "桑野駅", "徳島県", "JR牟岐線" ],
    [ "新野駅", "徳島県", "JR牟岐線" ],
    [ "阿波福井駅", "徳島県", "JR牟岐線" ],
    [ "由岐駅", "徳島県", "JR牟岐線" ],
    [ "田井ノ浜駅", "徳島県", "JR牟岐線" ],
    [ "木岐駅", "徳島県", "JR牟岐線" ],
    [ "北河内駅", "徳島県", "JR牟岐線" ],
    [ "日和佐駅", "徳島県", "JR牟岐線" ],
    [ "山河内駅", "徳島県", "JR牟岐線" ],
    [ "辺川駅", "徳島県", "JR牟岐線" ],
    [ "牟岐駅", "徳島県", "JR牟岐線" ],
    [ "鯖瀬駅", "徳島県", "JR牟岐線" ],
    [ "浅川駅", "徳島県", "JR牟岐線" ],
    [ "阿波海南駅", "徳島県", "JR牟岐線" ],
    [ "鳴門駅", "徳島県", "JR鳴門線" ],
    [ "撫養駅", "徳島県", "JR鳴門線" ],
    [ "金比羅前駅", "徳島県", "JR鳴門線" ],
    [ "教会前駅", "徳島県", "JR鳴門線" ],
    [ "立道駅", "徳島県", "JR鳴門線" ],
    [ "阿波大谷駅", "徳島県", "JR鳴門線" ],
    [ "池谷駅", "徳島県", "JR鳴門線" ],
    [ "高松駅", "香川県", "JR予讃線" ],
    [ "香西駅", "香川県", "JR予讃線" ],
    [ "鬼無駅", "香川県", "JR予讃線" ],
    [ "端岡駅", "香川県", "JR予讃線" ],
    [ "国分駅", "香川県", "JR予讃線" ],
    [ "讃岐府中駅", "香川県", "JR予讃線" ],
    [ "鴨川駅", "香川県", "JR予讃線" ],
    [ "八十場駅", "香川県", "JR予讃線" ],
    [ "坂出駅", "香川県", "JR予讃線" ],
    [ "宇多津駅", "香川県", "JR予讃線" ],
    [ "丸亀駅", "香川県", "JR予讃線" ],
    [ "讃岐塩屋駅", "香川県", "JR予讃線" ],
    [ "多度津駅", "香川県", "JR予讃線" ],
    [ "海岸寺駅", "香川県", "JR予讃線" ],
    [ "津島ノ宮駅", "香川県", "JR予讃線" ],
    [ "詫間駅", "香川県", "JR予讃線" ],
    [ "みの駅", "香川県", "JR予讃線" ],
    [ "高瀬駅", "香川県", "JR予讃線" ],
    [ "比地大駅", "香川県", "JR予讃線" ],
    [ "本山駅", "香川県", "JR予讃線" ],
    [ "観音寺駅", "香川県", "JR予讃線" ],
    [ "豊浜駅", "香川県", "JR予讃線" ],
    [ "箕浦駅", "香川県", "JR予讃線" ],
    [ "川之江駅", "愛媛県", "JR予讃線" ],
    [ "伊予三島駅", "愛媛県", "JR予讃線" ],
    [ "伊予寒川駅", "愛媛県", "JR予讃線" ],
    [ "赤星駅", "愛媛県", "JR予讃線" ],
    [ "伊予土居駅", "愛媛県", "JR予讃線" ],
    [ "関川駅", "愛媛県", "JR予讃線" ],
    [ "多喜浜駅", "愛媛県", "JR予讃線" ],
    [ "新居浜駅", "愛媛県", "JR予讃線" ],
    [ "中萩駅", "愛媛県", "JR予讃線" ],
    [ "伊予西条駅", "愛媛県", "JR予讃線" ],
    [ "石鎚山駅", "愛媛県", "JR予讃線" ],
    [ "伊予氷見駅", "愛媛県", "JR予讃線" ],
    [ "伊予小松駅", "愛媛県", "JR予讃線" ],
    [ "玉之江駅", "愛媛県", "JR予讃線" ],
    [ "壬生川駅", "愛媛県", "JR予讃線" ],
    [ "伊予三芳駅", "愛媛県", "JR予讃線" ],
    [ "伊予桜井駅", "愛媛県", "JR予讃線" ],
    [ "伊予富田駅", "愛媛県", "JR予讃線" ],
    [ "今治駅", "愛媛県", "JR予讃線" ],
    [ "波止浜駅", "愛媛県", "JR予讃線" ],
    [ "波方駅", "愛媛県", "JR予讃線" ],
    [ "大西駅", "愛媛県", "JR予讃線" ],
    [ "伊予亀岡駅", "愛媛県", "JR予讃線" ],
    [ "菊間駅", "愛媛県", "JR予讃線" ],
    [ "浅海駅", "愛媛県", "JR予讃線" ],
    [ "大浦駅", "愛媛県", "JR予讃線" ],
    [ "伊予北条駅", "愛媛県", "JR予讃線" ],
    [ "柳原駅", "愛媛県", "JR予讃線" ],
    [ "粟井駅", "愛媛県", "JR予讃線" ],
    [ "光洋台駅", "愛媛県", "JR予讃線" ],
    [ "堀江駅", "愛媛県", "JR予讃線" ],
    [ "伊予和気駅", "愛媛県", "JR予讃線" ],
    [ "三津浜駅", "愛媛県", "JR予讃線" ],
    [ "松山駅", "愛媛県", "JR予讃線" ],
    [ "松山駅", "愛媛県", "JR予讃・内子線" ],
    [ "市坪駅", "愛媛県", "JR予讃・内子線" ],
    [ "北伊予駅", "愛媛県", "JR予讃・内子線" ],
    [ "南伊予駅", "愛媛県", "JR予讃・内子線" ],
    [ "伊予横田駅", "愛媛県", "JR予讃・内子線" ],
    [ "鳥ノ木駅", "愛媛県", "JR予讃・内子線" ],
    [ "伊予市駅", "愛媛県", "JR予讃・内子線" ],
    [ "向井原駅", "愛媛県", "JR予讃・内子線" ],
    [ "伊予大平駅", "愛媛県", "JR予讃・内子線" ],
    [ "伊予中山駅", "愛媛県", "JR予讃・内子線" ],
    [ "伊予立川駅", "愛媛県", "JR予讃・内子線" ],
    [ "内子駅", "愛媛県", "JR予讃・内子線" ],
    [ "五十崎駅", "愛媛県", "JR予讃・内子線" ],
    [ "喜多山駅", "愛媛県", "JR予讃・内子線" ],
    [ "新谷駅", "愛媛県", "JR予讃・内子線" ],
    [ "高野川駅", "愛媛県", "JR予讃・内子線" ],
    [ "伊予上灘駅", "愛媛県", "JR予讃・内子線" ],
    [ "下灘駅", "愛媛県", "JR予讃・内子線" ],
    [ "串駅", "愛媛県", "JR予讃・内子線" ],
    [ "喜多灘駅", "愛媛県", "JR予讃・内子線" ],
    [ "伊予長浜駅", "愛媛県", "JR予讃・内子線" ],
    [ "伊予出石駅", "愛媛県", "JR予讃・内子線" ],
    [ "伊予白滝駅", "愛媛県", "JR予讃・内子線" ],
    [ "八多喜駅", "愛媛県", "JR予讃・内子線" ],
    [ "春賀駅", "愛媛県", "JR予讃・内子線" ],
    [ "五郎駅", "愛媛県", "JR予讃・内子線" ],
    [ "伊予大洲駅", "愛媛県", "JR予讃・内子線" ],
    [ "西大洲駅", "愛媛県", "JR予讃・内子線" ],
    [ "伊予平野駅", "愛媛県", "JR予讃・内子線" ],
    [ "千丈駅", "愛媛県", "JR予讃・内子線" ],
    [ "八幡浜駅", "愛媛県", "JR予讃・内子線" ],
    [ "双岩駅", "愛媛県", "JR予讃・内子線" ],
    [ "伊予石城駅", "愛媛県", "JR予讃・内子線" ],
    [ "上宇和駅", "愛媛県", "JR予讃・内子線" ],
    [ "卯之町駅", "愛媛県", "JR予讃・内子線" ],
    [ "下宇和駅", "愛媛県", "JR予讃・内子線" ],
    [ "立間駅", "愛媛県", "JR予讃・内子線" ],
    [ "伊予吉田駅", "愛媛県", "JR予讃・内子線" ],
    [ "高光駅", "愛媛県", "JR予讃・内子線" ],
    [ "北宇和島駅", "愛媛県", "JR予讃・内子線" ],
    [ "宇和島駅", "愛媛県", "JR予讃・内子線" ],
    [ "窪川駅", "高知県", "しまんとグリーンライン" ],
    [ "若井駅", "高知県", "しまんとグリーンライン" ],
    [ "家地川駅", "高知県", "しまんとグリーンライン" ],
    [ "打井川駅", "高知県", "しまんとグリーンライン" ],
    [ "土佐大正駅", "高知県", "しまんとグリーンライン" ],
    [ "土佐昭和駅", "高知県", "しまんとグリーンライン" ],
    [ "十川駅", "高知県", "しまんとグリーンライン" ],
    [ "半家駅", "高知県", "しまんとグリーンライン" ],
    [ "江川崎駅", "高知県", "しまんとグリーンライン" ],
    [ "西ケ方駅", "高知県", "しまんとグリーンライン" ],
    [ "真土駅", "愛媛県", "しまんとグリーンライン" ],
    [ "吉野生駅", "愛媛県", "しまんとグリーンライン" ],
    [ "松丸駅", "愛媛県", "しまんとグリーンライン" ],
    [ "出目駅", "愛媛県", "しまんとグリーンライン" ],
    [ "近永駅", "愛媛県", "しまんとグリーンライン" ],
    [ "深田駅", "愛媛県", "しまんとグリーンライン" ],
    [ "大内駅", "愛媛県", "しまんとグリーンライン" ],
    [ "二名駅", "愛媛県", "しまんとグリーンライン" ],
    [ "伊予宮野下駅", "愛媛県", "しまんとグリーンライン" ],
    [ "務田駅", "愛媛県", "しまんとグリーンライン" ],
    [ "北宇和島駅", "愛媛県", "しまんとグリーンライン" ],
    [ "宇和島駅", "愛媛県", "しまんとグリーンライン" ],
    [ "博多駅", "福岡県", "JR博多南線" ],
    [ "博多南駅", "福岡県", "JR博多南線" ],
    [ "下関駅", "山口県", "JR鹿児島本線" ],
    [ "門司港駅", "福岡県", "JR鹿児島本線" ],
    [ "小森江駅", "福岡県", "JR鹿児島本線" ],
    [ "門司駅", "福岡県", "JR鹿児島本線" ],
    [ "小倉駅", "福岡県", "JR鹿児島本線" ],
    [ "西小倉駅", "福岡県", "JR鹿児島本線" ],
    [ "九州工大前駅", "福岡県", "JR鹿児島本線" ],
    [ "戸畑駅", "福岡県", "JR鹿児島本線" ],
    [ "枝光駅", "福岡県", "JR鹿児島本線" ],
    [ "スペースワールド駅", "福岡県", "JR鹿児島本線" ],
    [ "八幡駅", "福岡県", "JR鹿児島本線" ],
    [ "黒崎駅", "福岡県", "JR鹿児島本線" ],
    [ "陣原駅", "福岡県", "JR鹿児島本線" ],
    [ "折尾駅", "福岡県", "JR鹿児島本線" ],
    [ "水巻駅", "福岡県", "JR鹿児島本線" ],
    [ "遠賀川駅", "福岡県", "JR鹿児島本線" ],
    [ "海老津駅", "福岡県", "JR鹿児島本線" ],
    [ "教育大前駅", "福岡県", "JR鹿児島本線" ],
    [ "赤間駅", "福岡県", "JR鹿児島本線" ],
    [ "東郷駅", "福岡県", "JR鹿児島本線" ],
    [ "東福間駅", "福岡県", "JR鹿児島本線" ],
    [ "福間駅", "福岡県", "JR鹿児島本線" ],
    [ "千鳥駅", "福岡県", "JR鹿児島本線" ],
    [ "古賀駅", "福岡県", "JR鹿児島本線" ],
    [ "ししぶ駅", "福岡県", "JR鹿児島本線" ],
    [ "新宮中央駅", "福岡県", "JR鹿児島本線" ],
    [ "福工大前駅", "福岡県", "JR鹿児島本線" ],
    [ "九産大前駅", "福岡県", "JR鹿児島本線" ],
    [ "香椎駅", "福岡県", "JR鹿児島本線" ],
    [ "千早駅", "福岡県", "JR鹿児島本線" ],
    [ "箱崎駅", "福岡県", "JR鹿児島本線" ],
    [ "吉塚駅", "福岡県", "JR鹿児島本線" ],
    [ "博多駅", "福岡県", "JR鹿児島本線" ],
    [ "博多駅", "福岡県", "JR鹿児島本線" ],
    [ "竹下駅", "福岡県", "JR鹿児島本線" ],
    [ "笹原駅", "福岡県", "JR鹿児島本線" ],
    [ "南福岡駅", "福岡県", "JR鹿児島本線" ],
    [ "春日駅", "福岡県", "JR鹿児島本線" ],
    [ "大野城駅", "福岡県", "JR鹿児島本線" ],
    [ "水城駅", "福岡県", "JR鹿児島本線" ],
    [ "都府楼南駅", "福岡県", "JR鹿児島本線" ],
    [ "二日市駅", "福岡県", "JR鹿児島本線" ],
    [ "天拝山駅", "福岡県", "JR鹿児島本線" ],
    [ "原田駅", "福岡県", "JR鹿児島本線" ],
    [ "けやき台駅", "佐賀県", "JR鹿児島本線" ],
    [ "基山駅", "佐賀県", "JR鹿児島本線" ],
    [ "弥生が丘駅", "佐賀県", "JR鹿児島本線" ],
    [ "田代駅", "佐賀県", "JR鹿児島本線" ],
    [ "鳥栖駅", "佐賀県", "JR鹿児島本線" ],
    [ "肥前旭駅", "佐賀県", "JR鹿児島本線" ],
    [ "久留米駅", "福岡県", "JR鹿児島本線" ],
    [ "荒木駅", "福岡県", "JR鹿児島本線" ],
    [ "西牟田駅", "福岡県", "JR鹿児島本線" ],
    [ "羽犬塚駅", "福岡県", "JR鹿児島本線" ],
    [ "筑後船小屋駅", "福岡県", "JR鹿児島本線" ],
    [ "瀬高駅", "福岡県", "JR鹿児島本線" ],
    [ "南瀬高駅", "福岡県", "JR鹿児島本線" ],
    [ "渡瀬駅", "福岡県", "JR鹿児島本線" ],
    [ "吉野駅", "福岡県", "JR鹿児島本線" ],
    [ "銀水駅", "福岡県", "JR鹿児島本線" ],
    [ "大牟田駅", "福岡県", "JR鹿児島本線" ],
    [ "荒尾駅", "熊本県", "JR鹿児島本線" ],
    [ "南荒尾駅", "熊本県", "JR鹿児島本線" ],
    [ "長洲駅", "熊本県", "JR鹿児島本線" ],
    [ "大野下駅", "熊本県", "JR鹿児島本線" ],
    [ "玉名駅", "熊本県", "JR鹿児島本線" ],
    [ "肥後伊倉駅", "熊本県", "JR鹿児島本線" ],
    [ "木葉駅", "熊本県", "JR鹿児島本線" ],
    [ "田原坂駅", "熊本県", "JR鹿児島本線" ],
    [ "植木駅", "熊本県", "JR鹿児島本線" ],
    [ "西里駅", "熊本県", "JR鹿児島本線" ],
    [ "崇城大学前駅", "熊本県", "JR鹿児島本線" ],
    [ "上熊本駅", "熊本県", "JR鹿児島本線" ],
    [ "熊本駅", "熊本県", "JR鹿児島本線" ],
    [ "西熊本駅", "熊本県", "JR鹿児島本線" ],
    [ "川尻駅", "熊本県", "JR鹿児島本線" ],
    [ "富合駅", "熊本県", "JR鹿児島本線" ],
    [ "宇土駅", "熊本県", "JR鹿児島本線" ],
    [ "松橋駅", "熊本県", "JR鹿児島本線" ],
    [ "小川駅", "熊本県", "JR鹿児島本線" ],
    [ "有佐駅", "熊本県", "JR鹿児島本線" ],
    [ "千丁駅", "熊本県", "JR鹿児島本線" ],
    [ "新八代駅", "熊本県", "JR鹿児島本線" ],
    [ "八代駅", "熊本県", "JR鹿児島本線" ],
    [ "川内駅", "鹿児島県", "JR鹿児島本線" ],
    [ "隈之城駅", "鹿児島県", "JR鹿児島本線" ],
    [ "木場茶屋駅", "鹿児島県", "JR鹿児島本線" ],
    [ "串木野駅", "鹿児島県", "JR鹿児島本線" ],
    [ "神村学園前駅", "鹿児島県", "JR鹿児島本線" ],
    [ "市来駅", "鹿児島県", "JR鹿児島本線" ],
    [ "湯之元駅", "鹿児島県", "JR鹿児島本線" ],
    [ "東市来駅", "鹿児島県", "JR鹿児島本線" ],
    [ "伊集院駅", "鹿児島県", "JR鹿児島本線" ],
    [ "薩摩松元駅", "鹿児島県", "JR鹿児島本線" ],
    [ "上伊集院駅", "鹿児島県", "JR鹿児島本線" ],
    [ "広木駅", "鹿児島県", "JR鹿児島本線" ],
    [ "鹿児島中央駅", "鹿児島県", "JR鹿児島本線" ],
    [ "鹿児島駅", "鹿児島県", "JR鹿児島本線" ],
    [ "鳥栖駅", "佐賀県", "JR長崎本線" ],
    [ "新鳥栖駅", "佐賀県", "JR長崎本線" ],
    [ "肥前麓駅", "佐賀県", "JR長崎本線" ],
    [ "中原駅", "佐賀県", "JR長崎本線" ],
    [ "吉野ケ里公園駅", "佐賀県", "JR長崎本線" ],
    [ "神埼駅", "佐賀県", "JR長崎本線" ],
    [ "伊賀屋駅", "佐賀県", "JR長崎本線" ],
    [ "佐賀駅", "佐賀県", "JR長崎本線" ],
    [ "鍋島駅", "佐賀県", "JR長崎本線" ],
    [ "バルーンさが駅", "佐賀県", "JR長崎本線" ],
    [ "久保田駅", "佐賀県", "JR長崎本線" ],
    [ "牛津駅", "佐賀県", "JR長崎本線" ],
    [ "江北駅", "佐賀県", "JR長崎本線" ],
    [ "肥前白石駅", "佐賀県", "JR長崎本線" ],
    [ "肥前竜王駅", "佐賀県", "JR長崎本線" ],
    [ "肥前鹿島駅", "佐賀県", "JR長崎本線" ],
    [ "肥前浜駅", "佐賀県", "JR長崎本線" ],
    [ "肥前七浦駅", "佐賀県", "JR長崎本線" ],
    [ "肥前飯田駅", "佐賀県", "JR長崎本線" ],
    [ "多良駅", "佐賀県", "JR長崎本線" ],
    [ "肥前大浦駅", "佐賀県", "JR長崎本線" ],
    [ "小長井駅", "長崎県", "JR長崎本線" ],
    [ "長里駅", "長崎県", "JR長崎本線" ],
    [ "湯江駅", "長崎県", "JR長崎本線" ],
    [ "小江駅", "長崎県", "JR長崎本線" ],
    [ "肥前長田駅", "長崎県", "JR長崎本線" ],
    [ "東諫早駅", "長崎県", "JR長崎本線" ],
    [ "諫早駅", "長崎県", "JR長崎本線" ],
    [ "西諫早駅", "長崎県", "JR長崎本線" ],
    [ "喜々津駅", "長崎県", "JR長崎本線" ],
    [ "市布駅", "長崎県", "JR長崎本線" ],
    [ "肥前古賀駅", "長崎県", "JR長崎本線" ],
    [ "現川駅", "長崎県", "JR長崎本線" ],
    [ "東園駅", "長崎県", "JR長崎本線" ],
    [ "大草駅", "長崎県", "JR長崎本線" ],
    [ "本川内駅", "長崎県", "JR長崎本線" ],
    [ "長与駅", "長崎県", "JR長崎本線" ],
    [ "高田駅", "長崎県", "JR長崎本線" ],
    [ "道ノ尾駅", "長崎県", "JR長崎本線" ],
    [ "西浦上駅", "長崎県", "JR長崎本線" ],
    [ "浦上駅", "長崎県", "JR長崎本線" ],
    [ "長崎駅", "長崎県", "JR長崎本線" ],
    [ "門司港駅", "福岡県", "JR日豊本線" ],
    [ "小森江駅", "福岡県", "JR日豊本線" ],
    [ "門司駅", "福岡県", "JR日豊本線" ],
    [ "小倉駅", "福岡県", "JR日豊本線" ],
    [ "西小倉駅", "福岡県", "JR日豊本線" ],
    [ "南小倉駅", "福岡県", "JR日豊本線" ],
    [ "城野駅", "福岡県", "JR日豊本線" ],
    [ "安部山公園駅", "福岡県", "JR日豊本線" ],
    [ "下曽根駅", "福岡県", "JR日豊本線" ],
    [ "朽網駅", "福岡県", "JR日豊本線" ],
    [ "苅田駅", "福岡県", "JR日豊本線" ],
    [ "小波瀬西工大前駅", "福岡県", "JR日豊本線" ],
    [ "行橋駅", "福岡県", "JR日豊本線" ],
    [ "南行橋駅", "福岡県", "JR日豊本線" ],
    [ "新田原駅", "福岡県", "JR日豊本線" ],
    [ "築城駅", "福岡県", "JR日豊本線" ],
    [ "椎田駅", "福岡県", "JR日豊本線" ],
    [ "豊前松江駅", "福岡県", "JR日豊本線" ],
    [ "宇島駅", "福岡県", "JR日豊本線" ],
    [ "三毛門駅", "福岡県", "JR日豊本線" ],
    [ "吉富駅", "福岡県", "JR日豊本線" ],
    [ "中津駅", "大分県", "JR日豊本線" ],
    [ "東中津駅", "大分県", "JR日豊本線" ],
    [ "今津駅", "大分県", "JR日豊本線" ],
    [ "天津駅", "大分県", "JR日豊本線" ],
    [ "豊前善光寺駅", "大分県", "JR日豊本線" ],
    [ "柳ケ浦駅", "大分県", "JR日豊本線" ],
    [ "豊前長洲駅", "大分県", "JR日豊本線" ],
    [ "宇佐駅", "大分県", "JR日豊本線" ],
    [ "西屋敷駅", "大分県", "JR日豊本線" ],
    [ "立石駅", "大分県", "JR日豊本線" ],
    [ "中山香駅", "大分県", "JR日豊本線" ],
    [ "杵築駅", "大分県", "JR日豊本線" ],
    [ "大神駅", "大分県", "JR日豊本線" ],
    [ "日出駅", "大分県", "JR日豊本線" ],
    [ "暘谷駅", "大分県", "JR日豊本線" ],
    [ "豊後豊岡駅", "大分県", "JR日豊本線" ],
    [ "亀川駅", "大分県", "JR日豊本線" ],
    [ "別府大学駅", "大分県", "JR日豊本線" ],
    [ "別府駅", "大分県", "JR日豊本線" ],
    [ "東別府駅", "大分県", "JR日豊本線" ],
    [ "西大分駅", "大分県", "JR日豊本線" ],
    [ "大分駅", "大分県", "JR日豊本線" ],
    [ "牧駅", "大分県", "JR日豊本線" ],
    [ "高城駅", "大分県", "JR日豊本線" ],
    [ "鶴崎駅", "大分県", "JR日豊本線" ],
    [ "大在駅", "大分県", "JR日豊本線" ],
    [ "坂ノ市駅", "大分県", "JR日豊本線" ],
    [ "幸崎駅", "大分県", "JR日豊本線" ],
    [ "佐志生駅", "大分県", "JR日豊本線" ],
    [ "下ノ江駅", "大分県", "JR日豊本線" ],
    [ "熊崎駅", "大分県", "JR日豊本線" ],
    [ "上臼杵駅", "大分県", "JR日豊本線" ],
    [ "臼杵駅", "大分県", "JR日豊本線" ],
    [ "津久見駅", "大分県", "JR日豊本線" ],
    [ "日代駅", "大分県", "JR日豊本線" ],
    [ "浅海井駅", "大分県", "JR日豊本線" ],
    [ "狩生駅", "大分県", "JR日豊本線" ],
    [ "海崎駅", "大分県", "JR日豊本線" ],
    [ "佐伯駅", "大分県", "JR日豊本線" ],
    [ "佐伯駅", "大分県", "JR日豊本線" ],
    [ "上岡駅", "大分県", "JR日豊本線" ],
    [ "直見駅", "大分県", "JR日豊本線" ],
    [ "直川駅", "大分県", "JR日豊本線" ],
    [ "重岡駅", "大分県", "JR日豊本線" ],
    [ "宗太郎駅", "大分県", "JR日豊本線" ],
    [ "市棚駅", "宮崎県", "JR日豊本線" ],
    [ "北川駅", "宮崎県", "JR日豊本線" ],
    [ "日向長井駅", "宮崎県", "JR日豊本線" ],
    [ "北延岡駅", "宮崎県", "JR日豊本線" ],
    [ "延岡駅", "宮崎県", "JR日豊本線" ],
    [ "南延岡駅", "宮崎県", "JR日豊本線" ],
    [ "旭ケ丘駅", "宮崎県", "JR日豊本線" ],
    [ "土々呂駅", "宮崎県", "JR日豊本線" ],
    [ "門川駅", "宮崎県", "JR日豊本線" ],
    [ "日向市駅", "宮崎県", "JR日豊本線" ],
    [ "財光寺駅", "宮崎県", "JR日豊本線" ],
    [ "南日向駅", "宮崎県", "JR日豊本線" ],
    [ "美々津駅", "宮崎県", "JR日豊本線" ],
    [ "東都農駅", "宮崎県", "JR日豊本線" ],
    [ "都農駅", "宮崎県", "JR日豊本線" ],
    [ "川南駅", "宮崎県", "JR日豊本線" ],
    [ "高鍋駅", "宮崎県", "JR日豊本線" ],
    [ "日向新富駅", "宮崎県", "JR日豊本線" ],
    [ "佐土原駅", "宮崎県", "JR日豊本線" ],
    [ "日向住吉駅", "宮崎県", "JR日豊本線" ],
    [ "蓮ケ池駅", "宮崎県", "JR日豊本線" ],
    [ "宮崎神宮駅", "宮崎県", "JR日豊本線" ],
    [ "宮崎駅", "宮崎県", "JR日豊本線" ],
    [ "南宮崎駅", "宮崎県", "JR日豊本線" ],
    [ "加納駅", "宮崎県", "JR日豊本線" ],
    [ "清武駅", "宮崎県", "JR日豊本線" ],
    [ "日向沓掛駅", "宮崎県", "JR日豊本線" ],
    [ "田野駅", "宮崎県", "JR日豊本線" ],
    [ "青井岳駅", "宮崎県", "JR日豊本線" ],
    [ "山之口駅", "宮崎県", "JR日豊本線" ],
    [ "餅原駅", "宮崎県", "JR日豊本線" ],
    [ "三股駅", "宮崎県", "JR日豊本線" ],
    [ "都城駅", "宮崎県", "JR日豊本線" ],
    [ "西都城駅", "宮崎県", "JR日豊本線" ],
    [ "五十市駅", "宮崎県", "JR日豊本線" ],
    [ "財部駅", "鹿児島県", "JR日豊本線" ],
    [ "北俣駅", "鹿児島県", "JR日豊本線" ],
    [ "大隅大川原駅", "鹿児島県", "JR日豊本線" ],
    [ "北永野田駅", "鹿児島県", "JR日豊本線" ],
    [ "霧島神宮駅", "鹿児島県", "JR日豊本線" ],
    [ "国分駅", "鹿児島県", "JR日豊本線" ],
    [ "隼人駅", "鹿児島県", "JR日豊本線" ],
    [ "加治木駅", "鹿児島県", "JR日豊本線" ],
    [ "錦江駅", "鹿児島県", "JR日豊本線" ],
    [ "帖佐駅", "鹿児島県", "JR日豊本線" ],
    [ "姶良駅", "鹿児島県", "JR日豊本線" ],
    [ "重富駅", "鹿児島県", "JR日豊本線" ],
    [ "竜ケ水駅", "鹿児島県", "JR日豊本線" ],
    [ "鹿児島駅", "鹿児島県", "JR日豊本線" ],
    [ "鹿児島中央駅", "鹿児島県", "JR日豊本線" ],
    [ "桂川駅", "福岡県", "福北ゆたか線" ],
    [ "筑前大分駅", "福岡県", "福北ゆたか線" ],
    [ "九郎原駅", "福岡県", "福北ゆたか線" ],
    [ "城戸南蔵院前駅", "福岡県", "福北ゆたか線" ],
    [ "筑前山手駅", "福岡県", "福北ゆたか線" ],
    [ "篠栗駅", "福岡県", "福北ゆたか線" ],
    [ "門松駅", "福岡県", "福北ゆたか線" ],
    [ "長者原駅", "福岡県", "福北ゆたか線" ],
    [ "原町駅", "福岡県", "福北ゆたか線" ],
    [ "柚須駅", "福岡県", "福北ゆたか線" ],
    [ "吉塚駅", "福岡県", "福北ゆたか線" ],
    [ "博多駅", "福岡県", "福北ゆたか線" ],
    [ "姪浜駅", "福岡県", "JR筑肥線" ],
    [ "下山門駅", "福岡県", "JR筑肥線" ],
    [ "今宿駅", "福岡県", "JR筑肥線" ],
    [ "九大学研都市駅", "福岡県", "JR筑肥線" ],
    [ "周船寺駅", "福岡県", "JR筑肥線" ],
    [ "波多江駅", "福岡県", "JR筑肥線" ],
    [ "糸島高校前駅", "福岡県", "JR筑肥線" ],
    [ "筑前前原駅", "福岡県", "JR筑肥線" ],
    [ "美咲が丘駅", "福岡県", "JR筑肥線" ],
    [ "加布里駅", "福岡県", "JR筑肥線" ],
    [ "一貴山駅", "福岡県", "JR筑肥線" ],
    [ "筑前深江駅", "福岡県", "JR筑肥線" ],
    [ "大入駅", "福岡県", "JR筑肥線" ],
    [ "福吉駅", "福岡県", "JR筑肥線" ],
    [ "鹿家駅", "福岡県", "JR筑肥線" ],
    [ "浜崎駅", "佐賀県", "JR筑肥線" ],
    [ "虹ノ松原駅", "佐賀県", "JR筑肥線" ],
    [ "東唐津駅", "佐賀県", "JR筑肥線" ],
    [ "和多田駅", "佐賀県", "JR筑肥線" ],
    [ "唐津駅", "佐賀県", "JR筑肥線" ],
    [ "西唐津駅", "佐賀県", "JR筑肥線" ],
    [ "若松駅", "福岡県", "若松線" ],
    [ "藤ノ木駅", "福岡県", "若松線" ],
    [ "奥洞海駅", "福岡県", "若松線" ],
    [ "二島駅", "福岡県", "若松線" ],
    [ "本城駅", "福岡県", "若松線" ],
    [ "折尾駅", "福岡県", "若松線" ],
    [ "折尾駅", "福岡県", "福北ゆたか線" ],
    [ "東水巻駅", "福岡県", "福北ゆたか線" ],
    [ "中間駅", "福岡県", "福北ゆたか線" ],
    [ "筑前垣生駅", "福岡県", "福北ゆたか線" ],
    [ "鞍手駅", "福岡県", "福北ゆたか線" ],
    [ "筑前植木駅", "福岡県", "福北ゆたか線" ],
    [ "新入駅", "福岡県", "福北ゆたか線" ],
    [ "直方駅", "福岡県", "福北ゆたか線" ],
    [ "勝野駅", "福岡県", "福北ゆたか線" ],
    [ "小竹駅", "福岡県", "福北ゆたか線" ],
    [ "鯰田駅", "福岡県", "福北ゆたか線" ],
    [ "浦田駅", "福岡県", "福北ゆたか線" ],
    [ "新飯塚駅", "福岡県", "福北ゆたか線" ],
    [ "飯塚駅", "福岡県", "福北ゆたか線" ],
    [ "天道駅", "福岡県", "福北ゆたか線" ],
    [ "桂川駅", "福岡県", "福北ゆたか線" ],
    [ "桂川駅", "福岡県", "原田線" ],
    [ "上穂波駅", "福岡県", "原田線" ],
    [ "筑前内野駅", "福岡県", "原田線" ],
    [ "筑前山家駅", "福岡県", "原田線" ],
    [ "原田駅", "福岡県", "原田線" ],
    [ "久留米駅", "福岡県", "ゆふ高原線" ],
    [ "久留米高校前駅", "福岡県", "ゆふ高原線" ],
    [ "南久留米駅", "福岡県", "ゆふ高原線" ],
    [ "久留米大学前駅", "福岡県", "ゆふ高原線" ],
    [ "御井駅", "福岡県", "ゆふ高原線" ],
    [ "善導寺駅", "福岡県", "ゆふ高原線" ],
    [ "筑後草野駅", "福岡県", "ゆふ高原線" ],
    [ "田主丸駅", "福岡県", "ゆふ高原線" ],
    [ "筑後吉井駅", "福岡県", "ゆふ高原線" ],
    [ "うきは駅", "福岡県", "ゆふ高原線" ],
    [ "筑後大石駅", "福岡県", "ゆふ高原線" ],
    [ "夜明駅", "大分県", "ゆふ高原線" ],
    [ "光岡駅", "大分県", "ゆふ高原線" ],
    [ "日田駅", "大分県", "ゆふ高原線" ],
    [ "豊後三芳駅", "大分県", "ゆふ高原線" ],
    [ "豊後中川駅", "大分県", "ゆふ高原線" ],
    [ "天ケ瀬駅", "大分県", "ゆふ高原線" ],
    [ "杉河内駅", "大分県", "ゆふ高原線" ],
    [ "北山田駅", "大分県", "ゆふ高原線" ],
    [ "豊後森駅", "大分県", "ゆふ高原線" ],
    [ "恵良駅", "大分県", "ゆふ高原線" ],
    [ "引治駅", "大分県", "ゆふ高原線" ],
    [ "豊後中村駅", "大分県", "ゆふ高原線" ],
    [ "野矢駅", "大分県", "ゆふ高原線" ],
    [ "由布院駅", "大分県", "ゆふ高原線" ],
    [ "南由布駅", "大分県", "ゆふ高原線" ],
    [ "湯平駅", "大分県", "ゆふ高原線" ],
    [ "庄内駅", "大分県", "ゆふ高原線" ],
    [ "天神山駅", "大分県", "ゆふ高原線" ],
    [ "小野屋駅", "大分県", "ゆふ高原線" ],
    [ "鬼瀬駅", "大分県", "ゆふ高原線" ],
    [ "向之原駅", "大分県", "ゆふ高原線" ],
    [ "豊後国分駅", "大分県", "ゆふ高原線" ],
    [ "賀来駅", "大分県", "ゆふ高原線" ],
    [ "南大分駅", "大分県", "ゆふ高原線" ],
    [ "古国府駅", "大分県", "ゆふ高原線" ],
    [ "大分駅", "大分県", "ゆふ高原線" ],
    [ "小倉駅", "福岡県", "JR日田彦山線" ],
    [ "西小倉駅", "福岡県", "JR日田彦山線" ],
    [ "南小倉駅", "福岡県", "JR日田彦山線" ],
    [ "城野駅", "福岡県", "JR日田彦山線" ],
    [ "石田駅", "福岡県", "JR日田彦山線" ],
    [ "志井公園駅", "福岡県", "JR日田彦山線" ],
    [ "志井駅", "福岡県", "JR日田彦山線" ],
    [ "石原町駅", "福岡県", "JR日田彦山線" ],
    [ "呼野駅", "福岡県", "JR日田彦山線" ],
    [ "採銅所駅", "福岡県", "JR日田彦山線" ],
    [ "香春駅", "福岡県", "JR日田彦山線" ],
    [ "一本松駅", "福岡県", "JR日田彦山線" ],
    [ "田川伊田駅", "福岡県", "JR日田彦山線" ],
    [ "田川後藤寺駅", "福岡県", "JR日田彦山線" ],
    [ "池尻駅", "福岡県", "JR日田彦山線" ],
    [ "豊前川崎駅", "福岡県", "JR日田彦山線" ],
    [ "西添田駅", "福岡県", "JR日田彦山線" ],
    [ "添田駅", "福岡県", "JR日田彦山線" ],
    [ "歓遊舎ひこさん駅", "福岡県", "JR日田彦山線" ],
    [ "豊前桝田駅", "福岡県", "JR日田彦山線" ],
    [ "彦山駅", "福岡県", "JR日田彦山線" ],
    [ "筑前岩屋駅", "福岡県", "JR日田彦山線" ],
    [ "大行司駅", "福岡県", "JR日田彦山線" ],
    [ "宝珠山駅", "福岡県", "JR日田彦山線" ],
    [ "大鶴駅", "大分県", "JR日田彦山線" ],
    [ "今山駅", "大分県", "JR日田彦山線" ],
    [ "夜明駅", "大分県", "JR日田彦山線" ],
    [ "光岡駅", "大分県", "JR日田彦山線" ],
    [ "日田駅", "大分県", "JR日田彦山線" ],
    [ "新飯塚駅", "福岡県", "JR後藤寺線" ],
    [ "上三緒駅", "福岡県", "JR後藤寺線" ],
    [ "下鴨生駅", "福岡県", "JR後藤寺線" ],
    [ "筑前庄内駅", "福岡県", "JR後藤寺線" ],
    [ "船尾駅", "福岡県", "JR後藤寺線" ],
    [ "田川後藤寺駅", "福岡県", "JR後藤寺線" ],
    [ "西戸崎駅", "福岡県", "海の中道線" ],
    [ "海ノ中道駅", "福岡県", "海の中道線" ],
    [ "雁ノ巣駅", "福岡県", "海の中道線" ],
    [ "奈多駅", "福岡県", "海の中道線" ],
    [ "和白駅", "福岡県", "海の中道線" ],
    [ "香椎駅", "福岡県", "海の中道線" ],
    [ "香椎駅", "福岡県", "JR香椎線" ],
    [ "香椎神宮駅", "福岡県", "JR香椎線" ],
    [ "舞松原駅", "福岡県", "JR香椎線" ],
    [ "土井駅", "福岡県", "JR香椎線" ],
    [ "長者原駅", "福岡県", "JR香椎線" ],
    [ "酒殿駅", "福岡県", "JR香椎線" ],
    [ "須恵駅", "福岡県", "JR香椎線" ],
    [ "須恵中央駅", "福岡県", "JR香椎線" ],
    [ "新原駅", "福岡県", "JR香椎線" ],
    [ "宇美駅", "福岡県", "JR香椎線" ],
    [ "江北駅", "佐賀県", "JR佐世保線" ],
    [ "大町駅", "佐賀県", "JR佐世保線" ],
    [ "北方駅", "佐賀県", "JR佐世保線" ],
    [ "高橋駅", "佐賀県", "JR佐世保線" ],
    [ "武雄温泉駅", "佐賀県", "JR佐世保線" ],
    [ "永尾駅", "佐賀県", "JR佐世保線" ],
    [ "三間坂駅", "佐賀県", "JR佐世保線" ],
    [ "上有田駅", "佐賀県", "JR佐世保線" ],
    [ "有田駅", "佐賀県", "JR佐世保線" ],
    [ "三河内駅", "長崎県", "JR佐世保線" ],
    [ "早岐駅", "長崎県", "JR佐世保線" ],
    [ "大塔駅", "長崎県", "JR佐世保線" ],
    [ "日宇駅", "長崎県", "JR佐世保線" ],
    [ "佐世保駅", "長崎県", "JR佐世保線" ],
    [ "西唐津駅", "佐賀県", "JR筑肥線" ],
    [ "唐津駅", "佐賀県", "JR筑肥線" ],
    [ "鬼塚駅", "佐賀県", "JR筑肥線" ],
    [ "山本駅", "佐賀県", "JR筑肥線" ],
    [ "肥前久保駅", "佐賀県", "JR筑肥線" ],
    [ "西相知駅", "佐賀県", "JR筑肥線" ],
    [ "佐里駅", "佐賀県", "JR筑肥線" ],
    [ "駒鳴駅", "佐賀県", "JR筑肥線" ],
    [ "大川野駅", "佐賀県", "JR筑肥線" ],
    [ "肥前長野駅", "佐賀県", "JR筑肥線" ],
    [ "桃川駅", "佐賀県", "JR筑肥線" ],
    [ "金石原駅", "佐賀県", "JR筑肥線" ],
    [ "上伊万里駅", "佐賀県", "JR筑肥線" ],
    [ "伊万里駅", "佐賀県", "JR筑肥線" ],
    [ "佐賀駅", "佐賀県", "JR唐津線" ],
    [ "鍋島駅", "佐賀県", "JR唐津線" ],
    [ "バルーンさが駅", "佐賀県", "JR唐津線" ],
    [ "久保田駅", "佐賀県", "JR唐津線" ],
    [ "小城駅", "佐賀県", "JR唐津線" ],
    [ "東多久駅", "佐賀県", "JR唐津線" ],
    [ "中多久駅", "佐賀県", "JR唐津線" ],
    [ "多久駅", "佐賀県", "JR唐津線" ],
    [ "厳木駅", "佐賀県", "JR唐津線" ],
    [ "岩屋駅", "佐賀県", "JR唐津線" ],
    [ "相知駅", "佐賀県", "JR唐津線" ],
    [ "本牟田部駅", "佐賀県", "JR唐津線" ],
    [ "山本駅", "佐賀県", "JR唐津線" ],
    [ "鬼塚駅", "佐賀県", "JR唐津線" ],
    [ "唐津駅", "佐賀県", "JR唐津線" ],
    [ "西唐津駅", "佐賀県", "JR唐津線" ],
    [ "早岐駅", "長崎県", "JR大村線" ],
    [ "ハウステンボス駅", "長崎県", "JR大村線" ],
    [ "南風崎駅", "長崎県", "JR大村線" ],
    [ "小串郷駅", "長崎県", "JR大村線" ],
    [ "川棚駅", "長崎県", "JR大村線" ],
    [ "彼杵駅", "長崎県", "JR大村線" ],
    [ "千綿駅", "長崎県", "JR大村線" ],
    [ "松原駅", "長崎県", "JR大村線" ],
    [ "大村車両基地駅", "長崎県", "JR大村線" ],
    [ "竹松駅", "長崎県", "JR大村線" ],
    [ "新大村駅", "長崎県", "JR大村線" ],
    [ "諏訪駅", "長崎県", "JR大村線" ],
    [ "大村駅", "長崎県", "JR大村線" ],
    [ "岩松駅", "長崎県", "JR大村線" ],
    [ "諫早駅", "長崎県", "JR大村線" ],
    [ "熊本駅", "熊本県", "阿蘇高原線" ],
    [ "平成駅", "熊本県", "阿蘇高原線" ],
    [ "南熊本駅", "熊本県", "阿蘇高原線" ],
    [ "新水前寺駅", "熊本県", "阿蘇高原線" ],
    [ "水前寺駅", "熊本県", "阿蘇高原線" ],
    [ "東海学園前駅", "熊本県", "阿蘇高原線" ],
    [ "竜田口駅", "熊本県", "阿蘇高原線" ],
    [ "武蔵塚駅", "熊本県", "阿蘇高原線" ],
    [ "光の森駅", "熊本県", "阿蘇高原線" ],
    [ "三里木駅", "熊本県", "阿蘇高原線" ],
    [ "原水駅", "熊本県", "阿蘇高原線" ],
    [ "肥後大津駅", "熊本県", "阿蘇高原線" ],
    [ "瀬田駅", "熊本県", "阿蘇高原線" ],
    [ "立野駅", "熊本県", "阿蘇高原線" ],
    [ "赤水駅", "熊本県", "阿蘇高原線" ],
    [ "市ノ川駅", "熊本県", "阿蘇高原線" ],
    [ "内牧駅", "熊本県", "阿蘇高原線" ],
    [ "阿蘇駅", "熊本県", "阿蘇高原線" ],
    [ "いこいの村駅", "熊本県", "阿蘇高原線" ],
    [ "宮地駅", "熊本県", "阿蘇高原線" ],
    [ "波野駅", "熊本県", "阿蘇高原線" ],
    [ "滝水駅", "熊本県", "阿蘇高原線" ],
    [ "豊後荻駅", "大分県", "阿蘇高原線" ],
    [ "玉来駅", "大分県", "阿蘇高原線" ],
    [ "豊後竹田駅", "大分県", "阿蘇高原線" ],
    [ "朝地駅", "大分県", "阿蘇高原線" ],
    [ "緒方駅", "大分県", "阿蘇高原線" ],
    [ "豊後清川駅", "大分県", "阿蘇高原線" ],
    [ "三重町駅", "大分県", "阿蘇高原線" ],
    [ "菅尾駅", "大分県", "阿蘇高原線" ],
    [ "犬飼駅", "大分県", "阿蘇高原線" ],
    [ "竹中駅", "大分県", "阿蘇高原線" ],
    [ "中判田駅", "大分県", "阿蘇高原線" ],
    [ "大分大学前駅", "大分県", "阿蘇高原線" ],
    [ "敷戸駅", "大分県", "阿蘇高原線" ],
    [ "滝尾駅", "大分県", "阿蘇高原線" ],
    [ "大分駅", "大分県", "阿蘇高原線" ],
    [ "熊本駅", "熊本県", "三角線" ],
    [ "川尻駅", "熊本県", "三角線" ],
    [ "富合駅", "熊本県", "三角線" ],
    [ "宇土駅", "熊本県", "三角線" ],
    [ "緑川駅", "熊本県", "三角線" ],
    [ "住吉駅", "熊本県", "三角線" ],
    [ "肥後長浜駅", "熊本県", "三角線" ],
    [ "網田駅", "熊本県", "三角線" ],
    [ "赤瀬駅", "熊本県", "三角線" ],
    [ "石打ダム駅", "熊本県", "三角線" ],
    [ "波多浦駅", "熊本県", "三角線" ],
    [ "三角駅", "熊本県", "三角線" ],
    [ "八代駅", "熊本県", "えびの高原線" ],
    [ "段駅", "熊本県", "えびの高原線" ],
    [ "坂本駅", "熊本県", "えびの高原線" ],
    [ "葉木駅", "熊本県", "えびの高原線" ],
    [ "鎌瀬駅", "熊本県", "えびの高原線" ],
    [ "瀬戸石駅", "熊本県", "えびの高原線" ],
    [ "海路駅", "熊本県", "えびの高原線" ],
    [ "吉尾駅", "熊本県", "えびの高原線" ],
    [ "白石駅", "熊本県", "えびの高原線" ],
    [ "球泉洞駅", "熊本県", "えびの高原線" ],
    [ "一勝地駅", "熊本県", "えびの高原線" ],
    [ "那良口駅", "熊本県", "えびの高原線" ],
    [ "渡駅", "熊本県", "えびの高原線" ],
    [ "西人吉駅", "熊本県", "えびの高原線" ],
    [ "人吉駅", "熊本県", "えびの高原線" ],
    [ "大畑駅", "熊本県", "えびの高原線" ],
    [ "矢岳駅", "熊本県", "えびの高原線" ],
    [ "真幸駅", "宮崎県", "えびの高原線" ],
    [ "吉松駅", "鹿児島県", "えびの高原線" ],
    [ "吉松駅", "鹿児島県", "JR肥薩線" ],
    [ "栗野駅", "鹿児島県", "JR肥薩線" ],
    [ "大隅横川駅", "鹿児島県", "JR肥薩線" ],
    [ "植村駅", "鹿児島県", "JR肥薩線" ],
    [ "霧島温泉駅", "鹿児島県", "JR肥薩線" ],
    [ "嘉例川駅", "鹿児島県", "JR肥薩線" ],
    [ "中福良駅", "鹿児島県", "JR肥薩線" ],
    [ "表木山駅", "鹿児島県", "JR肥薩線" ],
    [ "日当山駅", "鹿児島県", "JR肥薩線" ],
    [ "隼人駅", "鹿児島県", "JR肥薩線" ],
    [ "宮崎駅", "宮崎県", "JR宮崎空港線" ],
    [ "南宮崎駅", "宮崎県", "JR宮崎空港線" ],
    [ "田吉駅", "宮崎県", "JR宮崎空港線" ],
    [ "宮崎空港駅", "宮崎県", "JR宮崎空港線" ],
    [ "宮崎駅", "宮崎県", "JR日南線" ],
    [ "南宮崎駅", "宮崎県", "JR日南線" ],
    [ "田吉駅", "宮崎県", "JR日南線" ],
    [ "南方駅", "宮崎県", "JR日南線" ],
    [ "木花駅", "宮崎県", "JR日南線" ],
    [ "運動公園駅", "宮崎県", "JR日南線" ],
    [ "曽山寺駅", "宮崎県", "JR日南線" ],
    [ "子供の国駅", "宮崎県", "JR日南線" ],
    [ "青島駅", "宮崎県", "JR日南線" ],
    [ "折生迫駅", "宮崎県", "JR日南線" ],
    [ "内海駅", "宮崎県", "JR日南線" ],
    [ "小内海駅", "宮崎県", "JR日南線" ],
    [ "伊比井駅", "宮崎県", "JR日南線" ],
    [ "北郷駅", "宮崎県", "JR日南線" ],
    [ "内之田駅", "宮崎県", "JR日南線" ],
    [ "飫肥駅", "宮崎県", "JR日南線" ],
    [ "日南駅", "宮崎県", "JR日南線" ],
    [ "油津駅", "宮崎県", "JR日南線" ],
    [ "大堂津駅", "宮崎県", "JR日南線" ],
    [ "南郷駅", "宮崎県", "JR日南線" ],
    [ "谷之口駅", "宮崎県", "JR日南線" ],
    [ "榎原駅", "宮崎県", "JR日南線" ],
    [ "日向大束駅", "宮崎県", "JR日南線" ],
    [ "日向北方駅", "宮崎県", "JR日南線" ],
    [ "串間駅", "宮崎県", "JR日南線" ],
    [ "福島今町駅", "宮崎県", "JR日南線" ],
    [ "福島高松駅", "宮崎県", "JR日南線" ],
    [ "大隅夏井駅", "鹿児島県", "JR日南線" ],
    [ "志布志駅", "鹿児島県", "JR日南線" ],
    [ "都城駅", "宮崎県", "えびの高原線" ],
    [ "日向庄内駅", "宮崎県", "えびの高原線" ],
    [ "谷頭駅", "宮崎県", "えびの高原線" ],
    [ "万ケ塚駅", "宮崎県", "えびの高原線" ],
    [ "東高崎駅", "宮崎県", "えびの高原線" ],
    [ "高崎新田駅", "宮崎県", "えびの高原線" ],
    [ "日向前田駅", "宮崎県", "えびの高原線" ],
    [ "高原駅", "宮崎県", "えびの高原線" ],
    [ "広原駅", "宮崎県", "えびの高原線" ],
    [ "小林駅", "宮崎県", "えびの高原線" ],
    [ "西小林駅", "宮崎県", "えびの高原線" ],
    [ "えびの飯野駅", "宮崎県", "えびの高原線" ],
    [ "えびの上江駅", "宮崎県", "えびの高原線" ],
    [ "えびの駅", "宮崎県", "えびの高原線" ],
    [ "京町温泉駅", "宮崎県", "えびの高原線" ],
    [ "鶴丸駅", "鹿児島県", "えびの高原線" ],
    [ "吉松駅", "鹿児島県", "えびの高原線" ],
    [ "鹿児島中央駅", "鹿児島県", "JR指宿枕崎線" ],
    [ "郡元駅", "鹿児島県", "JR指宿枕崎線" ],
    [ "南鹿児島駅", "鹿児島県", "JR指宿枕崎線" ],
    [ "宇宿駅", "鹿児島県", "JR指宿枕崎線" ],
    [ "谷山駅", "鹿児島県", "JR指宿枕崎線" ],
    [ "慈眼寺駅", "鹿児島県", "JR指宿枕崎線" ],
    [ "坂之上駅", "鹿児島県", "JR指宿枕崎線" ],
    [ "五位野駅", "鹿児島県", "JR指宿枕崎線" ],
    [ "平川駅", "鹿児島県", "JR指宿枕崎線" ],
    [ "瀬々串駅", "鹿児島県", "JR指宿枕崎線" ],
    [ "中名駅", "鹿児島県", "JR指宿枕崎線" ],
    [ "喜入駅", "鹿児島県", "JR指宿枕崎線" ],
    [ "前之浜駅", "鹿児島県", "JR指宿枕崎線" ],
    [ "生見駅", "鹿児島県", "JR指宿枕崎線" ],
    [ "薩摩今和泉駅", "鹿児島県", "JR指宿枕崎線" ],
    [ "宮ケ浜駅", "鹿児島県", "JR指宿枕崎線" ],
    [ "二月田駅", "鹿児島県", "JR指宿枕崎線" ],
    [ "指宿駅", "鹿児島県", "JR指宿枕崎線" ],
    [ "山川駅", "鹿児島県", "JR指宿枕崎線" ],
    [ "大山駅", "鹿児島県", "JR指宿枕崎線" ],
    [ "西大山駅", "鹿児島県", "JR指宿枕崎線" ],
    [ "薩摩川尻駅", "鹿児島県", "JR指宿枕崎線" ],
    [ "東開聞駅", "鹿児島県", "JR指宿枕崎線" ],
    [ "開聞駅", "鹿児島県", "JR指宿枕崎線" ],
    [ "入野駅", "鹿児島県", "JR指宿枕崎線" ],
    [ "頴娃駅", "鹿児島県", "JR指宿枕崎線" ],
    [ "西頴娃駅", "鹿児島県", "JR指宿枕崎線" ],
    [ "御領駅", "鹿児島県", "JR指宿枕崎線" ],
    [ "石垣駅", "鹿児島県", "JR指宿枕崎線" ],
    [ "水成川駅", "鹿児島県", "JR指宿枕崎線" ],
    [ "頴娃大川駅", "鹿児島県", "JR指宿枕崎線" ],
    [ "松ケ浦駅", "鹿児島県", "JR指宿枕崎線" ],
    [ "薩摩塩屋駅", "鹿児島県", "JR指宿枕崎線" ],
    [ "白沢駅", "鹿児島県", "JR指宿枕崎線" ],
    [ "薩摩板敷駅", "鹿児島県", "JR指宿枕崎線" ],
    [ "枕崎駅", "鹿児島県", "JR指宿枕崎線" ],
    [ "池袋駅", "東京都", "東武東上線" ],
    [ "北池袋駅", "東京都", "東武東上線" ],
    [ "下板橋駅", "東京都", "東武東上線" ],
    [ "大山駅", "東京都", "東武東上線" ],
    [ "中板橋駅", "東京都", "東武東上線" ],
    [ "ときわ台駅", "東京都", "東武東上線" ],
    [ "上板橋駅", "東京都", "東武東上線" ],
    [ "東武練馬駅", "東京都", "東武東上線" ],
    [ "下赤塚駅", "東京都", "東武東上線" ],
    [ "成増駅", "東京都", "東武東上線" ],
    [ "和光市駅", "埼玉県", "東武東上線" ],
    [ "朝霞駅", "埼玉県", "東武東上線" ],
    [ "朝霞台駅", "埼玉県", "東武東上線" ],
    [ "志木駅", "埼玉県", "東武東上線" ],
    [ "柳瀬川駅", "埼玉県", "東武東上線" ],
    [ "みずほ台駅", "埼玉県", "東武東上線" ],
    [ "鶴瀬駅", "埼玉県", "東武東上線" ],
    [ "ふじみ野駅", "埼玉県", "東武東上線" ],
    [ "上福岡駅", "埼玉県", "東武東上線" ],
    [ "新河岸駅", "埼玉県", "東武東上線" ],
    [ "川越駅", "埼玉県", "東武東上線" ],
    [ "川越市駅", "埼玉県", "東武東上線" ],
    [ "霞ヶ関駅", "埼玉県", "東武東上線" ],
    [ "鶴ヶ島駅", "埼玉県", "東武東上線" ],
    [ "若葉駅", "埼玉県", "東武東上線" ],
    [ "坂戸駅", "埼玉県", "東武東上線" ],
    [ "北坂戸駅", "埼玉県", "東武東上線" ],
    [ "高坂駅", "埼玉県", "東武東上線" ],
    [ "東松山駅", "埼玉県", "東武東上線" ],
    [ "森林公園駅", "埼玉県", "東武東上線" ],
    [ "つきのわ駅", "埼玉県", "東武東上線" ],
    [ "武蔵嵐山駅", "埼玉県", "東武東上線" ],
    [ "小川町駅", "埼玉県", "東武東上線" ],
    [ "東武竹沢駅", "埼玉県", "東武東上線" ],
    [ "みなみ寄居駅", "埼玉県", "東武東上線" ],
    [ "男衾駅", "埼玉県", "東武東上線" ],
    [ "鉢形駅", "埼玉県", "東武東上線" ],
    [ "玉淀駅", "埼玉県", "東武東上線" ],
    [ "寄居駅", "埼玉県", "東武東上線" ],
    [ "浅草駅", "東京都", "東武伊勢崎線" ],
    [ "とうきょうスカイツリー駅", "東京都", "東武伊勢崎線" ],
    [ "押上〈スカイツリー前〉駅", "東京都", "東武伊勢崎線" ],
    [ "曳舟駅", "東京都", "東武伊勢崎線" ],
    [ "東向島駅", "東京都", "東武伊勢崎線" ],
    [ "鐘ヶ淵駅", "東京都", "東武伊勢崎線" ],
    [ "堀切駅", "東京都", "東武伊勢崎線" ],
    [ "牛田駅", "東京都", "東武伊勢崎線" ],
    [ "北千住駅", "東京都", "東武伊勢崎線" ],
    [ "小菅駅", "東京都", "東武伊勢崎線" ],
    [ "五反野駅", "東京都", "東武伊勢崎線" ],
    [ "梅島駅", "東京都", "東武伊勢崎線" ],
    [ "西新井駅", "東京都", "東武伊勢崎線" ],
    [ "竹ノ塚駅", "東京都", "東武伊勢崎線" ],
    [ "谷塚駅", "埼玉県", "東武伊勢崎線" ],
    [ "草加駅", "埼玉県", "東武伊勢崎線" ],
    [ "獨協大学前〈草加松原〉駅", "埼玉県", "東武伊勢崎線" ],
    [ "新田駅", "埼玉県", "東武伊勢崎線" ],
    [ "蒲生駅", "埼玉県", "東武伊勢崎線" ],
    [ "新越谷駅", "埼玉県", "東武伊勢崎線" ],
    [ "越谷駅", "埼玉県", "東武伊勢崎線" ],
    [ "北越谷駅", "埼玉県", "東武伊勢崎線" ],
    [ "大袋駅", "埼玉県", "東武伊勢崎線" ],
    [ "せんげん台駅", "埼玉県", "東武伊勢崎線" ],
    [ "武里駅", "埼玉県", "東武伊勢崎線" ],
    [ "一ノ割駅", "埼玉県", "東武伊勢崎線" ],
    [ "春日部駅", "埼玉県", "東武伊勢崎線" ],
    [ "北春日部駅", "埼玉県", "東武伊勢崎線" ],
    [ "姫宮駅", "埼玉県", "東武伊勢崎線" ],
    [ "東武動物公園駅", "埼玉県", "東武伊勢崎線" ],
    [ "和戸駅", "埼玉県", "東武伊勢崎線" ],
    [ "久喜駅", "埼玉県", "東武伊勢崎線" ],
    [ "鷲宮駅", "埼玉県", "東武伊勢崎線" ],
    [ "花崎駅", "埼玉県", "東武伊勢崎線" ],
    [ "加須駅", "埼玉県", "東武伊勢崎線" ],
    [ "南羽生駅", "埼玉県", "東武伊勢崎線" ],
    [ "羽生駅", "埼玉県", "東武伊勢崎線" ],
    [ "川俣駅", "群馬県", "東武伊勢崎線" ],
    [ "茂林寺前駅", "群馬県", "東武伊勢崎線" ],
    [ "館林駅", "群馬県", "東武伊勢崎線" ],
    [ "多々良駅", "群馬県", "東武伊勢崎線" ],
    [ "県駅", "栃木県", "東武伊勢崎線" ],
    [ "福居駅", "栃木県", "東武伊勢崎線" ],
    [ "東武和泉駅", "栃木県", "東武伊勢崎線" ],
    [ "足利市駅", "栃木県", "東武伊勢崎線" ],
    [ "野州山辺駅", "栃木県", "東武伊勢崎線" ],
    [ "韮川駅", "群馬県", "東武伊勢崎線" ],
    [ "太田駅", "群馬県", "東武伊勢崎線" ],
    [ "細谷駅", "群馬県", "東武伊勢崎線" ],
    [ "木崎駅", "群馬県", "東武伊勢崎線" ],
    [ "世良田駅", "群馬県", "東武伊勢崎線" ],
    [ "境町駅", "群馬県", "東武伊勢崎線" ],
    [ "剛志駅", "群馬県", "東武伊勢崎線" ],
    [ "新伊勢崎駅", "群馬県", "東武伊勢崎線" ],
    [ "伊勢崎駅", "群馬県", "東武伊勢崎線" ],
    [ "東武動物公園駅", "埼玉県", "東武日光線" ],
    [ "杉戸高野台駅", "埼玉県", "東武日光線" ],
    [ "幸手駅", "埼玉県", "東武日光線" ],
    [ "南栗橋駅", "埼玉県", "東武日光線" ],
    [ "栗橋駅", "埼玉県", "東武日光線" ],
    [ "新古河駅", "埼玉県", "東武日光線" ],
    [ "柳生駅", "埼玉県", "東武日光線" ],
    [ "板倉東洋大前駅", "群馬県", "東武日光線" ],
    [ "藤岡駅", "栃木県", "東武日光線" ],
    [ "静和駅", "栃木県", "東武日光線" ],
    [ "新大平下駅", "栃木県", "東武日光線" ],
    [ "栃木駅", "栃木県", "東武日光線" ],
    [ "新栃木駅", "栃木県", "東武日光線" ],
    [ "合戦場駅", "栃木県", "東武日光線" ],
    [ "家中駅", "栃木県", "東武日光線" ],
    [ "東武金崎駅", "栃木県", "東武日光線" ],
    [ "楡木駅", "栃木県", "東武日光線" ],
    [ "樅山駅", "栃木県", "東武日光線" ],
    [ "新鹿沼駅", "栃木県", "東武日光線" ],
    [ "北鹿沼駅", "栃木県", "東武日光線" ],
    [ "板荷駅", "栃木県", "東武日光線" ],
    [ "下小代駅", "栃木県", "東武日光線" ],
    [ "明神駅", "栃木県", "東武日光線" ],
    [ "下今市駅", "栃木県", "東武日光線" ],
    [ "上今市駅", "栃木県", "東武日光線" ],
    [ "東武日光駅", "栃木県", "東武日光線" ],
    [ "大宮駅", "埼玉県", "東武野田線" ],
    [ "北大宮駅", "埼玉県", "東武野田線" ],
    [ "大宮公園駅", "埼玉県", "東武野田線" ],
    [ "大和田駅", "埼玉県", "東武野田線" ],
    [ "七里駅", "埼玉県", "東武野田線" ],
    [ "岩槻駅", "埼玉県", "東武野田線" ],
    [ "東岩槻駅", "埼玉県", "東武野田線" ],
    [ "豊春駅", "埼玉県", "東武野田線" ],
    [ "八木崎駅", "埼玉県", "東武野田線" ],
    [ "春日部駅", "埼玉県", "東武野田線" ],
    [ "藤の牛島駅", "埼玉県", "東武野田線" ],
    [ "南桜井駅", "埼玉県", "東武野田線" ],
    [ "川間駅", "千葉県", "東武野田線" ],
    [ "七光台駅", "千葉県", "東武野田線" ],
    [ "清水公園駅", "千葉県", "東武野田線" ],
    [ "愛宕駅", "千葉県", "東武野田線" ],
    [ "野田市駅", "千葉県", "東武野田線" ],
    [ "梅郷駅", "千葉県", "東武野田線" ],
    [ "運河駅", "千葉県", "東武野田線" ],
    [ "江戸川台駅", "千葉県", "東武野田線" ],
    [ "初石駅", "千葉県", "東武野田線" ],
    [ "流山おおたかの森駅", "千葉県", "東武野田線" ],
    [ "豊四季駅", "千葉県", "東武野田線" ],
    [ "柏駅", "千葉県", "東武野田線" ],
    [ "新柏駅", "千葉県", "東武野田線" ],
    [ "増尾駅", "千葉県", "東武野田線" ],
    [ "逆井駅", "千葉県", "東武野田線" ],
    [ "高柳駅", "千葉県", "東武野田線" ],
    [ "六実駅", "千葉県", "東武野田線" ],
    [ "新鎌ヶ谷駅", "千葉県", "東武野田線" ],
    [ "鎌ヶ谷駅", "千葉県", "東武野田線" ],
    [ "馬込沢駅", "千葉県", "東武野田線" ],
    [ "塚田駅", "千葉県", "東武野田線" ],
    [ "新船橋駅", "千葉県", "東武野田線" ],
    [ "船橋駅", "千葉県", "東武野田線" ],
    [ "曳舟駅", "東京都", "東武亀戸線" ],
    [ "小村井駅", "東京都", "東武亀戸線" ],
    [ "東あずま駅", "東京都", "東武亀戸線" ],
    [ "亀戸水神駅", "東京都", "東武亀戸線" ],
    [ "亀戸駅", "東京都", "東武亀戸線" ],
    [ "西新井駅", "東京都", "東武大師線" ],
    [ "大師前駅", "東京都", "東武大師線" ],
    [ "坂戸駅", "埼玉県", "東武越生線" ],
    [ "一本松駅", "埼玉県", "東武越生線" ],
    [ "西大家駅", "埼玉県", "東武越生線" ],
    [ "川角駅", "埼玉県", "東武越生線" ],
    [ "武州長瀬駅", "埼玉県", "東武越生線" ],
    [ "東毛呂駅", "埼玉県", "東武越生線" ],
    [ "武州唐沢駅", "埼玉県", "東武越生線" ],
    [ "越生駅", "埼玉県", "東武越生線" ],
    [ "栃木駅", "栃木県", "東武宇都宮線" ],
    [ "新栃木駅", "栃木県", "東武宇都宮線" ],
    [ "野州平川駅", "栃木県", "東武宇都宮線" ],
    [ "野州大塚駅", "栃木県", "東武宇都宮線" ],
    [ "壬生駅", "栃木県", "東武宇都宮線" ],
    [ "国谷駅", "栃木県", "東武宇都宮線" ],
    [ "おもちゃのまち駅", "栃木県", "東武宇都宮線" ],
    [ "安塚駅", "栃木県", "東武宇都宮線" ],
    [ "西川田駅", "栃木県", "東武宇都宮線" ],
    [ "江曽島駅", "栃木県", "東武宇都宮線" ],
    [ "南宇都宮駅", "栃木県", "東武宇都宮線" ],
    [ "東武宇都宮駅", "栃木県", "東武宇都宮線" ],
    [ "下今市駅", "栃木県", "東武鬼怒川線" ],
    [ "大谷向駅", "栃木県", "東武鬼怒川線" ],
    [ "大桑駅", "栃木県", "東武鬼怒川線" ],
    [ "新高徳駅", "栃木県", "東武鬼怒川線" ],
    [ "小佐越駅", "栃木県", "東武鬼怒川線" ],
    [ "東武ワールドスクウェア駅", "栃木県", "東武鬼怒川線" ],
    [ "鬼怒川温泉駅", "栃木県", "東武鬼怒川線" ],
    [ "鬼怒川公園駅", "栃木県", "東武鬼怒川線" ],
    [ "新藤原駅", "栃木県", "東武鬼怒川線" ],
    [ "館林駅", "群馬県", "東武佐野線" ],
    [ "渡瀬駅", "群馬県", "東武佐野線" ],
    [ "田島駅", "栃木県", "東武佐野線" ],
    [ "佐野市駅", "栃木県", "東武佐野線" ],
    [ "佐野駅", "栃木県", "東武佐野線" ],
    [ "堀米駅", "栃木県", "東武佐野線" ],
    [ "吉水駅", "栃木県", "東武佐野線" ],
    [ "田沼駅", "栃木県", "東武佐野線" ],
    [ "多田駅", "栃木県", "東武佐野線" ],
    [ "葛生駅", "栃木県", "東武佐野線" ],
    [ "太田駅", "群馬県", "東武桐生線" ],
    [ "三枚橋駅", "群馬県", "東武桐生線" ],
    [ "治良門橋駅", "群馬県", "東武桐生線" ],
    [ "藪塚駅", "群馬県", "東武桐生線" ],
    [ "阿左美駅", "群馬県", "東武桐生線" ],
    [ "新桐生駅", "群馬県", "東武桐生線" ],
    [ "相老駅", "群馬県", "東武桐生線" ],
    [ "赤城駅", "群馬県", "東武桐生線" ],
    [ "館林駅", "群馬県", "東武小泉線" ],
    [ "成島駅", "群馬県", "東武小泉線" ],
    [ "本中野駅", "群馬県", "東武小泉線" ],
    [ "篠塚駅", "群馬県", "東武小泉線" ],
    [ "東小泉駅", "群馬県", "東武小泉線" ],
    [ "小泉町駅", "群馬県", "東武小泉線" ],
    [ "西小泉駅", "群馬県", "東武小泉線" ],
    [ "太田駅", "群馬県", "東武小泉線" ],
    [ "竜舞駅", "群馬県", "東武小泉線" ],
    [ "池袋駅", "東京都", "西武池袋線" ],
    [ "椎名町駅", "東京都", "西武池袋線" ],
    [ "東長崎駅", "東京都", "西武池袋線" ],
    [ "江古田駅", "東京都", "西武池袋線" ],
    [ "桜台駅", "東京都", "西武池袋線" ],
    [ "練馬駅", "東京都", "西武池袋線" ],
    [ "中村橋駅", "東京都", "西武池袋線" ],
    [ "富士見台駅", "東京都", "西武池袋線" ],
    [ "練馬高野台駅", "東京都", "西武池袋線" ],
    [ "石神井公園駅", "東京都", "西武池袋線" ],
    [ "大泉学園駅", "東京都", "西武池袋線" ],
    [ "保谷駅", "東京都", "西武池袋線" ],
    [ "ひばりヶ丘駅", "東京都", "西武池袋線" ],
    [ "東久留米駅", "東京都", "西武池袋線" ],
    [ "清瀬駅", "東京都", "西武池袋線" ],
    [ "秋津駅", "東京都", "西武池袋線" ],
    [ "所沢駅", "埼玉県", "西武池袋線" ],
    [ "西所沢駅", "埼玉県", "西武池袋線" ],
    [ "小手指駅", "埼玉県", "西武池袋線" ],
    [ "狭山ヶ丘駅", "埼玉県", "西武池袋線" ],
    [ "武蔵藤沢駅", "埼玉県", "西武池袋線" ],
    [ "稲荷山公園駅", "埼玉県", "西武池袋線" ],
    [ "入間市駅", "埼玉県", "西武池袋線" ],
    [ "仏子駅", "埼玉県", "西武池袋線" ],
    [ "元加治駅", "埼玉県", "西武池袋線" ],
    [ "飯能駅", "埼玉県", "西武池袋線" ],
    [ "東飯能駅", "埼玉県", "西武池袋線" ],
    [ "高麗駅", "埼玉県", "西武池袋線" ],
    [ "武蔵横手駅", "埼玉県", "西武池袋線" ],
    [ "東吾野駅", "埼玉県", "西武池袋線" ],
    [ "吾野駅", "埼玉県", "西武池袋線" ],
    [ "飯能駅", "埼玉県", "西武秩父線" ],
    [ "東飯能駅", "埼玉県", "西武秩父線" ],
    [ "高麗駅", "埼玉県", "西武秩父線" ],
    [ "武蔵横手駅", "埼玉県", "西武秩父線" ],
    [ "東吾野駅", "埼玉県", "西武秩父線" ],
    [ "吾野駅", "埼玉県", "西武秩父線" ],
    [ "西吾野駅", "埼玉県", "西武秩父線" ],
    [ "正丸駅", "埼玉県", "西武秩父線" ],
    [ "芦ヶ久保駅", "埼玉県", "西武秩父線" ],
    [ "横瀬駅", "埼玉県", "西武秩父線" ],
    [ "西武秩父駅", "埼玉県", "西武秩父線" ],
    [ "小竹向原駅", "東京都", "西武有楽町線" ],
    [ "新桜台駅", "東京都", "西武有楽町線" ],
    [ "練馬駅", "東京都", "西武有楽町線" ],
    [ "練馬駅", "東京都", "西武豊島線" ],
    [ "豊島園駅", "東京都", "西武豊島線" ],
    [ "西所沢駅", "埼玉県", "西武狭山線" ],
    [ "下山口駅", "埼玉県", "西武狭山線" ],
    [ "西武球場前駅", "埼玉県", "西武狭山線" ],
    [ "多摩湖駅", "東京都", "レオライナー" ],
    [ "西武園ゆうえんち駅", "埼玉県", "レオライナー" ],
    [ "西武球場前駅", "埼玉県", "レオライナー" ],
    [ "西武新宿駅", "東京都", "西武新宿線" ],
    [ "高田馬場駅", "東京都", "西武新宿線" ],
    [ "下落合駅", "東京都", "西武新宿線" ],
    [ "中井駅", "東京都", "西武新宿線" ],
    [ "新井薬師前駅", "東京都", "西武新宿線" ],
    [ "沼袋駅", "東京都", "西武新宿線" ],
    [ "野方駅", "東京都", "西武新宿線" ],
    [ "都立家政駅", "東京都", "西武新宿線" ],
    [ "鷺ノ宮駅", "東京都", "西武新宿線" ],
    [ "下井草駅", "東京都", "西武新宿線" ],
    [ "井荻駅", "東京都", "西武新宿線" ],
    [ "上井草駅", "東京都", "西武新宿線" ],
    [ "上石神井駅", "東京都", "西武新宿線" ],
    [ "武蔵関駅", "東京都", "西武新宿線" ],
    [ "東伏見駅", "東京都", "西武新宿線" ],
    [ "西武柳沢駅", "東京都", "西武新宿線" ],
    [ "田無駅", "東京都", "西武新宿線" ],
    [ "花小金井駅", "東京都", "西武新宿線" ],
    [ "小平駅", "東京都", "西武新宿線" ],
    [ "久米川駅", "東京都", "西武新宿線" ],
    [ "東村山駅", "東京都", "西武新宿線" ],
    [ "所沢駅", "埼玉県", "西武新宿線" ],
    [ "航空公園駅", "埼玉県", "西武新宿線" ],
    [ "新所沢駅", "埼玉県", "西武新宿線" ],
    [ "入曽駅", "埼玉県", "西武新宿線" ],
    [ "狭山市駅", "埼玉県", "西武新宿線" ],
    [ "新狭山駅", "埼玉県", "西武新宿線" ],
    [ "南大塚駅", "埼玉県", "西武新宿線" ],
    [ "本川越駅", "埼玉県", "西武新宿線" ],
    [ "小平駅", "東京都", "西武拝島線" ],
    [ "萩山駅", "東京都", "西武拝島線" ],
    [ "小川駅", "東京都", "西武拝島線" ],
    [ "東大和市駅", "東京都", "西武拝島線" ],
    [ "玉川上水駅", "東京都", "西武拝島線" ],
    [ "武蔵砂川駅", "東京都", "西武拝島線" ],
    [ "西武立川駅", "東京都", "西武拝島線" ],
    [ "拝島駅", "東京都", "西武拝島線" ],
    [ "東村山駅", "東京都", "西武西武園線" ],
    [ "西武園駅", "東京都", "西武西武園線" ],
    [ "国分寺駅", "東京都", "西武国分寺線" ],
    [ "恋ヶ窪駅", "東京都", "西武国分寺線" ],
    [ "鷹の台駅", "東京都", "西武国分寺線" ],
    [ "小川駅", "東京都", "西武国分寺線" ],
    [ "東村山駅", "東京都", "西武国分寺線" ],
    [ "国分寺駅", "東京都", "西武多摩湖線" ],
    [ "一橋学園駅", "東京都", "西武多摩湖線" ],
    [ "青梅街道駅", "東京都", "西武多摩湖線" ],
    [ "萩山駅", "東京都", "西武多摩湖線" ],
    [ "八坂駅", "東京都", "西武多摩湖線" ],
    [ "武蔵大和駅", "東京都", "西武多摩湖線" ],
    [ "多摩湖駅", "東京都", "西武多摩湖線" ],
    [ "武蔵境駅", "東京都", "西武多摩川線" ],
    [ "新小金井駅", "東京都", "西武多摩川線" ],
    [ "多磨駅", "東京都", "西武多摩川線" ],
    [ "白糸台駅", "東京都", "西武多摩川線" ],
    [ "競艇場前駅", "東京都", "西武多摩川線" ],
    [ "是政駅", "東京都", "西武多摩川線" ],
    [ "京成上野駅", "東京都", "京成本線" ],
    [ "日暮里駅", "東京都", "京成本線" ],
    [ "新三河島駅", "東京都", "京成本線" ],
    [ "町屋駅", "東京都", "京成本線" ],
    [ "千住大橋駅", "東京都", "京成本線" ],
    [ "京成関屋駅", "東京都", "京成本線" ],
    [ "堀切菖蒲園駅", "東京都", "京成本線" ],
    [ "お花茶屋駅", "東京都", "京成本線" ],
    [ "青砥駅", "東京都", "京成本線" ],
    [ "京成高砂駅", "東京都", "京成本線" ],
    [ "京成小岩駅", "東京都", "京成本線" ],
    [ "江戸川駅", "東京都", "京成本線" ],
    [ "国府台駅", "千葉県", "京成本線" ],
    [ "市川真間駅", "千葉県", "京成本線" ],
    [ "菅野駅", "千葉県", "京成本線" ],
    [ "京成八幡駅", "千葉県", "京成本線" ],
    [ "鬼越駅", "千葉県", "京成本線" ],
    [ "京成中山駅", "千葉県", "京成本線" ],
    [ "東中山駅", "千葉県", "京成本線" ],
    [ "京成西船駅", "千葉県", "京成本線" ],
    [ "海神駅", "千葉県", "京成本線" ],
    [ "京成船橋駅", "千葉県", "京成本線" ],
    [ "大神宮下駅", "千葉県", "京成本線" ],
    [ "船橋競馬場駅", "千葉県", "京成本線" ],
    [ "谷津駅", "千葉県", "京成本線" ],
    [ "京成津田沼駅", "千葉県", "京成本線" ],
    [ "京成大久保駅", "千葉県", "京成本線" ],
    [ "実籾駅", "千葉県", "京成本線" ],
    [ "八千代台駅", "千葉県", "京成本線" ],
    [ "京成大和田駅", "千葉県", "京成本線" ],
    [ "勝田台駅", "千葉県", "京成本線" ],
    [ "志津駅", "千葉県", "京成本線" ],
    [ "ユーカリが丘駅", "千葉県", "京成本線" ],
    [ "京成臼井駅", "千葉県", "京成本線" ],
    [ "京成佐倉駅", "千葉県", "京成本線" ],
    [ "大佐倉駅", "千葉県", "京成本線" ],
    [ "京成酒々井駅", "千葉県", "京成本線" ],
    [ "宗吾参道駅", "千葉県", "京成本線" ],
    [ "公津の杜駅", "千葉県", "京成本線" ],
    [ "京成成田駅", "千葉県", "京成本線" ],
    [ "東成田駅", "千葉県", "京成本線" ],
    [ "空港第２ビル（第２旅客ターミナル）駅", "千葉県", "京成本線" ],
    [ "成田空港（第１旅客ターミナル）駅", "千葉県", "京成本線" ],
    [ "押上（スカイツリー前）駅", "東京都", "京成押上線" ],
    [ "京成曳舟駅", "東京都", "京成押上線" ],
    [ "八広駅", "東京都", "京成押上線" ],
    [ "四ツ木駅", "東京都", "京成押上線" ],
    [ "京成立石駅", "東京都", "京成押上線" ],
    [ "青砥駅", "東京都", "京成押上線" ],
    [ "京成高砂駅", "東京都", "京成押上線" ],
    [ "京成高砂駅", "東京都", "京成金町線" ],
    [ "柴又駅", "東京都", "京成金町線" ],
    [ "京成金町駅", "東京都", "京成金町線" ],
    [ "京成津田沼駅", "千葉県", "京成千葉線" ],
    [ "京成幕張本郷駅", "千葉県", "京成千葉線" ],
    [ "京成幕張駅", "千葉県", "京成千葉線" ],
    [ "検見川駅", "千葉県", "京成千葉線" ],
    [ "京成稲毛駅", "千葉県", "京成千葉線" ],
    [ "みどり台駅", "千葉県", "京成千葉線" ],
    [ "西登戸駅", "千葉県", "京成千葉線" ],
    [ "新千葉駅", "千葉県", "京成千葉線" ],
    [ "京成千葉駅", "千葉県", "京成千葉線" ],
    [ "千葉中央駅", "千葉県", "京成千葉線" ],
    [ "千葉中央駅", "千葉県", "京成千原線" ],
    [ "千葉寺駅", "千葉県", "京成千原線" ],
    [ "大森台駅", "千葉県", "京成千原線" ],
    [ "学園前駅", "千葉県", "京成千原線" ],
    [ "おゆみ野駅", "千葉県", "京成千原線" ],
    [ "ちはら台駅", "千葉県", "京成千原線" ],
    [ "京成上野駅", "東京都", "成田スカイアクセス" ],
    [ "日暮里駅", "東京都", "成田スカイアクセス" ],
    [ "青砥駅", "東京都", "成田スカイアクセス" ],
    [ "京成高砂駅", "東京都", "成田スカイアクセス" ],
    [ "東松戸駅", "千葉県", "成田スカイアクセス" ],
    [ "新鎌ヶ谷駅", "千葉県", "成田スカイアクセス" ],
    [ "千葉ニュータウン中央駅", "千葉県", "成田スカイアクセス" ],
    [ "印旛日本医大駅", "千葉県", "成田スカイアクセス" ],
    [ "成田湯川駅", "千葉県", "成田スカイアクセス" ],
    [ "空港第２ビル（第２旅客ターミナル）駅", "千葉県", "成田スカイアクセス" ],
    [ "成田空港（第１旅客ターミナル）駅", "千葉県", "成田スカイアクセス" ],
    [ "新宿駅", "東京都", "京王線" ],
    [ "笹塚駅", "東京都", "京王線" ],
    [ "代田橋駅", "東京都", "京王線" ],
    [ "明大前駅", "東京都", "京王線" ],
    [ "下高井戸駅", "東京都", "京王線" ],
    [ "桜上水駅", "東京都", "京王線" ],
    [ "上北沢駅", "東京都", "京王線" ],
    [ "八幡山駅", "東京都", "京王線" ],
    [ "芦花公園駅", "東京都", "京王線" ],
    [ "千歳烏山駅", "東京都", "京王線" ],
    [ "仙川駅", "東京都", "京王線" ],
    [ "つつじヶ丘駅", "東京都", "京王線" ],
    [ "柴崎駅", "東京都", "京王線" ],
    [ "国領駅", "東京都", "京王線" ],
    [ "布田駅", "東京都", "京王線" ],
    [ "調布駅", "東京都", "京王線" ],
    [ "西調布駅", "東京都", "京王線" ],
    [ "飛田給駅", "東京都", "京王線" ],
    [ "武蔵野台駅", "東京都", "京王線" ],
    [ "多磨霊園駅", "東京都", "京王線" ],
    [ "東府中駅", "東京都", "京王線" ],
    [ "府中駅", "東京都", "京王線" ],
    [ "分倍河原駅", "東京都", "京王線" ],
    [ "中河原駅", "東京都", "京王線" ],
    [ "聖蹟桜ヶ丘駅", "東京都", "京王線" ],
    [ "百草園駅", "東京都", "京王線" ],
    [ "高幡不動駅", "東京都", "京王線" ],
    [ "南平駅", "東京都", "京王線" ],
    [ "平山城址公園駅", "東京都", "京王線" ],
    [ "長沼駅", "東京都", "京王線" ],
    [ "北野駅", "東京都", "京王線" ],
    [ "京王八王子駅", "東京都", "京王線" ],
    [ "調布駅", "東京都", "京王相模原線" ],
    [ "京王多摩川駅", "東京都", "京王相模原線" ],
    [ "京王稲田堤駅", "神奈川県", "京王相模原線" ],
    [ "京王よみうりランド駅", "東京都", "京王相模原線" ],
    [ "稲城駅", "東京都", "京王相模原線" ],
    [ "若葉台駅", "神奈川県", "京王相模原線" ],
    [ "京王永山駅", "東京都", "京王相模原線" ],
    [ "京王多摩センター駅", "東京都", "京王相模原線" ],
    [ "京王堀之内駅", "東京都", "京王相模原線" ],
    [ "南大沢駅", "東京都", "京王相模原線" ],
    [ "多摩境駅", "東京都", "京王相模原線" ],
    [ "橋本駅", "神奈川県", "京王相模原線" ],
    [ "北野駅", "東京都", "京王高尾線" ],
    [ "京王片倉駅", "東京都", "京王高尾線" ],
    [ "山田駅", "東京都", "京王高尾線" ],
    [ "めじろ台駅", "東京都", "京王高尾線" ],
    [ "狭間駅", "東京都", "京王高尾線" ],
    [ "高尾駅", "東京都", "京王高尾線" ],
    [ "高尾山口駅", "東京都", "京王高尾線" ],
    [ "東府中駅", "東京都", "京王競馬場線" ],
    [ "府中競馬正門前駅", "東京都", "京王競馬場線" ],
    [ "高幡不動駅", "東京都", "京王動物園線" ],
    [ "多摩動物公園駅", "東京都", "京王動物園線" ],
    [ "渋谷駅", "東京都", "京王井の頭線" ],
    [ "神泉駅", "東京都", "京王井の頭線" ],
    [ "駒場東大前駅", "東京都", "京王井の頭線" ],
    [ "池ノ上駅", "東京都", "京王井の頭線" ],
    [ "下北沢駅", "東京都", "京王井の頭線" ],
    [ "新代田駅", "東京都", "京王井の頭線" ],
    [ "東松原駅", "東京都", "京王井の頭線" ],
    [ "明大前駅", "東京都", "京王井の頭線" ],
    [ "永福町駅", "東京都", "京王井の頭線" ],
    [ "西永福駅", "東京都", "京王井の頭線" ],
    [ "浜田山駅", "東京都", "京王井の頭線" ],
    [ "高井戸駅", "東京都", "京王井の頭線" ],
    [ "富士見ヶ丘駅", "東京都", "京王井の頭線" ],
    [ "久我山駅", "東京都", "京王井の頭線" ],
    [ "三鷹台駅", "東京都", "京王井の頭線" ],
    [ "井の頭公園駅", "東京都", "京王井の頭線" ],
    [ "吉祥寺駅", "東京都", "京王井の頭線" ],
    [ "新線新宿駅", "東京都", "京王新線" ],
    [ "初台駅", "東京都", "京王新線" ],
    [ "幡ヶ谷駅", "東京都", "京王新線" ],
    [ "笹塚駅", "東京都", "京王新線" ],
    [ "新宿駅", "東京都", "小田急線" ],
    [ "南新宿駅", "東京都", "小田急線" ],
    [ "参宮橋駅", "東京都", "小田急線" ],
    [ "代々木八幡駅", "東京都", "小田急線" ],
    [ "代々木上原駅", "東京都", "小田急線" ],
    [ "東北沢駅", "東京都", "小田急線" ],
    [ "下北沢駅", "東京都", "小田急線" ],
    [ "世田谷代田駅", "東京都", "小田急線" ],
    [ "梅ヶ丘駅", "東京都", "小田急線" ],
    [ "豪徳寺駅", "東京都", "小田急線" ],
    [ "経堂駅", "東京都", "小田急線" ],
    [ "千歳船橋駅", "東京都", "小田急線" ],
    [ "祖師ヶ谷大蔵駅", "東京都", "小田急線" ],
    [ "成城学園前駅", "東京都", "小田急線" ],
    [ "喜多見駅", "東京都", "小田急線" ],
    [ "狛江駅", "東京都", "小田急線" ],
    [ "和泉多摩川駅", "東京都", "小田急線" ],
    [ "登戸駅", "神奈川県", "小田急線" ],
    [ "向ヶ丘遊園駅", "神奈川県", "小田急線" ],
    [ "生田駅", "神奈川県", "小田急線" ],
    [ "読売ランド前駅", "神奈川県", "小田急線" ],
    [ "百合ヶ丘駅", "神奈川県", "小田急線" ],
    [ "新百合ヶ丘駅", "神奈川県", "小田急線" ],
    [ "柿生駅", "神奈川県", "小田急線" ],
    [ "鶴川駅", "東京都", "小田急線" ],
    [ "玉川学園前駅", "東京都", "小田急線" ],
    [ "町田駅", "東京都", "小田急線" ],
    [ "相模大野駅", "神奈川県", "小田急線" ],
    [ "小田急相模原駅", "神奈川県", "小田急線" ],
    [ "相武台前駅", "神奈川県", "小田急線" ],
    [ "座間駅", "神奈川県", "小田急線" ],
    [ "海老名駅", "神奈川県", "小田急線" ],
    [ "厚木駅", "神奈川県", "小田急線" ],
    [ "本厚木駅", "神奈川県", "小田急線" ],
    [ "愛甲石田駅", "神奈川県", "小田急線" ],
    [ "伊勢原駅", "神奈川県", "小田急線" ],
    [ "鶴巻温泉駅", "神奈川県", "小田急線" ],
    [ "東海大学前駅", "神奈川県", "小田急線" ],
    [ "秦野駅", "神奈川県", "小田急線" ],
    [ "渋沢駅", "神奈川県", "小田急線" ],
    [ "新松田駅", "神奈川県", "小田急線" ],
    [ "開成駅", "神奈川県", "小田急線" ],
    [ "栢山駅", "神奈川県", "小田急線" ],
    [ "富水駅", "神奈川県", "小田急線" ],
    [ "螢田駅", "神奈川県", "小田急線" ],
    [ "足柄駅", "神奈川県", "小田急線" ],
    [ "小田原駅", "神奈川県", "小田急線" ],
    [ "相模大野駅", "神奈川県", "小田急江ノ島線" ],
    [ "東林間駅", "神奈川県", "小田急江ノ島線" ],
    [ "中央林間駅", "神奈川県", "小田急江ノ島線" ],
    [ "南林間駅", "神奈川県", "小田急江ノ島線" ],
    [ "鶴間駅", "神奈川県", "小田急江ノ島線" ],
    [ "大和駅", "神奈川県", "小田急江ノ島線" ],
    [ "桜ヶ丘駅", "神奈川県", "小田急江ノ島線" ],
    [ "高座渋谷駅", "神奈川県", "小田急江ノ島線" ],
    [ "長後駅", "神奈川県", "小田急江ノ島線" ],
    [ "湘南台駅", "神奈川県", "小田急江ノ島線" ],
    [ "六会日大前駅", "神奈川県", "小田急江ノ島線" ],
    [ "善行駅", "神奈川県", "小田急江ノ島線" ],
    [ "藤沢本町駅", "神奈川県", "小田急江ノ島線" ],
    [ "藤沢駅", "神奈川県", "小田急江ノ島線" ],
    [ "本鵠沼駅", "神奈川県", "小田急江ノ島線" ],
    [ "鵠沼海岸駅", "神奈川県", "小田急江ノ島線" ],
    [ "片瀬江ノ島駅", "神奈川県", "小田急江ノ島線" ],
    [ "新百合ヶ丘駅", "神奈川県", "小田急多摩線" ],
    [ "五月台駅", "神奈川県", "小田急多摩線" ],
    [ "栗平駅", "神奈川県", "小田急多摩線" ],
    [ "黒川駅", "神奈川県", "小田急多摩線" ],
    [ "はるひ野駅", "神奈川県", "小田急多摩線" ],
    [ "小田急永山駅", "東京都", "小田急多摩線" ],
    [ "小田急多摩センター駅", "東京都", "小田急多摩線" ],
    [ "唐木田駅", "東京都", "小田急多摩線" ],
    [ "渋谷駅", "東京都", "東急東横線" ],
    [ "代官山駅", "東京都", "東急東横線" ],
    [ "中目黒駅", "東京都", "東急東横線" ],
    [ "祐天寺駅", "東京都", "東急東横線" ],
    [ "学芸大学駅", "東京都", "東急東横線" ],
    [ "都立大学駅", "東京都", "東急東横線" ],
    [ "自由が丘駅", "東京都", "東急東横線" ],
    [ "田園調布駅", "東京都", "東急東横線" ],
    [ "多摩川駅", "東京都", "東急東横線" ],
    [ "新丸子駅", "神奈川県", "東急東横線" ],
    [ "武蔵小杉駅", "神奈川県", "東急東横線" ],
    [ "元住吉駅", "神奈川県", "東急東横線" ],
    [ "日吉駅", "神奈川県", "東急東横線" ],
    [ "綱島駅", "神奈川県", "東急東横線" ],
    [ "大倉山駅", "神奈川県", "東急東横線" ],
    [ "菊名駅", "神奈川県", "東急東横線" ],
    [ "妙蓮寺駅", "神奈川県", "東急東横線" ],
    [ "白楽駅", "神奈川県", "東急東横線" ],
    [ "東白楽駅", "神奈川県", "東急東横線" ],
    [ "反町駅", "神奈川県", "東急東横線" ],
    [ "横浜駅", "神奈川県", "東急東横線" ],
    [ "目黒駅", "東京都", "東急目黒線" ],
    [ "不動前駅", "東京都", "東急目黒線" ],
    [ "武蔵小山駅", "東京都", "東急目黒線" ],
    [ "西小山駅", "東京都", "東急目黒線" ],
    [ "洗足駅", "東京都", "東急目黒線" ],
    [ "大岡山駅", "東京都", "東急目黒線" ],
    [ "奥沢駅", "東京都", "東急目黒線" ],
    [ "田園調布駅", "東京都", "東急目黒線" ],
    [ "多摩川駅", "東京都", "東急目黒線" ],
    [ "新丸子駅", "神奈川県", "東急目黒線" ],
    [ "武蔵小杉駅", "神奈川県", "東急目黒線" ],
    [ "元住吉駅", "神奈川県", "東急目黒線" ],
    [ "日吉駅", "神奈川県", "東急目黒線" ],
    [ "渋谷駅", "東京都", "東急田園都市線" ],
    [ "池尻大橋駅", "東京都", "東急田園都市線" ],
    [ "三軒茶屋駅", "東京都", "東急田園都市線" ],
    [ "駒沢大学駅", "東京都", "東急田園都市線" ],
    [ "桜新町駅", "東京都", "東急田園都市線" ],
    [ "用賀駅", "東京都", "東急田園都市線" ],
    [ "二子玉川駅", "東京都", "東急田園都市線" ],
    [ "二子新地駅", "神奈川県", "東急田園都市線" ],
    [ "高津駅", "神奈川県", "東急田園都市線" ],
    [ "溝の口駅", "神奈川県", "東急田園都市線" ],
    [ "梶が谷駅", "神奈川県", "東急田園都市線" ],
    [ "宮崎台駅", "神奈川県", "東急田園都市線" ],
    [ "宮前平駅", "神奈川県", "東急田園都市線" ],
    [ "鷺沼駅", "神奈川県", "東急田園都市線" ],
    [ "たまプラーザ駅", "神奈川県", "東急田園都市線" ],
    [ "あざみ野駅", "神奈川県", "東急田園都市線" ],
    [ "江田駅", "神奈川県", "東急田園都市線" ],
    [ "市が尾駅", "神奈川県", "東急田園都市線" ],
    [ "藤が丘駅", "神奈川県", "東急田園都市線" ],
    [ "青葉台駅", "神奈川県", "東急田園都市線" ],
    [ "田奈駅", "神奈川県", "東急田園都市線" ],
    [ "長津田駅", "神奈川県", "東急田園都市線" ],
    [ "つくし野駅", "東京都", "東急田園都市線" ],
    [ "すずかけ台駅", "東京都", "東急田園都市線" ],
    [ "南町田グランベリーパーク駅", "東京都", "東急田園都市線" ],
    [ "つきみ野駅", "神奈川県", "東急田園都市線" ],
    [ "中央林間駅", "神奈川県", "東急田園都市線" ],
    [ "大井町駅", "東京都", "東急大井町線" ],
    [ "下神明駅", "東京都", "東急大井町線" ],
    [ "戸越公園駅", "東京都", "東急大井町線" ],
    [ "中延駅", "東京都", "東急大井町線" ],
    [ "荏原町駅", "東京都", "東急大井町線" ],
    [ "旗の台駅", "東京都", "東急大井町線" ],
    [ "北千束駅", "東京都", "東急大井町線" ],
    [ "大岡山駅", "東京都", "東急大井町線" ],
    [ "緑が丘駅", "東京都", "東急大井町線" ],
    [ "自由が丘駅", "東京都", "東急大井町線" ],
    [ "九品仏駅", "東京都", "東急大井町線" ],
    [ "尾山台駅", "東京都", "東急大井町線" ],
    [ "等々力駅", "東京都", "東急大井町線" ],
    [ "上野毛駅", "東京都", "東急大井町線" ],
    [ "二子玉川駅", "東京都", "東急大井町線" ],
    [ "二子新地駅", "神奈川県", "東急大井町線" ],
    [ "高津駅", "神奈川県", "東急大井町線" ],
    [ "溝の口駅", "神奈川県", "東急大井町線" ],
    [ "五反田駅", "東京都", "東急池上線" ],
    [ "大崎広小路駅", "東京都", "東急池上線" ],
    [ "戸越銀座駅", "東京都", "東急池上線" ],
    [ "荏原中延駅", "東京都", "東急池上線" ],
    [ "旗の台駅", "東京都", "東急池上線" ],
    [ "長原駅", "東京都", "東急池上線" ],
    [ "洗足池駅", "東京都", "東急池上線" ],
    [ "石川台駅", "東京都", "東急池上線" ],
    [ "雪が谷大塚駅", "東京都", "東急池上線" ],
    [ "御嶽山駅", "東京都", "東急池上線" ],
    [ "久が原駅", "東京都", "東急池上線" ],
    [ "千鳥町駅", "東京都", "東急池上線" ],
    [ "池上駅", "東京都", "東急池上線" ],
    [ "蓮沼駅", "東京都", "東急池上線" ],
    [ "蒲田駅", "東京都", "東急池上線" ],
    [ "多摩川駅", "東京都", "東急多摩川線" ],
    [ "沼部駅", "東京都", "東急多摩川線" ],
    [ "鵜の木駅", "東京都", "東急多摩川線" ],
    [ "下丸子駅", "東京都", "東急多摩川線" ],
    [ "武蔵新田駅", "東京都", "東急多摩川線" ],
    [ "矢口渡駅", "東京都", "東急多摩川線" ],
    [ "蒲田駅", "東京都", "東急多摩川線" ],
    [ "三軒茶屋駅", "東京都", "東急世田谷線" ],
    [ "西太子堂駅", "東京都", "東急世田谷線" ],
    [ "若林駅", "東京都", "東急世田谷線" ],
    [ "松陰神社前駅", "東京都", "東急世田谷線" ],
    [ "世田谷駅", "東京都", "東急世田谷線" ],
    [ "上町駅", "東京都", "東急世田谷線" ],
    [ "宮の坂駅", "東京都", "東急世田谷線" ],
    [ "山下駅", "東京都", "東急世田谷線" ],
    [ "松原駅", "東京都", "東急世田谷線" ],
    [ "下高井戸駅", "東京都", "東急世田谷線" ],
    [ "長津田駅", "神奈川県", "東急こどもの国線" ],
    [ "恩田駅", "神奈川県", "東急こどもの国線" ],
    [ "こどもの国駅", "神奈川県", "東急こどもの国線" ],
    [ "泉岳寺駅", "東京都", "京急本線" ],
    [ "品川駅", "東京都", "京急本線" ],
    [ "北品川駅", "東京都", "京急本線" ],
    [ "新馬場駅", "東京都", "京急本線" ],
    [ "青物横丁駅", "東京都", "京急本線" ],
    [ "鮫洲駅", "東京都", "京急本線" ],
    [ "立会川駅", "東京都", "京急本線" ],
    [ "大森海岸駅", "東京都", "京急本線" ],
    [ "平和島駅", "東京都", "京急本線" ],
    [ "大森町駅", "東京都", "京急本線" ],
    [ "梅屋敷駅", "東京都", "京急本線" ],
    [ "京急蒲田駅", "東京都", "京急本線" ],
    [ "雑色駅", "東京都", "京急本線" ],
    [ "六郷土手駅", "東京都", "京急本線" ],
    [ "京急川崎駅", "神奈川県", "京急本線" ],
    [ "八丁畷駅", "神奈川県", "京急本線" ],
    [ "鶴見市場駅", "神奈川県", "京急本線" ],
    [ "京急鶴見駅", "神奈川県", "京急本線" ],
    [ "花月総持寺駅", "神奈川県", "京急本線" ],
    [ "生麦駅", "神奈川県", "京急本線" ],
    [ "京急新子安駅", "神奈川県", "京急本線" ],
    [ "子安駅", "神奈川県", "京急本線" ],
    [ "神奈川新町駅", "神奈川県", "京急本線" ],
    [ "京急東神奈川駅", "神奈川県", "京急本線" ],
    [ "神奈川駅", "神奈川県", "京急本線" ],
    [ "横浜駅", "神奈川県", "京急本線" ],
    [ "戸部駅", "神奈川県", "京急本線" ],
    [ "日ノ出町駅", "神奈川県", "京急本線" ],
    [ "黄金町駅", "神奈川県", "京急本線" ],
    [ "南太田駅", "神奈川県", "京急本線" ],
    [ "井土ヶ谷駅", "神奈川県", "京急本線" ],
    [ "弘明寺駅", "神奈川県", "京急本線" ],
    [ "上大岡駅", "神奈川県", "京急本線" ],
    [ "屏風浦駅", "神奈川県", "京急本線" ],
    [ "杉田駅", "神奈川県", "京急本線" ],
    [ "京急富岡駅", "神奈川県", "京急本線" ],
    [ "能見台駅", "神奈川県", "京急本線" ],
    [ "金沢文庫駅", "神奈川県", "京急本線" ],
    [ "金沢八景駅", "神奈川県", "京急本線" ],
    [ "追浜駅", "神奈川県", "京急本線" ],
    [ "京急田浦駅", "神奈川県", "京急本線" ],
    [ "安針塚駅", "神奈川県", "京急本線" ],
    [ "逸見駅", "神奈川県", "京急本線" ],
    [ "汐入駅", "神奈川県", "京急本線" ],
    [ "横須賀中央駅", "神奈川県", "京急本線" ],
    [ "県立大学駅", "神奈川県", "京急本線" ],
    [ "堀ノ内駅", "神奈川県", "京急本線" ],
    [ "京急大津駅", "神奈川県", "京急本線" ],
    [ "馬堀海岸駅", "神奈川県", "京急本線" ],
    [ "浦賀駅", "神奈川県", "京急本線" ],
    [ "京急蒲田駅", "東京都", "京急空港線" ],
    [ "糀谷駅", "東京都", "京急空港線" ],
    [ "大鳥居駅", "東京都", "京急空港線" ],
    [ "穴守稲荷駅", "東京都", "京急空港線" ],
    [ "天空橋駅", "東京都", "京急空港線" ],
    [ "羽田空港第3ターミナル駅", "東京都", "京急空港線" ],
    [ "羽田空港第1・第2ターミナル駅", "東京都", "京急空港線" ],
    [ "京急川崎駅", "神奈川県", "京急大師線" ],
    [ "港町駅", "神奈川県", "京急大師線" ],
    [ "鈴木町駅", "神奈川県", "京急大師線" ],
    [ "川崎大師駅", "神奈川県", "京急大師線" ],
    [ "東門前駅", "神奈川県", "京急大師線" ],
    [ "大師橋駅", "神奈川県", "京急大師線" ],
    [ "小島新田駅", "神奈川県", "京急大師線" ],
    [ "金沢八景駅", "神奈川県", "京急逗子線" ],
    [ "六浦駅", "神奈川県", "京急逗子線" ],
    [ "神武寺駅", "神奈川県", "京急逗子線" ],
    [ "逗子・葉山駅", "神奈川県", "京急逗子線" ],
    [ "堀ノ内駅", "神奈川県", "京急久里浜線" ],
    [ "新大津駅", "神奈川県", "京急久里浜線" ],
    [ "北久里浜駅", "神奈川県", "京急久里浜線" ],
    [ "京急久里浜駅", "神奈川県", "京急久里浜線" ],
    [ "ＹＲＰ野比駅", "神奈川県", "京急久里浜線" ],
    [ "京急長沢駅", "神奈川県", "京急久里浜線" ],
    [ "津久井浜駅", "神奈川県", "京急久里浜線" ],
    [ "三浦海岸駅", "神奈川県", "京急久里浜線" ],
    [ "三崎口駅", "神奈川県", "京急久里浜線" ],
    [ "浅草駅", "東京都", "東京メトロ銀座線" ],
    [ "田原町駅", "東京都", "東京メトロ銀座線" ],
    [ "稲荷町駅", "東京都", "東京メトロ銀座線" ],
    [ "上野駅", "東京都", "東京メトロ銀座線" ],
    [ "上野広小路駅", "東京都", "東京メトロ銀座線" ],
    [ "末広町駅", "東京都", "東京メトロ銀座線" ],
    [ "神田駅", "東京都", "東京メトロ銀座線" ],
    [ "三越前駅", "東京都", "東京メトロ銀座線" ],
    [ "日本橋駅", "東京都", "東京メトロ銀座線" ],
    [ "京橋駅", "東京都", "東京メトロ銀座線" ],
    [ "銀座駅", "東京都", "東京メトロ銀座線" ],
    [ "新橋駅", "東京都", "東京メトロ銀座線" ],
    [ "虎ノ門駅", "東京都", "東京メトロ銀座線" ],
    [ "溜池山王駅", "東京都", "東京メトロ銀座線" ],
    [ "赤坂見附駅", "東京都", "東京メトロ銀座線" ],
    [ "青山一丁目駅", "東京都", "東京メトロ銀座線" ],
    [ "外苑前駅", "東京都", "東京メトロ銀座線" ],
    [ "表参道駅", "東京都", "東京メトロ銀座線" ],
    [ "渋谷駅", "東京都", "東京メトロ銀座線" ],
    [ "池袋駅", "東京都", "東京メトロ丸ノ内線" ],
    [ "新大塚駅", "東京都", "東京メトロ丸ノ内線" ],
    [ "茗荷谷駅", "東京都", "東京メトロ丸ノ内線" ],
    [ "後楽園駅", "東京都", "東京メトロ丸ノ内線" ],
    [ "本郷三丁目駅", "東京都", "東京メトロ丸ノ内線" ],
    [ "御茶ノ水駅", "東京都", "東京メトロ丸ノ内線" ],
    [ "淡路町駅", "東京都", "東京メトロ丸ノ内線" ],
    [ "大手町駅", "東京都", "東京メトロ丸ノ内線" ],
    [ "東京駅", "東京都", "東京メトロ丸ノ内線" ],
    [ "銀座駅", "東京都", "東京メトロ丸ノ内線" ],
    [ "霞ケ関駅", "東京都", "東京メトロ丸ノ内線" ],
    [ "国会議事堂前駅", "東京都", "東京メトロ丸ノ内線" ],
    [ "赤坂見附駅", "東京都", "東京メトロ丸ノ内線" ],
    [ "四ツ谷駅", "東京都", "東京メトロ丸ノ内線" ],
    [ "四谷三丁目駅", "東京都", "東京メトロ丸ノ内線" ],
    [ "新宿御苑前駅", "東京都", "東京メトロ丸ノ内線" ],
    [ "新宿三丁目駅", "東京都", "東京メトロ丸ノ内線" ],
    [ "新宿駅", "東京都", "東京メトロ丸ノ内線" ],
    [ "西新宿駅", "東京都", "東京メトロ丸ノ内線" ],
    [ "中野坂上駅", "東京都", "東京メトロ丸ノ内線" ],
    [ "新中野駅", "東京都", "東京メトロ丸ノ内線" ],
    [ "東高円寺駅", "東京都", "東京メトロ丸ノ内線" ],
    [ "新高円寺駅", "東京都", "東京メトロ丸ノ内線" ],
    [ "南阿佐ケ谷駅", "東京都", "東京メトロ丸ノ内線" ],
    [ "荻窪駅", "東京都", "東京メトロ丸ノ内線" ],
    [ "中野新橋駅", "東京都", "東京メトロ丸ノ内線" ],
    [ "中野富士見町駅", "東京都", "東京メトロ丸ノ内線" ],
    [ "方南町駅", "東京都", "東京メトロ丸ノ内線" ],
    [ "北千住駅", "東京都", "東京メトロ日比谷線" ],
    [ "南千住駅", "東京都", "東京メトロ日比谷線" ],
    [ "三ノ輪駅", "東京都", "東京メトロ日比谷線" ],
    [ "入谷駅", "東京都", "東京メトロ日比谷線" ],
    [ "上野駅", "東京都", "東京メトロ日比谷線" ],
    [ "仲御徒町駅", "東京都", "東京メトロ日比谷線" ],
    [ "秋葉原駅", "東京都", "東京メトロ日比谷線" ],
    [ "小伝馬町駅", "東京都", "東京メトロ日比谷線" ],
    [ "人形町駅", "東京都", "東京メトロ日比谷線" ],
    [ "茅場町駅", "東京都", "東京メトロ日比谷線" ],
    [ "八丁堀駅", "東京都", "東京メトロ日比谷線" ],
    [ "築地駅", "東京都", "東京メトロ日比谷線" ],
    [ "東銀座駅", "東京都", "東京メトロ日比谷線" ],
    [ "銀座駅", "東京都", "東京メトロ日比谷線" ],
    [ "日比谷駅", "東京都", "東京メトロ日比谷線" ],
    [ "霞ケ関駅", "東京都", "東京メトロ日比谷線" ],
    [ "虎ノ門ヒルズ駅", "東京都", "東京メトロ日比谷線" ],
    [ "神谷町駅", "東京都", "東京メトロ日比谷線" ],
    [ "六本木駅", "東京都", "東京メトロ日比谷線" ],
    [ "広尾駅", "東京都", "東京メトロ日比谷線" ],
    [ "恵比寿駅", "東京都", "東京メトロ日比谷線" ],
    [ "中目黒駅", "東京都", "東京メトロ日比谷線" ],
    [ "中野駅", "東京都", "東京メトロ東西線" ],
    [ "落合駅", "東京都", "東京メトロ東西線" ],
    [ "高田馬場駅", "東京都", "東京メトロ東西線" ],
    [ "早稲田駅", "東京都", "東京メトロ東西線" ],
    [ "神楽坂駅", "東京都", "東京メトロ東西線" ],
    [ "飯田橋駅", "東京都", "東京メトロ東西線" ],
    [ "九段下駅", "東京都", "東京メトロ東西線" ],
    [ "竹橋駅", "東京都", "東京メトロ東西線" ],
    [ "大手町駅", "東京都", "東京メトロ東西線" ],
    [ "日本橋駅", "東京都", "東京メトロ東西線" ],
    [ "茅場町駅", "東京都", "東京メトロ東西線" ],
    [ "門前仲町駅", "東京都", "東京メトロ東西線" ],
    [ "木場駅", "東京都", "東京メトロ東西線" ],
    [ "東陽町駅", "東京都", "東京メトロ東西線" ],
    [ "南砂町駅", "東京都", "東京メトロ東西線" ],
    [ "西葛西駅", "東京都", "東京メトロ東西線" ],
    [ "葛西駅", "東京都", "東京メトロ東西線" ],
    [ "浦安駅", "千葉県", "東京メトロ東西線" ],
    [ "南行徳駅", "千葉県", "東京メトロ東西線" ],
    [ "行徳駅", "千葉県", "東京メトロ東西線" ],
    [ "妙典駅", "千葉県", "東京メトロ東西線" ],
    [ "原木中山駅", "千葉県", "東京メトロ東西線" ],
    [ "西船橋駅", "千葉県", "東京メトロ東西線" ],
    [ "北綾瀬駅", "東京都", "東京メトロ千代田線" ],
    [ "綾瀬駅", "東京都", "東京メトロ千代田線" ],
    [ "北千住駅", "東京都", "東京メトロ千代田線" ],
    [ "町屋駅", "東京都", "東京メトロ千代田線" ],
    [ "西日暮里駅", "東京都", "東京メトロ千代田線" ],
    [ "千駄木駅", "東京都", "東京メトロ千代田線" ],
    [ "根津駅", "東京都", "東京メトロ千代田線" ],
    [ "湯島駅", "東京都", "東京メトロ千代田線" ],
    [ "新御茶ノ水駅", "東京都", "東京メトロ千代田線" ],
    [ "大手町駅", "東京都", "東京メトロ千代田線" ],
    [ "二重橋前駅", "東京都", "東京メトロ千代田線" ],
    [ "日比谷駅", "東京都", "東京メトロ千代田線" ],
    [ "霞ケ関駅", "東京都", "東京メトロ千代田線" ],
    [ "国会議事堂前駅", "東京都", "東京メトロ千代田線" ],
    [ "赤坂駅", "東京都", "東京メトロ千代田線" ],
    [ "乃木坂駅", "東京都", "東京メトロ千代田線" ],
    [ "表参道駅", "東京都", "東京メトロ千代田線" ],
    [ "明治神宮前〈原宿〉駅", "東京都", "東京メトロ千代田線" ],
    [ "代々木公園駅", "東京都", "東京メトロ千代田線" ],
    [ "代々木上原駅", "東京都", "東京メトロ千代田線" ],
    [ "和光市駅", "埼玉県", "東京メトロ有楽町線" ],
    [ "地下鉄成増駅", "東京都", "東京メトロ有楽町線" ],
    [ "地下鉄赤塚駅", "東京都", "東京メトロ有楽町線" ],
    [ "平和台駅", "東京都", "東京メトロ有楽町線" ],
    [ "氷川台駅", "東京都", "東京メトロ有楽町線" ],
    [ "小竹向原駅", "東京都", "東京メトロ有楽町線" ],
    [ "千川駅", "東京都", "東京メトロ有楽町線" ],
    [ "要町駅", "東京都", "東京メトロ有楽町線" ],
    [ "池袋駅", "東京都", "東京メトロ有楽町線" ],
    [ "東池袋駅", "東京都", "東京メトロ有楽町線" ],
    [ "護国寺駅", "東京都", "東京メトロ有楽町線" ],
    [ "江戸川橋駅", "東京都", "東京メトロ有楽町線" ],
    [ "飯田橋駅", "東京都", "東京メトロ有楽町線" ],
    [ "市ケ谷駅", "東京都", "東京メトロ有楽町線" ],
    [ "麹町駅", "東京都", "東京メトロ有楽町線" ],
    [ "永田町駅", "東京都", "東京メトロ有楽町線" ],
    [ "桜田門駅", "東京都", "東京メトロ有楽町線" ],
    [ "有楽町駅", "東京都", "東京メトロ有楽町線" ],
    [ "銀座一丁目駅", "東京都", "東京メトロ有楽町線" ],
    [ "新富町駅", "東京都", "東京メトロ有楽町線" ],
    [ "月島駅", "東京都", "東京メトロ有楽町線" ],
    [ "豊洲駅", "東京都", "東京メトロ有楽町線" ],
    [ "辰巳駅", "東京都", "東京メトロ有楽町線" ],
    [ "新木場駅", "東京都", "東京メトロ有楽町線" ],
    [ "渋谷駅", "東京都", "東京メトロ半蔵門線" ],
    [ "表参道駅", "東京都", "東京メトロ半蔵門線" ],
    [ "青山一丁目駅", "東京都", "東京メトロ半蔵門線" ],
    [ "永田町駅", "東京都", "東京メトロ半蔵門線" ],
    [ "半蔵門駅", "東京都", "東京メトロ半蔵門線" ],
    [ "九段下駅", "東京都", "東京メトロ半蔵門線" ],
    [ "神保町駅", "東京都", "東京メトロ半蔵門線" ],
    [ "大手町駅", "東京都", "東京メトロ半蔵門線" ],
    [ "三越前駅", "東京都", "東京メトロ半蔵門線" ],
    [ "水天宮前駅", "東京都", "東京メトロ半蔵門線" ],
    [ "清澄白河駅", "東京都", "東京メトロ半蔵門線" ],
    [ "住吉駅", "東京都", "東京メトロ半蔵門線" ],
    [ "錦糸町駅", "東京都", "東京メトロ半蔵門線" ],
    [ "押上〈スカイツリー前〉駅", "東京都", "東京メトロ半蔵門線" ],
    [ "赤羽岩淵駅", "東京都", "東京メトロ南北線" ],
    [ "志茂駅", "東京都", "東京メトロ南北線" ],
    [ "王子神谷駅", "東京都", "東京メトロ南北線" ],
    [ "王子駅", "東京都", "東京メトロ南北線" ],
    [ "西ケ原駅", "東京都", "東京メトロ南北線" ],
    [ "駒込駅", "東京都", "東京メトロ南北線" ],
    [ "本駒込駅", "東京都", "東京メトロ南北線" ],
    [ "東大前駅", "東京都", "東京メトロ南北線" ],
    [ "後楽園駅", "東京都", "東京メトロ南北線" ],
    [ "飯田橋駅", "東京都", "東京メトロ南北線" ],
    [ "市ケ谷駅", "東京都", "東京メトロ南北線" ],
    [ "四ツ谷駅", "東京都", "東京メトロ南北線" ],
    [ "永田町駅", "東京都", "東京メトロ南北線" ],
    [ "溜池山王駅", "東京都", "東京メトロ南北線" ],
    [ "六本木一丁目駅", "東京都", "東京メトロ南北線" ],
    [ "麻布十番駅", "東京都", "東京メトロ南北線" ],
    [ "白金高輪駅", "東京都", "東京メトロ南北線" ],
    [ "白金台駅", "東京都", "東京メトロ南北線" ],
    [ "目黒駅", "東京都", "東京メトロ南北線" ],
    [ "和光市駅", "埼玉県", "東京メトロ副都心線" ],
    [ "地下鉄成増駅", "東京都", "東京メトロ副都心線" ],
    [ "地下鉄赤塚駅", "東京都", "東京メトロ副都心線" ],
    [ "平和台駅", "東京都", "東京メトロ副都心線" ],
    [ "氷川台駅", "東京都", "東京メトロ副都心線" ],
    [ "小竹向原駅", "東京都", "東京メトロ副都心線" ],
    [ "千川駅", "東京都", "東京メトロ副都心線" ],
    [ "要町駅", "東京都", "東京メトロ副都心線" ],
    [ "池袋駅", "東京都", "東京メトロ副都心線" ],
    [ "雑司が谷駅", "東京都", "東京メトロ副都心線" ],
    [ "西早稲田駅", "東京都", "東京メトロ副都心線" ],
    [ "東新宿駅", "東京都", "東京メトロ副都心線" ],
    [ "新宿三丁目駅", "東京都", "東京メトロ副都心線" ],
    [ "北参道駅", "東京都", "東京メトロ副都心線" ],
    [ "明治神宮前〈原宿〉駅", "東京都", "東京メトロ副都心線" ],
    [ "渋谷駅", "東京都", "東京メトロ副都心線" ],
    [ "横浜駅", "神奈川県", "相鉄本線" ],
    [ "平沼橋駅", "神奈川県", "相鉄本線" ],
    [ "西横浜駅", "神奈川県", "相鉄本線" ],
    [ "天王町駅", "神奈川県", "相鉄本線" ],
    [ "星川駅", "神奈川県", "相鉄本線" ],
    [ "和田町駅", "神奈川県", "相鉄本線" ],
    [ "上星川駅", "神奈川県", "相鉄本線" ],
    [ "西谷駅", "神奈川県", "相鉄本線" ],
    [ "鶴ヶ峰駅", "神奈川県", "相鉄本線" ],
    [ "二俣川駅", "神奈川県", "相鉄本線" ],
    [ "希望ヶ丘駅", "神奈川県", "相鉄本線" ],
    [ "三ツ境駅", "神奈川県", "相鉄本線" ],
    [ "瀬谷駅", "神奈川県", "相鉄本線" ],
    [ "大和駅", "神奈川県", "相鉄本線" ],
    [ "相模大塚駅", "神奈川県", "相鉄本線" ],
    [ "さがみ野駅", "神奈川県", "相鉄本線" ],
    [ "かしわ台駅", "神奈川県", "相鉄本線" ],
    [ "海老名駅", "神奈川県", "相鉄本線" ],
    [ "二俣川駅", "神奈川県", "相鉄いずみ野線" ],
    [ "南万騎が原駅", "神奈川県", "相鉄いずみ野線" ],
    [ "緑園都市駅", "神奈川県", "相鉄いずみ野線" ],
    [ "弥生台駅", "神奈川県", "相鉄いずみ野線" ],
    [ "いずみ野駅", "神奈川県", "相鉄いずみ野線" ],
    [ "いずみ中央駅", "神奈川県", "相鉄いずみ野線" ],
    [ "ゆめが丘駅", "神奈川県", "相鉄いずみ野線" ],
    [ "湘南台駅", "神奈川県", "相鉄いずみ野線" ],
    [ "新宿駅", "東京都", "相鉄・JR直通線" ],
    [ "渋谷駅", "東京都", "相鉄・JR直通線" ],
    [ "恵比寿駅", "東京都", "相鉄・JR直通線" ],
    [ "大崎駅", "東京都", "相鉄・JR直通線" ],
    [ "西大井駅", "東京都", "相鉄・JR直通線" ],
    [ "武蔵小杉駅", "神奈川県", "相鉄・JR直通線" ],
    [ "羽沢横浜国大駅", "神奈川県", "相鉄・JR直通線" ],
    [ "西谷駅", "神奈川県", "相鉄・JR直通線" ],
    [ "鶴ヶ峰駅", "神奈川県", "相鉄・JR直通線" ],
    [ "二俣川駅", "神奈川県", "相鉄・JR直通線" ],
    [ "希望ヶ丘駅", "神奈川県", "相鉄・JR直通線" ],
    [ "三ツ境駅", "神奈川県", "相鉄・JR直通線" ],
    [ "瀬谷駅", "神奈川県", "相鉄・JR直通線" ],
    [ "大和駅", "神奈川県", "相鉄・JR直通線" ],
    [ "相模大塚駅", "神奈川県", "相鉄・JR直通線" ],
    [ "さがみ野駅", "神奈川県", "相鉄・JR直通線" ],
    [ "かしわ台駅", "神奈川県", "相鉄・JR直通線" ],
    [ "海老名駅", "神奈川県", "相鉄・JR直通線" ],
    [ "豊橋駅", "愛知県", "名鉄名古屋本線" ],
    [ "伊奈駅", "愛知県", "名鉄名古屋本線" ],
    [ "小田渕駅", "愛知県", "名鉄名古屋本線" ],
    [ "国府駅", "愛知県", "名鉄名古屋本線" ],
    [ "御油駅", "愛知県", "名鉄名古屋本線" ],
    [ "名電赤坂駅", "愛知県", "名鉄名古屋本線" ],
    [ "名電長沢駅", "愛知県", "名鉄名古屋本線" ],
    [ "本宿駅", "愛知県", "名鉄名古屋本線" ],
    [ "名電山中駅", "愛知県", "名鉄名古屋本線" ],
    [ "藤川駅", "愛知県", "名鉄名古屋本線" ],
    [ "美合駅", "愛知県", "名鉄名古屋本線" ],
    [ "男川駅", "愛知県", "名鉄名古屋本線" ],
    [ "東岡崎駅", "愛知県", "名鉄名古屋本線" ],
    [ "岡崎公園前駅", "愛知県", "名鉄名古屋本線" ],
    [ "矢作橋駅", "愛知県", "名鉄名古屋本線" ],
    [ "宇頭駅", "愛知県", "名鉄名古屋本線" ],
    [ "新安城駅", "愛知県", "名鉄名古屋本線" ],
    [ "牛田駅", "愛知県", "名鉄名古屋本線" ],
    [ "知立駅", "愛知県", "名鉄名古屋本線" ],
    [ "一ツ木駅", "愛知県", "名鉄名古屋本線" ],
    [ "富士松駅", "愛知県", "名鉄名古屋本線" ],
    [ "豊明駅", "愛知県", "名鉄名古屋本線" ],
    [ "前後駅", "愛知県", "名鉄名古屋本線" ],
    [ "中京競馬場前駅", "愛知県", "名鉄名古屋本線" ],
    [ "有松駅", "愛知県", "名鉄名古屋本線" ],
    [ "左京山駅", "愛知県", "名鉄名古屋本線" ],
    [ "鳴海駅", "愛知県", "名鉄名古屋本線" ],
    [ "本星崎駅", "愛知県", "名鉄名古屋本線" ],
    [ "本笠寺駅", "愛知県", "名鉄名古屋本線" ],
    [ "桜駅", "愛知県", "名鉄名古屋本線" ],
    [ "呼続駅", "愛知県", "名鉄名古屋本線" ],
    [ "堀田駅", "愛知県", "名鉄名古屋本線" ],
    [ "神宮前駅", "愛知県", "名鉄名古屋本線" ],
    [ "金山駅", "愛知県", "名鉄名古屋本線" ],
    [ "山王駅", "愛知県", "名鉄名古屋本線" ],
    [ "名鉄名古屋駅", "愛知県", "名鉄名古屋本線" ],
    [ "栄生駅", "愛知県", "名鉄名古屋本線" ],
    [ "東枇杷島駅", "愛知県", "名鉄名古屋本線" ],
    [ "西枇杷島駅", "愛知県", "名鉄名古屋本線" ],
    [ "二ツ杁駅", "愛知県", "名鉄名古屋本線" ],
    [ "新川橋駅", "愛知県", "名鉄名古屋本線" ],
    [ "須ヶ口駅", "愛知県", "名鉄名古屋本線" ],
    [ "丸ノ内駅", "愛知県", "名鉄名古屋本線" ],
    [ "新清洲駅", "愛知県", "名鉄名古屋本線" ],
    [ "大里駅", "愛知県", "名鉄名古屋本線" ],
    [ "奥田駅", "愛知県", "名鉄名古屋本線" ],
    [ "国府宮駅", "愛知県", "名鉄名古屋本線" ],
    [ "島氏永駅", "愛知県", "名鉄名古屋本線" ],
    [ "妙興寺駅", "愛知県", "名鉄名古屋本線" ],
    [ "名鉄一宮駅", "愛知県", "名鉄名古屋本線" ],
    [ "今伊勢駅", "愛知県", "名鉄名古屋本線" ],
    [ "石刀駅", "愛知県", "名鉄名古屋本線" ],
    [ "新木曽川駅", "愛知県", "名鉄名古屋本線" ],
    [ "黒田駅", "愛知県", "名鉄名古屋本線" ],
    [ "木曽川堤駅", "愛知県", "名鉄名古屋本線" ],
    [ "笠松駅", "岐阜県", "名鉄名古屋本線" ],
    [ "岐南駅", "岐阜県", "名鉄名古屋本線" ],
    [ "茶所駅", "岐阜県", "名鉄名古屋本線" ],
    [ "加納駅", "岐阜県", "名鉄名古屋本線" ],
    [ "名鉄岐阜駅", "岐阜県", "名鉄名古屋本線" ],
    [ "国府駅", "愛知県", "名鉄豊川線" ],
    [ "八幡駅", "愛知県", "名鉄豊川線" ],
    [ "諏訪町駅", "愛知県", "名鉄豊川線" ],
    [ "稲荷口駅", "愛知県", "名鉄豊川線" ],
    [ "豊川稲荷駅", "愛知県", "名鉄豊川線" ],
    [ "新安城駅", "愛知県", "名鉄西尾線" ],
    [ "北安城駅", "愛知県", "名鉄西尾線" ],
    [ "南安城駅", "愛知県", "名鉄西尾線" ],
    [ "碧海古井駅", "愛知県", "名鉄西尾線" ],
    [ "堀内公園駅", "愛知県", "名鉄西尾線" ],
    [ "桜井駅", "愛知県", "名鉄西尾線" ],
    [ "南桜井駅", "愛知県", "名鉄西尾線" ],
    [ "米津駅", "愛知県", "名鉄西尾線" ],
    [ "桜町前駅", "愛知県", "名鉄西尾線" ],
    [ "西尾口駅", "愛知県", "名鉄西尾線" ],
    [ "西尾駅", "愛知県", "名鉄西尾線" ],
    [ "福地駅", "愛知県", "名鉄西尾線" ],
    [ "鎌谷駅", "愛知県", "名鉄西尾線" ],
    [ "上横須賀駅", "愛知県", "名鉄西尾線" ],
    [ "三河荻原駅", "愛知県", "名鉄西尾線" ],
    [ "吉良吉田駅", "愛知県", "名鉄西尾線" ],
    [ "吉良吉田駅", "愛知県", "名鉄蒲郡線" ],
    [ "三河鳥羽駅", "愛知県", "名鉄蒲郡線" ],
    [ "西幡豆駅", "愛知県", "名鉄蒲郡線" ],
    [ "東幡豆駅", "愛知県", "名鉄蒲郡線" ],
    [ "こどもの国駅", "愛知県", "名鉄蒲郡線" ],
    [ "西浦駅", "愛知県", "名鉄蒲郡線" ],
    [ "形原駅", "愛知県", "名鉄蒲郡線" ],
    [ "三河鹿島駅", "愛知県", "名鉄蒲郡線" ],
    [ "蒲郡競艇場前駅", "愛知県", "名鉄蒲郡線" ],
    [ "蒲郡駅", "愛知県", "名鉄蒲郡線" ],
    [ "碧南駅", "愛知県", "名鉄三河線" ],
    [ "碧南中央駅", "愛知県", "名鉄三河線" ],
    [ "新川町駅", "愛知県", "名鉄三河線" ],
    [ "北新川駅", "愛知県", "名鉄三河線" ],
    [ "高浜港駅", "愛知県", "名鉄三河線" ],
    [ "三河高浜駅", "愛知県", "名鉄三河線" ],
    [ "吉浜駅", "愛知県", "名鉄三河線" ],
    [ "小垣江駅", "愛知県", "名鉄三河線" ],
    [ "刈谷市駅", "愛知県", "名鉄三河線" ],
    [ "刈谷駅", "愛知県", "名鉄三河線" ],
    [ "重原駅", "愛知県", "名鉄三河線" ],
    [ "知立駅", "愛知県", "名鉄三河線" ],
    [ "三河知立駅", "愛知県", "名鉄三河線" ],
    [ "三河八橋駅", "愛知県", "名鉄三河線" ],
    [ "若林駅", "愛知県", "名鉄三河線" ],
    [ "竹村駅", "愛知県", "名鉄三河線" ],
    [ "土橋駅", "愛知県", "名鉄三河線" ],
    [ "上挙母駅", "愛知県", "名鉄三河線" ],
    [ "豊田市駅", "愛知県", "名鉄三河線" ],
    [ "梅坪駅", "愛知県", "名鉄三河線" ],
    [ "越戸駅", "愛知県", "名鉄三河線" ],
    [ "平戸橋駅", "愛知県", "名鉄三河線" ],
    [ "猿投駅", "愛知県", "名鉄三河線" ],
    [ "豊田市駅", "愛知県", "名鉄豊田線" ],
    [ "梅坪駅", "愛知県", "名鉄豊田線" ],
    [ "上豊田駅", "愛知県", "名鉄豊田線" ],
    [ "浄水駅", "愛知県", "名鉄豊田線" ],
    [ "三好ヶ丘駅", "愛知県", "名鉄豊田線" ],
    [ "黒笹駅", "愛知県", "名鉄豊田線" ],
    [ "米野木駅", "愛知県", "名鉄豊田線" ],
    [ "日進駅", "愛知県", "名鉄豊田線" ],
    [ "赤池駅", "愛知県", "名鉄豊田線" ],
    [ "常滑駅", "愛知県", "名鉄空港線" ],
    [ "りんくう常滑駅", "愛知県", "名鉄空港線" ],
    [ "中部国際空港駅", "愛知県", "名鉄空港線" ],
    [ "名鉄名古屋駅", "愛知県", "名鉄常滑線" ],
    [ "山王駅", "愛知県", "名鉄常滑線" ],
    [ "金山駅", "愛知県", "名鉄常滑線" ],
    [ "神宮前駅", "愛知県", "名鉄常滑線" ],
    [ "豊田本町駅", "愛知県", "名鉄常滑線" ],
    [ "道徳駅", "愛知県", "名鉄常滑線" ],
    [ "大江駅", "愛知県", "名鉄常滑線" ],
    [ "大同町駅", "愛知県", "名鉄常滑線" ],
    [ "柴田駅", "愛知県", "名鉄常滑線" ],
    [ "名和駅", "愛知県", "名鉄常滑線" ],
    [ "聚楽園駅", "愛知県", "名鉄常滑線" ],
    [ "新日鉄前駅", "愛知県", "名鉄常滑線" ],
    [ "太田川駅", "愛知県", "名鉄常滑線" ],
    [ "尾張横須賀駅", "愛知県", "名鉄常滑線" ],
    [ "寺本駅", "愛知県", "名鉄常滑線" ],
    [ "朝倉駅", "愛知県", "名鉄常滑線" ],
    [ "古見駅", "愛知県", "名鉄常滑線" ],
    [ "長浦駅", "愛知県", "名鉄常滑線" ],
    [ "日長駅", "愛知県", "名鉄常滑線" ],
    [ "新舞子駅", "愛知県", "名鉄常滑線" ],
    [ "大野町駅", "愛知県", "名鉄常滑線" ],
    [ "西ノ口駅", "愛知県", "名鉄常滑線" ],
    [ "蒲池駅", "愛知県", "名鉄常滑線" ],
    [ "榎戸駅", "愛知県", "名鉄常滑線" ],
    [ "多屋駅", "愛知県", "名鉄常滑線" ],
    [ "常滑駅", "愛知県", "名鉄常滑線" ],
    [ "太田川駅", "愛知県", "名鉄河和線" ],
    [ "高横須賀駅", "愛知県", "名鉄河和線" ],
    [ "南加木屋駅", "愛知県", "名鉄河和線" ],
    [ "八幡新田駅", "愛知県", "名鉄河和線" ],
    [ "巽ヶ丘駅", "愛知県", "名鉄河和線" ],
    [ "白沢駅", "愛知県", "名鉄河和線" ],
    [ "坂部駅", "愛知県", "名鉄河和線" ],
    [ "阿久比駅", "愛知県", "名鉄河和線" ],
    [ "椋岡駅", "愛知県", "名鉄河和線" ],
    [ "植大駅", "愛知県", "名鉄河和線" ],
    [ "半田口駅", "愛知県", "名鉄河和線" ],
    [ "住吉町駅", "愛知県", "名鉄河和線" ],
    [ "知多半田駅", "愛知県", "名鉄河和線" ],
    [ "成岩駅", "愛知県", "名鉄河和線" ],
    [ "青山駅", "愛知県", "名鉄河和線" ],
    [ "上ゲ駅", "愛知県", "名鉄河和線" ],
    [ "知多武豊駅", "愛知県", "名鉄河和線" ],
    [ "富貴駅", "愛知県", "名鉄河和線" ],
    [ "布土駅", "愛知県", "名鉄河和線" ],
    [ "河和口駅", "愛知県", "名鉄河和線" ],
    [ "河和駅", "愛知県", "名鉄河和線" ],
    [ "富貴駅", "愛知県", "名鉄知多新線" ],
    [ "上野間駅", "愛知県", "名鉄知多新線" ],
    [ "美浜緑苑駅", "愛知県", "名鉄知多新線" ],
    [ "知多奥田駅", "愛知県", "名鉄知多新線" ],
    [ "野間駅", "愛知県", "名鉄知多新線" ],
    [ "内海駅", "愛知県", "名鉄知多新線" ],
    [ "大江駅", "愛知県", "名鉄築港線" ],
    [ "東名古屋港駅", "愛知県", "名鉄築港線" ],
    [ "栄町駅", "愛知県", "名鉄瀬戸線" ],
    [ "東大手駅", "愛知県", "名鉄瀬戸線" ],
    [ "清水駅", "愛知県", "名鉄瀬戸線" ],
    [ "尼ヶ坂駅", "愛知県", "名鉄瀬戸線" ],
    [ "森下駅", "愛知県", "名鉄瀬戸線" ],
    [ "大曽根駅", "愛知県", "名鉄瀬戸線" ],
    [ "矢田駅", "愛知県", "名鉄瀬戸線" ],
    [ "守山自衛隊前駅", "愛知県", "名鉄瀬戸線" ],
    [ "瓢箪山駅", "愛知県", "名鉄瀬戸線" ],
    [ "小幡駅", "愛知県", "名鉄瀬戸線" ],
    [ "喜多山駅", "愛知県", "名鉄瀬戸線" ],
    [ "大森・金城学院前駅", "愛知県", "名鉄瀬戸線" ],
    [ "印場駅", "愛知県", "名鉄瀬戸線" ],
    [ "旭前駅", "愛知県", "名鉄瀬戸線" ],
    [ "尾張旭駅", "愛知県", "名鉄瀬戸線" ],
    [ "三郷駅", "愛知県", "名鉄瀬戸線" ],
    [ "水野駅", "愛知県", "名鉄瀬戸線" ],
    [ "新瀬戸駅", "愛知県", "名鉄瀬戸線" ],
    [ "瀬戸市役所前駅", "愛知県", "名鉄瀬戸線" ],
    [ "尾張瀬戸駅", "愛知県", "名鉄瀬戸線" ],
    [ "須ヶ口駅", "愛知県", "名鉄津島線" ],
    [ "甚目寺駅", "愛知県", "名鉄津島線" ],
    [ "七宝駅", "愛知県", "名鉄津島線" ],
    [ "木田駅", "愛知県", "名鉄津島線" ],
    [ "青塚駅", "愛知県", "名鉄津島線" ],
    [ "勝幡駅", "愛知県", "名鉄津島線" ],
    [ "藤浪駅", "愛知県", "名鉄津島線" ],
    [ "津島駅", "愛知県", "名鉄津島線" ],
    [ "弥富駅", "愛知県", "名鉄尾西線" ],
    [ "弥富口駅", "愛知県", "名鉄尾西線" ],
    [ "五ノ三駅", "愛知県", "名鉄尾西線" ],
    [ "佐屋駅", "愛知県", "名鉄尾西線" ],
    [ "日比野駅", "愛知県", "名鉄尾西線" ],
    [ "津島駅", "愛知県", "名鉄尾西線" ],
    [ "町方駅", "愛知県", "名鉄尾西線" ],
    [ "六輪駅", "愛知県", "名鉄尾西線" ],
    [ "渕高駅", "愛知県", "名鉄尾西線" ],
    [ "丸渕駅", "愛知県", "名鉄尾西線" ],
    [ "上丸渕駅", "愛知県", "名鉄尾西線" ],
    [ "森上駅", "愛知県", "名鉄尾西線" ],
    [ "山崎駅", "愛知県", "名鉄尾西線" ],
    [ "玉野駅", "愛知県", "名鉄尾西線" ],
    [ "萩原駅", "愛知県", "名鉄尾西線" ],
    [ "二子駅", "愛知県", "名鉄尾西線" ],
    [ "苅安賀駅", "愛知県", "名鉄尾西線" ],
    [ "観音寺駅", "愛知県", "名鉄尾西線" ],
    [ "名鉄一宮駅", "愛知県", "名鉄尾西線" ],
    [ "西一宮駅", "愛知県", "名鉄尾西線" ],
    [ "開明駅", "愛知県", "名鉄尾西線" ],
    [ "奥町駅", "愛知県", "名鉄尾西線" ],
    [ "玉ノ井駅", "愛知県", "名鉄尾西線" ],
    [ "名鉄名古屋駅", "愛知県", "名鉄犬山線" ],
    [ "栄生駅", "愛知県", "名鉄犬山線" ],
    [ "東枇杷島駅", "愛知県", "名鉄犬山線" ],
    [ "下小田井駅", "愛知県", "名鉄犬山線" ],
    [ "中小田井駅", "愛知県", "名鉄犬山線" ],
    [ "上小田井駅", "愛知県", "名鉄犬山線" ],
    [ "西春駅", "愛知県", "名鉄犬山線" ],
    [ "徳重・名古屋芸大駅", "愛知県", "名鉄犬山線" ],
    [ "大山寺駅", "愛知県", "名鉄犬山線" ],
    [ "岩倉駅", "愛知県", "名鉄犬山線" ],
    [ "石仏駅", "愛知県", "名鉄犬山線" ],
    [ "布袋駅", "愛知県", "名鉄犬山線" ],
    [ "江南駅", "愛知県", "名鉄犬山線" ],
    [ "柏森駅", "愛知県", "名鉄犬山線" ],
    [ "扶桑駅", "愛知県", "名鉄犬山線" ],
    [ "木津用水駅", "愛知県", "名鉄犬山線" ],
    [ "犬山口駅", "愛知県", "名鉄犬山線" ],
    [ "犬山駅", "愛知県", "名鉄犬山線" ],
    [ "犬山遊園駅", "愛知県", "名鉄犬山線" ],
    [ "新鵜沼駅", "岐阜県", "名鉄犬山線" ],
    [ "新鵜沼駅", "岐阜県", "名鉄各務原線" ],
    [ "鵜沼宿駅", "岐阜県", "名鉄各務原線" ],
    [ "羽場駅", "岐阜県", "名鉄各務原線" ],
    [ "苧ヶ瀬駅", "岐阜県", "名鉄各務原線" ],
    [ "名電各務原駅", "岐阜県", "名鉄各務原線" ],
    [ "二十軒駅", "岐阜県", "名鉄各務原線" ],
    [ "三柿野駅", "岐阜県", "名鉄各務原線" ],
    [ "六軒駅", "岐阜県", "名鉄各務原線" ],
    [ "各務原市役所前駅", "岐阜県", "名鉄各務原線" ],
    [ "市民公園前駅", "岐阜県", "名鉄各務原線" ],
    [ "新那加駅", "岐阜県", "名鉄各務原線" ],
    [ "新加納駅", "岐阜県", "名鉄各務原線" ],
    [ "高田橋駅", "岐阜県", "名鉄各務原線" ],
    [ "手力駅", "岐阜県", "名鉄各務原線" ],
    [ "切通駅", "岐阜県", "名鉄各務原線" ],
    [ "細畑駅", "岐阜県", "名鉄各務原線" ],
    [ "田神駅", "岐阜県", "名鉄各務原線" ],
    [ "名鉄岐阜駅", "岐阜県", "名鉄各務原線" ],
    [ "犬山駅", "愛知県", "名鉄広見線" ],
    [ "富岡前駅", "愛知県", "名鉄広見線" ],
    [ "善師野駅", "愛知県", "名鉄広見線" ],
    [ "西可児駅", "岐阜県", "名鉄広見線" ],
    [ "可児川駅", "岐阜県", "名鉄広見線" ],
    [ "日本ライン今渡駅", "岐阜県", "名鉄広見線" ],
    [ "新可児駅", "岐阜県", "名鉄広見線" ],
    [ "明智駅", "岐阜県", "名鉄広見線" ],
    [ "顔戸駅", "岐阜県", "名鉄広見線" ],
    [ "御嵩口駅", "岐阜県", "名鉄広見線" ],
    [ "御嵩駅", "岐阜県", "名鉄広見線" ],
    [ "上飯田駅", "愛知県", "名鉄小牧線" ],
    [ "味鋺駅", "愛知県", "名鉄小牧線" ],
    [ "味美駅", "愛知県", "名鉄小牧線" ],
    [ "春日井駅", "愛知県", "名鉄小牧線" ],
    [ "牛山駅", "愛知県", "名鉄小牧線" ],
    [ "間内駅", "愛知県", "名鉄小牧線" ],
    [ "小牧口駅", "愛知県", "名鉄小牧線" ],
    [ "小牧駅", "愛知県", "名鉄小牧線" ],
    [ "小牧原駅", "愛知県", "名鉄小牧線" ],
    [ "味岡駅", "愛知県", "名鉄小牧線" ],
    [ "田県神社前駅", "愛知県", "名鉄小牧線" ],
    [ "楽田駅", "愛知県", "名鉄小牧線" ],
    [ "羽黒駅", "愛知県", "名鉄小牧線" ],
    [ "犬山駅", "愛知県", "名鉄小牧線" ],
    [ "犬山遊園駅", "愛知県", "犬山モノレール" ],
    [ "成田山駅", "愛知県", "犬山モノレール" ],
    [ "動物園駅", "愛知県", "犬山モノレール" ],
    [ "笠松駅", "岐阜県", "名鉄竹鼻線" ],
    [ "西笠松駅", "岐阜県", "名鉄竹鼻線" ],
    [ "柳津駅", "岐阜県", "名鉄竹鼻線" ],
    [ "南宿駅", "岐阜県", "名鉄竹鼻線" ],
    [ "須賀駅", "岐阜県", "名鉄竹鼻線" ],
    [ "不破一色駅", "岐阜県", "名鉄竹鼻線" ],
    [ "竹鼻駅", "岐阜県", "名鉄竹鼻線" ],
    [ "羽島市役所前駅", "岐阜県", "名鉄竹鼻線" ],
    [ "江吉良駅", "岐阜県", "名鉄竹鼻線" ],
    [ "江吉良駅", "岐阜県", "名鉄羽島線" ],
    [ "新羽島駅", "岐阜県", "名鉄羽島線" ],
    [ "大阪難波駅", "大阪府", "近鉄難波線" ],
    [ "近鉄日本橋駅", "大阪府", "近鉄難波線" ],
    [ "大阪上本町駅", "大阪府", "近鉄難波線" ],
    [ "鶴橋駅", "大阪府", "近鉄難波線" ],
    [ "今里駅", "大阪府", "近鉄難波線" ],
    [ "布施駅", "大阪府", "近鉄難波線" ],
    [ "大和西大寺駅", "奈良県", "近鉄橿原線" ],
    [ "尼ヶ辻駅", "奈良県", "近鉄橿原線" ],
    [ "西ノ京駅", "奈良県", "近鉄橿原線" ],
    [ "九条駅", "奈良県", "近鉄橿原線" ],
    [ "近鉄郡山駅", "奈良県", "近鉄橿原線" ],
    [ "筒井駅", "奈良県", "近鉄橿原線" ],
    [ "平端駅", "奈良県", "近鉄橿原線" ],
    [ "ファミリー公園前駅", "奈良県", "近鉄橿原線" ],
    [ "結崎駅", "奈良県", "近鉄橿原線" ],
    [ "石見駅", "奈良県", "近鉄橿原線" ],
    [ "田原本駅", "奈良県", "近鉄橿原線" ],
    [ "笠縫駅", "奈良県", "近鉄橿原線" ],
    [ "新ノ口駅", "奈良県", "近鉄橿原線" ],
    [ "大和八木駅", "奈良県", "近鉄橿原線" ],
    [ "八木西口駅", "奈良県", "近鉄橿原線" ],
    [ "畝傍御陵前駅", "奈良県", "近鉄橿原線" ],
    [ "橿原神宮前駅", "奈良県", "近鉄橿原線" ],
    [ "大阪阿部野橋駅", "大阪府", "近鉄南大阪線" ],
    [ "河堀口駅", "大阪府", "近鉄南大阪線" ],
    [ "北田辺駅", "大阪府", "近鉄南大阪線" ],
    [ "今川駅", "大阪府", "近鉄南大阪線" ],
    [ "針中野駅", "大阪府", "近鉄南大阪線" ],
    [ "矢田駅", "大阪府", "近鉄南大阪線" ],
    [ "河内天美駅", "大阪府", "近鉄南大阪線" ],
    [ "布忍駅", "大阪府", "近鉄南大阪線" ],
    [ "高見ノ里駅", "大阪府", "近鉄南大阪線" ],
    [ "河内松原駅", "大阪府", "近鉄南大阪線" ],
    [ "恵我ノ荘駅", "大阪府", "近鉄南大阪線" ],
    [ "高鷲駅", "大阪府", "近鉄南大阪線" ],
    [ "藤井寺駅", "大阪府", "近鉄南大阪線" ],
    [ "土師ノ里駅", "大阪府", "近鉄南大阪線" ],
    [ "道明寺駅", "大阪府", "近鉄南大阪線" ],
    [ "古市駅", "大阪府", "近鉄南大阪線" ],
    [ "駒ヶ谷駅", "大阪府", "近鉄南大阪線" ],
    [ "上ノ太子駅", "大阪府", "近鉄南大阪線" ],
    [ "二上山駅", "奈良県", "近鉄南大阪線" ],
    [ "二上神社口駅", "奈良県", "近鉄南大阪線" ],
    [ "当麻寺駅", "奈良県", "近鉄南大阪線" ],
    [ "磐城駅", "奈良県", "近鉄南大阪線" ],
    [ "尺土駅", "奈良県", "近鉄南大阪線" ],
    [ "高田市駅", "奈良県", "近鉄南大阪線" ],
    [ "浮孔駅", "奈良県", "近鉄南大阪線" ],
    [ "坊城駅", "奈良県", "近鉄南大阪線" ],
    [ "橿原神宮西口駅", "奈良県", "近鉄南大阪線" ],
    [ "橿原神宮前駅", "奈良県", "近鉄南大阪線" ],
    [ "桑名駅", "三重県", "近鉄養老線" ],
    [ "播磨駅", "三重県", "近鉄養老線" ],
    [ "下深谷駅", "三重県", "近鉄養老線" ],
    [ "下野代駅", "三重県", "近鉄養老線" ],
    [ "多度駅", "三重県", "近鉄養老線" ],
    [ "美濃松山駅", "岐阜県", "近鉄養老線" ],
    [ "石津駅", "岐阜県", "近鉄養老線" ],
    [ "美濃山崎駅", "岐阜県", "近鉄養老線" ],
    [ "駒野駅", "岐阜県", "近鉄養老線" ],
    [ "美濃津屋駅", "岐阜県", "近鉄養老線" ],
    [ "養老駅", "岐阜県", "近鉄養老線" ],
    [ "美濃高田駅", "岐阜県", "近鉄養老線" ],
    [ "烏江駅", "岐阜県", "近鉄養老線" ],
    [ "大外羽駅", "岐阜県", "近鉄養老線" ],
    [ "友江駅", "岐阜県", "近鉄養老線" ],
    [ "美濃青柳駅", "岐阜県", "近鉄養老線" ],
    [ "西大垣駅", "岐阜県", "近鉄養老線" ],
    [ "大垣駅", "岐阜県", "近鉄養老線" ],
    [ "室駅", "岐阜県", "近鉄養老線" ],
    [ "北大垣駅", "岐阜県", "近鉄養老線" ],
    [ "東赤坂駅", "岐阜県", "近鉄養老線" ],
    [ "広神戸駅", "岐阜県", "近鉄養老線" ],
    [ "北神戸駅", "岐阜県", "近鉄養老線" ],
    [ "池野駅", "岐阜県", "近鉄養老線" ],
    [ "北池野駅", "岐阜県", "近鉄養老線" ],
    [ "美濃本郷駅", "岐阜県", "近鉄養老線" ],
    [ "揖斐駅", "岐阜県", "近鉄養老線" ],
    [ "大阪上本町駅", "大阪府", "近鉄大阪線" ],
    [ "鶴橋駅", "大阪府", "近鉄大阪線" ],
    [ "今里駅", "大阪府", "近鉄大阪線" ],
    [ "布施駅", "大阪府", "近鉄大阪線" ],
    [ "俊徳道駅", "大阪府", "近鉄大阪線" ],
    [ "長瀬駅", "大阪府", "近鉄大阪線" ],
    [ "弥刀駅", "大阪府", "近鉄大阪線" ],
    [ "久宝寺口駅", "大阪府", "近鉄大阪線" ],
    [ "近鉄八尾駅", "大阪府", "近鉄大阪線" ],
    [ "河内山本駅", "大阪府", "近鉄大阪線" ],
    [ "高安駅", "大阪府", "近鉄大阪線" ],
    [ "恩智駅", "大阪府", "近鉄大阪線" ],
    [ "法善寺駅", "大阪府", "近鉄大阪線" ],
    [ "堅下駅", "大阪府", "近鉄大阪線" ],
    [ "安堂駅", "大阪府", "近鉄大阪線" ],
    [ "河内国分駅", "大阪府", "近鉄大阪線" ],
    [ "大阪教育大前駅", "大阪府", "近鉄大阪線" ],
    [ "関屋駅", "奈良県", "近鉄大阪線" ],
    [ "二上駅", "奈良県", "近鉄大阪線" ],
    [ "近鉄下田駅", "奈良県", "近鉄大阪線" ],
    [ "五位堂駅", "奈良県", "近鉄大阪線" ],
    [ "築山駅", "奈良県", "近鉄大阪線" ],
    [ "大和高田駅", "奈良県", "近鉄大阪線" ],
    [ "松塚駅", "奈良県", "近鉄大阪線" ],
    [ "真菅駅", "奈良県", "近鉄大阪線" ],
    [ "大和八木駅", "奈良県", "近鉄大阪線" ],
    [ "耳成駅", "奈良県", "近鉄大阪線" ],
    [ "大福駅", "奈良県", "近鉄大阪線" ],
    [ "桜井駅", "奈良県", "近鉄大阪線" ],
    [ "大和朝倉駅", "奈良県", "近鉄大阪線" ],
    [ "長谷寺駅", "奈良県", "近鉄大阪線" ],
    [ "榛原駅", "奈良県", "近鉄大阪線" ],
    [ "室生口大野駅", "奈良県", "近鉄大阪線" ],
    [ "三本松駅", "奈良県", "近鉄大阪線" ],
    [ "赤目口駅", "三重県", "近鉄大阪線" ],
    [ "名張駅", "三重県", "近鉄大阪線" ],
    [ "桔梗が丘駅", "三重県", "近鉄大阪線" ],
    [ "美旗駅", "三重県", "近鉄大阪線" ],
    [ "伊賀神戸駅", "三重県", "近鉄大阪線" ],
    [ "青山町駅", "三重県", "近鉄大阪線" ],
    [ "伊賀上津駅", "三重県", "近鉄大阪線" ],
    [ "西青山駅", "三重県", "近鉄大阪線" ],
    [ "東青山駅", "三重県", "近鉄大阪線" ],
    [ "榊原温泉口駅", "三重県", "近鉄大阪線" ],
    [ "大三駅", "三重県", "近鉄大阪線" ],
    [ "伊勢石橋駅", "三重県", "近鉄大阪線" ],
    [ "川合高岡駅", "三重県", "近鉄大阪線" ],
    [ "伊勢中川駅", "三重県", "近鉄大阪線" ],
    [ "伊賀上野駅", "三重県", "近鉄伊賀線" ],
    [ "新居駅", "三重県", "近鉄伊賀線" ],
    [ "西大手駅", "三重県", "近鉄伊賀線" ],
    [ "上野市駅", "三重県", "近鉄伊賀線" ],
    [ "広小路駅", "三重県", "近鉄伊賀線" ],
    [ "茅町駅", "三重県", "近鉄伊賀線" ],
    [ "桑町駅", "三重県", "近鉄伊賀線" ],
    [ "猪田道駅", "三重県", "近鉄伊賀線" ],
    [ "市部駅", "三重県", "近鉄伊賀線" ],
    [ "依那古駅", "三重県", "近鉄伊賀線" ],
    [ "丸山駅", "三重県", "近鉄伊賀線" ],
    [ "上林駅", "三重県", "近鉄伊賀線" ],
    [ "比土駅", "三重県", "近鉄伊賀線" ],
    [ "伊賀神戸駅", "三重県", "近鉄伊賀線" ],
    [ "橿原神宮前駅", "奈良県", "近鉄吉野線" ],
    [ "岡寺駅", "奈良県", "近鉄吉野線" ],
    [ "飛鳥駅", "奈良県", "近鉄吉野線" ],
    [ "壺阪山駅", "奈良県", "近鉄吉野線" ],
    [ "市尾駅", "奈良県", "近鉄吉野線" ],
    [ "葛駅", "奈良県", "近鉄吉野線" ],
    [ "吉野口駅", "奈良県", "近鉄吉野線" ],
    [ "薬水駅", "奈良県", "近鉄吉野線" ],
    [ "福神駅", "奈良県", "近鉄吉野線" ],
    [ "大阿太駅", "奈良県", "近鉄吉野線" ],
    [ "下市口駅", "奈良県", "近鉄吉野線" ],
    [ "越部駅", "奈良県", "近鉄吉野線" ],
    [ "六田駅", "奈良県", "近鉄吉野線" ],
    [ "大和上市駅", "奈良県", "近鉄吉野線" ],
    [ "吉野神宮駅", "奈良県", "近鉄吉野線" ],
    [ "吉野駅", "奈良県", "近鉄吉野線" ],
    [ "近鉄四日市駅", "三重県", "近鉄湯の山線" ],
    [ "中川原駅", "三重県", "近鉄湯の山線" ],
    [ "伊勢松本駅", "三重県", "近鉄湯の山線" ],
    [ "伊勢川島駅", "三重県", "近鉄湯の山線" ],
    [ "高角駅", "三重県", "近鉄湯の山線" ],
    [ "桜駅", "三重県", "近鉄湯の山線" ],
    [ "菰野駅", "三重県", "近鉄湯の山線" ],
    [ "中菰野駅", "三重県", "近鉄湯の山線" ],
    [ "大羽根園駅", "三重県", "近鉄湯の山線" ],
    [ "湯の山温泉駅", "三重県", "近鉄湯の山線" ],
    [ "伊勢中川駅", "三重県", "近鉄山田線" ],
    [ "伊勢中原駅", "三重県", "近鉄山田線" ],
    [ "松ヶ崎駅", "三重県", "近鉄山田線" ],
    [ "松阪駅", "三重県", "近鉄山田線" ],
    [ "東松阪駅", "三重県", "近鉄山田線" ],
    [ "櫛田駅", "三重県", "近鉄山田線" ],
    [ "漕代駅", "三重県", "近鉄山田線" ],
    [ "斎宮駅", "三重県", "近鉄山田線" ],
    [ "明星駅", "三重県", "近鉄山田線" ],
    [ "明野駅", "三重県", "近鉄山田線" ],
    [ "小俣駅", "三重県", "近鉄山田線" ],
    [ "宮町駅", "三重県", "近鉄山田線" ],
    [ "伊勢市駅", "三重県", "近鉄山田線" ],
    [ "宇治山田駅", "三重県", "近鉄山田線" ],
    [ "宇治山田駅", "三重県", "近鉄鳥羽線" ],
    [ "五十鈴川駅", "三重県", "近鉄鳥羽線" ],
    [ "朝熊駅", "三重県", "近鉄鳥羽線" ],
    [ "池の浦駅", "三重県", "近鉄鳥羽線" ],
    [ "鳥羽駅", "三重県", "近鉄鳥羽線" ],
    [ "平端駅", "奈良県", "近鉄天理線" ],
    [ "二階堂駅", "奈良県", "近鉄天理線" ],
    [ "前栽駅", "奈良県", "近鉄天理線" ],
    [ "天理駅", "奈良県", "近鉄天理線" ],
    [ "道明寺駅", "大阪府", "近鉄道明寺線" ],
    [ "柏原南口駅", "大阪府", "近鉄道明寺線" ],
    [ "柏原駅", "大阪府", "近鉄道明寺線" ],
    [ "あすなろう四日市駅", "三重県", "内部線" ],
    [ "赤堀駅", "三重県", "内部線" ],
    [ "日永駅", "三重県", "内部線" ],
    [ "南日永駅", "三重県", "内部線" ],
    [ "泊駅", "三重県", "内部線" ],
    [ "追分駅", "三重県", "内部線" ],
    [ "小古曽駅", "三重県", "内部線" ],
    [ "内部駅", "三重県", "内部線" ],
    [ "あすなろう四日市駅", "三重県", "八王子線" ],
    [ "赤堀駅", "三重県", "八王子線" ],
    [ "日永駅", "三重県", "八王子線" ],
    [ "西日野駅", "三重県", "八王子線" ],
    [ "鳥羽駅", "三重県", "近鉄志摩線" ],
    [ "中之郷駅", "三重県", "近鉄志摩線" ],
    [ "志摩赤崎駅", "三重県", "近鉄志摩線" ],
    [ "船津駅", "三重県", "近鉄志摩線" ],
    [ "加茂駅", "三重県", "近鉄志摩線" ],
    [ "松尾駅", "三重県", "近鉄志摩線" ],
    [ "白木駅", "三重県", "近鉄志摩線" ],
    [ "五知駅", "三重県", "近鉄志摩線" ],
    [ "沓掛駅", "三重県", "近鉄志摩線" ],
    [ "上之郷駅", "三重県", "近鉄志摩線" ],
    [ "志摩磯部駅", "三重県", "近鉄志摩線" ],
    [ "穴川駅", "三重県", "近鉄志摩線" ],
    [ "志摩横山駅", "三重県", "近鉄志摩線" ],
    [ "鵜方駅", "三重県", "近鉄志摩線" ],
    [ "志摩神明駅", "三重県", "近鉄志摩線" ],
    [ "賢島駅", "三重県", "近鉄志摩線" ],
    [ "生駒駅", "奈良県", "近鉄生駒線" ],
    [ "菜畑駅", "奈良県", "近鉄生駒線" ],
    [ "一分駅", "奈良県", "近鉄生駒線" ],
    [ "南生駒駅", "奈良県", "近鉄生駒線" ],
    [ "萩の台駅", "奈良県", "近鉄生駒線" ],
    [ "東山駅", "奈良県", "近鉄生駒線" ],
    [ "元山上口駅", "奈良県", "近鉄生駒線" ],
    [ "平群駅", "奈良県", "近鉄生駒線" ],
    [ "竜田川駅", "奈良県", "近鉄生駒線" ],
    [ "勢野北口駅", "奈良県", "近鉄生駒線" ],
    [ "信貴山下駅", "奈良県", "近鉄生駒線" ],
    [ "王寺駅", "奈良県", "近鉄生駒線" ],
    [ "西田原本駅", "奈良県", "近鉄田原本線" ],
    [ "黒田駅", "奈良県", "近鉄田原本線" ],
    [ "但馬駅", "奈良県", "近鉄田原本線" ],
    [ "箸尾駅", "奈良県", "近鉄田原本線" ],
    [ "池部駅", "奈良県", "近鉄田原本線" ],
    [ "佐味田川駅", "奈良県", "近鉄田原本線" ],
    [ "大輪田駅", "奈良県", "近鉄田原本線" ],
    [ "新王寺駅", "奈良県", "近鉄田原本線" ],
    [ "尺土駅", "奈良県", "近鉄御所線" ],
    [ "近鉄新庄駅", "奈良県", "近鉄御所線" ],
    [ "忍海駅", "奈良県", "近鉄御所線" ],
    [ "近鉄御所駅", "奈良県", "近鉄御所線" ],
    [ "伊勢若松駅", "三重県", "近鉄鈴鹿線" ],
    [ "柳駅", "三重県", "近鉄鈴鹿線" ],
    [ "鈴鹿市駅", "三重県", "近鉄鈴鹿線" ],
    [ "三日市駅", "三重県", "近鉄鈴鹿線" ],
    [ "平田町駅", "三重県", "近鉄鈴鹿線" ],
    [ "布施駅", "大阪府", "近鉄奈良線" ],
    [ "河内永和駅", "大阪府", "近鉄奈良線" ],
    [ "河内小阪駅", "大阪府", "近鉄奈良線" ],
    [ "八戸ノ里駅", "大阪府", "近鉄奈良線" ],
    [ "若江岩田駅", "大阪府", "近鉄奈良線" ],
    [ "河内花園駅", "大阪府", "近鉄奈良線" ],
    [ "東花園駅", "大阪府", "近鉄奈良線" ],
    [ "瓢箪山駅", "大阪府", "近鉄奈良線" ],
    [ "枚岡駅", "大阪府", "近鉄奈良線" ],
    [ "額田駅", "大阪府", "近鉄奈良線" ],
    [ "石切駅", "大阪府", "近鉄奈良線" ],
    [ "生駒駅", "奈良県", "近鉄奈良線" ],
    [ "東生駒駅", "奈良県", "近鉄奈良線" ],
    [ "富雄駅", "奈良県", "近鉄奈良線" ],
    [ "学園前駅", "奈良県", "近鉄奈良線" ],
    [ "菖蒲池駅", "奈良県", "近鉄奈良線" ],
    [ "大和西大寺駅", "奈良県", "近鉄奈良線" ],
    [ "新大宮駅", "奈良県", "近鉄奈良線" ],
    [ "近鉄奈良駅", "奈良県", "近鉄奈良線" ],
    [ "河内山本駅", "大阪府", "近鉄信貴線" ],
    [ "服部川駅", "大阪府", "近鉄信貴線" ],
    [ "信貴山口駅", "大阪府", "近鉄信貴線" ],
    [ "古市駅", "大阪府", "近鉄長野線" ],
    [ "喜志駅", "大阪府", "近鉄長野線" ],
    [ "富田林駅", "大阪府", "近鉄長野線" ],
    [ "富田林西口駅", "大阪府", "近鉄長野線" ],
    [ "川西駅", "大阪府", "近鉄長野線" ],
    [ "滝谷不動駅", "大阪府", "近鉄長野線" ],
    [ "汐ノ宮駅", "大阪府", "近鉄長野線" ],
    [ "河内長野駅", "大阪府", "近鉄長野線" ],
    [ "長田駅", "大阪府", "近鉄けいはんな線" ],
    [ "荒本駅", "大阪府", "近鉄けいはんな線" ],
    [ "吉田駅", "大阪府", "近鉄けいはんな線" ],
    [ "新石切駅", "大阪府", "近鉄けいはんな線" ],
    [ "生駒駅", "奈良県", "近鉄けいはんな線" ],
    [ "白庭台駅", "奈良県", "近鉄けいはんな線" ],
    [ "学研北生駒駅", "奈良県", "近鉄けいはんな線" ],
    [ "学研奈良登美ヶ丘駅", "奈良県", "近鉄けいはんな線" ],
    [ "信貴山口駅", "大阪府", "西信貴ケーブル" ],
    [ "高安山駅", "大阪府", "西信貴ケーブル" ],
    [ "京都駅", "京都府", "近鉄京都線" ],
    [ "東寺駅", "京都府", "近鉄京都線" ],
    [ "十条駅", "京都府", "近鉄京都線" ],
    [ "上鳥羽口駅", "京都府", "近鉄京都線" ],
    [ "竹田駅", "京都府", "近鉄京都線" ],
    [ "伏見駅", "京都府", "近鉄京都線" ],
    [ "近鉄丹波橋駅", "京都府", "近鉄京都線" ],
    [ "桃山御陵前駅", "京都府", "近鉄京都線" ],
    [ "向島駅", "京都府", "近鉄京都線" ],
    [ "小倉駅", "京都府", "近鉄京都線" ],
    [ "伊勢田駅", "京都府", "近鉄京都線" ],
    [ "大久保駅", "京都府", "近鉄京都線" ],
    [ "久津川駅", "京都府", "近鉄京都線" ],
    [ "寺田駅", "京都府", "近鉄京都線" ],
    [ "富野荘駅", "京都府", "近鉄京都線" ],
    [ "新田辺駅", "京都府", "近鉄京都線" ],
    [ "興戸駅", "京都府", "近鉄京都線" ],
    [ "三山木駅", "京都府", "近鉄京都線" ],
    [ "近鉄宮津駅", "京都府", "近鉄京都線" ],
    [ "狛田駅", "京都府", "近鉄京都線" ],
    [ "新祝園駅", "京都府", "近鉄京都線" ],
    [ "木津川台駅", "京都府", "近鉄京都線" ],
    [ "山田川駅", "京都府", "近鉄京都線" ],
    [ "高の原駅", "奈良県", "近鉄京都線" ],
    [ "平城駅", "奈良県", "近鉄京都線" ],
    [ "大和西大寺駅", "奈良県", "近鉄京都線" ],
    [ "新大宮駅", "奈良県", "近鉄京都線" ],
    [ "近鉄奈良駅", "奈良県", "近鉄京都線" ],
    [ "鳥居前駅", "奈良県", "生駒ケーブル" ],
    [ "宝山寺駅", "奈良県", "生駒ケーブル" ],
    [ "梅屋敷駅", "奈良県", "生駒ケーブル" ],
    [ "霞ヶ丘駅", "奈良県", "生駒ケーブル" ],
    [ "生駒山上駅", "奈良県", "生駒ケーブル" ],
    [ "近鉄名古屋駅", "愛知県", "近鉄名古屋線" ],
    [ "米野駅", "愛知県", "近鉄名古屋線" ],
    [ "黄金駅", "愛知県", "近鉄名古屋線" ],
    [ "烏森駅", "愛知県", "近鉄名古屋線" ],
    [ "近鉄八田駅", "愛知県", "近鉄名古屋線" ],
    [ "伏屋駅", "愛知県", "近鉄名古屋線" ],
    [ "戸田駅", "愛知県", "近鉄名古屋線" ],
    [ "近鉄蟹江駅", "愛知県", "近鉄名古屋線" ],
    [ "富吉駅", "愛知県", "近鉄名古屋線" ],
    [ "佐古木駅", "愛知県", "近鉄名古屋線" ],
    [ "近鉄弥富駅", "愛知県", "近鉄名古屋線" ],
    [ "近鉄長島駅", "三重県", "近鉄名古屋線" ],
    [ "桑名駅", "三重県", "近鉄名古屋線" ],
    [ "益生駅", "三重県", "近鉄名古屋線" ],
    [ "伊勢朝日駅", "三重県", "近鉄名古屋線" ],
    [ "川越富洲原駅", "三重県", "近鉄名古屋線" ],
    [ "近鉄富田駅", "三重県", "近鉄名古屋線" ],
    [ "霞ヶ浦駅", "三重県", "近鉄名古屋線" ],
    [ "阿倉川駅", "三重県", "近鉄名古屋線" ],
    [ "川原町駅", "三重県", "近鉄名古屋線" ],
    [ "近鉄四日市駅", "三重県", "近鉄名古屋線" ],
    [ "新正駅", "三重県", "近鉄名古屋線" ],
    [ "海山道駅", "三重県", "近鉄名古屋線" ],
    [ "塩浜駅", "三重県", "近鉄名古屋線" ],
    [ "北楠駅", "三重県", "近鉄名古屋線" ],
    [ "楠駅", "三重県", "近鉄名古屋線" ],
    [ "長太ノ浦駅", "三重県", "近鉄名古屋線" ],
    [ "箕田駅", "三重県", "近鉄名古屋線" ],
    [ "伊勢若松駅", "三重県", "近鉄名古屋線" ],
    [ "千代崎駅", "三重県", "近鉄名古屋線" ],
    [ "白子駅", "三重県", "近鉄名古屋線" ],
    [ "鼓ヶ浦駅", "三重県", "近鉄名古屋線" ],
    [ "磯山駅", "三重県", "近鉄名古屋線" ],
    [ "千里駅", "三重県", "近鉄名古屋線" ],
    [ "豊津上野駅", "三重県", "近鉄名古屋線" ],
    [ "白塚駅", "三重県", "近鉄名古屋線" ],
    [ "高田本山駅", "三重県", "近鉄名古屋線" ],
    [ "江戸橋駅", "三重県", "近鉄名古屋線" ],
    [ "津駅", "三重県", "近鉄名古屋線" ],
    [ "津新町駅", "三重県", "近鉄名古屋線" ],
    [ "南が丘駅", "三重県", "近鉄名古屋線" ],
    [ "久居駅", "三重県", "近鉄名古屋線" ],
    [ "桃園駅", "三重県", "近鉄名古屋線" ],
    [ "伊勢中川駅", "三重県", "近鉄名古屋線" ],
    [ "難波駅", "大阪府", "南海本線" ],
    [ "新今宮駅", "大阪府", "南海本線" ],
    [ "天下茶屋駅", "大阪府", "南海本線" ],
    [ "岸里玉出駅", "大阪府", "南海本線" ],
    [ "粉浜駅", "大阪府", "南海本線" ],
    [ "住吉大社駅", "大阪府", "南海本線" ],
    [ "住ノ江駅", "大阪府", "南海本線" ],
    [ "七道駅", "大阪府", "南海本線" ],
    [ "堺駅", "大阪府", "南海本線" ],
    [ "湊駅", "大阪府", "南海本線" ],
    [ "石津川駅", "大阪府", "南海本線" ],
    [ "諏訪ノ森駅", "大阪府", "南海本線" ],
    [ "浜寺公園駅", "大阪府", "南海本線" ],
    [ "羽衣駅", "大阪府", "南海本線" ],
    [ "高石駅", "大阪府", "南海本線" ],
    [ "北助松駅", "大阪府", "南海本線" ],
    [ "松ノ浜駅", "大阪府", "南海本線" ],
    [ "泉大津駅", "大阪府", "南海本線" ],
    [ "忠岡駅", "大阪府", "南海本線" ],
    [ "春木駅", "大阪府", "南海本線" ],
    [ "和泉大宮駅", "大阪府", "南海本線" ],
    [ "岸和田駅", "大阪府", "南海本線" ],
    [ "蛸地蔵駅", "大阪府", "南海本線" ],
    [ "貝塚駅", "大阪府", "南海本線" ],
    [ "二色浜駅", "大阪府", "南海本線" ],
    [ "鶴原駅", "大阪府", "南海本線" ],
    [ "井原里駅", "大阪府", "南海本線" ],
    [ "泉佐野駅", "大阪府", "南海本線" ],
    [ "羽倉崎駅", "大阪府", "南海本線" ],
    [ "吉見ノ里駅", "大阪府", "南海本線" ],
    [ "岡田浦駅", "大阪府", "南海本線" ],
    [ "樽井駅", "大阪府", "南海本線" ],
    [ "尾崎駅", "大阪府", "南海本線" ],
    [ "鳥取ノ荘駅", "大阪府", "南海本線" ],
    [ "箱作駅", "大阪府", "南海本線" ],
    [ "淡輪駅", "大阪府", "南海本線" ],
    [ "みさき公園駅", "大阪府", "南海本線" ],
    [ "孝子駅", "大阪府", "南海本線" ],
    [ "和歌山大学前駅", "和歌山県", "南海本線" ],
    [ "紀ノ川駅", "和歌山県", "南海本線" ],
    [ "和歌山市駅", "和歌山県", "南海本線" ],
    [ "泉佐野駅", "大阪府", "南海空港線" ],
    [ "りんくうタウン駅", "大阪府", "南海空港線" ],
    [ "関西空港駅", "大阪府", "南海空港線" ],
    [ "和歌山市駅", "和歌山県", "南海和歌山港線" ],
    [ "和歌山港駅", "和歌山県", "南海和歌山港線" ],
    [ "羽衣駅", "大阪府", "南海高師浜線" ],
    [ "伽羅橋駅", "大阪府", "南海高師浜線" ],
    [ "高師浜駅", "大阪府", "南海高師浜線" ],
    [ "和歌山市駅", "和歌山県", "南海加太線" ],
    [ "紀ノ川駅", "和歌山県", "南海加太線" ],
    [ "東松江駅", "和歌山県", "南海加太線" ],
    [ "中松江駅", "和歌山県", "南海加太線" ],
    [ "八幡前駅", "和歌山県", "南海加太線" ],
    [ "西ノ庄駅", "和歌山県", "南海加太線" ],
    [ "二里ヶ浜駅", "和歌山県", "南海加太線" ],
    [ "磯ノ浦駅", "和歌山県", "南海加太線" ],
    [ "加太駅", "和歌山県", "南海加太線" ],
    [ "みさき公園駅", "大阪府", "南海多奈川線" ],
    [ "深日町駅", "大阪府", "南海多奈川線" ],
    [ "深日港駅", "大阪府", "南海多奈川線" ],
    [ "多奈川駅", "大阪府", "南海多奈川線" ],
    [ "難波駅", "大阪府", "南海高野線" ],
    [ "今宮戎駅", "大阪府", "南海高野線" ],
    [ "新今宮駅", "大阪府", "南海高野線" ],
    [ "萩ノ茶屋駅", "大阪府", "南海高野線" ],
    [ "天下茶屋駅", "大阪府", "南海高野線" ],
    [ "岸里玉出駅", "大阪府", "南海高野線" ],
    [ "帝塚山駅", "大阪府", "南海高野線" ],
    [ "住吉東駅", "大阪府", "南海高野線" ],
    [ "沢ノ町駅", "大阪府", "南海高野線" ],
    [ "我孫子前駅", "大阪府", "南海高野線" ],
    [ "浅香山駅", "大阪府", "南海高野線" ],
    [ "堺東駅", "大阪府", "南海高野線" ],
    [ "三国ヶ丘駅", "大阪府", "南海高野線" ],
    [ "百舌鳥八幡駅", "大阪府", "南海高野線" ],
    [ "中百舌鳥駅", "大阪府", "南海高野線" ],
    [ "白鷺駅", "大阪府", "南海高野線" ],
    [ "初芝駅", "大阪府", "南海高野線" ],
    [ "萩原天神駅", "大阪府", "南海高野線" ],
    [ "北野田駅", "大阪府", "南海高野線" ],
    [ "狭山駅", "大阪府", "南海高野線" ],
    [ "大阪狭山市駅", "大阪府", "南海高野線" ],
    [ "金剛駅", "大阪府", "南海高野線" ],
    [ "滝谷駅", "大阪府", "南海高野線" ],
    [ "千代田駅", "大阪府", "南海高野線" ],
    [ "河内長野駅", "大阪府", "南海高野線" ],
    [ "三日市町駅", "大阪府", "南海高野線" ],
    [ "美加の台駅", "大阪府", "南海高野線" ],
    [ "千早口駅", "大阪府", "南海高野線" ],
    [ "天見駅", "大阪府", "南海高野線" ],
    [ "紀見峠駅", "和歌山県", "南海高野線" ],
    [ "林間田園都市駅", "和歌山県", "南海高野線" ],
    [ "御幸辻駅", "和歌山県", "南海高野線" ],
    [ "橋本駅", "和歌山県", "南海高野線" ],
    [ "紀伊清水駅", "和歌山県", "南海高野線" ],
    [ "学文路駅", "和歌山県", "南海高野線" ],
    [ "九度山駅", "和歌山県", "南海高野線" ],
    [ "高野下駅", "和歌山県", "南海高野線" ],
    [ "下古沢駅", "和歌山県", "南海高野線" ],
    [ "上古沢駅", "和歌山県", "南海高野線" ],
    [ "紀伊細川駅", "和歌山県", "南海高野線" ],
    [ "紀伊神谷駅", "和歌山県", "南海高野線" ],
    [ "極楽橋駅", "和歌山県", "南海高野線" ],
    [ "極楽橋駅", "和歌山県", "南海高野山ケーブル" ],
    [ "高野山駅", "和歌山県", "南海高野山ケーブル" ],
    [ "汐見橋駅", "大阪府", "南海汐見橋線" ],
    [ "芦原町駅", "大阪府", "南海汐見橋線" ],
    [ "木津川駅", "大阪府", "南海汐見橋線" ],
    [ "津守駅", "大阪府", "南海汐見橋線" ],
    [ "西天下茶屋駅", "大阪府", "南海汐見橋線" ],
    [ "岸里玉出駅", "大阪府", "南海汐見橋線" ],
    [ "出町柳駅", "京都府", "京阪本線" ],
    [ "神宮丸太町駅", "京都府", "京阪本線" ],
    [ "三条駅", "京都府", "京阪本線" ],
    [ "祇園四条駅", "京都府", "京阪本線" ],
    [ "清水五条駅", "京都府", "京阪本線" ],
    [ "七条駅", "京都府", "京阪本線" ],
    [ "東福寺駅", "京都府", "京阪本線" ],
    [ "鳥羽街道駅", "京都府", "京阪本線" ],
    [ "伏見稲荷駅", "京都府", "京阪本線" ],
    [ "龍谷大前深草駅", "京都府", "京阪本線" ],
    [ "藤森駅", "京都府", "京阪本線" ],
    [ "墨染駅", "京都府", "京阪本線" ],
    [ "丹波橋駅", "京都府", "京阪本線" ],
    [ "伏見桃山駅", "京都府", "京阪本線" ],
    [ "中書島駅", "京都府", "京阪本線" ],
    [ "淀駅", "京都府", "京阪本線" ],
    [ "石清水八幡宮駅", "京都府", "京阪本線" ],
    [ "橋本駅", "京都府", "京阪本線" ],
    [ "樟葉駅", "大阪府", "京阪本線" ],
    [ "牧野駅", "大阪府", "京阪本線" ],
    [ "御殿山駅", "大阪府", "京阪本線" ],
    [ "枚方市駅", "大阪府", "京阪本線" ],
    [ "枚方公園駅", "大阪府", "京阪本線" ],
    [ "光善寺駅", "大阪府", "京阪本線" ],
    [ "香里園駅", "大阪府", "京阪本線" ],
    [ "寝屋川市駅", "大阪府", "京阪本線" ],
    [ "萱島駅", "大阪府", "京阪本線" ],
    [ "大和田駅", "大阪府", "京阪本線" ],
    [ "古川橋駅", "大阪府", "京阪本線" ],
    [ "門真市駅", "大阪府", "京阪本線" ],
    [ "西三荘駅", "大阪府", "京阪本線" ],
    [ "守口市駅", "大阪府", "京阪本線" ],
    [ "土居駅", "大阪府", "京阪本線" ],
    [ "滝井駅", "大阪府", "京阪本線" ],
    [ "千林駅", "大阪府", "京阪本線" ],
    [ "森小路駅", "大阪府", "京阪本線" ],
    [ "関目駅", "大阪府", "京阪本線" ],
    [ "野江駅", "大阪府", "京阪本線" ],
    [ "京橋駅", "大阪府", "京阪本線" ],
    [ "天満橋駅", "大阪府", "京阪本線" ],
    [ "北浜駅", "大阪府", "京阪本線" ],
    [ "淀屋橋駅", "大阪府", "京阪本線" ],
    [ "中書島駅", "京都府", "京阪宇治線" ],
    [ "観月橋駅", "京都府", "京阪宇治線" ],
    [ "桃山南口駅", "京都府", "京阪宇治線" ],
    [ "六地蔵駅", "京都府", "京阪宇治線" ],
    [ "木幡駅", "京都府", "京阪宇治線" ],
    [ "黄檗駅", "京都府", "京阪宇治線" ],
    [ "三室戸駅", "京都府", "京阪宇治線" ],
    [ "宇治駅", "京都府", "京阪宇治線" ],
    [ "枚方市駅", "大阪府", "京阪交野線" ],
    [ "宮之阪駅", "大阪府", "京阪交野線" ],
    [ "星ヶ丘駅", "大阪府", "京阪交野線" ],
    [ "村野駅", "大阪府", "京阪交野線" ],
    [ "郡津駅", "大阪府", "京阪交野線" ],
    [ "交野市駅", "大阪府", "京阪交野線" ],
    [ "河内森駅", "大阪府", "京阪交野線" ],
    [ "私市駅", "大阪府", "京阪交野線" ],
    [ "出町柳駅", "京都府", "京阪鴨東線" ],
    [ "神宮丸太町駅", "京都府", "京阪鴨東線" ],
    [ "三条駅", "京都府", "京阪鴨東線" ],
    [ "ケーブル八幡宮口駅", "京都府", "石清水八幡宮参道ケーブル" ],
    [ "ケーブル八幡宮山上駅", "京都府", "石清水八幡宮参道ケーブル" ],
    [ "石山寺駅", "滋賀県", "京阪石山坂本線" ],
    [ "唐橋前駅", "滋賀県", "京阪石山坂本線" ],
    [ "京阪石山駅", "滋賀県", "京阪石山坂本線" ],
    [ "粟津駅", "滋賀県", "京阪石山坂本線" ],
    [ "瓦ヶ浜駅", "滋賀県", "京阪石山坂本線" ],
    [ "中ノ庄駅", "滋賀県", "京阪石山坂本線" ],
    [ "膳所本町駅", "滋賀県", "京阪石山坂本線" ],
    [ "錦駅", "滋賀県", "京阪石山坂本線" ],
    [ "京阪膳所駅", "滋賀県", "京阪石山坂本線" ],
    [ "石場駅", "滋賀県", "京阪石山坂本線" ],
    [ "島ノ関駅", "滋賀県", "京阪石山坂本線" ],
    [ "びわ湖浜大津駅", "滋賀県", "京阪石山坂本線" ],
    [ "三井寺駅", "滋賀県", "京阪石山坂本線" ],
    [ "大津市役所前駅", "滋賀県", "京阪石山坂本線" ],
    [ "京阪大津京駅", "滋賀県", "京阪石山坂本線" ],
    [ "近江神宮前駅", "滋賀県", "京阪石山坂本線" ],
    [ "南滋賀駅", "滋賀県", "京阪石山坂本線" ],
    [ "滋賀里駅", "滋賀県", "京阪石山坂本線" ],
    [ "穴太駅", "滋賀県", "京阪石山坂本線" ],
    [ "松ノ馬場駅", "滋賀県", "京阪石山坂本線" ],
    [ "坂本比叡山口駅", "滋賀県", "京阪石山坂本線" ],
    [ "御陵駅", "京都府", "京阪京津線" ],
    [ "京阪山科駅", "京都府", "京阪京津線" ],
    [ "四宮駅", "京都府", "京阪京津線" ],
    [ "追分駅", "滋賀県", "京阪京津線" ],
    [ "大谷駅", "滋賀県", "京阪京津線" ],
    [ "上栄町駅", "滋賀県", "京阪京津線" ],
    [ "びわ湖浜大津駅", "滋賀県", "京阪京津線" ],
    [ "天満橋駅", "大阪府", "京阪中之島線" ],
    [ "なにわ橋駅", "大阪府", "京阪中之島線" ],
    [ "大江橋駅", "大阪府", "京阪中之島線" ],
    [ "渡辺橋駅", "大阪府", "京阪中之島線" ],
    [ "中之島駅", "大阪府", "京阪中之島線" ],
    [ "大阪梅田駅", "大阪府", "阪急神戸本線" ],
    [ "中津駅", "大阪府", "阪急神戸本線" ],
    [ "十三駅", "大阪府", "阪急神戸本線" ],
    [ "神崎川駅", "大阪府", "阪急神戸本線" ],
    [ "園田駅", "兵庫県", "阪急神戸本線" ],
    [ "塚口駅", "兵庫県", "阪急神戸本線" ],
    [ "武庫之荘駅", "兵庫県", "阪急神戸本線" ],
    [ "西宮北口駅", "兵庫県", "阪急神戸本線" ],
    [ "夙川駅", "兵庫県", "阪急神戸本線" ],
    [ "芦屋川駅", "兵庫県", "阪急神戸本線" ],
    [ "岡本駅", "兵庫県", "阪急神戸本線" ],
    [ "御影駅", "兵庫県", "阪急神戸本線" ],
    [ "六甲駅", "兵庫県", "阪急神戸本線" ],
    [ "王子公園駅", "兵庫県", "阪急神戸本線" ],
    [ "春日野道駅", "兵庫県", "阪急神戸本線" ],
    [ "神戸三宮駅", "兵庫県", "阪急神戸本線" ],
    [ "大阪梅田駅", "大阪府", "阪急宝塚本線" ],
    [ "中津駅", "大阪府", "阪急宝塚本線" ],
    [ "十三駅", "大阪府", "阪急宝塚本線" ],
    [ "三国駅", "大阪府", "阪急宝塚本線" ],
    [ "庄内駅", "大阪府", "阪急宝塚本線" ],
    [ "服部天神駅", "大阪府", "阪急宝塚本線" ],
    [ "曽根駅", "大阪府", "阪急宝塚本線" ],
    [ "岡町駅", "大阪府", "阪急宝塚本線" ],
    [ "豊中駅", "大阪府", "阪急宝塚本線" ],
    [ "蛍池駅", "大阪府", "阪急宝塚本線" ],
    [ "石橋阪大前駅", "大阪府", "阪急宝塚本線" ],
    [ "池田駅", "大阪府", "阪急宝塚本線" ],
    [ "川西能勢口駅", "兵庫県", "阪急宝塚本線" ],
    [ "雲雀丘花屋敷駅", "兵庫県", "阪急宝塚本線" ],
    [ "山本駅", "兵庫県", "阪急宝塚本線" ],
    [ "中山観音駅", "兵庫県", "阪急宝塚本線" ],
    [ "売布神社駅", "兵庫県", "阪急宝塚本線" ],
    [ "清荒神駅", "兵庫県", "阪急宝塚本線" ],
    [ "宝塚駅", "兵庫県", "阪急宝塚本線" ],
    [ "京都河原町駅", "京都府", "阪急京都本線" ],
    [ "烏丸駅", "京都府", "阪急京都本線" ],
    [ "大宮駅", "京都府", "阪急京都本線" ],
    [ "西院駅", "京都府", "阪急京都本線" ],
    [ "西京極駅", "京都府", "阪急京都本線" ],
    [ "桂駅", "京都府", "阪急京都本線" ],
    [ "洛西口駅", "京都府", "阪急京都本線" ],
    [ "東向日駅", "京都府", "阪急京都本線" ],
    [ "西向日駅", "京都府", "阪急京都本線" ],
    [ "長岡天神駅", "京都府", "阪急京都本線" ],
    [ "西山天王山駅", "京都府", "阪急京都本線" ],
    [ "大山崎駅", "京都府", "阪急京都本線" ],
    [ "水無瀬駅", "大阪府", "阪急京都本線" ],
    [ "上牧駅", "大阪府", "阪急京都本線" ],
    [ "高槻市駅", "大阪府", "阪急京都本線" ],
    [ "富田駅", "大阪府", "阪急京都本線" ],
    [ "総持寺駅", "大阪府", "阪急京都本線" ],
    [ "茨木市駅", "大阪府", "阪急京都本線" ],
    [ "南茨木駅", "大阪府", "阪急京都本線" ],
    [ "摂津市駅", "大阪府", "阪急京都本線" ],
    [ "正雀駅", "大阪府", "阪急京都本線" ],
    [ "相川駅", "大阪府", "阪急京都本線" ],
    [ "上新庄駅", "大阪府", "阪急京都本線" ],
    [ "淡路駅", "大阪府", "阪急京都本線" ],
    [ "崇禅寺駅", "大阪府", "阪急京都本線" ],
    [ "南方駅", "大阪府", "阪急京都本線" ],
    [ "十三駅", "大阪府", "阪急京都本線" ],
    [ "大阪梅田駅", "大阪府", "阪急京都本線" ],
    [ "今津駅", "兵庫県", "阪急今津線" ],
    [ "阪神国道駅", "兵庫県", "阪急今津線" ],
    [ "西宮北口駅", "兵庫県", "阪急今津線" ],
    [ "門戸厄神駅", "兵庫県", "阪急今津線" ],
    [ "甲東園駅", "兵庫県", "阪急今津線" ],
    [ "仁川駅", "兵庫県", "阪急今津線" ],
    [ "小林駅", "兵庫県", "阪急今津線" ],
    [ "逆瀬川駅", "兵庫県", "阪急今津線" ],
    [ "宝塚南口駅", "兵庫県", "阪急今津線" ],
    [ "宝塚駅", "兵庫県", "阪急今津線" ],
    [ "夙川駅", "兵庫県", "阪急甲陽線" ],
    [ "苦楽園口駅", "兵庫県", "阪急甲陽線" ],
    [ "甲陽園駅", "兵庫県", "阪急甲陽線" ],
    [ "塚口駅", "兵庫県", "阪急伊丹線" ],
    [ "稲野駅", "兵庫県", "阪急伊丹線" ],
    [ "新伊丹駅", "兵庫県", "阪急伊丹線" ],
    [ "伊丹駅", "兵庫県", "阪急伊丹線" ],
    [ "石橋阪大前駅", "大阪府", "阪急箕面線" ],
    [ "桜井駅", "大阪府", "阪急箕面線" ],
    [ "牧落駅", "大阪府", "阪急箕面線" ],
    [ "箕面駅", "大阪府", "阪急箕面線" ],
    [ "北千里駅", "大阪府", "阪急千里線" ],
    [ "山田駅", "大阪府", "阪急千里線" ],
    [ "南千里駅", "大阪府", "阪急千里線" ],
    [ "千里山駅", "大阪府", "阪急千里線" ],
    [ "関大前駅", "大阪府", "阪急千里線" ],
    [ "豊津駅", "大阪府", "阪急千里線" ],
    [ "吹田駅", "大阪府", "阪急千里線" ],
    [ "下新庄駅", "大阪府", "阪急千里線" ],
    [ "淡路駅", "大阪府", "阪急千里線" ],
    [ "柴島駅", "大阪府", "阪急千里線" ],
    [ "天神橋筋六丁目駅", "大阪府", "阪急千里線" ],
    [ "桂駅", "京都府", "阪急嵐山線" ],
    [ "上桂駅", "京都府", "阪急嵐山線" ],
    [ "松尾大社駅", "京都府", "阪急嵐山線" ],
    [ "嵐山駅", "京都府", "阪急嵐山線" ],
    [ "大阪梅田駅", "大阪府", "阪神本線" ],
    [ "福島駅", "大阪府", "阪神本線" ],
    [ "野田駅", "大阪府", "阪神本線" ],
    [ "淀川駅", "大阪府", "阪神本線" ],
    [ "姫島駅", "大阪府", "阪神本線" ],
    [ "千船駅", "大阪府", "阪神本線" ],
    [ "杭瀬駅", "兵庫県", "阪神本線" ],
    [ "大物駅", "兵庫県", "阪神本線" ],
    [ "尼崎駅", "兵庫県", "阪神本線" ],
    [ "出屋敷駅", "兵庫県", "阪神本線" ],
    [ "尼崎センタープール前駅", "兵庫県", "阪神本線" ],
    [ "武庫川駅", "兵庫県", "阪神本線" ],
    [ "鳴尾・武庫川女子大前駅", "兵庫県", "阪神本線" ],
    [ "甲子園駅", "兵庫県", "阪神本線" ],
    [ "久寿川駅", "兵庫県", "阪神本線" ],
    [ "今津駅", "兵庫県", "阪神本線" ],
    [ "西宮駅", "兵庫県", "阪神本線" ],
    [ "香櫨園駅", "兵庫県", "阪神本線" ],
    [ "打出駅", "兵庫県", "阪神本線" ],
    [ "芦屋駅", "兵庫県", "阪神本線" ],
    [ "深江駅", "兵庫県", "阪神本線" ],
    [ "青木駅", "兵庫県", "阪神本線" ],
    [ "魚崎駅", "兵庫県", "阪神本線" ],
    [ "住吉駅", "兵庫県", "阪神本線" ],
    [ "御影駅", "兵庫県", "阪神本線" ],
    [ "石屋川駅", "兵庫県", "阪神本線" ],
    [ "新在家駅", "兵庫県", "阪神本線" ],
    [ "大石駅", "兵庫県", "阪神本線" ],
    [ "西灘駅", "兵庫県", "阪神本線" ],
    [ "岩屋駅", "兵庫県", "阪神本線" ],
    [ "春日野道駅", "兵庫県", "阪神本線" ],
    [ "神戸三宮駅", "兵庫県", "阪神本線" ],
    [ "元町駅", "兵庫県", "阪神本線" ],
    [ "大阪難波駅", "大阪府", "阪神なんば線" ],
    [ "桜川駅", "大阪府", "阪神なんば線" ],
    [ "ドーム前駅", "大阪府", "阪神なんば線" ],
    [ "九条駅", "大阪府", "阪神なんば線" ],
    [ "西九条駅", "大阪府", "阪神なんば線" ],
    [ "千鳥橋駅", "大阪府", "阪神なんば線" ],
    [ "伝法駅", "大阪府", "阪神なんば線" ],
    [ "福駅", "大阪府", "阪神なんば線" ],
    [ "出来島駅", "大阪府", "阪神なんば線" ],
    [ "大物駅", "兵庫県", "阪神なんば線" ],
    [ "尼崎駅", "兵庫県", "阪神なんば線" ],
    [ "武庫川駅", "兵庫県", "阪神武庫川線" ],
    [ "東鳴尾駅", "兵庫県", "阪神武庫川線" ],
    [ "洲先駅", "兵庫県", "阪神武庫川線" ],
    [ "武庫川団地前駅", "兵庫県", "阪神武庫川線" ],
    [ "西鉄福岡（天神）駅", "福岡県", "西鉄天神大牟田線" ],
    [ "薬院駅", "福岡県", "西鉄天神大牟田線" ],
    [ "西鉄平尾駅", "福岡県", "西鉄天神大牟田線" ],
    [ "高宮駅", "福岡県", "西鉄天神大牟田線" ],
    [ "大橋駅", "福岡県", "西鉄天神大牟田線" ],
    [ "井尻駅", "福岡県", "西鉄天神大牟田線" ],
    [ "雑餉隈駅", "福岡県", "西鉄天神大牟田線" ],
    [ "春日原駅", "福岡県", "西鉄天神大牟田線" ],
    [ "白木原駅", "福岡県", "西鉄天神大牟田線" ],
    [ "下大利駅", "福岡県", "西鉄天神大牟田線" ],
    [ "都府楼前駅", "福岡県", "西鉄天神大牟田線" ],
    [ "西鉄二日市駅", "福岡県", "西鉄天神大牟田線" ],
    [ "紫駅", "福岡県", "西鉄天神大牟田線" ],
    [ "朝倉街道駅", "福岡県", "西鉄天神大牟田線" ],
    [ "桜台駅", "福岡県", "西鉄天神大牟田線" ],
    [ "筑紫駅", "福岡県", "西鉄天神大牟田線" ],
    [ "津古駅", "福岡県", "西鉄天神大牟田線" ],
    [ "三国が丘駅", "福岡県", "西鉄天神大牟田線" ],
    [ "三沢駅", "福岡県", "西鉄天神大牟田線" ],
    [ "大保駅", "福岡県", "西鉄天神大牟田線" ],
    [ "西鉄小郡駅", "福岡県", "西鉄天神大牟田線" ],
    [ "端間駅", "福岡県", "西鉄天神大牟田線" ],
    [ "味坂駅", "福岡県", "西鉄天神大牟田線" ],
    [ "宮の陣駅", "福岡県", "西鉄天神大牟田線" ],
    [ "櫛原駅", "福岡県", "西鉄天神大牟田線" ],
    [ "西鉄久留米駅", "福岡県", "西鉄天神大牟田線" ],
    [ "花畑駅", "福岡県", "西鉄天神大牟田線" ],
    [ "試験場前駅", "福岡県", "西鉄天神大牟田線" ],
    [ "津福駅", "福岡県", "西鉄天神大牟田線" ],
    [ "安武駅", "福岡県", "西鉄天神大牟田線" ],
    [ "大善寺駅", "福岡県", "西鉄天神大牟田線" ],
    [ "三潴駅", "福岡県", "西鉄天神大牟田線" ],
    [ "犬塚駅", "福岡県", "西鉄天神大牟田線" ],
    [ "大溝駅", "福岡県", "西鉄天神大牟田線" ],
    [ "八丁牟田駅", "福岡県", "西鉄天神大牟田線" ],
    [ "蒲池駅", "福岡県", "西鉄天神大牟田線" ],
    [ "矢加部駅", "福岡県", "西鉄天神大牟田線" ],
    [ "西鉄柳川駅", "福岡県", "西鉄天神大牟田線" ],
    [ "徳益駅", "福岡県", "西鉄天神大牟田線" ],
    [ "塩塚駅", "福岡県", "西鉄天神大牟田線" ],
    [ "西鉄中島駅", "福岡県", "西鉄天神大牟田線" ],
    [ "江の浦駅", "福岡県", "西鉄天神大牟田線" ],
    [ "開駅", "福岡県", "西鉄天神大牟田線" ],
    [ "西鉄渡瀬駅", "福岡県", "西鉄天神大牟田線" ],
    [ "倉永駅", "福岡県", "西鉄天神大牟田線" ],
    [ "東甘木駅", "福岡県", "西鉄天神大牟田線" ],
    [ "西鉄銀水駅", "福岡県", "西鉄天神大牟田線" ],
    [ "新栄町駅", "福岡県", "西鉄天神大牟田線" ],
    [ "大牟田駅", "福岡県", "西鉄天神大牟田線" ],
    [ "西鉄二日市駅", "福岡県", "西鉄太宰府線" ],
    [ "西鉄五条駅", "福岡県", "西鉄太宰府線" ],
    [ "太宰府駅", "福岡県", "西鉄太宰府線" ],
    [ "宮の陣駅", "福岡県", "西鉄甘木線" ],
    [ "五郎丸駅", "福岡県", "西鉄甘木線" ],
    [ "学校前駅", "福岡県", "西鉄甘木線" ],
    [ "古賀茶屋駅", "福岡県", "西鉄甘木線" ],
    [ "北野駅", "福岡県", "西鉄甘木線" ],
    [ "大城駅", "福岡県", "西鉄甘木線" ],
    [ "金島駅", "福岡県", "西鉄甘木線" ],
    [ "大堰駅", "福岡県", "西鉄甘木線" ],
    [ "本郷駅", "福岡県", "西鉄甘木線" ],
    [ "上浦駅", "福岡県", "西鉄甘木線" ],
    [ "馬田駅", "福岡県", "西鉄甘木線" ],
    [ "甘木駅", "福岡県", "西鉄甘木線" ],
    [ "貝塚駅", "福岡県", "西鉄貝塚線" ],
    [ "名島駅", "福岡県", "西鉄貝塚線" ],
    [ "西鉄千早駅", "福岡県", "西鉄貝塚線" ],
    [ "香椎宮前駅", "福岡県", "西鉄貝塚線" ],
    [ "西鉄香椎駅", "福岡県", "西鉄貝塚線" ],
    [ "香椎花園前駅", "福岡県", "西鉄貝塚線" ],
    [ "唐の原駅", "福岡県", "西鉄貝塚線" ],
    [ "和白駅", "福岡県", "西鉄貝塚線" ],
    [ "三苫駅", "福岡県", "西鉄貝塚線" ],
    [ "西鉄新宮駅", "福岡県", "西鉄貝塚線" ],
    [ "古賀ゴルフ場前駅", "福岡県", "西鉄貝塚線" ],
    [ "西鉄古賀駅", "福岡県", "西鉄貝塚線" ],
    [ "花見駅", "福岡県", "西鉄貝塚線" ],
    [ "西鉄福間駅", "福岡県", "西鉄貝塚線" ],
    [ "宮地岳駅", "福岡県", "西鉄貝塚線" ],
    [ "津屋崎駅", "福岡県", "西鉄貝塚線" ],
    [ "宮の沢駅", "北海道", "札幌市営地下鉄東西線" ],
    [ "発寒南駅", "北海道", "札幌市営地下鉄東西線" ],
    [ "琴似駅", "北海道", "札幌市営地下鉄東西線" ],
    [ "二十四軒駅", "北海道", "札幌市営地下鉄東西線" ],
    [ "西２８丁目駅", "北海道", "札幌市営地下鉄東西線" ],
    [ "円山公園駅", "北海道", "札幌市営地下鉄東西線" ],
    [ "西１８丁目駅", "北海道", "札幌市営地下鉄東西線" ],
    [ "西１１丁目駅", "北海道", "札幌市営地下鉄東西線" ],
    [ "大通駅", "北海道", "札幌市営地下鉄東西線" ],
    [ "バスセンター前駅", "北海道", "札幌市営地下鉄東西線" ],
    [ "菊水駅", "北海道", "札幌市営地下鉄東西線" ],
    [ "東札幌駅", "北海道", "札幌市営地下鉄東西線" ],
    [ "白石駅", "北海道", "札幌市営地下鉄東西線" ],
    [ "南郷７丁目駅", "北海道", "札幌市営地下鉄東西線" ],
    [ "南郷１３丁目駅", "北海道", "札幌市営地下鉄東西線" ],
    [ "南郷１８丁目駅", "北海道", "札幌市営地下鉄東西線" ],
    [ "大谷地駅", "北海道", "札幌市営地下鉄東西線" ],
    [ "ひばりが丘駅", "北海道", "札幌市営地下鉄東西線" ],
    [ "新さっぽろ駅", "北海道", "札幌市営地下鉄東西線" ],
    [ "麻生駅", "北海道", "札幌市営地下鉄南北線" ],
    [ "北３４条駅", "北海道", "札幌市営地下鉄南北線" ],
    [ "北２４条駅", "北海道", "札幌市営地下鉄南北線" ],
    [ "北１８条駅", "北海道", "札幌市営地下鉄南北線" ],
    [ "北１２条駅", "北海道", "札幌市営地下鉄南北線" ],
    [ "さっぽろ駅", "北海道", "札幌市営地下鉄南北線" ],
    [ "大通駅", "北海道", "札幌市営地下鉄南北線" ],
    [ "すすきの駅", "北海道", "札幌市営地下鉄南北線" ],
    [ "中島公園駅", "北海道", "札幌市営地下鉄南北線" ],
    [ "幌平橋駅", "北海道", "札幌市営地下鉄南北線" ],
    [ "中の島駅", "北海道", "札幌市営地下鉄南北線" ],
    [ "平岸駅", "北海道", "札幌市営地下鉄南北線" ],
    [ "南平岸駅", "北海道", "札幌市営地下鉄南北線" ],
    [ "澄川駅", "北海道", "札幌市営地下鉄南北線" ],
    [ "自衛隊前駅", "北海道", "札幌市営地下鉄南北線" ],
    [ "真駒内駅", "北海道", "札幌市営地下鉄南北線" ],
    [ "栄町駅", "北海道", "札幌市営地下鉄東豊線" ],
    [ "新道東駅", "北海道", "札幌市営地下鉄東豊線" ],
    [ "元町駅", "北海道", "札幌市営地下鉄東豊線" ],
    [ "環状通東駅", "北海道", "札幌市営地下鉄東豊線" ],
    [ "東区役所前駅", "北海道", "札幌市営地下鉄東豊線" ],
    [ "北１３条東駅", "北海道", "札幌市営地下鉄東豊線" ],
    [ "さっぽろ駅", "北海道", "札幌市営地下鉄東豊線" ],
    [ "大通駅", "北海道", "札幌市営地下鉄東豊線" ],
    [ "豊水すすきの駅", "北海道", "札幌市営地下鉄東豊線" ],
    [ "学園前駅", "北海道", "札幌市営地下鉄東豊線" ],
    [ "豊平公園駅", "北海道", "札幌市営地下鉄東豊線" ],
    [ "美園駅", "北海道", "札幌市営地下鉄東豊線" ],
    [ "月寒中央駅", "北海道", "札幌市営地下鉄東豊線" ],
    [ "福住駅", "北海道", "札幌市営地下鉄東豊線" ],
    [ "西４丁目駅", "北海道", "札幌市電山鼻線" ],
    [ "西８丁目駅", "北海道", "札幌市電山鼻線" ],
    [ "中央区役所前駅", "北海道", "札幌市電山鼻線" ],
    [ "西１５丁目駅", "北海道", "札幌市電山鼻線" ],
    [ "西線６条駅", "北海道", "札幌市電山鼻線" ],
    [ "西線９条旭山公園通駅", "北海道", "札幌市電山鼻線" ],
    [ "西線１１条駅", "北海道", "札幌市電山鼻線" ],
    [ "西線１４条駅", "北海道", "札幌市電山鼻線" ],
    [ "西線１６条駅", "北海道", "札幌市電山鼻線" ],
    [ "ロープウェイ入口駅", "北海道", "札幌市電山鼻線" ],
    [ "電車事業所前駅", "北海道", "札幌市電山鼻線" ],
    [ "中央図書館前駅", "北海道", "札幌市電山鼻線" ],
    [ "石山通駅", "北海道", "札幌市電山鼻線" ],
    [ "東屯田通駅", "北海道", "札幌市電山鼻線" ],
    [ "幌南小学校前駅", "北海道", "札幌市電山鼻線" ],
    [ "山鼻１９条駅", "北海道", "札幌市電山鼻線" ],
    [ "静修学園前駅", "北海道", "札幌市電山鼻線" ],
    [ "行啓通駅", "北海道", "札幌市電山鼻線" ],
    [ "中島公園通駅", "北海道", "札幌市電山鼻線" ],
    [ "山鼻９条駅", "北海道", "札幌市電山鼻線" ],
    [ "東本願寺前駅", "北海道", "札幌市電山鼻線" ],
    [ "資生館小学校前駅", "北海道", "札幌市電山鼻線" ],
    [ "すすきの駅", "北海道", "札幌市電山鼻線" ],
    [ "狸小路駅", "北海道", "札幌市電山鼻線" ],
    [ "湯の川駅", "北海道", "函館市電２系統" ],
    [ "湯の川温泉駅", "北海道", "函館市電２系統" ],
    [ "函館アリーナ前駅", "北海道", "函館市電２系統" ],
    [ "駒場車庫前駅", "北海道", "函館市電２系統" ],
    [ "競馬場前駅", "北海道", "函館市電２系統" ],
    [ "深堀町駅", "北海道", "函館市電２系統" ],
    [ "柏木町駅", "北海道", "函館市電２系統" ],
    [ "杉並町駅", "北海道", "函館市電２系統" ],
    [ "五稜郭公園前駅", "北海道", "函館市電２系統" ],
    [ "中央病院前駅", "北海道", "函館市電２系統" ],
    [ "千代台駅", "北海道", "函館市電２系統" ],
    [ "堀川町駅", "北海道", "函館市電２系統" ],
    [ "昭和橋駅", "北海道", "函館市電２系統" ],
    [ "千歳町駅", "北海道", "函館市電２系統" ],
    [ "新川町駅", "北海道", "函館市電２系統" ],
    [ "松風町駅", "北海道", "函館市電２系統" ],
    [ "函館駅前駅", "北海道", "函館市電２系統" ],
    [ "市役所前駅", "北海道", "函館市電２系統" ],
    [ "魚市場通駅", "北海道", "函館市電２系統" ],
    [ "十字街駅", "北海道", "函館市電２系統" ],
    [ "宝来町駅", "北海道", "函館市電２系統" ],
    [ "青柳町駅", "北海道", "函館市電２系統" ],
    [ "谷地頭駅", "北海道", "函館市電２系統" ],
    [ "湯の川駅", "北海道", "函館市電５系統" ],
    [ "湯の川温泉駅", "北海道", "函館市電５系統" ],
    [ "函館アリーナ前駅", "北海道", "函館市電５系統" ],
    [ "駒場車庫前駅", "北海道", "函館市電５系統" ],
    [ "競馬場前駅", "北海道", "函館市電５系統" ],
    [ "深堀町駅", "北海道", "函館市電５系統" ],
    [ "柏木町駅", "北海道", "函館市電５系統" ],
    [ "杉並町駅", "北海道", "函館市電５系統" ],
    [ "五稜郭公園前駅", "北海道", "函館市電５系統" ],
    [ "中央病院前駅", "北海道", "函館市電５系統" ],
    [ "千代台駅", "北海道", "函館市電５系統" ],
    [ "堀川町駅", "北海道", "函館市電５系統" ],
    [ "昭和橋駅", "北海道", "函館市電５系統" ],
    [ "千歳町駅", "北海道", "函館市電５系統" ],
    [ "新川町駅", "北海道", "函館市電５系統" ],
    [ "松風町駅", "北海道", "函館市電５系統" ],
    [ "函館駅前駅", "北海道", "函館市電５系統" ],
    [ "市役所前駅", "北海道", "函館市電５系統" ],
    [ "魚市場通駅", "北海道", "函館市電５系統" ],
    [ "十字街駅", "北海道", "函館市電５系統" ],
    [ "末広町駅", "北海道", "函館市電５系統" ],
    [ "大町駅", "北海道", "函館市電５系統" ],
    [ "函館どつく前駅", "北海道", "函館市電５系統" ],
    [ "池田駅", "北海道", "ふるさと銀河線" ],
    [ "様舞駅", "北海道", "ふるさと銀河線" ],
    [ "高島駅", "北海道", "ふるさと銀河線" ],
    [ "大森駅", "北海道", "ふるさと銀河線" ],
    [ "勇足駅", "北海道", "ふるさと銀河線" ],
    [ "南本別駅", "北海道", "ふるさと銀河線" ],
    [ "岡女堂駅", "北海道", "ふるさと銀河線" ],
    [ "本別駅", "北海道", "ふるさと銀河線" ],
    [ "仙美里駅", "北海道", "ふるさと銀河線" ],
    [ "足寄駅", "北海道", "ふるさと銀河線" ],
    [ "愛冠駅", "北海道", "ふるさと銀河線" ],
    [ "西一線駅", "北海道", "ふるさと銀河線" ],
    [ "塩幌駅", "北海道", "ふるさと銀河線" ],
    [ "上利別駅", "北海道", "ふるさと銀河線" ],
    [ "笹森駅", "北海道", "ふるさと銀河線" ],
    [ "大誉地駅", "北海道", "ふるさと銀河線" ],
    [ "薫別駅", "北海道", "ふるさと銀河線" ],
    [ "陸別駅", "北海道", "ふるさと銀河線" ],
    [ "分線駅", "北海道", "ふるさと銀河線" ],
    [ "川上駅", "北海道", "ふるさと銀河線" ],
    [ "小利別駅", "北海道", "ふるさと銀河線" ],
    [ "置戸駅", "北海道", "ふるさと銀河線" ],
    [ "豊住駅", "北海道", "ふるさと銀河線" ],
    [ "境野駅", "北海道", "ふるさと銀河線" ],
    [ "西訓子府駅", "北海道", "ふるさと銀河線" ],
    [ "西富駅", "北海道", "ふるさと銀河線" ],
    [ "訓子府駅", "北海道", "ふるさと銀河線" ],
    [ "穂波駅", "北海道", "ふるさと銀河線" ],
    [ "日ノ出駅", "北海道", "ふるさと銀河線" ],
    [ "広郷駅", "北海道", "ふるさと銀河線" ],
    [ "上常呂駅", "北海道", "ふるさと銀河線" ],
    [ "北光社駅", "北海道", "ふるさと銀河線" ],
    [ "北見駅", "北海道", "ふるさと銀河線" ],
    [ "津軽五所川原駅", "青森県", "津軽鉄道線" ],
    [ "十川駅", "青森県", "津軽鉄道線" ],
    [ "五農校前駅", "青森県", "津軽鉄道線" ],
    [ "津軽飯詰駅", "青森県", "津軽鉄道線" ],
    [ "毘沙門駅", "青森県", "津軽鉄道線" ],
    [ "嘉瀬駅", "青森県", "津軽鉄道線" ],
    [ "金木駅", "青森県", "津軽鉄道線" ],
    [ "芦野公園駅", "青森県", "津軽鉄道線" ],
    [ "川倉駅", "青森県", "津軽鉄道線" ],
    [ "大沢内駅", "青森県", "津軽鉄道線" ],
    [ "深郷田駅", "青森県", "津軽鉄道線" ],
    [ "津軽中里駅", "青森県", "津軽鉄道線" ],
    [ "弘前駅", "青森県", "弘南鉄道弘南線" ],
    [ "弘前東高前駅", "青森県", "弘南鉄道弘南線" ],
    [ "運動公園前駅", "青森県", "弘南鉄道弘南線" ],
    [ "新里駅", "青森県", "弘南鉄道弘南線" ],
    [ "館田駅", "青森県", "弘南鉄道弘南線" ],
    [ "平賀駅", "青森県", "弘南鉄道弘南線" ],
    [ "柏農高校前駅", "青森県", "弘南鉄道弘南線" ],
    [ "津軽尾上駅", "青森県", "弘南鉄道弘南線" ],
    [ "尾上高校前駅", "青森県", "弘南鉄道弘南線" ],
    [ "田んぼアート駅", "青森県", "弘南鉄道弘南線" ],
    [ "田舎館駅", "青森県", "弘南鉄道弘南線" ],
    [ "境松駅", "青森県", "弘南鉄道弘南線" ],
    [ "黒石駅", "青森県", "弘南鉄道弘南線" ],
    [ "大鰐駅", "青森県", "弘南鉄道大鰐線" ],
    [ "宿川原駅", "青森県", "弘南鉄道大鰐線" ],
    [ "鯖石駅", "青森県", "弘南鉄道大鰐線" ],
    [ "石川プール前駅", "青森県", "弘南鉄道大鰐線" ],
    [ "石川駅", "青森県", "弘南鉄道大鰐線" ],
    [ "義塾高校前駅", "青森県", "弘南鉄道大鰐線" ],
    [ "津軽大沢駅", "青森県", "弘南鉄道大鰐線" ],
    [ "松木平駅", "青森県", "弘南鉄道大鰐線" ],
    [ "小栗山駅", "青森県", "弘南鉄道大鰐線" ],
    [ "千年駅", "青森県", "弘南鉄道大鰐線" ],
    [ "聖愛中高前駅", "青森県", "弘南鉄道大鰐線" ],
    [ "弘前学院大前駅", "青森県", "弘南鉄道大鰐線" ],
    [ "弘高下駅", "青森県", "弘南鉄道大鰐線" ],
    [ "中央弘前駅", "青森県", "弘南鉄道大鰐線" ],
    [ "三沢駅", "青森県", "十和田観光電鉄" ],
    [ "大曲駅", "青森県", "十和田観光電鉄" ],
    [ "柳沢駅", "青森県", "十和田観光電鉄" ],
    [ "七百駅", "青森県", "十和田観光電鉄" ],
    [ "古里駅", "青森県", "十和田観光電鉄" ],
    [ "三農校前駅", "青森県", "十和田観光電鉄" ],
    [ "高清水駅", "青森県", "十和田観光電鉄" ],
    [ "北里大学前駅", "青森県", "十和田観光電鉄" ],
    [ "工業高校前駅", "青森県", "十和田観光電鉄" ],
    [ "ひがし野団地駅", "青森県", "十和田観光電鉄" ],
    [ "十和田市駅", "青森県", "十和田観光電鉄" ],
    [ "盛岡駅", "岩手県", "いわて銀河鉄道線" ],
    [ "青山駅", "岩手県", "いわて銀河鉄道線" ],
    [ "厨川駅", "岩手県", "いわて銀河鉄道線" ],
    [ "巣子駅", "岩手県", "いわて銀河鉄道線" ],
    [ "滝沢駅", "岩手県", "いわて銀河鉄道線" ],
    [ "渋民駅", "岩手県", "いわて銀河鉄道線" ],
    [ "好摩駅", "岩手県", "いわて銀河鉄道線" ],
    [ "岩手川口駅", "岩手県", "いわて銀河鉄道線" ],
    [ "いわて沼宮内駅", "岩手県", "いわて銀河鉄道線" ],
    [ "御堂駅", "岩手県", "いわて銀河鉄道線" ],
    [ "奥中山高原駅", "岩手県", "いわて銀河鉄道線" ],
    [ "小繋駅", "岩手県", "いわて銀河鉄道線" ],
    [ "小鳥谷駅", "岩手県", "いわて銀河鉄道線" ],
    [ "一戸駅", "岩手県", "いわて銀河鉄道線" ],
    [ "二戸駅", "岩手県", "いわて銀河鉄道線" ],
    [ "斗米駅", "岩手県", "いわて銀河鉄道線" ],
    [ "金田一温泉駅", "岩手県", "いわて銀河鉄道線" ],
    [ "目時駅", "青森県", "いわて銀河鉄道線" ],
    [ "目時駅", "青森県", "青い森鉄道線" ],
    [ "三戸駅", "青森県", "青い森鉄道線" ],
    [ "諏訪ノ平駅", "青森県", "青い森鉄道線" ],
    [ "剣吉駅", "青森県", "青い森鉄道線" ],
    [ "苫米地駅", "青森県", "青い森鉄道線" ],
    [ "北高岩駅", "青森県", "青い森鉄道線" ],
    [ "八戸駅", "青森県", "青い森鉄道線" ],
    [ "陸奥市川駅", "青森県", "青い森鉄道線" ],
    [ "下田駅", "青森県", "青い森鉄道線" ],
    [ "向山駅", "青森県", "青い森鉄道線" ],
    [ "三沢駅", "青森県", "青い森鉄道線" ],
    [ "小川原駅", "青森県", "青い森鉄道線" ],
    [ "上北町駅", "青森県", "青い森鉄道線" ],
    [ "乙供駅", "青森県", "青い森鉄道線" ],
    [ "千曳駅", "青森県", "青い森鉄道線" ],
    [ "野辺地駅", "青森県", "青い森鉄道線" ],
    [ "狩場沢駅", "青森県", "青い森鉄道線" ],
    [ "清水川駅", "青森県", "青い森鉄道線" ],
    [ "小湊駅", "青森県", "青い森鉄道線" ],
    [ "西平内駅", "青森県", "青い森鉄道線" ],
    [ "浅虫温泉駅", "青森県", "青い森鉄道線" ],
    [ "野内駅", "青森県", "青い森鉄道線" ],
    [ "矢田前駅", "青森県", "青い森鉄道線" ],
    [ "小柳駅", "青森県", "青い森鉄道線" ],
    [ "東青森駅", "青森県", "青い森鉄道線" ],
    [ "筒井駅", "青森県", "青い森鉄道線" ],
    [ "青森駅", "青森県", "青い森鉄道線" ],
    [ "盛駅", "岩手県", "三陸鉄道リアス線" ],
    [ "陸前赤崎駅", "岩手県", "三陸鉄道リアス線" ],
    [ "綾里駅", "岩手県", "三陸鉄道リアス線" ],
    [ "恋し浜駅", "岩手県", "三陸鉄道リアス線" ],
    [ "甫嶺駅", "岩手県", "三陸鉄道リアス線" ],
    [ "三陸駅", "岩手県", "三陸鉄道リアス線" ],
    [ "吉浜駅", "岩手県", "三陸鉄道リアス線" ],
    [ "唐丹駅", "岩手県", "三陸鉄道リアス線" ],
    [ "平田駅", "岩手県", "三陸鉄道リアス線" ],
    [ "釜石駅", "岩手県", "三陸鉄道リアス線" ],
    [ "両石駅", "岩手県", "三陸鉄道リアス線" ],
    [ "鵜住居駅", "岩手県", "三陸鉄道リアス線" ],
    [ "大槌駅", "岩手県", "三陸鉄道リアス線" ],
    [ "吉里吉里駅", "岩手県", "三陸鉄道リアス線" ],
    [ "浪板海岸駅", "岩手県", "三陸鉄道リアス線" ],
    [ "岩手船越駅", "岩手県", "三陸鉄道リアス線" ],
    [ "織笠駅", "岩手県", "三陸鉄道リアス線" ],
    [ "陸中山田駅", "岩手県", "三陸鉄道リアス線" ],
    [ "豊間根駅", "岩手県", "三陸鉄道リアス線" ],
    [ "払川駅", "岩手県", "三陸鉄道リアス線" ],
    [ "津軽石駅", "岩手県", "三陸鉄道リアス線" ],
    [ "八木沢・宮古短大駅", "岩手県", "三陸鉄道リアス線" ],
    [ "磯鶏駅", "岩手県", "三陸鉄道リアス線" ],
    [ "宮古駅", "岩手県", "三陸鉄道リアス線" ],
    [ "山口団地駅", "岩手県", "三陸鉄道リアス線" ],
    [ "一の渡駅", "岩手県", "三陸鉄道リアス線" ],
    [ "佐羽根駅", "岩手県", "三陸鉄道リアス線" ],
    [ "田老駅", "岩手県", "三陸鉄道リアス線" ],
    [ "新田老駅", "岩手県", "三陸鉄道リアス線" ],
    [ "摂待駅", "岩手県", "三陸鉄道リアス線" ],
    [ "岩泉小本駅", "岩手県", "三陸鉄道リアス線" ],
    [ "島越駅", "岩手県", "三陸鉄道リアス線" ],
    [ "田野畑駅", "岩手県", "三陸鉄道リアス線" ],
    [ "普代駅", "岩手県", "三陸鉄道リアス線" ],
    [ "白井海岸駅", "岩手県", "三陸鉄道リアス線" ],
    [ "堀内駅", "岩手県", "三陸鉄道リアス線" ],
    [ "野田玉川駅", "岩手県", "三陸鉄道リアス線" ],
    [ "十府ヶ浦海岸駅", "岩手県", "三陸鉄道リアス線" ],
    [ "陸中野田駅", "岩手県", "三陸鉄道リアス線" ],
    [ "陸中宇部駅", "岩手県", "三陸鉄道リアス線" ],
    [ "久慈駅", "岩手県", "三陸鉄道リアス線" ],
    [ "鷹巣駅", "秋田県", "秋田内陸線" ],
    [ "西鷹巣駅", "秋田県", "秋田内陸線" ],
    [ "縄文小ヶ田駅", "秋田県", "秋田内陸線" ],
    [ "大野台駅", "秋田県", "秋田内陸線" ],
    [ "合川駅", "秋田県", "秋田内陸線" ],
    [ "上杉駅", "秋田県", "秋田内陸線" ],
    [ "米内沢駅", "秋田県", "秋田内陸線" ],
    [ "桂瀬駅", "秋田県", "秋田内陸線" ],
    [ "阿仁前田温泉駅", "秋田県", "秋田内陸線" ],
    [ "前田南駅", "秋田県", "秋田内陸線" ],
    [ "小渕駅", "秋田県", "秋田内陸線" ],
    [ "阿仁合駅", "秋田県", "秋田内陸線" ],
    [ "荒瀬駅", "秋田県", "秋田内陸線" ],
    [ "萱草駅", "秋田県", "秋田内陸線" ],
    [ "笑内駅", "秋田県", "秋田内陸線" ],
    [ "岩野目駅", "秋田県", "秋田内陸線" ],
    [ "比立内駅", "秋田県", "秋田内陸線" ],
    [ "奥阿仁駅", "秋田県", "秋田内陸線" ],
    [ "阿仁マタギ駅", "秋田県", "秋田内陸線" ],
    [ "戸沢駅", "秋田県", "秋田内陸線" ],
    [ "上桧木内駅", "秋田県", "秋田内陸線" ],
    [ "左通駅", "秋田県", "秋田内陸線" ],
    [ "羽後中里駅", "秋田県", "秋田内陸線" ],
    [ "松葉駅", "秋田県", "秋田内陸線" ],
    [ "羽後長戸呂駅", "秋田県", "秋田内陸線" ],
    [ "八津駅", "秋田県", "秋田内陸線" ],
    [ "西明寺駅", "秋田県", "秋田内陸線" ],
    [ "羽後太田駅", "秋田県", "秋田内陸線" ],
    [ "角館駅", "秋田県", "秋田内陸線" ],
    [ "羽後本荘駅", "秋田県", "鳥海山ろく線" ],
    [ "薬師堂駅", "秋田県", "鳥海山ろく線" ],
    [ "子吉駅", "秋田県", "鳥海山ろく線" ],
    [ "鮎川駅", "秋田県", "鳥海山ろく線" ],
    [ "黒沢駅", "秋田県", "鳥海山ろく線" ],
    [ "曲沢駅", "秋田県", "鳥海山ろく線" ],
    [ "前郷駅", "秋田県", "鳥海山ろく線" ],
    [ "久保田駅", "秋田県", "鳥海山ろく線" ],
    [ "西滝沢駅", "秋田県", "鳥海山ろく線" ],
    [ "吉沢駅", "秋田県", "鳥海山ろく線" ],
    [ "川辺駅", "秋田県", "鳥海山ろく線" ],
    [ "矢島駅", "秋田県", "鳥海山ろく線" ],
    [ "赤湯駅", "山形県", "フラワー長井線" ],
    [ "南陽市役所駅", "山形県", "フラワー長井線" ],
    [ "宮内駅", "山形県", "フラワー長井線" ],
    [ "おりはた駅", "山形県", "フラワー長井線" ],
    [ "梨郷駅", "山形県", "フラワー長井線" ],
    [ "西大塚駅", "山形県", "フラワー長井線" ],
    [ "今泉駅", "山形県", "フラワー長井線" ],
    [ "時庭駅", "山形県", "フラワー長井線" ],
    [ "南長井駅", "山形県", "フラワー長井線" ],
    [ "長井駅", "山形県", "フラワー長井線" ],
    [ "あやめ公園駅", "山形県", "フラワー長井線" ],
    [ "羽前成田駅", "山形県", "フラワー長井線" ],
    [ "白兎駅", "山形県", "フラワー長井線" ],
    [ "蚕桑駅", "山形県", "フラワー長井線" ],
    [ "鮎貝駅", "山形県", "フラワー長井線" ],
    [ "四季の郷駅", "山形県", "フラワー長井線" ],
    [ "荒砥駅", "山形県", "フラワー長井線" ],
    [ "石越駅", "宮城県", "くりはら田園鉄道線" ],
    [ "荒町駅", "宮城県", "くりはら田園鉄道線" ],
    [ "若柳駅", "宮城県", "くりはら田園鉄道線" ],
    [ "谷地畑駅", "宮城県", "くりはら田園鉄道線" ],
    [ "大岡小前駅", "宮城県", "くりはら田園鉄道線" ],
    [ "大岡駅", "宮城県", "くりはら田園鉄道線" ],
    [ "沢辺駅", "宮城県", "くりはら田園鉄道線" ],
    [ "津久毛駅", "宮城県", "くりはら田園鉄道線" ],
    [ "杉橋駅", "宮城県", "くりはら田園鉄道線" ],
    [ "鳥矢崎駅", "宮城県", "くりはら田園鉄道線" ],
    [ "栗駒駅", "宮城県", "くりはら田園鉄道線" ],
    [ "栗原田町駅", "宮城県", "くりはら田園鉄道線" ],
    [ "尾松駅", "宮城県", "くりはら田園鉄道線" ],
    [ "鶯沢駅", "宮城県", "くりはら田園鉄道線" ],
    [ "鶯沢工業高校前駅", "宮城県", "くりはら田園鉄道線" ],
    [ "細倉マインパーク前駅", "宮城県", "くりはら田園鉄道線" ],
    [ "福島駅", "福島県", "阿武隈急行線" ],
    [ "卸町駅", "福島県", "阿武隈急行線" ],
    [ "福島学院前駅", "福島県", "阿武隈急行線" ],
    [ "瀬上駅", "福島県", "阿武隈急行線" ],
    [ "向瀬上駅", "福島県", "阿武隈急行線" ],
    [ "高子駅", "福島県", "阿武隈急行線" ],
    [ "上保原駅", "福島県", "阿武隈急行線" ],
    [ "保原駅", "福島県", "阿武隈急行線" ],
    [ "大泉駅", "福島県", "阿武隈急行線" ],
    [ "二井田駅", "福島県", "阿武隈急行線" ],
    [ "新田駅", "福島県", "阿武隈急行線" ],
    [ "梁川駅", "福島県", "阿武隈急行線" ],
    [ "やながわ希望の森公園前駅", "福島県", "阿武隈急行線" ],
    [ "富野駅", "福島県", "阿武隈急行線" ],
    [ "兜駅", "福島県", "阿武隈急行線" ],
    [ "あぶくま駅", "宮城県", "阿武隈急行線" ],
    [ "丸森駅", "宮城県", "阿武隈急行線" ],
    [ "北丸森駅", "宮城県", "阿武隈急行線" ],
    [ "南角田駅", "宮城県", "阿武隈急行線" ],
    [ "角田駅", "宮城県", "阿武隈急行線" ],
    [ "横倉駅", "宮城県", "阿武隈急行線" ],
    [ "岡駅", "宮城県", "阿武隈急行線" ],
    [ "東船岡駅", "宮城県", "阿武隈急行線" ],
    [ "槻木駅", "宮城県", "阿武隈急行線" ],
    [ "泉中央駅", "宮城県", "仙台市営地下鉄南北線" ],
    [ "八乙女駅", "宮城県", "仙台市営地下鉄南北線" ],
    [ "黒松駅", "宮城県", "仙台市営地下鉄南北線" ],
    [ "旭ヶ丘駅", "宮城県", "仙台市営地下鉄南北線" ],
    [ "台原駅", "宮城県", "仙台市営地下鉄南北線" ],
    [ "北仙台駅", "宮城県", "仙台市営地下鉄南北線" ],
    [ "北四番丁駅", "宮城県", "仙台市営地下鉄南北線" ],
    [ "勾当台公園駅", "宮城県", "仙台市営地下鉄南北線" ],
    [ "広瀬通駅", "宮城県", "仙台市営地下鉄南北線" ],
    [ "仙台駅", "宮城県", "仙台市営地下鉄南北線" ],
    [ "五橋駅", "宮城県", "仙台市営地下鉄南北線" ],
    [ "愛宕橋駅", "宮城県", "仙台市営地下鉄南北線" ],
    [ "河原町駅", "宮城県", "仙台市営地下鉄南北線" ],
    [ "長町一丁目駅", "宮城県", "仙台市営地下鉄南北線" ],
    [ "長町駅", "宮城県", "仙台市営地下鉄南北線" ],
    [ "長町南駅", "宮城県", "仙台市営地下鉄南北線" ],
    [ "富沢駅", "宮城県", "仙台市営地下鉄南北線" ],
    [ "福島駅", "福島県", "福島交通飯坂線" ],
    [ "曽根田駅", "福島県", "福島交通飯坂線" ],
    [ "美術館図書館前駅", "福島県", "福島交通飯坂線" ],
    [ "岩代清水駅", "福島県", "福島交通飯坂線" ],
    [ "泉駅", "福島県", "福島交通飯坂線" ],
    [ "上松川駅", "福島県", "福島交通飯坂線" ],
    [ "笹谷駅", "福島県", "福島交通飯坂線" ],
    [ "桜水駅", "福島県", "福島交通飯坂線" ],
    [ "平野駅", "福島県", "福島交通飯坂線" ],
    [ "医王寺前駅", "福島県", "福島交通飯坂線" ],
    [ "花水坂駅", "福島県", "福島交通飯坂線" ],
    [ "飯坂温泉駅", "福島県", "福島交通飯坂線" ],
    [ "西若松駅", "福島県", "会津鉄道会津線" ],
    [ "南若松駅", "福島県", "会津鉄道会津線" ],
    [ "門田駅", "福島県", "会津鉄道会津線" ],
    [ "あまや駅", "福島県", "会津鉄道会津線" ],
    [ "芦ノ牧温泉駅", "福島県", "会津鉄道会津線" ],
    [ "大川ダム公園駅", "福島県", "会津鉄道会津線" ],
    [ "芦ノ牧温泉南駅", "福島県", "会津鉄道会津線" ],
    [ "湯野上温泉駅", "福島県", "会津鉄道会津線" ],
    [ "塔のへつり駅", "福島県", "会津鉄道会津線" ],
    [ "弥五島駅", "福島県", "会津鉄道会津線" ],
    [ "会津下郷駅", "福島県", "会津鉄道会津線" ],
    [ "ふるさと公園駅", "福島県", "会津鉄道会津線" ],
    [ "養鱒公園駅", "福島県", "会津鉄道会津線" ],
    [ "会津長野駅", "福島県", "会津鉄道会津線" ],
    [ "田島高校前駅", "福島県", "会津鉄道会津線" ],
    [ "会津田島駅", "福島県", "会津鉄道会津線" ],
    [ "中荒井駅", "福島県", "会津鉄道会津線" ],
    [ "会津荒海駅", "福島県", "会津鉄道会津線" ],
    [ "会津山村道場駅", "福島県", "会津鉄道会津線" ],
    [ "七ヶ岳登山口駅", "福島県", "会津鉄道会津線" ],
    [ "会津高原尾瀬口駅", "福島県", "会津鉄道会津線" ],
    [ "名取駅", "宮城県", "仙台空港線" ],
    [ "杜せきのした駅", "宮城県", "仙台空港線" ],
    [ "美田園駅", "宮城県", "仙台空港線" ],
    [ "仙台空港駅", "宮城県", "仙台空港線" ],
    [ "八木山動物公園駅", "宮城県", "仙台市営地下鉄東西線" ],
    [ "青葉山駅", "宮城県", "仙台市営地下鉄東西線" ],
    [ "川内駅", "宮城県", "仙台市営地下鉄東西線" ],
    [ "国際センター駅", "宮城県", "仙台市営地下鉄東西線" ],
    [ "大町西公園駅", "宮城県", "仙台市営地下鉄東西線" ],
    [ "青葉通一番町駅", "宮城県", "仙台市営地下鉄東西線" ],
    [ "仙台駅", "宮城県", "仙台市営地下鉄東西線" ],
    [ "宮城野通駅", "宮城県", "仙台市営地下鉄東西線" ],
    [ "連坊駅", "宮城県", "仙台市営地下鉄東西線" ],
    [ "薬師堂駅", "宮城県", "仙台市営地下鉄東西線" ],
    [ "卸町駅", "宮城県", "仙台市営地下鉄東西線" ],
    [ "六丁の目駅", "宮城県", "仙台市営地下鉄東西線" ],
    [ "荒井駅", "宮城県", "仙台市営地下鉄東西線" ],
    [ "都庁前駅", "東京都", "都営大江戸線" ],
    [ "新宿西口駅", "東京都", "都営大江戸線" ],
    [ "東新宿駅", "東京都", "都営大江戸線" ],
    [ "若松河田駅", "東京都", "都営大江戸線" ],
    [ "牛込柳町駅", "東京都", "都営大江戸線" ],
    [ "牛込神楽坂駅", "東京都", "都営大江戸線" ],
    [ "飯田橋駅", "東京都", "都営大江戸線" ],
    [ "春日駅", "東京都", "都営大江戸線" ],
    [ "本郷三丁目駅", "東京都", "都営大江戸線" ],
    [ "上野御徒町駅", "東京都", "都営大江戸線" ],
    [ "新御徒町駅", "東京都", "都営大江戸線" ],
    [ "蔵前駅", "東京都", "都営大江戸線" ],
    [ "両国駅", "東京都", "都営大江戸線" ],
    [ "森下駅", "東京都", "都営大江戸線" ],
    [ "清澄白河駅", "東京都", "都営大江戸線" ],
    [ "門前仲町駅", "東京都", "都営大江戸線" ],
    [ "月島駅", "東京都", "都営大江戸線" ],
    [ "勝どき駅", "東京都", "都営大江戸線" ],
    [ "築地市場駅", "東京都", "都営大江戸線" ],
    [ "汐留駅", "東京都", "都営大江戸線" ],
    [ "大門駅", "東京都", "都営大江戸線" ],
    [ "赤羽橋駅", "東京都", "都営大江戸線" ],
    [ "麻布十番駅", "東京都", "都営大江戸線" ],
    [ "六本木駅", "東京都", "都営大江戸線" ],
    [ "青山一丁目駅", "東京都", "都営大江戸線" ],
    [ "国立競技場駅", "東京都", "都営大江戸線" ],
    [ "代々木駅", "東京都", "都営大江戸線" ],
    [ "新宿駅", "東京都", "都営大江戸線" ],
    [ "西新宿五丁目駅", "東京都", "都営大江戸線" ],
    [ "中野坂上駅", "東京都", "都営大江戸線" ],
    [ "東中野駅", "東京都", "都営大江戸線" ],
    [ "中井駅", "東京都", "都営大江戸線" ],
    [ "落合南長崎駅", "東京都", "都営大江戸線" ],
    [ "新江古田駅", "東京都", "都営大江戸線" ],
    [ "練馬駅", "東京都", "都営大江戸線" ],
    [ "豊島園駅", "東京都", "都営大江戸線" ],
    [ "練馬春日町駅", "東京都", "都営大江戸線" ],
    [ "光が丘駅", "東京都", "都営大江戸線" ],
    [ "西馬込駅", "東京都", "都営浅草線" ],
    [ "馬込駅", "東京都", "都営浅草線" ],
    [ "中延駅", "東京都", "都営浅草線" ],
    [ "戸越駅", "東京都", "都営浅草線" ],
    [ "五反田駅", "東京都", "都営浅草線" ],
    [ "高輪台駅", "東京都", "都営浅草線" ],
    [ "泉岳寺駅", "東京都", "都営浅草線" ],
    [ "三田駅", "東京都", "都営浅草線" ],
    [ "大門駅", "東京都", "都営浅草線" ],
    [ "新橋駅", "東京都", "都営浅草線" ],
    [ "東銀座駅", "東京都", "都営浅草線" ],
    [ "宝町駅", "東京都", "都営浅草線" ],
    [ "日本橋駅", "東京都", "都営浅草線" ],
    [ "人形町駅", "東京都", "都営浅草線" ],
    [ "東日本橋駅", "東京都", "都営浅草線" ],
    [ "浅草橋駅", "東京都", "都営浅草線" ],
    [ "蔵前駅", "東京都", "都営浅草線" ],
    [ "浅草駅", "東京都", "都営浅草線" ],
    [ "本所吾妻橋駅", "東京都", "都営浅草線" ],
    [ "押上（スカイツリー前）駅", "東京都", "都営浅草線" ],
    [ "目黒駅", "東京都", "都営三田線" ],
    [ "白金台駅", "東京都", "都営三田線" ],
    [ "白金高輪駅", "東京都", "都営三田線" ],
    [ "三田駅", "東京都", "都営三田線" ],
    [ "芝公園駅", "東京都", "都営三田線" ],
    [ "御成門駅", "東京都", "都営三田線" ],
    [ "内幸町駅", "東京都", "都営三田線" ],
    [ "日比谷駅", "東京都", "都営三田線" ],
    [ "大手町駅", "東京都", "都営三田線" ],
    [ "神保町駅", "東京都", "都営三田線" ],
    [ "水道橋駅", "東京都", "都営三田線" ],
    [ "春日駅", "東京都", "都営三田線" ],
    [ "白山駅", "東京都", "都営三田線" ],
    [ "千石駅", "東京都", "都営三田線" ],
    [ "巣鴨駅", "東京都", "都営三田線" ],
    [ "西巣鴨駅", "東京都", "都営三田線" ],
    [ "新板橋駅", "東京都", "都営三田線" ],
    [ "板橋区役所前駅", "東京都", "都営三田線" ],
    [ "板橋本町駅", "東京都", "都営三田線" ],
    [ "本蓮沼駅", "東京都", "都営三田線" ],
    [ "志村坂上駅", "東京都", "都営三田線" ],
    [ "志村三丁目駅", "東京都", "都営三田線" ],
    [ "蓮根駅", "東京都", "都営三田線" ],
    [ "西台駅", "東京都", "都営三田線" ],
    [ "高島平駅", "東京都", "都営三田線" ],
    [ "新高島平駅", "東京都", "都営三田線" ],
    [ "西高島平駅", "東京都", "都営三田線" ],
    [ "新宿駅", "東京都", "都営新宿線" ],
    [ "新宿三丁目駅", "東京都", "都営新宿線" ],
    [ "曙橋駅", "東京都", "都営新宿線" ],
    [ "市ヶ谷駅", "東京都", "都営新宿線" ],
    [ "九段下駅", "東京都", "都営新宿線" ],
    [ "神保町駅", "東京都", "都営新宿線" ],
    [ "小川町駅", "東京都", "都営新宿線" ],
    [ "岩本町駅", "東京都", "都営新宿線" ],
    [ "馬喰横山駅", "東京都", "都営新宿線" ],
    [ "浜町駅", "東京都", "都営新宿線" ],
    [ "森下駅", "東京都", "都営新宿線" ],
    [ "菊川駅", "東京都", "都営新宿線" ],
    [ "住吉駅", "東京都", "都営新宿線" ],
    [ "西大島駅", "東京都", "都営新宿線" ],
    [ "大島駅", "東京都", "都営新宿線" ],
    [ "東大島駅", "東京都", "都営新宿線" ],
    [ "船堀駅", "東京都", "都営新宿線" ],
    [ "一之江駅", "東京都", "都営新宿線" ],
    [ "瑞江駅", "東京都", "都営新宿線" ],
    [ "篠崎駅", "東京都", "都営新宿線" ],
    [ "本八幡駅", "千葉県", "都営新宿線" ],
    [ "三ノ輪橋駅", "東京都", "東京さくらトラム（都電荒川線）" ],
    [ "荒川一中前駅", "東京都", "東京さくらトラム（都電荒川線）" ],
    [ "荒川区役所前駅", "東京都", "東京さくらトラム（都電荒川線）" ],
    [ "荒川二丁目駅", "東京都", "東京さくらトラム（都電荒川線）" ],
    [ "荒川七丁目駅", "東京都", "東京さくらトラム（都電荒川線）" ],
    [ "町屋駅前駅", "東京都", "東京さくらトラム（都電荒川線）" ],
    [ "町屋二丁目駅", "東京都", "東京さくらトラム（都電荒川線）" ],
    [ "東尾久三丁目駅", "東京都", "東京さくらトラム（都電荒川線）" ],
    [ "熊野前駅", "東京都", "東京さくらトラム（都電荒川線）" ],
    [ "宮ノ前駅", "東京都", "東京さくらトラム（都電荒川線）" ],
    [ "小台駅", "東京都", "東京さくらトラム（都電荒川線）" ],
    [ "荒川遊園地前駅", "東京都", "東京さくらトラム（都電荒川線）" ],
    [ "荒川車庫前駅", "東京都", "東京さくらトラム（都電荒川線）" ],
    [ "梶原駅", "東京都", "東京さくらトラム（都電荒川線）" ],
    [ "栄町駅", "東京都", "東京さくらトラム（都電荒川線）" ],
    [ "王子駅前駅", "東京都", "東京さくらトラム（都電荒川線）" ],
    [ "飛鳥山駅", "東京都", "東京さくらトラム（都電荒川線）" ],
    [ "滝野川一丁目駅", "東京都", "東京さくらトラム（都電荒川線）" ],
    [ "西ヶ原四丁目駅", "東京都", "東京さくらトラム（都電荒川線）" ],
    [ "新庚申塚駅", "東京都", "東京さくらトラム（都電荒川線）" ],
    [ "庚申塚駅", "東京都", "東京さくらトラム（都電荒川線）" ],
    [ "巣鴨新田駅", "東京都", "東京さくらトラム（都電荒川線）" ],
    [ "大塚駅前駅", "東京都", "東京さくらトラム（都電荒川線）" ],
    [ "向原駅", "東京都", "東京さくらトラム（都電荒川線）" ],
    [ "東池袋四丁目駅", "東京都", "東京さくらトラム（都電荒川線）" ],
    [ "都電雑司ヶ谷駅", "東京都", "東京さくらトラム（都電荒川線）" ],
    [ "鬼子母神前駅", "東京都", "東京さくらトラム（都電荒川線）" ],
    [ "学習院下駅", "東京都", "東京さくらトラム（都電荒川線）" ],
    [ "面影橋駅", "東京都", "東京さくらトラム（都電荒川線）" ],
    [ "早稲田駅", "東京都", "東京さくらトラム（都電荒川線）" ],
    [ "羽生駅", "埼玉県", "秩父鉄道秩父本線" ],
    [ "西羽生駅", "埼玉県", "秩父鉄道秩父本線" ],
    [ "新郷駅", "埼玉県", "秩父鉄道秩父本線" ],
    [ "武州荒木駅", "埼玉県", "秩父鉄道秩父本線" ],
    [ "東行田駅", "埼玉県", "秩父鉄道秩父本線" ],
    [ "行田市駅", "埼玉県", "秩父鉄道秩父本線" ],
    [ "持田駅", "埼玉県", "秩父鉄道秩父本線" ],
    [ "ソシオ流通センター駅", "埼玉県", "秩父鉄道秩父本線" ],
    [ "熊谷駅", "埼玉県", "秩父鉄道秩父本線" ],
    [ "上熊谷駅", "埼玉県", "秩父鉄道秩父本線" ],
    [ "石原駅", "埼玉県", "秩父鉄道秩父本線" ],
    [ "ひろせ野鳥の森駅", "埼玉県", "秩父鉄道秩父本線" ],
    [ "大麻生駅", "埼玉県", "秩父鉄道秩父本線" ],
    [ "明戸駅", "埼玉県", "秩父鉄道秩父本線" ],
    [ "武川駅", "埼玉県", "秩父鉄道秩父本線" ],
    [ "永田駅", "埼玉県", "秩父鉄道秩父本線" ],
    [ "ふかや花園駅", "埼玉県", "秩父鉄道秩父本線" ],
    [ "小前田駅", "埼玉県", "秩父鉄道秩父本線" ],
    [ "桜沢駅", "埼玉県", "秩父鉄道秩父本線" ],
    [ "寄居駅", "埼玉県", "秩父鉄道秩父本線" ],
    [ "波久礼駅", "埼玉県", "秩父鉄道秩父本線" ],
    [ "樋口駅", "埼玉県", "秩父鉄道秩父本線" ],
    [ "野上駅", "埼玉県", "秩父鉄道秩父本線" ],
    [ "長瀞駅", "埼玉県", "秩父鉄道秩父本線" ],
    [ "上長瀞駅", "埼玉県", "秩父鉄道秩父本線" ],
    [ "親鼻駅", "埼玉県", "秩父鉄道秩父本線" ],
    [ "皆野駅", "埼玉県", "秩父鉄道秩父本線" ],
    [ "和銅黒谷駅", "埼玉県", "秩父鉄道秩父本線" ],
    [ "大野原駅", "埼玉県", "秩父鉄道秩父本線" ],
    [ "秩父駅", "埼玉県", "秩父鉄道秩父本線" ],
    [ "御花畑駅", "埼玉県", "秩父鉄道秩父本線" ],
    [ "影森駅", "埼玉県", "秩父鉄道秩父本線" ],
    [ "浦山口駅", "埼玉県", "秩父鉄道秩父本線" ],
    [ "武州中川駅", "埼玉県", "秩父鉄道秩父本線" ],
    [ "武州日野駅", "埼玉県", "秩父鉄道秩父本線" ],
    [ "白久駅", "埼玉県", "秩父鉄道秩父本線" ],
    [ "三峰口駅", "埼玉県", "秩父鉄道秩父本線" ],
    [ "赤羽岩淵駅", "東京都", "埼玉高速鉄道線" ],
    [ "川口元郷駅", "埼玉県", "埼玉高速鉄道線" ],
    [ "南鳩ヶ谷駅", "埼玉県", "埼玉高速鉄道線" ],
    [ "鳩ヶ谷駅", "埼玉県", "埼玉高速鉄道線" ],
    [ "新井宿駅", "埼玉県", "埼玉高速鉄道線" ],
    [ "戸塚安行駅", "埼玉県", "埼玉高速鉄道線" ],
    [ "東川口駅", "埼玉県", "埼玉高速鉄道線" ],
    [ "浦和美園駅", "埼玉県", "埼玉高速鉄道線" ],
    [ "大原駅", "千葉県", "いすみ線" ],
    [ "西大原駅", "千葉県", "いすみ線" ],
    [ "上総東駅", "千葉県", "いすみ線" ],
    [ "新田野駅", "千葉県", "いすみ線" ],
    [ "国吉駅", "千葉県", "いすみ線" ],
    [ "上総中川駅", "千葉県", "いすみ線" ],
    [ "城見ヶ丘駅", "千葉県", "いすみ線" ],
    [ "大多喜駅", "千葉県", "いすみ線" ],
    [ "小谷松駅", "千葉県", "いすみ線" ],
    [ "東総元駅", "千葉県", "いすみ線" ],
    [ "久我原駅", "千葉県", "いすみ線" ],
    [ "総元駅", "千葉県", "いすみ線" ],
    [ "西畑駅", "千葉県", "いすみ線" ],
    [ "上総中野駅", "千葉県", "いすみ線" ],
    [ "秋葉原駅", "東京都", "つくばエクスプレス" ],
    [ "新御徒町駅", "東京都", "つくばエクスプレス" ],
    [ "浅草駅", "東京都", "つくばエクスプレス" ],
    [ "南千住駅", "東京都", "つくばエクスプレス" ],
    [ "北千住駅", "東京都", "つくばエクスプレス" ],
    [ "青井駅", "東京都", "つくばエクスプレス" ],
    [ "六町駅", "東京都", "つくばエクスプレス" ],
    [ "八潮駅", "埼玉県", "つくばエクスプレス" ],
    [ "三郷中央駅", "埼玉県", "つくばエクスプレス" ],
    [ "南流山駅", "千葉県", "つくばエクスプレス" ],
    [ "流山セントラルパーク駅", "千葉県", "つくばエクスプレス" ],
    [ "流山おおたかの森駅", "千葉県", "つくばエクスプレス" ],
    [ "柏の葉キャンパス駅", "千葉県", "つくばエクスプレス" ],
    [ "柏たなか駅", "千葉県", "つくばエクスプレス" ],
    [ "守谷駅", "茨城県", "つくばエクスプレス" ],
    [ "みらい平駅", "茨城県", "つくばエクスプレス" ],
    [ "みどりの駅", "茨城県", "つくばエクスプレス" ],
    [ "万博記念公園駅", "茨城県", "つくばエクスプレス" ],
    [ "研究学園駅", "茨城県", "つくばエクスプレス" ],
    [ "つくば駅", "茨城県", "つくばエクスプレス" ],
    [ "横浜駅", "神奈川県", "みなとみらい線" ],
    [ "新高島駅", "神奈川県", "みなとみらい線" ],
    [ "みなとみらい駅", "神奈川県", "みなとみらい線" ],
    [ "馬車道駅", "神奈川県", "みなとみらい線" ],
    [ "日本大通り駅", "神奈川県", "みなとみらい線" ],
    [ "元町・中華街駅", "神奈川県", "みなとみらい線" ],
    [ "新橋駅", "東京都", "ゆりかもめ" ],
    [ "汐留駅", "東京都", "ゆりかもめ" ],
    [ "竹芝駅", "東京都", "ゆりかもめ" ],
    [ "日の出駅", "東京都", "ゆりかもめ" ],
    [ "芝浦ふ頭駅", "東京都", "ゆりかもめ" ],
    [ "お台場海浜公園駅", "東京都", "ゆりかもめ" ],
    [ "台場駅", "東京都", "ゆりかもめ" ],
    [ "東京国際クルーズターミナル駅", "東京都", "ゆりかもめ" ],
    [ "テレコムセンター駅", "東京都", "ゆりかもめ" ],
    [ "青海駅", "東京都", "ゆりかもめ" ],
    [ "東京ビッグサイト駅", "東京都", "ゆりかもめ" ],
    [ "有明駅", "東京都", "ゆりかもめ" ],
    [ "有明テニスの森駅", "東京都", "ゆりかもめ" ],
    [ "市場前駅", "東京都", "ゆりかもめ" ],
    [ "新豊洲駅", "東京都", "ゆりかもめ" ],
    [ "豊洲駅", "東京都", "ゆりかもめ" ],
    [ "桐生駅", "群馬県", "わたらせ渓谷線" ],
    [ "下新田駅", "群馬県", "わたらせ渓谷線" ],
    [ "相老駅", "群馬県", "わたらせ渓谷線" ],
    [ "運動公園駅", "群馬県", "わたらせ渓谷線" ],
    [ "大間々駅", "群馬県", "わたらせ渓谷線" ],
    [ "上神梅駅", "群馬県", "わたらせ渓谷線" ],
    [ "本宿駅", "群馬県", "わたらせ渓谷線" ],
    [ "水沼駅", "群馬県", "わたらせ渓谷線" ],
    [ "花輪駅", "群馬県", "わたらせ渓谷線" ],
    [ "中野駅", "群馬県", "わたらせ渓谷線" ],
    [ "小中駅", "群馬県", "わたらせ渓谷線" ],
    [ "神戸駅", "群馬県", "わたらせ渓谷線" ],
    [ "沢入駅", "群馬県", "わたらせ渓谷線" ],
    [ "原向駅", "栃木県", "わたらせ渓谷線" ],
    [ "通洞駅", "栃木県", "わたらせ渓谷線" ],
    [ "足尾駅", "栃木県", "わたらせ渓谷線" ],
    [ "間藤駅", "栃木県", "わたらせ渓谷線" ],
    [ "ユーカリが丘駅", "千葉県", "ユーカリが丘線" ],
    [ "地区センター駅", "千葉県", "ユーカリが丘線" ],
    [ "公園駅", "千葉県", "ユーカリが丘線" ],
    [ "女子大駅", "千葉県", "ユーカリが丘線" ],
    [ "中学校駅", "千葉県", "ユーカリが丘線" ],
    [ "井野駅", "千葉県", "ユーカリが丘線" ],
    [ "小田原駅", "神奈川県", "伊豆箱根鉄道大雄山線" ],
    [ "緑町駅", "神奈川県", "伊豆箱根鉄道大雄山線" ],
    [ "井細田駅", "神奈川県", "伊豆箱根鉄道大雄山線" ],
    [ "五百羅漢駅", "神奈川県", "伊豆箱根鉄道大雄山線" ],
    [ "穴部駅", "神奈川県", "伊豆箱根鉄道大雄山線" ],
    [ "飯田岡駅", "神奈川県", "伊豆箱根鉄道大雄山線" ],
    [ "相模沼田駅", "神奈川県", "伊豆箱根鉄道大雄山線" ],
    [ "岩原駅", "神奈川県", "伊豆箱根鉄道大雄山線" ],
    [ "塚原駅", "神奈川県", "伊豆箱根鉄道大雄山線" ],
    [ "和田河原駅", "神奈川県", "伊豆箱根鉄道大雄山線" ],
    [ "富士フイルム前駅", "神奈川県", "伊豆箱根鉄道大雄山線" ],
    [ "大雄山駅", "神奈川県", "伊豆箱根鉄道大雄山線" ],
    [ "阿字ヶ浦駅", "茨城県", "ひたちなか海浜鉄道湊線" ],
    [ "磯崎駅", "茨城県", "ひたちなか海浜鉄道湊線" ],
    [ "美乃浜学園駅", "茨城県", "ひたちなか海浜鉄道湊線" ],
    [ "平磯駅", "茨城県", "ひたちなか海浜鉄道湊線" ],
    [ "殿山駅", "茨城県", "ひたちなか海浜鉄道湊線" ],
    [ "那珂湊駅", "茨城県", "ひたちなか海浜鉄道湊線" ],
    [ "高田の鉄橋駅", "茨城県", "ひたちなか海浜鉄道湊線" ],
    [ "中根駅", "茨城県", "ひたちなか海浜鉄道湊線" ],
    [ "金上駅", "茨城県", "ひたちなか海浜鉄道湊線" ],
    [ "工機前駅", "茨城県", "ひたちなか海浜鉄道湊線" ],
    [ "勝田駅", "茨城県", "ひたちなか海浜鉄道湊線" ],
    [ "あざみ野駅", "神奈川県", "ブルーライン" ],
    [ "中川駅", "神奈川県", "ブルーライン" ],
    [ "センター北駅", "神奈川県", "ブルーライン" ],
    [ "センター南駅", "神奈川県", "ブルーライン" ],
    [ "仲町台駅", "神奈川県", "ブルーライン" ],
    [ "新羽駅", "神奈川県", "ブルーライン" ],
    [ "北新横浜駅", "神奈川県", "ブルーライン" ],
    [ "新横浜駅", "神奈川県", "ブルーライン" ],
    [ "岸根公園駅", "神奈川県", "ブルーライン" ],
    [ "片倉町駅", "神奈川県", "ブルーライン" ],
    [ "三ツ沢上町駅", "神奈川県", "ブルーライン" ],
    [ "三ツ沢下町駅", "神奈川県", "ブルーライン" ],
    [ "横浜駅", "神奈川県", "ブルーライン" ],
    [ "高島町駅", "神奈川県", "ブルーライン" ],
    [ "桜木町駅", "神奈川県", "ブルーライン" ],
    [ "関内駅", "神奈川県", "ブルーライン" ],
    [ "伊勢佐木長者町駅", "神奈川県", "ブルーライン" ],
    [ "阪東橋駅", "神奈川県", "ブルーライン" ],
    [ "吉野町駅", "神奈川県", "ブルーライン" ],
    [ "蒔田駅", "神奈川県", "ブルーライン" ],
    [ "弘明寺駅", "神奈川県", "ブルーライン" ],
    [ "上大岡駅", "神奈川県", "ブルーライン" ],
    [ "港南中央駅", "神奈川県", "ブルーライン" ],
    [ "上永谷駅", "神奈川県", "ブルーライン" ],
    [ "下永谷駅", "神奈川県", "ブルーライン" ],
    [ "舞岡駅", "神奈川県", "ブルーライン" ],
    [ "戸塚駅", "神奈川県", "ブルーライン" ],
    [ "踊場駅", "神奈川県", "ブルーライン" ],
    [ "中田駅", "神奈川県", "ブルーライン" ],
    [ "立場駅", "神奈川県", "ブルーライン" ],
    [ "下飯田駅", "神奈川県", "ブルーライン" ],
    [ "湘南台駅", "神奈川県", "ブルーライン" ],
    [ "新杉田駅", "神奈川県", "金沢シーサイドライン" ],
    [ "南部市場駅", "神奈川県", "金沢シーサイドライン" ],
    [ "鳥浜駅", "神奈川県", "金沢シーサイドライン" ],
    [ "並木北駅", "神奈川県", "金沢シーサイドライン" ],
    [ "並木中央駅", "神奈川県", "金沢シーサイドライン" ],
    [ "幸浦駅", "神奈川県", "金沢シーサイドライン" ],
    [ "産業振興センター駅", "神奈川県", "金沢シーサイドライン" ],
    [ "福浦駅", "神奈川県", "金沢シーサイドライン" ],
    [ "市大医学部駅", "神奈川県", "金沢シーサイドライン" ],
    [ "八景島駅", "神奈川県", "金沢シーサイドライン" ],
    [ "海の公園柴口駅", "神奈川県", "金沢シーサイドライン" ],
    [ "海の公園南口駅", "神奈川県", "金沢シーサイドライン" ],
    [ "野島公園駅", "神奈川県", "金沢シーサイドライン" ],
    [ "金沢八景駅", "神奈川県", "金沢シーサイドライン" ],
    [ "取手駅", "茨城県", "関東鉄道常総線" ],
    [ "西取手駅", "茨城県", "関東鉄道常総線" ],
    [ "寺原駅", "茨城県", "関東鉄道常総線" ],
    [ "新取手駅", "茨城県", "関東鉄道常総線" ],
    [ "ゆめみ野駅", "茨城県", "関東鉄道常総線" ],
    [ "稲戸井駅", "茨城県", "関東鉄道常総線" ],
    [ "戸頭駅", "茨城県", "関東鉄道常総線" ],
    [ "南守谷駅", "茨城県", "関東鉄道常総線" ],
    [ "守谷駅", "茨城県", "関東鉄道常総線" ],
    [ "新守谷駅", "茨城県", "関東鉄道常総線" ],
    [ "小絹駅", "茨城県", "関東鉄道常総線" ],
    [ "水海道駅", "茨城県", "関東鉄道常総線" ],
    [ "北水海道駅", "茨城県", "関東鉄道常総線" ],
    [ "中妻駅", "茨城県", "関東鉄道常総線" ],
    [ "三妻駅", "茨城県", "関東鉄道常総線" ],
    [ "南石下駅", "茨城県", "関東鉄道常総線" ],
    [ "石下駅", "茨城県", "関東鉄道常総線" ],
    [ "玉村駅", "茨城県", "関東鉄道常総線" ],
    [ "宗道駅", "茨城県", "関東鉄道常総線" ],
    [ "下妻駅", "茨城県", "関東鉄道常総線" ],
    [ "大宝駅", "茨城県", "関東鉄道常総線" ],
    [ "騰波ノ江駅", "茨城県", "関東鉄道常総線" ],
    [ "黒子駅", "茨城県", "関東鉄道常総線" ],
    [ "大田郷駅", "茨城県", "関東鉄道常総線" ],
    [ "下館駅", "茨城県", "関東鉄道常総線" ],
    [ "佐貫駅", "茨城県", "関東鉄道竜ヶ崎線" ],
    [ "入地駅", "茨城県", "関東鉄道竜ヶ崎線" ],
    [ "竜ヶ崎駅", "茨城県", "関東鉄道竜ヶ崎線" ],
    [ "藤沢駅", "神奈川県", "江ノ島電鉄線" ],
    [ "石上駅", "神奈川県", "江ノ島電鉄線" ],
    [ "柳小路駅", "神奈川県", "江ノ島電鉄線" ],
    [ "鵠沼駅", "神奈川県", "江ノ島電鉄線" ],
    [ "湘南海岸公園駅", "神奈川県", "江ノ島電鉄線" ],
    [ "江ノ島駅", "神奈川県", "江ノ島電鉄線" ],
    [ "腰越駅", "神奈川県", "江ノ島電鉄線" ],
    [ "鎌倉高校前駅", "神奈川県", "江ノ島電鉄線" ],
    [ "七里ヶ浜駅", "神奈川県", "江ノ島電鉄線" ],
    [ "稲村ヶ崎駅", "神奈川県", "江ノ島電鉄線" ],
    [ "極楽寺駅", "神奈川県", "江ノ島電鉄線" ],
    [ "長谷駅", "神奈川県", "江ノ島電鉄線" ],
    [ "由比ヶ浜駅", "神奈川県", "江ノ島電鉄線" ],
    [ "和田塚駅", "神奈川県", "江ノ島電鉄線" ],
    [ "鎌倉駅", "神奈川県", "江ノ島電鉄線" ],
    [ "大宮駅", "埼玉県", "ニューシャトル" ],
    [ "鉄道博物館駅", "埼玉県", "ニューシャトル" ],
    [ "加茂宮駅", "埼玉県", "ニューシャトル" ],
    [ "東宮原駅", "埼玉県", "ニューシャトル" ],
    [ "今羽駅", "埼玉県", "ニューシャトル" ],
    [ "吉野原駅", "埼玉県", "ニューシャトル" ],
    [ "原市駅", "埼玉県", "ニューシャトル" ],
    [ "沼南駅", "埼玉県", "ニューシャトル" ],
    [ "丸山駅", "埼玉県", "ニューシャトル" ],
    [ "志久駅", "埼玉県", "ニューシャトル" ],
    [ "伊奈中央駅", "埼玉県", "ニューシャトル" ],
    [ "羽貫駅", "埼玉県", "ニューシャトル" ],
    [ "内宿駅", "埼玉県", "ニューシャトル" ],
    [ "鉾田駅", "茨城県", "鹿島鉄道線" ],
    [ "坂戸駅", "茨城県", "鹿島鉄道線" ],
    [ "巴川駅", "茨城県", "鹿島鉄道線" ],
    [ "借宿前駅", "茨城県", "鹿島鉄道線" ],
    [ "榎本駅", "茨城県", "鹿島鉄道線" ],
    [ "玉造町駅", "茨城県", "鹿島鉄道線" ],
    [ "浜駅", "茨城県", "鹿島鉄道線" ],
    [ "八木蒔駅", "茨城県", "鹿島鉄道線" ],
    [ "桃浦駅", "茨城県", "鹿島鉄道線" ],
    [ "小川高校下駅", "茨城県", "鹿島鉄道線" ],
    [ "常陸小川駅", "茨城県", "鹿島鉄道線" ],
    [ "四箇村駅", "茨城県", "鹿島鉄道線" ],
    [ "新高浜駅", "茨城県", "鹿島鉄道線" ],
    [ "玉里駅", "茨城県", "鹿島鉄道線" ],
    [ "東田中駅", "茨城県", "鹿島鉄道線" ],
    [ "石岡南台駅", "茨城県", "鹿島鉄道線" ],
    [ "石岡駅", "茨城県", "鹿島鉄道線" ],
    [ "水戸駅", "茨城県", "鹿島臨海鉄道大洗鹿島線" ],
    [ "東水戸駅", "茨城県", "鹿島臨海鉄道大洗鹿島線" ],
    [ "常澄駅", "茨城県", "鹿島臨海鉄道大洗鹿島線" ],
    [ "大洗駅", "茨城県", "鹿島臨海鉄道大洗鹿島線" ],
    [ "涸沼駅", "茨城県", "鹿島臨海鉄道大洗鹿島線" ],
    [ "鹿島旭駅", "茨城県", "鹿島臨海鉄道大洗鹿島線" ],
    [ "徳宿駅", "茨城県", "鹿島臨海鉄道大洗鹿島線" ],
    [ "新鉾田駅", "茨城県", "鹿島臨海鉄道大洗鹿島線" ],
    [ "北浦湖畔駅", "茨城県", "鹿島臨海鉄道大洗鹿島線" ],
    [ "大洋駅", "茨城県", "鹿島臨海鉄道大洗鹿島線" ],
    [ "鹿島灘駅", "茨城県", "鹿島臨海鉄道大洗鹿島線" ],
    [ "鹿島大野駅", "茨城県", "鹿島臨海鉄道大洗鹿島線" ],
    [ "長者ヶ浜潮騒はまなす公園前駅", "茨城県", "鹿島臨海鉄道大洗鹿島線" ],
    [ "荒野台駅", "茨城県", "鹿島臨海鉄道大洗鹿島線" ],
    [ "鹿島サッカースタジアム（臨）駅", "茨城県", "鹿島臨海鉄道大洗鹿島線" ],
    [ "鹿島神宮駅", "茨城県", "鹿島臨海鉄道大洗鹿島線" ],
    [ "東成田駅", "千葉県", "芝山鉄道線" ],
    [ "芝山千代田駅", "千葉県", "芝山鉄道線" ],
    [ "五井駅", "千葉県", "小湊鉄道線" ],
    [ "上総村上駅", "千葉県", "小湊鉄道線" ],
    [ "海士有木駅", "千葉県", "小湊鉄道線" ],
    [ "上総三又駅", "千葉県", "小湊鉄道線" ],
    [ "上総山田駅", "千葉県", "小湊鉄道線" ],
    [ "光風台駅", "千葉県", "小湊鉄道線" ],
    [ "馬立駅", "千葉県", "小湊鉄道線" ],
    [ "上総牛久駅", "千葉県", "小湊鉄道線" ],
    [ "上総川間駅", "千葉県", "小湊鉄道線" ],
    [ "上総鶴舞駅", "千葉県", "小湊鉄道線" ],
    [ "上総久保駅", "千葉県", "小湊鉄道線" ],
    [ "高滝駅", "千葉県", "小湊鉄道線" ],
    [ "里見駅", "千葉県", "小湊鉄道線" ],
    [ "飯給駅", "千葉県", "小湊鉄道線" ],
    [ "月崎駅", "千葉県", "小湊鉄道線" ],
    [ "上総大久保駅", "千葉県", "小湊鉄道線" ],
    [ "養老渓谷駅", "千葉県", "小湊鉄道線" ],
    [ "上総中野駅", "千葉県", "小湊鉄道線" ],
    [ "大船駅", "神奈川県", "湘南モノレール" ],
    [ "富士見町駅", "神奈川県", "湘南モノレール" ],
    [ "湘南町屋駅", "神奈川県", "湘南モノレール" ],
    [ "湘南深沢駅", "神奈川県", "湘南モノレール" ],
    [ "西鎌倉駅", "神奈川県", "湘南モノレール" ],
    [ "片瀬山駅", "神奈川県", "湘南モノレール" ],
    [ "目白山下駅", "神奈川県", "湘南モノレール" ],
    [ "湘南江の島駅", "神奈川県", "湘南モノレール" ],
    [ "高崎駅", "群馬県", "上信電鉄" ],
    [ "南高崎駅", "群馬県", "上信電鉄" ],
    [ "佐野のわたし駅", "群馬県", "上信電鉄" ],
    [ "根小屋駅", "群馬県", "上信電鉄" ],
    [ "高崎商科大学前駅", "群馬県", "上信電鉄" ],
    [ "山名駅", "群馬県", "上信電鉄" ],
    [ "西山名駅", "群馬県", "上信電鉄" ],
    [ "馬庭駅", "群馬県", "上信電鉄" ],
    [ "吉井駅", "群馬県", "上信電鉄" ],
    [ "西吉井駅", "群馬県", "上信電鉄" ],
    [ "上州新屋駅", "群馬県", "上信電鉄" ],
    [ "上州福島駅", "群馬県", "上信電鉄" ],
    [ "東富岡駅", "群馬県", "上信電鉄" ],
    [ "上州富岡駅", "群馬県", "上信電鉄" ],
    [ "西富岡駅", "群馬県", "上信電鉄" ],
    [ "上州七日市駅", "群馬県", "上信電鉄" ],
    [ "上州一ノ宮駅", "群馬県", "上信電鉄" ],
    [ "神農原駅", "群馬県", "上信電鉄" ],
    [ "南蛇井駅", "群馬県", "上信電鉄" ],
    [ "千平駅", "群馬県", "上信電鉄" ],
    [ "下仁田駅", "群馬県", "上信電鉄" ],
    [ "中央前橋駅", "群馬県", "上毛電気鉄道上毛線" ],
    [ "城東駅", "群馬県", "上毛電気鉄道上毛線" ],
    [ "三俣駅", "群馬県", "上毛電気鉄道上毛線" ],
    [ "片貝駅", "群馬県", "上毛電気鉄道上毛線" ],
    [ "上泉駅", "群馬県", "上毛電気鉄道上毛線" ],
    [ "赤坂駅", "群馬県", "上毛電気鉄道上毛線" ],
    [ "心臓血管センター駅", "群馬県", "上毛電気鉄道上毛線" ],
    [ "江木駅", "群馬県", "上毛電気鉄道上毛線" ],
    [ "大胡駅", "群馬県", "上毛電気鉄道上毛線" ],
    [ "樋越駅", "群馬県", "上毛電気鉄道上毛線" ],
    [ "北原駅", "群馬県", "上毛電気鉄道上毛線" ],
    [ "新屋駅", "群馬県", "上毛電気鉄道上毛線" ],
    [ "粕川駅", "群馬県", "上毛電気鉄道上毛線" ],
    [ "膳駅", "群馬県", "上毛電気鉄道上毛線" ],
    [ "新里駅", "群馬県", "上毛電気鉄道上毛線" ],
    [ "新川駅", "群馬県", "上毛電気鉄道上毛線" ],
    [ "東新川駅", "群馬県", "上毛電気鉄道上毛線" ],
    [ "赤城駅", "群馬県", "上毛電気鉄道上毛線" ],
    [ "桐生球場前駅", "群馬県", "上毛電気鉄道上毛線" ],
    [ "天王宿駅", "群馬県", "上毛電気鉄道上毛線" ],
    [ "富士山下駅", "群馬県", "上毛電気鉄道上毛線" ],
    [ "丸山下駅", "群馬県", "上毛電気鉄道上毛線" ],
    [ "西桐生駅", "群馬県", "上毛電気鉄道上毛線" ],
    [ "京成津田沼駅", "千葉県", "新京成線" ],
    [ "新津田沼駅", "千葉県", "新京成線" ],
    [ "前原駅", "千葉県", "新京成線" ],
    [ "薬園台駅", "千葉県", "新京成線" ],
    [ "習志野駅", "千葉県", "新京成線" ],
    [ "北習志野駅", "千葉県", "新京成線" ],
    [ "高根木戸駅", "千葉県", "新京成線" ],
    [ "高根公団駅", "千葉県", "新京成線" ],
    [ "滝不動駅", "千葉県", "新京成線" ],
    [ "三咲駅", "千葉県", "新京成線" ],
    [ "二和向台駅", "千葉県", "新京成線" ],
    [ "鎌ヶ谷大仏駅", "千葉県", "新京成線" ],
    [ "初富駅", "千葉県", "新京成線" ],
    [ "新鎌ヶ谷駅", "千葉県", "新京成線" ],
    [ "北初富駅", "千葉県", "新京成線" ],
    [ "くぬぎ山駅", "千葉県", "新京成線" ],
    [ "元山駅", "千葉県", "新京成線" ],
    [ "五香駅", "千葉県", "新京成線" ],
    [ "常盤平駅", "千葉県", "新京成線" ],
    [ "八柱駅", "千葉県", "新京成線" ],
    [ "みのり台駅", "千葉県", "新京成線" ],
    [ "松戸新田駅", "千葉県", "新京成線" ],
    [ "上本郷駅", "千葉県", "新京成線" ],
    [ "松戸駅", "千葉県", "新京成線" ],
    [ "下館駅", "茨城県", "真岡鐵道真岡線" ],
    [ "下館二高前駅", "茨城県", "真岡鐵道真岡線" ],
    [ "折本駅", "茨城県", "真岡鐵道真岡線" ],
    [ "ひぐち駅", "茨城県", "真岡鐵道真岡線" ],
    [ "久下田駅", "栃木県", "真岡鐵道真岡線" ],
    [ "寺内駅", "栃木県", "真岡鐵道真岡線" ],
    [ "真岡駅", "栃木県", "真岡鐵道真岡線" ],
    [ "北真岡駅", "栃木県", "真岡鐵道真岡線" ],
    [ "西田井駅", "栃木県", "真岡鐵道真岡線" ],
    [ "北山駅", "栃木県", "真岡鐵道真岡線" ],
    [ "益子駅", "栃木県", "真岡鐵道真岡線" ],
    [ "七井駅", "栃木県", "真岡鐵道真岡線" ],
    [ "多田羅駅", "栃木県", "真岡鐵道真岡線" ],
    [ "市塙駅", "栃木県", "真岡鐵道真岡線" ],
    [ "笹原田駅", "栃木県", "真岡鐵道真岡線" ],
    [ "天矢場駅", "栃木県", "真岡鐵道真岡線" ],
    [ "茂木駅", "栃木県", "真岡鐵道真岡線" ],
    [ "千葉みなと駅", "千葉県", "千葉都市モノレール１号線" ],
    [ "市役所前駅", "千葉県", "千葉都市モノレール１号線" ],
    [ "千葉駅", "千葉県", "千葉都市モノレール１号線" ],
    [ "栄町駅", "千葉県", "千葉都市モノレール１号線" ],
    [ "葭川公園駅", "千葉県", "千葉都市モノレール１号線" ],
    [ "県庁前駅", "千葉県", "千葉都市モノレール１号線" ],
    [ "千葉みなと駅", "千葉県", "千葉都市モノレール２号線" ],
    [ "市役所前駅", "千葉県", "千葉都市モノレール２号線" ],
    [ "千葉駅", "千葉県", "千葉都市モノレール２号線" ],
    [ "千葉公園駅", "千葉県", "千葉都市モノレール２号線" ],
    [ "作草部駅", "千葉県", "千葉都市モノレール２号線" ],
    [ "天台駅", "千葉県", "千葉都市モノレール２号線" ],
    [ "穴川駅", "千葉県", "千葉都市モノレール２号線" ],
    [ "スポーツセンター駅", "千葉県", "千葉都市モノレール２号線" ],
    [ "動物公園駅", "千葉県", "千葉都市モノレール２号線" ],
    [ "みつわ台駅", "千葉県", "千葉都市モノレール２号線" ],
    [ "都賀駅", "千葉県", "千葉都市モノレール２号線" ],
    [ "桜木駅", "千葉県", "千葉都市モノレール２号線" ],
    [ "小倉台駅", "千葉県", "千葉都市モノレール２号線" ],
    [ "千城台北駅", "千葉県", "千葉都市モノレール２号線" ],
    [ "千城台駅", "千葉県", "千葉都市モノレール２号線" ],
    [ "馬橋駅", "千葉県", "流鉄流山線" ],
    [ "幸谷駅", "千葉県", "流鉄流山線" ],
    [ "小金城趾駅", "千葉県", "流鉄流山線" ],
    [ "鰭ヶ崎駅", "千葉県", "流鉄流山線" ],
    [ "平和台駅", "千葉県", "流鉄流山線" ],
    [ "流山駅", "千葉県", "流鉄流山線" ],
    [ "多摩センター駅", "東京都", "多摩モノレール" ],
    [ "松が谷駅", "東京都", "多摩モノレール" ],
    [ "大塚・帝京大学駅", "東京都", "多摩モノレール" ],
    [ "中央大学・明星大学駅", "東京都", "多摩モノレール" ],
    [ "多摩動物公園駅", "東京都", "多摩モノレール" ],
    [ "程久保駅", "東京都", "多摩モノレール" ],
    [ "高幡不動駅", "東京都", "多摩モノレール" ],
    [ "万願寺駅", "東京都", "多摩モノレール" ],
    [ "甲州街道駅", "東京都", "多摩モノレール" ],
    [ "柴崎体育館駅", "東京都", "多摩モノレール" ],
    [ "立川南駅", "東京都", "多摩モノレール" ],
    [ "立川北駅", "東京都", "多摩モノレール" ],
    [ "高松駅", "東京都", "多摩モノレール" ],
    [ "立飛駅", "東京都", "多摩モノレール" ],
    [ "泉体育館駅", "東京都", "多摩モノレール" ],
    [ "砂川七番駅", "東京都", "多摩モノレール" ],
    [ "玉川上水駅", "東京都", "多摩モノレール" ],
    [ "桜街道駅", "東京都", "多摩モノレール" ],
    [ "上北台駅", "東京都", "多摩モノレール" ],
    [ "銚子駅", "千葉県", "銚子電鉄線" ],
    [ "仲ノ町駅", "千葉県", "銚子電鉄線" ],
    [ "観音駅", "千葉県", "銚子電鉄線" ],
    [ "本銚子駅", "千葉県", "銚子電鉄線" ],
    [ "笠上黒生駅", "千葉県", "銚子電鉄線" ],
    [ "西海鹿島駅", "千葉県", "銚子電鉄線" ],
    [ "海鹿島駅", "千葉県", "銚子電鉄線" ],
    [ "君ヶ浜駅", "千葉県", "銚子電鉄線" ],
    [ "犬吠駅", "千葉県", "銚子電鉄線" ],
    [ "外川駅", "千葉県", "銚子電鉄線" ],
    [ "浜松町駅", "東京都", "東京モノレール" ],
    [ "天王洲アイル駅", "東京都", "東京モノレール" ],
    [ "大井競馬場前駅", "東京都", "東京モノレール" ],
    [ "流通センター駅", "東京都", "東京モノレール" ],
    [ "昭和島駅", "東京都", "東京モノレール" ],
    [ "整備場駅", "東京都", "東京モノレール" ],
    [ "天空橋駅", "東京都", "東京モノレール" ],
    [ "羽田空港第3ターミナル駅", "東京都", "東京モノレール" ],
    [ "新整備場駅", "東京都", "東京モノレール" ],
    [ "羽田空港第1ターミナル駅", "東京都", "東京モノレール" ],
    [ "羽田空港第2ターミナル駅", "東京都", "東京モノレール" ],
    [ "新木場駅", "東京都", "りんかい線" ],
    [ "東雲駅", "東京都", "りんかい線" ],
    [ "国際展示場駅", "東京都", "りんかい線" ],
    [ "東京テレポート駅", "東京都", "りんかい線" ],
    [ "天王洲アイル駅", "東京都", "りんかい線" ],
    [ "品川シーサイド駅", "東京都", "りんかい線" ],
    [ "大井町駅", "東京都", "りんかい線" ],
    [ "大崎駅", "東京都", "りんかい線" ],
    [ "西船橋駅", "千葉県", "東葉高速線" ],
    [ "東海神駅", "千葉県", "東葉高速線" ],
    [ "飯山満駅", "千葉県", "東葉高速線" ],
    [ "北習志野駅", "千葉県", "東葉高速線" ],
    [ "船橋日大前駅", "千葉県", "東葉高速線" ],
    [ "八千代緑が丘駅", "千葉県", "東葉高速線" ],
    [ "八千代中央駅", "千葉県", "東葉高速線" ],
    [ "村上駅", "千葉県", "東葉高速線" ],
    [ "東葉勝田台駅", "千葉県", "東葉高速線" ],
    [ "小田原駅", "神奈川県", "箱根登山鉄道鉄道線" ],
    [ "箱根板橋駅", "神奈川県", "箱根登山鉄道鉄道線" ],
    [ "風祭駅", "神奈川県", "箱根登山鉄道鉄道線" ],
    [ "入生田駅", "神奈川県", "箱根登山鉄道鉄道線" ],
    [ "箱根湯本駅", "神奈川県", "箱根登山鉄道鉄道線" ],
    [ "塔ノ沢駅", "神奈川県", "箱根登山鉄道鉄道線" ],
    [ "大平台駅", "神奈川県", "箱根登山鉄道鉄道線" ],
    [ "宮ノ下駅", "神奈川県", "箱根登山鉄道鉄道線" ],
    [ "小涌谷駅", "神奈川県", "箱根登山鉄道鉄道線" ],
    [ "彫刻の森駅", "神奈川県", "箱根登山鉄道鉄道線" ],
    [ "強羅駅", "神奈川県", "箱根登山鉄道鉄道線" ],
    [ "京成高砂駅", "東京都", "北総鉄道北総線" ],
    [ "新柴又駅", "東京都", "北総鉄道北総線" ],
    [ "矢切駅", "千葉県", "北総鉄道北総線" ],
    [ "北国分駅", "千葉県", "北総鉄道北総線" ],
    [ "秋山駅", "千葉県", "北総鉄道北総線" ],
    [ "東松戸駅", "千葉県", "北総鉄道北総線" ],
    [ "松飛台駅", "千葉県", "北総鉄道北総線" ],
    [ "大町駅", "千葉県", "北総鉄道北総線" ],
    [ "新鎌ヶ谷駅", "千葉県", "北総鉄道北総線" ],
    [ "西白井駅", "千葉県", "北総鉄道北総線" ],
    [ "白井駅", "千葉県", "北総鉄道北総線" ],
    [ "小室駅", "千葉県", "北総鉄道北総線" ],
    [ "千葉ニュータウン中央駅", "千葉県", "北総鉄道北総線" ],
    [ "印西牧の原駅", "千葉県", "北総鉄道北総線" ],
    [ "印旛日本医大駅", "千葉県", "北総鉄道北総線" ],
    [ "新藤原駅", "栃木県", "ほっとスパ・ライン" ],
    [ "龍王峡駅", "栃木県", "ほっとスパ・ライン" ],
    [ "川治温泉駅", "栃木県", "ほっとスパ・ライン" ],
    [ "川治湯元駅", "栃木県", "ほっとスパ・ライン" ],
    [ "湯西川温泉駅", "栃木県", "ほっとスパ・ライン" ],
    [ "中三依温泉駅", "栃木県", "ほっとスパ・ライン" ],
    [ "上三依塩原温泉口駅", "栃木県", "ほっとスパ・ライン" ],
    [ "男鹿高原駅", "栃木県", "ほっとスパ・ライン" ],
    [ "会津高原尾瀬口駅", "福島県", "ほっとスパ・ライン" ],
    [ "日暮里駅", "東京都", "日暮里・舎人ライナー" ],
    [ "西日暮里駅", "東京都", "日暮里・舎人ライナー" ],
    [ "赤土小学校前駅", "東京都", "日暮里・舎人ライナー" ],
    [ "熊野前駅", "東京都", "日暮里・舎人ライナー" ],
    [ "足立小台駅", "東京都", "日暮里・舎人ライナー" ],
    [ "扇大橋駅", "東京都", "日暮里・舎人ライナー" ],
    [ "高野駅", "東京都", "日暮里・舎人ライナー" ],
    [ "江北駅", "東京都", "日暮里・舎人ライナー" ],
    [ "西新井大師西駅", "東京都", "日暮里・舎人ライナー" ],
    [ "谷在家駅", "東京都", "日暮里・舎人ライナー" ],
    [ "舎人公園駅", "東京都", "日暮里・舎人ライナー" ],
    [ "舎人駅", "東京都", "日暮里・舎人ライナー" ],
    [ "見沼代親水公園駅", "東京都", "日暮里・舎人ライナー" ],
    [ "中山駅", "神奈川県", "グリーンライン" ],
    [ "川和町駅", "神奈川県", "グリーンライン" ],
    [ "都筑ふれあいの丘駅", "神奈川県", "グリーンライン" ],
    [ "センター南駅", "神奈川県", "グリーンライン" ],
    [ "センター北駅", "神奈川県", "グリーンライン" ],
    [ "北山田駅", "神奈川県", "グリーンライン" ],
    [ "東山田駅", "神奈川県", "グリーンライン" ],
    [ "高田駅", "神奈川県", "グリーンライン" ],
    [ "日吉本町駅", "神奈川県", "グリーンライン" ],
    [ "日吉駅", "神奈川県", "グリーンライン" ],
    [ "強羅駅", "神奈川県", "箱根登山ケーブルカー" ],
    [ "公園下駅", "神奈川県", "箱根登山ケーブルカー" ],
    [ "公園上駅", "神奈川県", "箱根登山ケーブルカー" ],
    [ "中強羅駅", "神奈川県", "箱根登山ケーブルカー" ],
    [ "上強羅駅", "神奈川県", "箱根登山ケーブルカー" ],
    [ "早雲山駅", "神奈川県", "箱根登山ケーブルカー" ],
    [ "大月駅", "山梨県", "富士急行線" ],
    [ "上大月駅", "山梨県", "富士急行線" ],
    [ "田野倉駅", "山梨県", "富士急行線" ],
    [ "禾生駅", "山梨県", "富士急行線" ],
    [ "赤坂駅", "山梨県", "富士急行線" ],
    [ "都留市駅", "山梨県", "富士急行線" ],
    [ "谷村町駅", "山梨県", "富士急行線" ],
    [ "都留文科大学前駅", "山梨県", "富士急行線" ],
    [ "十日市場駅", "山梨県", "富士急行線" ],
    [ "東桂駅", "山梨県", "富士急行線" ],
    [ "三つ峠駅", "山梨県", "富士急行線" ],
    [ "寿駅", "山梨県", "富士急行線" ],
    [ "葭池温泉前駅", "山梨県", "富士急行線" ],
    [ "下吉田駅", "山梨県", "富士急行線" ],
    [ "月江寺駅", "山梨県", "富士急行線" ],
    [ "富士山駅", "山梨県", "富士急行線" ],
    [ "富士急ハイランド駅", "山梨県", "富士急行線" ],
    [ "河口湖駅", "山梨県", "富士急行線" ],
    [ "六日町駅", "新潟県", "北越急行ほくほく線" ],
    [ "魚沼丘陵駅", "新潟県", "北越急行ほくほく線" ],
    [ "美佐島駅", "新潟県", "北越急行ほくほく線" ],
    [ "しんざ駅", "新潟県", "北越急行ほくほく線" ],
    [ "十日町駅", "新潟県", "北越急行ほくほく線" ],
    [ "まつだい駅", "新潟県", "北越急行ほくほく線" ],
    [ "ほくほく大島駅", "新潟県", "北越急行ほくほく線" ],
    [ "虫川大杉駅", "新潟県", "北越急行ほくほく線" ],
    [ "うらがわら駅", "新潟県", "北越急行ほくほく線" ],
    [ "大池いこいの森駅", "新潟県", "北越急行ほくほく線" ],
    [ "くびき駅", "新潟県", "北越急行ほくほく線" ],
    [ "犀潟駅", "新潟県", "北越急行ほくほく線" ],
    [ "軽井沢駅", "長野県", "しなの鉄道線" ],
    [ "中軽井沢駅", "長野県", "しなの鉄道線" ],
    [ "信濃追分駅", "長野県", "しなの鉄道線" ],
    [ "御代田駅", "長野県", "しなの鉄道線" ],
    [ "平原駅", "長野県", "しなの鉄道線" ],
    [ "小諸駅", "長野県", "しなの鉄道線" ],
    [ "滋野駅", "長野県", "しなの鉄道線" ],
    [ "田中駅", "長野県", "しなの鉄道線" ],
    [ "大屋駅", "長野県", "しなの鉄道線" ],
    [ "信濃国分寺駅", "長野県", "しなの鉄道線" ],
    [ "上田駅", "長野県", "しなの鉄道線" ],
    [ "西上田駅", "長野県", "しなの鉄道線" ],
    [ "テクノさかき駅", "長野県", "しなの鉄道線" ],
    [ "坂城駅", "長野県", "しなの鉄道線" ],
    [ "戸倉駅", "長野県", "しなの鉄道線" ],
    [ "千曲駅", "長野県", "しなの鉄道線" ],
    [ "屋代駅", "長野県", "しなの鉄道線" ],
    [ "屋代高校前駅", "長野県", "しなの鉄道線" ],
    [ "篠ノ井駅", "長野県", "しなの鉄道線" ],
    [ "今井駅", "長野県", "しなの鉄道線" ],
    [ "川中島駅", "長野県", "しなの鉄道線" ],
    [ "安茂里駅", "長野県", "しなの鉄道線" ],
    [ "長野駅", "長野県", "しなの鉄道線" ],
    [ "上田駅", "長野県", "上田電鉄別所線" ],
    [ "城下駅", "長野県", "上田電鉄別所線" ],
    [ "三好町駅", "長野県", "上田電鉄別所線" ],
    [ "赤坂上駅", "長野県", "上田電鉄別所線" ],
    [ "上田原駅", "長野県", "上田電鉄別所線" ],
    [ "寺下駅", "長野県", "上田電鉄別所線" ],
    [ "神畑駅", "長野県", "上田電鉄別所線" ],
    [ "大学前駅", "長野県", "上田電鉄別所線" ],
    [ "下之郷駅", "長野県", "上田電鉄別所線" ],
    [ "中塩田駅", "長野県", "上田電鉄別所線" ],
    [ "塩田町駅", "長野県", "上田電鉄別所線" ],
    [ "中野駅", "長野県", "上田電鉄別所線" ],
    [ "舞田駅", "長野県", "上田電鉄別所線" ],
    [ "八木沢駅", "長野県", "上田電鉄別所線" ],
    [ "別所温泉駅", "長野県", "上田電鉄別所線" ],
    [ "長野駅", "長野県", "長野電鉄長野線" ],
    [ "市役所前駅", "長野県", "長野電鉄長野線" ],
    [ "権堂駅", "長野県", "長野電鉄長野線" ],
    [ "善光寺下駅", "長野県", "長野電鉄長野線" ],
    [ "本郷駅", "長野県", "長野電鉄長野線" ],
    [ "桐原駅", "長野県", "長野電鉄長野線" ],
    [ "信濃吉田駅", "長野県", "長野電鉄長野線" ],
    [ "朝陽駅", "長野県", "長野電鉄長野線" ],
    [ "附属中学前駅", "長野県", "長野電鉄長野線" ],
    [ "柳原駅", "長野県", "長野電鉄長野線" ],
    [ "村山駅", "長野県", "長野電鉄長野線" ],
    [ "日野駅", "長野県", "長野電鉄長野線" ],
    [ "須坂駅", "長野県", "長野電鉄長野線" ],
    [ "北須坂駅", "長野県", "長野電鉄長野線" ],
    [ "小布施駅", "長野県", "長野電鉄長野線" ],
    [ "都住駅", "長野県", "長野電鉄長野線" ],
    [ "桜沢駅", "長野県", "長野電鉄長野線" ],
    [ "延徳駅", "長野県", "長野電鉄長野線" ],
    [ "信州中野駅", "長野県", "長野電鉄長野線" ],
    [ "中野松川駅", "長野県", "長野電鉄長野線" ],
    [ "信濃竹原駅", "長野県", "長野電鉄長野線" ],
    [ "夜間瀬駅", "長野県", "長野電鉄長野線" ],
    [ "上条駅", "長野県", "長野電鉄長野線" ],
    [ "湯田中駅", "長野県", "長野電鉄長野線" ],
    [ "屋代駅", "長野県", "長野電鉄屋代線" ],
    [ "東屋代駅", "長野県", "長野電鉄屋代線" ],
    [ "雨宮駅", "長野県", "長野電鉄屋代線" ],
    [ "岩野駅", "長野県", "長野電鉄屋代線" ],
    [ "象山口駅", "長野県", "長野電鉄屋代線" ],
    [ "松代駅", "長野県", "長野電鉄屋代線" ],
    [ "金井山駅", "長野県", "長野電鉄屋代線" ],
    [ "大室駅", "長野県", "長野電鉄屋代線" ],
    [ "信濃川田駅", "長野県", "長野電鉄屋代線" ],
    [ "若穂駅", "長野県", "長野電鉄屋代線" ],
    [ "綿内駅", "長野県", "長野電鉄屋代線" ],
    [ "井上駅", "長野県", "長野電鉄屋代線" ],
    [ "須坂駅", "長野県", "長野電鉄屋代線" ],
    [ "松本駅", "長野県", "上高地線" ],
    [ "西松本駅", "長野県", "上高地線" ],
    [ "渚駅", "長野県", "上高地線" ],
    [ "信濃荒井駅", "長野県", "上高地線" ],
    [ "大庭駅", "長野県", "上高地線" ],
    [ "下新駅", "長野県", "上高地線" ],
    [ "北新・松本大学前駅", "長野県", "上高地線" ],
    [ "新村駅", "長野県", "上高地線" ],
    [ "三溝駅", "長野県", "上高地線" ],
    [ "森口駅", "長野県", "上高地線" ],
    [ "下島駅", "長野県", "上高地線" ],
    [ "波田駅", "長野県", "上高地線" ],
    [ "渕東駅", "長野県", "上高地線" ],
    [ "新島々駅", "長野県", "上高地線" ],
    [ "電鉄富山駅", "富山県", "富山地鉄本線" ],
    [ "稲荷町駅", "富山県", "富山地鉄本線" ],
    [ "新庄田中駅", "富山県", "富山地鉄本線" ],
    [ "東新庄駅", "富山県", "富山地鉄本線" ],
    [ "越中荏原駅", "富山県", "富山地鉄本線" ],
    [ "越中三郷駅", "富山県", "富山地鉄本線" ],
    [ "越中舟橋駅", "富山県", "富山地鉄本線" ],
    [ "寺田駅", "富山県", "富山地鉄本線" ],
    [ "越中泉駅", "富山県", "富山地鉄本線" ],
    [ "相ノ木駅", "富山県", "富山地鉄本線" ],
    [ "新相ノ木駅", "富山県", "富山地鉄本線" ],
    [ "上市駅", "富山県", "富山地鉄本線" ],
    [ "新宮川駅", "富山県", "富山地鉄本線" ],
    [ "中加積駅", "富山県", "富山地鉄本線" ],
    [ "西加積駅", "富山県", "富山地鉄本線" ],
    [ "西滑川駅", "富山県", "富山地鉄本線" ],
    [ "中滑川駅", "富山県", "富山地鉄本線" ],
    [ "滑川駅", "富山県", "富山地鉄本線" ],
    [ "浜加積駅", "富山県", "富山地鉄本線" ],
    [ "早月加積駅", "富山県", "富山地鉄本線" ],
    [ "越中中村駅", "富山県", "富山地鉄本線" ],
    [ "西魚津駅", "富山県", "富山地鉄本線" ],
    [ "電鉄魚津駅", "富山県", "富山地鉄本線" ],
    [ "新魚津駅", "富山県", "富山地鉄本線" ],
    [ "経田駅", "富山県", "富山地鉄本線" ],
    [ "電鉄石田駅", "富山県", "富山地鉄本線" ],
    [ "電鉄黒部駅", "富山県", "富山地鉄本線" ],
    [ "東三日市駅", "富山県", "富山地鉄本線" ],
    [ "荻生駅", "富山県", "富山地鉄本線" ],
    [ "長屋駅", "富山県", "富山地鉄本線" ],
    [ "新黒部駅", "富山県", "富山地鉄本線" ],
    [ "舌山駅", "富山県", "富山地鉄本線" ],
    [ "若栗駅", "富山県", "富山地鉄本線" ],
    [ "栃屋駅", "富山県", "富山地鉄本線" ],
    [ "浦山駅", "富山県", "富山地鉄本線" ],
    [ "下立口駅", "富山県", "富山地鉄本線" ],
    [ "下立駅", "富山県", "富山地鉄本線" ],
    [ "愛本駅", "富山県", "富山地鉄本線" ],
    [ "内山駅", "富山県", "富山地鉄本線" ],
    [ "音沢駅", "富山県", "富山地鉄本線" ],
    [ "宇奈月温泉駅", "富山県", "富山地鉄本線" ],
    [ "寺田駅", "富山県", "富山地鉄立山線" ],
    [ "稚子塚駅", "富山県", "富山地鉄立山線" ],
    [ "田添駅", "富山県", "富山地鉄立山線" ],
    [ "五百石駅", "富山県", "富山地鉄立山線" ],
    [ "榎町駅", "富山県", "富山地鉄立山線" ],
    [ "下段駅", "富山県", "富山地鉄立山線" ],
    [ "釜ヶ淵駅", "富山県", "富山地鉄立山線" ],
    [ "沢中山駅", "富山県", "富山地鉄立山線" ],
    [ "岩峅寺駅", "富山県", "富山地鉄立山線" ],
    [ "横江駅", "富山県", "富山地鉄立山線" ],
    [ "千垣駅", "富山県", "富山地鉄立山線" ],
    [ "有峰口駅", "富山県", "富山地鉄立山線" ],
    [ "本宮駅", "富山県", "富山地鉄立山線" ],
    [ "立山駅", "富山県", "富山地鉄立山線" ],
    [ "電鉄富山駅", "富山県", "富山地鉄不二越・上滝線" ],
    [ "稲荷町駅", "富山県", "富山地鉄不二越・上滝線" ],
    [ "栄町駅", "富山県", "富山地鉄不二越・上滝線" ],
    [ "不二越駅", "富山県", "富山地鉄不二越・上滝線" ],
    [ "大泉駅", "富山県", "富山地鉄不二越・上滝線" ],
    [ "南富山駅", "富山県", "富山地鉄不二越・上滝線" ],
    [ "朝菜町駅", "富山県", "富山地鉄不二越・上滝線" ],
    [ "上堀駅", "富山県", "富山地鉄不二越・上滝線" ],
    [ "小杉駅", "富山県", "富山地鉄不二越・上滝線" ],
    [ "布市駅", "富山県", "富山地鉄不二越・上滝線" ],
    [ "開発駅", "富山県", "富山地鉄不二越・上滝線" ],
    [ "月岡駅", "富山県", "富山地鉄不二越・上滝線" ],
    [ "大庄駅", "富山県", "富山地鉄不二越・上滝線" ],
    [ "上滝駅", "富山県", "富山地鉄不二越・上滝線" ],
    [ "大川寺駅", "富山県", "富山地鉄不二越・上滝線" ],
    [ "岩峅寺駅", "富山県", "富山地鉄不二越・上滝線" ],
    [ "猪谷駅", "富山県", "神岡鉄道神岡線" ],
    [ "飛騨中山駅", "岐阜県", "神岡鉄道神岡線" ],
    [ "茂住駅", "岐阜県", "神岡鉄道神岡線" ],
    [ "漆山駅", "岐阜県", "神岡鉄道神岡線" ],
    [ "神岡鉱山前駅", "岐阜県", "神岡鉄道神岡線" ],
    [ "飛騨神岡駅", "岐阜県", "神岡鉄道神岡線" ],
    [ "神岡大橋駅", "岐阜県", "神岡鉄道神岡線" ],
    [ "奥飛騨温泉口駅", "岐阜県", "神岡鉄道神岡線" ],
    [ "宇奈月駅", "富山県", "黒部峡谷鉄道本線" ],
    [ "黒薙駅", "富山県", "黒部峡谷鉄道本線" ],
    [ "鐘釣駅", "富山県", "黒部峡谷鉄道本線" ],
    [ "欅平駅", "富山県", "黒部峡谷鉄道本線" ],
    [ "南富山駅前駅", "富山県", "富山地鉄市内線【１・２系統】" ],
    [ "丸の内駅", "富山県", "富山地鉄富山都心線【３系統(環状線)】" ],
    [ "大町駅", "富山県", "富山地鉄市内線【１・２系統】" ],
    [ "国際会議場前駅", "富山県", "富山地鉄富山都心線【３系統(環状線)】" ],
    [ "堀川小泉駅", "富山県", "富山地鉄市内線【１・２系統】" ],
    [ "大手モール駅", "富山県", "富山地鉄富山都心線【３系統(環状線)】" ],
    [ "小泉町駅", "富山県", "富山地鉄市内線【１・２系統】" ],
    [ "グランドプラザ前駅", "富山県", "富山地鉄富山都心線【３系統(環状線)】" ],
    [ "西中野駅", "富山県", "富山地鉄市内線【１・２系統】" ],
    [ "広貫堂前駅", "富山県", "富山地鉄市内線【１・２系統】" ],
    [ "中町（西町北）駅", "富山県", "富山地鉄富山都心線【３系統(環状線)】" ],
    [ "上本町駅", "富山県", "富山地鉄市内線【１・２系統】" ],
    [ "荒町駅", "富山県", "富山地鉄富山都心線【３系統(環状線)】" ],
    [ "西町駅", "富山県", "富山地鉄市内線【１・２系統】" ],
    [ "中町（西町北）駅", "富山県", "富山地鉄市内線【１・２系統】" ],
    [ "桜橋駅", "富山県", "富山地鉄富山都心線【３系統(環状線)】" ],
    [ "荒町駅", "富山県", "富山地鉄市内線【１・２系統】" ],
    [ "電気ビル前駅", "富山県", "富山地鉄富山都心線【３系統(環状線)】" ],
    [ "桜橋駅", "富山県", "富山地鉄市内線【１・２系統】" ],
    [ "地鉄ビル前駅", "富山県", "富山地鉄富山都心線【３系統(環状線)】" ],
    [ "電気ビル前駅", "富山県", "富山地鉄市内線【１・２系統】" ],
    [ "電鉄富山駅・エスタ前駅", "富山県", "富山地鉄富山都心線【３系統(環状線)】" ],
    [ "富山駅", "富山県", "富山地鉄富山都心線【３系統(環状線)】" ],
    [ "地鉄ビル前駅", "富山県", "富山地鉄市内線【１・２系統】" ],
    [ "新富町駅", "富山県", "富山地鉄富山都心線【３系統(環状線)】" ],
    [ "電鉄富山駅・エスタ前駅", "富山県", "富山地鉄市内線【１・２系統】" ],
    [ "富山駅", "富山県", "富山地鉄市内線【１・２系統】" ],
    [ "県庁前駅", "富山県", "富山地鉄富山都心線【３系統(環状線)】" ],
    [ "新富町駅", "富山県", "富山地鉄市内線【１・２系統】" ],
    [ "県庁前駅", "富山県", "富山地鉄市内線【１・２系統】" ],
    [ "丸の内駅", "富山県", "富山地鉄市内線【１・２系統】" ],
    [ "諏訪川原駅", "富山県", "富山地鉄市内線【１・２系統】" ],
    [ "安野屋駅", "富山県", "富山地鉄市内線【１・２系統】" ],
    [ "トヨタモビリティ富山 Gスクエア五福前駅", "富山県", "富山地鉄市内線【１・２系統】" ],
    [ "富山大学前駅", "富山県", "富山地鉄市内線【１・２系統】" ],
    [ "高岡駅", "富山県", "万葉線" ],
    [ "末広町駅", "富山県", "万葉線" ],
    [ "片原町駅", "富山県", "万葉線" ],
    [ "坂下町駅", "富山県", "万葉線" ],
    [ "急患医療センター前駅", "富山県", "万葉線" ],
    [ "広小路駅", "富山県", "万葉線" ],
    [ "志貴野中学校前駅", "富山県", "万葉線" ],
    [ "市民病院前駅", "富山県", "万葉線" ],
    [ "江尻駅", "富山県", "万葉線" ],
    [ "旭ヶ丘駅", "富山県", "万葉線" ],
    [ "荻布駅", "富山県", "万葉線" ],
    [ "新能町駅", "富山県", "万葉線" ],
    [ "米島口駅", "富山県", "万葉線" ],
    [ "能町口駅", "富山県", "万葉線" ],
    [ "新吉久駅", "富山県", "万葉線" ],
    [ "吉久駅", "富山県", "万葉線" ],
    [ "中伏木駅", "富山県", "万葉線" ],
    [ "六渡寺駅", "富山県", "万葉線" ],
    [ "庄川口駅", "富山県", "万葉線" ],
    [ "西新湊駅", "富山県", "万葉線" ],
    [ "新町口駅", "富山県", "万葉線" ],
    [ "中新湊駅", "富山県", "万葉線" ],
    [ "東新湊駅", "富山県", "万葉線" ],
    [ "海王丸駅", "富山県", "万葉線" ],
    [ "越ノ潟駅", "富山県", "万葉線" ],
    [ "富山駅", "富山県", "富山港線" ],
    [ "オークスカナルパークホテル富山前駅", "富山県", "富山港線" ],
    [ "インテック本社前駅", "富山県", "富山港線" ],
    [ "龍谷富山高校前（永楽町）駅", "富山県", "富山港線" ],
    [ "奥田中学校前駅", "富山県", "富山港線" ],
    [ "下奥井駅", "富山県", "富山港線" ],
    [ "粟島（大阪屋ショップ前）駅", "富山県", "富山港線" ],
    [ "越中中島駅", "富山県", "富山港線" ],
    [ "城川原駅", "富山県", "富山港線" ],
    [ "犬島新町駅", "富山県", "富山港線" ],
    [ "蓮町（馬場記念公園前）駅", "富山県", "富山港線" ],
    [ "萩浦小学校前駅", "富山県", "富山港線" ],
    [ "東岩瀬駅", "富山県", "富山港線" ],
    [ "競輪場前駅", "富山県", "富山港線" ],
    [ "岩瀬浜駅", "富山県", "富山港線" ],
    [ "野町駅", "石川県", "北陸鉄道石川線" ],
    [ "西泉駅", "石川県", "北陸鉄道石川線" ],
    [ "新西金沢駅", "石川県", "北陸鉄道石川線" ],
    [ "押野駅", "石川県", "北陸鉄道石川線" ],
    [ "野々市駅", "石川県", "北陸鉄道石川線" ],
    [ "野々市工大前駅", "石川県", "北陸鉄道石川線" ],
    [ "馬替駅", "石川県", "北陸鉄道石川線" ],
    [ "額住宅前駅", "石川県", "北陸鉄道石川線" ],
    [ "乙丸駅", "石川県", "北陸鉄道石川線" ],
    [ "四十万駅", "石川県", "北陸鉄道石川線" ],
    [ "陽羽里駅", "石川県", "北陸鉄道石川線" ],
    [ "曽谷駅", "石川県", "北陸鉄道石川線" ],
    [ "道法寺駅", "石川県", "北陸鉄道石川線" ],
    [ "井口駅", "石川県", "北陸鉄道石川線" ],
    [ "小柳駅", "石川県", "北陸鉄道石川線" ],
    [ "日御子駅", "石川県", "北陸鉄道石川線" ],
    [ "鶴来駅", "石川県", "北陸鉄道石川線" ],
    [ "中鶴来駅", "石川県", "北陸鉄道石川線" ],
    [ "加賀一の宮駅", "石川県", "北陸鉄道石川線" ],
    [ "北鉄金沢駅", "石川県", "北陸鉄道浅野川線" ],
    [ "七ツ屋駅", "石川県", "北陸鉄道浅野川線" ],
    [ "上諸江駅", "石川県", "北陸鉄道浅野川線" ],
    [ "磯部駅", "石川県", "北陸鉄道浅野川線" ],
    [ "割出駅", "石川県", "北陸鉄道浅野川線" ],
    [ "三口駅", "石川県", "北陸鉄道浅野川線" ],
    [ "三ツ屋駅", "石川県", "北陸鉄道浅野川線" ],
    [ "大河端駅", "石川県", "北陸鉄道浅野川線" ],
    [ "北間駅", "石川県", "北陸鉄道浅野川線" ],
    [ "蚊爪駅", "石川県", "北陸鉄道浅野川線" ],
    [ "粟ヶ崎駅", "石川県", "北陸鉄道浅野川線" ],
    [ "内灘駅", "石川県", "北陸鉄道浅野川線" ],
    [ "七尾駅", "石川県", "のと鉄道七尾線" ],
    [ "和倉温泉駅", "石川県", "のと鉄道七尾線" ],
    [ "田鶴浜駅", "石川県", "のと鉄道七尾線" ],
    [ "笠師保駅", "石川県", "のと鉄道七尾線" ],
    [ "能登中島駅", "石川県", "のと鉄道七尾線" ],
    [ "西岸駅", "石川県", "のと鉄道七尾線" ],
    [ "能登鹿島駅", "石川県", "のと鉄道七尾線" ],
    [ "穴水駅", "石川県", "のと鉄道七尾線" ],
    [ "福井駅", "福井県", "えちぜん鉄道勝山永平寺線" ],
    [ "新福井駅", "福井県", "えちぜん鉄道勝山永平寺線" ],
    [ "福井口駅", "福井県", "えちぜん鉄道勝山永平寺線" ],
    [ "越前開発駅", "福井県", "えちぜん鉄道勝山永平寺線" ],
    [ "越前新保駅", "福井県", "えちぜん鉄道勝山永平寺線" ],
    [ "追分口駅", "福井県", "えちぜん鉄道勝山永平寺線" ],
    [ "東藤島駅", "福井県", "えちぜん鉄道勝山永平寺線" ],
    [ "越前島橋駅", "福井県", "えちぜん鉄道勝山永平寺線" ],
    [ "観音町駅", "福井県", "えちぜん鉄道勝山永平寺線" ],
    [ "松岡駅", "福井県", "えちぜん鉄道勝山永平寺線" ],
    [ "志比堺駅", "福井県", "えちぜん鉄道勝山永平寺線" ],
    [ "永平寺口駅", "福井県", "えちぜん鉄道勝山永平寺線" ],
    [ "下志比駅", "福井県", "えちぜん鉄道勝山永平寺線" ],
    [ "光明寺駅", "福井県", "えちぜん鉄道勝山永平寺線" ],
    [ "轟駅", "福井県", "えちぜん鉄道勝山永平寺線" ],
    [ "越前野中駅", "福井県", "えちぜん鉄道勝山永平寺線" ],
    [ "山王駅", "福井県", "えちぜん鉄道勝山永平寺線" ],
    [ "越前竹原駅", "福井県", "えちぜん鉄道勝山永平寺線" ],
    [ "小舟渡駅", "福井県", "えちぜん鉄道勝山永平寺線" ],
    [ "保田駅", "福井県", "えちぜん鉄道勝山永平寺線" ],
    [ "発坂駅", "福井県", "えちぜん鉄道勝山永平寺線" ],
    [ "比島駅", "福井県", "えちぜん鉄道勝山永平寺線" ],
    [ "勝山駅", "福井県", "えちぜん鉄道勝山永平寺線" ],
    [ "福井駅", "福井県", "えちぜん鉄道三国芦原線" ],
    [ "新福井駅", "福井県", "えちぜん鉄道三国芦原線" ],
    [ "福井口駅", "福井県", "えちぜん鉄道三国芦原線" ],
    [ "まつもと町屋駅", "福井県", "えちぜん鉄道三国芦原線" ],
    [ "西別院駅", "福井県", "えちぜん鉄道三国芦原線" ],
    [ "田原町駅", "福井県", "えちぜん鉄道三国芦原線" ],
    [ "福大前西福井駅", "福井県", "えちぜん鉄道三国芦原線" ],
    [ "日華化学前駅", "福井県", "えちぜん鉄道三国芦原線" ],
    [ "八ツ島駅", "福井県", "えちぜん鉄道三国芦原線" ],
    [ "新田塚駅", "福井県", "えちぜん鉄道三国芦原線" ],
    [ "中角駅", "福井県", "えちぜん鉄道三国芦原線" ],
    [ "鷲塚針原駅", "福井県", "えちぜん鉄道三国芦原線" ],
    [ "太郎丸エンゼルランド駅", "福井県", "えちぜん鉄道三国芦原線" ],
    [ "西春江ハートピア駅", "福井県", "えちぜん鉄道三国芦原線" ],
    [ "西長田ゆりの里駅", "福井県", "えちぜん鉄道三国芦原線" ],
    [ "下兵庫こうふく駅", "福井県", "えちぜん鉄道三国芦原線" ],
    [ "大関駅", "福井県", "えちぜん鉄道三国芦原線" ],
    [ "本荘駅", "福井県", "えちぜん鉄道三国芦原線" ],
    [ "番田駅", "福井県", "えちぜん鉄道三国芦原線" ],
    [ "あわら湯のまち駅", "福井県", "えちぜん鉄道三国芦原線" ],
    [ "水居駅", "福井県", "えちぜん鉄道三国芦原線" ],
    [ "三国神社駅", "福井県", "えちぜん鉄道三国芦原線" ],
    [ "三国駅", "福井県", "えちぜん鉄道三国芦原線" ],
    [ "三国港駅", "福井県", "えちぜん鉄道三国芦原線" ],
    [ "たけふ新駅", "福井県", "福井鉄道福武線" ],
    [ "北府駅", "福井県", "福井鉄道福武線" ],
    [ "スポーツ公園駅", "福井県", "福井鉄道福武線" ],
    [ "家久駅", "福井県", "福井鉄道福武線" ],
    [ "サンドーム西駅", "福井県", "福井鉄道福武線" ],
    [ "西鯖江駅", "福井県", "福井鉄道福武線" ],
    [ "西山公園駅", "福井県", "福井鉄道福武線" ],
    [ "水落駅", "福井県", "福井鉄道福武線" ],
    [ "神明駅", "福井県", "福井鉄道福武線" ],
    [ "鳥羽中駅", "福井県", "福井鉄道福武線" ],
    [ "三十八社駅", "福井県", "福井鉄道福武線" ],
    [ "泰澄の里駅", "福井県", "福井鉄道福武線" ],
    [ "浅水駅", "福井県", "福井鉄道福武線" ],
    [ "ハーモニーホール駅", "福井県", "福井鉄道福武線" ],
    [ "清明駅", "福井県", "福井鉄道福武線" ],
    [ "江端駅", "福井県", "福井鉄道福武線" ],
    [ "ベル前駅", "福井県", "福井鉄道福武線" ],
    [ "花堂駅", "福井県", "福井鉄道福武線" ],
    [ "赤十字前駅", "福井県", "福井鉄道福武線" ],
    [ "商工会議所前駅", "福井県", "福井鉄道福武線" ],
    [ "足羽山公園口駅", "福井県", "福井鉄道福武線" ],
    [ "福井城址大名町駅", "福井県", "福井鉄道福武線" ],
    [ "福井駅", "福井県", "福井鉄道福武線" ],
    [ "仁愛女子高校駅", "福井県", "福井鉄道福武線" ],
    [ "田原町駅", "福井県", "福井鉄道福武線" ],
    [ "直江津駅", "新潟県", "日本海ひすいライン" ],
    [ "谷浜駅", "新潟県", "日本海ひすいライン" ],
    [ "有間川駅", "新潟県", "日本海ひすいライン" ],
    [ "名立駅", "新潟県", "日本海ひすいライン" ],
    [ "筒石駅", "新潟県", "日本海ひすいライン" ],
    [ "能生駅", "新潟県", "日本海ひすいライン" ],
    [ "浦本駅", "新潟県", "日本海ひすいライン" ],
    [ "梶屋敷駅", "新潟県", "日本海ひすいライン" ],
    [ "えちご押上ひすい海岸駅", "新潟県", "日本海ひすいライン" ],
    [ "糸魚川駅", "新潟県", "日本海ひすいライン" ],
    [ "青海駅", "新潟県", "日本海ひすいライン" ],
    [ "親不知駅", "新潟県", "日本海ひすいライン" ],
    [ "市振駅", "新潟県", "日本海ひすいライン" ],
    [ "直江津駅", "新潟県", "妙高はねうまライン" ],
    [ "春日山駅", "新潟県", "妙高はねうまライン" ],
    [ "高田駅", "新潟県", "妙高はねうまライン" ],
    [ "南高田駅", "新潟県", "妙高はねうまライン" ],
    [ "上越妙高駅", "新潟県", "妙高はねうまライン" ],
    [ "北新井駅", "新潟県", "妙高はねうまライン" ],
    [ "新井駅", "新潟県", "妙高はねうまライン" ],
    [ "二本木駅", "新潟県", "妙高はねうまライン" ],
    [ "関山駅", "新潟県", "妙高はねうまライン" ],
    [ "妙高高原駅", "新潟県", "妙高はねうまライン" ],
    [ "倶利伽羅駅", "石川県", "あいの風とやま鉄道線" ],
    [ "石動駅", "富山県", "あいの風とやま鉄道線" ],
    [ "福岡駅", "富山県", "あいの風とやま鉄道線" ],
    [ "西高岡駅", "富山県", "あいの風とやま鉄道線" ],
    [ "高岡やぶなみ駅", "富山県", "あいの風とやま鉄道線" ],
    [ "高岡駅", "富山県", "あいの風とやま鉄道線" ],
    [ "越中大門駅", "富山県", "あいの風とやま鉄道線" ],
    [ "小杉駅", "富山県", "あいの風とやま鉄道線" ],
    [ "呉羽駅", "富山県", "あいの風とやま鉄道線" ],
    [ "富山駅", "富山県", "あいの風とやま鉄道線" ],
    [ "新富山口駅", "富山県", "あいの風とやま鉄道線" ],
    [ "東富山駅", "富山県", "あいの風とやま鉄道線" ],
    [ "水橋駅", "富山県", "あいの風とやま鉄道線" ],
    [ "滑川駅", "富山県", "あいの風とやま鉄道線" ],
    [ "東滑川駅", "富山県", "あいの風とやま鉄道線" ],
    [ "魚津駅", "富山県", "あいの風とやま鉄道線" ],
    [ "黒部駅", "富山県", "あいの風とやま鉄道線" ],
    [ "生地駅", "富山県", "あいの風とやま鉄道線" ],
    [ "西入善駅", "富山県", "あいの風とやま鉄道線" ],
    [ "入善駅", "富山県", "あいの風とやま鉄道線" ],
    [ "泊駅", "富山県", "あいの風とやま鉄道線" ],
    [ "越中宮崎駅", "富山県", "あいの風とやま鉄道線" ],
    [ "市振駅", "新潟県", "あいの風とやま鉄道線" ],
    [ "金沢駅", "石川県", "IRいしかわ鉄道線" ],
    [ "東金沢駅", "石川県", "IRいしかわ鉄道線" ],
    [ "森本駅", "石川県", "IRいしかわ鉄道線" ],
    [ "津幡駅", "石川県", "IRいしかわ鉄道線" ],
    [ "倶利伽羅駅", "石川県", "IRいしかわ鉄道線" ],
    [ "長野駅", "長野県", "北しなの線" ],
    [ "北長野駅", "長野県", "北しなの線" ],
    [ "三才駅", "長野県", "北しなの線" ],
    [ "豊野駅", "長野県", "北しなの線" ],
    [ "牟礼駅", "長野県", "北しなの線" ],
    [ "古間駅", "長野県", "北しなの線" ],
    [ "黒姫駅", "長野県", "北しなの線" ],
    [ "妙高高原駅", "新潟県", "北しなの線" ],
    [ "伊東駅", "静岡県", "伊豆急行線" ],
    [ "南伊東駅", "静岡県", "伊豆急行線" ],
    [ "川奈駅", "静岡県", "伊豆急行線" ],
    [ "富戸駅", "静岡県", "伊豆急行線" ],
    [ "城ヶ崎海岸駅", "静岡県", "伊豆急行線" ],
    [ "伊豆高原駅", "静岡県", "伊豆急行線" ],
    [ "伊豆大川駅", "静岡県", "伊豆急行線" ],
    [ "伊豆北川駅", "静岡県", "伊豆急行線" ],
    [ "伊豆熱川駅", "静岡県", "伊豆急行線" ],
    [ "片瀬白田駅", "静岡県", "伊豆急行線" ],
    [ "伊豆稲取駅", "静岡県", "伊豆急行線" ],
    [ "今井浜海岸駅", "静岡県", "伊豆急行線" ],
    [ "河津駅", "静岡県", "伊豆急行線" ],
    [ "稲梓駅", "静岡県", "伊豆急行線" ],
    [ "蓮台寺駅", "静岡県", "伊豆急行線" ],
    [ "伊豆急下田駅", "静岡県", "伊豆急行線" ],
    [ "三島駅", "静岡県", "伊豆箱根鉄道駿豆線" ],
    [ "三島広小路駅", "静岡県", "伊豆箱根鉄道駿豆線" ],
    [ "三島田町駅", "静岡県", "伊豆箱根鉄道駿豆線" ],
    [ "三島二日町駅", "静岡県", "伊豆箱根鉄道駿豆線" ],
    [ "大場駅", "静岡県", "伊豆箱根鉄道駿豆線" ],
    [ "伊豆仁田駅", "静岡県", "伊豆箱根鉄道駿豆線" ],
    [ "原木駅", "静岡県", "伊豆箱根鉄道駿豆線" ],
    [ "韮山駅", "静岡県", "伊豆箱根鉄道駿豆線" ],
    [ "伊豆長岡駅", "静岡県", "伊豆箱根鉄道駿豆線" ],
    [ "田京駅", "静岡県", "伊豆箱根鉄道駿豆線" ],
    [ "大仁駅", "静岡県", "伊豆箱根鉄道駿豆線" ],
    [ "牧之郷駅", "静岡県", "伊豆箱根鉄道駿豆線" ],
    [ "修善寺駅", "静岡県", "伊豆箱根鉄道駿豆線" ],
    [ "吉原駅", "静岡県", "岳南鉄道線" ],
    [ "ジヤトコ前（ジヤトコ１地区前）駅", "静岡県", "岳南鉄道線" ],
    [ "吉原本町駅", "静岡県", "岳南鉄道線" ],
    [ "本吉原駅", "静岡県", "岳南鉄道線" ],
    [ "岳南原田駅", "静岡県", "岳南鉄道線" ],
    [ "比奈駅", "静岡県", "岳南鉄道線" ],
    [ "岳南富士岡駅", "静岡県", "岳南鉄道線" ],
    [ "須津駅", "静岡県", "岳南鉄道線" ],
    [ "神谷駅", "静岡県", "岳南鉄道線" ],
    [ "岳南江尾駅", "静岡県", "岳南鉄道線" ],
    [ "新静岡駅", "静岡県", "静岡鉄道静岡清水線" ],
    [ "日吉町駅", "静岡県", "静岡鉄道静岡清水線" ],
    [ "音羽町駅", "静岡県", "静岡鉄道静岡清水線" ],
    [ "春日町駅", "静岡県", "静岡鉄道静岡清水線" ],
    [ "柚木駅", "静岡県", "静岡鉄道静岡清水線" ],
    [ "長沼駅", "静岡県", "静岡鉄道静岡清水線" ],
    [ "古庄駅", "静岡県", "静岡鉄道静岡清水線" ],
    [ "県総合運動場駅", "静岡県", "静岡鉄道静岡清水線" ],
    [ "県立美術館前駅", "静岡県", "静岡鉄道静岡清水線" ],
    [ "草薙駅", "静岡県", "静岡鉄道静岡清水線" ],
    [ "御門台駅", "静岡県", "静岡鉄道静岡清水線" ],
    [ "狐ヶ崎駅", "静岡県", "静岡鉄道静岡清水線" ],
    [ "桜橋駅", "静岡県", "静岡鉄道静岡清水線" ],
    [ "入江岡駅", "静岡県", "静岡鉄道静岡清水線" ],
    [ "新清水駅", "静岡県", "静岡鉄道静岡清水線" ],
    [ "掛川駅", "静岡県", "天竜浜名湖線" ],
    [ "掛川市役所前駅", "静岡県", "天竜浜名湖線" ],
    [ "西掛川駅", "静岡県", "天竜浜名湖線" ],
    [ "桜木駅", "静岡県", "天竜浜名湖線" ],
    [ "いこいの広場駅", "静岡県", "天竜浜名湖線" ],
    [ "細谷駅", "静岡県", "天竜浜名湖線" ],
    [ "原谷駅", "静岡県", "天竜浜名湖線" ],
    [ "原田駅", "静岡県", "天竜浜名湖線" ],
    [ "戸綿駅", "静岡県", "天竜浜名湖線" ],
    [ "遠州森駅", "静岡県", "天竜浜名湖線" ],
    [ "森町病院前駅", "静岡県", "天竜浜名湖線" ],
    [ "円田駅", "静岡県", "天竜浜名湖線" ],
    [ "遠江一宮駅", "静岡県", "天竜浜名湖線" ],
    [ "敷地駅", "静岡県", "天竜浜名湖線" ],
    [ "豊岡駅", "静岡県", "天竜浜名湖線" ],
    [ "上野部駅", "静岡県", "天竜浜名湖線" ],
    [ "天竜二俣駅", "静岡県", "天竜浜名湖線" ],
    [ "二俣本町駅", "静岡県", "天竜浜名湖線" ],
    [ "西鹿島駅", "静岡県", "天竜浜名湖線" ],
    [ "岩水寺駅", "静岡県", "天竜浜名湖線" ],
    [ "宮口駅", "静岡県", "天竜浜名湖線" ],
    [ "フルーツパーク駅", "静岡県", "天竜浜名湖線" ],
    [ "都田駅", "静岡県", "天竜浜名湖線" ],
    [ "常葉大学前駅", "静岡県", "天竜浜名湖線" ],
    [ "金指駅", "静岡県", "天竜浜名湖線" ],
    [ "岡地駅", "静岡県", "天竜浜名湖線" ],
    [ "気賀駅", "静岡県", "天竜浜名湖線" ],
    [ "西気賀駅", "静岡県", "天竜浜名湖線" ],
    [ "寸座駅", "静岡県", "天竜浜名湖線" ],
    [ "浜名湖佐久米駅", "静岡県", "天竜浜名湖線" ],
    [ "東都筑駅", "静岡県", "天竜浜名湖線" ],
    [ "都筑駅", "静岡県", "天竜浜名湖線" ],
    [ "三ヶ日駅", "静岡県", "天竜浜名湖線" ],
    [ "奥浜名湖駅", "静岡県", "天竜浜名湖線" ],
    [ "尾奈駅", "静岡県", "天竜浜名湖線" ],
    [ "知波田駅", "静岡県", "天竜浜名湖線" ],
    [ "大森駅", "静岡県", "天竜浜名湖線" ],
    [ "アスモ前駅", "静岡県", "天竜浜名湖線" ],
    [ "新所原駅", "静岡県", "天竜浜名湖線" ],
    [ "新浜松駅", "静岡県", "遠州鉄道鉄道線" ],
    [ "第一通り駅", "静岡県", "遠州鉄道鉄道線" ],
    [ "遠州病院駅", "静岡県", "遠州鉄道鉄道線" ],
    [ "八幡駅", "静岡県", "遠州鉄道鉄道線" ],
    [ "助信駅", "静岡県", "遠州鉄道鉄道線" ],
    [ "曳馬駅", "静岡県", "遠州鉄道鉄道線" ],
    [ "上島駅", "静岡県", "遠州鉄道鉄道線" ],
    [ "自動車学校前駅", "静岡県", "遠州鉄道鉄道線" ],
    [ "さぎの宮駅", "静岡県", "遠州鉄道鉄道線" ],
    [ "積志駅", "静岡県", "遠州鉄道鉄道線" ],
    [ "遠州西ヶ崎駅", "静岡県", "遠州鉄道鉄道線" ],
    [ "遠州小松駅", "静岡県", "遠州鉄道鉄道線" ],
    [ "浜北駅", "静岡県", "遠州鉄道鉄道線" ],
    [ "美薗中央公園駅", "静岡県", "遠州鉄道鉄道線" ],
    [ "遠州小林駅", "静岡県", "遠州鉄道鉄道線" ],
    [ "遠州芝本駅", "静岡県", "遠州鉄道鉄道線" ],
    [ "遠州岩水寺駅", "静岡県", "遠州鉄道鉄道線" ],
    [ "西鹿島駅", "静岡県", "遠州鉄道鉄道線" ],
    [ "金谷駅", "静岡県", "大井川鐵道大井川本線" ],
    [ "新金谷駅", "静岡県", "大井川鐵道大井川本線" ],
    [ "代官町駅", "静岡県", "大井川鐵道大井川本線" ],
    [ "日切駅", "静岡県", "大井川鐵道大井川本線" ],
    [ "合格駅", "静岡県", "大井川鐵道大井川本線" ],
    [ "門出駅", "静岡県", "大井川鐵道大井川本線" ],
    [ "神尾駅", "静岡県", "大井川鐵道大井川本線" ],
    [ "福用駅", "静岡県", "大井川鐵道大井川本線" ],
    [ "大和田駅", "静岡県", "大井川鐵道大井川本線" ],
    [ "家山駅", "静岡県", "大井川鐵道大井川本線" ],
    [ "抜里駅", "静岡県", "大井川鐵道大井川本線" ],
    [ "川根温泉笹間渡駅", "静岡県", "大井川鐵道大井川本線" ],
    [ "地名駅", "静岡県", "大井川鐵道大井川本線" ],
    [ "塩郷駅", "静岡県", "大井川鐵道大井川本線" ],
    [ "下泉駅", "静岡県", "大井川鐵道大井川本線" ],
    [ "田野口駅", "静岡県", "大井川鐵道大井川本線" ],
    [ "駿河徳山駅", "静岡県", "大井川鐵道大井川本線" ],
    [ "青部駅", "静岡県", "大井川鐵道大井川本線" ],
    [ "崎平駅", "静岡県", "大井川鐵道大井川本線" ],
    [ "千頭駅", "静岡県", "大井川鐵道大井川本線" ],
    [ "千頭駅", "静岡県", "南アルプスあぷとライン" ],
    [ "川根両国駅", "静岡県", "南アルプスあぷとライン" ],
    [ "沢間駅", "静岡県", "南アルプスあぷとライン" ],
    [ "土本駅", "静岡県", "南アルプスあぷとライン" ],
    [ "川根小山駅", "静岡県", "南アルプスあぷとライン" ],
    [ "奥泉駅", "静岡県", "南アルプスあぷとライン" ],
    [ "アプトいちしろ駅", "静岡県", "南アルプスあぷとライン" ],
    [ "長島ダム駅", "静岡県", "南アルプスあぷとライン" ],
    [ "ひらんだ駅", "静岡県", "南アルプスあぷとライン" ],
    [ "奥大井湖上駅", "静岡県", "南アルプスあぷとライン" ],
    [ "接岨峡温泉駅", "静岡県", "南アルプスあぷとライン" ],
    [ "尾盛駅", "静岡県", "南アルプスあぷとライン" ],
    [ "閑蔵駅", "静岡県", "南アルプスあぷとライン" ],
    [ "井川駅", "静岡県", "南アルプスあぷとライン" ],
    [ "名古屋駅", "愛知県", "あおなみ線" ],
    [ "ささしまライブ駅", "愛知県", "あおなみ線" ],
    [ "小本駅", "愛知県", "あおなみ線" ],
    [ "荒子駅", "愛知県", "あおなみ線" ],
    [ "南荒子駅", "愛知県", "あおなみ線" ],
    [ "中島駅", "愛知県", "あおなみ線" ],
    [ "港北駅", "愛知県", "あおなみ線" ],
    [ "荒子川公園駅", "愛知県", "あおなみ線" ],
    [ "稲永駅", "愛知県", "あおなみ線" ],
    [ "野跡駅", "愛知県", "あおなみ線" ],
    [ "金城ふ頭駅", "愛知県", "あおなみ線" ],
    [ "枇杷島駅", "愛知県", "東海交通事業城北線" ],
    [ "尾張星の宮駅", "愛知県", "東海交通事業城北線" ],
    [ "小田井駅", "愛知県", "東海交通事業城北線" ],
    [ "比良駅", "愛知県", "東海交通事業城北線" ],
    [ "味美駅", "愛知県", "東海交通事業城北線" ],
    [ "勝川駅", "愛知県", "東海交通事業城北線" ],
    [ "岡崎駅", "愛知県", "愛知環状鉄道線" ],
    [ "六名駅", "愛知県", "愛知環状鉄道線" ],
    [ "中岡崎駅", "愛知県", "愛知環状鉄道線" ],
    [ "北岡崎駅", "愛知県", "愛知環状鉄道線" ],
    [ "大門駅", "愛知県", "愛知環状鉄道線" ],
    [ "北野桝塚駅", "愛知県", "愛知環状鉄道線" ],
    [ "三河上郷駅", "愛知県", "愛知環状鉄道線" ],
    [ "永覚駅", "愛知県", "愛知環状鉄道線" ],
    [ "末野原駅", "愛知県", "愛知環状鉄道線" ],
    [ "三河豊田駅", "愛知県", "愛知環状鉄道線" ],
    [ "新上挙母駅", "愛知県", "愛知環状鉄道線" ],
    [ "新豊田駅", "愛知県", "愛知環状鉄道線" ],
    [ "愛環梅坪駅", "愛知県", "愛知環状鉄道線" ],
    [ "四郷駅", "愛知県", "愛知環状鉄道線" ],
    [ "貝津駅", "愛知県", "愛知環状鉄道線" ],
    [ "保見駅", "愛知県", "愛知環状鉄道線" ],
    [ "篠原駅", "愛知県", "愛知環状鉄道線" ],
    [ "八草駅", "愛知県", "愛知環状鉄道線" ],
    [ "山口駅", "愛知県", "愛知環状鉄道線" ],
    [ "瀬戸口駅", "愛知県", "愛知環状鉄道線" ],
    [ "瀬戸市駅", "愛知県", "愛知環状鉄道線" ],
    [ "中水野駅", "愛知県", "愛知環状鉄道線" ],
    [ "高蔵寺駅", "愛知県", "愛知環状鉄道線" ],
    [ "藤が丘駅", "愛知県", "リニモ" ],
    [ "はなみずき通駅", "愛知県", "リニモ" ],
    [ "杁ヶ池公園駅", "愛知県", "リニモ" ],
    [ "長久手古戦場駅", "愛知県", "リニモ" ],
    [ "芸大通駅", "愛知県", "リニモ" ],
    [ "公園西駅", "愛知県", "リニモ" ],
    [ "愛・地球博記念公園駅", "愛知県", "リニモ" ],
    [ "陶磁資料館南駅", "愛知県", "リニモ" ],
    [ "八草駅", "愛知県", "リニモ" ],
    [ "高畑駅", "愛知県", "名古屋市営地下鉄東山線" ],
    [ "八田駅", "愛知県", "名古屋市営地下鉄東山線" ],
    [ "岩塚駅", "愛知県", "名古屋市営地下鉄東山線" ],
    [ "中村公園駅", "愛知県", "名古屋市営地下鉄東山線" ],
    [ "中村日赤駅", "愛知県", "名古屋市営地下鉄東山線" ],
    [ "本陣駅", "愛知県", "名古屋市営地下鉄東山線" ],
    [ "亀島駅", "愛知県", "名古屋市営地下鉄東山線" ],
    [ "名古屋駅", "愛知県", "名古屋市営地下鉄東山線" ],
    [ "伏見駅", "愛知県", "名古屋市営地下鉄東山線" ],
    [ "栄駅", "愛知県", "名古屋市営地下鉄東山線" ],
    [ "新栄町駅", "愛知県", "名古屋市営地下鉄東山線" ],
    [ "千種駅", "愛知県", "名古屋市営地下鉄東山線" ],
    [ "今池駅", "愛知県", "名古屋市営地下鉄東山線" ],
    [ "池下駅", "愛知県", "名古屋市営地下鉄東山線" ],
    [ "覚王山駅", "愛知県", "名古屋市営地下鉄東山線" ],
    [ "本山駅", "愛知県", "名古屋市営地下鉄東山線" ],
    [ "東山公園駅", "愛知県", "名古屋市営地下鉄東山線" ],
    [ "星ヶ丘駅", "愛知県", "名古屋市営地下鉄東山線" ],
    [ "一社駅", "愛知県", "名古屋市営地下鉄東山線" ],
    [ "上社駅", "愛知県", "名古屋市営地下鉄東山線" ],
    [ "本郷駅", "愛知県", "名古屋市営地下鉄東山線" ],
    [ "藤が丘駅", "愛知県", "名古屋市営地下鉄東山線" ],
    [ "ナゴヤドーム前矢田駅", "愛知県", "名古屋市営地下鉄名城線" ],
    [ "大曽根駅", "愛知県", "名古屋市営地下鉄名城線" ],
    [ "平安通駅", "愛知県", "名古屋市営地下鉄名城線" ],
    [ "志賀本通駅", "愛知県", "名古屋市営地下鉄名城線" ],
    [ "黒川駅", "愛知県", "名古屋市営地下鉄名城線" ],
    [ "名城公園駅", "愛知県", "名古屋市営地下鉄名城線" ],
    [ "名古屋城駅", "愛知県", "名古屋市営地下鉄名城線" ],
    [ "久屋大通駅", "愛知県", "名古屋市営地下鉄名城線" ],
    [ "栄駅", "愛知県", "名古屋市営地下鉄名城線" ],
    [ "矢場町駅", "愛知県", "名古屋市営地下鉄名城線" ],
    [ "上前津駅", "愛知県", "名古屋市営地下鉄名城線" ],
    [ "東別院駅", "愛知県", "名古屋市営地下鉄名城線" ],
    [ "金山駅", "愛知県", "名古屋市営地下鉄名城線" ],
    [ "西高蔵駅", "愛知県", "名古屋市営地下鉄名城線" ],
    [ "熱田神宮西駅", "愛知県", "名古屋市営地下鉄名城線" ],
    [ "熱田神宮伝馬町駅", "愛知県", "名古屋市営地下鉄名城線" ],
    [ "堀田駅", "愛知県", "名古屋市営地下鉄名城線" ],
    [ "妙音通駅", "愛知県", "名古屋市営地下鉄名城線" ],
    [ "新瑞橋駅", "愛知県", "名古屋市営地下鉄名城線" ],
    [ "瑞穂運動場東駅", "愛知県", "名古屋市営地下鉄名城線" ],
    [ "総合リハビリセンター駅", "愛知県", "名古屋市営地下鉄名城線" ],
    [ "八事駅", "愛知県", "名古屋市営地下鉄名城線" ],
    [ "八事日赤駅", "愛知県", "名古屋市営地下鉄名城線" ],
    [ "名古屋大学駅", "愛知県", "名古屋市営地下鉄名城線" ],
    [ "本山駅", "愛知県", "名古屋市営地下鉄名城線" ],
    [ "自由ヶ丘駅", "愛知県", "名古屋市営地下鉄名城線" ],
    [ "茶屋ヶ坂駅", "愛知県", "名古屋市営地下鉄名城線" ],
    [ "砂田橋駅", "愛知県", "名古屋市営地下鉄名城線" ],
    [ "金山駅", "愛知県", "名古屋市営地下鉄名港線" ],
    [ "日比野駅", "愛知県", "名古屋市営地下鉄名港線" ],
    [ "六番町駅", "愛知県", "名古屋市営地下鉄名港線" ],
    [ "東海通駅", "愛知県", "名古屋市営地下鉄名港線" ],
    [ "港区役所駅", "愛知県", "名古屋市営地下鉄名港線" ],
    [ "築地口駅", "愛知県", "名古屋市営地下鉄名港線" ],
    [ "名古屋港駅", "愛知県", "名古屋市営地下鉄名港線" ],
    [ "上小田井駅", "愛知県", "名古屋市営地下鉄鶴舞線" ],
    [ "庄内緑地公園駅", "愛知県", "名古屋市営地下鉄鶴舞線" ],
    [ "庄内通駅", "愛知県", "名古屋市営地下鉄鶴舞線" ],
    [ "浄心駅", "愛知県", "名古屋市営地下鉄鶴舞線" ],
    [ "浅間町駅", "愛知県", "名古屋市営地下鉄鶴舞線" ],
    [ "丸の内駅", "愛知県", "名古屋市営地下鉄鶴舞線" ],
    [ "伏見駅", "愛知県", "名古屋市営地下鉄鶴舞線" ],
    [ "大須観音駅", "愛知県", "名古屋市営地下鉄鶴舞線" ],
    [ "上前津駅", "愛知県", "名古屋市営地下鉄鶴舞線" ],
    [ "鶴舞駅", "愛知県", "名古屋市営地下鉄鶴舞線" ],
    [ "荒畑駅", "愛知県", "名古屋市営地下鉄鶴舞線" ],
    [ "御器所駅", "愛知県", "名古屋市営地下鉄鶴舞線" ],
    [ "川名駅", "愛知県", "名古屋市営地下鉄鶴舞線" ],
    [ "いりなか駅", "愛知県", "名古屋市営地下鉄鶴舞線" ],
    [ "八事駅", "愛知県", "名古屋市営地下鉄鶴舞線" ],
    [ "塩釜口駅", "愛知県", "名古屋市営地下鉄鶴舞線" ],
    [ "植田駅", "愛知県", "名古屋市営地下鉄鶴舞線" ],
    [ "原駅", "愛知県", "名古屋市営地下鉄鶴舞線" ],
    [ "平針駅", "愛知県", "名古屋市営地下鉄鶴舞線" ],
    [ "赤池駅", "愛知県", "名古屋市営地下鉄鶴舞線" ],
    [ "太閤通駅", "愛知県", "名古屋市営地下鉄桜通線" ],
    [ "名古屋駅", "愛知県", "名古屋市営地下鉄桜通線" ],
    [ "国際センター駅", "愛知県", "名古屋市営地下鉄桜通線" ],
    [ "丸の内駅", "愛知県", "名古屋市営地下鉄桜通線" ],
    [ "久屋大通駅", "愛知県", "名古屋市営地下鉄桜通線" ],
    [ "高岳駅", "愛知県", "名古屋市営地下鉄桜通線" ],
    [ "車道駅", "愛知県", "名古屋市営地下鉄桜通線" ],
    [ "今池駅", "愛知県", "名古屋市営地下鉄桜通線" ],
    [ "吹上駅", "愛知県", "名古屋市営地下鉄桜通線" ],
    [ "御器所駅", "愛知県", "名古屋市営地下鉄桜通線" ],
    [ "桜山駅", "愛知県", "名古屋市営地下鉄桜通線" ],
    [ "瑞穂区役所駅", "愛知県", "名古屋市営地下鉄桜通線" ],
    [ "瑞穂運動場西駅", "愛知県", "名古屋市営地下鉄桜通線" ],
    [ "新瑞橋駅", "愛知県", "名古屋市営地下鉄桜通線" ],
    [ "桜本町駅", "愛知県", "名古屋市営地下鉄桜通線" ],
    [ "鶴里駅", "愛知県", "名古屋市営地下鉄桜通線" ],
    [ "野並駅", "愛知県", "名古屋市営地下鉄桜通線" ],
    [ "鳴子北駅", "愛知県", "名古屋市営地下鉄桜通線" ],
    [ "相生山駅", "愛知県", "名古屋市営地下鉄桜通線" ],
    [ "神沢駅", "愛知県", "名古屋市営地下鉄桜通線" ],
    [ "徳重駅", "愛知県", "名古屋市営地下鉄桜通線" ],
    [ "平安通駅", "愛知県", "名古屋市営地下鉄上飯田線" ],
    [ "上飯田駅", "愛知県", "名古屋市営地下鉄上飯田線" ],
    [ "小牧駅", "愛知県", "ピーチライナー" ],
    [ "小牧原駅", "愛知県", "ピーチライナー" ],
    [ "東田中駅", "愛知県", "ピーチライナー" ],
    [ "上末駅", "愛知県", "ピーチライナー" ],
    [ "桃花台西駅", "愛知県", "ピーチライナー" ],
    [ "桃花台センター駅", "愛知県", "ピーチライナー" ],
    [ "桃花台東駅", "愛知県", "ピーチライナー" ],
    [ "新豊橋駅", "愛知県", "豊橋鉄道渥美線" ],
    [ "柳生橋駅", "愛知県", "豊橋鉄道渥美線" ],
    [ "小池駅", "愛知県", "豊橋鉄道渥美線" ],
    [ "愛知大学前駅", "愛知県", "豊橋鉄道渥美線" ],
    [ "南栄駅", "愛知県", "豊橋鉄道渥美線" ],
    [ "高師駅", "愛知県", "豊橋鉄道渥美線" ],
    [ "芦原駅", "愛知県", "豊橋鉄道渥美線" ],
    [ "植田駅", "愛知県", "豊橋鉄道渥美線" ],
    [ "向ヶ丘駅", "愛知県", "豊橋鉄道渥美線" ],
    [ "大清水駅", "愛知県", "豊橋鉄道渥美線" ],
    [ "老津駅", "愛知県", "豊橋鉄道渥美線" ],
    [ "杉山駅", "愛知県", "豊橋鉄道渥美線" ],
    [ "やぐま台駅", "愛知県", "豊橋鉄道渥美線" ],
    [ "豊島駅", "愛知県", "豊橋鉄道渥美線" ],
    [ "神戸駅", "愛知県", "豊橋鉄道渥美線" ],
    [ "三河田原駅", "愛知県", "豊橋鉄道渥美線" ],
    [ "駅前駅", "愛知県", "豊橋鉄道東田本線" ],
    [ "駅前大通駅", "愛知県", "豊橋鉄道東田本線" ],
    [ "新川駅", "愛知県", "豊橋鉄道東田本線" ],
    [ "札木駅", "愛知県", "豊橋鉄道東田本線" ],
    [ "市役所前駅", "愛知県", "豊橋鉄道東田本線" ],
    [ "豊橋公園前駅", "愛知県", "豊橋鉄道東田本線" ],
    [ "東八町駅", "愛知県", "豊橋鉄道東田本線" ],
    [ "前畑駅", "愛知県", "豊橋鉄道東田本線" ],
    [ "東田坂上駅", "愛知県", "豊橋鉄道東田本線" ],
    [ "東田駅", "愛知県", "豊橋鉄道東田本線" ],
    [ "競輪場前駅", "愛知県", "豊橋鉄道東田本線" ],
    [ "井原駅", "愛知県", "豊橋鉄道東田本線" ],
    [ "赤岩口駅", "愛知県", "豊橋鉄道東田本線" ],
    [ "運動公園前駅", "愛知県", "豊橋鉄道東田本線" ],
    [ "井原駅", "愛知県", "豊橋鉄道運動公園前線" ],
    [ "運動公園前駅", "愛知県", "豊橋鉄道運動公園前線" ],
    [ "大曽根駅", "愛知県", "ゆとりーとライン" ],
    [ "ナゴヤドーム前矢田駅", "愛知県", "ゆとりーとライン" ],
    [ "砂田橋駅", "愛知県", "ゆとりーとライン" ],
    [ "守山駅", "愛知県", "ゆとりーとライン" ],
    [ "金屋駅", "愛知県", "ゆとりーとライン" ],
    [ "川宮駅", "愛知県", "ゆとりーとライン" ],
    [ "川村駅", "愛知県", "ゆとりーとライン" ],
    [ "白沢渓谷駅", "愛知県", "ゆとりーとライン" ],
    [ "小幡緑地駅", "愛知県", "ゆとりーとライン" ],
    [ "恵那駅", "岐阜県", "明知鉄道明知線" ],
    [ "東野駅", "岐阜県", "明知鉄道明知線" ],
    [ "飯沼駅", "岐阜県", "明知鉄道明知線" ],
    [ "阿木駅", "岐阜県", "明知鉄道明知線" ],
    [ "飯羽間駅", "岐阜県", "明知鉄道明知線" ],
    [ "極楽駅", "岐阜県", "明知鉄道明知線" ],
    [ "岩村駅", "岐阜県", "明知鉄道明知線" ],
    [ "花白温泉駅", "岐阜県", "明知鉄道明知線" ],
    [ "山岡駅", "岐阜県", "明知鉄道明知線" ],
    [ "野志駅", "岐阜県", "明知鉄道明知線" ],
    [ "明智駅", "岐阜県", "明知鉄道明知線" ],
    [ "美濃太田駅", "岐阜県", "長良川鉄道越美南線" ],
    [ "前平公園駅", "岐阜県", "長良川鉄道越美南線" ],
    [ "加茂野駅", "岐阜県", "長良川鉄道越美南線" ],
    [ "富加駅", "岐阜県", "長良川鉄道越美南線" ],
    [ "関富岡駅", "岐阜県", "長良川鉄道越美南線" ],
    [ "関口駅", "岐阜県", "長良川鉄道越美南線" ],
    [ "せきてらす前駅", "岐阜県", "長良川鉄道越美南線" ],
    [ "関駅", "岐阜県", "長良川鉄道越美南線" ],
    [ "関市役所前駅", "岐阜県", "長良川鉄道越美南線" ],
    [ "関下有知駅", "岐阜県", "長良川鉄道越美南線" ],
    [ "松森駅", "岐阜県", "長良川鉄道越美南線" ],
    [ "美濃市駅", "岐阜県", "長良川鉄道越美南線" ],
    [ "梅山駅", "岐阜県", "長良川鉄道越美南線" ],
    [ "湯の洞温泉口駅", "岐阜県", "長良川鉄道越美南線" ],
    [ "洲原駅", "岐阜県", "長良川鉄道越美南線" ],
    [ "母野駅", "岐阜県", "長良川鉄道越美南線" ],
    [ "木尾駅", "岐阜県", "長良川鉄道越美南線" ],
    [ "八坂駅", "岐阜県", "長良川鉄道越美南線" ],
    [ "みなみ子宝温泉駅", "岐阜県", "長良川鉄道越美南線" ],
    [ "大矢駅", "岐阜県", "長良川鉄道越美南線" ],
    [ "福野駅", "岐阜県", "長良川鉄道越美南線" ],
    [ "美並苅安駅", "岐阜県", "長良川鉄道越美南線" ],
    [ "赤池駅", "岐阜県", "長良川鉄道越美南線" ],
    [ "深戸駅", "岐阜県", "長良川鉄道越美南線" ],
    [ "相生駅", "岐阜県", "長良川鉄道越美南線" ],
    [ "郡上八幡駅", "岐阜県", "長良川鉄道越美南線" ],
    [ "自然園前駅", "岐阜県", "長良川鉄道越美南線" ],
    [ "山田駅", "岐阜県", "長良川鉄道越美南線" ],
    [ "徳永駅", "岐阜県", "長良川鉄道越美南線" ],
    [ "郡上大和駅", "岐阜県", "長良川鉄道越美南線" ],
    [ "万場駅", "岐阜県", "長良川鉄道越美南線" ],
    [ "上万場駅", "岐阜県", "長良川鉄道越美南線" ],
    [ "大中駅", "岐阜県", "長良川鉄道越美南線" ],
    [ "大島駅", "岐阜県", "長良川鉄道越美南線" ],
    [ "美濃白鳥駅", "岐阜県", "長良川鉄道越美南線" ],
    [ "白鳥高原駅", "岐阜県", "長良川鉄道越美南線" ],
    [ "白山長滝駅", "岐阜県", "長良川鉄道越美南線" ],
    [ "北濃駅", "岐阜県", "長良川鉄道越美南線" ],
    [ "大垣駅", "岐阜県", "樽見鉄道樽見線" ],
    [ "東大垣駅", "岐阜県", "樽見鉄道樽見線" ],
    [ "横屋駅", "岐阜県", "樽見鉄道樽見線" ],
    [ "十九条駅", "岐阜県", "樽見鉄道樽見線" ],
    [ "美江寺駅", "岐阜県", "樽見鉄道樽見線" ],
    [ "北方真桑駅", "岐阜県", "樽見鉄道樽見線" ],
    [ "モレラ岐阜駅", "岐阜県", "樽見鉄道樽見線" ],
    [ "糸貫駅", "岐阜県", "樽見鉄道樽見線" ],
    [ "本巣駅", "岐阜県", "樽見鉄道樽見線" ],
    [ "織部駅", "岐阜県", "樽見鉄道樽見線" ],
    [ "木知原駅", "岐阜県", "樽見鉄道樽見線" ],
    [ "谷汲口駅", "岐阜県", "樽見鉄道樽見線" ],
    [ "神海駅", "岐阜県", "樽見鉄道樽見線" ],
    [ "高科駅", "岐阜県", "樽見鉄道樽見線" ],
    [ "鍋原駅", "岐阜県", "樽見鉄道樽見線" ],
    [ "日当駅", "岐阜県", "樽見鉄道樽見線" ],
    [ "高尾駅", "岐阜県", "樽見鉄道樽見線" ],
    [ "水鳥駅", "岐阜県", "樽見鉄道樽見線" ],
    [ "樽見駅", "岐阜県", "樽見鉄道樽見線" ],
    [ "近鉄富田駅", "三重県", "三岐鉄道三岐線" ],
    [ "大矢知駅", "三重県", "三岐鉄道三岐線" ],
    [ "平津駅", "三重県", "三岐鉄道三岐線" ],
    [ "暁学園前駅", "三重県", "三岐鉄道三岐線" ],
    [ "山城駅", "三重県", "三岐鉄道三岐線" ],
    [ "保々駅", "三重県", "三岐鉄道三岐線" ],
    [ "北勢中央公園口駅", "三重県", "三岐鉄道三岐線" ],
    [ "梅戸井駅", "三重県", "三岐鉄道三岐線" ],
    [ "大安駅", "三重県", "三岐鉄道三岐線" ],
    [ "三里駅", "三重県", "三岐鉄道三岐線" ],
    [ "丹生川駅", "三重県", "三岐鉄道三岐線" ],
    [ "伊勢治田駅", "三重県", "三岐鉄道三岐線" ],
    [ "東藤原駅", "三重県", "三岐鉄道三岐線" ],
    [ "西野尻駅", "三重県", "三岐鉄道三岐線" ],
    [ "西藤原駅", "三重県", "三岐鉄道三岐線" ],
    [ "西桑名駅", "三重県", "三岐鉄道北勢線" ],
    [ "馬道駅", "三重県", "三岐鉄道北勢線" ],
    [ "西別所駅", "三重県", "三岐鉄道北勢線" ],
    [ "蓮花寺駅", "三重県", "三岐鉄道北勢線" ],
    [ "在良駅", "三重県", "三岐鉄道北勢線" ],
    [ "星川駅", "三重県", "三岐鉄道北勢線" ],
    [ "七和駅", "三重県", "三岐鉄道北勢線" ],
    [ "穴太駅", "三重県", "三岐鉄道北勢線" ],
    [ "東員駅", "三重県", "三岐鉄道北勢線" ],
    [ "大泉駅", "三重県", "三岐鉄道北勢線" ],
    [ "楚原駅", "三重県", "三岐鉄道北勢線" ],
    [ "麻生田駅", "三重県", "三岐鉄道北勢線" ],
    [ "阿下喜駅", "三重県", "三岐鉄道北勢線" ],
    [ "河原田駅", "三重県", "伊勢鉄道伊勢線" ],
    [ "鈴鹿駅", "三重県", "伊勢鉄道伊勢線" ],
    [ "玉垣駅", "三重県", "伊勢鉄道伊勢線" ],
    [ "鈴鹿サーキット稲生駅", "三重県", "伊勢鉄道伊勢線" ],
    [ "徳田駅", "三重県", "伊勢鉄道伊勢線" ],
    [ "中瀬古駅", "三重県", "伊勢鉄道伊勢線" ],
    [ "伊勢上野駅", "三重県", "伊勢鉄道伊勢線" ],
    [ "河芸駅", "三重県", "伊勢鉄道伊勢線" ],
    [ "東一身田駅", "三重県", "伊勢鉄道伊勢線" ],
    [ "津駅", "三重県", "伊勢鉄道伊勢線" ],
    [ "伊賀上野駅", "三重県", "伊賀鉄道伊賀線" ],
    [ "新居駅", "三重県", "伊賀鉄道伊賀線" ],
    [ "西大手駅", "三重県", "伊賀鉄道伊賀線" ],
    [ "上野市駅", "三重県", "伊賀鉄道伊賀線" ],
    [ "広小路駅", "三重県", "伊賀鉄道伊賀線" ],
    [ "茅町駅", "三重県", "伊賀鉄道伊賀線" ],
    [ "桑町駅", "三重県", "伊賀鉄道伊賀線" ],
    [ "四十九駅", "三重県", "伊賀鉄道伊賀線" ],
    [ "猪田道駅", "三重県", "伊賀鉄道伊賀線" ],
    [ "市部駅", "三重県", "伊賀鉄道伊賀線" ],
    [ "依那古駅", "三重県", "伊賀鉄道伊賀線" ],
    [ "丸山駅", "三重県", "伊賀鉄道伊賀線" ],
    [ "上林駅", "三重県", "伊賀鉄道伊賀線" ],
    [ "比土駅", "三重県", "伊賀鉄道伊賀線" ],
    [ "伊賀神戸駅", "三重県", "伊賀鉄道伊賀線" ],
    [ "桑名駅", "三重県", "養老鉄道養老線" ],
    [ "播磨駅", "三重県", "養老鉄道養老線" ],
    [ "下深谷駅", "三重県", "養老鉄道養老線" ],
    [ "下野代駅", "三重県", "養老鉄道養老線" ],
    [ "多度駅", "三重県", "養老鉄道養老線" ],
    [ "美濃松山駅", "岐阜県", "養老鉄道養老線" ],
    [ "石津駅", "岐阜県", "養老鉄道養老線" ],
    [ "美濃山崎駅", "岐阜県", "養老鉄道養老線" ],
    [ "駒野駅", "岐阜県", "養老鉄道養老線" ],
    [ "美濃津屋駅", "岐阜県", "養老鉄道養老線" ],
    [ "養老駅", "岐阜県", "養老鉄道養老線" ],
    [ "美濃高田駅", "岐阜県", "養老鉄道養老線" ],
    [ "烏江駅", "岐阜県", "養老鉄道養老線" ],
    [ "大外羽駅", "岐阜県", "養老鉄道養老線" ],
    [ "友江駅", "岐阜県", "養老鉄道養老線" ],
    [ "美濃青柳駅", "岐阜県", "養老鉄道養老線" ],
    [ "西大垣駅", "岐阜県", "養老鉄道養老線" ],
    [ "大垣駅", "岐阜県", "養老鉄道養老線" ],
    [ "室駅", "岐阜県", "養老鉄道養老線" ],
    [ "北大垣駅", "岐阜県", "養老鉄道養老線" ],
    [ "東赤坂駅", "岐阜県", "養老鉄道養老線" ],
    [ "広神戸駅", "岐阜県", "養老鉄道養老線" ],
    [ "北神戸駅", "岐阜県", "養老鉄道養老線" ],
    [ "池野駅", "岐阜県", "養老鉄道養老線" ],
    [ "北池野駅", "岐阜県", "養老鉄道養老線" ],
    [ "美濃本郷駅", "岐阜県", "養老鉄道養老線" ],
    [ "揖斐駅", "岐阜県", "養老鉄道養老線" ],
    [ "米原駅", "滋賀県", "近江鉄道本線" ],
    [ "フジテック前駅", "滋賀県", "近江鉄道本線" ],
    [ "鳥居本駅", "滋賀県", "近江鉄道本線" ],
    [ "彦根駅", "滋賀県", "近江鉄道本線" ],
    [ "ひこね芹川駅", "滋賀県", "近江鉄道本線" ],
    [ "彦根口駅", "滋賀県", "近江鉄道本線" ],
    [ "高宮駅", "滋賀県", "近江鉄道本線" ],
    [ "尼子駅", "滋賀県", "近江鉄道本線" ],
    [ "豊郷駅", "滋賀県", "近江鉄道本線" ],
    [ "愛知川駅", "滋賀県", "近江鉄道本線" ],
    [ "五箇荘駅", "滋賀県", "近江鉄道本線" ],
    [ "河辺の森駅", "滋賀県", "近江鉄道本線" ],
    [ "八日市駅", "滋賀県", "近江鉄道本線" ],
    [ "長谷野駅", "滋賀県", "近江鉄道本線" ],
    [ "大学前駅", "滋賀県", "近江鉄道本線" ],
    [ "京セラ前駅", "滋賀県", "近江鉄道本線" ],
    [ "桜川駅", "滋賀県", "近江鉄道本線" ],
    [ "朝日大塚駅", "滋賀県", "近江鉄道本線" ],
    [ "朝日野駅", "滋賀県", "近江鉄道本線" ],
    [ "日野駅", "滋賀県", "近江鉄道本線" ],
    [ "水口松尾駅", "滋賀県", "近江鉄道本線" ],
    [ "水口駅", "滋賀県", "近江鉄道本線" ],
    [ "水口石橋駅", "滋賀県", "近江鉄道本線" ],
    [ "水口城南駅", "滋賀県", "近江鉄道本線" ],
    [ "貴生川駅", "滋賀県", "近江鉄道本線" ],
    [ "高宮駅", "滋賀県", "近江鉄道多賀線" ],
    [ "スクリーン駅", "滋賀県", "近江鉄道多賀線" ],
    [ "多賀大社前駅", "滋賀県", "近江鉄道多賀線" ],
    [ "八日市駅", "滋賀県", "近江鉄道八日市線" ],
    [ "新八日市駅", "滋賀県", "近江鉄道八日市線" ],
    [ "太郎坊宮前駅", "滋賀県", "近江鉄道八日市線" ],
    [ "市辺駅", "滋賀県", "近江鉄道八日市線" ],
    [ "平田駅", "滋賀県", "近江鉄道八日市線" ],
    [ "武佐駅", "滋賀県", "近江鉄道八日市線" ],
    [ "近江八幡駅", "滋賀県", "近江鉄道八日市線" ],
    [ "貴生川駅", "滋賀県", "信楽高原鐵道信楽線" ],
    [ "紫香楽宮跡駅", "滋賀県", "信楽高原鐵道信楽線" ],
    [ "雲井駅", "滋賀県", "信楽高原鐵道信楽線" ],
    [ "勅旨駅", "滋賀県", "信楽高原鐵道信楽線" ],
    [ "玉桂寺前駅", "滋賀県", "信楽高原鐵道信楽線" ],
    [ "信楽駅", "滋賀県", "信楽高原鐵道信楽線" ],
    [ "トロッコ嵯峨駅", "京都府", "嵯峨野観光線" ],
    [ "トロッコ嵐山駅", "京都府", "嵯峨野観光線" ],
    [ "トロッコ保津峡駅", "京都府", "嵯峨野観光線" ],
    [ "トロッコ亀岡駅", "京都府", "嵯峨野観光線" ],
    [ "出町柳駅", "京都府", "叡山電鉄叡山本線" ],
    [ "元田中駅", "京都府", "叡山電鉄叡山本線" ],
    [ "茶山駅", "京都府", "叡山電鉄叡山本線" ],
    [ "一乗寺駅", "京都府", "叡山電鉄叡山本線" ],
    [ "修学院駅", "京都府", "叡山電鉄叡山本線" ],
    [ "宝ヶ池駅", "京都府", "叡山電鉄叡山本線" ],
    [ "三宅八幡駅", "京都府", "叡山電鉄叡山本線" ],
    [ "八瀬比叡山口駅", "京都府", "叡山電鉄叡山本線" ],
    [ "宝ヶ池駅", "京都府", "叡山電鉄鞍馬線" ],
    [ "八幡前駅", "京都府", "叡山電鉄鞍馬線" ],
    [ "岩倉駅", "京都府", "叡山電鉄鞍馬線" ],
    [ "木野駅", "京都府", "叡山電鉄鞍馬線" ],
    [ "京都精華大前駅", "京都府", "叡山電鉄鞍馬線" ],
    [ "二軒茶屋駅", "京都府", "叡山電鉄鞍馬線" ],
    [ "市原駅", "京都府", "叡山電鉄鞍馬線" ],
    [ "二ノ瀬駅", "京都府", "叡山電鉄鞍馬線" ],
    [ "貴船口駅", "京都府", "叡山電鉄鞍馬線" ],
    [ "鞍馬駅", "京都府", "叡山電鉄鞍馬線" ],
    [ "福知山駅", "京都府", "宮福線" ],
    [ "福知山市民病院口駅", "京都府", "宮福線" ],
    [ "荒河かしの木台駅", "京都府", "宮福線" ],
    [ "牧駅", "京都府", "宮福線" ],
    [ "下天津駅", "京都府", "宮福線" ],
    [ "公庄駅", "京都府", "宮福線" ],
    [ "大江駅", "京都府", "宮福線" ],
    [ "大江高校前駅", "京都府", "宮福線" ],
    [ "二俣駅", "京都府", "宮福線" ],
    [ "大江山口内宮駅", "京都府", "宮福線" ],
    [ "辛皮駅", "京都府", "宮福線" ],
    [ "喜多駅", "京都府", "宮福線" ],
    [ "宮村駅", "京都府", "宮福線" ],
    [ "宮津駅", "京都府", "宮福線" ],
    [ "宮津駅", "京都府", "宮豊線" ],
    [ "天橋立駅", "京都府", "宮豊線" ],
    [ "岩滝口駅", "京都府", "宮豊線" ],
    [ "与謝野駅", "京都府", "宮豊線" ],
    [ "京丹後大宮駅", "京都府", "宮豊線" ],
    [ "峰山駅", "京都府", "宮豊線" ],
    [ "網野駅", "京都府", "宮豊線" ],
    [ "夕日ヶ浦木津温泉駅", "京都府", "宮豊線" ],
    [ "小天橋駅", "京都府", "宮豊線" ],
    [ "かぶと山駅", "京都府", "宮豊線" ],
    [ "久美浜駅", "京都府", "宮豊線" ],
    [ "コウノトリの郷駅", "兵庫県", "宮豊線" ],
    [ "豊岡駅", "兵庫県", "宮豊線" ],
    [ "国際会館駅", "京都府", "京都市営地下鉄烏丸線" ],
    [ "松ヶ崎駅", "京都府", "京都市営地下鉄烏丸線" ],
    [ "北山駅", "京都府", "京都市営地下鉄烏丸線" ],
    [ "北大路駅", "京都府", "京都市営地下鉄烏丸線" ],
    [ "鞍馬口駅", "京都府", "京都市営地下鉄烏丸線" ],
    [ "今出川駅", "京都府", "京都市営地下鉄烏丸線" ],
    [ "丸太町駅", "京都府", "京都市営地下鉄烏丸線" ],
    [ "烏丸御池駅", "京都府", "京都市営地下鉄烏丸線" ],
    [ "四条駅", "京都府", "京都市営地下鉄烏丸線" ],
    [ "五条駅", "京都府", "京都市営地下鉄烏丸線" ],
    [ "京都駅", "京都府", "京都市営地下鉄烏丸線" ],
    [ "九条駅", "京都府", "京都市営地下鉄烏丸線" ],
    [ "十条駅", "京都府", "京都市営地下鉄烏丸線" ],
    [ "くいな橋駅", "京都府", "京都市営地下鉄烏丸線" ],
    [ "竹田駅", "京都府", "京都市営地下鉄烏丸線" ],
    [ "六地蔵駅", "京都府", "京都市営地下鉄東西線" ],
    [ "石田駅", "京都府", "京都市営地下鉄東西線" ],
    [ "醍醐駅", "京都府", "京都市営地下鉄東西線" ],
    [ "小野駅", "京都府", "京都市営地下鉄東西線" ],
    [ "椥辻駅", "京都府", "京都市営地下鉄東西線" ],
    [ "東野駅", "京都府", "京都市営地下鉄東西線" ],
    [ "山科駅", "京都府", "京都市営地下鉄東西線" ],
    [ "御陵駅", "京都府", "京都市営地下鉄東西線" ],
    [ "蹴上駅", "京都府", "京都市営地下鉄東西線" ],
    [ "東山駅", "京都府", "京都市営地下鉄東西線" ],
    [ "三条京阪駅", "京都府", "京都市営地下鉄東西線" ],
    [ "京都市役所前駅", "京都府", "京都市営地下鉄東西線" ],
    [ "烏丸御池駅", "京都府", "京都市営地下鉄東西線" ],
    [ "二条城前駅", "京都府", "京都市営地下鉄東西線" ],
    [ "二条駅", "京都府", "京都市営地下鉄東西線" ],
    [ "西大路御池駅", "京都府", "京都市営地下鉄東西線" ],
    [ "太秦天神川駅", "京都府", "京都市営地下鉄東西線" ],
    [ "四条大宮駅", "京都府", "京福電鉄嵐山本線" ],
    [ "西院駅", "京都府", "京福電鉄嵐山本線" ],
    [ "西大路三条駅", "京都府", "京福電鉄嵐山本線" ],
    [ "山ノ内駅", "京都府", "京福電鉄嵐山本線" ],
    [ "嵐電天神川駅", "京都府", "京福電鉄嵐山本線" ],
    [ "蚕ノ社駅", "京都府", "京福電鉄嵐山本線" ],
    [ "太秦広隆寺駅", "京都府", "京福電鉄嵐山本線" ],
    [ "帷子ノ辻駅", "京都府", "京福電鉄嵐山本線" ],
    [ "有栖川駅", "京都府", "京福電鉄嵐山本線" ],
    [ "車折神社駅", "京都府", "京福電鉄嵐山本線" ],
    [ "鹿王院駅", "京都府", "京福電鉄嵐山本線" ],
    [ "嵐電嵯峨駅", "京都府", "京福電鉄嵐山本線" ],
    [ "嵐山駅", "京都府", "京福電鉄嵐山本線" ],
    [ "帷子ノ辻駅", "京都府", "京福電鉄北野線" ],
    [ "撮影所前駅", "京都府", "京福電鉄北野線" ],
    [ "常盤駅", "京都府", "京福電鉄北野線" ],
    [ "鳴滝駅", "京都府", "京福電鉄北野線" ],
    [ "宇多野駅", "京都府", "京福電鉄北野線" ],
    [ "御室仁和寺駅", "京都府", "京福電鉄北野線" ],
    [ "妙心寺駅", "京都府", "京福電鉄北野線" ],
    [ "龍安寺駅", "京都府", "京福電鉄北野線" ],
    [ "等持院・立命館大学衣笠キャンパス前駅", "京都府", "京福電鉄北野線" ],
    [ "北野白梅町駅", "京都府", "京福電鉄北野線" ],
    [ "千里中央駅", "大阪府", "北大阪急行電鉄" ],
    [ "桃山台駅", "大阪府", "北大阪急行電鉄" ],
    [ "緑地公園駅", "大阪府", "北大阪急行電鉄" ],
    [ "江坂駅", "大阪府", "北大阪急行電鉄" ],
    [ "川西能勢口駅", "兵庫県", "能勢電鉄妙見線" ],
    [ "絹延橋駅", "兵庫県", "能勢電鉄妙見線" ],
    [ "滝山駅", "兵庫県", "能勢電鉄妙見線" ],
    [ "鶯の森駅", "兵庫県", "能勢電鉄妙見線" ],
    [ "鼓滝駅", "兵庫県", "能勢電鉄妙見線" ],
    [ "多田駅", "兵庫県", "能勢電鉄妙見線" ],
    [ "平野駅", "兵庫県", "能勢電鉄妙見線" ],
    [ "一の鳥居駅", "兵庫県", "能勢電鉄妙見線" ],
    [ "畦野駅", "兵庫県", "能勢電鉄妙見線" ],
    [ "山下駅", "兵庫県", "能勢電鉄妙見線" ],
    [ "笹部駅", "兵庫県", "能勢電鉄妙見線" ],
    [ "光風台駅", "大阪府", "能勢電鉄妙見線" ],
    [ "ときわ台駅", "大阪府", "能勢電鉄妙見線" ],
    [ "妙見口駅", "大阪府", "能勢電鉄妙見線" ],
    [ "中百舌鳥駅", "大阪府", "泉北高速鉄道線" ],
    [ "深井駅", "大阪府", "泉北高速鉄道線" ],
    [ "泉ヶ丘駅", "大阪府", "泉北高速鉄道線" ],
    [ "栂・美木多駅", "大阪府", "泉北高速鉄道線" ],
    [ "光明池駅", "大阪府", "泉北高速鉄道線" ],
    [ "和泉中央駅", "大阪府", "泉北高速鉄道線" ],
    [ "貝塚駅", "大阪府", "水間鉄道水間線" ],
    [ "貝塚市役所前駅", "大阪府", "水間鉄道水間線" ],
    [ "近義の里駅", "大阪府", "水間鉄道水間線" ],
    [ "石才駅", "大阪府", "水間鉄道水間線" ],
    [ "清児駅", "大阪府", "水間鉄道水間線" ],
    [ "名越駅", "大阪府", "水間鉄道水間線" ],
    [ "森駅", "大阪府", "水間鉄道水間線" ],
    [ "三ツ松駅", "大阪府", "水間鉄道水間線" ],
    [ "三ヶ山口駅", "大阪府", "水間鉄道水間線" ],
    [ "水間観音駅", "大阪府", "水間鉄道水間線" ],
    [ "江坂駅", "大阪府", "大阪メトロ御堂筋線" ],
    [ "東三国駅", "大阪府", "大阪メトロ御堂筋線" ],
    [ "新大阪駅", "大阪府", "大阪メトロ御堂筋線" ],
    [ "西中島南方駅", "大阪府", "大阪メトロ御堂筋線" ],
    [ "中津駅", "大阪府", "大阪メトロ御堂筋線" ],
    [ "梅田駅", "大阪府", "大阪メトロ御堂筋線" ],
    [ "淀屋橋駅", "大阪府", "大阪メトロ御堂筋線" ],
    [ "本町駅", "大阪府", "大阪メトロ御堂筋線" ],
    [ "心斎橋駅", "大阪府", "大阪メトロ御堂筋線" ],
    [ "なんば駅", "大阪府", "大阪メトロ御堂筋線" ],
    [ "大国町駅", "大阪府", "大阪メトロ御堂筋線" ],
    [ "動物園前駅", "大阪府", "大阪メトロ御堂筋線" ],
    [ "天王寺駅", "大阪府", "大阪メトロ御堂筋線" ],
    [ "昭和町駅", "大阪府", "大阪メトロ御堂筋線" ],
    [ "西田辺駅", "大阪府", "大阪メトロ御堂筋線" ],
    [ "長居駅", "大阪府", "大阪メトロ御堂筋線" ],
    [ "あびこ駅", "大阪府", "大阪メトロ御堂筋線" ],
    [ "北花田駅", "大阪府", "大阪メトロ御堂筋線" ],
    [ "新金岡駅", "大阪府", "大阪メトロ御堂筋線" ],
    [ "なかもず駅", "大阪府", "大阪メトロ御堂筋線" ],
    [ "大日駅", "大阪府", "大阪メトロ谷町線" ],
    [ "守口駅", "大阪府", "大阪メトロ谷町線" ],
    [ "太子橋今市駅", "大阪府", "大阪メトロ谷町線" ],
    [ "千林大宮駅", "大阪府", "大阪メトロ谷町線" ],
    [ "関目高殿駅", "大阪府", "大阪メトロ谷町線" ],
    [ "野江内代駅", "大阪府", "大阪メトロ谷町線" ],
    [ "都島駅", "大阪府", "大阪メトロ谷町線" ],
    [ "天神橋筋六丁目駅", "大阪府", "大阪メトロ谷町線" ],
    [ "中崎町駅", "大阪府", "大阪メトロ谷町線" ],
    [ "東梅田駅", "大阪府", "大阪メトロ谷町線" ],
    [ "南森町駅", "大阪府", "大阪メトロ谷町線" ],
    [ "天満橋駅", "大阪府", "大阪メトロ谷町線" ],
    [ "谷町四丁目駅", "大阪府", "大阪メトロ谷町線" ],
    [ "谷町六丁目駅", "大阪府", "大阪メトロ谷町線" ],
    [ "谷町九丁目駅", "大阪府", "大阪メトロ谷町線" ],
    [ "四天王寺前夕陽ヶ丘駅", "大阪府", "大阪メトロ谷町線" ],
    [ "天王寺駅", "大阪府", "大阪メトロ谷町線" ],
    [ "阿倍野駅", "大阪府", "大阪メトロ谷町線" ],
    [ "文の里駅", "大阪府", "大阪メトロ谷町線" ],
    [ "田辺駅", "大阪府", "大阪メトロ谷町線" ],
    [ "駒川中野駅", "大阪府", "大阪メトロ谷町線" ],
    [ "平野駅", "大阪府", "大阪メトロ谷町線" ],
    [ "喜連瓜破駅", "大阪府", "大阪メトロ谷町線" ],
    [ "出戸駅", "大阪府", "大阪メトロ谷町線" ],
    [ "長原駅", "大阪府", "大阪メトロ谷町線" ],
    [ "八尾南駅", "大阪府", "大阪メトロ谷町線" ],
    [ "西梅田駅", "大阪府", "大阪メトロ四つ橋線" ],
    [ "肥後橋駅", "大阪府", "大阪メトロ四つ橋線" ],
    [ "本町駅", "大阪府", "大阪メトロ四つ橋線" ],
    [ "四ツ橋駅", "大阪府", "大阪メトロ四つ橋線" ],
    [ "なんば駅", "大阪府", "大阪メトロ四つ橋線" ],
    [ "大国町駅", "大阪府", "大阪メトロ四つ橋線" ],
    [ "花園町駅", "大阪府", "大阪メトロ四つ橋線" ],
    [ "岸里駅", "大阪府", "大阪メトロ四つ橋線" ],
    [ "玉出駅", "大阪府", "大阪メトロ四つ橋線" ],
    [ "北加賀屋駅", "大阪府", "大阪メトロ四つ橋線" ],
    [ "住之江公園駅", "大阪府", "大阪メトロ四つ橋線" ],
    [ "コスモスクエア駅", "大阪府", "大阪メトロ中央線" ],
    [ "大阪港駅", "大阪府", "大阪メトロ中央線" ],
    [ "朝潮橋駅", "大阪府", "大阪メトロ中央線" ],
    [ "弁天町駅", "大阪府", "大阪メトロ中央線" ],
    [ "九条駅", "大阪府", "大阪メトロ中央線" ],
    [ "阿波座駅", "大阪府", "大阪メトロ中央線" ],
    [ "本町駅", "大阪府", "大阪メトロ中央線" ],
    [ "堺筋本町駅", "大阪府", "大阪メトロ中央線" ],
    [ "谷町四丁目駅", "大阪府", "大阪メトロ中央線" ],
    [ "森ノ宮駅", "大阪府", "大阪メトロ中央線" ],
    [ "緑橋駅", "大阪府", "大阪メトロ中央線" ],
    [ "深江橋駅", "大阪府", "大阪メトロ中央線" ],
    [ "高井田駅", "大阪府", "大阪メトロ中央線" ],
    [ "長田駅", "大阪府", "大阪メトロ中央線" ],
    [ "野田阪神駅", "大阪府", "大阪メトロ千日前線" ],
    [ "玉川駅", "大阪府", "大阪メトロ千日前線" ],
    [ "阿波座駅", "大阪府", "大阪メトロ千日前線" ],
    [ "西長堀駅", "大阪府", "大阪メトロ千日前線" ],
    [ "桜川駅", "大阪府", "大阪メトロ千日前線" ],
    [ "なんば駅", "大阪府", "大阪メトロ千日前線" ],
    [ "日本橋駅", "大阪府", "大阪メトロ千日前線" ],
    [ "谷町九丁目駅", "大阪府", "大阪メトロ千日前線" ],
    [ "鶴橋駅", "大阪府", "大阪メトロ千日前線" ],
    [ "今里駅", "大阪府", "大阪メトロ千日前線" ],
    [ "新深江駅", "大阪府", "大阪メトロ千日前線" ],
    [ "小路駅", "大阪府", "大阪メトロ千日前線" ],
    [ "北巽駅", "大阪府", "大阪メトロ千日前線" ],
    [ "南巽駅", "大阪府", "大阪メトロ千日前線" ],
    [ "天神橋筋六丁目駅", "大阪府", "大阪メトロ堺筋線" ],
    [ "扇町駅", "大阪府", "大阪メトロ堺筋線" ],
    [ "南森町駅", "大阪府", "大阪メトロ堺筋線" ],
    [ "北浜駅", "大阪府", "大阪メトロ堺筋線" ],
    [ "堺筋本町駅", "大阪府", "大阪メトロ堺筋線" ],
    [ "長堀橋駅", "大阪府", "大阪メトロ堺筋線" ],
    [ "日本橋駅", "大阪府", "大阪メトロ堺筋線" ],
    [ "恵美須町駅", "大阪府", "大阪メトロ堺筋線" ],
    [ "動物園前駅", "大阪府", "大阪メトロ堺筋線" ],
    [ "天下茶屋駅", "大阪府", "大阪メトロ堺筋線" ],
    [ "大正駅", "大阪府", "大阪メトロ長堀鶴見緑地線" ],
    [ "ドーム前千代崎駅", "大阪府", "大阪メトロ長堀鶴見緑地線" ],
    [ "西長堀駅", "大阪府", "大阪メトロ長堀鶴見緑地線" ],
    [ "西大橋駅", "大阪府", "大阪メトロ長堀鶴見緑地線" ],
    [ "心斎橋駅", "大阪府", "大阪メトロ長堀鶴見緑地線" ],
    [ "長堀橋駅", "大阪府", "大阪メトロ長堀鶴見緑地線" ],
    [ "松屋町駅", "大阪府", "大阪メトロ長堀鶴見緑地線" ],
    [ "谷町六丁目駅", "大阪府", "大阪メトロ長堀鶴見緑地線" ],
    [ "玉造駅", "大阪府", "大阪メトロ長堀鶴見緑地線" ],
    [ "森ノ宮駅", "大阪府", "大阪メトロ長堀鶴見緑地線" ],
    [ "大阪ビジネスパーク駅", "大阪府", "大阪メトロ長堀鶴見緑地線" ],
    [ "京橋駅", "大阪府", "大阪メトロ長堀鶴見緑地線" ],
    [ "蒲生四丁目駅", "大阪府", "大阪メトロ長堀鶴見緑地線" ],
    [ "今福鶴見駅", "大阪府", "大阪メトロ長堀鶴見緑地線" ],
    [ "横堤駅", "大阪府", "大阪メトロ長堀鶴見緑地線" ],
    [ "鶴見緑地駅", "大阪府", "大阪メトロ長堀鶴見緑地線" ],
    [ "門真南駅", "大阪府", "大阪メトロ長堀鶴見緑地線" ],
    [ "コスモスクエア駅", "大阪府", "大阪メトロ南港ポートタウン線" ],
    [ "トレードセンター前駅", "大阪府", "大阪メトロ南港ポートタウン線" ],
    [ "中ふ頭駅", "大阪府", "大阪メトロ南港ポートタウン線" ],
    [ "ポートタウン西駅", "大阪府", "大阪メトロ南港ポートタウン線" ],
    [ "ポートタウン東駅", "大阪府", "大阪メトロ南港ポートタウン線" ],
    [ "フェリーターミナル駅", "大阪府", "大阪メトロ南港ポートタウン線" ],
    [ "南港東駅", "大阪府", "大阪メトロ南港ポートタウン線" ],
    [ "南港口駅", "大阪府", "大阪メトロ南港ポートタウン線" ],
    [ "平林駅", "大阪府", "大阪メトロ南港ポートタウン線" ],
    [ "住之江公園駅", "大阪府", "大阪メトロ南港ポートタウン線" ],
    [ "大阪空港駅", "大阪府", "大阪モノレール線" ],
    [ "蛍池駅", "大阪府", "大阪モノレール線" ],
    [ "柴原阪大前駅", "大阪府", "大阪モノレール線" ],
    [ "少路駅", "大阪府", "大阪モノレール線" ],
    [ "千里中央駅", "大阪府", "大阪モノレール線" ],
    [ "山田駅", "大阪府", "大阪モノレール線" ],
    [ "万博記念公園駅", "大阪府", "大阪モノレール線" ],
    [ "宇野辺駅", "大阪府", "大阪モノレール線" ],
    [ "南茨木駅", "大阪府", "大阪モノレール線" ],
    [ "沢良宜駅", "大阪府", "大阪モノレール線" ],
    [ "摂津駅", "大阪府", "大阪モノレール線" ],
    [ "南摂津駅", "大阪府", "大阪モノレール線" ],
    [ "大日駅", "大阪府", "大阪モノレール線" ],
    [ "門真市駅", "大阪府", "大阪モノレール線" ],
    [ "万博記念公園駅", "大阪府", "大阪モノレール彩都線" ],
    [ "公園東口駅", "大阪府", "大阪モノレール彩都線" ],
    [ "阪大病院前駅", "大阪府", "大阪モノレール彩都線" ],
    [ "豊川駅", "大阪府", "大阪モノレール彩都線" ],
    [ "彩都西駅", "大阪府", "大阪モノレール彩都線" ],
    [ "天王寺駅前駅", "大阪府", "阪堺電軌上町線" ],
    [ "阿倍野駅", "大阪府", "阪堺電軌上町線" ],
    [ "松虫駅", "大阪府", "阪堺電軌上町線" ],
    [ "東天下茶屋駅", "大阪府", "阪堺電軌上町線" ],
    [ "北畠駅", "大阪府", "阪堺電軌上町線" ],
    [ "姫松駅", "大阪府", "阪堺電軌上町線" ],
    [ "帝塚山三丁目駅", "大阪府", "阪堺電軌上町線" ],
    [ "帝塚山四丁目駅", "大阪府", "阪堺電軌上町線" ],
    [ "神ノ木駅", "大阪府", "阪堺電軌上町線" ],
    [ "住吉駅", "大阪府", "阪堺電軌上町線" ],
    [ "住吉公園駅", "大阪府", "阪堺電軌上町線" ],
    [ "恵美須町駅", "大阪府", "阪堺電軌阪堺線" ],
    [ "新今宮駅前駅", "大阪府", "阪堺電軌阪堺線" ],
    [ "今池駅", "大阪府", "阪堺電軌阪堺線" ],
    [ "今船駅", "大阪府", "阪堺電軌阪堺線" ],
    [ "松田町駅", "大阪府", "阪堺電軌阪堺線" ],
    [ "北天下茶屋駅", "大阪府", "阪堺電軌阪堺線" ],
    [ "聖天坂駅", "大阪府", "阪堺電軌阪堺線" ],
    [ "天神ノ森駅", "大阪府", "阪堺電軌阪堺線" ],
    [ "東玉出駅", "大阪府", "阪堺電軌阪堺線" ],
    [ "塚西駅", "大阪府", "阪堺電軌阪堺線" ],
    [ "東粉浜駅", "大阪府", "阪堺電軌阪堺線" ],
    [ "住吉駅", "大阪府", "阪堺電軌阪堺線" ],
    [ "住吉鳥居前駅", "大阪府", "阪堺電軌阪堺線" ],
    [ "細井川駅", "大阪府", "阪堺電軌阪堺線" ],
    [ "安立町駅", "大阪府", "阪堺電軌阪堺線" ],
    [ "我孫子道駅", "大阪府", "阪堺電軌阪堺線" ],
    [ "大和川駅", "大阪府", "阪堺電軌阪堺線" ],
    [ "高須神社駅", "大阪府", "阪堺電軌阪堺線" ],
    [ "綾ノ町駅", "大阪府", "阪堺電軌阪堺線" ],
    [ "神明町駅", "大阪府", "阪堺電軌阪堺線" ],
    [ "妙国寺前駅", "大阪府", "阪堺電軌阪堺線" ],
    [ "花田口駅", "大阪府", "阪堺電軌阪堺線" ],
    [ "大小路駅", "大阪府", "阪堺電軌阪堺線" ],
    [ "宿院駅", "大阪府", "阪堺電軌阪堺線" ],
    [ "寺地町駅", "大阪府", "阪堺電軌阪堺線" ],
    [ "御陵前駅", "大阪府", "阪堺電軌阪堺線" ],
    [ "東湊駅", "大阪府", "阪堺電軌阪堺線" ],
    [ "石津北駅", "大阪府", "阪堺電軌阪堺線" ],
    [ "石津駅", "大阪府", "阪堺電軌阪堺線" ],
    [ "船尾駅", "大阪府", "阪堺電軌阪堺線" ],
    [ "浜寺駅前駅", "大阪府", "阪堺電軌阪堺線" ],
    [ "三宮駅", "兵庫県", "神戸高速東西線" ],
    [ "花隈駅", "兵庫県", "神戸高速東西線" ],
    [ "元町駅", "兵庫県", "神戸高速東西線" ],
    [ "西元町駅", "兵庫県", "神戸高速東西線" ],
    [ "高速神戸駅", "兵庫県", "神戸高速東西線" ],
    [ "新開地駅", "兵庫県", "神戸高速東西線" ],
    [ "大開駅", "兵庫県", "神戸高速東西線" ],
    [ "高速長田駅", "兵庫県", "神戸高速東西線" ],
    [ "西代駅", "兵庫県", "神戸高速東西線" ],
    [ "新開地駅", "兵庫県", "神戸高速南北線" ],
    [ "湊川駅", "兵庫県", "神戸高速南北線" ],
    [ "湊川駅", "兵庫県", "有馬線" ],
    [ "長田駅", "兵庫県", "有馬線" ],
    [ "丸山駅", "兵庫県", "有馬線" ],
    [ "鵯越駅", "兵庫県", "有馬線" ],
    [ "菊水山駅", "兵庫県", "有馬線" ],
    [ "鈴蘭台駅", "兵庫県", "有馬線" ],
    [ "北鈴蘭台駅", "兵庫県", "有馬線" ],
    [ "山の街駅", "兵庫県", "有馬線" ],
    [ "箕谷駅", "兵庫県", "有馬線" ],
    [ "谷上駅", "兵庫県", "有馬線" ],
    [ "花山駅", "兵庫県", "有馬線" ],
    [ "大池駅", "兵庫県", "有馬線" ],
    [ "神鉄六甲駅", "兵庫県", "有馬線" ],
    [ "唐櫃台駅", "兵庫県", "有馬線" ],
    [ "有馬口駅", "兵庫県", "有馬線" ],
    [ "新有馬駅", "兵庫県", "有馬線" ],
    [ "有馬温泉駅", "兵庫県", "有馬線" ],
    [ "有馬口駅", "兵庫県", "三田線" ],
    [ "五社駅", "兵庫県", "三田線" ],
    [ "岡場駅", "兵庫県", "三田線" ],
    [ "田尾寺駅", "兵庫県", "三田線" ],
    [ "二郎駅", "兵庫県", "三田線" ],
    [ "道場南口駅", "兵庫県", "三田線" ],
    [ "神鉄道場駅", "兵庫県", "三田線" ],
    [ "横山駅", "兵庫県", "三田線" ],
    [ "三田本町駅", "兵庫県", "三田線" ],
    [ "三田駅", "兵庫県", "三田線" ],
    [ "三田駅", "兵庫県", "公園都市線" ],
    [ "三田本町駅", "兵庫県", "公園都市線" ],
    [ "横山駅", "兵庫県", "公園都市線" ],
    [ "フラワータウン駅", "兵庫県", "公園都市線" ],
    [ "南ウッディタウン駅", "兵庫県", "公園都市線" ],
    [ "ウッディタウン中央駅", "兵庫県", "公園都市線" ],
    [ "鈴蘭台駅", "兵庫県", "粟生線" ],
    [ "鈴蘭台西口駅", "兵庫県", "粟生線" ],
    [ "西鈴蘭台駅", "兵庫県", "粟生線" ],
    [ "藍那駅", "兵庫県", "粟生線" ],
    [ "木津駅", "兵庫県", "粟生線" ],
    [ "木幡駅", "兵庫県", "粟生線" ],
    [ "栄駅", "兵庫県", "粟生線" ],
    [ "押部谷駅", "兵庫県", "粟生線" ],
    [ "緑が丘駅", "兵庫県", "粟生線" ],
    [ "広野ゴルフ場前駅", "兵庫県", "粟生線" ],
    [ "志染駅", "兵庫県", "粟生線" ],
    [ "恵比須駅", "兵庫県", "粟生線" ],
    [ "三木上の丸駅", "兵庫県", "粟生線" ],
    [ "三木駅", "兵庫県", "粟生線" ],
    [ "大村駅", "兵庫県", "粟生線" ],
    [ "樫山駅", "兵庫県", "粟生線" ],
    [ "市場駅", "兵庫県", "粟生線" ],
    [ "小野駅", "兵庫県", "粟生線" ],
    [ "葉多駅", "兵庫県", "粟生線" ],
    [ "粟生駅", "兵庫県", "粟生線" ],
    [ "新神戸駅", "兵庫県", "北神線" ],
    [ "谷上駅", "兵庫県", "北神線" ],
    [ "西代駅", "兵庫県", "山陽電鉄本線" ],
    [ "板宿駅", "兵庫県", "山陽電鉄本線" ],
    [ "東須磨駅", "兵庫県", "山陽電鉄本線" ],
    [ "月見山駅", "兵庫県", "山陽電鉄本線" ],
    [ "須磨寺駅", "兵庫県", "山陽電鉄本線" ],
    [ "山陽須磨駅", "兵庫県", "山陽電鉄本線" ],
    [ "須磨浦公園駅", "兵庫県", "山陽電鉄本線" ],
    [ "山陽塩屋駅", "兵庫県", "山陽電鉄本線" ],
    [ "滝の茶屋駅", "兵庫県", "山陽電鉄本線" ],
    [ "東垂水駅", "兵庫県", "山陽電鉄本線" ],
    [ "山陽垂水駅", "兵庫県", "山陽電鉄本線" ],
    [ "霞ヶ丘駅", "兵庫県", "山陽電鉄本線" ],
    [ "舞子公園駅", "兵庫県", "山陽電鉄本線" ],
    [ "西舞子駅", "兵庫県", "山陽電鉄本線" ],
    [ "大蔵谷駅", "兵庫県", "山陽電鉄本線" ],
    [ "人丸前駅", "兵庫県", "山陽電鉄本線" ],
    [ "山陽明石駅", "兵庫県", "山陽電鉄本線" ],
    [ "西新町駅", "兵庫県", "山陽電鉄本線" ],
    [ "林崎松江海岸駅", "兵庫県", "山陽電鉄本線" ],
    [ "藤江駅", "兵庫県", "山陽電鉄本線" ],
    [ "中八木駅", "兵庫県", "山陽電鉄本線" ],
    [ "江井ヶ島駅", "兵庫県", "山陽電鉄本線" ],
    [ "西江井ヶ島駅", "兵庫県", "山陽電鉄本線" ],
    [ "山陽魚住駅", "兵庫県", "山陽電鉄本線" ],
    [ "東二見駅", "兵庫県", "山陽電鉄本線" ],
    [ "西二見駅", "兵庫県", "山陽電鉄本線" ],
    [ "播磨町駅", "兵庫県", "山陽電鉄本線" ],
    [ "別府駅", "兵庫県", "山陽電鉄本線" ],
    [ "浜の宮駅", "兵庫県", "山陽電鉄本線" ],
    [ "尾上の松駅", "兵庫県", "山陽電鉄本線" ],
    [ "高砂駅", "兵庫県", "山陽電鉄本線" ],
    [ "荒井駅", "兵庫県", "山陽電鉄本線" ],
    [ "伊保駅", "兵庫県", "山陽電鉄本線" ],
    [ "山陽曽根駅", "兵庫県", "山陽電鉄本線" ],
    [ "大塩駅", "兵庫県", "山陽電鉄本線" ],
    [ "的形駅", "兵庫県", "山陽電鉄本線" ],
    [ "八家駅", "兵庫県", "山陽電鉄本線" ],
    [ "白浜の宮駅", "兵庫県", "山陽電鉄本線" ],
    [ "妻鹿駅", "兵庫県", "山陽電鉄本線" ],
    [ "飾磨駅", "兵庫県", "山陽電鉄本線" ],
    [ "亀山駅", "兵庫県", "山陽電鉄本線" ],
    [ "手柄駅", "兵庫県", "山陽電鉄本線" ],
    [ "山陽姫路駅", "兵庫県", "山陽電鉄本線" ],
    [ "飾磨駅", "兵庫県", "山陽電鉄網干線" ],
    [ "西飾磨駅", "兵庫県", "山陽電鉄網干線" ],
    [ "夢前川駅", "兵庫県", "山陽電鉄網干線" ],
    [ "広畑駅", "兵庫県", "山陽電鉄網干線" ],
    [ "山陽天満駅", "兵庫県", "山陽電鉄網干線" ],
    [ "平松駅", "兵庫県", "山陽電鉄網干線" ],
    [ "山陽網干駅", "兵庫県", "山陽電鉄網干線" ],
    [ "山下駅", "兵庫県", "能勢電鉄日生線" ],
    [ "日生中央駅", "兵庫県", "能勢電鉄日生線" ],
    [ "厄神駅", "兵庫県", "三木鉄道三木線" ],
    [ "国包駅", "兵庫県", "三木鉄道三木線" ],
    [ "宗佐駅", "兵庫県", "三木鉄道三木線" ],
    [ "下石野駅", "兵庫県", "三木鉄道三木線" ],
    [ "石野駅", "兵庫県", "三木鉄道三木線" ],
    [ "西這田駅", "兵庫県", "三木鉄道三木線" ],
    [ "別所駅", "兵庫県", "三木鉄道三木線" ],
    [ "高木駅", "兵庫県", "三木鉄道三木線" ],
    [ "三木駅", "兵庫県", "三木鉄道三木線" ],
    [ "粟生駅", "兵庫県", "北条鉄道北条線" ],
    [ "網引駅", "兵庫県", "北条鉄道北条線" ],
    [ "田原駅", "兵庫県", "北条鉄道北条線" ],
    [ "法華口駅", "兵庫県", "北条鉄道北条線" ],
    [ "播磨下里駅", "兵庫県", "北条鉄道北条線" ],
    [ "長駅", "兵庫県", "北条鉄道北条線" ],
    [ "播磨横田駅", "兵庫県", "北条鉄道北条線" ],
    [ "北条町駅", "兵庫県", "北条鉄道北条線" ],
    [ "上郡駅", "兵庫県", "智頭急行智頭線" ],
    [ "苔縄駅", "兵庫県", "智頭急行智頭線" ],
    [ "河野原円心駅", "兵庫県", "智頭急行智頭線" ],
    [ "久崎駅", "兵庫県", "智頭急行智頭線" ],
    [ "佐用駅", "兵庫県", "智頭急行智頭線" ],
    [ "平福駅", "兵庫県", "智頭急行智頭線" ],
    [ "石井駅", "兵庫県", "智頭急行智頭線" ],
    [ "宮本武蔵駅", "岡山県", "智頭急行智頭線" ],
    [ "大原駅", "岡山県", "智頭急行智頭線" ],
    [ "西粟倉駅", "岡山県", "智頭急行智頭線" ],
    [ "あわくら温泉駅", "岡山県", "智頭急行智頭線" ],
    [ "山郷駅", "鳥取県", "智頭急行智頭線" ],
    [ "恋山形駅", "鳥取県", "智頭急行智頭線" ],
    [ "智頭駅", "鳥取県", "智頭急行智頭線" ],
    [ "新長田駅", "兵庫県", "神戸市営地下鉄西神線" ],
    [ "板宿駅", "兵庫県", "神戸市営地下鉄西神線" ],
    [ "妙法寺駅", "兵庫県", "神戸市営地下鉄西神線" ],
    [ "名谷駅", "兵庫県", "神戸市営地下鉄西神線" ],
    [ "総合運動公園駅", "兵庫県", "神戸市営地下鉄西神線" ],
    [ "学園都市駅", "兵庫県", "神戸市営地下鉄西神線" ],
    [ "伊川谷駅", "兵庫県", "神戸市営地下鉄西神線" ],
    [ "西神南駅", "兵庫県", "神戸市営地下鉄西神線" ],
    [ "西神中央駅", "兵庫県", "神戸市営地下鉄西神線" ],
    [ "新神戸駅", "兵庫県", "神戸市営地下鉄山手線" ],
    [ "三宮駅", "兵庫県", "神戸市営地下鉄山手線" ],
    [ "県庁前駅", "兵庫県", "神戸市営地下鉄山手線" ],
    [ "大倉山駅", "兵庫県", "神戸市営地下鉄山手線" ],
    [ "湊川公園駅", "兵庫県", "神戸市営地下鉄山手線" ],
    [ "上沢駅", "兵庫県", "神戸市営地下鉄山手線" ],
    [ "長田駅", "兵庫県", "神戸市営地下鉄山手線" ],
    [ "新長田駅", "兵庫県", "神戸市営地下鉄山手線" ],
    [ "新長田駅", "兵庫県", "夢かもめ" ],
    [ "駒ヶ林駅", "兵庫県", "夢かもめ" ],
    [ "苅藻駅", "兵庫県", "夢かもめ" ],
    [ "御崎公園駅", "兵庫県", "夢かもめ" ],
    [ "和田岬駅", "兵庫県", "夢かもめ" ],
    [ "中央市場前駅", "兵庫県", "夢かもめ" ],
    [ "ハーバーランド駅", "兵庫県", "夢かもめ" ],
    [ "みなと元町駅", "兵庫県", "夢かもめ" ],
    [ "旧居留地・大丸前駅", "兵庫県", "夢かもめ" ],
    [ "三宮・花時計前駅", "兵庫県", "夢かもめ" ],
    [ "三宮駅", "兵庫県", "ポートライナー" ],
    [ "貿易センター駅", "兵庫県", "ポートライナー" ],
    [ "ポートターミナル駅", "兵庫県", "ポートライナー" ],
    [ "中公園駅", "兵庫県", "ポートライナー" ],
    [ "みなとじま駅", "兵庫県", "ポートライナー" ],
    [ "市民広場駅", "兵庫県", "ポートライナー" ],
    [ "医療センター駅", "兵庫県", "ポートライナー" ],
    [ "計算科学センター（神戸どうぶつ王国・「富岳」前）駅", "兵庫県", "ポートライナー" ],
    [ "神戸空港駅", "兵庫県", "ポートライナー" ],
    [ "南公園駅", "兵庫県", "ポートライナー" ],
    [ "中埠頭駅", "兵庫県", "ポートライナー" ],
    [ "北埠頭駅", "兵庫県", "ポートライナー" ],
    [ "住吉駅", "兵庫県", "六甲ライナー" ],
    [ "魚崎駅", "兵庫県", "六甲ライナー" ],
    [ "南魚崎駅", "兵庫県", "六甲ライナー" ],
    [ "アイランド北口駅", "兵庫県", "六甲ライナー" ],
    [ "アイランドセンター駅", "兵庫県", "六甲ライナー" ],
    [ "マリンパーク駅", "兵庫県", "六甲ライナー" ],
    [ "御坊駅", "和歌山県", "紀州鉄道線" ],
    [ "学門駅", "和歌山県", "紀州鉄道線" ],
    [ "紀伊御坊駅", "和歌山県", "紀州鉄道線" ],
    [ "市役所前駅", "和歌山県", "紀州鉄道線" ],
    [ "西御坊駅", "和歌山県", "紀州鉄道線" ],
    [ "和歌山駅", "和歌山県", "貴志川線" ],
    [ "田中口駅", "和歌山県", "貴志川線" ],
    [ "日前宮駅", "和歌山県", "貴志川線" ],
    [ "神前駅", "和歌山県", "貴志川線" ],
    [ "竈山駅", "和歌山県", "貴志川線" ],
    [ "交通センター前駅", "和歌山県", "貴志川線" ],
    [ "岡崎前駅", "和歌山県", "貴志川線" ],
    [ "吉礼駅", "和歌山県", "貴志川線" ],
    [ "伊太祈曽駅", "和歌山県", "貴志川線" ],
    [ "山東駅", "和歌山県", "貴志川線" ],
    [ "大池遊園駅", "和歌山県", "貴志川線" ],
    [ "西山口駅", "和歌山県", "貴志川線" ],
    [ "甘露寺前駅", "和歌山県", "貴志川線" ],
    [ "貴志駅", "和歌山県", "貴志川線" ],
    [ "井高野駅", "大阪府", "大阪メトロ今里筋線" ],
    [ "瑞光四丁目駅", "大阪府", "大阪メトロ今里筋線" ],
    [ "だいどう豊里駅", "大阪府", "大阪メトロ今里筋線" ],
    [ "太子橋今市駅", "大阪府", "大阪メトロ今里筋線" ],
    [ "清水駅", "大阪府", "大阪メトロ今里筋線" ],
    [ "新森古市駅", "大阪府", "大阪メトロ今里筋線" ],
    [ "関目成育駅", "大阪府", "大阪メトロ今里筋線" ],
    [ "蒲生四丁目駅", "大阪府", "大阪メトロ今里筋線" ],
    [ "鴫野駅", "大阪府", "大阪メトロ今里筋線" ],
    [ "緑橋駅", "大阪府", "大阪メトロ今里筋線" ],
    [ "今里駅", "大阪府", "大阪メトロ今里筋線" ],
    [ "宮津駅", "京都府", "宮舞線" ],
    [ "栗田駅", "京都府", "宮舞線" ],
    [ "丹後由良駅", "京都府", "宮舞線" ],
    [ "丹後神崎駅", "京都府", "宮舞線" ],
    [ "東雲駅", "京都府", "宮舞線" ],
    [ "四所駅", "京都府", "宮舞線" ],
    [ "西舞鶴駅", "京都府", "宮舞線" ],
    [ "郡家駅", "鳥取県", "若桜線" ],
    [ "八頭高校前駅", "鳥取県", "若桜線" ],
    [ "因幡船岡駅", "鳥取県", "若桜線" ],
    [ "隼駅", "鳥取県", "若桜線" ],
    [ "安部駅", "鳥取県", "若桜線" ],
    [ "八東駅", "鳥取県", "若桜線" ],
    [ "徳丸駅", "鳥取県", "若桜線" ],
    [ "丹比駅", "鳥取県", "若桜線" ],
    [ "若桜駅", "鳥取県", "若桜線" ],
    [ "電鉄出雲市駅", "島根県", "北松江線" ],
    [ "出雲科学館パークタウン前駅", "島根県", "北松江線" ],
    [ "大津町駅", "島根県", "北松江線" ],
    [ "武志駅", "島根県", "北松江線" ],
    [ "川跡駅", "島根県", "北松江線" ],
    [ "大寺駅", "島根県", "北松江線" ],
    [ "美談駅", "島根県", "北松江線" ],
    [ "旅伏駅", "島根県", "北松江線" ],
    [ "雲州平田駅", "島根県", "北松江線" ],
    [ "布崎駅", "島根県", "北松江線" ],
    [ "湖遊館新駅", "島根県", "北松江線" ],
    [ "園駅", "島根県", "北松江線" ],
    [ "一畑口駅", "島根県", "北松江線" ],
    [ "伊野灘駅", "島根県", "北松江線" ],
    [ "津ノ森駅", "島根県", "北松江線" ],
    [ "高ノ宮駅", "島根県", "北松江線" ],
    [ "松江フォーゲルパーク駅", "島根県", "北松江線" ],
    [ "秋鹿町駅", "島根県", "北松江線" ],
    [ "長江駅", "島根県", "北松江線" ],
    [ "朝日ヶ丘駅", "島根県", "北松江線" ],
    [ "松江イングリッシュガーデン前駅", "島根県", "北松江線" ],
    [ "松江しんじ湖温泉駅", "島根県", "北松江線" ],
    [ "川跡駅", "島根県", "大社線" ],
    [ "高浜駅", "島根県", "大社線" ],
    [ "遙堪駅", "島根県", "大社線" ],
    [ "浜山公園北口駅", "島根県", "大社線" ],
    [ "出雲大社前駅", "島根県", "大社線" ],
    [ "倉敷市駅", "岡山県", "水島本線" ],
    [ "球場前駅", "岡山県", "水島本線" ],
    [ "西富井駅", "岡山県", "水島本線" ],
    [ "福井駅", "岡山県", "水島本線" ],
    [ "浦田駅", "岡山県", "水島本線" ],
    [ "弥生駅", "岡山県", "水島本線" ],
    [ "栄駅", "岡山県", "水島本線" ],
    [ "常盤駅", "岡山県", "水島本線" ],
    [ "水島駅", "岡山県", "水島本線" ],
    [ "三菱自工前駅", "岡山県", "水島本線" ],
    [ "総社駅", "岡山県", "井原線" ],
    [ "清音駅", "岡山県", "井原線" ],
    [ "川辺宿駅", "岡山県", "井原線" ],
    [ "吉備真備駅", "岡山県", "井原線" ],
    [ "備中呉妹駅", "岡山県", "井原線" ],
    [ "三谷駅", "岡山県", "井原線" ],
    [ "矢掛駅", "岡山県", "井原線" ],
    [ "小田駅", "岡山県", "井原線" ],
    [ "早雲の里荏原駅", "岡山県", "井原線" ],
    [ "井原駅", "岡山県", "井原線" ],
    [ "いずえ駅", "岡山県", "井原線" ],
    [ "子守唄の里高屋駅", "岡山県", "井原線" ],
    [ "御領駅", "広島県", "井原線" ],
    [ "湯野駅", "広島県", "井原線" ],
    [ "神辺駅", "広島県", "井原線" ],
    [ "岡山駅前駅", "岡山県", "東山線" ],
    [ "西川緑道公園駅", "岡山県", "東山線" ],
    [ "柳川駅", "岡山県", "東山線" ],
    [ "城下駅", "岡山県", "東山線" ],
    [ "県庁通り駅", "岡山県", "東山線" ],
    [ "西大寺町駅", "岡山県", "東山線" ],
    [ "小橋駅", "岡山県", "東山線" ],
    [ "中納言駅", "岡山県", "東山線" ],
    [ "門田屋敷駅", "岡山県", "東山線" ],
    [ "東山駅", "岡山県", "東山線" ],
    [ "岡山駅前駅", "岡山県", "清輝橋線" ],
    [ "西川緑道公園駅", "岡山県", "清輝橋線" ],
    [ "柳川駅", "岡山県", "清輝橋線" ],
    [ "郵便局前駅", "岡山県", "清輝橋線" ],
    [ "田町駅", "岡山県", "清輝橋線" ],
    [ "新西大寺町筋駅", "岡山県", "清輝橋線" ],
    [ "大雲寺前駅", "岡山県", "清輝橋線" ],
    [ "東中央町駅", "岡山県", "清輝橋線" ],
    [ "清輝橋駅", "岡山県", "清輝橋線" ],
    [ "みどり口駅", "広島県", "スカイレールみどり坂線" ],
    [ "みどり中街駅", "広島県", "スカイレールみどり坂線" ],
    [ "みどり中央駅", "広島県", "スカイレールみどり坂線" ],
    [ "本通駅", "広島県", "アストラムライン" ],
    [ "県庁前駅", "広島県", "アストラムライン" ],
    [ "城北駅", "広島県", "アストラムライン" ],
    [ "新白島駅", "広島県", "アストラムライン" ],
    [ "白島駅", "広島県", "アストラムライン" ],
    [ "牛田駅", "広島県", "アストラムライン" ],
    [ "不動院前駅", "広島県", "アストラムライン" ],
    [ "祇園新橋北駅", "広島県", "アストラムライン" ],
    [ "西原駅", "広島県", "アストラムライン" ],
    [ "中筋駅", "広島県", "アストラムライン" ],
    [ "古市駅", "広島県", "アストラムライン" ],
    [ "大町駅", "広島県", "アストラムライン" ],
    [ "毘沙門台駅", "広島県", "アストラムライン" ],
    [ "安東駅", "広島県", "アストラムライン" ],
    [ "上安駅", "広島県", "アストラムライン" ],
    [ "高取駅", "広島県", "アストラムライン" ],
    [ "長楽寺駅", "広島県", "アストラムライン" ],
    [ "伴駅", "広島県", "アストラムライン" ],
    [ "大原駅", "広島県", "アストラムライン" ],
    [ "伴中央駅", "広島県", "アストラムライン" ],
    [ "大塚駅", "広島県", "アストラムライン" ],
    [ "広域公園前駅", "広島県", "アストラムライン" ],
    [ "広島駅", "広島県", "広電１号線" ],
    [ "猿猴橋町駅", "広島県", "広電１号線" ],
    [ "的場町駅", "広島県", "広電１号線" ],
    [ "稲荷町駅", "広島県", "広電１号線" ],
    [ "銀山町駅", "広島県", "広電１号線" ],
    [ "胡町駅", "広島県", "広電１号線" ],
    [ "八丁堀駅", "広島県", "広電１号線" ],
    [ "立町駅", "広島県", "広電１号線" ],
    [ "紙屋町東駅", "広島県", "広電１号線" ],
    [ "本通駅", "広島県", "広電１号線" ],
    [ "袋町駅", "広島県", "広電１号線" ],
    [ "中電前駅", "広島県", "広電１号線" ],
    [ "市役所前駅", "広島県", "広電１号線" ],
    [ "鷹野橋駅", "広島県", "広電１号線" ],
    [ "日赤病院前駅", "広島県", "広電１号線" ],
    [ "広電本社前駅", "広島県", "広電１号線" ],
    [ "御幸橋駅", "広島県", "広電１号線" ],
    [ "皆実町六丁目駅", "広島県", "広電１号線" ],
    [ "広大附属学校前駅", "広島県", "広電１号線" ],
    [ "県病院前駅", "広島県", "広電１号線" ],
    [ "宇品二丁目駅", "広島県", "広電１号線" ],
    [ "宇品三丁目駅", "広島県", "広電１号線" ],
    [ "宇品四丁目駅", "広島県", "広電１号線" ],
    [ "宇品五丁目駅", "広島県", "広電１号線" ],
    [ "海岸通駅", "広島県", "広電１号線" ],
    [ "元宇品口駅", "広島県", "広電１号線" ],
    [ "広島港（宇品）駅", "広島県", "広電１号線" ],
    [ "広島駅", "広島県", "広電２号線" ],
    [ "猿猴橋町駅", "広島県", "広電２号線" ],
    [ "的場町駅", "広島県", "広電２号線" ],
    [ "稲荷町駅", "広島県", "広電２号線" ],
    [ "銀山町駅", "広島県", "広電２号線" ],
    [ "胡町駅", "広島県", "広電２号線" ],
    [ "八丁堀駅", "広島県", "広電２号線" ],
    [ "立町駅", "広島県", "広電２号線" ],
    [ "紙屋町東駅", "広島県", "広電２号線" ],
    [ "紙屋町西駅", "広島県", "広電２号線" ],
    [ "原爆ドーム前駅", "広島県", "広電２号線" ],
    [ "本川町駅", "広島県", "広電２号線" ],
    [ "十日市町駅", "広島県", "広電２号線" ],
    [ "土橋駅", "広島県", "広電２号線" ],
    [ "小網町駅", "広島県", "広電２号線" ],
    [ "天満町駅", "広島県", "広電２号線" ],
    [ "観音町駅", "広島県", "広電２号線" ],
    [ "西観音町駅", "広島県", "広電２号線" ],
    [ "福島町駅", "広島県", "広電２号線" ],
    [ "広電西広島（己斐）駅", "広島県", "広電２号線" ],
    [ "東高須駅", "広島県", "広電２号線" ],
    [ "高須駅", "広島県", "広電２号線" ],
    [ "古江駅", "広島県", "広電２号線" ],
    [ "草津駅", "広島県", "広電２号線" ],
    [ "草津南駅", "広島県", "広電２号線" ],
    [ "商工センター入口駅", "広島県", "広電２号線" ],
    [ "井口駅", "広島県", "広電２号線" ],
    [ "修大協創中高前駅", "広島県", "広電２号線" ],
    [ "広電五日市駅", "広島県", "広電２号線" ],
    [ "佐伯区役所前駅", "広島県", "広電２号線" ],
    [ "楽々園駅", "広島県", "広電２号線" ],
    [ "山陽女学園前駅", "広島県", "広電２号線" ],
    [ "広電廿日市駅", "広島県", "広電２号線" ],
    [ "廿日市市役所前（平良）駅", "広島県", "広電２号線" ],
    [ "宮内駅", "広島県", "広電２号線" ],
    [ "ＪＡ広島病院前駅", "広島県", "広電２号線" ],
    [ "地御前駅", "広島県", "広電２号線" ],
    [ "阿品東駅", "広島県", "広電２号線" ],
    [ "広電阿品駅", "広島県", "広電２号線" ],
    [ "宮島ボートレース場駅", "広島県", "広電２号線" ],
    [ "広電宮島口駅", "広島県", "広電２号線" ],
    [ "広島港（宇品）駅", "広島県", "広電３号線" ],
    [ "元宇品口駅", "広島県", "広電３号線" ],
    [ "海岸通駅", "広島県", "広電３号線" ],
    [ "宇品五丁目駅", "広島県", "広電３号線" ],
    [ "宇品四丁目駅", "広島県", "広電３号線" ],
    [ "宇品三丁目駅", "広島県", "広電３号線" ],
    [ "宇品二丁目駅", "広島県", "広電３号線" ],
    [ "県病院前駅", "広島県", "広電３号線" ],
    [ "広大附属学校前駅", "広島県", "広電３号線" ],
    [ "皆実町六丁目駅", "広島県", "広電３号線" ],
    [ "御幸橋駅", "広島県", "広電３号線" ],
    [ "広電本社前駅", "広島県", "広電３号線" ],
    [ "日赤病院前駅", "広島県", "広電３号線" ],
    [ "鷹野橋駅", "広島県", "広電３号線" ],
    [ "市役所前駅", "広島県", "広電３号線" ],
    [ "中電前駅", "広島県", "広電３号線" ],
    [ "袋町駅", "広島県", "広電３号線" ],
    [ "本通駅", "広島県", "広電３号線" ],
    [ "紙屋町西駅", "広島県", "広電３号線" ],
    [ "原爆ドーム前駅", "広島県", "広電３号線" ],
    [ "本川町駅", "広島県", "広電３号線" ],
    [ "十日市町駅", "広島県", "広電３号線" ],
    [ "土橋駅", "広島県", "広電３号線" ],
    [ "小網町駅", "広島県", "広電３号線" ],
    [ "天満町駅", "広島県", "広電３号線" ],
    [ "観音町駅", "広島県", "広電３号線" ],
    [ "西観音町駅", "広島県", "広電３号線" ],
    [ "福島町駅", "広島県", "広電３号線" ],
    [ "広電西広島（己斐）駅", "広島県", "広電３号線" ],
    [ "広島駅", "広島県", "広電５号線" ],
    [ "猿猴橋町駅", "広島県", "広電５号線" ],
    [ "的場町駅", "広島県", "広電５号線" ],
    [ "段原一丁目駅", "広島県", "広電５号線" ],
    [ "比治山下駅", "広島県", "広電５号線" ],
    [ "比治山橋駅", "広島県", "広電５号線" ],
    [ "南区役所前駅", "広島県", "広電５号線" ],
    [ "皆実町二丁目駅", "広島県", "広電５号線" ],
    [ "皆実町六丁目駅", "広島県", "広電５号線" ],
    [ "広大附属学校前駅", "広島県", "広電５号線" ],
    [ "県病院前駅", "広島県", "広電５号線" ],
    [ "宇品二丁目駅", "広島県", "広電５号線" ],
    [ "宇品三丁目駅", "広島県", "広電５号線" ],
    [ "宇品四丁目駅", "広島県", "広電５号線" ],
    [ "宇品五丁目駅", "広島県", "広電５号線" ],
    [ "海岸通駅", "広島県", "広電５号線" ],
    [ "元宇品口駅", "広島県", "広電５号線" ],
    [ "広島港（宇品）駅", "広島県", "広電５号線" ],
    [ "広島駅", "広島県", "広電６号線" ],
    [ "猿猴橋町駅", "広島県", "広電６号線" ],
    [ "的場町駅", "広島県", "広電６号線" ],
    [ "稲荷町駅", "広島県", "広電６号線" ],
    [ "銀山町駅", "広島県", "広電６号線" ],
    [ "胡町駅", "広島県", "広電６号線" ],
    [ "八丁堀駅", "広島県", "広電６号線" ],
    [ "立町駅", "広島県", "広電６号線" ],
    [ "紙屋町東駅", "広島県", "広電６号線" ],
    [ "紙屋町西駅", "広島県", "広電６号線" ],
    [ "原爆ドーム前駅", "広島県", "広電６号線" ],
    [ "本川町駅", "広島県", "広電６号線" ],
    [ "十日市町駅", "広島県", "広電６号線" ],
    [ "土橋駅", "広島県", "広電６号線" ],
    [ "舟入町駅", "広島県", "広電６号線" ],
    [ "舟入本町駅", "広島県", "広電６号線" ],
    [ "舟入幸町駅", "広島県", "広電６号線" ],
    [ "舟入川口町駅", "広島県", "広電６号線" ],
    [ "舟入南駅", "広島県", "広電６号線" ],
    [ "江波駅", "広島県", "広電６号線" ],
    [ "横川駅", "広島県", "広電７号線" ],
    [ "横川一丁目駅", "広島県", "広電７号線" ],
    [ "別院前駅", "広島県", "広電７号線" ],
    [ "寺町駅", "広島県", "広電７号線" ],
    [ "十日市町駅", "広島県", "広電７号線" ],
    [ "本川町駅", "広島県", "広電７号線" ],
    [ "原爆ドーム前駅", "広島県", "広電７号線" ],
    [ "紙屋町西駅", "広島県", "広電７号線" ],
    [ "本通駅", "広島県", "広電７号線" ],
    [ "袋町駅", "広島県", "広電７号線" ],
    [ "中電前駅", "広島県", "広電７号線" ],
    [ "市役所前駅", "広島県", "広電７号線" ],
    [ "鷹野橋駅", "広島県", "広電７号線" ],
    [ "日赤病院前駅", "広島県", "広電７号線" ],
    [ "広電本社前駅", "広島県", "広電７号線" ],
    [ "横川駅", "広島県", "広電８号線" ],
    [ "横川一丁目駅", "広島県", "広電８号線" ],
    [ "別院前駅", "広島県", "広電８号線" ],
    [ "寺町駅", "広島県", "広電８号線" ],
    [ "十日市町駅", "広島県", "広電８号線" ],
    [ "土橋駅", "広島県", "広電８号線" ],
    [ "舟入町駅", "広島県", "広電８号線" ],
    [ "舟入本町駅", "広島県", "広電８号線" ],
    [ "舟入幸町駅", "広島県", "広電８号線" ],
    [ "舟入川口町駅", "広島県", "広電８号線" ],
    [ "舟入南駅", "広島県", "広電８号線" ],
    [ "江波駅", "広島県", "広電８号線" ],
    [ "八丁堀駅", "広島県", "広電９号線" ],
    [ "女学院前駅", "広島県", "広電９号線" ],
    [ "縮景園前駅", "広島県", "広電９号線" ],
    [ "家庭裁判所前駅", "広島県", "広電９号線" ],
    [ "白島駅", "広島県", "広電９号線" ],
    [ "川西駅", "山口県", "錦川清流線" ],
    [ "清流新岩国駅", "山口県", "錦川清流線" ],
    [ "守内かさ神駅", "山口県", "錦川清流線" ],
    [ "南河内駅", "山口県", "錦川清流線" ],
    [ "行波駅", "山口県", "錦川清流線" ],
    [ "北河内駅", "山口県", "錦川清流線" ],
    [ "椋野駅", "山口県", "錦川清流線" ],
    [ "南桑駅", "山口県", "錦川清流線" ],
    [ "清流みはらし駅", "山口県", "錦川清流線" ],
    [ "根笠駅", "山口県", "錦川清流線" ],
    [ "河山駅", "山口県", "錦川清流線" ],
    [ "柳瀬駅", "山口県", "錦川清流線" ],
    [ "錦町駅", "山口県", "錦川清流線" ],
    [ "阿波海南駅", "徳島県", "阿波室戸シーサイドライン" ],
    [ "海部駅", "徳島県", "阿波室戸シーサイドライン" ],
    [ "宍喰駅", "徳島県", "阿波室戸シーサイドライン" ],
    [ "甲浦駅", "高知県", "阿波室戸シーサイドライン" ],
    [ "高松築港駅", "香川県", "琴電琴平線" ],
    [ "片原町駅", "香川県", "琴電琴平線" ],
    [ "瓦町駅", "香川県", "琴電琴平線" ],
    [ "栗林公園駅", "香川県", "琴電琴平線" ],
    [ "三条駅", "香川県", "琴電琴平線" ],
    [ "伏石駅", "香川県", "琴電琴平線" ],
    [ "太田駅", "香川県", "琴電琴平線" ],
    [ "仏生山駅", "香川県", "琴電琴平線" ],
    [ "空港通り駅", "香川県", "琴電琴平線" ],
    [ "一宮駅", "香川県", "琴電琴平線" ],
    [ "円座駅", "香川県", "琴電琴平線" ],
    [ "岡本駅", "香川県", "琴電琴平線" ],
    [ "挿頭丘駅", "香川県", "琴電琴平線" ],
    [ "畑田駅", "香川県", "琴電琴平線" ],
    [ "陶駅", "香川県", "琴電琴平線" ],
    [ "綾川（イオンモール綾川）駅", "香川県", "琴電琴平線" ],
    [ "滝宮駅", "香川県", "琴電琴平線" ],
    [ "羽床駅", "香川県", "琴電琴平線" ],
    [ "栗熊駅", "香川県", "琴電琴平線" ],
    [ "岡田駅", "香川県", "琴電琴平線" ],
    [ "羽間駅", "香川県", "琴電琴平線" ],
    [ "榎井駅", "香川県", "琴電琴平線" ],
    [ "琴電琴平駅", "香川県", "琴電琴平線" ],
    [ "高松築港駅", "香川県", "琴電長尾線" ],
    [ "片原町駅", "香川県", "琴電長尾線" ],
    [ "瓦町駅", "香川県", "琴電長尾線" ],
    [ "花園駅", "香川県", "琴電長尾線" ],
    [ "林道駅", "香川県", "琴電長尾線" ],
    [ "木太東口駅", "香川県", "琴電長尾線" ],
    [ "元山駅", "香川県", "琴電長尾線" ],
    [ "水田駅", "香川県", "琴電長尾線" ],
    [ "西前田駅", "香川県", "琴電長尾線" ],
    [ "高田駅", "香川県", "琴電長尾線" ],
    [ "池戸駅", "香川県", "琴電長尾線" ],
    [ "農学部前駅", "香川県", "琴電長尾線" ],
    [ "平木駅", "香川県", "琴電長尾線" ],
    [ "学園通り駅", "香川県", "琴電長尾線" ],
    [ "白山駅", "香川県", "琴電長尾線" ],
    [ "井戸駅", "香川県", "琴電長尾線" ],
    [ "公文明駅", "香川県", "琴電長尾線" ],
    [ "長尾駅", "香川県", "琴電長尾線" ],
    [ "瓦町駅", "香川県", "琴電志度線" ],
    [ "今橋駅", "香川県", "琴電志度線" ],
    [ "松島二丁目駅", "香川県", "琴電志度線" ],
    [ "沖松島駅", "香川県", "琴電志度線" ],
    [ "春日川駅", "香川県", "琴電志度線" ],
    [ "潟元駅", "香川県", "琴電志度線" ],
    [ "琴電屋島駅", "香川県", "琴電志度線" ],
    [ "古高松駅", "香川県", "琴電志度線" ],
    [ "八栗駅", "香川県", "琴電志度線" ],
    [ "六万寺駅", "香川県", "琴電志度線" ],
    [ "大町駅", "香川県", "琴電志度線" ],
    [ "八栗新道駅", "香川県", "琴電志度線" ],
    [ "塩屋駅", "香川県", "琴電志度線" ],
    [ "房前駅", "香川県", "琴電志度線" ],
    [ "原駅", "香川県", "琴電志度線" ],
    [ "琴電志度駅", "香川県", "琴電志度線" ],
    [ "松山市駅", "愛媛県", "伊予鉄道郡中線" ],
    [ "土橋駅", "愛媛県", "伊予鉄道郡中線" ],
    [ "土居田駅", "愛媛県", "伊予鉄道郡中線" ],
    [ "余戸駅", "愛媛県", "伊予鉄道郡中線" ],
    [ "鎌田駅", "愛媛県", "伊予鉄道郡中線" ],
    [ "岡田駅", "愛媛県", "伊予鉄道郡中線" ],
    [ "古泉駅", "愛媛県", "伊予鉄道郡中線" ],
    [ "松前駅", "愛媛県", "伊予鉄道郡中線" ],
    [ "地蔵町駅", "愛媛県", "伊予鉄道郡中線" ],
    [ "新川駅", "愛媛県", "伊予鉄道郡中線" ],
    [ "郡中駅", "愛媛県", "伊予鉄道郡中線" ],
    [ "郡中港駅", "愛媛県", "伊予鉄道郡中線" ],
    [ "高浜駅", "愛媛県", "伊予鉄道高浜線" ],
    [ "梅津寺駅", "愛媛県", "伊予鉄道高浜線" ],
    [ "港山駅", "愛媛県", "伊予鉄道高浜線" ],
    [ "三津駅", "愛媛県", "伊予鉄道高浜線" ],
    [ "山西駅", "愛媛県", "伊予鉄道高浜線" ],
    [ "西衣山駅", "愛媛県", "伊予鉄道高浜線" ],
    [ "衣山駅", "愛媛県", "伊予鉄道高浜線" ],
    [ "古町駅", "愛媛県", "伊予鉄道高浜線" ],
    [ "大手町駅", "愛媛県", "伊予鉄道高浜線" ],
    [ "松山市駅", "愛媛県", "伊予鉄道高浜線" ],
    [ "松山市駅", "愛媛県", "伊予鉄道横河原線" ],
    [ "石手川公園駅", "愛媛県", "伊予鉄道横河原線" ],
    [ "いよ立花駅", "愛媛県", "伊予鉄道横河原線" ],
    [ "福音寺駅", "愛媛県", "伊予鉄道横河原線" ],
    [ "北久米駅", "愛媛県", "伊予鉄道横河原線" ],
    [ "久米駅", "愛媛県", "伊予鉄道横河原線" ],
    [ "鷹ノ子駅", "愛媛県", "伊予鉄道横河原線" ],
    [ "平井駅", "愛媛県", "伊予鉄道横河原線" ],
    [ "梅本駅", "愛媛県", "伊予鉄道横河原線" ],
    [ "牛渕団地前駅", "愛媛県", "伊予鉄道横河原線" ],
    [ "牛渕駅", "愛媛県", "伊予鉄道横河原線" ],
    [ "田窪駅", "愛媛県", "伊予鉄道横河原線" ],
    [ "見奈良駅", "愛媛県", "伊予鉄道横河原線" ],
    [ "愛大医学部南口駅", "愛媛県", "伊予鉄道横河原線" ],
    [ "横河原駅", "愛媛県", "伊予鉄道横河原線" ],
    [ "松山市駅前駅", "愛媛県", "伊予鉄道環状線" ],
    [ "南堀端駅", "愛媛県", "伊予鉄道環状線" ],
    [ "西堀端駅", "愛媛県", "伊予鉄道環状線" ],
    [ "大手町駅", "愛媛県", "伊予鉄道環状線" ],
    [ "松山駅前駅", "愛媛県", "伊予鉄道環状線" ],
    [ "宮田町駅", "愛媛県", "伊予鉄道環状線" ],
    [ "古町駅", "愛媛県", "伊予鉄道環状線" ],
    [ "萱町六丁目駅", "愛媛県", "伊予鉄道環状線" ],
    [ "本町六丁目駅", "愛媛県", "伊予鉄道環状線" ],
    [ "木屋町駅", "愛媛県", "伊予鉄道環状線" ],
    [ "高砂町駅", "愛媛県", "伊予鉄道環状線" ],
    [ "清水町駅", "愛媛県", "伊予鉄道環状線" ],
    [ "鉄砲町駅", "愛媛県", "伊予鉄道環状線" ],
    [ "赤十字病院前駅", "愛媛県", "伊予鉄道環状線" ],
    [ "平和通一丁目駅", "愛媛県", "伊予鉄道環状線" ],
    [ "上一万駅", "愛媛県", "伊予鉄道環状線" ],
    [ "警察署前駅", "愛媛県", "伊予鉄道環状線" ],
    [ "勝山町駅", "愛媛県", "伊予鉄道環状線" ],
    [ "大街道駅", "愛媛県", "伊予鉄道環状線" ],
    [ "県庁前駅", "愛媛県", "伊予鉄道環状線" ],
    [ "市役所前駅", "愛媛県", "伊予鉄道環状線" ],
    [ "松山市駅前駅", "愛媛県", "伊予鉄道環状線" ],
    [ "南堀端駅", "愛媛県", "伊予鉄道環状線" ],
    [ "市役所前駅", "愛媛県", "伊予鉄道環状線" ],
    [ "県庁前駅", "愛媛県", "伊予鉄道環状線" ],
    [ "大街道駅", "愛媛県", "伊予鉄道環状線" ],
    [ "勝山町駅", "愛媛県", "伊予鉄道環状線" ],
    [ "警察署前駅", "愛媛県", "伊予鉄道環状線" ],
    [ "上一万駅", "愛媛県", "伊予鉄道環状線" ],
    [ "平和通一丁目駅", "愛媛県", "伊予鉄道環状線" ],
    [ "赤十字病院前駅", "愛媛県", "伊予鉄道環状線" ],
    [ "鉄砲町駅", "愛媛県", "伊予鉄道環状線" ],
    [ "清水町駅", "愛媛県", "伊予鉄道環状線" ],
    [ "高砂町駅", "愛媛県", "伊予鉄道環状線" ],
    [ "木屋町駅", "愛媛県", "伊予鉄道環状線" ],
    [ "本町六丁目駅", "愛媛県", "伊予鉄道環状線" ],
    [ "萱町六丁目駅", "愛媛県", "伊予鉄道環状線" ],
    [ "古町駅", "愛媛県", "伊予鉄道環状線" ],
    [ "宮田町駅", "愛媛県", "伊予鉄道環状線" ],
    [ "松山駅前駅", "愛媛県", "伊予鉄道環状線" ],
    [ "大手町駅", "愛媛県", "伊予鉄道環状線" ],
    [ "西堀端駅", "愛媛県", "伊予鉄道環状線" ],
    [ "松山市駅前駅", "愛媛県", "伊予鉄道市駅線" ],
    [ "南堀端駅", "愛媛県", "伊予鉄道市駅線" ],
    [ "市役所前駅", "愛媛県", "伊予鉄道市駅線" ],
    [ "県庁前駅", "愛媛県", "伊予鉄道市駅線" ],
    [ "大街道駅", "愛媛県", "伊予鉄道市駅線" ],
    [ "勝山町駅", "愛媛県", "伊予鉄道市駅線" ],
    [ "警察署前駅", "愛媛県", "伊予鉄道市駅線" ],
    [ "上一万駅", "愛媛県", "伊予鉄道市駅線" ],
    [ "南町駅", "愛媛県", "伊予鉄道市駅線" ],
    [ "道後公園駅", "愛媛県", "伊予鉄道市駅線" ],
    [ "道後温泉駅", "愛媛県", "伊予鉄道市駅線" ],
    [ "松山駅前駅", "愛媛県", "伊予鉄道松山駅前線" ],
    [ "大手町駅", "愛媛県", "伊予鉄道松山駅前線" ],
    [ "西堀端駅", "愛媛県", "伊予鉄道松山駅前線" ],
    [ "南堀端駅", "愛媛県", "伊予鉄道松山駅前線" ],
    [ "市役所前駅", "愛媛県", "伊予鉄道松山駅前線" ],
    [ "県庁前駅", "愛媛県", "伊予鉄道松山駅前線" ],
    [ "大街道駅", "愛媛県", "伊予鉄道松山駅前線" ],
    [ "勝山町駅", "愛媛県", "伊予鉄道松山駅前線" ],
    [ "警察署前駅", "愛媛県", "伊予鉄道松山駅前線" ],
    [ "上一万駅", "愛媛県", "伊予鉄道松山駅前線" ],
    [ "南町駅", "愛媛県", "伊予鉄道松山駅前線" ],
    [ "道後公園駅", "愛媛県", "伊予鉄道松山駅前線" ],
    [ "道後温泉駅", "愛媛県", "伊予鉄道松山駅前線" ],
    [ "本町六丁目駅", "愛媛県", "伊予鉄道本町線" ],
    [ "本町五丁目駅", "愛媛県", "伊予鉄道本町線" ],
    [ "本町四丁目駅", "愛媛県", "伊予鉄道本町線" ],
    [ "本町三丁目駅", "愛媛県", "伊予鉄道本町線" ],
    [ "本町一丁目駅", "愛媛県", "伊予鉄道本町線" ],
    [ "南堀端駅", "愛媛県", "伊予鉄道本町線" ],
    [ "松山市駅前駅", "愛媛県", "伊予鉄道本町線" ],
    [ "窪川駅", "高知県", "中村線" ],
    [ "若井駅", "高知県", "中村線" ],
    [ "荷稲駅", "高知県", "中村線" ],
    [ "伊与喜駅", "高知県", "中村線" ],
    [ "土佐佐賀駅", "高知県", "中村線" ],
    [ "佐賀公園駅", "高知県", "中村線" ],
    [ "土佐白浜駅", "高知県", "中村線" ],
    [ "有井川駅", "高知県", "中村線" ],
    [ "土佐上川口駅", "高知県", "中村線" ],
    [ "海の王迎駅", "高知県", "中村線" ],
    [ "浮鞭駅", "高知県", "中村線" ],
    [ "土佐入野駅", "高知県", "中村線" ],
    [ "西大方駅", "高知県", "中村線" ],
    [ "古津賀駅", "高知県", "中村線" ],
    [ "中村駅", "高知県", "中村線" ],
    [ "中村駅", "高知県", "宿毛線" ],
    [ "具同駅", "高知県", "宿毛線" ],
    [ "国見駅", "高知県", "宿毛線" ],
    [ "有岡駅", "高知県", "宿毛線" ],
    [ "工業団地駅", "高知県", "宿毛線" ],
    [ "平田駅", "高知県", "宿毛線" ],
    [ "東宿毛駅", "高知県", "宿毛線" ],
    [ "宿毛駅", "高知県", "宿毛線" ],
    [ "後免駅", "高知県", "ごめん・なはり線" ],
    [ "後免町駅", "高知県", "ごめん・なはり線" ],
    [ "立田駅", "高知県", "ごめん・なはり線" ],
    [ "のいち駅", "高知県", "ごめん・なはり線" ],
    [ "よしかわ駅", "高知県", "ごめん・なはり線" ],
    [ "あかおか駅", "高知県", "ごめん・なはり線" ],
    [ "香我美駅", "高知県", "ごめん・なはり線" ],
    [ "夜須駅", "高知県", "ごめん・なはり線" ],
    [ "西分駅", "高知県", "ごめん・なはり線" ],
    [ "和食駅", "高知県", "ごめん・なはり線" ],
    [ "赤野駅", "高知県", "ごめん・なはり線" ],
    [ "穴内駅", "高知県", "ごめん・なはり線" ],
    [ "球場前駅", "高知県", "ごめん・なはり線" ],
    [ "あき総合病院前駅", "高知県", "ごめん・なはり線" ],
    [ "安芸駅", "高知県", "ごめん・なはり線" ],
    [ "伊尾木駅", "高知県", "ごめん・なはり線" ],
    [ "下山駅", "高知県", "ごめん・なはり線" ],
    [ "唐浜駅", "高知県", "ごめん・なはり線" ],
    [ "安田駅", "高知県", "ごめん・なはり線" ],
    [ "田野駅", "高知県", "ごめん・なはり線" ],
    [ "奈半利駅", "高知県", "ごめん・なはり線" ],
    [ "後免町駅", "高知県", "ごめん線" ],
    [ "後免東町駅", "高知県", "ごめん線" ],
    [ "後免中町駅", "高知県", "ごめん線" ],
    [ "後免西町駅", "高知県", "ごめん線" ],
    [ "東工業前駅", "高知県", "ごめん線" ],
    [ "住吉通駅", "高知県", "ごめん線" ],
    [ "篠原駅", "高知県", "ごめん線" ],
    [ "小篭通駅", "高知県", "ごめん線" ],
    [ "長崎駅", "高知県", "ごめん線" ],
    [ "明見橋駅", "高知県", "ごめん線" ],
    [ "一条橋駅", "高知県", "ごめん線" ],
    [ "清和学園前駅", "高知県", "ごめん線" ],
    [ "領石通駅", "高知県", "ごめん線" ],
    [ "北浦駅", "高知県", "ごめん線" ],
    [ "舟戸駅", "高知県", "ごめん線" ],
    [ "鹿児駅", "高知県", "ごめん線" ],
    [ "田辺島通駅", "高知県", "ごめん線" ],
    [ "東新木駅", "高知県", "ごめん線" ],
    [ "新木駅", "高知県", "ごめん線" ],
    [ "介良通駅", "高知県", "ごめん線" ],
    [ "文珠通駅", "高知県", "ごめん線" ],
    [ "高須駅", "高知県", "ごめん線" ],
    [ "県立美術館通駅", "高知県", "ごめん線" ],
    [ "西高須駅", "高知県", "ごめん線" ],
    [ "葛島橋東詰駅", "高知県", "ごめん線" ],
    [ "知寄町三丁目駅", "高知県", "ごめん線" ],
    [ "知寄町駅", "高知県", "ごめん線" ],
    [ "知寄町二丁目駅", "高知県", "ごめん線" ],
    [ "知寄町一丁目駅", "高知県", "ごめん線" ],
    [ "宝永町駅", "高知県", "ごめん線" ],
    [ "菜園場町駅", "高知県", "ごめん線" ],
    [ "デンテツターミナルビル前駅", "高知県", "ごめん線" ],
    [ "はりまや橋駅", "高知県", "ごめん線" ],
    [ "はりまや橋駅", "高知県", "伊野線" ],
    [ "堀詰駅", "高知県", "伊野線" ],
    [ "大橋通駅", "高知県", "伊野線" ],
    [ "高知城前駅", "高知県", "伊野線" ],
    [ "県庁前駅", "高知県", "伊野線" ],
    [ "グランド通駅", "高知県", "伊野線" ],
    [ "枡形駅", "高知県", "伊野線" ],
    [ "上町一丁目駅", "高知県", "伊野線" ],
    [ "上町二丁目駅", "高知県", "伊野線" ],
    [ "上町四丁目駅", "高知県", "伊野線" ],
    [ "上町五丁目駅", "高知県", "伊野線" ],
    [ "旭町一丁目駅", "高知県", "伊野線" ],
    [ "旭駅前通駅", "高知県", "伊野線" ],
    [ "旭町三丁目駅", "高知県", "伊野線" ],
    [ "蛍橋駅", "高知県", "伊野線" ],
    [ "鏡川橋駅", "高知県", "伊野線" ],
    [ "鴨部駅", "高知県", "伊野線" ],
    [ "曙町東町駅", "高知県", "伊野線" ],
    [ "曙町駅", "高知県", "伊野線" ],
    [ "朝倉駅", "高知県", "伊野線" ],
    [ "朝倉駅前駅", "高知県", "伊野線" ],
    [ "朝倉神社前駅", "高知県", "伊野線" ],
    [ "宮の奥駅", "高知県", "伊野線" ],
    [ "咥内駅", "高知県", "伊野線" ],
    [ "宇治団地前駅", "高知県", "伊野線" ],
    [ "八代通駅", "高知県", "伊野線" ],
    [ "中山駅", "高知県", "伊野線" ],
    [ "枝川駅", "高知県", "伊野線" ],
    [ "伊野商業前駅", "高知県", "伊野線" ],
    [ "北内駅", "高知県", "伊野線" ],
    [ "北山駅", "高知県", "伊野線" ],
    [ "鳴谷駅", "高知県", "伊野線" ],
    [ "伊野駅前駅", "高知県", "伊野線" ],
    [ "伊野駅", "高知県", "伊野線" ],
    [ "高知駅前駅", "高知県", "桟橋線" ],
    [ "高知橋駅", "高知県", "桟橋線" ],
    [ "蓮池町通駅", "高知県", "桟橋線" ],
    [ "はりまや橋駅", "高知県", "桟橋線" ],
    [ "梅の辻駅", "高知県", "桟橋線" ],
    [ "桟橋通一丁目駅", "高知県", "桟橋線" ],
    [ "桟橋通二丁目駅", "高知県", "桟橋線" ],
    [ "桟橋通三丁目駅", "高知県", "桟橋線" ],
    [ "桟橋通四丁目駅", "高知県", "桟橋線" ],
    [ "桟橋車庫前駅", "高知県", "桟橋線" ],
    [ "桟橋通五丁目駅", "高知県", "桟橋線" ],
    [ "基山駅", "佐賀県", "甘木鉄道" ],
    [ "立野駅", "佐賀県", "甘木鉄道" ],
    [ "小郡駅", "福岡県", "甘木鉄道" ],
    [ "大板井駅", "福岡県", "甘木鉄道" ],
    [ "松崎駅", "福岡県", "甘木鉄道" ],
    [ "今隈駅", "福岡県", "甘木鉄道" ],
    [ "西太刀洗駅", "福岡県", "甘木鉄道" ],
    [ "山隈駅", "福岡県", "甘木鉄道" ],
    [ "太刀洗駅", "福岡県", "甘木鉄道" ],
    [ "高田駅", "福岡県", "甘木鉄道" ],
    [ "甘木駅", "福岡県", "甘木鉄道" ],
    [ "直方駅", "福岡県", "伊田線" ],
    [ "南直方御殿口駅", "福岡県", "伊田線" ],
    [ "あかぢ駅", "福岡県", "伊田線" ],
    [ "藤棚駅", "福岡県", "伊田線" ],
    [ "中泉駅", "福岡県", "伊田線" ],
    [ "市場駅", "福岡県", "伊田線" ],
    [ "ふれあい生力駅", "福岡県", "伊田線" ],
    [ "赤池駅", "福岡県", "伊田線" ],
    [ "人見駅", "福岡県", "伊田線" ],
    [ "金田駅", "福岡県", "伊田線" ],
    [ "上金田駅", "福岡県", "伊田線" ],
    [ "糒駅", "福岡県", "伊田線" ],
    [ "田川市立病院駅", "福岡県", "伊田線" ],
    [ "下伊田駅", "福岡県", "伊田線" ],
    [ "田川伊田駅", "福岡県", "伊田線" ],
    [ "金田駅", "福岡県", "糸田線" ],
    [ "豊前大熊駅", "福岡県", "糸田線" ],
    [ "松山駅", "福岡県", "糸田線" ],
    [ "糸田駅", "福岡県", "糸田線" ],
    [ "大藪駅", "福岡県", "糸田線" ],
    [ "田川後藤寺駅", "福岡県", "糸田線" ],
    [ "田川伊田駅", "福岡県", "田川線" ],
    [ "上伊田駅", "福岡県", "田川線" ],
    [ "勾金駅", "福岡県", "田川線" ],
    [ "柿下温泉口駅", "福岡県", "田川線" ],
    [ "内田駅", "福岡県", "田川線" ],
    [ "赤駅", "福岡県", "田川線" ],
    [ "油須原駅", "福岡県", "田川線" ],
    [ "源じいの森駅", "福岡県", "田川線" ],
    [ "崎山駅", "福岡県", "田川線" ],
    [ "犀川駅", "福岡県", "田川線" ],
    [ "東犀川三四郎駅", "福岡県", "田川線" ],
    [ "新豊津駅", "福岡県", "田川線" ],
    [ "豊津駅", "福岡県", "田川線" ],
    [ "今川河童駅", "福岡県", "田川線" ],
    [ "美夜古泉駅", "福岡県", "田川線" ],
    [ "令和コスタ行橋駅", "福岡県", "田川線" ],
    [ "行橋駅", "福岡県", "田川線" ],
    [ "福岡空港駅", "福岡県", "福岡市営地下鉄空港線" ],
    [ "東比恵駅", "福岡県", "福岡市営地下鉄空港線" ],
    [ "博多駅", "福岡県", "福岡市営地下鉄空港線" ],
    [ "祇園駅", "福岡県", "福岡市営地下鉄空港線" ],
    [ "中洲川端駅", "福岡県", "福岡市営地下鉄空港線" ],
    [ "天神駅", "福岡県", "福岡市営地下鉄空港線" ],
    [ "赤坂駅", "福岡県", "福岡市営地下鉄空港線" ],
    [ "大濠公園駅", "福岡県", "福岡市営地下鉄空港線" ],
    [ "唐人町駅", "福岡県", "福岡市営地下鉄空港線" ],
    [ "西新駅", "福岡県", "福岡市営地下鉄空港線" ],
    [ "藤崎駅", "福岡県", "福岡市営地下鉄空港線" ],
    [ "室見駅", "福岡県", "福岡市営地下鉄空港線" ],
    [ "姪浜駅", "福岡県", "福岡市営地下鉄空港線" ],
    [ "中洲川端駅", "福岡県", "福岡市営地下鉄箱崎線" ],
    [ "呉服町駅", "福岡県", "福岡市営地下鉄箱崎線" ],
    [ "千代県庁口駅", "福岡県", "福岡市営地下鉄箱崎線" ],
    [ "馬出九大病院前駅", "福岡県", "福岡市営地下鉄箱崎線" ],
    [ "箱崎宮前駅", "福岡県", "福岡市営地下鉄箱崎線" ],
    [ "箱崎九大前駅", "福岡県", "福岡市営地下鉄箱崎線" ],
    [ "貝塚駅", "福岡県", "福岡市営地下鉄箱崎線" ],
    [ "橋本駅", "福岡県", "福岡市営地下鉄七隈線" ],
    [ "次郎丸駅", "福岡県", "福岡市営地下鉄七隈線" ],
    [ "賀茂駅", "福岡県", "福岡市営地下鉄七隈線" ],
    [ "野芥駅", "福岡県", "福岡市営地下鉄七隈線" ],
    [ "梅林駅", "福岡県", "福岡市営地下鉄七隈線" ],
    [ "福大前駅", "福岡県", "福岡市営地下鉄七隈線" ],
    [ "七隈駅", "福岡県", "福岡市営地下鉄七隈線" ],
    [ "金山駅", "福岡県", "福岡市営地下鉄七隈線" ],
    [ "茶山駅", "福岡県", "福岡市営地下鉄七隈線" ],
    [ "別府駅", "福岡県", "福岡市営地下鉄七隈線" ],
    [ "六本松駅", "福岡県", "福岡市営地下鉄七隈線" ],
    [ "桜坂駅", "福岡県", "福岡市営地下鉄七隈線" ],
    [ "薬院大通駅", "福岡県", "福岡市営地下鉄七隈線" ],
    [ "薬院駅", "福岡県", "福岡市営地下鉄七隈線" ],
    [ "渡辺通駅", "福岡県", "福岡市営地下鉄七隈線" ],
    [ "天神南駅", "福岡県", "福岡市営地下鉄七隈線" ],
    [ "小倉駅", "福岡県", "北九州モノレール" ],
    [ "平和通駅", "福岡県", "北九州モノレール" ],
    [ "旦過駅", "福岡県", "北九州モノレール" ],
    [ "香春口三萩野駅", "福岡県", "北九州モノレール" ],
    [ "片野駅", "福岡県", "北九州モノレール" ],
    [ "城野駅", "福岡県", "北九州モノレール" ],
    [ "北方駅", "福岡県", "北九州モノレール" ],
    [ "競馬場前駅", "福岡県", "北九州モノレール" ],
    [ "守恒駅", "福岡県", "北九州モノレール" ],
    [ "徳力公団前駅", "福岡県", "北九州モノレール" ],
    [ "徳力嵐山口駅", "福岡県", "北九州モノレール" ],
    [ "志井駅", "福岡県", "北九州モノレール" ],
    [ "企救丘駅", "福岡県", "北九州モノレール" ],
    [ "黒崎駅前駅", "福岡県", "筑豊電気鉄道線" ],
    [ "西黒崎駅", "福岡県", "筑豊電気鉄道線" ],
    [ "熊西駅", "福岡県", "筑豊電気鉄道線" ],
    [ "萩原駅", "福岡県", "筑豊電気鉄道線" ],
    [ "穴生駅", "福岡県", "筑豊電気鉄道線" ],
    [ "森下駅", "福岡県", "筑豊電気鉄道線" ],
    [ "今池駅", "福岡県", "筑豊電気鉄道線" ],
    [ "永犬丸駅", "福岡県", "筑豊電気鉄道線" ],
    [ "三ヶ森駅", "福岡県", "筑豊電気鉄道線" ],
    [ "西山駅", "福岡県", "筑豊電気鉄道線" ],
    [ "通谷駅", "福岡県", "筑豊電気鉄道線" ],
    [ "東中間駅", "福岡県", "筑豊電気鉄道線" ],
    [ "筑豊中間駅", "福岡県", "筑豊電気鉄道線" ],
    [ "希望が丘高校前駅", "福岡県", "筑豊電気鉄道線" ],
    [ "筑豊香月駅", "福岡県", "筑豊電気鉄道線" ],
    [ "楠橋駅", "福岡県", "筑豊電気鉄道線" ],
    [ "新木屋瀬駅", "福岡県", "筑豊電気鉄道線" ],
    [ "木屋瀬駅", "福岡県", "筑豊電気鉄道線" ],
    [ "遠賀野駅", "福岡県", "筑豊電気鉄道線" ],
    [ "感田駅", "福岡県", "筑豊電気鉄道線" ],
    [ "筑豊直方駅", "福岡県", "筑豊電気鉄道線" ],
    [ "有田駅", "佐賀県", "西九州線" ],
    [ "三代橋駅", "佐賀県", "西九州線" ],
    [ "黒川駅", "佐賀県", "西九州線" ],
    [ "蔵宿駅", "佐賀県", "西九州線" ],
    [ "西有田駅", "佐賀県", "西九州線" ],
    [ "大木駅", "佐賀県", "西九州線" ],
    [ "山谷駅", "佐賀県", "西九州線" ],
    [ "夫婦石駅", "佐賀県", "西九州線" ],
    [ "金武駅", "佐賀県", "西九州線" ],
    [ "川東駅", "佐賀県", "西九州線" ],
    [ "伊万里駅", "佐賀県", "西九州線" ],
    [ "伊万里駅", "佐賀県", "西九州線" ],
    [ "東山代駅", "佐賀県", "西九州線" ],
    [ "里駅", "佐賀県", "西九州線" ],
    [ "楠久駅", "佐賀県", "西九州線" ],
    [ "鳴石駅", "佐賀県", "西九州線" ],
    [ "久原駅", "佐賀県", "西九州線" ],
    [ "波瀬駅", "佐賀県", "西九州線" ],
    [ "浦ノ崎駅", "佐賀県", "西九州線" ],
    [ "福島口駅", "佐賀県", "西九州線" ],
    [ "今福駅", "長崎県", "西九州線" ],
    [ "鷹島口駅", "長崎県", "西九州線" ],
    [ "前浜駅", "長崎県", "西九州線" ],
    [ "調川駅", "長崎県", "西九州線" ],
    [ "松浦駅", "長崎県", "西九州線" ],
    [ "松浦発電所前駅", "長崎県", "西九州線" ],
    [ "御厨駅", "長崎県", "西九州線" ],
    [ "西木場駅", "長崎県", "西九州線" ],
    [ "東田平駅", "長崎県", "西九州線" ],
    [ "中田平駅", "長崎県", "西九州線" ],
    [ "たびら平戸口駅", "長崎県", "西九州線" ],
    [ "西田平駅", "長崎県", "西九州線" ],
    [ "すえたちばな駅", "長崎県", "西九州線" ],
    [ "江迎鹿町駅", "長崎県", "西九州線" ],
    [ "高岩駅", "長崎県", "西九州線" ],
    [ "いのつき駅", "長崎県", "西九州線" ],
    [ "潜竜ヶ滝駅", "長崎県", "西九州線" ],
    [ "吉井駅", "長崎県", "西九州線" ],
    [ "神田駅", "長崎県", "西九州線" ],
    [ "清峰高校前駅", "長崎県", "西九州線" ],
    [ "佐々駅", "長崎県", "西九州線" ],
    [ "小浦駅", "長崎県", "西九州線" ],
    [ "真申駅", "長崎県", "西九州線" ],
    [ "棚方駅", "長崎県", "西九州線" ],
    [ "相浦駅", "長崎県", "西九州線" ],
    [ "大学駅", "長崎県", "西九州線" ],
    [ "上相浦駅", "長崎県", "西九州線" ],
    [ "本山駅", "長崎県", "西九州線" ],
    [ "中里駅", "長崎県", "西九州線" ],
    [ "皆瀬駅", "長崎県", "西九州線" ],
    [ "野中駅", "長崎県", "西九州線" ],
    [ "左石駅", "長崎県", "西九州線" ],
    [ "泉福寺駅", "長崎県", "西九州線" ],
    [ "山の田駅", "長崎県", "西九州線" ],
    [ "北佐世保駅", "長崎県", "西九州線" ],
    [ "中佐世保駅", "長崎県", "西九州線" ],
    [ "佐世保中央駅", "長崎県", "西九州線" ],
    [ "佐世保駅", "長崎県", "西九州線" ],
    [ "諫早駅", "長崎県", "島原鉄道線" ],
    [ "本諫早駅", "長崎県", "島原鉄道線" ],
    [ "幸駅", "長崎県", "島原鉄道線" ],
    [ "小野駅", "長崎県", "島原鉄道線" ],
    [ "干拓の里駅", "長崎県", "島原鉄道線" ],
    [ "森山駅", "長崎県", "島原鉄道線" ],
    [ "釜ノ鼻駅", "長崎県", "島原鉄道線" ],
    [ "諫早東高校駅", "長崎県", "島原鉄道線" ],
    [ "愛野駅", "長崎県", "島原鉄道線" ],
    [ "阿母崎駅", "長崎県", "島原鉄道線" ],
    [ "吾妻駅", "長崎県", "島原鉄道線" ],
    [ "古部駅", "長崎県", "島原鉄道線" ],
    [ "大正駅", "長崎県", "島原鉄道線" ],
    [ "西郷駅", "長崎県", "島原鉄道線" ],
    [ "神代駅", "長崎県", "島原鉄道線" ],
    [ "多比良駅", "長崎県", "島原鉄道線" ],
    [ "有明湯江駅", "長崎県", "島原鉄道線" ],
    [ "大三東駅", "長崎県", "島原鉄道線" ],
    [ "松尾駅", "長崎県", "島原鉄道線" ],
    [ "三会駅", "長崎県", "島原鉄道線" ],
    [ "島原駅", "長崎県", "島原鉄道線" ],
    [ "霊丘公園体育館駅", "長崎県", "島原鉄道線" ],
    [ "島原船津駅", "長崎県", "島原鉄道線" ],
    [ "島原港駅", "長崎県", "島原鉄道線" ],
    [ "秩父が浦駅", "長崎県", "島原鉄道線" ],
    [ "安徳駅", "長崎県", "島原鉄道線" ],
    [ "瀬野深江駅", "長崎県", "島原鉄道線" ],
    [ "深江駅", "長崎県", "島原鉄道線" ],
    [ "布津新田駅", "長崎県", "島原鉄道線" ],
    [ "布津駅", "長崎県", "島原鉄道線" ],
    [ "堂崎駅", "長崎県", "島原鉄道線" ],
    [ "蒲河駅", "長崎県", "島原鉄道線" ],
    [ "有家駅", "長崎県", "島原鉄道線" ],
    [ "西有家駅", "長崎県", "島原鉄道線" ],
    [ "龍石駅", "長崎県", "島原鉄道線" ],
    [ "北有馬駅", "長崎県", "島原鉄道線" ],
    [ "常光寺前駅", "長崎県", "島原鉄道線" ],
    [ "浦田観音駅", "長崎県", "島原鉄道線" ],
    [ "原城駅", "長崎県", "島原鉄道線" ],
    [ "有馬吉川駅", "長崎県", "島原鉄道線" ],
    [ "東大屋駅", "長崎県", "島原鉄道線" ],
    [ "口之津駅", "長崎県", "島原鉄道線" ],
    [ "白浜海水浴場前駅", "長崎県", "島原鉄道線" ],
    [ "加津佐駅", "長崎県", "島原鉄道線" ],
    [ "赤迫駅", "長崎県", "長崎電軌１系統" ],
    [ "住吉駅", "長崎県", "長崎電軌１系統" ],
    [ "昭和町通駅", "長崎県", "長崎電軌１系統" ],
    [ "千歳町駅", "長崎県", "長崎電軌１系統" ],
    [ "若葉町駅", "長崎県", "長崎電軌１系統" ],
    [ "長崎大学駅", "長崎県", "長崎電軌１系統" ],
    [ "岩屋橋駅", "長崎県", "長崎電軌１系統" ],
    [ "浦上車庫駅", "長崎県", "長崎電軌１系統" ],
    [ "大橋駅", "長崎県", "長崎電軌１系統" ],
    [ "平和公園駅", "長崎県", "長崎電軌１系統" ],
    [ "原爆資料館駅", "長崎県", "長崎電軌１系統" ],
    [ "大学病院駅", "長崎県", "長崎電軌１系統" ],
    [ "浦上駅前駅", "長崎県", "長崎電軌１系統" ],
    [ "茂里町駅", "長崎県", "長崎電軌１系統" ],
    [ "銭座町駅", "長崎県", "長崎電軌１系統" ],
    [ "宝町駅", "長崎県", "長崎電軌１系統" ],
    [ "八千代町駅", "長崎県", "長崎電軌１系統" ],
    [ "長崎駅前駅", "長崎県", "長崎電軌１系統" ],
    [ "五島町駅", "長崎県", "長崎電軌１系統" ],
    [ "大波止駅", "長崎県", "長崎電軌１系統" ],
    [ "出島駅", "長崎県", "長崎電軌１系統" ],
    [ "新地中華街駅", "長崎県", "長崎電軌１系統" ],
    [ "西浜町駅", "長崎県", "長崎電軌１系統" ],
    [ "観光通駅", "長崎県", "長崎電軌１系統" ],
    [ "思案橋駅", "長崎県", "長崎電軌１系統" ],
    [ "崇福寺駅", "長崎県", "長崎電軌１系統" ],
    [ "蛍茶屋駅", "長崎県", "長崎電軌３系統" ],
    [ "新中川町駅", "長崎県", "長崎電軌３系統" ],
    [ "新大工町駅", "長崎県", "長崎電軌３系統" ],
    [ "諏訪神社駅", "長崎県", "長崎電軌３系統" ],
    [ "市役所駅", "長崎県", "長崎電軌３系統" ],
    [ "桜町駅", "長崎県", "長崎電軌３系統" ],
    [ "長崎駅前駅", "長崎県", "長崎電軌３系統" ],
    [ "八千代町駅", "長崎県", "長崎電軌３系統" ],
    [ "宝町駅", "長崎県", "長崎電軌３系統" ],
    [ "銭座町駅", "長崎県", "長崎電軌３系統" ],
    [ "茂里町駅", "長崎県", "長崎電軌３系統" ],
    [ "浦上駅前駅", "長崎県", "長崎電軌３系統" ],
    [ "大学病院駅", "長崎県", "長崎電軌３系統" ],
    [ "原爆資料館駅", "長崎県", "長崎電軌３系統" ],
    [ "平和公園駅", "長崎県", "長崎電軌３系統" ],
    [ "大橋駅", "長崎県", "長崎電軌３系統" ],
    [ "浦上車庫駅", "長崎県", "長崎電軌３系統" ],
    [ "岩屋橋駅", "長崎県", "長崎電軌３系統" ],
    [ "長崎大学駅", "長崎県", "長崎電軌３系統" ],
    [ "若葉町駅", "長崎県", "長崎電軌３系統" ],
    [ "千歳町駅", "長崎県", "長崎電軌３系統" ],
    [ "昭和町通駅", "長崎県", "長崎電軌３系統" ],
    [ "住吉駅", "長崎県", "長崎電軌３系統" ],
    [ "赤迫駅", "長崎県", "長崎電軌３系統" ],
    [ "蛍茶屋駅", "長崎県", "長崎電軌４系統" ],
    [ "新中川町駅", "長崎県", "長崎電軌４系統" ],
    [ "新大工町駅", "長崎県", "長崎電軌４系統" ],
    [ "諏訪神社駅", "長崎県", "長崎電軌４系統" ],
    [ "市役所駅", "長崎県", "長崎電軌４系統" ],
    [ "めがね橋駅", "長崎県", "長崎電軌４系統" ],
    [ "浜町アーケード駅", "長崎県", "長崎電軌４系統" ],
    [ "観光通駅", "長崎県", "長崎電軌４系統" ],
    [ "思案橋駅", "長崎県", "長崎電軌４系統" ],
    [ "崇福寺駅", "長崎県", "長崎電軌４系統" ],
    [ "蛍茶屋駅", "長崎県", "長崎電軌５系統" ],
    [ "新中川町駅", "長崎県", "長崎電軌５系統" ],
    [ "新大工町駅", "長崎県", "長崎電軌５系統" ],
    [ "諏訪神社駅", "長崎県", "長崎電軌５系統" ],
    [ "市役所駅", "長崎県", "長崎電軌５系統" ],
    [ "めがね橋駅", "長崎県", "長崎電軌５系統" ],
    [ "西浜町駅", "長崎県", "長崎電軌５系統" ],
    [ "浜町アーケード駅", "長崎県", "長崎電軌５系統" ],
    [ "新地中華街駅", "長崎県", "長崎電軌５系統" ],
    [ "メディカルセンター駅", "長崎県", "長崎電軌５系統" ],
    [ "大浦海岸通駅", "長崎県", "長崎電軌５系統" ],
    [ "大浦天主堂駅", "長崎県", "長崎電軌５系統" ],
    [ "石橋駅", "長崎県", "長崎電軌５系統" ],
    [ "藤崎宮前駅", "熊本県", "熊本電鉄本線" ],
    [ "黒髪町駅", "熊本県", "熊本電鉄本線" ],
    [ "北熊本駅", "熊本県", "熊本電鉄本線" ],
    [ "亀井駅", "熊本県", "熊本電鉄本線" ],
    [ "八景水谷駅", "熊本県", "熊本電鉄本線" ],
    [ "堀川駅", "熊本県", "熊本電鉄本線" ],
    [ "新須屋駅", "熊本県", "熊本電鉄本線" ],
    [ "須屋駅", "熊本県", "熊本電鉄本線" ],
    [ "三ツ石駅", "熊本県", "熊本電鉄本線" ],
    [ "黒石駅", "熊本県", "熊本電鉄本線" ],
    [ "熊本高専前駅", "熊本県", "熊本電鉄本線" ],
    [ "再春医療センター前駅", "熊本県", "熊本電鉄本線" ],
    [ "御代志駅", "熊本県", "熊本電鉄本線" ],
    [ "上熊本駅", "熊本県", "熊本電鉄上熊本線" ],
    [ "韓々坂駅", "熊本県", "熊本電鉄上熊本線" ],
    [ "池田駅", "熊本県", "熊本電鉄上熊本線" ],
    [ "打越駅", "熊本県", "熊本電鉄上熊本線" ],
    [ "坪井川公園駅", "熊本県", "熊本電鉄上熊本線" ],
    [ "北熊本駅", "熊本県", "熊本電鉄上熊本線" ],
    [ "立野駅", "熊本県", "高森線" ],
    [ "長陽駅", "熊本県", "高森線" ],
    [ "加勢駅", "熊本県", "高森線" ],
    [ "阿蘇下田城ふれあい温泉駅", "熊本県", "高森線" ],
    [ "南阿蘇水の生まれる里白水高原駅", "熊本県", "高森線" ],
    [ "中松駅", "熊本県", "高森線" ],
    [ "阿蘇白川駅", "熊本県", "高森線" ],
    [ "南阿蘇白川水源駅", "熊本県", "高森線" ],
    [ "見晴台駅", "熊本県", "高森線" ],
    [ "高森駅", "熊本県", "高森線" ],
    [ "人吉温泉駅", "熊本県", "湯前線" ],
    [ "相良藩願成寺駅", "熊本県", "湯前線" ],
    [ "川村駅", "熊本県", "湯前線" ],
    [ "肥後西村駅", "熊本県", "湯前線" ],
    [ "一武駅", "熊本県", "湯前線" ],
    [ "木上駅", "熊本県", "湯前線" ],
    [ "おかどめ幸福駅", "熊本県", "湯前線" ],
    [ "あさぎり駅", "熊本県", "湯前線" ],
    [ "東免田駅", "熊本県", "湯前線" ],
    [ "公立病院前駅", "熊本県", "湯前線" ],
    [ "多良木駅", "熊本県", "湯前線" ],
    [ "東多良木駅", "熊本県", "湯前線" ],
    [ "新鶴羽駅", "熊本県", "湯前線" ],
    [ "湯前駅", "熊本県", "湯前線" ],
    [ "八代駅", "熊本県", "肥薩おれんじ鉄道線" ],
    [ "肥後高田駅", "熊本県", "肥薩おれんじ鉄道線" ],
    [ "日奈久温泉駅", "熊本県", "肥薩おれんじ鉄道線" ],
    [ "肥後二見駅", "熊本県", "肥薩おれんじ鉄道線" ],
    [ "上田浦駅", "熊本県", "肥薩おれんじ鉄道線" ],
    [ "たのうら御立岬公園駅", "熊本県", "肥薩おれんじ鉄道線" ],
    [ "肥後田浦駅", "熊本県", "肥薩おれんじ鉄道線" ],
    [ "海浦駅", "熊本県", "肥薩おれんじ鉄道線" ],
    [ "佐敷駅", "熊本県", "肥薩おれんじ鉄道線" ],
    [ "湯浦駅", "熊本県", "肥薩おれんじ鉄道線" ],
    [ "津奈木駅", "熊本県", "肥薩おれんじ鉄道線" ],
    [ "新水俣駅", "熊本県", "肥薩おれんじ鉄道線" ],
    [ "水俣駅", "熊本県", "肥薩おれんじ鉄道線" ],
    [ "袋駅", "熊本県", "肥薩おれんじ鉄道線" ],
    [ "米ノ津駅", "鹿児島県", "肥薩おれんじ鉄道線" ],
    [ "出水駅", "鹿児島県", "肥薩おれんじ鉄道線" ],
    [ "西出水駅", "鹿児島県", "肥薩おれんじ鉄道線" ],
    [ "高尾野駅", "鹿児島県", "肥薩おれんじ鉄道線" ],
    [ "野田郷駅", "鹿児島県", "肥薩おれんじ鉄道線" ],
    [ "折口駅", "鹿児島県", "肥薩おれんじ鉄道線" ],
    [ "阿久根駅", "鹿児島県", "肥薩おれんじ鉄道線" ],
    [ "牛ノ浜駅", "鹿児島県", "肥薩おれんじ鉄道線" ],
    [ "薩摩大川駅", "鹿児島県", "肥薩おれんじ鉄道線" ],
    [ "西方駅", "鹿児島県", "肥薩おれんじ鉄道線" ],
    [ "薩摩高城駅", "鹿児島県", "肥薩おれんじ鉄道線" ],
    [ "草道駅", "鹿児島県", "肥薩おれんじ鉄道線" ],
    [ "上川内駅", "鹿児島県", "肥薩おれんじ鉄道線" ],
    [ "川内駅", "鹿児島県", "肥薩おれんじ鉄道線" ],
    [ "田崎橋駅", "熊本県", "熊本市電Ａ系統" ],
    [ "二本木口駅", "熊本県", "熊本市電Ａ系統" ],
    [ "熊本駅前駅", "熊本県", "熊本市電Ａ系統" ],
    [ "祇園橋駅", "熊本県", "熊本市電Ａ系統" ],
    [ "呉服町駅", "熊本県", "熊本市電Ａ系統" ],
    [ "河原町駅", "熊本県", "熊本市電Ａ系統" ],
    [ "慶徳校前駅", "熊本県", "熊本市電Ａ系統" ],
    [ "辛島町駅", "熊本県", "熊本市電Ａ系統" ],
    [ "花畑町駅", "熊本県", "熊本市電Ａ系統" ],
    [ "熊本城・市役所前駅", "熊本県", "熊本市電Ａ系統" ],
    [ "通町筋駅", "熊本県", "熊本市電Ａ系統" ],
    [ "水道町駅", "熊本県", "熊本市電Ａ系統" ],
    [ "九品寺交差点駅", "熊本県", "熊本市電Ａ系統" ],
    [ "交通局前駅", "熊本県", "熊本市電Ａ系統" ],
    [ "味噌天神前駅", "熊本県", "熊本市電Ａ系統" ],
    [ "新水前寺駅前駅", "熊本県", "熊本市電Ａ系統" ],
    [ "国府駅", "熊本県", "熊本市電Ａ系統" ],
    [ "水前寺公園駅", "熊本県", "熊本市電Ａ系統" ],
    [ "市立体育館前駅", "熊本県", "熊本市電Ａ系統" ],
    [ "商業高校前駅", "熊本県", "熊本市電Ａ系統" ],
    [ "八丁馬場駅", "熊本県", "熊本市電Ａ系統" ],
    [ "神水交差点駅", "熊本県", "熊本市電Ａ系統" ],
    [ "健軍校前駅", "熊本県", "熊本市電Ａ系統" ],
    [ "動植物園入口駅", "熊本県", "熊本市電Ａ系統" ],
    [ "健軍交番前駅", "熊本県", "熊本市電Ａ系統" ],
    [ "健軍町駅", "熊本県", "熊本市電Ａ系統" ],
    [ "上熊本駅", "熊本県", "熊本市電Ｂ系統" ],
    [ "県立体育館前駅", "熊本県", "熊本市電Ｂ系統" ],
    [ "本妙寺入口駅", "熊本県", "熊本市電Ｂ系統" ],
    [ "杉塘駅", "熊本県", "熊本市電Ｂ系統" ],
    [ "段山町駅", "熊本県", "熊本市電Ｂ系統" ],
    [ "蔚山町駅", "熊本県", "熊本市電Ｂ系統" ],
    [ "新町駅", "熊本県", "熊本市電Ｂ系統" ],
    [ "洗馬橋駅", "熊本県", "熊本市電Ｂ系統" ],
    [ "西辛島町駅", "熊本県", "熊本市電Ｂ系統" ],
    [ "辛島町駅", "熊本県", "熊本市電Ｂ系統" ],
    [ "花畑町駅", "熊本県", "熊本市電Ｂ系統" ],
    [ "熊本城・市役所前駅", "熊本県", "熊本市電Ｂ系統" ],
    [ "通町筋駅", "熊本県", "熊本市電Ｂ系統" ],
    [ "水道町駅", "熊本県", "熊本市電Ｂ系統" ],
    [ "九品寺交差点駅", "熊本県", "熊本市電Ｂ系統" ],
    [ "交通局前駅", "熊本県", "熊本市電Ｂ系統" ],
    [ "味噌天神前駅", "熊本県", "熊本市電Ｂ系統" ],
    [ "新水前寺駅前駅", "熊本県", "熊本市電Ｂ系統" ],
    [ "国府駅", "熊本県", "熊本市電Ｂ系統" ],
    [ "水前寺公園駅", "熊本県", "熊本市電Ｂ系統" ],
    [ "市立体育館前駅", "熊本県", "熊本市電Ｂ系統" ],
    [ "商業高校前駅", "熊本県", "熊本市電Ｂ系統" ],
    [ "八丁馬場駅", "熊本県", "熊本市電Ｂ系統" ],
    [ "神水交差点駅", "熊本県", "熊本市電Ｂ系統" ],
    [ "健軍校前駅", "熊本県", "熊本市電Ｂ系統" ],
    [ "動植物園入口駅", "熊本県", "熊本市電Ｂ系統" ],
    [ "健軍交番前駅", "熊本県", "熊本市電Ｂ系統" ],
    [ "健軍町駅", "熊本県", "熊本市電Ｂ系統" ],
    [ "鹿児島駅前駅", "鹿児島県", "鹿児島市電１系統" ],
    [ "桜島桟橋通駅", "鹿児島県", "鹿児島市電１系統" ],
    [ "水族館口駅", "鹿児島県", "鹿児島市電１系統" ],
    [ "市役所前駅", "鹿児島県", "鹿児島市電１系統" ],
    [ "朝日通駅", "鹿児島県", "鹿児島市電１系統" ],
    [ "いづろ通駅", "鹿児島県", "鹿児島市電１系統" ],
    [ "天文館通駅", "鹿児島県", "鹿児島市電１系統" ],
    [ "高見馬場駅", "鹿児島県", "鹿児島市電１系統" ],
    [ "甲東中学校前駅", "鹿児島県", "鹿児島市電１系統" ],
    [ "新屋敷駅", "鹿児島県", "鹿児島市電１系統" ],
    [ "武之橋駅", "鹿児島県", "鹿児島市電１系統" ],
    [ "二中通駅", "鹿児島県", "鹿児島市電１系統" ],
    [ "荒田八幡駅", "鹿児島県", "鹿児島市電１系統" ],
    [ "騎射場駅", "鹿児島県", "鹿児島市電１系統" ],
    [ "鴨池駅", "鹿児島県", "鹿児島市電１系統" ],
    [ "郡元駅", "鹿児島県", "鹿児島市電１系統" ],
    [ "郡元南駅", "鹿児島県", "鹿児島市電１系統" ],
    [ "涙橋駅", "鹿児島県", "鹿児島市電１系統" ],
    [ "南鹿児島駅前駅", "鹿児島県", "鹿児島市電１系統" ],
    [ "二軒茶屋駅", "鹿児島県", "鹿児島市電１系統" ],
    [ "宇宿一丁目駅", "鹿児島県", "鹿児島市電１系統" ],
    [ "脇田駅", "鹿児島県", "鹿児島市電１系統" ],
    [ "笹貫駅", "鹿児島県", "鹿児島市電１系統" ],
    [ "上塩屋駅", "鹿児島県", "鹿児島市電１系統" ],
    [ "谷山駅", "鹿児島県", "鹿児島市電１系統" ],
    [ "鹿児島駅前駅", "鹿児島県", "鹿児島市電２系統" ],
    [ "桜島桟橋通駅", "鹿児島県", "鹿児島市電２系統" ],
    [ "水族館口駅", "鹿児島県", "鹿児島市電２系統" ],
    [ "市役所前駅", "鹿児島県", "鹿児島市電２系統" ],
    [ "朝日通駅", "鹿児島県", "鹿児島市電２系統" ],
    [ "いづろ通駅", "鹿児島県", "鹿児島市電２系統" ],
    [ "天文館通駅", "鹿児島県", "鹿児島市電２系統" ],
    [ "高見馬場駅", "鹿児島県", "鹿児島市電２系統" ],
    [ "加治屋町駅", "鹿児島県", "鹿児島市電２系統" ],
    [ "高見橋駅", "鹿児島県", "鹿児島市電２系統" ],
    [ "鹿児島中央駅前駅", "鹿児島県", "鹿児島市電２系統" ],
    [ "都通駅", "鹿児島県", "鹿児島市電２系統" ],
    [ "中洲通駅", "鹿児島県", "鹿児島市電２系統" ],
    [ "市立病院前駅", "鹿児島県", "鹿児島市電２系統" ],
    [ "神田（交通局前）駅", "鹿児島県", "鹿児島市電２系統" ],
    [ "唐湊駅", "鹿児島県", "鹿児島市電２系統" ],
    [ "工学部前駅", "鹿児島県", "鹿児島市電２系統" ],
    [ "純心学園前駅", "鹿児島県", "鹿児島市電２系統" ],
    [ "中郡駅", "鹿児島県", "鹿児島市電２系統" ],
    [ "郡元駅", "鹿児島県", "鹿児島市電２系統" ],
    [ "那覇空港駅", "沖縄県", "ゆいレール" ],
    [ "赤嶺駅", "沖縄県", "ゆいレール" ],
    [ "小禄駅", "沖縄県", "ゆいレール" ],
    [ "奥武山公園駅", "沖縄県", "ゆいレール" ],
    [ "壺川駅", "沖縄県", "ゆいレール" ],
    [ "旭橋駅", "沖縄県", "ゆいレール" ],
    [ "県庁前駅", "沖縄県", "ゆいレール" ],
    [ "美栄橋駅", "沖縄県", "ゆいレール" ],
    [ "牧志駅", "沖縄県", "ゆいレール" ],
    [ "安里駅", "沖縄県", "ゆいレール" ],
    [ "おもろまち駅", "沖縄県", "ゆいレール" ],
    [ "古島駅", "沖縄県", "ゆいレール" ],
    [ "市立病院前駅", "沖縄県", "ゆいレール" ],
    [ "儀保駅", "沖縄県", "ゆいレール" ],
    [ "首里駅", "沖縄県", "ゆいレール" ],
    [ "石嶺駅", "沖縄県", "ゆいレール" ],
    [ "経塚駅", "沖縄県", "ゆいレール" ],
    [ "浦添前田駅", "沖縄県", "ゆいレール" ],
    [ "てだこ浦西駅", "沖縄県", "ゆいレール" ],
    [ "九州鉄道記念館駅", "福岡県", "門司港レトロ観光線" ],
    [ "出光美術館駅", "福岡県", "門司港レトロ観光線" ],
    [ "ノーフォーク広場駅", "福岡県", "門司港レトロ観光線" ],
    [ "関門海峡めかり駅", "福岡県", "門司港レトロ観光線" ]
  ]

  function check1() {
    window.scrollTo(0, 0);

    if (storePostCode === '') {
      setErrorMessage('店舗の郵便番号を入力してください。');
    } else if (storeAddress === '') {
      setErrorMessage('店舗の住所を入力してください。');
    } else if (Number(storeCount) === 0) {
      setErrorMessage('店舗名の最寄り駅の数を入力してください。');
    } else {
      if (Number(storeCount) === 1) {
        if (station1Name === '') {
          setErrorMessage('店舗名の1つ目の最寄り駅名を入力してください。');
        } else if (station1Time === '') {
          setErrorMessage('店舗名の1つ目の移動時間を入力してください。');
        } else {
          save(true);
        }
      } else if (Number(storeCount) === 2) {
        if (station1Name === '') {
          setErrorMessage('店舗名の1つ目の最寄り駅名を入力してください。');
        } else if (station1Time === '') {
          setErrorMessage('店舗名の1つ目の移動時間を入力してください。');
        } else if (station2Name === '') {
          setErrorMessage('店舗名の2つ目の最寄り駅名を入力してください。');
        } else if (station2Time === '') {
          setErrorMessage('店舗名の2つ目の移動時間を入力してください。');
        } else {
          save(true);
        }
      } else if (Number(storeCount) === 3) {
        if (station1Name === '') {
          setErrorMessage('店舗名の1つ目の最寄り駅名を入力してください。');
        } else if (station1Time === '') {
          setErrorMessage('店舗名の1つ目の移動時間を入力してください。');
        } else if (station2Name === '') {
          setErrorMessage('店舗名の2つ目の最寄り駅名を入力してください。');
        } else if (station2Time === '') {
          setErrorMessage('店舗名の2つ目の移動時間を入力してください。');
        } else if (station3Name === '') {
          setErrorMessage('店舗名の3つ目の最寄り駅名を入力してください。');
        } else if (station3Time === '') {
          setErrorMessage('店舗名の3つ目の移動時間を入力してください。');
        } else {
          save(true);
        }
      }
    }
  }

  function save(e: boolean) {

    var stations: string[] = [];

    stations = [
      station1Name, station2Name, station3Name,
    ];

    var array: string[] = [];
    array = stations.filter(item => item !== '');
    var stationArray: string[] = [];

    if (array.length !== 0) {
      for (let i = 0; i < array.length; i++) {
        var stationName = array[i].slice(0, array[i].indexOf('（'));

        for (let i = 0; i < train.length; i++) {
          if (train[i][0] === stationName) {
            stationArray.push(...train[i]);
          }
        }
      }
    }

    var setStationTagsArray: string[] = [];
    setStationTagsArray = Array.from(new Set(stationArray));

    firebase.firestore().collection("stores").doc(store)
    .update({

      status: statusPentagon && statusType && e,

      status_place: e,
      
      // name: storeName,

      postCode: storePostCode,
      address: storeAddress,

      stationCount: Number(storeCount),
      station1Name: station1Name,
      station1Time: station1Time,
      station2Name: station2Name,
      station2Time: station2Time,
      station3Name: station3Name,
      station3Time: station3Time,

      stationTags: setStationTagsArray,
      last_time: new Date(),
    }).then(() => {
      navigate(-1);
    })
  }

  return (
    <>
    <Header />

    <div className={Styles.box}>
      <div className={Styles.box_inline}>

        {errorMessage !== '' ?
          <ErrorMessage message={errorMessage} />
        : <></> }

        <div className={Styles.contents}>
          
          {/* <DefaultInput1
            title={'店舗名'} required={true} length={20} place={'横浜店、みなとみらい店など'} type={'text'}
            value={storeName} setValue={setStoreName}
            desc={''}
          /> */}

          <DefaultTitle title={'郵便番号'} required={true} />
          <input className={Styles.post_input}
            placeholder='2220011' value={storePostCode} maxLength={7}
            onChange={(e) => {
              setStorePostCode(e.target.value);
              fetch(`https://api.zipaddress.net/?zipcode=${e.target.value}`)
              .then(response => response.json())
              .then(data => {
                setStoreAddress(data.data.fullAddress);
              }).catch(err => {})
            }} />
          <p className={Styles.desc}>※半角数字（ハイフン無し）で入力してください。</p>

          <DefaultInput1
            title={'住所'} required={true} length={50} place={'神奈川県横浜市港北区菊名4-8-25'} type={'text'}
            value={storeAddress} setValue={setStoreAddress}
            desc={'※番地・部屋番号は半角数字で入力してください。'}
          />

          <DefaultTitle title={'最寄り駅の数'} required={true} />
          <select className={Styles.select}
            value={storeCount} onChange={(e) => setStationCount(Number(e.target.value))}>
            <option value={0}>未選択</option>
            <option value={1}>1つ</option>
            <option value={2}>2つ</option>
            <option value={3}>3つ</option>
          </select>
          <p className={Styles.desc}>　</p>

          {1 <= Number(storeCount) && Number(storeCount) <= 3 ?
          <>
            <DefaultTitle title={'1つ目：最寄り駅'} required={true} />
            <DefaultStation
              value={station1Name} setValue={setStation1Name}
            />
            <p className={Styles.desc}>　</p>

            <DefaultSelect1
              title={'1つ目：移動時間'} required={true} length={0}
              value={station1Time} setValue={setStation1Time}
              list={['未選択', '3分以内', '5分以内', '10分以内', '15分以内', '20分以内', '21分以上',]}
              desc={''}
            />

          </> : <></>}

          {2 <= Number(storeCount) && Number(storeCount) <= 3 ?
          <>
            <DefaultTitle title={'最寄り駅②'} required={true} />
            <DefaultStation
              value={station2Name} setValue={setStation2Name}
            />
            <p className={Styles.desc}>　</p>

            <DefaultSelect1
              title={'2つ目：移動時間'} required={true} length={0}
              value={station2Time} setValue={setStation2Time}
              list={['未選択', '3分以内', '5分以内', '10分以内', '15分以内', '20分以内', '21分以上',]}
              desc={''}
            />

          </> : <></>}

          {3 <= Number(storeCount) && Number(storeCount) <= 3 ?
          <>
            <DefaultTitle title={'最寄り駅③'} required={true} />
            <DefaultStation
              value={station3Name} setValue={setStation3Name}
            />
            <p className={Styles.desc}>　</p>

            <DefaultSelect1
              title={'3つ目：移動時間'} required={true} length={0}
              value={station3Time} setValue={setStation3Time}
              list={['未選択', '3分以内', '5分以内', '10分以内', '15分以内', '20分以内', '21分以上',]}
              desc={''}
            />

          </> : <></>}
        </div>

        <div className={Styles.save_box}>
          <button className={Styles.save_box_now} onClick={() => setBackStatus(true)}>戻る</button>
          <button className={Styles.save_box_now} onClick={() => save(false)}>一時保存</button>
          <button className={Styles.save_box_save} onClick={() => check1()}>保存</button>
        </div>

      </div>
    </div>

    {backStatus ?
      <BackModal
        setBackStatus={setBackStatus}
      />
    : <></> }

    </>
  );
}

export default RecruitEditStorPlace;