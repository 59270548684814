import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import firebase from "firebase/compat/app";

// Thunk action to fetch recruits
export const fetchRecruits = createAsyncThunk(
  'recruit/fetchRecruits',
  async (id: string, { rejectWithValue }) => {
    try {
      const querySnapshot = await firebase.firestore().collection('recruits')
        .where('companyId', '==', id).orderBy('last_time', 'desc').get();
      
      const recruitList: Recruit[] = [];
      const recruitIds: string[] = [];
      
      querySnapshot.forEach((doc) => {
        recruitList.push({ 
          id: doc.id,
          title: doc.data().store_title,
          status: doc.data().status,
          employment: doc.data().recruit_employmentStatus,
          time: new Date(doc.data()?.time.seconds * 1000 + doc.data()?.time.nanoseconds / 1000000)?.toISOString(),
          pv: doc.data().data_pv.length,
          saves: doc.data().data_save.length,
          applicants: doc.data().data_applicants.length,
          recruitment: doc.data().data_recruitment.length,
          goal: doc.data().store_goal,
          apply: doc.data().data_apply,
          exam_selection: doc.data().exam_selection,
          place_storeCount: doc.data().place_storeCount,
          place_store1Name: doc.data().place_store1Name,
          place_store2Name: doc.data().place_store2Name,
          place_store3Name: doc.data().place_store3Name,
          place_store4Name: doc.data().place_store4Name,
          place_store5Name: doc.data().place_store5Name,
        });

        recruitIds.push(doc.id);
      });

      return { recruitList, recruitIds };
    } catch (error) {
      return rejectWithValue('Error fetching recruits');
    }
  }
);

// Recruit interface
interface Recruit {
  id: string;
  status: number;
  title: string;
  employment: string;
  time: string;
  pv: number;
  saves: number;
  applicants: string[];
  recruitment: number;
  goal: number;
  apply: number;
  exam_selection: number,
  place_storeCount: number;
  place_store1Name: string;
  place_store2Name: string;
  place_store3Name: string;
  place_store4Name: string;
  place_store5Name: string;
}

// State interface
interface RecruitState {
  recruitList: Recruit[];
  recruitIds: string[];
}

// Initial state
const initialState: RecruitState = {
  recruitList: [],
  recruitIds: [],
};

// Slice
const recruitSlice = createSlice({
  name: 'recruit',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchRecruits.fulfilled, (state, action) => {
        state.recruitList = action.payload.recruitList;
        state.recruitIds = action.payload.recruitIds;
      })
  },
});

export default recruitSlice.reducer;
