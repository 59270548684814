import Styles from './styles.module.css';
import firebase from 'firebase/compat/app';
import React, { useState, useEffect } from 'react';
import Analytics from './1.analytics/index';
import Task from './3.task/index';
import Recruit from './2.recruit/index';
import { LuInfo } from 'react-icons/lu';

const Home: React.FC = () => {

  const [ info, setInfo ] = useState<string[]>([]);

  useEffect(() => {
    firebase.firestore().collection('info').doc('works').get()
    .then((doc) => {
      setInfo(doc.data()?.info || []);
    });
  }, []);

  return (
    <>
    <div className='works_service'>
      
      {info.length !== 0 ?
        <div className={Styles.home_info}>
          {info.map((data, index) =>
          <>
            <h4>運営事務局からのお知らせ</h4>
            <LuInfo className={Styles.home_info_icon} />
            <p>{data}</p>
          </> )}
        </div>
      : <></> }

      <Analytics />

      <div className={Styles.home_bottom}>
        <Recruit />
        <Task />
      </div>

    </div>
    </>
  );
}


export default Home;
