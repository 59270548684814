import Styles from './styles.module.css';
import React, { useState, useEffect } from 'react';
import DefaultTitle from '../../../../../../package/parts/title/index';
import firebase from 'firebase/compat/app';
import DefaultImage from './../../../../../../image/default/home.png';
import { FaPen } from "react-icons/fa";

type StoreEditProps = {
  editInfo: editInfoList;
  setEditInfo: React.Dispatch<React.SetStateAction<editInfoList>>;
};

interface editInfoList {
  id: string,
  name: string,
}

const StoreEdit: React.FC<StoreEditProps> = ({ editInfo, setEditInfo }) => {

  const [ image, setImage ] = useState('');
  const [ name, setName ] = useState('');
  const [ errorName, setErrorName ] = useState(false);

  const [ styleImageFile, setStyleImageFile ] = useState('');
  const [ styleImageFilePath, setStyleImageFilePath ] = useState<File | null>(null);

  useEffect(() => {
    setName(editInfo.name);
    setImage(editInfo.id);
  }, []);

  const styleImages = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setStyleImageFile(URL.createObjectURL(e.target.files[0]));
      setStyleImageFilePath(e.target.files[0]);
    }
  }

  function save() {

    setEditInfo({ id: '', name: '' });

    firebase.firestore().collection('stores').doc(image)
    .update({
      name: name,
      last_time: new Date(),
    });

    if (styleImageFilePath) {
      firebase.storage().ref().child(`/stores/${image}`).put(styleImageFilePath);
    }
  }

  return (
    <>
    <div className={Styles.box}>
      <div>
        <div className={Styles.box_black} onClick={() => setEditInfo({ id: '', name: '' })}></div>
        <div className={Styles.box_contents}>

          <div className={Styles.title}>
            <h4>店舗追加</h4>
            <button onClick={() => name === '' ? setErrorName(true) : save()}>編集</button>
          </div>

          <p className={Styles.desc}>※既に登録していない店舗を追加してください。</p>

          <DefaultTitle title={'店舗写真'} required={true} />

          <div className={Styles.make_image}>
            <FaPen className={Styles.make_image_pen} />
            <input type="file" onChange={styleImages} />
            {styleImageFile === '' ?
              <img className={Styles.make_image_file} alt=''
                src={`https://firebasestorage.googleapis.com/v0/b/trankllc-com.appspot.com/o/stores%2Fresize_images%2F${image}_500x500?alt=media&token=9128e868-a542-467f-94c0-54594423adb1`}
                onError={e => {
                  e.currentTarget.onerror = null;
                  e.currentTarget.src = DefaultImage;
                }}/>
            : 
              <img className={Styles.make_image_file} src={styleImageFile} alt='' />
            }
          </div>

          <div className={Styles.title}>
            <div>
              <p>店舗名</p>
              <div className={Styles.title_yes}>※必須</div>
            </div>
            <p>{name.length} / 20</p>
          </div>
          <input className={Styles.input} placeholder={'メモリー 横浜店'} maxLength={20} type='text' 
            value={name} onChange={(e) => setName(e.target.value)} />
          <p className={Styles.desc} style={errorName ? { color : '#AD0200', fontWeight : 'bold' } : {}}>※店舗名を入力してください。</p>

        </div>
      </div>
    </div>
    </>
  );
}

export default StoreEdit;
