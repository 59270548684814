import Styles from './styles.module.css';
import React from 'react'
import { useNavigate } from 'react-router-dom';

type RecruitEditBackProps = {
  setBackStatus: React.Dispatch<React.SetStateAction<boolean>>;
};

const RecruitEditBack: React.FC<RecruitEditBackProps> = ({ setBackStatus }) => {

  const navigate = useNavigate();

  const link: string = new URL(window.location.href).href;
  const recruitLink = decodeURIComponent(link.slice(link.indexOf('id=') + 3, link.length));

  return (
    <>
    <div className={Styles.modal}>
      <div>
        <div className={Styles.modal_black} onClick={() => setBackStatus(false)}></div>
        <div className={Styles.modal_contents}>
          <h4>保存せずに戻りますか？</h4>
          <p>
            保存せずに画面を終了した場合、編集記録は残りませんので今一度ご確認ください。<br />
          </p>
          <div>
            <button style={{backgroundColor : 'white', color : '#AD0200'}} onClick={() => setBackStatus(false)}>キャンセル</button>
            <button style={{backgroundColor : '#AD0200', color : 'white'}} 
              onClick={() => navigate(`/edit/id=${recruitLink}`)}>戻る</button>
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

export default RecruitEditBack;


