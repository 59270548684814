import React, { useState, useLayoutEffect } from 'react';
import firebase from 'firebase/compat/app';
import Styles from './styles.module.css';

type RecruitPostsProps = {
  companyId: string;
};

const RecruitPosts: React.FC<RecruitPostsProps> = ({ companyId }) => {

  const [ photos, setPhotos ] = useState<any[]>([]);

  useLayoutEffect(() => {
    firebase.firestore().collection('posts').where('companyId', '==', companyId).get()
    .then(snapShot => {
      var tmp: any[] = [];
      snapShot.forEach((doc) => {
        tmp.push({...doc.data(), id: doc.id });
      });
      setPhotos(tmp);
    });
  }, []);

  return (
    <>
    <div className={Styles.text}>
      <div className={Styles.photos}>
        <h5>得意のヘアスタイル</h5>
        <div>
          {photos.map((data, index) =>
            <img alt='' key={index} src={`https://firebasestorage.googleapis.com/v0/b/trankllc-com.appspot.com/o/image%2Fresize_images%2F${data.id}_500x500?alt=media&token=`} />
          )}
        </div>
      </div>
    </div>
    </>
  );
}

export default RecruitPosts;
