import DocumentMeta from 'react-document-meta';
import Styles from '../styles.module.css';
import React from 'react';
import { AiFillCheckCircle } from "react-icons/ai";
import AnchorLink from 'react-anchor-link-smooth-scroll';
import Icon from './../../image/media/1/top.png';
import Image from './../../image/media/5/top.png';
import Image1 from './../../image/media/5/1.png';
import Image2 from './../../image/media/5/2.png';
import Image3 from './../../image/media/5/3.png';
import Image4 from './../../image/media/5/4.png';
import Image5 from './../../image/media/5/5.png';
import Image6 from './../../image/media/5/6.png';
import Image7 from './../../image/media/5/7.png';
import Image8 from './../../image/media/5/8.png';

const Article0001 = () => {

  const meta = {
    title: '【初心者向け】内定に近づく美容学生の履歴書書き方完全マニュアル',
    description: 'この記事では、美容学生が就活の時に使用する履歴書とエントリーシートの書き方について解説します。履歴書とは、美容院に応募する際に提出する書類で、自分の基礎情報や、アピールポイントを記載するものです。エントリーシートと呼ばれる場合もあり、ほとんどのサロンで応募する際に記載する必要があります。',
    canonical: 'https://memorii-works.com/',
    meta: { charset: 'utf-8', }
  };

  return (
    <>
    <DocumentMeta {...meta}>
      <div className={Styles.media_nav}>
        <a className={Styles.media_nav_icon} href='https://trankllc.com/media/subsidy/business/0000'>
          <img src={Icon} loading="lazy" alt='memorii' />
          <h2>美容学生の教科書</h2>
        </a>
        <a className={Styles.media_nav_contact} href='https://trankllc.com/contact'>
          <p>問い合わせ</p>
        </a>
      </div>
      <div className={Styles.media}>
        <div className={Styles.media_article}>
          
          <p className={Styles.media_date}>2024/04/20 更新日: -</p>
          <h1 className={Styles.media_title}>【初心者向け】内定に近づく美容学生の履歴書書き方完全マニュアル</h1>
          <img src={Image} className={Styles.media_title_img} alt='美容 インスタ' />
    
          <p className={Styles.media_text_text}>
            <br />
            履歴書とは、美容院に応募する際に提出する書類で、自分の基礎情報や、アピールポイントを記載するものです。<br />
            エントリーシートと呼ばれる場合もあり、ほとんどのサロンで応募する際に記載する必要があります。<br />
          </p>
    
          <div className={Styles.media_introduction}>
            <div>
              <AiFillCheckCircle className={Styles.media_introduction_icon} />
              <h3>この記事でこの3点が分かる！</h3>
            </div>
            <hr></hr>
            <ul>
              <li><span>履歴書を書く時のマナー</span></li>
              <li><span>履歴書で書く項目</span></li>
              <li><span>魅力的な履歴書にするための工夫ポイント</span></li>
            </ul>
          </div>
    
          <div className={Styles.media_contents}>
            <div className={Styles.media_contents_title}>
              本記事の目次
            </div>
            <div className={Styles.media_contents_contents}>
              <ol>
                <li><AnchorLink href="#1" offset="100"><strong>履歴書の概要</strong></AnchorLink></li>
                <li><AnchorLink href="#2" offset="100"><strong>履歴書を書く時の注意点</strong></AnchorLink></li>
                <li><AnchorLink href="#3" offset="100"><strong>採用担当者が履歴書で見ているポイント</strong></AnchorLink></li>
                <li><AnchorLink href="#4" offset="100"><strong>履歴書の項目</strong></AnchorLink></li>
                <li><AnchorLink href="#5" offset="100"><strong>他の学生と差をつける工夫</strong></AnchorLink></li>
                <li><AnchorLink href="#6" offset="100"><strong>まとめ</strong></AnchorLink></li>
              </ol>
            </div>
          </div>
    
          <div id='1' className={Styles.media_text_title}>
            <h2>1.履歴書の概要</h2>
            <br />
          </div>
          <p className={Styles.media_text_text}>
            <img src={Image1} className={Styles.media_img} alt='美容学生 履歴書' />
            参照：<a href='https://jsite.mhlw.go.jp/aomori-roudoukyoku/content/contents/R3_pdf_rirekisyo.pdf' target="_blank" rel="nofollow noopener noreferrer">
              厚生労働省 履歴書 フォーマット
            </a><br />
            <br />
            上の写真は、厚生労働省が提供している履歴書のフォーマットです。<br />
            多くの履歴書は左側に氏名や住所、学歴といった個人情報を記載し、右側に志望理由や自己PRといったアピールポイントを記載するものとなっています。<br />
            他にも、コンビニで販売されていたり、インターネット上で無料ダウンロードができるものが提供されていたりするので、最適なものを活用してください。<br />
            <br />
            <strong>【Point：サロン独自の履歴書が提供される場合がある】</strong><br />
            サロンによっては、各サロン独自の履歴書を提供している場合があります。Ocean Toky様oやLIPPS様といった有名サロンが該当するため、自分が受けるサロンの採用ページを確認してみてください。<br />
          </p>

          <div id='2' className={Styles.media_text_title}>
              <h2>履歴書を書く時の注意点</h2>
          </div>
          <h3 className={Styles.media_text_text_title}>出来る限り手書きで記載！</h3><br />
          <p className={Styles.media_text_text}>
            履歴書は、できる限り手書きで記載しましょう。<br />
            下のグラフは、ライフネット生命が公開した「新卒採用関係者の意識調査」によるものですが、<strong>「手書きの方が有利」と答えた企業は「パソコンの方が有利」と答えた企業の7倍以上</strong>となっています。<br />
            <br />
            <img src={Image2} className={Styles.media_img} alt='美容 履歴書' />
            参照：<a href='https://www.lifenet-seimei.co.jp/shared/pdf/2013-4568.pdf' target="_blank" rel="nofollow noopener noreferrer">
              ライフネット生命調べ 新卒採用関係者の意識調査
            </a><br />
            <br />
            <strong>手書きの履歴書は時間がかかってしまいますが、時間がない人でも第一志望のサロンでは手書きの履歴書を提出するようにしましょう。</strong><br />
          </p>
          <h3 className={Styles.media_text_text_title}>修正テープ・修正液は使わない！</h3><br />
          <p className={Styles.media_text_text}>
            履歴書では、内容の他に一般的なマナーも確認しています。一般的には、正式な書類を提出する際に修正テープ・修正液は使用しないのがマナーです。<br />
            そのため、時間はかかってしまいますが、間違えた場合は新しく書き直すようにしましょう。<br />
            <br />
            また、履歴書の記載はボールペンなどの、消えないペンで書くことが一般的です。あらかじめ鉛筆での下書き等を行い、間違えないように注意して記載しましょう。<br />
          </p>

          <div id='3' className={Styles.media_text_title}>
              <h2>採用担当者が履歴書で見ているポイント</h2>
          </div>
          <p className={Styles.media_text_text}>
            ここでは、採用担当者が履歴書のどこを見ているのかを確認していきましょう！<br />
          </p>
          <h3 className={Styles.media_text_text_title}>〇マナー編</h3><br />
          <p className={Styles.media_text_text}>
            <img src={Image3} className={Styles.media_img} alt='履歴書 手書き' />
            参照：<a href='https://saiyo-kakaricho.com/wp/resume_digital-transformation/' target="_blank" rel="nofollow noopener noreferrer">
              株式会社ネットオン調べ 履歴書に対する価値観
            </a><br />
            <br />
            上のグラフは株式会社ネットオンが行った「手書きの履歴書を受領している理由」ですが、「筆跡から人柄が分かる」や「一般常識の確認」といった項目が上位なのが分かりますね。<br />
            <br />
            そのため、<strong>「文字は丁寧にかく」「写真は証明写真で撮る」「修正ペンを使わない」といった基本的なマナーを守り、きれいな履歴書を書くことを心がけましょう！</strong><br />
          </p>
          <h3 className={Styles.media_text_text_title}>〇内容編</h3><br />
          <p className={Styles.media_text_text}>
            <img src={Image4} className={Styles.media_img} alt='履歴書 手書き' />
            参照：<a href='https://next.rikunabi.com/tenshokuknowhow/archives/5135/' target="_blank" rel="nofollow noopener noreferrer">
              株式会社リクルート調べ 採用担当者へのアンケート
            </a><br />
            <br />
            こちらは、株式会社リクルートが行った「採用担当者へのアンケート」ですが、採用担当者は「志望動機」や「自己PR」といった他の人と違いが出る内容を重視していることが分かります。<br />
            これらの項目については下で詳しく記載しておりますので、履歴書では「志望動機」「自己PR」を詳しく書く必要があるということを覚えておきましょう！<br />
            <br />
            また、履歴書の記載はボールペンなどの、消えないペンで書くことが一般的です。あらかじめ鉛筆での下書き等を行い、間違えないように注意して記載しましょう。<br />
          </p>
          <div className={Styles.media_introduction}>
              <div>
                <AiFillCheckCircle className={Styles.media_introduction_icon} />
                <h3>ここまでのまとめ</h3>
              </div>
              <hr></hr>
              <ul>
                <li><span>・履歴書は手書きで書くべし！</span></li>
                <li><span>・履歴書は基本的なマナーを守り、丁寧な字で書くべし！</span></li>
                <li><span>・履歴書では「志望動機」や「自己PR」が重視されている！</span></li>
              </ul>
          </div>
          <p className={Styles.media_text_text}>
            ここまでの内容はいかがでしたでしょうか？<br />
            上のまとめを踏まえて、次からは例を見ながら一緒に履歴書を埋めていきましょう！<br />
          </p>

          <div id='4' className={Styles.media_text_title}>
            <h2>4.履歴書の項目</h2>
            <br />
          </div>
          <p className={Styles.media_text_text}>
            <img src={Image5} className={Styles.media_img} alt='履歴書' />
            <br />
            ここでは、履歴書の記載例を見ながら、項目ごとに記載する内容を確認していきます。<br />
            書く内容や、書く際のポイントを意識しながら実際に記載を行ってみましょう！<br />
          </p>
          <h3 className={Styles.media_text_text_title}>①作成日付</h3><br />
          <p className={Styles.media_text_text}>
            日付には作成した日付ではなく、応募先に提出した日付を記載します。<br />
            そのため、持参する場合は持参日、郵送する場合は投函日を記載しましょう。<br />
            <br />
            また、令和〇年と20○○年という2パターンの記載方法がありますが、基本的にはどちらでも問題はありません。ただし、履歴書内では記載方法を統一しましょう。<br />
          </p>
          <h3 className={Styles.media_text_text_title}>②証明写真</h3><br />
          <p className={Styles.media_text_text}>
            3か月以内に証明写真機で撮影した写真を貼りましょう。顔は口角を上げた程度の笑顔で、胸より上が移っている写真を使うことをおすすめします。<br />
            <br />
            <strong>【Point：証明写真の服装やセットは？】</strong><br />
            証明写真で重要なのは「清潔感」があることです。そのため、サロンからの指定がない場合は過度なセットやメイクは避け、服装もリクルートスーツが望ましいと言えます。<br />
          </p>
          <h3 className={Styles.media_text_text_title}>③氏名</h3><br />
          <p className={Styles.media_text_text}>
            苗字と名前の間は1文字分開けるようにしましょう。また、ふりがなは漢字の上に書く、「ふりがな」と「フリガナ」によって平仮名とカタカナを使い分けると見栄えがいいです。<br />
          </p>
          <h3 className={Styles.media_text_text_title}>④生年月日</h3><br />
          <p className={Styles.media_text_text}>
            自分の生年月日を記載する欄です。満年齢の欄は提出日時点での年齢を記載します。<br />
          </p>
          <h3 className={Styles.media_text_text_title}>⑤住所・電話番号</h3><br />
          <p className={Styles.media_text_text}>
            現在住民票がある場所の住所を記載します。一人暮らしや寮生活を行っている人は、下の欄にある連絡先に現在住んでいる住所を記載しましょう。<br />
            また、電話番号に結果の連絡が来る場合もありますので、自分が最も確認できる電話番号を記載しましょう。<br />
          </p>
          <h3 className={Styles.media_text_text_title}>⑥学歴・職歴</h3><br />
          <p className={Styles.media_text_text}>
            基本的には、高校入学からの学歴を記載します。専門学校に通っており、今後卒業する予定のある方は「卒業予定」として卒業する見込みのある年度を記載しましょう。<br />
            <br />
            職歴にアルバイトを記載する必要はありません。新卒の場合は学歴のみを記載し、アルバイト等での実績を記載したい場合は「志望動機」や「自己PR」で記載しましょう。<br />
            全ての学歴・職歴を記載した後、1行開けて右揃えで「以上」と書きます。
          </p>
          <h3 className={Styles.media_text_text_title}>⑦免許・資格</h3><br />
          <p className={Styles.media_text_text}>
            ここでは、応募するサロンに関係のある免許や資格を記載します。<br />
            出張サービスを行っているサロンの場合は自動車免許、海外支店があるサロンの場合は英語検定等を書いておくと、評価がアップする可能性があります。<br />
          </p>
          <h3 className={Styles.media_text_text_title}>⑦免許・資格</h3><br />
          <p className={Styles.media_text_text}>
            ここでは、応募するサロンに関係のある免許や資格を記載します。<br />
            出張サービスを行っているサロンの場合は自動車免許、海外支店があるサロンの場合は英語検定等を書いておくと、評価がアップする可能性があります。<br />
          </p>
          <h3 className={Styles.media_text_text_title}>⑧志望の動機・アピールポイントなど</h3><br />
          <p className={Styles.media_text_text}>
            ここはフリースペースとなっており、サロンからの指定がない場合は自由に記載する欄となります。<br />
            基本的には「志望動機」と「自己PR」の2点を記載することが求められますが、ここの書き方については下で詳しく説明していますので、そちらを参考にしてみてください。<br />
            <br />
            また、サロンからの指定がない場合でも、フリースペースは確実に埋めるようにしましょう！ここの記載がないだけで、応募の熱量が低いと思われてしまいます。<br />
            そのため、ユーザー名やアイコンでどのような投稿を行っているのかが分かるようにし、フォローしたい！と思ってもらえるようにしましょう。<br />
          </p>
          <h3 className={Styles.media_text_text_title}>⑨本人希望記入欄</h3><br />
          <p className={Styles.media_text_text}>
            基本的には、会社の規定に従事するという内容を記載しましょう。<br />
            日常生活に支障がでる肉体的・精神的な不安を抱えている場合は、ここで伝えてみてもいいかもしれませんね。<br />
          </p>

          <div className={Styles.media_introduction}>
              <div>
                <AiFillCheckCircle className={Styles.media_introduction_icon} />
                <h3>履歴書の項目のまとめ</h3>
              </div>
              <hr></hr>
              <ul>
                <li><span>・令和〇年と20○○年という記載方法はどちらかに統一する！</span></li>
                <li><span>・証明写真は自撮りではなく、写真機やスタジオで撮影する！</span></li>
                <li><span>・志望理由、アピールポイント等のフリースペースは必ず埋める！</span></li>
              </ul>
          </div>
          <p className={Styles.media_text_text}>
            履歴書の項目部分はいかがでしたでしょうか？<br />
            基本的な項目がまとめてありますので、各項目のポイントと自分の書いた履歴書を照らし合わせてみましょう！<br />
            次は応用編です！履歴書で他の人と違いがつけられるよう、一緒に頑張りましょう！<br />
          </p>
          
          <div id='5' className={Styles.media_text_title}>
            <h2>5.他の学生と差をつける工夫</h2>
          </div>
          <h3 className={Styles.media_text_text_title}>担当者の目に止まる！フリースペースを記載する際の一工夫</h3><br />
          <p className={Styles.media_text_text}>
            <br />
            さて、今までの記事でフリースペースに記載する志望理由や自己PRが非常に重要だということは分かっていただけましたでしょうか。<br />
            ここでは、フリースペースのどのような記述でも使える、魅力的な文章にする方法をまとめて紹介していきます。自分が書いた文章と照らし合わせながら、読んでみてください！<br />
            <br />
            <strong>〇言いたいこと(結論)を最初に書く！</strong><br />
            これは文章を書く上で、非常に重要な項目となっています。<br />
            まずは、下の2つの文章を読んでみてください。<br />
            <br />
            <img src={Image6} className={Styles.media_img} alt='履歴書 美容' />
            <br />
            <br />
            どちらの文章の方が、読んでいて読みやすかったでしょうか。<br />
            おそらく多くの人は、下の文章の方が読みやすかったと思います。使っている単語はほとんど変わっておらずここまでの差が出るのは、言いたいことを最初に書くということを意識した文章になっているからです。<br />
            <br />
            皆さんの文章は、言いたいことが多すぎて結論が最後になっていないでしょうか？魅力的な文章にするために、もう一度見直してみてください！<br />
            <br />
            <strong>〇自分のことだけでなく、サロン側の目線も取り入れて書く！</strong><br />
            志望理由や自己PRで多いのが、「自分はこう思う！だからサロンに入りたい」という、自分の視点のみで書かれた文章です。これも非常に大事なことなのですが、他の候補者と差をつけるためにも、サロン側の目線を取り入れることを意識しましょう！<br />
            <br />
            ここで、皆さんの日常生活を思い出してみてください。<br />
            友達からカフェに行こうと誘われたとき、どちらが魅力的に思えるでしょうか？<br />
            <br />
            <img src={Image7} className={Styles.media_img} alt='履歴書 美容' />
            <br />
            <br />
            答えは言うまでもないと思います。<br />
            ここでの文章のポイントは、私(友達)のしたいことと、A君のしたいことがどちらも叶えられるという部分です。<br />
            <br />
            <strong>この視点が、志望理由や自己PRでも非常に重要となっています。</strong><br />
            サロンはあなたのためにあるわけではなく、サロン全体で目指している姿があり、その仲間を募集するために、採用を行っています。<br />
            そのため、サロンの目指している姿を理解し、そのうえで自分を仲間として認めてもらうためにも、まずはサロン側の目線を持ち、なぜ自分を採用するといいのかということを記載することで非常に魅力的なアピールとなります。<br />
            <br />
            また、「志望理由」や「自己PR」の具体的な文章の作り方については、以下の記事で詳しく説明しています。まだ書いていないという方は、こちらも参考にしてみてください！<br />
            <br />
            記事①：<a href='https://next.rikunabi.com/tenshokuknowhow/archives/5135/' target="_blank" rel="nofollow noopener noreferrer">
              志望動機
            </a><br />
            記事②：<a href='https://next.rikunabi.com/tenshokuknowhow/archives/5135/' target="_blank" rel="nofollow noopener noreferrer">
              自己PR
            </a><br />
          </p>
          <h3 className={Styles.media_text_text_title}>送付状を併せて送ろう！マナーで他の学生と差をつける工夫</h3><br />
          <p className={Styles.media_text_text}>
            <br />
            履歴書を郵送する際には、封筒に履歴書を入れて郵送する必要があります。<br />
            その際に、送付状を併せて入れてみましょう。<br />
            送付状はどんな書類を送ったかを記載した資料であり、送付状を送ることで確実に書類が送付されているかを確認することが出来ます。<br />
            <br />
            <img src={Image8} className={Styles.media_img} alt='送付状 美容' />
            <br />
            <br />
            送付状は上のような書類となっており、インターネット等で送付状をダウンロードすることが出来ますので「送付状 美容院」等で検索してダウンロードしてみてください！<br />
          </p>

          <div className={Styles.media_introduction}>
              <div>
                <AiFillCheckCircle className={Styles.media_introduction_icon} />
                <h3>履歴書一工夫のまとめ</h3>
              </div>
              <hr></hr>
              <ul>
                <li><span>・フリースペースでは、言いたいこと(結論)を最初に書く！</span></li>
                <li><span>・自分のことだけでなく、サロン側の視点にたった文章を記載する！</span></li>
                <li><span>・郵送で送る場合、送付状を併せて入れる！</span></li>
              </ul>
          </div>

          <div id='6' className={Styles.media_text_title}>
            <h2>6.まとめ</h2>
          </div>
          <p className={Styles.media_text_text}>
            <br />
            さて、履歴書の書き方についての記事はいかがでしたでしょうか？<br />
            ここまで読んでいただいた皆さんは、もうすでに履歴書についてのスペシャリストです！皆さんが書類選考を突破し、第一志望のサロンに合格することを祈っております。<br />
            <br />
            本サイトでは、美容学生向けに他の記事も作成しておりますので、そちらも是非ご覧ください！<br />
          </p>
        </div>
      </div>
    </DocumentMeta>
    </>
  );
}


export default Article0001;
