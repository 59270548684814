import Styles from './styles.module.css';
import React, { useState, useEffect } from 'react'
import Resume from './resume/index';
import Photo from './photo/index';
// import Evaluation from './evaluation/index';
// import { isMobile } from "react-device-detect";

import { fetchDetails } from '../../features/package/detailsPanel';
import { fetchRecruit } from '../../features/package/detailsPanel';
import { fetchApplicant } from '../../features/package/detailsPanel';

import { useDispatch, useSelector } from 'react-redux';

const DetailsMobile: React.FC = () => {

  const dispatch = useDispatch();

  const company = useSelector((state: any) => state.company.company);
  const users = useSelector((state: any) => state.auth.users);

  const recruitList = useSelector((state: any) => state.detailsPanel.recruit);
  const applicantList = useSelector((state: any) => state.detailsPanel.applicant);

  const [ resumeAppearSwich, setResumeAppearSwich ] = useState(1);

  return (
    <>
    <div className={Styles.box}>
      <div>
        <div className={Styles.box_black} 
          onClick={() => 
            {dispatch(fetchDetails(false)); dispatch(fetchRecruit(applicantList.recruitId)); dispatch(fetchApplicant(applicantList.id));}}>  
        </div>
        <div className={Styles.mobile}>
          
          <div className={Styles.mobile_title}>
            <div>
              <h4 style={resumeAppearSwich === 1 ? {borderBottom : '3px solid #AD0200', color: '#222222'} : {}}
                onClick={() => setResumeAppearSwich(1)}>履歴書</h4>
              <h4 style={resumeAppearSwich === 2 ? {borderBottom : '3px solid #AD0200', color: '#222222'} : {}}
                onClick={() => setResumeAppearSwich(2)}>memorii photo</h4>
            </div>
          </div>

          <div className={Styles.mobile_contents}>

            {resumeAppearSwich === 1 ?
              <Resume
                recruitList={recruitList}
                applicantList={applicantList}
              />
            : <></> }

            {resumeAppearSwich === 2 ?
              <Photo uid={applicantList.uid} />
            : <></> }

          </div>

        </div>
      </div>
    </div>
    </>
  );
}

export default DetailsMobile;


