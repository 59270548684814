import Styles from './styles.module.css';
import React, { useState, useEffect } from 'react';
import MakeRecruit from './6.makeRecruit/index';
import Item from './1.item/index';
import { useSelector } from 'react-redux';

const Recruit: React.FC = () => {

  const company = useSelector((state: any) => state.company.company);
  const recruitIds = useSelector((state: any) => state.recruit.recruitIds);
  const recruitList = useSelector((state: any) => state.recruit.recruitList as TypeRecruitList[]);

  const [ progress, setProgress ] = useState(0);
  const [ makeDoc, setMakeDoc ] = useState(0);

  const [ count1, setCount1 ] = useState(0);
  const [ count2, setCount2 ] = useState(0);
  const [ count3, setCount3 ] = useState(0);
  
  useEffect(() => {
    getRecruits(recruitList);
  }, [ recruitIds ]);

  function getRecruits(recruit: TypeRecruitList[]) {

    var count1 = 0;
    var count2 = 0;
    var count3 = 0;

    for (let i = 0; i < recruit.length; i++) {
      if (recruit[i].status === 1) { count1 = count1 + 1 }
      if (recruit[i].status === 2) { count2 = count2 + 1 }
      if (recruit[i].status === 3) { count3 = count3 + 1 }

      if (recruit.length === i + 1) {
        setCount1(count1);
        setCount2(count2);
        setCount3(count3);
      }
    }
  }

  interface TypeRecruitList {
    id: string;
    status: number;
    title: string;
    employment: string;
    time: string;
    pv: number;
    saves: number;
    applicants: string[];
    recruitment: number;
    goal: number;
    apply: number;
  }

  return (
    <>
    <div className='works_service'>

      <div className={Styles.progress}>
        <h4 style={progress === 0 ? {borderBottom : '3px solid #AD0200', color: '#222222'} : {}}
          onClick={() => setProgress(0)}>一覧({count1 + count2 + count3})</h4>
        <h4 style={progress === 1 ? {borderBottom : '3px solid #AD0200', color: '#222222'} : {}}
          onClick={() => setProgress(1)}>公開中({count1})</h4>
        <h4 style={progress === 2 ? {borderBottom : '3px solid #AD0200', color: '#222222'} : {}}
          onClick={() => setProgress(2)}>審査中({count2})</h4>
        <h4 style={progress === 3 ? {borderBottom : '3px solid #AD0200', color: '#222222'} : {}}
          onClick={() => setProgress(3)}>作成中({count3})</h4>
      </div>

      <div>
        <div className={Styles.recruit_title}>
          <div>
            <h4>{company.name}</h4>
          </div>
          <button onClick={() => setMakeDoc(1)}>新規求人を作成する</button>
        </div>

        <div className={Styles.recruit_table}>

          <div className={Styles.recruit_table_nav}>
            <h2 className={Styles.recruit_table_nav_1}>募集役職</h2>
            <h3 className={Styles.recruit_table_nav_2}>求人タイトル</h3>
            <h4 className={Styles.recruit_table_nav_3}>編集日</h4>
            <div className={Styles.recruit_table_nav_4}>
              <p>PV数</p>
              <p>保存数</p>
              <p>応募者数</p>
            </div>
          </div>

          {recruitList.map((data, index) => (
            <div key={index}>
              {/* 一覧 */}
              {progress === 0 ?
                <Item recruit={data} />
              : <></> }

              {/* 公開中 */}
              {progress === 1 && data.status === 1 ?
                <Item recruit={data} />
              : <></> }

              {/* 準備中 */}
              {progress === 2 && data.status === 2 ?
                <Item recruit={data} />
              : <></> }

              {/* 作成中 */}
              {progress === 3 && data.status === 3 ?
                <Item recruit={data} />
              : <></> }
            </div> 
          ))}
        </div>

        {/* <button className={Styles.store_setting} 
          onClick={() => navigate(`/edit/storeSetting/id=${company.id}+page=2`)}>店舗一覧の編集</button> */}

      </div>

    </div>

    {/* 求人作成 */}
    {makeDoc === 1 ?
      <MakeRecruit setMakeDoc={setMakeDoc} />
    : <></>}
    </>
  );
}

export default Recruit;

