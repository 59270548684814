import Styles from './styles.module.css';
import 'firebase/auth'
import React, { useState, useEffect } from 'react';
import firebase from "firebase/compat/app";
import { useNavigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import BackModal from '../11.back/index';
import Header from '../../../../0.header/index';
import DefaultTitle from '../../../../../../../package/parts/title/index';
import Image1 from './../../../../../../../image/salonType/1.png';
import Image2 from './../../../../../../../image/salonType/2.png';
import Image3 from './../../../../../../../image/salonType/3.png';
import Image4 from './../../../../../../../image/salonType/4.png';
import Image5 from './../../../../../../../image/salonType/5.png';
import Image6 from './../../../../../../../image/salonType/6.png';
import Image7 from './../../../../../../../image/salonType/7.png';
import Image8 from './../../../../../../../image/salonType/8.png';

const RecruitEditStorType: React.FC = () => {
  
  const navigate = useNavigate();

  const link: string = new URL(window.location.href).href;
  const store = decodeURIComponent(link.slice(link.indexOf('id=') + 3, link.length));

  const [ region, setRegion ] = useState(false);
  const [ strengths, setStrengths ] = useState(false);
  const [ treatment, setTreatment ] = useState(false);

  const [ backStatus, setBackStatus ] = useState(false);

  const [ statusPentagon, setStatusPentagon ] = useState(false);
  const [ statusPlace, setStatusPlace ] = useState(false);
  // const [ statusType, setStatusType ] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    return onAuthStateChanged(getAuth(), (user) => {
      if (user) {

        firebase.firestore().collection("stores").doc(store)
        .get().then((doc) => {
          const data = doc.data();
          if (data) {
            setRegion(data.region === undefined ? false : data.region);
            setStrengths(data.strengths === undefined ? false : data.strengths);
            setTreatment(data.treatment === undefined ? false : data.treatment);
  
            setStatusPentagon(data.status_pentagon === undefined ? false : data.status_pentagon);
            setStatusPlace(data.status_place === undefined ? false : data.status_place);
          }
        });

      } else {
        navigate('/company/login');
      }
    })

  }, [store])

  function check() {
    window.scrollTo(0, 0);
    save(true);
  }

  function save(e: boolean) {
    firebase.firestore().collection("stores").doc(store)
    .update({
      status: statusPlace && statusPentagon && e,
      status_type: e,

      region: region,
      strengths: strengths,
      treatment: treatment,

      type: 
        region && strengths && treatment ? '有名サロン' :  
        region && strengths && !treatment ? 'トレンドサロン' :
        region && !strengths && treatment ? 'おしゃれサロン' :
        region && !strengths && !treatment ? 'ビューティーサロン' :
        !region && strengths && treatment ? 'オールラウンドサロン' :
        !region && strengths && !treatment ? '愛されサロン' :
        !region && !strengths && treatment ? '密着サロン' :
        !region && !strengths && !treatment ? 'こだわりサロン' :
        '',

    }).then(() => {
      navigate(-1);
    });
  }

  return (
    <>

    <Header />

    <div className={Styles.box}>
      <div className={Styles.box_inline}>

        <div className={Styles.contents}>

          <h5 className={Styles.title}>サロンタイプ診断</h5>
          
          <DefaultTitle title={'立地'} required={true} />

          <div className={Styles.radio_btn}>
            <div>
              <input type="radio" name='1' checked={region === true} onClick={() => setRegion(true)} />
              <p>都会型サロン</p>
            </div>
            <div>
              <input type="radio" name='1' checked={region === false} onClick={() => setRegion(false)} />
              <p>地域型サロン</p>
            </div>
          </div>
          
          <DefaultTitle title={'強み'} required={true} />

          <div className={Styles.radio_btn}>
            <div>
              <input type="radio" name='2' checked={strengths === true} onClick={() => setStrengths(true)} />
              <p>集客力</p>
            </div>
            <div>
              <input type="radio" name='2' checked={strengths === false} onClick={() => setStrengths(false)} />
              <p>技術力</p>
            </div>
          </div>
          
          <DefaultTitle title={'施術幅'} required={true} />

          <div className={Styles.radio_btn}>
            <div>
              <input type="radio" name='3' checked={treatment === true} onClick={() => setTreatment(true)} />
              <p>オールマイティー</p>
            </div>
            <div>
              <input type="radio" name='3' checked={treatment === false} onClick={() => setTreatment(false)} />
              <p>専門的</p>
            </div>
          </div>

        </div>

        <div className={Styles.contents}>

          <h5 className={Styles.title}>サロンタイプ</h5>
          
          {
            region && strengths && treatment ? <img src={Image1} alt='' /> :
            region && strengths && !treatment ? <img src={Image2} alt='' /> :
            region && !strengths && treatment ? <img src={Image3} alt='' /> :
            region && !strengths && !treatment ? <img src={Image4} alt='' /> :
            !region && strengths && treatment ? <img src={Image5} alt='' /> :
            !region && strengths && !treatment ? <img src={Image6} alt='' /> :
            !region && !strengths && treatment ? <img src={Image7} alt='' /> :
            !region && !strengths && !treatment ? <img src={Image8} alt='' /> : <></>
          }

        </div>

        <div className={Styles.save_box}>
          <button className={Styles.save_box_now} onClick={() => setBackStatus(true)}>戻る</button>
          <button className={Styles.save_box_now} onClick={() => save(false)}>一時保存</button>
          <button className={Styles.save_box_save} onClick={() => check()}>保存</button>
        </div>
        
      </div>
    </div>

    {backStatus ?
      <BackModal
        setBackStatus={setBackStatus}
      />
    : <></> }
    </>
  );
}

export default RecruitEditStorType;