import Styles from './styles.module.css';
import React, { useState, useEffect } from 'react';
import firebase from "firebase/compat/app";
import DefaultImg from './../../../image/default/account.png';

import { useDispatch, useSelector } from 'react-redux';
import { fetchMessages } from '../../../features/6.contact/messages';

type ManageNavProps = {
  chatList: Chat;
};

interface Chat {
  id: string;
  ids: string[];
  names: string[];
  reason: string;
  time: string;
  applicantId: string;
  badges: number,
  recruitId: string,
}

interface Message {
  id: string;
  send: string;
  receive: string;
  text: string;
  time: string;
}

const ContactMessage: React.FC<ManageNavProps> = ({ chatList }) => {

  const dispatch = useDispatch();
  const message = useSelector((state: any) => state.messages.list as Message[]);
  const company = useSelector((state: any) => state.company.company);
  const users = useSelector((state: any) => state.auth.users);

  const [ receiveUid, setReceiveUid ] = useState<string>('');
  const [ receiveName, setReceiveName ] = useState<string>('');
  const [ sendUid, setSendUid ] = useState<string>('');

  useEffect(() => {
    dispatch(fetchMessages(chatList.id));

    setReceiveUid(chatList.names.indexOf(company.id) === 1 ? chatList.names[3] : chatList.names[1]);
    setReceiveName(chatList.names.indexOf(company.id) === 1 ? chatList.names[2] : chatList.names[0]);
    setSendUid(chatList.names.indexOf(company.id) === 1 ? chatList.names[1] : chatList.names[3]);
  }, [chatList]);


  const [ sendText, setSendText ] = useState('');

  function send() {
    firebase.firestore().collection('chat').doc(chatList.id).collection('message').doc()
    .set({
      message_send: sendUid,
      message_receive: receiveUid,
      message_text: sendText,
      message_time: new Date(),
      message_id: chatList.id,
    });

    const badge_name = `${receiveUid}_badge`;
    firebase.firestore().collection('chat').doc(chatList.id)
    .update({
      chat_last: sendText,
      [badge_name]: firebase.firestore.FieldValue.increment(1),
    });

    firebase.firestore().collection('users').doc(users.uid)
    .update({
      user_badge: firebase.firestore.FieldValue.increment(1),
    });

    setSendText('');

    dispatch(fetchMessages(chatList.id));
  }

  return (
    <>
    <div className={Styles.message_title}>
      <div>
        <img src={`https://firebasestorage.googleapis.com/v0/b/trankllc-com.appspot.com/o/profiles%2Fresize_images%2F${receiveUid}_200x200?alt=media&token=`}
          onError={e => {
            e.currentTarget.onerror = null;
            e.currentTarget.src = DefaultImg;
          }}/>
        <h5>{receiveName}</h5>
      </div>
      {/* {chatList.reason === '応募' ?
        <h4 onClick={() => { setRecruitId(recruitId); setApplicantId(applicantId);}}>情報</h4>
      : <></> } */}
    </div>

    <div className={Styles.message_message}>
      {message.map((data) =>
      <>
        {data.send === company.id ?
          <div className={Styles.message_message_sender}>
            <p>{new Date(data.time).getFullYear()}年{new Date(data.time).getMonth() + 1}月{new Date(data.time).getDate()}日</p>
            <h4>{data.text}</h4>
          </div>
          :
          <div className={Styles.message_message_received}>
            <img src={`https://firebasestorage.googleapis.com/v0/b/trankllc-com.appspot.com/o/profiles%2Fresize_images%2F${data.receive}_200x200?alt=media&token=`}
              onError={e => {
                e.currentTarget.onerror = null;
                e.currentTarget.src = DefaultImg;
              }}/>
            <h4>{data.text}</h4>
            <p>{new Date(chatList.time).getFullYear()}年{new Date(chatList.time).getMonth() + 1}月{new Date(chatList.time).getDate()}日</p>
          </div>
        }
      </> )}
    </div>

    <div className={Styles.message_input}>
      <div>
        <textarea placeholder='初めまして！採用担当の伊藤です。'
          value={sendText}
          onChange={(e) => {
            e.target.style.height = '0px';
            e.target.style.height = e.target.scrollHeight + 'px';
            setSendText(e.target.value);
          }}/>
        <button onClick={() => send()}>送信</button>
      </div>
    </div>
    </>
  );
}

export default ContactMessage;