// actions.ts
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import firebase from "firebase/compat/app";
import { collection, query, where, onSnapshot } from 'firebase/firestore';

interface Applicant {
  id: string;
  recruitId: string;
  name: string;
  uid: string;
  examination: number;
  sex: string;
  time: string;
  managerMemo: string;
  hopeStore: string[];
  examList1: string[];
  examList2: string[];
  examList3: string[];
  examList4: string[];
  examList5: string[];
}

interface ApplicantsState {
  applicantsListTrue: Applicant[];
  applicantsListFalse: Applicant[];
  progressCount: number[];
}

const initialState: ApplicantsState = {
  applicantsListTrue: [],
  applicantsListFalse: [],
  progressCount: [],
};

interface FetchManageApplicantsPayload {
  applicantsListTrue: Applicant[];
  applicantsListFalse: Applicant[];
  progressCount: number[];
}

interface getList {
  uid: string;
  ids: string[];
}

// Thunk action to fetch applicants data
export const fetchManageApplicants = createAsyncThunk(
  'manageApplicants/fetchManageApplicants',
  async (list: getList, { rejectWithValue }) => {
    try {
      const result = await new Promise<FetchManageApplicantsPayload>((resolve, reject) => {
        const db = firebase.firestore();
        const docRef = collection(db, 'applicants');
        const queryRef = query(docRef, where('recruitId', 'in', list.ids));
        
        const unsubscribe = onSnapshot(queryRef, (snapshot) => {
          
          let tmpTrue: Applicant[] = [];
          let tmpFalse: Applicant[] = [];
          let counts = Array(8).fill(0);

          counts = [0, 0, 0, 0, 0, 0, 0, 0];
          tmpTrue = [];
          tmpFalse = [];

          snapshot.docs.forEach((doc) => {
            const data = doc.data();
            const exam = data.examination || 0;

            if (1 <= exam && exam <= 7) {
              counts[exam]++;
            }

            const applicant = {
              id: doc.id,
              recruitId: data.recruitId,
              name: data.name,
              uid: data.uid,
              examination: data.examination,
              sex: data.sex,
              time: new Date(
                data?.time.seconds * 1000 + data?.time.nanoseconds / 1000000
              )?.toISOString(),
              managerMemo: data.managerMemo,
              hopeStore: data.hopeStore,
              examList1: data.examList1,
              examList2: data.examList2,
              examList3: data.examList3,
              examList4: data.examList4,
              examList5: data.examList5,
            };

            if (data.save.includes(list.uid)) {
              tmpTrue.push(applicant);
            } else {
              tmpFalse.push(applicant);
            }

            resolve({
              applicantsListTrue: tmpTrue,
              applicantsListFalse: tmpFalse,
              progressCount: counts,
            });
          });

          unsubscribe();

        }, reject);
      });
      return result;

    } catch (error) {
      return rejectWithValue('error');
    }
  }
);

// Slice
const applicantsSlice = createSlice({
  name: 'manageApplicants',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchManageApplicants.fulfilled, (state, action) => {
        state.applicantsListTrue = action.payload.applicantsListTrue;
        state.applicantsListFalse = action.payload.applicantsListFalse;
        state.progressCount = action.payload.progressCount;
      })
  },
});

export default applicantsSlice.reducer;
