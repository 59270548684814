import DocumentMeta from 'react-document-meta';
import './styles.css';
import React, { useState, useEffect } from 'react';
import firebase from 'firebase/compat/app';
import { onSnapshot, collection, query, where, orderBy, } from 'firebase/firestore';
import Header from './0.header/index';
import Navigation from './navigation/index';
import Home from './1.home/index';
import Recruit from './2.recruit/index';
import Photo from './3.photo/index';
import Manage from './4.manage/index';
import Schedule from './5.schedule/index';
import Messages from './6.contact/main';
import Setting from './7.setting/index';
import { useNavigate } from 'react-router-dom';
import { isMobile } from "react-device-detect";

import Details from "./../mobile/info/index";
import { useDispatch, useSelector } from 'react-redux';
import { fetchAuthState } from '../features/0.main/auth';
import { fetchCompanyState } from '../features/0.main/company';
import { fetchRecruits } from '../features/0.main/recruit';
import { fetchNavigation } from '../features/0.main/navigation';

import SearviceMobile from './../mobile/index';

const Service: React.FC = () => {
  
  const dispatch = useDispatch();

  const company = useSelector((state: any) => state.company.company);
  const details = useSelector((state: any) => state.detailsPanel.details);
  const navigation = useSelector((state: any) => state.navigation.number);

  const [ authority, setAuthority ] = useState(false);

  useEffect(() => {
    dispatch(fetchRecruits(company.id));
  }, [ company ]);

  useEffect(() => {
    if (!isMobile) { 
      const fetchData = async () => {
  
        var fetchUsers =  await dispatch(fetchAuthState());
  
        if (fetchAuthState.fulfilled.match(fetchUsers)) {
          const users = fetchUsers.payload;
  
          var fetchCompany = await dispatch(fetchCompanyState(users.id));
          
          if (fetchCompanyState.fulfilled.match(fetchCompany)) {
            const company = fetchCompany.payload;
  
            if (company.administrator.includes(users.uid)) {
              setAuthority(true);
            } else if (company.member.includes(users.uid)) {
              if (Number(navigation) !== 4 || Number(navigation) !== 5 || Number(navigation) !== 6) {
                dispatch(fetchNavigation(4));
              }
            }
        
            if (isMobile) {
              if (Number(navigation) === 4 || Number(navigation) === 5 || Number(navigation) === 6) {
                dispatch(fetchNavigation(4));
              }
            }
          }
  
          await dispatch(fetchRecruits(company.id));
        }
      }
      fetchData();
    }
  }, [ dispatch ]);

  return (
    <>
    {!isMobile ?
    <>
      <Header />
      
      <Navigation
        navigation={navigation}
        authority={authority}
      />

      <div>
        {navigation === 1 && authority ?
          <Home />
            : navigation === 2 && authority ?
          <Recruit />
            : navigation === 3 && authority ?
          <Photo />
            : navigation === 4 ?
          <Manage />
            : navigation === 5 ?
          <Schedule />
            : navigation === 6 && authority ?
          <Messages />
            : navigation === 7 && authority ?
          <Setting />
          : <></>
        }
      </div>

      {details ?
        <Details />
      : <></> }
    </> : 
    <SearviceMobile />
    }
    </>
  );
}

export default Service;
