import 'firebase/auth';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import firebase from 'firebase/compat/app';
import liff from "@line/liff";
import { useDispatch, useSelector } from 'react-redux';
import { fetchAuthState } from 'src/features/0.main/auth';
import { fetchCompanyState } from 'src/features/0.main/company';
import { fetchRecruits } from 'src/features/0.main/recruit';

const CallbackPage: React.FC = () => {

  const users = useSelector((state: any) => state.auth.users);
  const company = useSelector((state: any) => state.company.company);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {

    const fetchData = async () => {
  
      var fetchUsers =  await dispatch(fetchAuthState());

      if (fetchAuthState.fulfilled.match(fetchUsers)) {
        const users = fetchUsers.payload;

        var fetchCompany = await dispatch(fetchCompanyState(users.id));
        
        if (fetchCompanyState.fulfilled.match(fetchCompany)) {
          const company = fetchCompany.payload;

          liff
          .init({
            liffId: '2006500323-aKDEKRzl',
            withLoginOnExternalBrowser: true,
          })
          .then(() => {
            liff.ready.then(() => {
              if (liff.isLoggedIn()) {
                liff
                .getProfile()
                .then((profile) => {
                  return writeLineId(profile.userId, profile.pictureUrl, profile.displayName, company.id, users.id,);
                });
              }
            });
          });
        }
      }
    }
    fetchData();
    
  }, [users, company]);

  async function writeLineId(id: string, url: string | undefined, name: string, company: string, user: string,) {

    console.log(company);
    await firebase.firestore().collection('companies').doc(company)
    .update({
      user_lineId: id,
      user_linePicture: url,
      user_lineName: name,
    })
    .then(() => {
      console.log('言ってるはず1');
      navigate(`/service`);
    });

    console.log(user);

    await firebase.firestore().collection('users').doc(user)
    .update({
      user_lineId: id,
      user_linePicture: url,
      user_lineName: name,
    })
    .then(() => {
      console.log('言ってるはず2');
      navigate(`/service`);
    });
  }

  return (
    <>
    <h4></h4>
    </>
  );
}

export default CallbackPage;