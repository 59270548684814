import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import firebase from "firebase/compat/app";

export const fetchDetails = createAsyncThunk(
  'detailsPanel/fetchDetails',
  async (status: boolean, { rejectWithValue }) => {
    return status;
  }
);

export const fetchRecruit = createAsyncThunk(
  'detailsPanel/fetchRecruit',
  async (recruitId: string, { rejectWithValue }) => {
    try {
      if (recruitId !== '') {
        const doc = await firebase.firestore().collection('recruits').doc(recruitId).get();
        if (doc.exists) {

          if (doc.data()?.exam_selection === 0) {
            return {
              id: doc.id,
              companyId: doc.data()?.companyId,
              selection: 0,
              selectionCount: doc.data()?.exam_selection1Count,
              selectionText1: doc.data()?.exam_selection1Text1,
              selectionText2: doc.data()?.exam_selection1Text2,
              selectionText3: doc.data()?.exam_selection1Text3,
              selectionText4: doc.data()?.exam_selection1Text4,
              selectionText5: doc.data()?.exam_selection1Text5,
              selectionText6: doc.data()?.exam_selection1Text6,
              selectionText7: doc.data()?.exam_selection1Text7,
              selectionText8: doc.data()?.exam_selection1Text8,

              resume: doc.data()?.document_resume,
              coordinate: doc.data()?.document_coordinate,
              question: doc.data()?.document_question,
              questionCount: doc.data()?.document_questionCount,
              question01: doc.data()?.document_question01,
              question02: doc.data()?.document_question02,
              question03: doc.data()?.document_question03,
              otherDocument: doc.data()?.document_otherDocument,
              otherDocumentCount: doc.data()?.document_otherDocumentCount,
              otherDocument01: doc.data()?.document_otherDocument01,
              otherDocument02: doc.data()?.document_otherDocument02,
              otherDocument03: doc.data()?.document_otherDocument03,
            };
          } else if (doc.data()?.exam_selection === 1) {
            return {
              id: doc.id,
              companyId: doc.data()?.companyId,
              selection: 1,
              selectionCount: doc.data()?.exam_selection1Count,
              selectionText1: doc.data()?.exam_selection1Text1,
              selectionText2: doc.data()?.exam_selection1Text2,
              selectionText3: doc.data()?.exam_selection1Text3,
              selectionText4: doc.data()?.exam_selection1Text4,
              selectionText5: doc.data()?.exam_selection1Text5,
              selectionText6: doc.data()?.exam_selection1Text6,
              selectionText7: doc.data()?.exam_selection1Text7,
              selectionText8: doc.data()?.exam_selection1Text8,

              resume: doc.data()?.document_resume,
              coordinate: doc.data()?.document_coordinate,
              question: doc.data()?.document_question,
              questionCount: doc.data()?.document_questionCount,
              question01: doc.data()?.document_question01,
              question02: doc.data()?.document_question02,
              question03: doc.data()?.document_question03,
              otherDocument: doc.data()?.document_otherDocument,
              otherDocumentCount: doc.data()?.document_otherDocumentCount,
              otherDocument01: doc.data()?.document_otherDocument01,
              otherDocument02: doc.data()?.document_otherDocument02,
              otherDocument03: doc.data()?.document_otherDocument03,
            };
          } else if (doc.data()?.exam_selection === 2) {
            return {
              id: doc.id,
              companyId: doc.data()?.companyId,
              selection: 2,
              selectionCount: doc.data()?.exam_selection2Count,
              selectionText1: doc.data()?.exam_selection2Text1,
              selectionText2: doc.data()?.exam_selection2Text2,
              selectionText3: doc.data()?.exam_selection2Text3,
              selectionText4: doc.data()?.exam_selection2Text4,
              selectionText5: doc.data()?.exam_selection2Text5,
              selectionText6: doc.data()?.exam_selection2Text6,
              selectionText7: doc.data()?.exam_selection2Text7,
              selectionText8: doc.data()?.exam_selection2Text8,

              resume: doc.data()?.document_resume,
              coordinate: doc.data()?.document_coordinate,
              question: doc.data()?.document_question,
              questionCount: doc.data()?.document_questionCount,
              question01: doc.data()?.document_question01,
              question02: doc.data()?.document_question02,
              question03: doc.data()?.document_question03,
              otherDocument: doc.data()?.document_otherDocument,
              otherDocumentCount: doc.data()?.document_otherDocumentCount,
              otherDocument01: doc.data()?.document_otherDocument01,
              otherDocument02: doc.data()?.document_otherDocument02,
              otherDocument03: doc.data()?.document_otherDocument03,
            };
          } else if (doc.data()?.exam_selection === 3) {
            return {
              id: doc.id,
              companyId: doc.data()?.companyId,
              selection: 3,
              selectionCount: doc.data()?.exam_selection3Count,
              selectionText1: doc.data()?.exam_selection3Text1,
              selectionText2: doc.data()?.exam_selection3Text2,
              selectionText3: doc.data()?.exam_selection3Text3,
              selectionText4: doc.data()?.exam_selection3Text4,
              selectionText5: doc.data()?.exam_selection3Text5,
              selectionText6: doc.data()?.exam_selection3Text6,
              selectionText7: doc.data()?.exam_selection3Text7,
              selectionText8: doc.data()?.exam_selection3Text8,

              resume: doc.data()?.document_resume,
              coordinate: doc.data()?.document_coordinate,
              question: doc.data()?.document_question,
              questionCount: doc.data()?.document_questionCount,
              question01: doc.data()?.document_question01,
              question02: doc.data()?.document_question02,
              question03: doc.data()?.document_question03,
              otherDocument: doc.data()?.document_otherDocument,
              otherDocumentCount: doc.data()?.document_otherDocumentCount,
              otherDocument01: doc.data()?.document_otherDocument01,
              otherDocument02: doc.data()?.document_otherDocument02,
              otherDocument03: doc.data()?.document_otherDocument03,
            };
          } else if (doc.data()?.exam_selection === 4) {
            return {
              id: doc.id,
              companyId: doc.data()?.companyId,
              selection: 4,
              selectionCount: doc.data()?.exam_selection4Count,
              selectionText1: doc.data()?.exam_selection4Text1,
              selectionText2: doc.data()?.exam_selection4Text2,
              selectionText3: doc.data()?.exam_selection4Text3,
              selectionText4: doc.data()?.exam_selection4Text4,
              selectionText5: doc.data()?.exam_selection4Text5,
              selectionText6: doc.data()?.exam_selection4Text6,
              selectionText7: doc.data()?.exam_selection4Text7,
              selectionText8: doc.data()?.exam_selection4Text8,

              resume: doc.data()?.document_resume,
              coordinate: doc.data()?.document_coordinate,
              question: doc.data()?.document_question,
              questionCount: doc.data()?.document_questionCount,
              question01: doc.data()?.document_question01,
              question02: doc.data()?.document_question02,
              question03: doc.data()?.document_question03,
              otherDocument: doc.data()?.document_otherDocument,
              otherDocumentCount: doc.data()?.document_otherDocumentCount,
              otherDocument01: doc.data()?.document_otherDocument01,
              otherDocument02: doc.data()?.document_otherDocument02,
              otherDocument03: doc.data()?.document_otherDocument03,
            };
          } else if (doc.data()?.exam_selection === 5) {
            return {
              id: doc.id,
              companyId: doc.data()?.companyId,
              selection: 5,
              selectionCount: doc.data()?.exam_selection5Count,
              selectionText1: doc.data()?.exam_selection5Text1,
              selectionText2: doc.data()?.exam_selection5Text2,
              selectionText3: doc.data()?.exam_selection5Text3,
              selectionText4: doc.data()?.exam_selection5Text4,
              selectionText5: doc.data()?.exam_selection5Text5,
              selectionText6: doc.data()?.exam_selection5Text6,
              selectionText7: doc.data()?.exam_selection5Text7,
              selectionText8: doc.data()?.exam_selection5Text8,

              resume: doc.data()?.document_resume,
              coordinate: doc.data()?.document_coordinate,
              question: doc.data()?.document_question,
              questionCount: doc.data()?.document_questionCount,
              question01: doc.data()?.document_question01,
              question02: doc.data()?.document_question02,
              question03: doc.data()?.document_question03,
              otherDocument: doc.data()?.document_otherDocument,
              otherDocumentCount: doc.data()?.document_otherDocumentCount,
              otherDocument01: doc.data()?.document_otherDocument01,
              otherDocument02: doc.data()?.document_otherDocument02,
              otherDocument03: doc.data()?.document_otherDocument03,
            };
          }
        } else {
          return rejectWithValue("Recruit not found");
        }
      } else {
        return ([]);
      }
    } catch (error) {
      return rejectWithValue("Error fetching recruit");
    }
  }
);

export const fetchApplicant = createAsyncThunk(
  'detailsPanel/fetchApplicant',
  async (applicantId: string, { rejectWithValue }) => {
    try {
      if (applicantId !== '') {
        const doc = await firebase.firestore().collection('applicants').doc(applicantId).get();
        if (doc.exists) {
          return {
            id: doc.id,
            uid: doc.data()?.uid,
            examination: doc.data()?.examination,
            recruitId: doc.data()?.recruitId,
            time: new Date(doc.data()?.time.seconds * 1000 + doc.data()?.time.nanoseconds / 1000000)?.toISOString(),
            name: doc.data()?.name,
            nameHurigana: doc.data()?.nameHurigana,
            birthdayYears: doc.data()?.birthdayYears,
            birthdayMonth: doc.data()?.birthdayMonth,
            birthdayDays: doc.data()?.birthdayDays,
            age: doc.data()?.age,
            sex: doc.data()?.sex,
            bloodType: doc.data()?.bloodType,
            addressHurigana: doc.data()?.addressHurigana,
            postCode: doc.data()?.postCode,
            address: doc.data()?.address,
            phoneNumber: doc.data()?.phoneNumber,
            mailAddress: doc.data()?.mailAddress,
            history1: doc.data()?.history1,
            historyYears1: doc.data()?.historyYears1,
            historyMonth1: doc.data()?.historyMonth1,
            history2: doc.data()?.history2,
            historyYears2: doc.data()?.historyYears2,
            historyMonth2: doc.data()?.historyMonth2,
            history3: doc.data()?.history3,
            historyYears3: doc.data()?.historyYears3,
            historyMonth3: doc.data()?.historyMonth3,
            history4: doc.data()?.history4,
            historyYears4: doc.data()?.historyYears4,
            historyMonth4: doc.data()?.historyMonth4,
            history5: doc.data()?.history5,
            historyYears5: doc.data()?.historyYears5,
            historyMonth5: doc.data()?.historyMonth5,
            history6: doc.data()?.history6,
            historyYears6: doc.data()?.historyYears6,
            historyMonth6: doc.data()?.historyMonth6,
            award1: doc.data()?.award1,
            awardYears1: doc.data()?.awardYears1,
            awardMonth1: doc.data()?.awardMonth1,
            award2: doc.data()?.award2,
            awardYears2: doc.data()?.awardYears2,
            awardMonth2: doc.data()?.awardMonth2,
            award3: doc.data()?.award3,
            awardYears3: doc.data()?.awardYears3,
            awardMonth3: doc.data()?.awardMonth3,
            instagram: doc.data()?.instagram,
            instagramFollower: doc.data()?.instagramFollower,
            tiktok: doc.data()?.tiktok,
            tiktokFollower: doc.data()?.tiktokFollower,
            twitter: doc.data()?.twitter,
            twitterFollower: doc.data()?.twitterFollower,
            motivation: doc.data()?.motivation,
            prText: doc.data()?.prText,
            coordinate1Title: doc.data()?.coordinate1Title,
            coordinate1Point: doc.data()?.coordinate1Point,
            coordinate2Title: doc.data()?.coordinate2Title,
            coordinate2Point: doc.data()?.coordinate2Point,
            coordinate3Title: doc.data()?.coordinate3Title,
            coordinate3Point: doc.data()?.coordinate3Point,
            question01Text: doc.data()?.question01Text,
            question02Text: doc.data()?.question02Text,
            question03Text: doc.data()?.question03Text,
          };
        } else {
          return rejectWithValue("Applicant not found");
        }
      } else {
        return ([]);
      }
    } catch (error) {
      return rejectWithValue("Error fetching applicant");
    }
  }
);

interface RecruitState {
  details: false,
  recruit: RecruitList;
  applicant: ApplicantList;
}

const recruit: RecruitList = {
  id: "",
  selection: 1,
  selectionCount: 5,
  selectionText1: "",
  selectionText2: "",
  selectionText3: "",
  selectionText4: "",
  selectionText5: "",
  selectionText6: "",
  selectionText7: "",
  selectionText8: "",

  resume: false,
  coordinate: false,
  question: false,
  otherDocument: false,
  questionCount: 0,
  question01: '',
  question02: '',
  question03: '',
  otherDocumentCount: 0,
  otherDocument01: '',
  otherDocument02: '',
  otherDocument03: '',
};

const applicant: ApplicantList = {
  id: '',
  examination: 0,
  recruitId: '',
  time: '',
  name: '',
  nameHurigana: '',
  birthdayYears: '',
  birthdayMonth: '',
  birthdayDays: '',
  age: '',
  sex: '',
  bloodType: '',
  addressHurigana: '',
  postCode: '',
  address: '',
  phoneNumber: '',
  mailAddress: '',
  history1: '',
  historyYears1: '',
  historyMonth1: '',
  history2: '',
  historyYears2: '',
  historyMonth2: '',
  history3: '',
  historyYears3: '',
  historyMonth3: '',
  history4: '',
  historyYears4: '',
  historyMonth4: '',
  history5: '',
  historyYears5: '',
  historyMonth5: '',
  history6: '',
  historyYears6: '',
  historyMonth6: '',
  award1: '',
  awardYears1: '',
  awardMonth1: '',
  award2: '',
  awardYears2: '',
  awardMonth2: '',
  award3: '',
  awardYears3: '',
  awardMonth3: '',
  instagram: '',
  instagramFollower: '',
  tiktok: '',
  tiktokFollower: '',
  twitter: '',
  twitterFollower: '',
  motivation: '',
  prText: '',
  coordinate1Title: '',
  coordinate1Point: '',
  coordinate2Title: '',
  coordinate2Point: '',
  coordinate3Title: '',
  coordinate3Point: '',
  question01Text: '',
  question02Text: '',
  question03Text: '',
};

const initialState: RecruitState = {
  details: false,
  recruit: recruit,
  applicant: applicant,
};

interface RecruitList {
  id: string;
  selection: number,
  selectionCount: number,
  selectionText1: string,
  selectionText2: string,
  selectionText3: string,
  selectionText4: string,
  selectionText5: string,
  selectionText6: string,
  selectionText7: string,
  selectionText8: string,

  resume: boolean;
  coordinate: boolean;
  question: boolean;
  questionCount: number;
  question01: string;
  question02: string;
  question03: string;
  otherDocument: boolean;
  otherDocumentCount: number;
  otherDocument01: string;
  otherDocument02: string;
  otherDocument03: string;
}

interface ApplicantList {
  id: string;
  examination: number;
  recruitId: string;
  time: string;
  name: string;
  nameHurigana: string;
  birthdayYears: string;
  birthdayMonth: string;
  birthdayDays: string;
  age: string;
  sex: string;
  bloodType: string;
  addressHurigana: string;
  postCode: string;
  address: string;
  phoneNumber: string;
  mailAddress: string;
  history1: string;
  historyYears1: string;
  historyMonth1: string;
  history2: string;
  historyYears2: string;
  historyMonth2: string;
  history3: string;
  historyYears3: string;
  historyMonth3: string;
  history4: string;
  historyYears4: string;
  historyMonth4: string;
  history5: string;
  historyYears5: string;
  historyMonth5: string;
  history6: string;
  historyYears6: string;
  historyMonth6: string;
  award1: string;
  awardYears1: string;
  awardMonth1: string;
  award2: string;
  awardYears2: string;
  awardMonth2: string;
  award3: string;
  awardYears3: string;
  awardMonth3: string;
  instagram: string;
  instagramFollower: string;
  tiktok: string;
  tiktokFollower: string;
  twitter: string;
  twitterFollower: string;
  motivation: string;
  prText: string;
  coordinate1Title: string;
  coordinate1Point: string;
  coordinate2Title: string;
  coordinate2Point: string;
  coordinate3Title: string;
  coordinate3Point: string;
  question01Text: string;
  question02Text: string;
  question03Text: string;
}

// Slice
const recruitsSlice = createSlice({
  name: 'detailsPanel',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchDetails.fulfilled, (state, action) => {
        state.details = action.payload as false;
      })
      .addCase(fetchRecruit.fulfilled, (state, action) => {
        state.recruit = action.payload as RecruitList;
      })
      .addCase(fetchApplicant.fulfilled, (state, action) => {
        state.applicant = action.payload as ApplicantList;
      })
  },
});

export default recruitsSlice.reducer;
