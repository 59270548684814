import React from 'react';
import Styles from './styles.module.css';

type DefaultSelect2Props = {
  title: string,
  required: Boolean,
  value1: string,
  setValue1: React.Dispatch<React.SetStateAction<string>>;
  value2: string,
  setValue2: React.Dispatch<React.SetStateAction<string>>;
  list1: string[],
  list2: string[],
  desc: string;
};

const DefaultSelect2: React.FC<DefaultSelect2Props> = ({ title, required, value1, setValue1, value2, setValue2, list1, list2, desc }) => {

  return (
    <>
    <div className={Styles.title}>
      <div>
        <p>{title}</p>
        <div className={required ? Styles.title_yes : Styles.title_no}>{required ? '※必須' : '任意'}</div>
      </div>
      <p></p>
    </div>
    <div className={Styles.select}>
      <select className={Styles.select} value={value1} onChange={(e) => setValue1(e.target.value)}>
        {list1.map((data, index) =>
          <option value={data === '未選択' ? '' : data} key={index}>{data}</option>
        )}
      </select>
      <p>〜</p>
      <select className={Styles.select} value={value2} onChange={(e) => setValue2(e.target.value)}>
        {list2.map((data, index) =>
          <option value={data === '未選択' ? '' : data} key={index}>{data}</option>
        )}
      </select>
    </div>
    <p className={Styles.desc}>{desc}</p>
    </>
  );
}

export default DefaultSelect2;
