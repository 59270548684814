import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import firebase from "firebase/compat/app";

interface Chat {
  id: string;
  ids: string[];
  names: string[];
  reason: string;
  time: string;
  applicantId: string;
  badges: number,
  recruitId: string,
}

interface ChatState {
  list: Chat[];
}

export const fetchChats = createAsyncThunk(
  'contacts/fetchChats',
  async (id: string, { rejectWithValue }) => {
    try {
      const querySnapshot = await firebase.firestore().collection('chat')
        .where('companyId', '==', id).orderBy('chat_time', 'asc').get();
      
      const chats: any[] = [];
      querySnapshot.forEach((doc) => {

        const data = doc.data();
        if (data) {
          chats.push({
            id: doc.id,
            ids: doc.data().chat_name,
            names: [ ...doc.data()[doc.data().chat_name[0]], ...doc.data()[doc.data().chat_name[1]] ], 
            badges: doc.data()[`${id}_badge`], 
            recruitId: doc.data().recruitId, 
            reason: doc.data().reason, 
            applicantId: doc.data().applicantId, 
            time: new Date(doc.data().chat_time.seconds * 1000 + doc.data().chat_time.nanoseconds / 1000000)?.toISOString()
          });
        }
      });

      return chats;
    } catch (error) {
      return rejectWithValue('Error fetching chats');
    }
  }
);

const chatList = createSlice({
  name: 'contacts',
  initialState: {
    list: [] as Chat[],
  } as ChatState, 
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchChats.fulfilled, (state, action) => {
        state.list = action.payload;
      })
  },
});

export default chatList.reducer;
