
import DocumentMeta from 'react-document-meta';
import Styles from '../styles.module.css';
import React from 'react';
import { AiFillCheckCircle } from "react-icons/ai";
import AnchorLink from 'react-anchor-link-smooth-scroll';
import Icon from './../../image/media/1/top.png';
import Image from './../../image/media/3/top.png';
import Image1 from './../../image/media/3/1.png';
import Image2 from './../../image/media/3/2.png';
import Image3 from './../../image/media/3/3.png';
import Image4 from './../../image/media/3/4.png';
import Image5 from './../../image/media/3/5.png';
import Image6 from './../../image/media/3/6.png';
import Image7 from './../../image/media/3/7.png';
import Image8 from './../../image/media/3/8.png';
import Image9 from './../../image/media/3/9.png';
import Image10 from './../../image/media/3/10.png';
import Image11 from './../../image/media/3/11.png';

const Article0001 = () => {

  const meta = {
    title: '【初心者向け】X(Twitter)の基本的な使い方を分かりやすく解説！-美容学生',
    description: '2023年にイーロン・マスク氏が買収したことで話題になったTwitter。現在もXという名前でサービス展開を行っています。Xに変わったことによる機能の変化はありませんが、ロゴや水色の公式マークの概要、各種の機能名称などが変わりました。未だに進化を続けいているXについて、基本的な使い方を紹介します！',
    canonical: 'https://memorii-works.com/',
    meta: { charset: 'utf-8', }
  };

  return (
    <>
    <DocumentMeta {...meta}>
      <div className={Styles.media_nav}>
        <a className={Styles.media_nav_icon} href='https://trankllc.com/media/subsidy/business/0000'>
          <img src={Icon} loading="lazy" alt='memorii' />
          <h2>美容学生の教科書</h2>
        </a>
        <a className={Styles.media_nav_contact} href='https://trankllc.com/contact'>
          <p>問い合わせ</p>
        </a>
      </div>
      <div className={Styles.media}>
        <div className={Styles.media_article}>
          
          <p className={Styles.media_date}>2024/04/20 更新日: -</p>
          <h1 className={Styles.media_title}>【初心者向け】X(Twitter)の基本的な使い方を分かりやすく解説！-美容学生</h1>
          <img src={Image} className={Styles.media_title_img} alt='美容学生 X' />
    
          <p className={Styles.media_text_text}>
            <br />
            <strong>2023年にイーロン・マスク氏が買収したことで話題になったTwitter</strong>。現在もXという名前でサービス展開を行っています。<br />
            Xに変わったことによる機能の変化はありませんが、ロゴや水色の公式マークの概要、各種の機能名称などが変わりました。<br />
            <strong>未だに進化を続けいているXについて、基本的な使い方を紹介します！</strong><br />
          </p>
    
          <div className={Styles.media_introduction}>
            <div>
              <AiFillCheckCircle className={Styles.media_introduction_icon} />
              <h3>この記事でこの3点が分かる！</h3>
            </div>
            <hr></hr>
            <ul>
              <li><span>X(旧Twitter)の概要</span></li>
              <li><span>X(旧Twitter)の機能一覧</span></li>
              <li><span>X(旧Twitter)の楽しみ方</span></li>
            </ul>
          </div>
    
          <div className={Styles.media_contents}>
            <div className={Styles.media_contents_title}>
              本記事の目次
            </div>
            <div className={Styles.media_contents_contents}>
              <ol>
                <li><AnchorLink href="#1" offset="100"><strong>Xとは？</strong></AnchorLink></li>
                <li><AnchorLink href="#2" offset="100"><strong>基本機能</strong></AnchorLink></li>
                <li><AnchorLink href="#3" offset="100"><strong>Xの楽しみ方</strong></AnchorLink></li>
                <li><AnchorLink href="#4" offset="100"><strong>まとめ</strong></AnchorLink></li>
              </ol>
            </div>
          </div>
    
          <div id='1' className={Styles.media_text_title}>
            <h2>1.Xとは？</h2>
          </div>
          <p className={Styles.media_text_text}>
            <br />
            <img src={Image1} className={Styles.media_img} alt='X とは' /><br />
            <br />
            X(エックス)は、Twitterが名前を変えたSNSとなっており、世界中のユーザーに使われています。<br />
            <strong>Xは文章をメインに投稿するSNSとなっており、140字以内の文章と任意の写真を併せて投稿することが出来ます。</strong><br />
            世界の中でも特に日本では人気が高く、全年代に周知されている、拡散力の高いSNSとなっています。<br />
          </p>

          <div id='2' className={Styles.media_text_title}>
            <h2>2.Xの基本機能</h2>
          </div>
          <br />
          <p className={Styles.media_text_text}>
            <img src={Image2} className={Styles.media_img} alt='X 機能' />
          参照：<a href='https://twitter.com/X' target="_blank" rel="nofollow noopener noreferrer">
            X 公式アカウント
          </a><br />
            <br />
            Xのトップページは、上図のような内容になっています。<br />
            <strong>①：アイコン</strong><br />
            自分の好きな画像を選択することが出来ます。特徴が一目で分かるような写真がおすすめです。<br />
            <strong>②：ユーザーネーム</strong><br />
            アイコンとともに表示される名前です。途切れないよう、10文字以内程度の名前にしましょう。<br />
            <strong>③：プロフィール</strong><br />
            一言や自分の居住地、関連リンクを貼ることが出来ます。他のSNSをやっている場合はリンクを貼るのがおすすめです。<br />
            <strong>④：フォロー・フォロワー数</strong><br />
            フォローしている人数と、フォローされている人数が表示されます。<br />
            <strong>⑤：投稿・いいね一覧</strong><br />
            過去に行った投稿や、いいねした投稿が一覧で表示されます。画像や動画と共に投稿した場合、メディア欄で確認することが出来ます。<br />
            <br />
            以上が基本的なプロフィールの説明となります。アカウントをまだ作っていない人は、アイコンやユーザーネームを決めるところから始めてみましょう！<br />
            次に、Xで使える基本的な機能の紹介をしていきます。<br />
          </p>
          <h3 className={Styles.media_text_text_title}>ポスト</h3><br />
          <p className={Styles.media_text_text}>
            ポストとは、Xに短い文章や写真・動画を投稿することを言います。<br />
            <br />
            <img src={Image3} className={Styles.media_img} alt='X 投稿' /><br />
            <br />
            <strong>【ポストのやり方】</strong><br />
            ①：1枚目画像の左下にある、羽のマークをタップします。<br />
            ②：2枚目画像で、文章の入力や写真・動画の選定を行います。<br />
            ③：最後に、文章入力欄の右下にある「投稿」ボタンを押して完了です。<br />
            <br />
            日本語では、最大140文字を1投稿で入力することが出来ます。<br />
            画像や動画を活用しながら、魅力的な投稿の作成を行いましょう！<br />
          </p>
          <h3 className={Styles.media_text_text_title}>リポスト</h3><br />
          <p className={Styles.media_text_text}>
            リポストは、他の人の投稿をシェアして自分の投稿をすることを言います。<br />
            <br />
            <img src={Image4} className={Styles.media_img} alt='X リポスト' /><br />
            <br />
            <strong>【リポストのやり方】</strong><br />
            ①：1枚目画像の下部中央「ハートマーク」の左隣の「矢印マーク」をタップします。<br />
            ②：画面2枚目の下部「リポスト」をタップすると完了です。<br />
            <br />
            あなたがリポストした投稿は、あなたのことをフォローしている人たちに表示されます。<br />
            積極的にリポストを行い、お気に入りの投稿を拡散しましょう！<br />
          </p>
          <h3 className={Styles.media_text_text_title}>リプライ</h3><br />
          <p className={Styles.media_text_text}>
            リプライは、投稿に対してコメントを行う機能です。<br />
            <br />
            <img src={Image5} className={Styles.media_img} alt='X リプライ' /><br />
            <br />
            <strong>【リプライのやり方】</strong><br />
            ①：1枚目画像の下部にある「吹き出しマーク」をタップします。<br />
            ②：2枚目画像で、文章の入力や写真・動画の選定を行います。<br />
            ③：最後に、文章入力欄の右下にある「投稿」ボタンを押して完了です。<br />
            <br />
            リプライは拡散力はないですが、投稿した本人に届きやすい特徴があります。<br />
            投稿した本人が見ることを考え、相手を傷つけるような誹謗中傷は必ず避けるようにしましょう。<br />
          </p>
          <h3 className={Styles.media_text_text_title}>いいね</h3><br />
          <p className={Styles.media_text_text}>
            いいねは、投稿に対して高評価を送るような機能です。<br />
            <br />
            <img src={Image6} className={Styles.media_img} alt='X いいね' /><br />
            <br />
            <strong>【いいねのやり方】</strong><br />
            ・1枚目画像の下部にある「ハートマーク」をタップします。<br />
            <br />
            「いいね数」が多ければ多いほど、反響のあった投稿としてみられます。<br />
            あなたが様々な投稿に対して「いいね」を押すとXが学習し、あなたに対しておすすめの投稿を表示してくれます。<br />
            いろいろな投稿に「いいね」をし、お気に入りの投稿を拡散しましょう！<br />
          </p>
          <h3 className={Styles.media_text_text_title}>フォロー</h3><br />
          <p className={Styles.media_text_text}>
            フォローは、有名人やインフルエンサーといった、あなたの好きな人の投稿を見やすくするための機能です。<br />
            <br />
            <img src={Image7} className={Styles.media_img} alt='X フォロー' /><br />
            <br />
            <strong>【フォローのやり方】</strong><br />
            ①：1枚目画像のように、フォローしたいアカウントのプロフィールに行きます。<br />
            ②：「フォローする」をクリックし、2枚目画像のように「フォロー中」となれば完了です。<br />
            <br />
            フォローしたアカウントのはタイムラインに表示されるようになり、投稿が表示されやすくなります。。<br />
            お気に入りのユーザーをフォローし、発信される情報を逃さないようにしましょう！<br />
          </p>
          <h3 className={Styles.media_text_text_title}>DM(ダイレクトメッセージ)</h3><br />
          <p className={Styles.media_text_text}>
            DMは、LINEのようにX内でチャットのやり取りができる機能です。<br />
            <br />
            <img src={Image8} className={Styles.media_img} alt='X DM' /><br />
            <br />
            <strong>【DMのやり方】</strong><br />
            ①：1枚目画像のようにアカウントのプロフィールに行き、「手紙マーク」をタップします。<br />
            ②：2枚目画像のように、チャットを送れる画面になったらDMを送ることが出来ます。<br />
            <br />
            DMは個人でのやり取りとなるため、ユーザーとの距離感を縮めることが出来ます。<br />
            一方、ユーザーによっては相互にフォローしていないとDMが送れなかったり、DMを公開していない人もいるので、DMを送る際には注意しましょう。<br />
            <br />
          </p>

          <div id='3' className={Styles.media_text_title}>
            <h2>3.Xの楽しみ方</h2>
          </div>
          <br />
          <p className={Styles.media_text_text}>
            Xは気軽に使えるSNSとなっており、様々な形で楽しむことが出来ます。<br />
            Xの代表的な楽しみ方は以下のようなものになっていますので、まだインストールしていない方は、この機会に始めてみましょう！<br />
            Xのトップページは、上図のような内容になっています。<br />
          </p>
          <h3 className={Styles.media_text_text_title}>有名人・企業の投稿をチェック</h3><br />
          <p className={Styles.media_text_text}>
            <br />
            <img src={Image9} className={Styles.media_img} alt='X 美容学生' />
              参照：<a href='https://twitter.com/kuruma_takahira' target="_blank" rel="nofollow noopener noreferrer">
              令和ロマン くるまさんの投稿
            </a><br />
            <br />
            Xでは、好きな芸人やスポーツ選手、有名人が発信する情報や何気ない投稿を見ることが出来ます。<br />
            また、企業広告などXのみで配信されている内容や、X上で有名なアカウントの面白い投稿といったX特有のエンタメもあり、多くの方に楽しまれています。<br />
          </p>
          <h3 className={Styles.media_text_text_title}>トレンドのチェック</h3><br />
          <br />
          <p className={Styles.media_text_text}>
            <img src={Image10} className={Styles.media_img} alt='X トレンド' />
            <br />
            最新トレンドのチェックも、Xの楽しみ方の一つです。<br />
            <strong>Xでは、「For you」や「Trending」などトレンドを様々な種類に区別しており、自分に合わせた楽しみ方をすることが出来ます。</strong><br />
            「Trending」ではX上で話題になっている事柄をリアルタイムで表示され、「For you」ではあなたが関心のあるジャンルのトレンドをリアルタイムで見ることができます。<br />
            Xを上手に活用して、トレンドの情報を入手していきましょう！<br />
          </p>
          <h3 className={Styles.media_text_text_title}>気軽にポスト</h3><br />
          <p className={Styles.media_text_text}>
            Xでは、気軽にポストするのも楽しみ方の一つです。<br />
            ポストの仕方は先ほどお伝えした通りにですが、内容は人を不快にさせたり、傷づけるコンテンツでない限りなんでも構いません。<br />
            「おはよー」や「スタバなう」といった、なんてことない内容もポストしている方もいますね。<br />
            <br />
            とはいえ、全世界の人に見られる可能性がある中で、気軽に投稿できないと思う方もいらっしゃいますよね。<br />
            そんな方のために、<strong>「ポストを非公開」</strong>にする機能があります。<br />
            これはあなたの投稿が<strong>フォロワーにしか表示されなくなる機能で、不特定多数の人に投稿を見られることを避けることが出来ます。</strong><br />
            <br />
            <strong>【ポストを非公開にする手順】</strong>
            <img src={Image11} className={Styles.media_img} alt='X 非公開' />
            <br />
            ①：「ホーム」で左上に表示されるアイコンをタップします。<br />
            ②：画像1枚目下部の、「設定とプライバシー」をタップします。<br />
            ③：画像2枚目の「プライバシーの設定」をタップします。<br />
            ④：画像3枚目の「オーディエンスとタグ付け」をタップします。<br />
            ⑤：画像4枚目の「ポストを非公開」をオンにすると、ポストが非公開になります。<br />
            <br />
            あなたのことをフォローする人もあなたが決めることができ、フォロー承諾をしなければ投稿が表示されることはありません。<br />
            非公開機能を使って気軽にポストを行い、Xを楽しみつくしましょう！<br />
          </p>

          <div id='4' className={Styles.media_text_title}>
            <h2>4.まとめ</h2>
          </div>
          <p className={Styles.media_text_text}>
            <br />
            Xについてなんとなくは理解することはできましたか？<br />
            ここでは紹介しきれなかった機能がXには多くあります。<br />
            ただ、基本から覚えないと頭の中が混乱してしまうのでここで紹介した機能から使ってみてください。徐々にXをもっと使いこなせるようになると思います。<br />
            <br />
            ぜひ活用してみてください！<br />
          </p>
        </div>
      </div>
    </DocumentMeta>
    </>
  );
}


export default Article0001;
