import React, { useEffect, useState } from 'react';
import Styles from './styles.module.css';

type DefaultInput1Props = {
  title: string,
  required: Boolean,
  value: string,
  place: string,
  type: string,
  desc: string,
  length: number,
  setValue: React.Dispatch<React.SetStateAction<string>>;
};

const DefaultInput1: React.FC<DefaultInput1Props> = ({title, required, value, place, desc, length, setValue }) => {
  
  return (
    <>
    <div className={Styles.title}>
      <div>
        <p>{title}</p>
        <div className={ required ? Styles.title_yes : Styles.title_no }>{required ? '※必須' : '任意'}</div>
      </div>
      <p>{typeof value === 'string' ? value.length : '0'} /{length}</p>
    </div>
    <textarea className={Styles.textarea} maxLength={length}
      placeholder={place} value={value} onChange={(e) => setValue(e.target.value)} />
    <p className={Styles.desc}>{desc}</p>
    </>
  );
}

export default DefaultInput1;
