import Styles from './styles.module.css';
import React from 'react'

type StyleTagsProps = {
  style: string[];
  setStyle: React.Dispatch<React.SetStateAction<string[]>>;
  setSwitchStyle: React.Dispatch<React.SetStateAction<boolean>>;
};

const StyleTags: React.FC<StyleTagsProps> = ({ style, setStyle, setSwitchStyle }) => {

  var スタイル = [
    'ストリート',
    'クラシック',
    'モード',
    'フェミニン',
    'グランジ',
    'アンニュイ',
    'ロック',
    'クリエイティブ',
  ]

  var カット = [
    'セニングカット',
    'シャギーカット',
    'スライドカット',
    'ドライカット',
    'レザーカット',
    'レイヤーカット',
    'ウルフカット',
    'ボブカット',
    'チョップカット',
    'ブラントカット',
    'グラデーションカット',
  ]

  var パーマ = [
    'コールドパーマ',
    '水パーマ',
    'ピンパーマ',
    'ストレートパーマ',
    'ツイストパーマ',
    'スパイラルパーマ',
    'ツイストスパイラルパーマ',
    'ニュアンスパーマ',
    'カルマパーマ',
    'ワンカールパーマ',
    'デジタルパーマ',
    'ホットパーマ',
    'エアウエーブパーマ',
    'クリープパーマ',
  ]

  var カラー = [
    'グラデーションカラー',
    'ハイライトカラー',
    'ローライトカラー',
    'イヤリングカラー',
    'ヴェールカラー',
    'イルミナカラー',
    'ハイトーンカラー',
  ]

  var 縮毛矯正 = [
    '酸性縮毛',
    'アルカリ性縮毛',
    '低温縮毛',
  ]

  function pushStyle(value: string) {
    if (style.length <= 4) {
      setStyle([...style, value])
    }
  }

  return (
    <>
    <div className={Styles.modal}>
      <div>
        <div className={Styles.modal_black} onClick={() => setSwitchStyle(false)}></div>
        <div className={Styles.modal_contents}>
          
          <div className={Styles.title}>
            <h5>その他のタグ</h5>
            <div>
              <button onClick={() => setStyle([])}>クリア</button>
              <button onClick={() => setSwitchStyle(false)}>確定</button>
            </div>
          </div>

          <p className={Styles.desc}>
            {style.length <= 4 ? '※5つ以上のタグを選択する事は出来ません。' : <span>※5つ以上のタグを選択する事は出来ません。</span> }
          </p>

          <div className={Styles.select}>

            <h5>スタイル関連</h5>
            <div>
              {スタイル.map((data, index) =>
                <div key={index}>
                  <input type="checkbox" checked={style.indexOf(data) !== -1}
                    onChange={(e) => e.target.checked ? pushStyle(data) : setStyle(style.filter((v, index) => (v !== data)))}/>
                  <p>{data}</p>
                </div>
              )}
            </div>

            <h5>カット関連</h5>
            <div>
              {カット.map((data, index) =>
                <div key={index}>
                  <input type="checkbox" checked={style.indexOf(data) !== -1}
                    onChange={(e) => e.target.checked ? pushStyle(data) : setStyle(style.filter((v, index) => (v !== data)))}/>
                  <p>{data}</p>
                </div>
              )}
            </div>

            <h5>パーマ関連</h5>
            <div>
              {パーマ.map((data, index) =>
                <div key={index}>
                  <input type="checkbox" checked={style.indexOf(data) !== -1}
                    onChange={(e) => e.target.checked ? pushStyle(data) : setStyle(style.filter((v, index) => (v !== data)))}/>
                  <p>{data}</p>
                </div>
              )}
            </div>

            <h5>カラー関連</h5>
            <div>
              {カラー.map((data, index) =>
                <div key={index}>
                  <input type="checkbox" checked={style.indexOf(data) !== -1}
                    onChange={(e) => e.target.checked ? pushStyle(data) : setStyle(style.filter((v, index) => (v !== data)))}/>
                  <p>{data}</p>
                </div>
              )}
            </div>

            <h5>縮毛矯正関連</h5>
            <div>
              {縮毛矯正.map((data, index) =>
                <div key={index}>
                  <input type="checkbox" checked={style.indexOf(data) !== -1}
                    onChange={(e) => e.target.checked ? pushStyle(data) : setStyle(style.filter((v, index) => (v !== data)))}/>
                  <p>{data}</p>
                </div>
              )}
            </div>

          </div>
        </div>
      </div>
    </div>
    </>
  );
}

export default StyleTags;
